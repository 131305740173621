import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import { Link } from "react-router-dom";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit } from "react-icons/fi";
import Button from "@material-ui/core/Button";
import Delete from "../../components/Crm/Candidates/Delete";
import Moment from "react-moment";
import styled from "styled-components";
import DropCandidates from "../../components/Crm/Candidates/DropCandidates";
import { V3 } from "../../lib/api";
import { CANDIDATES_LOADING, GET_CANDIDATES } from "../../redux/types";

const From = styled.div`
  width: 100%;
  display: block;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 600;

  ${(props) =>
    props.quality === "CRM" &&
    `
    background: #41d7ba;
  `};
  ${(props) =>
    props.quality === "TWO.JOBS" &&
    `
    background: #D91A32;
    color: #fff;
  `};
  ${(props) =>
    props.quality === "LINKEDIN" &&
    `
    background: #1488bf;
    color: #fff;
  `};
`;

function Candidates() {
  const [filters, setFilters] = React.useState([]);

  const [loadingExport, setLoadingExport] = useState(false);

  const dispatch = useDispatch();

  const handleFetch = (state) => {
    setFilters(state);

    var filterdata = sessionStorage.getItem("candidatesfilters");
    if (filterdata !== null && filterdata !== undefined) {
      const filterData = JSON.parse("[" + filterdata + "]");
      let newFilters = state;
      newFilters.filtered = filterData[0];
      setFilters(newFilters);
    }

    var queryString = state.filtered
      .map((key) => key.id + "=" + encodeURIComponent(key.value))
      .join("&");
    let query = `?page=${state.page + 1}&${queryString}`;
    dispatch({
      type: CANDIDATES_LOADING,
    });
    V3.get(`/crm/candidates${query}`).then((res) => {
      dispatch({
        type: GET_CANDIDATES,
        payload: res.data,
      });
    });
  };
  const handleExport = () => {
    setLoadingExport(true);

    var filterdata = sessionStorage.getItem("candidatesfilters");
    if (filterdata !== null && filterdata !== undefined) {
      const filterData = JSON.parse("[" + filterdata + "]");
      filters.filtered = filterData[0];
    }

    var queryString = filters.filtered
      .map((key) => key.id + "=" + encodeURIComponent(key.value))
      .join("&");
    let query = `?page=${filters.page + 1}&${queryString}`;
    V3.get(`crm/candidates/export${query}`)
      .then((result) => {
        window.open(result?.data?.data?.url, "_blank");
        setLoadingExport(false);
      })
      .catch((err) => {
        setLoadingExport(false);
      });
  };

  const { candidates, data, loading } = useSelector((state) => state.candidate);
  const columns = [
    {
      id: "name",
      Header: "Kandidaten Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/crm/candidate/${props.original._id}`}>
          <strong>{props.original.name}</strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <a href={`mailto:${props.original.email}`}>
          <strong style={{ textTransform: "lowercase" }}>
            {props.original.email}
          </strong>
        </a>
      ),
    },
    {
      id: "phone",
      Header: "phone",
      accessor: (d) => d._id,
      Cell: (props) => (
        <a href={`tel:+${props.original.phone}`}>
          <strong>{props.original.phone}</strong>
        </a>
      ),
    },
    {
      id: "from",
      Header: "From",
      accessor: "from",

      Cell: (props) => (
        <div style={{ width: "100%" }}>
          {props.value ? (
            <From quality={props.value}>{props.value}</From>
          ) : (
            <From quality="CRM">CRM</From>
          )}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="CRM">CRM</option>
          <option value="TWO.JOBS">TWO.JOBS</option>
          <option value="LINKEDIN">LINKEDIN</option>
        </select>
      ),
    },
    {
      id: "createdAt",
      Header: "Datum",
      accessor: "createdAt",
      filterable: true,
      Cell: (props) => <Moment format="DD.MM.YYYY">{props.value}</Moment>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-createdAt">Neu-Alt</option>
          <option value="createdAt">Alt-neu</option>
        </select>
      ),
    },

    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Bearbeiten">
            <Link to={`/crm/candidates/edit/${props.original._id}`}>
              <FiEdit></FiEdit>
            </Link>
          </Tooltip>
          <Tooltip arrow title="Löschen Sie diesen Kandidaten">
            <Delete candidateId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <DropCandidates>
      <Header title="Kandidaten" size={data.total}>
        <Button
          color="primary"
          variant="contained"
          style={{ float: "right", marginRight: 15 }}
          onClick={() => {
            handleExport();
          }}
        >
          Export
        </Button>
        <Button
          component={Link}
          to="/crm/candidates/new"
          color="primary"
          variant="contained"
          style={{ float: "right" }}
        >
          Neu
        </Button>
      </Header>
      <Table
        data={candidates}
        columns={columns}
        loading={loading || loadingExport}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={handleFetch}
        noDataText="Keine Kandidaten"
        filterable={true}
        defaultFiltered={filters.filtered}
        onFilteredChange={(filtered) => {
          sessionStorage.setItem("candidatesfilters", JSON.stringify(filtered));
        }}
        sortable={false}
      />
    </DropCandidates>
  );
}

export default Candidates;
