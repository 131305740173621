import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 15px;

  .MuiButtonBase-root {
    border-color: #d0d5dd !important;
    background: #fff;
    &:hover {
      background-color: #d0d5dd;
      color: #344054;
    }
  }
  .MuiButton-outlined {
    border-color: #d0d5dd;
    color: #344054;
  }
  .MuiButton-contained {
    background-color: #d0d5dd;
    color: #344054;
  }
  @media (max-width: 420px) {
    .MuiButtonGroup-grouped {
      padding: 8px;
      font-size: 12px;
    }
  }
`;

function Filters({ setLoading, setEndDate, setStartDate, endDate, startDate }) {
  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    if (endDate && startDate) {
      setClickedButton(moment(endDate).diff(moment(startDate), "months"));
    }
  }, [endDate, startDate]);

  return (
    <Wrapper>
      <ButtonGroup>
        <Button
          variant={clickedButton > 12 ? "contained" : "outlined"}
          onClick={() => {
            setStartDate(moment("01/01/2019", "DD/MM/YYYY"));
            setEndDate(moment(new Date()));
            setClickedButton("all");
            if (setLoading) {
              setLoading(true);
            }
          }}
        >
          Alle
        </Button>
        <Button
          variant={clickedButton === "12" ? "contained" : "outlined"}
          onClick={() => {
            setStartDate(moment(new Date()).subtract(1, "year"));
            setEndDate(moment(new Date()));
            setClickedButton("12");
            if (setLoading) {
              setLoading(true);
            }
          }}
        >
          12 Monate
        </Button>
        <Button
          variant={clickedButton === "6" ? "contained" : "outlined"}
          onClick={() => {
            setStartDate(moment(new Date()).subtract(6, "month"));
            setEndDate(moment(new Date()));
            setClickedButton("6");
            if (setLoading) {
              setLoading(true);
            }
          }}
        >
          6 Monate
        </Button>
        <Button
          variant={clickedButton === "3" ? "contained" : "outlined"}
          onClick={() => {
            setStartDate(moment(new Date()).subtract(3, "month"));
            setEndDate(moment(new Date()));
            setClickedButton("3");
            if (setLoading) {
              setLoading(true);
            }
          }}
        >
          3 Monate
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}

export default Filters;
