import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { SET_END_DATE, SET_START_DATE } from "../../redux/types";

function Head({ user }) {
  const { startDate, endDate } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  return (
    <Paper style={{ padding: 30 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Avatar
            src={user && user.profile.picture}
            alt={user && user.profile.name}
            style={{ width: 180, height: 180 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div style={{ marginBottom: 15 }}>
            <h1 style={{ margin: 0 }}>{user && user.profile.name}</h1>
            <Grid container spacing={2} style={{ maxWidth: 500 }}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={(date) =>
                    dispatch({
                      type: SET_START_DATE,
                      payload: date,
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={(date) =>
                    dispatch({
                      type: SET_END_DATE,
                      payload: date,
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <p style={{ margin: 0 }}>Kontaktieren mit:</p>
          <div style={{ display: "flex", marginLeft: -15 }}>
            {user && user.profile.facebook && (
              <IconButton
                component="a"
                href={`https://facebook.com/${user.profile.facebook}`}
                target="_blank"
              >
                <FacebookIcon />
              </IconButton>
            )}
            {user && user.profile.instagram && (
              <IconButton
                component="a"
                href={`https://instagram.com/${user.profile.instagram}`}
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
            )}
            {user && user.profile.linkedIn && (
              <IconButton
                component="a"
                href={`https://linkedin.com/${user.profile.linkedIn}`}
                target="_blank"
              >
                <LinkedInIcon />
              </IconButton>
            )}
            {user && user.profile.phone && (
              <IconButton component="a" href={`tel:+${user.phone}`}>
                <PhoneIcon />
              </IconButton>
            )}
            {user && user.email && (
              <IconButton component="a" href={`mailto:${user.email}`}>
                <EmailIcon />
              </IconButton>
            )}
          </div>

          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to={`/users/edit/${user && user._id}`}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            Edit Profile
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Head;
