import React, { useState } from "react";
import Card from "../../Card";
import Table from "../../Table";
import Actions from "../../Table/Actions";
import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import New from "./new";
import { useConfirm } from "material-ui-confirm";
import { Grid, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { withRouter } from "react-router-dom";
import EditDayOf from "./Edit";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker } from "@material-ui/pickers";

function SickDays({ match }) {
  const date = moment("01.01.2019");
  const [sickDays, setSickDays] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [days, setDays] = useState(0);
  const confirm = useConfirm();
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = ({ from, to, _id, days: daysAmount }) => {
    confirm({
      title: `Delete your days of`,
      description: `Delete day off from ${moment(from).format(
        "DD.MM.YYYY"
      )}-${moment(to).format("DD.MM.YYYY")}`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/sick-days/${_id}`, {
        approved: true,
      })
        .then((result) => {
          let tmp = [...sickDays];
          setSickDays(tmp.filter((el, index) => el._id !== _id));
          setLoading(false);
          setDays(days - daysAmount);
        })
        .catch((err) => {});
    });
  };

  const handleUpdate = (id, data) => {
    const tmpData = sickDays.map((el) => {
      if (el._id !== id) {
        return el;
      } else {
        return data;
      }
    });
    setSickDays([...tmpData, data]);
    enqueueSnackbar("Day of has been updated", {
      variant: "success",
    });
  };

  const columns = [
    {
      id: "form",
      Header: "Form",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.startDate}</Moment>
        </strong>
      ),
    },

    {
      id: "to",
      Header: "To",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>
            {moment(props.original.endDate).subtract(2, "hour").format()}
          </Moment>
        </strong>
      ),
    },
    {
      id: "days",
      Header: "Days",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.days}</strong>,
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          <Actions>
            <Tooltip arrow title="Edit">
              <EditDayOf data={props.original} handleUpdate={handleUpdate} />
            </Tooltip>
            <Tooltip arrow title="Delete">
              <span onClick={() => handleDelete(props.original)}>
                <DeleteIcon></DeleteIcon>
              </span>
            </Tooltip>
          </Actions>
        </>
      ),
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/sick-days${query}`)
      .then((result) => {
        setData(result.data);
        setDays(result.data.days);
        setSickDays(result.data.data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const add = (data) => {
    setDays(days + data.data.days);
    setSickDays([...sickDays, data.data]);
  };
  return (
    <Card
      title={`Sick Days: ${days}`}
      style={{ marginTop: 15 }}
      button={
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
          <New add={add} id={id} />
        </>
      }
    >
      <Table
        data={sickDays}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        filterable={false}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&user=${id}&${queryString}`;
          handleFetch(query);
        }}
        minRows={5}
        noDataText="No days off"
        defaultFiltered={[
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
    </Card>
  );
}

export default withRouter(SickDays);
