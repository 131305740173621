import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MediaChoose from "../../../components/Media/Chooser";
import { V3 } from "../../../lib/api";
import Editor from "../../../components/Editor";
import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import TagsInput from "../../../components/Job/TagInput";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function PersonImage({ values, setFieldValue }) {
  const [selectImage, setSelectedImage] = React.useState(values.personImage);

  React.useEffect(() => {
    setFieldValue("personImage", selectImage ? selectImage._id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <Paper>
      <MediaChoose onChange={setSelectedImage}>
        <div style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}>
          {selectImage ? (
            <Preview>
              <img
                src={selectImage ? selectImage.lg.url : ""}
                alt={selectImage ? selectImage.lg.url : ""}
              />
            </Preview>
          ) : (
            <p style={{ textAlign: "center" }}>
              Klicken Sie, um ein Bild auszuwählen
            </p>
          )}
        </div>
      </MediaChoose>
      {selectImage && (
        <div style={{ padding: 15 }}>
          <Button
            onClick={(e) => setSelectedImage(null)}
            fullWidth
            variant="outlined"
          >
            Aktuelles Bild entfernen
          </Button>
        </div>
      )}
    </Paper>
  );
}

function CompanyImage({ values, setFieldValue }) {
  const [selectImage, setSelectedImage] = React.useState(values.companyImage);

  React.useEffect(() => {
    setFieldValue("companyImage", selectImage ? selectImage._id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <Paper>
      <MediaChoose onChange={setSelectedImage}>
        <div style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}>
          {selectImage ? (
            <Preview>
              <img
                src={selectImage ? selectImage.lg.url : ""}
                alt={selectImage ? selectImage.lg.url : ""}
              />
            </Preview>
          ) : (
            <p style={{ textAlign: "center" }}>
              Klicken Sie, um ein Bild auszuwählen
            </p>
          )}
        </div>
      </MediaChoose>
      {selectImage && (
        <div style={{ padding: 15 }}>
          <Button
            onClick={(e) => setSelectedImage(null)}
            fullWidth
            variant="outlined"
          >
            Aktuelles Bild entfernen
          </Button>
        </div>
      )}
    </Paper>
  );
}

function EditTestimonial() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    V3.get(`/testimonials/${id}`).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _handleSubmit = (values, formApi) => {
    setMessage(null);
    V3.put(`/testimonials/${id}`, values)
      .then((res) => {
        enqueueSnackbar("Testimonial updated successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        setMessage(
          err?.response?.data?.message || "Could not updated Testimonial"
        );
        enqueueSnackbar("Could not updated Testimonial", {
          variant: "error",
        });
        formApi.setSubmitting(false);
      });
  };

  if (!data) {
    return <Loader />;
  }
  return (
    <Formik
      validationSchema={Yup.object().shape({
        en: Yup.object({
          name: Yup.string().nullable().required("Required"),
          role: Yup.string().nullable().required("Required"),
          location: Yup.string().nullable().required("Required"),
          description: Yup.string().nullable().required("Required"),
        }),
        de: Yup.object({
          name: Yup.string().nullable().required("Required"),
          role: Yup.string().nullable().required("Required"),
          location: Yup.string().nullable().required("Required"),
          description: Yup.string().nullable().required("Required"),
        }),
        published: Yup.boolean(),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        en: data.en,
        de: data.de,
        published: data.published,
        companyImage: data.companyImage,
        personImage: data.personImage,
        val: 0,
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, values }) => (
        <Form>
          {(isSubmitting || loading) && <Loader></Loader>}
          <Container style={{ maxWidth: 1024 }}>
            <Paper style={{ padding: 15, marginBottom: 15 }}>
              <Grid container spacing={2} style={{ marginBottom: 15 }}>
                <Grid item xs={12} md={4}>
                  <PersonImage setFieldValue={setFieldValue} values={values} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CompanyImage setFieldValue={setFieldValue} values={values} />
                </Grid>
              </Grid>
              <Paper style={{ marginBottom: 30 }}>
                <Tabs
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={values.val}
                  onChange={(e, val) => setFieldValue("val", val)}
                >
                  <Tab label="German" {...a11yProps(0)} />
                  <Tab label="English" {...a11yProps(1)} />
                </Tabs>
              </Paper>
              {message && (
                <Alert severity="error" style={{ marginBottom: 15 }}>
                  {message}
                </Alert>
              )}

              <TabPanel value={values.val} index={0}>
                <Editor
                  value={values.de.description}
                  onChange={(val) => setFieldValue("de.description", val)}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="de.name"
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Role"
                  name="de.role"
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Location"
                  name="de.location"
                />
                <TagsInput
                  selectedItem={values.de.hashtags}
                  tags={values.de.hashtags}
                  setSelectedItem={(data) => setFieldValue("de.hashtags", data)}
                  fullWidth
                  variant="outlined"
                  id="hashtags"
                  name="hashtags"
                  placeholder="hinzufügen"
                  label="Hashtags"
                />
              </TabPanel>
              <TabPanel value={values.val} index={1}>
                <Editor
                  value={values.en.description}
                  onChange={(val) => setFieldValue("en.description", val)}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="en.name"
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Role"
                  name="en.role"
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Location"
                  name="en.location"
                />
                <TagsInput
                  selectedItem={values.en.hashtags}
                  tags={values.en.hashtags}
                  setSelectedItem={(data) => setFieldValue("en.hashtags", data)}
                  fullWidth
                  variant="outlined"
                  id="hashtags"
                  name="hashtags"
                  placeholder="hinzufügen"
                  label="Hashtags"
                />
              </TabPanel>

              <Paper
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <span>Published</span>
                <div>
                  <Field component={Switch} color="primary" name="published" />
                </div>
              </Paper>

              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: 15 }}
                fullWidth
                disabled={!dirty}
                type="submit"
              >
                Aktualisieren
              </Button>
            </Paper>
          </Container>
        </Form>
      )}
    </Formik>
  );
}

export default EditTestimonial;
