import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { CRM } from "../config";
import { FormLabel, IconButton } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
export default function ImageUpload({
  onImageChange,
  imagePreviewUrl,
  id,
  label,
  onRemove,
}) {
  return (
    <Card
      variant="outlined"
      style={{ cursor: "pointer", position: "relative" }}
    >
      {label && (
        <FormLabel>
          <p style={{ margin: 0, paddingTop: 8, paddingLeft: 8 }}>{label}</p>
        </FormLabel>
      )}
      {onRemove && imagePreviewUrl && (
        <IconButton
          variant="contained"
          style={{
            border: "1px solid #000",
            backgroundColor: "#fff",
            marginRight: 8,
            position: "absolute",
            top: 35,
            right: 5,
            zIndex: 9999,
          }}
          onClick={() => onRemove()}
        >
          <DeleteOutlinedIcon style={{ color: "#000" }} />
        </IconButton>
      )}

      <CardActions style={{ width: "100%", cursor: "pointer" }}>
        <React.Fragment>
          <label
            htmlFor={id ? id : "image"}
            style={{ width: "100%", cursor: "pointer" }}
          >
            <CardMedia
              style={{ height: 250, borderRadius: 3 }}
              image={
                imagePreviewUrl
                  ? imagePreviewUrl
                  : CRM + "/images/not-found.png"
              }
            ></CardMedia>
          </label>
        </React.Fragment>
      </CardActions>
      <input
        title={id ? id : "image"}
        name={id ? id : "image"}
        id={id ? id : "image"}
        onChange={onImageChange}
        type="file"
        style={{ display: "none" }}
      />
    </Card>
  );
}
