import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { V3 } from "../../../lib/api";

import Card from "../../Card";
import Loader from "../../Loader";
import styled from "styled-components";
function Wrapper({ size, title, loading }) {
  return (
    <Loader loading={loading}>
      <Paper
        style={{
          padding: "32px 0",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <h1>
          <CountUp end={size} />{" "}
        </h1>

        <h3 style={{ fontSize: 16 }}>{title}</h3>
      </Paper>
    </Loader>
  );
}

const TotalWrapper = styled(Paper)`
  height: 100%;
  overflow: hidden;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    padding: 15px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    padding: 15px;
  }
  .bottom-wrapper {
    padding: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    background-color: ${(props) => props.theme.palette.lightBackground};
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;
function Wrapper2({ size, size2, title, loading }) {
  return (
    <Loader loading={loading}>
      <TotalWrapper>
        <p style={{ fontSize: 16 }}>{title}</p>
        <h3>
          <CountUp end={size + size2} />
        </h3>
        <Divider />
        <div className="bottom-wrapper">
          <div className="bottom">
            <h4>OR</h4>
            <h4>{size}</h4>
          </div>
          <div className="bottom">
            <h4>COR</h4>
            <h4>{size2}</h4>
          </div>
        </div>
      </TotalWrapper>
    </Loader>
  );
}

function Statistic() {
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(`01/01/${new Date().getFullYear}`, "DD/MM/YYYY").format()
  );
  const [endDate, setEndDate] = useState(moment().format());
  const [loading, setLoading] = useState(false);
  const [topChannels, setTopChannels] = useState([]);

  const fetchData = async () => {
    const resTop = await V3.get(`/shared-links/channels`, {
      params: {
        endDate: moment(endDate).format("DD/MM/YYYY"),
        startDate: moment(startDate).format("DD/MM/YYYY"),
      },
    });
    setTopChannels(resTop.data.data);
    setLoading(true);
    const res = await V3.get(`/shared-links/info`, {
      params: { endDate, startDate },
    });
    setData(res.data);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={12} md={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Start"
            format="dd/MM/yyyy"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{ marginRight: 15 }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="End"
            format="dd/MM/yyyy"
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper2
          size={data ? data.total : "0"}
          size2={data ? data.totalCompany : "0"}
          title="Total geteilte Links"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper2
          size={data ? data.distinct : "0"}
          size2={data ? data.distinctCompany : "0"}
          title="Total geteilte Jobs"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper2
          size={data ? data.visits : "0"}
          size2={data ? data.visitsFromCompany : "0"}
          title="Besuche"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper2
          size={data ? data.downloadedApp : "0"}
          size2={data ? data.downloadedAppFromCompany : "0"}
          title="App"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper
          size={data ? data.recruiters : "0"}
          title="Total genehmigte Recruiters"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Wrapper
          size={data ? data.companyRecruiters : "0"}
          title="Total Company Recruiters"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title={"Top Klicks"} defaultOpen={false}>
          <Table aria-label="simple table">
            <TableBody>
              {data?.topShared
                .sort((a, b) => (a.amount > b.amount ? -1 : 1))
                .map((row) => {
                  let amount = 0;
                  // eslint-disable-next-line array-callback-return
                  row.sharedLinks.map((el) => {
                    amount += el.points;
                  });
                  return (
                    <TableRow key={row?.user?.profile?.name}>
                      <TableCell component="th" scope="row">
                        {row?.user?.profile?.name
                          ? row?.user?.profile?.name
                          : `${row?.user?.profile?.firstname} ${row?.user?.profile?.lastname}`}
                      </TableCell>
                      <TableCell align="right">{amount}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title={"Top Weiterleitungen"} defaultOpen={false}>
          <Table aria-label="simple table">
            <TableBody>
              {data?.topClicked
                .sort((a, b) => {
                  return a.externalClick.length > b.externalClick.length
                    ? -1
                    : 1;
                })
                .map((row) => {
                  let amount = 0;
                  // eslint-disable-next-line array-callback-return
                  row.externalClick.map((el) => {
                    amount += el.points;
                  });
                  return (
                    <TableRow key={row?.user?.profile?.name}>
                      <TableCell component="th" scope="row">
                        {row?.user?.profile?.name
                          ? row?.user?.profile?.name
                          : `${row?.user?.profile?.firstname} ${row?.user?.profile?.lastname}`}
                      </TableCell>
                      <TableCell align="right">{amount}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title={"Top Channels"} defaultOpen={false}>
          <Table aria-label="simple table">
            <TableBody>
              {topChannels.map((row) => {
                return (
                  <TableRow key={row?.user?.profile?.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row?.key}
                    </TableCell>
                    <TableCell align="right"> {row?.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Statistic;
