import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import Actions from "../../../components/Table/Actions";
import Card from "../../../components/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "./Delete";
import Edit from "./update";
import AddNew from "./addNew";
import Moment from "react-moment";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@material-ui/core";
import { FiDownload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { SET_JOB } from "../../../redux/types";
import { useConfirm } from "material-ui-confirm";

const Doc = ({ files }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!files || !files?.length) {
    return "-";
  }
  return (
    <>
      <Tooltip title="Herunterladen">
        <IconButton variant="contained" color="primary" onClick={handleClick}>
          <FiDownload className="fi" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          {files.map((file, index) => (
            <ListItem button component="a" href={file} target="_blank">
              <ListItemText primary={`Dokument herunterladen ${index + 1}`} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

function Jobs() {
  const { job, loading } = useSelector((state) => state.job);

  const dispatch = useDispatch();

  const confirm = useConfirm();

  useEffect(() => {
    if (job.prospectProfiles) {
      setData(job.prospectProfiles);
    }
  }, [job]);

  const [data, setData] = useState([]);

  const handleNew = (jobData, interestedHasBeenDeleted) => {
    setData(jobData?.prospectProfiles);
    dispatch({
      type: SET_JOB,
      payload: jobData,
    });
    if (interestedHasBeenDeleted)
      confirm({
        title: `Doublette`,
        description: `Doublette - wurde automatisch bei "Interessenten ohne Tel" gelöscht`,
      });
  };
  const handleDelete = (id) => {
    const tmp = data.filter((el) => el._id !== id);
    setData(tmp);
  };
  const handleUpdate = (updatedData) => {
    const tmp = data.map((el) => {
      if (el._id === updatedData._id) {
        return updatedData;
      } else {
        return el;
      }
    });
    setData(tmp);
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d.name,
      filterable: false,
      Cell: (props) => <strong>{props.original?.name || "-"}</strong>,
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d.email,
      filterable: false,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original?.email || "-"}
        </span>
      ),
    },
    {
      id: "phone",
      Header: "Tel.",
      accessor: (d) => d.phone,
      filterable: false,
      Cell: (props) => <span>{props.original?.phone}</span>,
    },
    {
      id: "profile",
      Header: "profile",
      accessor: (d) => d.profile,
      filterable: false,
      Cell: (props) => (
        <a
          href={props.original.profile}
          style={{ textTransform: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.original.profile}
        </a>
      ),
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "files",
      Header: "Documents",
      accessor: (d) => d.files,
      filterable: false,
      Cell: (props) => <Doc files={props.original?.files} />,
    },
    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Profile">
            <Edit
              profile={props.original}
              jobId={job._id}
              handleUpdate={handleUpdate}
            />
          </Tooltip>
          <Tooltip title="Delete this Profile">
            <Delete
              profileId={props.original._id}
              jobId={job._id}
              handleDelete={handleDelete}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <Card
      title="Eingereichte Interessenten mit Tel"
      style={{ marginTop: 15 }}
      button={<AddNew id={job._id} handleNew={handleNew} />}
    >
      <Table
        data={data.filter((el) => !el.deleted)}
        columns={columns}
        loading={loading}
        page={1}
        pages={1}
        noDataText="No data"
        minRows={5}
        filterable={false}
        showPaginationBottom={false}
      />
    </Card>
  );
}

export default Jobs;
