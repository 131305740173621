import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import Moment from "react-moment";
export default function Notification({ data }) {
  return (
    <React.Fragment>
      <Link to={data.link}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={data.author && data.author.profile.name}
              src={data.author && data.author.profile.picture}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                <strong>{data.author && data.author.profile.name}</strong> -
                <Moment>{data.createdAt}</Moment>
              </span>
            }
            secondary={<React.Fragment>{data.title}</React.Fragment>}
          />
        </ListItem>
      </Link>

      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
}
