import {
  GET_JOBS,
  GET_JOB,
  ADD_JOB,
  DELETE_JOB,
  JOBS_LOADING,
  FILTERS,
  UPDATE_JOB,
  SET_JOB_NOTES_ACTIVITIES,
  SET_INTERESTED,
  SET_JOB,
} from "../types";

const initialState = {
  jobs: [],
  data: [],
  job: null,
  shared: 0,
  loading: true,
  slug_exists: null,
  filters: [],
  noteActivities: [],
  page: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload.data,
        data: action.payload,
        loading: false,
        slug_exists: null,
      };
    case GET_JOB:
      return {
        ...state,
        job: action.payload.data,
        shared: action.payload.shared,
        noteActivities: action.payload.notesActivities,
        loading: false,
      };
    case SET_JOB:
      return {
        ...state,
        job: action.payload,
        loading: false,
      };
    case DELETE_JOB:
      return {
        ...state,
        jobs: state.jobs.filter((job) => job._id !== action.payload),
        slug_exists: null,
        loading: false,
      };
    case ADD_JOB:
      return {
        ...state,
        jobs: [action.payload, ...state.jobs],
        loading: false,
        slug_exists: null,
      };
    case FILTERS:
      return {
        ...state,
        filters: action.filters,
        page: action.page,
      };
    case JOBS_LOADING:
      return {
        ...state,
        job: null,
        loading: true,
      };
    case UPDATE_JOB:
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job._id === action.payload._id) {
            return action.payload;
          } else {
            return job;
          }
        }),
        job: action.data,
        loading: false,
      };
    case SET_INTERESTED:
      return {
        ...state,
        job: { ...state.job, interested: action.payload },
      };
    case SET_JOB_NOTES_ACTIVITIES:
      return {
        ...state,

        noteActivities: [action.payload, ...state.noteActivities],
      };
    default:
      return state;
  }
}
