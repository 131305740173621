import io, { Socket } from "socket.io-client";
import React, { useEffect, useState } from "react";

export const SocketContext = React.createContext(Socket);

export const SocketProvider = ({ url, options, children }) => {
  const socket = io(url, {
    reconnection: true,
    reconnectionDelay: 3000,
    transports: ["websocket"],
    ...options,
  });

  const [connected, setConnected] = useState(false);

  // Connect to the Socket.io server when the component mounts
  useEffect(() => {
    socket.on("connect", () => {
      setConnected(true);
    });

    socket.on("disconnect", () => {
      setConnected(false);
    });

    // Clean up the socket when the component unmounts
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
