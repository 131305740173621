import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Editor from "../../components/Editor";
import Card from "../../components/Card";
import { Switch } from "material-ui-formik-components/Switch";
import NotifyNewProduct from "../../components/Notify/NewProduct";
import GalleryBuilder from "../../components/GalleryBuilder";
import { V3 } from "../../lib/api";
import NewProductEmail from "../../components/Notify/NewProductEmail";
import { Cantons2 } from "../../data";
import ImageUpload from "../../components/imageUpload";
import { GET_ERRORS, UPDATE_PRODUCT } from "../../redux/types";
import { useSnackbar } from "notistack";

function EditProduct(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [categories, setCategories] = useState([]);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  React.useEffect(() => {
    let productId = props.match.params.id;
    V3.get(`/products/${productId}/id`).then((res) => {
      setData(res.data.data);
      if (res.data?.data?.logo) {
        setAvatarPreview(res.data?.data?.logo?.md?.url);
      }
      if (res.data?.data?.thumbnail) {
        setThumbnailPreview(res.data?.data?.thumbnail?.md?.url);
      }
      setLoading(false);
    });
    V3.get(`/categories/products?limit=999`).then((res) => {
      setCategories(
        res?.data?.data?.map((el) => {
          return {
            key: el?._id,
            label: el?.de?.name,
            value: el?.de?.name,
          };
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };
  const onThumbnailChange = (event) => {
    setThumbnail(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setThumbnailPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const _handleSubmit = async (values, formikAPi) => {
    let productId = props.match.params.id;
    const { type, category, gallery, canton } = values;

    const newProductData = {
      ...values,
      type: parseInt(type.key),
      category: category?.key,
      canton: canton?.key,
      gallery: gallery.map((el) => el._id),
    };

    if (avatar) {
      try {
        const formData = new FormData();

        formData.append("images", avatar);

        const res = await V3.post(`medias`, formData);

        if (res.data.data?.length) {
          newProductData.logo = res.data.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    } else if (!avatarPreview) {
      newProductData.logo = null;
    }
    if (thumbnail) {
      try {
        const formData = new FormData();

        formData.append("images", thumbnail);

        const res = await V3.post(`medias`, formData);

        if (res.data.data?.length) {
          newProductData.thumbnail = res.data.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    } else if (!thumbnailPreview) {
      newProductData.thumbnail = null;
    }

    V3.put(`/products/${productId}`, newProductData)
      .then((res) => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: productId,
        });
        enqueueSnackbar("Product updated successfully", {
          variant: "success",
        });
        formikAPi.setSubmitting(false);
      })
      .catch((error) => {
        dispatch({
          type: GET_ERRORS,
          payload: { msg: error.response.data.massage },
        });
      });
  };
  if (loading) {
    return <Loader />;
  }
  if (data) {
    return (
      <Formik
        validationSchema={Yup.object().shape(
          {
            title: Yup.string().nullable().required("Required"),
            type: Yup.object().nullable().required("Required"),
            description: Yup.string().nullable(),
            descriptionTitle: Yup.string().nullable(),
            terms: Yup.string().nullable(),
            rabatCode: Yup.string().nullable(),
            stock: Yup.number().nullable(),
            points: Yup.number().nullable().required("Required"),
            chfPrice: Yup.number().nullable().required("Required"),
            internalNote: Yup.string().nullable(),
            video: Yup.string().nullable(),
            favoriteProduct: Yup.boolean(),
            canton: Yup.object()
              .nullable()
              .when("type", {
                is: (type) => {
                  return type?.key === 2;
                },
                then: Yup.object().required("Required"),
              }),
          },
          ["type"]
        )}
        onSubmit={_handleSubmit}
        initialValues={{
          title: data.title,
          description: data.description,
          descriptionTitle: data.descriptionTitle,
          terms: data.terms,
          gallery: data.gallery || [],
          points: data.points,
          chfPrice: data.chfPrice,
          stock: data.stock,
          published: data.published === false ? false : true,
          rabatCode: data.rabatCode || "",
          internalNote: data.internalNote || "",
          video: data.video || "",
          media: data.media || "",
          favoriteProduct: (data && data.favoriteProduct) || false,
          category: data.category
            ? {
                key: data.category._id,
                label: data.category.name,
                value: data.category.name,
              }
            : null,
          type: {
            key: parseInt(data.type),
            label: data.type === "1" ? "Gutschein" : "Aktivität",
            value: data.type === "1" ? "Gutschein" : "Aktivität",
          },
          canton: data?.canton
            ? {
                key: data.canton,
                label: data.canton,
                value: data.canton,
              }
            : null,
        }}
      >
        {({ isSubmitting, setFieldValue, initialValues, values }) => (
          <Form>
            {isSubmitting && <Loader></Loader>}
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="title"
                    label="Titel"
                    name="title"
                    style={{ margin: 0 }}
                  />
                </Paper>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="video"
                    label="Video"
                    name="video"
                    style={{ margin: 0 }}
                  />
                </Paper>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="descriptionTitle"
                    label="Description Title"
                    name="descriptionTitle"
                    style={{ margin: 0 }}
                  />
                </Paper>
                <Card
                  title="Beschreibung"
                  style={{ marginBottom: 15, overflow: "hidden" }}
                >
                  <Editor
                    value={initialValues.description}
                    onChange={(value) => setFieldValue("description", value)}
                  />
                </Card>
                <Card title="Terms" style={{ marginBottom: 15 }}>
                  <Editor
                    value={initialValues.terms}
                    onChange={(value) => setFieldValue("terms", value)}
                  />
                </Card>
                <GalleryBuilder
                  images={values.gallery}
                  onChange={(value) => {
                    setFieldValue("gallery", value);
                  }}
                  onRemove={(item) => {
                    setFieldValue(
                      "gallery",
                      values.gallery.filter((i) => i._id !== item._id)
                    );
                  }}
                />

                <Card title="Internal Note" style={{ marginTop: 15 }}>
                  <Editor
                    value={values.internalNote}
                    onChange={(value) => setFieldValue("internalNote", value)}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ padding: "16px 32px" }}
                  >
                    Aktualisieren
                  </Button>
                </Paper>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <NotifyNewProduct products={[data._id]} />
                </Paper>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <NewProductEmail product={data} />
                </Paper>
                <Paper style={{ overflow: "hidden" }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="points"
                            label="Klicks"
                            name="points"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="chfPrice"
                            label="CHF"
                            name="chfPrice"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="stock"
                            label="Bestand"
                            name="stock"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="type"
                            options={[
                              {
                                key: 1,
                                label: "Gutschein",
                                value: "Gutschein",
                              },
                              {
                                key: 2,
                                label: "Aktivität",
                                value: "Aktivität",
                              },
                            ]}
                            component={Autocomplete}
                            textFieldProps={{
                              label: "Typ",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="category"
                            options={categories}
                            component={Autocomplete}
                            textFieldProps={{
                              label: "Category",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {values?.type?.key === 2 && (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              name="canton"
                              options={Cantons2}
                              component={Autocomplete}
                              textFieldProps={{
                                label: "Kanton",
                                variant: "outlined",
                                style: { margin: 0 },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}

                      {values.type && values.type.value === "Gutschein" && (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="rabatCode"
                              label="Rabat Code"
                              name="rabatCode"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Publiziert
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="published"
                              id="published"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Product - Favorite
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="favoriteProduct"
                              id="favoriteProduct"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <Paper style={{ marginTop: 15 }}>
                  <ImageUpload
                    imagePreviewUrl={avatarPreview}
                    onImageChange={onAvatarChange}
                    id="avatar"
                    label={"Logo"}
                    onRemove={() => {
                      setAvatar(null);
                      setAvatarPreview(null);
                    }}
                  />
                </Paper>
                <Paper style={{ marginTop: 15 }}>
                  <ImageUpload
                    imagePreviewUrl={thumbnailPreview}
                    onImageChange={onThumbnailChange}
                    id="thumbnail"
                    label={"Thumbnail"}
                    onRemove={() => {
                      setThumbnail(null);
                      setThumbnailPreview(null);
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  } else {
    return <h1>This product doesn't exist</h1>;
  }
}
export default EditProduct;
