import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { DELETE_BONUS } from "../../redux/types";
function Delete({ jobId }) {
  const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    V3.delete(`/bonuses/${jobId}`)
      .then((res) => {
        dispatch({
          type: DELETE_BONUS,
          payload: jobId,
        });
        setOpen(false);
        enqueueSnackbar("Bonus deleted successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <span className="action fi" onClick={handleClickOpen}>
        <DeleteIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Bonus</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <DialogContentText>
            Are u sure u want to delete this bonus? Your wont be able to recover
            it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
