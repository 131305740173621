import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { V3 } from "../../lib/api";
import Loader from "../GlobalLoader";

function Edit({ data, handleUpdate }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = (values, form) => {
    V3.put(`potentials/${data._id}`, values).then((res) => {
      form.setSubmitting(false);
      handleUpdate(data._id, res.data.data);
    });
  };
  return (
    <>
      <Tooltip arrow title="Bearbeiten">
        <span className="action fi" onClick={() => setOpen(true)}>
          <FiEdit></FiEdit>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <Formik
          validationSchema={Yup.object().shape({
            package: Yup.number().nullable().required("Required"),
            activeServices: Yup.number().nullable().required("Required"),
            chance: Yup.number().nullable().required("Required"),
            available: Yup.date().nullable().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            package: data.package,
            activeServices: data.activeServices,
            chance: data.chance,
            available: data.available,
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Change Pipeline</DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="package"
                  label="Potential Paket"
                  name="package"
                  style={{ marginTop: 0 }}
                />
                <Field
                  component={TextField}
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="activeServices"
                  label="Potential ZDL"
                  name="activeServices"
                />
                <Field
                  component={TextField}
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="chance"
                  label="Chance"
                  name="chance"
                  style={{ marginBottom: 15 }}
                />
                <Field
                  component={KeyboardDatePicker}
                  label="Kommt in"
                  format="MM.yyyy"
                  inputVariant="outlined"
                  placeholder="04/2020"
                  name="date"
                  views={["month", "year"]}
                  fullWidth
                  value={values.available}
                  onChange={(date) => setFieldValue("available", date)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpen(false)}
                  color="primary"
                  variant="contained"
                >
                  close
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Edit;
