import React, { useState } from "react";
import JobClicksChart from "../Widgets/JobClicksChart";
import ActiveJobs from "../Widgets/ActiveJobs";
import moment from "moment";
import Filters from "./Filters";

function Jobs({ id }) {
  const [published, setPublished] = useState(true);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "year")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  return (
    <>
      <Filters
        published={published}
        setPublished={setPublished}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <JobClicksChart
        id={id}
        published={published}
        startDate={startDate}
        endDate={endDate}
      />
      <ActiveJobs companyId={id} published={published} />
    </>
  );
}

export default Jobs;
