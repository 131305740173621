import React from "react";
import clsx from "clsx";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import User from "./User";
import Notifications from "../Notifications/index";
import Search from "../Search/Default";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddTimeReport from "./AddTimeReport";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.text.primary,
  },
  hide: {
    display: "none",
  },

  search: {
    position: "relative",
    height: 40,
    width: "100%",
    maxWidth: 400,
    margin: "0 auto",
  },
  inputWrapper: {
    position: "relative",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: "absolute",
    height: 40,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    zIndex: 199,
  },
  inputRoot: {
    color: theme.palette.text.primary,
    width: "100%",
    height: 40,
    "&.Mui-focused ~ .suggestions": {
      display: "block !important",
    },
    "&.hover ~ .suggestions": {
      display: "block !important",
    },
  },
  inputInput: {
    padding: 0,
    height: 40,
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#5E5E5E",
    "&:hover": {
      backgroundColor: fade(theme.palette.text.primary, 0.15),
    },
    "&:focus": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  suggestions: {
    display: "none",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#5E5E5E",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default function MiniDrawer({ setTheme, theme, open, setOpen }) {
  const classes = useStyles();
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.up("lg"));

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: matches ? open : false,
      })}
    >
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: matches ? open : false,
              })}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {matches && <Search></Search>}
          </Grid>
          <Grid item xs={1}>
            <AddTimeReport />
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Notifications />
              <User setTheme={setTheme} theme={theme}></User>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
