/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import { Delete } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import Moment from "react-moment";
import { useDebounce } from "use-debounce";
import styled from "styled-components";
import moment from "moment";
import {
  TextField,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Cantons2 } from "../../data";
import { V3 } from "../../lib/api";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTheme } from "styled-components";
import {
  DELETE_INTERESTED,
  GET_INTERESTED,
  INTERESTED_LOADING,
} from "../../redux/types";
const ReactDatesWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  > span {
    position: absolute;
    left: 10px;
    top: -10px;
    z-index: 100;
  }

  .DateInput .DateInput_input {
    padding-bottom: 0px !important;
    padding-top: 25px !important;
    font-size: 16px;
    text-align: left !important;
    padding-left: 12px !important;
  }
`;

function Interested() {
  const confirm = useConfirm();

  const handleDelete = (id) => {
    confirm({
      title: `Löschen Sie dieses Interesse`,
      description:
        "Sind Sie sicher, dass Sie dies löschen möchten? Sie können es nicht wiederherstellen!",
    }).then(() => {
      dispatch({ type: INTERESTED_LOADING });
      V3.delete(`/interested/${id}`).then((res) =>
        dispatch({
          type: DELETE_INTERESTED,
          payload: id,
        })
      );
    });
  };

  const [search, setSearch] = useState(null);
  const [searchValue] = useDebounce(search, 600);
  const [tableState, setTableState] = useState({ filtered: [], page: 0 });
  const [startDate, setStartDate] = useState(moment("01-01-2022"));
  const [categories, setCategories] = React.useState([]);
  const [endDate, setEndDate] = useState(moment("12-31-2023"));
  const [isLink, setIsLink] = useState(false);
  const [linkedInAccounts, setLinkedInAccounts] = React.useState([]);
  const dispatch = useDispatch();

  const theme = useTheme();

  useEffect(() => {
    var queryString = tableState?.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${tableState.page + 1}&${
      search ? `search=${search.replace("&", "|")}&` : ""
    }isLink=${isLink}&${
      startDate ? `start=${moment(startDate).format("DD/MM/YYYY")}&` : ""
    }${
      endDate ? `end=${moment(endDate).format("DD/MM/YYYY")}&` : ""
    }${queryString}`;
    dispatch({ type: INTERESTED_LOADING });
    V3.get(`/interested${query}`).then((res) => {
      dispatch({ type: GET_INTERESTED, payload: res.data });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, startDate, endDate, tableState, isLink]);

  useEffect(() => {
    V3.get(`/categories/job`).then((response) => {
      setCategories(response.data);
    });
    V3.get(`/linkedin-accounts?limit=100000`).then((res) => {
      setLinkedInAccounts(
        res.data.data.map((el) => {
          return {
            key: el._id,
            label: el.accountName,
            value: el.accountName,
          };
        })
      );
    });
  }, []);

  const CategoriesDrop = categories.map((category) => {
    return {
      key: category.name,
      value: category.name,
      label: category.name,
    };
  });

  const { interested, data, loading } = useSelector((state) => state.interest);

  const checkNotes = (note) => {
    let noteString = note;
    let noteText = "";
    tableState.filtered.map((el) => {
      if (el.id === "notes") {
        noteText = el.value;
      }
    });

    if (noteText.length > 0) {
      const reg = new RegExp(noteText, "ig");

      noteString = noteString.replaceAll(
        reg,
        `<span style="background-color: ${theme.palette.orange.main};border-radius:5px;padding: 2px;padding-left: 4px;padding-right: 4px;color: ${theme.palette.orange.contrastText};">${noteText}</span>`
      );
    }
    if (search && search.length > 0) {
      const regSearch = new RegExp(search, "ig");
      noteString = noteString.replaceAll(
        regSearch,
        `<span style="background-color: ${theme.palette.orange.main};border-radius:5px;padding: 2px;padding-left: 4px;padding-right: 4px;color: ${theme.palette.orange.contrastText};">${search}</span>`
      );
    }
    return noteString;
  };
  const columns = [
    {
      id: "name",
      Header: "Name",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => (
        <span>{props.original.firstName + " " + props.original.lastName}</span>
      ),
    },
    {
      id: "jobTile",
      Header: "Berufsbezeichnung",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.jobTile}</span>,
    },
    {
      id: "desiredFunction",
      Header: "Wunschfunktion",
      filterable: true,
      accessor: (d) => d.desiredFunction,
      Cell: (props) => <span>{props.original.desiredFunction}</span>,
    },
    {
      id: "category",
      Header: "Kategorie",
      filterable: true,
      accessor: (d) => d.category,
      Cell: (props) => <span>{props.original.category}</span>,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value?.key ? value?.key : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? CategoriesDrop.find((canton) => canton.key === filter.value)
              : ""
          }
          options={CategoriesDrop}
          getOptionLabel={(option) => option?.label || ""}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },

    {
      id: "canton",
      Header: "Kanton",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.canton}</span>,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value?.key ? value?.key : "")}
          style={{ width: "100%" }}
          value={
            filter ? Cantons2.find((canton) => canton.key === filter.value) : ""
          }
          options={Cantons2}
          getOptionLabel={(option) => option?.label || ""}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "workload",
      Header: "Pensum",
      filterable: true,
      accessor: (d) => d.workload,
      Cell: (props) => <strong>{props.original?.workload || "-"}</strong>,
    },
    {
      id: "link",
      Header: "Link",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <a href={props.original.link} target="_blanc">
          <strong style={{ textTransform: "lowercase" }}>
            {props.original.link}
          </strong>
        </a>
      ),
    },
    {
      id: "account",
      Header: "Account",
      filterable: true,
      accessor: (d) => d.linkedInAccount,
      Cell: (props) => (
        <strong>{props.original?.linkedInAccount?.accountName || "-"}</strong>
      ),
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value?.key ? value?.key : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? linkedInAccounts.find((canton) => canton.key === filter.value)
              : ""
          }
          options={linkedInAccounts}
          getOptionLabel={(option) => option?.label || ""}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "notes",
      Header: "Anmerkungen",
      filterable: true,
      width: 300,
      accessor: (d) => d.notes,
      Cell: (props) => {
        if (props.original?.notes?.length > 0) {
          let tmp = "";
          props.original.notes.map((el) => {
            tmp += `${el} <br/>`;
          });

          return (
            <div
              dangerouslySetInnerHTML={{
                __html: `<p style="
    word-break: break-word;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
">${checkNotes(tmp)}</p>`,
              }}
              style={{
                overflowX: "scroll",
                height: 150,

                margin: 5,
                paddingLeft: 5,
                width: 300,
                wordBreak: "break-word",
                textTransform: "none",
              }}
            />
          );
        } else return "-";
      },
    },
    {
      id: "from",
      Header: "Erstellt am",
      filterable: false,
      accessor: (d) => d.workload,
      Cell: (props) => <Moment>{props.original?.createdAt}</Moment>,
    },
    {
      id: "from",
      Header: "Ab Datum verfügbar",
      filterable: true,
      accessor: (d) => d.workload,
      Cell: (props) =>
        props.original?.from ? <Moment>{props.original?.from}</Moment> : <>-</>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="from">Alt-Neu</option>
          <option value="-from">Neu-alt</option>
        </select>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Bearbeiten">
            <span className="action fi">
              <Link to={`/interested/edit/${props.original._id}`}>
                <FiEdit></FiEdit>
              </Link>
            </span>
          </Tooltip>
          <Tooltip title="Löschen">
            <Delete onClick={() => handleDelete(props.original._id)} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Interested" size={data.total} icon={<GroupIcon />}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLink}
                  onChange={(e) => setIsLink(e.target.checked)}
                  color="primary"
                />
              }
              label="Link"
            />
            <TextField
              placeholder="Suchen..."
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReactDatesWrapper style={{ float: "right" }}>
              <span>Erstellt am</span>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="From"
                    format="dd/MM/yyyy"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="To"
                    format="dd/MM/yyyy"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </ReactDatesWrapper>
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton
              style={{
                backgroundColor:
                  tableState.filtered.length > 0 || search?.length > 0
                    ? "#85ca00"
                    : "transparent",
                marginRight: 15,
              }}
              onClick={() => {
                setSearch("");
                setTableState({ filtered: [], page: 0 });
              }}
            >
              <ClearAllIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              component={Link}
              to="/interested/new"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
            >
              Neu
            </Button>
          </Grid>
        </Grid>
      </Header>
      <Table
        data={interested}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setTableState(state);
        }}
        noDataText="No Interested"
        filterable={true}
        defaultFiltered={[...tableState?.filtered]}
      />
    </React.Fragment>
  );
}

export default Interested;
