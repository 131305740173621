import React from "react";
import {
  DateRangePicker as DateRangePickerComp,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";

const staticRangesLabels = {
  Today: "Heute",
  Yesterday: "Gestern",
  "This Week": "Diese Woche",
  "Last Week": "Letzte Woche",
  "This Month": "Diesen Monat",
  "Last Month": "Letzten Monat",
};

const inputRangesLabels = {
  "days up to today": "Tage bis heute",
  "days starting today": "Tage ab heute",
};

function translateRange(dictionary) {
  return (item) =>
    dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
}

export default function DateRangePicker(props) {
  const sqStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const sqInputRanges = defaultInputRanges.map(
    translateRange(inputRangesLabels)
  );

  return (
    <DateRangePickerComp
      {...props}
      //   locale={sqLocale}
      staticRanges={sqStaticRanges}
      inputRanges={sqInputRanges}
    />
  );
}
