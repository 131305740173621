import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "../../GlobalLoader";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { DatePicker } from "material-ui-formik-components/DatePicker";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../../lib/api";
import { UPDATE_JOB } from "../../../redux/types";
import EditIcon from "@material-ui/icons/Edit";

function EditActiveSourcingOnboarding({ data }) {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const { job } = useSelector((state) => state.job);

  useEffect(() => {
    if (open) {
      V3.get(`/users?company=${job?.company?._id}&limit=400`).then(
        (response) => {
          const usersData = response.data.data.map((user) => {
            return {
              key: user?._id,
              value: user?.profile?.name,
              label: user?.profile?.name,
            };
          });

          setUsers(usersData);
        }
      );
    }
  }, [open, job]);

  const handleSubmit = (values, formApi) => {
    V3.put(`jobs/${job._id}/active-sourcing-onboarding/${data._id}`, {
      ...values,
      users: values.users.map((el) => el.key),
    }).then((res) => {
      setOpen(false);
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
      formApi.setSubmitting(false);
    });
  };

  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditIcon />
      </span>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update Active Sourcing Onboarding</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            users: Yup.array().nullable(),
            email: Yup.string()
              .email("Select a user or enter email")
              .nullable(),
            phone: Yup.string().nullable(),
            firstName: Yup.string().nullable(),
            lastName: Yup.string().nullable(),
            date: Yup.date().required("Required"),
          })}
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={{
            ...data,
            users: data?.users?.length
              ? data?.users.map((user) => {
                  return {
                    key: user?._id,
                    value: user?.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      name="users"
                      id="users"
                      options={users}
                      fullWidth
                      multiple
                      textFieldProps={{
                        label: "User",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      id="firstName"
                      label="First Name"
                      inputVariant="outlined"
                      fullWidth
                      type="text"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      id="lastName"
                      label="Last Name"
                      inputVariant="outlined"
                      fullWidth
                      type="text"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="email"
                      id="email"
                      label="Email"
                      inputVariant="outlined"
                      fullWidth
                      type="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="phone"
                      id="phone"
                      label="Phone"
                      inputVariant="outlined"
                      fullWidth
                      type="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={DatePicker}
                      name="date"
                      id="date"
                      label="Date"
                      inputVariant="outlined"
                      fullWidth
                      format="dd.MM.yyyy"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    close
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={values.files < 1}
                  >
                    submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default EditActiveSourcingOnboarding;
