import React, { useState } from "react";
import Card from "../../Card";
import Table from "../../Table";
import Actions from "../../Table/Actions";
import { V3 } from "../../../lib/api";
import DeleteIcon from "@material-ui/icons/Delete";
import New from "./New";
import Edit from "./Edit";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_CONTACTING,
  CONTACTING_LOADING,
  DELETE_CONTACTING,
  GET_CONTACTINGS,
  UPDATE_CONTACTING,
} from "../../../redux/types";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";

const IconWrapper = styled.div`
  margin-right: 0;
`;

const ContactedWrapper = styled.div`
  width: fit-content;
  padding: 6px 16px;
  margin-right: 30px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  border-radius: 16px;
  border: ${(props) => props.theme.palette.primary.main} solid;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

function Index({ jobId }) {
  const [open, setOpen] = useState(false);
  const [acc, setAcc] = useState(null);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const { toContact, contacted, loading, contactings, data } = useSelector(
    (state) => state.contacting
  );

  const dispatch = useDispatch();
  const max = new Date().getFullYear();
  const min = 2021;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ key: `${i}`, label: `${i}`, value: `${i}` });
  }

  const columns = [
    {
      id: "note",
      Header: "Reminder - Kandidatenpool und Interessentenpool nutzen",
      accessor: (d) => d.note,
      Cell: (props) => <strong>{props.original.note || "-"}</strong>,
    },
    {
      id: "year",
      Header: "Year",
      accessor: (d) => d.year,
      Cell: (props) => (
        <strong>{moment(props.original?.year).format("YYYY")}</strong>
      ),
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?.key : "")}
          style={{ width: "100%" }}
          options={years}
          value={
            filter ? years.find((week) => week?.key === filter?.value) : null
          }
          getOptionLabel={(option) => option.key}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "week",
      Header: "Week",
      accessor: (d) => d.week,
      Cell: (props) => <strong>{props.original.week}</strong>,
    },
    {
      id: "contacted",
      Header: "Contacted",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.contacted}</strong>,
    },

    {
      id: "toContact",
      Header: "To Contact",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.toContact}</strong>,
    },
    {
      id: "differenz",
      Header: "Differenz",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.toContact - props.original.contacted}</strong>
      ),
    },

    {
      id: "account",
      Header: "Account",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.linkedInAccount ? (
            <Tooltip title="Info">
              <ContactedWrapper
                onClick={() => {
                  setAcc(props.original.linkedInAccount);
                  setOpen(true);
                }}
              >
                {props.original.linkedInAccount.accountNumber}{" "}
                {props.original.linkedInAccount.accountName}
              </ContactedWrapper>
            </Tooltip>
          ) : (
            "-"
          )}
        </strong>
      ),
    },
    {
      id: "recruiter",
      Header: "Recruiter",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original?.recruiter?.profile?.name || "-"}</strong>
      ),
    },
    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <IconWrapper>
            <Edit contactingData={props.original} update={update} />
            <Tooltip title="Delete this week">
              <span className="action fi">
                <DeleteIcon onClick={() => handleDelete(props.original)} />
              </span>
            </Tooltip>
          </IconWrapper>
        </Actions>
      ),
    },
  ];

  const getData = (query) => {
    dispatch({
      type: CONTACTING_LOADING,
    });
    V3.get(`/contactings${query}`).then((res) => {
      dispatch({
        type: GET_CONTACTINGS,
        payload: res.data,
      });
    });
  };

  const add = (newData) => {
    dispatch({
      type: ADD_CONTACTING,
      payload: newData,
    });
  };
  const update = (newData) => {
    dispatch({
      type: UPDATE_CONTACTING,
      payload: newData,
    });
  };

  const handleDelete = (info) => {
    confirm({
      title: `Delete week ${info.week}`,
      description: `Are you sure you wont to delete this week.`,
    }).then(() => {
      V3.delete(`/contactings/${info._id}`)
        .then((res) => {
          dispatch({
            type: DELETE_CONTACTING,
            payload: { ...res.data, data: info },
          });
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong! Please try again latter.", {
            variant: "error",
          });
        });
    });
  };

  return (
    <Card
      title={`Contacted: ${contacted}   Differenz: ${toContact - contacted}`}
      style={{ marginTop: 30 }}
      button={<New add={add} job={jobId} />}
    >
      <Table
        data={contactings}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${
            state.page + 1
          }&job=${jobId}&showAllData=true&sort=-week&${queryString}`;
          getData(query);
        }}
        filterable={false}
        noDataText="Keine Daten"
        minRows={5}
        defaultFiltered={[
          {
            id: "year",
            value: `${new Date().getFullYear()}`,
          },
        ]}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Account Info</DialogTitle>
        <DialogContent>
          <div>
            <p>
              <strong style={{ marginRight: 15 }}>Account Number:</strong>{" "}
              {acc?.accountNumber}
            </p>
            <p>
              <strong style={{ marginRight: 15 }}>Account Name:</strong>{" "}
              {acc?.accountName}
            </p>
            <p>
              <strong style={{ marginRight: 15 }}>VPN:</strong> {acc?.vpn}
            </p>
            <p>
              <strong style={{ marginRight: 15 }}>Email:</strong> {acc?.email}
            </p>
            <p>
              <strong style={{ marginRight: 15 }}>Password:</strong>{" "}
              {acc?.password}
            </p>
          </div>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default Index;
