import * as React from "react";
const ForwardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke={props.stroke ? props.stroke : "#194061"}
      strokeWidth={1.5}
      d="M16.034 14.079c2.966.421 3-.834 3-1.5v-9c-.034-1.079-.051-1.492-.45-1.897M4.034 14.078c-3.034.422-3-1.078-3-1.5v-10c0-.666.466-1.578 2.5-1.5h14c.466 0 .72.268 1.05.604m-17.05-.104 8.5 6.5 8.55-6.396M6.034 19.578c-1-1.833-1.8-5.5 3-5.5h5m0 0-3.5-3m3.5 3-3.5 3"
    />
  </svg>
);
export default ForwardIcon;
