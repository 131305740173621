import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";

export default function LinkedInLinks({ data }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
      >
        Info
      </Button>
      <Dialog open={open} onClose={() => setOpen(true)} maxWidth="lg">
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>FirstName</TableCell>
                <TableCell>LastName</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Linkedin</TableCell>
              </TableRow>
              {data?.linkedinApplication?.map((el) => (
                <TableRow>
                  <TableCell>
                    <Moment>{el?.createdAt}</Moment>
                  </TableCell>
                  <TableCell>{el?.firstName}</TableCell>
                  <TableCell>{el?.lastName}</TableCell>
                  <TableCell>{el?.email}</TableCell>
                  <TableCell>{el?.phone}</TableCell>
                  <TableCell>
                    {el?.linkedinProfileLink ? (
                      <a
                        href={el?.linkedinProfileLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {el?.linkedinProfileLink}
                      </a>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
