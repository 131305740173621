import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import Moment from "react-moment";
import MeetingSVG from "./MeetingSVG";
import { Link } from "react-router-dom";
const NoMeeting = styled(Paper)`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  padding: 15px;
  min-height: 400px;
  svg {
    max-width: 300px;
  }
  p {
    font-weight: 600;
    font-size: 18px;
  }
`;

const Meeting = styled(Paper)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    left: 0;
  }

  &.phone {
    background-color: rgba(248, 120, 41, 0.2);
    &:before {
      background-color: #ff9d2c;
    }
  }
  &.chat {
    background-color: rgba(66, 135, 245, 0.2);

    &:before {
      background-color: #2ec6d5;
    }
  }

  h1 {
    margin: 0;
    font-size: 18px;
  }
  p {
    margin: 0;
    margin-top: 10;
    margin-bottom: 5;
  }
`;

const Icon = styled.a`
  float: right;
  background: #fff;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.phone {
    svg {
      color: #ff9d2c;
    }
  }
  &.chat {
    svg {
      color: #2ec6d5;
    }
  }
`;
function Meetings({ meetings }) {
  return (
    <React.Fragment>
      {meetings.length ? (
        <React.Fragment>
          <h1 style={{ marginTop: 0 }}>Termine</h1>
          {meetings.map((meeting) => {
            return (
              <Meeting
                className={meeting.type === "3" ? "chat" : "phone"}
                key={meeting._id}
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <span>
                      {meeting.type === "3" ? "Termin Offen" : "Nachfassen Am"}
                    </span>
                    <Link to={`/crm/company/${meeting.company._id}`}>
                      <h1>{meeting.company.name}</h1>
                    </Link>
                    <p>
                      <Moment format="DD.MM.YYYY hh:mm">{meeting.date}</Moment>
                    </p>
                    <strong>
                      {meeting.description ? meeting.description : "No notes"}
                    </strong>
                  </Grid>
                  <Grid item xs={2}>
                    <Icon
                      className={meeting.type === "3" ? "chat" : "phone"}
                      href={`tel:+${meeting.company.phone}`}
                    >
                      <PhoneEnabledIcon />
                    </Icon>
                  </Grid>
                </Grid>
              </Meeting>
            );
          })}
        </React.Fragment>
      ) : (
        <NoMeeting>
          <MeetingSVG />
          <p>Keine Termine heute</p>
        </NoMeeting>
      )}
    </React.Fragment>
  );
}

export default Meetings;
