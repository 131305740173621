import React, { useState } from "react";
import Card from "../Card";
import Table from "../Table";
import Actions from "../Table/Actions";
import Moment from "react-moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Upload from "../fileUpload";
import { useSnackbar } from "notistack";
import { V3 } from "../../lib/api";
import { useConfirm } from "material-ui-confirm";

function UserFiles({ user, files, setFiles }) {
  const [open, setOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const handleClose = () => {
    setOpen(false);
    setUploadedFiles([]);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const res = await V3.post(`users/${user}/files`, formData);

      setFiles(res?.data?.data || []);
      enqueueSnackbar("Files has been uploaded", { variant: "success" });
      handleClose();
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDelete = (id) => {
    confirm({
      title: "Delete File",
      description: "Are you sure you wont to delete this file",
    }).then(async () => {
      try {
        const res = await V3.delete(`users/${user}/files/${id}`);

        setFiles(res?.data?.data || []);
        enqueueSnackbar("Files has been deleted", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    });
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`${props.original.url} `}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props?.original.fileName}
          </a>
        </strong>
      ),
    },

    {
      id: "createdAt",
      Header: () => <span style={{ textAlign: "right" }}>Datum</span>,
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },

    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Delete this alert">
            <DeleteIcon onClick={() => handleDelete(props.original._id)} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <>
      {" "}
      <Card
        title={"User Files"}
        button={
          <Button
            onClick={() => setOpen(true)}
            color="primary"
            variant="outlined"
          >
            Add
          </Button>
        }
      >
        <Table
          data={files}
          columns={columns}
          loading={false}
          page={1}
          pages={1}
          filterable={true}
          onFetchData={() => {}}
          noDataText="No Data"
          minRows={5}
        />
      </Card>
      <Dialog open={open} setOpen={() => handleClose()} fullWidth maxWidth="sm">
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <Upload handleChange={(values) => setUploadedFiles(values)} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            variant="contained"
          >
            close
          </Button>
          <Button
            onClick={() => handleUpload()}
            color="primary"
            variant="contained"
          >
            upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserFiles;
