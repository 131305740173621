import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Select } from "material-ui-formik-components/Select";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Loader from "../GlobalLoader";
import { routes } from "../../utils/data";
import SelectRoutes from "../RoutesTemplate/SelectRoutes";
import { useEffect } from "react";
import { V3 } from "../../lib/api";
import { Autocomplete } from "material-ui-formik-components";
import { ADD_USER } from "../../redux/types";
function New() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [routesTemplates, setRoutesTemplates] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    V3.get(`routes-template?limit=1000`).then((res) => {
      setRoutesTemplates(
        res.data.data.map((el) => {
          return {
            key: el._id,
            value: el._id,
            label: el.title,
            routes: el?.routes,
          };
        })
      );
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, formikApi) => {
    const data = {
      name: values.firstname + " " + values.lastname,
      firstname: values.firstname,
      lastname: values.lastname,

      email: values.email,
      role: values.role,
      routes: values.routes,
      routesTemplate: values.routesTemplate?.key || null,
    };
    setMessage(null);

    V3.post(`/users`, data)
      .then((res) => {
        formikApi.setSubmitting(false);
        setOpen(false);
        dispatch({
          type: ADD_USER,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        formikApi.setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Neuer Benutzer
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Formik
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
          })}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{
            role: "employee",
            routes: routes,
          }}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Neuen Benutzer erstellen</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Vorname"
                      name="firstname"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Nachname"
                      name="lastname"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={Select}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Role"
                      name="role"
                      style={{ margin: 0 }}
                      options={[
                        {
                          key: "admin",
                          value: "admin",
                          label: "Admin",
                        },
                        {
                          key: "employee",
                          value: "employee",
                          label: "Employee",
                        },
                        {
                          key: "editor",
                          value: "editor",
                          label: "Editor",
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="E-Mail"
                      name="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Role"
                      name="routesTemplate"
                      onChange={(e, val) => {
                        setFieldValue("routesTemplate", val);
                        setFieldValue("routes", val?.routes);
                      }}
                      style={{ margin: 0 }}
                      options={routesTemplates}
                      textFieldProps={{
                        label: "Template",
                        variant: "outlined",
                        style: { margin: 0, marginBottom: 15 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectRoutes
                      routes={values.routes}
                      setRoutes={(val) => setFieldValue("routes", val)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Schließen
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  Erstellen
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default New;
