import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent } from "./Dialog";

const ErrorDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent align="center">
        <h1 style={{ color: "red", textDecoration: "bold" }}>
          Invoice not created,
          <br /> please try again.
        </h1>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
