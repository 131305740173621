import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Button, Link } from "@material-ui/core";
const FilesUploaded = styled.div`
  margin-top: 10px;
  padding: 20px 0;
  padding: 20px;
  border-radius: 5px;
  .info--marker {
    float: right;
    margin-right: 10px;
    cursor: pointer;
    svg {
      transform: scale(1.8);
    }
  }
  h4 {
    float: left;
    font-size: 18px;
  }

  ul {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    list-style: none;

    li {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      padding: 0 20px;
      transition: 0.5s ease;
      cursor: pointer;
      background: rgba(76, 84, 90, 0.1);
      margin-bottom: 10px;
      border-radius: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        width: 60%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .remove--btn {
        text-align: right;
        width: 10%;

        svg {
          transform: scale(1.2);
        }

        &:hover {
          svg {
            transform: scale(1.4);
          }
        }
      }
      span.size {
        width: 30%;
      }

      &:hover {
        background: rgba(76, 84, 90, 0.2);
      }
    }
  }
`;
const FileUploadWrapper = styled.div`
  .dropzone {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: #fff; */
    cursor: pointer;
    box-sizing: border-box;

    .button {
      margin-top: 20px;
    }

    svg {
      width: 60px;
      height: 60px;
    }
  }
`;
function FileUpload(props) {
  const [myFiles, setMyFiles] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
    props.handleChange([...myFiles, ...acceptedFiles]);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    props.handleChange(newFiles);
  };

  const files = myFiles.map((file) => (
    <CSSTransition key={file.path} timeout={500} classNames="move">
      <li key={file.path}>
        <strong>{file.name}</strong>
        <span className="size">{file.size} bytes</span>
        <span className="remove--btn" onClick={removeFile(file)}>
          <AiOutlineDelete></AiOutlineDelete>
        </span>
      </li>
    </CSSTransition>
  ));

  return (
    <Card variant="outlined" align="center">
      <CardActionArea>
        <FileUploadWrapper>
          <div
            {...getRootProps({
              className: `dropzone ${isDragActive ? "active" : ""}`,
            })}
          >
            <input {...getInputProps()} />
            <FaCloudUploadAlt></FaCloudUploadAlt>
            {isDragActive ? (
              <p style={{ textAlign: "center" }}>
                Legen Sie die Dateien hier ab ...
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                Ziehen Sie einige Dateien per Drag 'n' Drop hierher oder klicken
                Sie, um Dateien auszuwählen.
              </p>
            )}

            <span className="button outline">Dokumente suchen</span>
          </div>
        </FileUploadWrapper>
      </CardActionArea>
      {props.flyer ? (
        <>
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            href={props.flyer}
            style={{ width: "45%", margin: 5 }}
            target="_blank"
            underline="none"
          >
            Download Flyer
          </Button>
          <Button
            variant="outlined"
            fullWidth
            target="_blank"
            style={{
              margin: 5,
              width: "45%",
              color: "red",
              borderColor: "red",
            }}
            onClick={() => props.handleDelete()}
            underline="none"
          >
            Delete Flyer
          </Button>
        </>
      ) : null}
      {files.length > 0 && (
        <FilesUploaded>
          <div className="head">
            <h4>Files</h4>
          </div>
          <ul>
            <TransitionGroup>{files}</TransitionGroup>
          </ul>
        </FilesUploaded>
      )}
    </Card>
  );
}

export default FileUpload;
