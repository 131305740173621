import React, { useState } from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../../lib/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Alert from "@material-ui/lab/Alert";
import Loader from "../../components/GlobalLoader";
import Editor from "../../components/Editor";

function UpdateCallTemplate() {
  const [data, setData] = useState({});
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [template, setTemplate] = useState(null);

  const confirm = useConfirm();

  const handleDelete = (id) => {
    confirm({
      title: `Delete Template`,
      description: `Are you sure you wont to delete this template.`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/notes-template/${id}`).then(() => {
        setQuiz(quiz.filter((el) => el._id !== id));
        setLoading(false);
      });
    });
  };

  const handleSubmit = async (values, formApi) => {
    try {
      const res = await V3.post("notes-template", values);
      setQuiz([...quiz, res.data.data]);
      setOpen(false);
    } catch (error) {
      formApi.setSubmitting(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const handleUpdate = async (values, formApi) => {
    try {
      const res = await V3.put(`notes-template/${template._id}`, values);
      const newData = res.data.data;
      setQuiz(
        quiz.map((el) => {
          if (el._id === newData._id) {
            return newData;
          } else {
            return el;
          }
        })
      );
      setOpenEdit(false);
      setTemplate(null);
    } catch (error) {
      formApi.setSubmitting(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original?.title}</strong>,
    },

    {
      id: "createdAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      filterable: false,
      width: 200,
      Cell: (props) => (
        <div>
          <Moment>{props.original.createdAt}</Moment>
        </div>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Template">
            <span>
              <EditIcon
                onClick={() => {
                  setTemplate(props.original);
                  setOpenEdit(true);
                }}
              />
            </span>
          </Tooltip>

          <Tooltip title="Delete this Template">
            <span
              className="action fi"
              onClick={() => handleDelete(props.original._id)}
            >
              <DeleteIcon />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Notes Template">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Add
        </Button>
      </Header>
      <Table
        data={quiz}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setLoading(true);
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          V3.get(`/notes-template${query}`)
            .then((res) => {
              setData(res.data);
              setQuiz(res.data.data);
              setLoading(false);
            })
            .catch(() => {
              setData({});
              setQuiz([]);
              setLoading(false);
            });
        }}
        noDataText="No Data"
        filterable={false}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {" "}
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{ description: "" }}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Create new template</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Title"
                      name="title"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Editor
                      value={values.description}
                      onChange={(value) => setFieldValue("description", value)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        maxWidth="sm"
        fullWidth
      >
        {" "}
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          onSubmit={handleUpdate}
          initialValues={{
            description: template?.description,
            title: template?.title,
          }}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Update template</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Title"
                      name="title"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Editor
                      value={values.description}
                      onChange={(value) => setFieldValue("description", value)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenEdit(false)} color="primary">
                  Close
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default UpdateCallTemplate;
