import {
  GET_PRODUCTS,
  GET_PRODUCT,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  PRODUCTS_LOADING,
  GET_ONLY_PRODUCTS,
  DUPLICATE_PRODUCT,
  UPDATE_PRODUCT,
} from "../types";

const initialState = {
  products: [],
  data: [],
  product: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((el) => {
          if (el._id === action.payload._id) {
            return action.payload;
          } else {
            return el;
          }
        }),
        product: action.payload,
        loading: false,
      };
    case GET_ONLY_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload.data,
        loading: false,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.payload
        ),
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [action.payload, ...state.products],
      };

    case PRODUCTS_LOADING:
      return {
        ...state,
        product: null,
        loading: true,
      };
    case DUPLICATE_PRODUCT:
      const index = state.products.findIndex((el) => el._id === action.id);
      const newProducts = [
        ...state.products.slice(0, index),
        action.payload,
        ...state.products.slice(index, state.products.length - 1),
      ];
      return {
        ...state,
        products: newProducts,
        loading: false,
      };
    default:
      return state;
  }
}
