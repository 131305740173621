import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Grid, InputAdornment, IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { V3 } from "../../lib/api";
import Loader from "../GlobalLoader";
function AddCompanyUser(props) {
  const { openDefault, companyId, handleAdd } = props;
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleModal = () => {
    setOpen(!open);
  };
  const handleAddUser = (values, formApi) => {
    V3.post(`/companies/add-user/${companyId}`, values).then((res) => {
      enqueueSnackbar("Company user created successfully", {
        variant: "success",
      });
      handleAdd(res.data.data);
      formApi.setSubmitting(false);
      setOpen(false);
    });
  };

  useEffect(() => {
    if (openDefault) {
      setOpen(openDefault);
    }
  }, [openDefault]);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleModal}>
        Add User
      </Button>
      <Dialog open={open} onClose={handleModal}>
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Please enter a valid email")
              .required("Required"),
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            phone: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
            confirm: Yup.string().required("Required"),
          })}
          onSubmit={handleAddUser}
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
            phone: "",
            password: "",
            confirm: "",
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Add Company User</DialogTitle>
              <DialogContent style={{ maxWidth: 600, minWidth: 600 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstname"
                      label="Firstname"
                      name="firstname"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastname"
                      label="Lastname"
                      name="lastname"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Mobile"
                      name="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      label="Passwort"
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type={showConfirmPassword ? "text" : "password"}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="confirm"
                      label="Passwort bestätigen"
                      name="confirm"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "15px 24px" }}>
                <Button onClick={handleModal} color="primary">
                  Schließen
                </Button>
                <Button color="primary" variant="outlined" type="submit">
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default AddCompanyUser;
