import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
const PageHeader = styled(Grid)`
  margin-bottom: 15px;
  h1 {
    margin: 0;
  }

  @media (max-width: 960px) {
    h1 {
      margin: 0;
      text-align: center;
    }

    .MuiButton-root {
      width: 100%;
      display: block;
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 10px;
      text-align: center !important;
    }
  }
`;

const Title = styled(Grid)`
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    justify-content: center;
    margin-bottom: 30px;
  }
`;
const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @media (max-width: 960px) {
    justify-content: center;
    padding-right: 0;

    > div {
      flex-direction: column;
      justify-content: center;
      > div {
        margin-left: 0;
        margin-bottom: 15px;
      }
      > button {
        display: none;
      }
    }
  }
`;
export default function Header({ title, children, size, icon }) {
  return (
    <PageHeader container>
      <Title items xs={12} md={3}>
        <h1>{title}</h1>
        {size ? (
          <Chip label={size} style={{ marginLeft: 15 }} />
        ) : (
          <React.Fragment />
        )}
        {icon && icon}
      </Title>
      <ButtonWrapper items xs={12} md={9}>
        {children}
      </ButtonWrapper>
    </PageHeader>
  );
}
