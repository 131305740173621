import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../components/GlobalLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "../../components/Table";
import { useEffect } from "react";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Header from "../../components/Page/Header";
import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function Categories() {
  const [open, setOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(false);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [category, setCategory] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    V3.get(`categories/job?limit=999`).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const _handleSubmit = (values, formikApi) => {
    setMessage(null);

    V3.post(`categories/job`, values)
      .then((res) => {
        setData([res.data.data, ...data]);
        formikApi.setSubmitting(false);
        formikApi.resetForm({});
        setMessage(null);
        enqueueSnackbar("Category created successfully", {
          variant: "success",
        });
      })

      .catch((err) => {
        formikApi.setSubmitting(false);
        setMessage(err?.response?.data?.message);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };
  const _handleUpdate = (values, formikApi) => {
    V3.put(`categories/job/${category._id}`, values)
      .then((res) => {
        setData(
          data.map((el) => {
            if (el._id === res?.data?.data?._id) {
              return res.data.data;
            } else {
              return el;
            }
          })
        );
        enqueueSnackbar("Category updated successfully", {
          variant: "success",
        });

        formikApi.setSubmitting(false);
        setOpenUpdate(false);
      })

      .catch((err) => {
        formikApi.setSubmitting(false);
        setUpdateMessage(err?.response?.data?.message);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

  const handleDelete = () => {
    setLoading(true);

    V3.delete(`categories/job/${currentCategory}`)
      .then(() => {
        setData(data.filter((el) => el._id !== currentCategory));
        enqueueSnackbar("Category deleted successfully", {
          variant: "success",
        });
        setOpen(false);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCurrentCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      id: "name",
      Header: "Kategorie",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.de?.name
            ? props.original?.de?.name
            : props.original?.en?.name || "-"}
        </strong>
      ),
    },
    {
      id: "description",
      Header: "Beschreibung",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.de?.description
            ? props.original?.de?.description
            : props.original?.en?.description || "-"}
        </strong>
      ),
    },
    {
      id: "icon",
      Header: "Icon",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.de?.icon
            ? props.original?.de?.icon
            : props.original?.en?.icon || "-"}
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          <IconButton
            onClick={() => {
              setCategory(props.original);
              setOpenUpdate(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleClickOpen(props.original._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Job categories" />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {message && (
            <Alert severity="error" style={{ marginBottom: 15 }}>
              {message}
            </Alert>
          )}
          <Paper style={{ padding: 15 }}>
            <Formik
              validationSchema={Yup.object().shape({
                en: Yup.object({
                  name: Yup.string().nullable().required("Required"),
                  description: Yup.string().nullable(),
                  icon: Yup.string().nullable(),
                }),
                de: Yup.object({
                  name: Yup.string().nullable().required("Required"),
                  description: Yup.string().nullable(),
                  icon: Yup.string().nullable(),
                }),
              })}
              onSubmit={_handleSubmit}
              initialValues={{
                en: { name: "", description: "", icon: "" },
                de: { name: "", description: "", icon: "" },
                val: 0,
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  {isSubmitting && <Loader></Loader>}
                  <Paper style={{ marginBottom: 30 }}>
                    <Tabs
                      indicatorColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      value={values.val}
                      onChange={(e, val) => setFieldValue("val", val)}
                    >
                      <Tab label="German" {...a11yProps(0)} />
                      <Tab label="English" {...a11yProps(1)} />
                    </Tabs>
                  </Paper>
                  <TabPanel value={values.val} index={0}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="de.name"
                      label="Kategorie"
                      name="de.name"
                      style={{ marginTop: 0 }}
                    />
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="de.icon"
                      label="Icon"
                      name="de.icon"
                    />

                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="de.description"
                      label="Beschreibung"
                      name="de.description"
                      multiline
                      rows={6}
                    />
                  </TabPanel>
                  <TabPanel value={values.val} index={1}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en.name"
                      label="Kategorie"
                      name="en.name"
                      style={{ marginTop: 0 }}
                    />
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en.icon"
                      label="Icon"
                      name="en.icon"
                    />

                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en.description"
                      label="Beschreibung"
                      name="en.description"
                      multiline
                      rows={6}
                    />
                  </TabPanel>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 30 }}
                  >
                    Create category
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Table
            data={data}
            columns={columns}
            loading={loading}
            noDataText="No categories"
            manual={false}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kategorie löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wirklich diese Kategorie löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)}>
        <DialogTitle>Kategorie löschen</DialogTitle>

        <Formik
          validationSchema={Yup.object().shape({
            en: Yup.object({
              name: Yup.string().nullable().required("Required"),
              description: Yup.string().nullable(),
              icon: Yup.string().nullable(),
            }),
            de: Yup.object({
              name: Yup.string().nullable().required("Required"),
              description: Yup.string().nullable(),
              icon: Yup.string().nullable(),
            }),
          })}
          onSubmit={_handleUpdate}
          initialValues={{
            en: category?.en,
            de: category?.de,
            val: 0,
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent>
                {updateMessage && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {updateMessage}
                  </Alert>
                )}
                {isSubmitting && <Loader></Loader>}
                <Paper style={{ marginBottom: 30 }}>
                  <Tabs
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    value={values.val}
                    onChange={(e, val) => setFieldValue("val", val)}
                  >
                    <Tab label="German" {...a11yProps(0)} />
                    <Tab label="English" {...a11yProps(1)} />
                  </Tabs>
                </Paper>
                <TabPanel value={values.val} index={0}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="de.name"
                    label="Kategorie"
                    name="de.name"
                    style={{ marginTop: 0 }}
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="de.icon"
                    label="Icon"
                    name="de.icon"
                  />

                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="de.description"
                    label="Beschreibung"
                    name="de.description"
                    multiline
                    rows={6}
                  />
                </TabPanel>
                <TabPanel value={values.val} index={1}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="en.name"
                    label="Kategorie"
                    name="en.name"
                    style={{ marginTop: 0 }}
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="en.icon"
                    label="Icon"
                    name="en.icon"
                  />

                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="en.description"
                    label="Beschreibung"
                    name="en.description"
                    multiline
                    rows={6}
                  />
                </TabPanel>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenUpdate(false)}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Update category
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default Categories;
