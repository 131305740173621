import React, { useState } from "react";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import Header from "../../components/Page/Header";

import { Button, Grid } from "@material-ui/core";
import { V3 } from "../../lib/api";
import LaunchIcon from "@material-ui/icons/Launch";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

export default function InactiveRecruiters() {
  const [recruiters, setRecruiters] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(3, "months"));
  const [endDate, setEndDate] = useState(moment());

  const columns = [
    {
      id: "recruiter",
      Header: "Recruiter",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>
          <Link to={`/recruiter/${original ? original._id : ""}`}>
            {original && original.user
              ? original.user.name
                ? original.user.profile.name
                : `${original.user.profile.firstname || ""} ${
                    original.user.profile.lastname || ""
                  }`
              : "User Deleted"}{" "}
          </Link>
        </strong>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong style={{ textTransform: "none" }}>
          {original && original.user ? original.user.email : "-"}
        </strong>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong style={{ textTransform: "nome" }}>
          <Link
            to={`/recruiter/${original ? original._id : ""}`}
            style={{ textTransform: "nome" }}
          >
            <LaunchIcon />
          </Link>
        </strong>
      ),
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header title="Inactive Recruiters">
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="From"
            format="dd/MM/yyyy"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{ marginRight: 15 }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="To"
            format="dd/MM/yyyy"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{ marginRight: 15 }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              V3.get(`shared-links/recruiters/export${query}`).then((res) => {
                window.open(res.data.data.url, "_blank");
                setLoading(false);
              });
            }}
          >
            Export
          </Button>
        </Header>
        <Table
          data={recruiters}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            const queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let queryS = `?page=${state.page + 1}&${queryString}`;

            setLoading(true);
            V3.get(`shared-links/recruiters${queryS}`).then((res) => {
              setQuery(queryS);
              setData(res.data);
              setRecruiters(res.data.data);
              setLoading(false);
            });
          }}
          noDataText="No shared links"
          defaultFiltered={[
            {
              id: "startDate",
              value: moment(startDate).format("DD/MM/YYYY"),
            },
            {
              id: "endDate",
              value: moment(endDate).format("DD/MM/YYYY"),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
