import React, { useState } from "react";
import Card from "../../Card";
import { Button } from "@material-ui/core";
import { V3 } from "../../../lib/api";

import Table from "../../Table";
import Actions from "../../Table/Actions";

import LaunchIcon from "@material-ui/icons/Launch";
import New from "./New";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";

function ActiveSourcingOnBoarding({ id }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const confirm = useConfirm();

  const handleSubmit = (values) => {
    const formData = new FormData();

    values.files.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("job", id);

    V3.post(`/active-sourcing-files`, formData).then((res) => {
      setChats([res.data.data, ...chats]);
      setOpen(false);
    });
  };

  const handleDelete = (info) => {
    confirm({
      title: `Delete file`,
      description: "Are you sure You wont to delete this file",
    }).then(() => {
      V3.delete(`/active-sourcing-files/${info._id}`).then((res) => {
        const tmp = chats.filter((job) => job._id !== info._id);
        setChats(tmp);
      });
    });
  };

  const columns = [
    {
      id: "image",
      Header: "Image",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original?.name || "-"}</strong>,
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <a
            href={`${props.original?.file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LaunchIcon />
          </a>
          <span onClick={() => handleDelete(props.original)}>
            <DeleteIcon />
          </span>
        </Actions>
      ),
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/active-sourcing-files${query}`).then(({ data }) => {
      setData(data);
      setChats(data.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        title="Files"
        button={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      >
        <div>
          <Table
            data={chats}
            columns={columns}
            loading={loading}
            page={!data.pagination ? 0 : data.pagination.current - 1}
            pages={!data.pagination ? 1 : data.pagination.total}
            onFetchData={(state) => {
              var queryString = state.filtered
                .map((key) => key.id + "=" + key.value)
                .join("&");
              let query = `?page=${
                state.page + 1
              }&job=${id}&limit=5&${queryString}`;
              handleFetch(query);
            }}
            filterable={false}
            minRows={1}
            noDataText="No Data"
          />
        </div>
      </Card>
      <New open={open} setOpen={setOpen} handleSubmit={handleSubmit} />
    </>
  );
}

export default ActiveSourcingOnBoarding;
