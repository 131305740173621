import {
  green,
  deepOrange,
  lightBlue,
  deepPurple,
  lime,
} from "@material-ui/core/colors";

export const priorities = [
  { id: 1, text: "Termin Offen", color: lightBlue },
  { id: 2, text: "Nachfassen Am", color: deepOrange },
  { id: 3, text: "Aufgaben", color: green },
  { id: 4, text: "Kundenpflege", color: deepPurple },
  { id: 5, text: "Day off", color: lime },
];
