import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  padding: 60px 30px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  border: 2px dashed #5e85a1;
  background-color: #e8eef2;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export default function Empty({ isDragActive, open }) {
  return (
    <Wrapper onClick={open} className={isDragActive ? "show" : ""}>
      <CloudUploadOutlinedIcon />
      <p>
        <strong>Drop ketu</strong> per te ngarkuar dokumentin
      </p>
      <p>PNG, JPG or GIF (max. 800x400px)</p>
    </Wrapper>
  );
}
