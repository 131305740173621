import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { DASHBOARD } from "../../../config";
const Avatar = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  margin-top: -40px;
  margin-left: 15px;
  img {
    width: 100%;
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard({ data, onChange, handleClose }) {
  const classes = useStyles();

  const handleSelect = () => {
    onChange(data);
    handleClose();
  };
  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia
        style={{ borderBottom: "1px solid #ccc" }}
        className={classes.media}
        image={
          data?.images?.coverX400
            ? data.images.coverX400
            : DASHBOARD + "/images/not-found.png"
        }
        title={data.name}
      />
      <Avatar>
        <img
          src={
            data?.images?.avatarX128
              ? data.images.avatarX128
              : DASHBOARD + "/images/not-found.png"
          }
          alt={data.name}
        />
      </Avatar>
      <CardContent style={{ padding: "5px 15px" }}>
        <h3
          style={{
            fontSize: 18,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {data?.name}
        </h3>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={handleSelect}
        >
          Select
        </Button>
      </CardActions>
    </Card>
  );
}
