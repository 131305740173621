import moment from "moment";
import React, { useState } from "react";
import DateRangePicker from "../../DatePicker/DateRangePicker";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import styled from "styled-components";
import { Button, Dialog, Grid, Paper } from "@material-ui/core";

const Wrapper = styled.div`
  margin-bottom: 15px;

  .date-filter {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px 16px;
    color: #667085;
    &:hover {
      background-color: #fafafa;
    }
  }
`;
const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 5px;
  width: max-content;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

function Filters({
  published,
  setPublished,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ButtonsWrapper>
            <Button
              onClick={() => {
                setPublished(false);
              }}
              variant="contained"
              className={`custom-btn ${!published ? "active" : "not-Active"}`}
              color="primary"
            >
              Archiviert
            </Button>
            <Button
              onClick={() => {
                setPublished(true);
              }}
              variant="contained"
              className={`custom-btn ${published ? "active" : "not-Active"}`}
              color="primary"
            >
              Nicht archiviert
            </Button>
          </ButtonsWrapper>
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <Button
            variant="outlined"
            onClick={() => setOpen(true)}
            startIcon={<CalendarIcon />}
            className="date-filter"
          >
            Datum Auswählen
          </Button>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            title="Eksporto"
            maxWidth="sm"
          >
            <Wrapper>
              <Paper
                variant="outlined"
                style={{
                  overflow: "hidden",
                  marginBottom: 15,
                }}
              >
                <DateRangePicker
                  onChange={(item) => {
                    setStart(item?.selection?.startDate);
                    setEnd(item?.selection?.endDate);
                  }}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={[
                    {
                      startDate: new Date(moment(start).format()),
                      endDate: new Date(moment(end).format()),
                      key: "selection",
                    },
                  ]}
                  direction="horizontal"
                />
              </Paper>
              <div style={{ padding: "0 15px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => setOpen(false)}
                    >
                      Schließen
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setStartDate(start);
                        setEndDate(end);
                        setOpen(false);
                      }}
                    >
                      Suchen
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Wrapper>
          </Dialog>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Filters;
