import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch, FormControlLabel } from "@material-ui/core";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import { V3 } from "../../../lib/api";
import EditIcon from "@material-ui/icons/Edit";
import Editor from "../../../components/Editor";

const Wrapper = styled.div`
  .MuiTypography-body1 {
    padding-left: 15px;
  }
`;

function Delete(props) {
  const [open, setOpen] = React.useState(false);

  const { profile } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikAPi) => {
    const data = {
      _id: profile._id,
      profile: values.profile,
      phone: values.phone,
      name: values.name,
      email: values.email,
      sendEmail: values.sendEmail,
      description: values.description,
    };

    V3.put(`/jobs/${props.jobId}/prospect-profiles/${profile._id}`, data).then(
      () => {
        formikAPi.setSubmitting(false);
        props.handleUpdate(data);
        setOpen(false);
      }
    );
  };

  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <EditIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Bearbeiten Sie diese eingereichte Interessentenprofile
        </DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              profile: Yup.string().required("Required"),
              name: Yup.string().required("Required"),
              phone: Yup.string().required("Required"),
              sendEmail: Yup.boolean().required("Required"),
              email: Yup.string().email().required("Required"),
              description: Yup.string(),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              sendEmail: profile?.sendEmail,
              email: profile?.email,
              profile: profile?.profile,
              phone: profile?.phone,
              name: profile?.name,
              description: profile?.description,
            }}
          >
            {({
              isSubmitting,
              dirty,
              setFieldValue,
              initialValues,
              values,
            }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="name"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Tel."
                  name="phone"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Profile"
                  name="profile"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  name="email"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Wrapper>
                  <FormControlLabel
                    style={{ paddingLeft: 15, marginTop: 15, marginBottom: 15 }}
                    control={
                      <Switch
                        checked={values.sendEmail}
                        onChange={(e) =>
                          setFieldValue("sendEmail", e.target.checked)
                        }
                        color="secondary"
                        style={{ marginRight: 15 }}
                      />
                    }
                    label="Send Email"
                  />
                </Wrapper>
                <Editor
                  value={values.description}
                  onChange={(val) => setFieldValue("description", val)}
                />

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="Submit">
                    update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
