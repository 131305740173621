import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
const defaultButton = (props) => (
  <Button
    {...props}
    variant="outlined"
    style={{ minWidth: 45, marginRight: 3 }}
  >
    {props.children}
  </Button>
);

export default class Paginationn extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [
          1,
          ...this.addToPrevPage(page),
          page - 1,
          page,
          page + 1,
          ...this.addToNextPage(page, total),
          total,
        ];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, ...this.addToNextPage(0, total), total];
      }
    }
  };

  addToNextPage = (pageNumber, total) => {
    const pagesArray = [];
    if (total > pageNumber + 10) {
      pagesArray.push(pageNumber + 10);
    }
    if (total > pageNumber + 25) {
      pagesArray.push(pageNumber + 25);
    }
    if (total > pageNumber + 50) {
      pagesArray.push(pageNumber + 50);
    }

    return pagesArray;
  };
  addToPrevPage = (pageNumber) => {
    const pagesArray = [];
    if (0 < pageNumber - 50) {
      pagesArray.push(pageNumber - 50);
    }
    if (0 < pageNumber - 25) {
      pagesArray.push(pageNumber - 25);
    }
    if (0 < pageNumber - 10) {
      pagesArray.push(pageNumber - 10);
    }

    return pagesArray;
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <div style={{ padding: 15 }}>
        <PageButtonComponent
          onClick={() => {
            if (activePage === 1) return;
            this.changePage(activePage - 1);
          }}
          disabled={activePage === 1}
        >
          <ChevronLeftIcon />
        </PageButtonComponent>
        {visiblePages.map((page, index, array) => {
          return (
            <PageButtonComponent
              key={page}
              variant={activePage === page ? "contained" : "default"}
              color={activePage === page ? "primary" : "inherit"}
              onClick={this.changePage.bind(null, page)}
            >
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </PageButtonComponent>
          );
        })}
        <PageButtonComponent
          onClick={() => {
            if (activePage === this.props.pages) return;
            this.changePage(activePage + 1);
          }}
          disabled={activePage === this.props.pages}
        >
          <ChevronRightIcon />
        </PageButtonComponent>
      </div>
    );
  }
}
