import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import KeyboardDatePicker from "../../../Views/crm/candidates/KeyboardDatePicker";
import Grid from "@material-ui/core/Grid";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { Switch } from "material-ui-formik-components/Switch";
import EditIcon from "@material-ui/icons/Edit";
import dayjs from "../../../utils/DayJs";

function EditDayOf({ handleUpdate, data }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = (values, formikApi) => {
    V3.put(`/daysOf/${data._id}`, values).then((res) => {
      handleUpdate(data._id, res.data);
      formikApi.setSubmitting(false);
      setOpen(false);
    });
  };
  return (
    <>
      <span>
        <EditIcon onClick={() => setOpen(true)} />
      </span>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select your days off.</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              from: Yup.date().nullable().required("Required"),
              to: Yup.date().nullable().required("Required"),
              halfDay: Yup.boolean().required("Required"),
            })}
            onSubmit={handleSubmit}
            initialValues={{
              from: data?.startDate ? dayjs(data?.startDate) : null,
              to: data?.endDate ? dayjs(data?.endDate) : null,
              halfDay: data?.halfDay,
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <DialogContent style={{ minWidth: 500 }}>
                  {isSubmitting && <Loader />}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="from"
                        name="from"
                        label="from"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="to"
                        name="to"
                        label="to"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={Switch}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="halfDay"
                        name="halfDay"
                        label={
                          <p style={{ paddingLeft: 15 }}>
                            Only for half of the day
                          </p>
                        }
                        style={{ margin: 0, paddingLeft: 15 }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="submit">
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditDayOf;
