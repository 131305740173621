import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Card from "../../components/Card";
import ImageUpload from "../../components/imageUpload";
import FileUpload from "../../components/flyerUpload";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import {
  IconButton,
  InputAdornment,
  TextField as MUITextField,
  Switch as MuiSwitch,
} from "@material-ui/core";
import CrmCompanySelector from "./CrmCompanySelector";
import { companyConsultants } from "../../data";
import { V3 } from "../../lib/api";
import { Switch } from "material-ui-formik-components/Switch";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import styled from "styled-components";
import { useSnackbar } from "notistack";

const PhoneWrapper = styled.div`
  input {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
`;

const selectValues = [
  {
    key: "Dashboard",
    label: "Dashboard",
    value: "Dashboard",
  },
  {
    key: "Tool",
    label: "Tool",
    value: "Tool",
  },
  {
    key: "E-Mail",
    label: "E-Mail",
    value: "E-Mail",
  },
];

function AddCompany() {
  const [users, setUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [con, setCon] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await V3.get(`/users?roles=admin,employee&limit=400`);

      setUsers(
        response.data.data.map((user) => {
          return {
            key: user._id,
            value: user.profile.name,
            label: user.profile.name,
          };
        })
      );
      const teamRes = await V3.get(`/teams?limit=100`);
      setTeamMembers(
        teamRes.data.data.map((user) => {
          return {
            key: user._id,
            value: user.name,
            label: user.name,
          };
        })
      );
    };
    fetchData();
  }, []);

  const onConChange = (event, value) => {
    setCon(value);
  };

  const checkConsultant = (consultant) => {
    const c = teamMembers.find((el) => el?.value === consultant?.profile?.name);
    if (c) {
      setCon(c);
    }
  };

  const _handleSubmit = async (values, formAPi) => {
    const {
      crmCompany,
      dashboardSelect,
      emailsForCrawler,
      sendCrawlerEmailTo,
      jobs,
      avatar,
      avatarPreview,
      flyer,
      flyerPreview,
      members,
      email,
      firstname,
      lastname,
      phone,
      password,
      confirmPassword,
      ...rest
    } = values;

    let images = {};

    if (avatar || flyer?.length) {
      const formData = new FormData();

      if (avatar) {
        formData.append("avatar", avatar);
      }
      if (flyer && flyer?.length > 0) {
        formData.append("flyer", flyer[0]);
      }
      const res = await V3.put(`companies/files`, formData);

      if (res?.data?.data) {
        images = res?.data?.data;
      }
    }

    if (!flyerPreview && !flyer?.length) {
      images.flyer = null;
    }

    const data = {
      ...rest,
      dashboardSelect: dashboardSelect?.key,
      noCrawlerEmailTo: [],
      sendCrawlerEmailTo: sendCrawlerEmailTo?.length
        ? sendCrawlerEmailTo.map((el) => el.key)
        : [],
      emailsForCrawler: emailsForCrawler?.length ? emailsForCrawler : [],
      consultant: con?.key,
      crmCompany: crmCompany ? crmCompany._id : null,
      consultants: members.map((el) => el.key),
      avatar: avatarPreview,
      flyer: flyerPreview,
      images: images,
      availableJobs: jobs,
    };

    const userData = {
      email,
      firstname,
      lastname,
      phone,
      password,
      confirm: confirmPassword,
    };
    V3.post(`/companies`, data)
      .then((res) => {
        V3.post(`/companies/add-user/${res.data.data._id}`, userData)
          .then((res) => {
            enqueueSnackbar("Company created successfully", {
              variant: "success",
            });
            push("/companies");
          })
          .catch((error) => {
            console.log("error", error?.response?.data?.message);
            enqueueSnackbar("Could not created User", {
              variant: "error",
            });
            push(`/companies/edit/${res.data.data._id}`);
          });
      })
      .catch((err) => {
        console.log(err.response);
        enqueueSnackbar(
          err?.response?.data?.message || "Could not created Company",
          {
            variant: "error",
          }
        );
        formAPi.setSubmitting(false);
      });
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().nullable().required("Required"),
        website: Yup.string(),
        street: Yup.string(),
        zip: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        impressionRate: Yup.number().nullable(),
        activeSourcing: Yup.number().nullable(),
        allInOne: Yup.number().nullable(),
        dashboardSelect: Yup.string().nullable(),
        dashboardEmail: Yup.string().nullable(),
        dashboardTool: Yup.string().nullable(),
        totalEmployees: Yup.number().nullable(),
        crmCompany: Yup.object().nullable().required("Required"),
        daughterCompany: Yup.boolean().nullable(),
        parentCompany: Yup.boolean().nullable(),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Required"),
        firstname: Yup.string().required("Required"),
        lastname: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        confirmPassword: Yup.string().required("Required"),
        addCandidates: Yup.boolean().nullable(),
      })}
      onSubmit={_handleSubmit}
      enableReinitialize
      initialValues={{
        name: "",
        website: "",
        street: "",
        zip: "",
        city: "",
        state: "",
        impressionRate: "",
        consultant: null,
        crmCompany: null,
        activeSourcing: null,
        dashboardSelect: {
          key: "",
          label: "",
          value: "",
        },
        dashboardEmail: "",
        dashboardTool: "",
        toolLink: "",
        toolUsername: "",
        toolPassword: "",
        toolNote: "",
        daughterCompany: false,
        parentCompany: false,
        totalEmployees: 0,
        allInOne: 0,
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        password: "",
        confirmPassword: "",
        whatsapp: "41",
        contactWithWhatsapp: false,
        addCandidates: true,
        applyWithVideo: false,
        applyWithLinkedin: false,
        avatar: null,
        avatarPreview: null,
        flyer: null,
        flyerPreview: null,
        intranet: false,
        members: companyConsultants,
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, values, setFieldTouched }) => (
        <Form>
          {isSubmitting && <Loader />}
          {/* {flyer.length < 1 && (
            <Alert severity="error" style={{ marginBottom: 15 }}>
              Please upload a Flyer
            </Alert>
          )} */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="website"
                      label="Website"
                      name="website"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card title="Location" contentStyles={{ padding: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="street"
                          label="Strasse"
                          name="street"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="zip"
                          label="PLZ"
                          name="zip"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="city"
                          label="Ort"
                          name="city"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="state"
                          label="Land"
                          name="state"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card title="User" contentStyles={{ padding: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="firstname"
                          label="Firstname"
                          name="firstname"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="lastname"
                          label="Lastname"
                          name="lastname"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="email"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="phone"
                          label="Mobile"
                          name="phone"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="password"
                          label="Passwort"
                          name="password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type={showConfirmPassword ? "text" : "password"}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="confirmPassword"
                          label="Passwort bestätigen"
                          name="confirmPassword"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  // disabled={!dirty || flyer.length < 1}
                  disabled={!dirty}
                  style={{ padding: "16px 32px" }}
                >
                  Create
                </Button>
              </Paper>
              <Paper>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <CrmCompanySelector
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          autoUpdateField={true}
                          checkConsultant={checkConsultant}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="impressionsRate"
                          label="Impressions Rate"
                          name="impressionsRate"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="consultant"
                          options={users}
                          variant="outlined"
                          textFieldProps={{
                            label: "Consultant",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell colSpan={2}>
                        <MUIAutocomplete
                          multiple
                          options={users}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => {
                            setFieldValue("members", value);
                          }}
                          value={values.members}
                          getOptionSelected={(option, value) => {
                            return option.key === value.key;
                          }}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <MUITextField
                              {...params}
                              placeholder="Select"
                              variant="outlined"
                              label="Consultants"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <MUIAutocomplete
                          options={teamMembers}
                          getOptionLabel={(option) => option.label}
                          onChange={onConChange}
                          value={con}
                          getOptionSelected={(option, value) => {
                            return option.key === value.key;
                          }}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <MUITextField
                              {...params}
                              placeholder="Select"
                              variant="outlined"
                              label="Kundenberater"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Hauptunternehmen
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field
                            component={MuiSwitch}
                            name="parentCompany"
                            checked={values.parentCompany}
                            onChange={() =>
                              setFieldValue(
                                "parentCompany",
                                !values?.parentCompany
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Tochterunternehmen
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field
                            component={MuiSwitch}
                            name="daughterCompany"
                            checked={values.daughterCompany}
                            onChange={() =>
                              setFieldValue(
                                "daughterCompany",
                                !values?.daughterCompany
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="activeSourcing"
                          label="Active sourcing"
                          name="activeSourcing"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="allInOne"
                          label="All in One"
                          name="allInOne"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="totalEmployees"
                          label="Total Employees"
                          name="totalEmployees"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Bewerben mit video
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="applyWithVideo" />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Bewerben ohne CV
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="applyWithLinkedin" />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Contact With Whatsapp
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field
                            component={Switch}
                            name="contactWithWhatsapp"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {values?.contactWithWhatsapp && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <PhoneWrapper>
                            {" "}
                            <PhoneInput
                              country={"us"}
                              value={values.whatsapp}
                              onChange={(phone) =>
                                setFieldValue("whatsapp", phone)
                              }
                              style={{ width: "100%" }}
                              fullWidth
                            />
                          </PhoneWrapper>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="dashboardSelect"
                          options={selectValues}
                          variant="outlined"
                          textFieldProps={{
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    {values.dashboardSelect?.key === "E-Mail" && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dashboardEmail"
                            label="Email"
                            name="dashboardEmail"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {values.dashboardSelect?.key === "Tool" && (
                      <>
                        {" "}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="dashboardTool"
                              label="Tool"
                              name="dashboardTool"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {" "}
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolLink"
                              label="Link"
                              name="toolLink"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolUsername"
                              label="Username"
                              name="toolUsername"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {" "}
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolPassword"
                              label="Password"
                              name="toolPassword"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {" "}
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolNote"
                              label="Note"
                              name="toolNote"
                              style={{ margin: 0 }}
                              multiline
                              rows={6}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Add Candidates
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="addCandidates" />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Intranet
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="intranet" />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>

              <Paper style={{ marginTop: 15 }}>
                <ImageUpload
                  imagePreviewUrl={values.avatarPreview}
                  onImageChange={(event) => {
                    setFieldValue("avatar", event.target.files[0]);
                    let reader = new FileReader();

                    reader.onloadend = () => {
                      setFieldValue("avatarPreview", reader.result);
                    };

                    reader.readAsDataURL(event.target.files[0]);
                  }}
                  id="avatar"
                />
              </Paper>
              <Paper style={{ marginTop: 15 }}>
                <FileUpload
                  handleChange={(e) => setFieldValue("flyer", e)}
                  id="flyer"
                />
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AddCompany;
