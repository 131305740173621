import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../../../components/GlobalLoader";
import { V3 } from "../../../../lib/api";

function Delete(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = async (values, formApi) => {
    setLoading(true);

    V3.put(`/jobs/${props.id}/interview-question`, values).then((res) => {
      setOpen(false);
      setLoading(false);
      props.handleNew(res.data.data.interviewQuestions);
    });
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
      {loading && <Loader />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Fügen Sie neue frage hinzu</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              question: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{}}
          >
            {({
              isSubmitting,

              values,
            }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question"
                  name="question"
                  style={{ margin: 0, marginTop: 15 }}
                  multiline={true}
                  rows={5}
                />

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
