import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @media (max-width: 960px) {
    justify-content: center;
    padding-right: 0;
    flex-direction: column;
    > span {
      display: none;
    }
    .DateInput input {
      padding: 5px !important;
    }
  }
`;
const Title = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 960px) {
    justify-content: center;
    h3 {
      line-height: 25px !important;
    }
  }
`;
export default function Header({ title, children }) {
  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={0} style={{ borderBottom: '1px solid #ccc' }}>
        <Title item xs={12} md={8}>
          <h3 style={{ margin: 0, lineHeight: '58px', paddingLeft: 15 }}>
            {title}
          </h3>
        </Title>
        <ButtonWrapper item xs={12} md={4}>
          {children}
        </ButtonWrapper>
      </Grid>
    </div>
  );
}
