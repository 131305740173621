import moment from "moment";

export const getPublicHolidays = () => {
  const PublicHolidays = [
    {
      date: `${new Date().getFullYear()}-01-01T23:31:42+02:00`,
      name: "Neujahrstag",
    },
    {
      date: `${new Date().getFullYear()}-01-02T23:31:42+02:00`,
      name: "Berchtoldstag",
    },
    {
      date: `${new Date().getFullYear()}-04-06T23:31:42+02:00`,
      name: "Tag vor Karfreitag",
    },
    {
      date: `${new Date().getFullYear()}-04-07T23:31:42+02:00`,
      name: "Karfreitag",
    },
    {
      date: `${new Date().getFullYear()}-04-10T23:31:42+02:00`,
      name: "Ostermontag",
    },
    {
      date: `${new Date().getFullYear()}-04-17T23:31:42+02:00`,
      name: "Sechseläuten",
    },
    {
      date: `${new Date().getFullYear()}-05-01T23:31:42+02:00`,
      name: "Tag der Arbeit",
    },
    {
      date: `${new Date().getFullYear()}-05-17T23:31:42+02:00`,
      name: "Tag vor Auffahrt",
    },
    {
      date: `${new Date().getFullYear()}-05-18T23:31:42+02:00`,
      name: "Auffahrt",
    },
    {
      date: `${new Date().getFullYear()}-06-29T23:31:42+02:00`,
      name: "Pfingstmontag",
    },
    {
      date: `${new Date().getFullYear()}-08-01T23:31:42+02:00`,
      name: "Nationalfeiertag Schweiz",
    },
    {
      date: `${new Date().getFullYear()}-09-11T23:31:42+02:00`,
      name: "Knabenschiessen",
    },
    {
      date: `${new Date().getFullYear()}-12-25T23:31:42+02:00`,
      name: "Weihnachtstag",
    },
    {
      date: `${new Date().getFullYear()}-12-26T23:31:42+02:00`,
      name: "Stephanstag",
    },
  ];

  const holidays = [];

  for (let index = 0; index < PublicHolidays.length; index++) {
    let holiday = moment(PublicHolidays[index].date);
    if (holiday.isBefore(new Date())) {
      holiday.add(1, "year");
      holidays.push({
        date: holiday.format(),
        name: PublicHolidays[index].name,
      });
    } else {
      holidays.push({
        date: holiday.format(),
        name: PublicHolidays[index].name,
      });
    }
  }

  return holidays;
};
