import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Table from "../Table";
import Card from "../Card";
import SendCoins from "./SendCoins";
import { V3 } from "../../lib/api";
import { GET_FREE_CLICKS } from "../../redux/types";
const FreeClicks = ({ refId }) => {
  const { freeClicks, recruiter } = useSelector((state) => state.recruiter);

  const dispatch = useDispatch();

  useEffect(() => {
    V3.get(`/free-coins/recruiter/${refId}`).then((response) => {
      dispatch({
        type: GET_FREE_CLICKS,
        payload: response.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: "description",
      accessor: "job",
      Cell: (props) => <strong>{props.original.description}</strong>,
    },
    {
      Header: "Geteilt am",
      accessor: "createdAt",
      Cell: (props) => (
        <span className="number date">
          {props.value ? <Moment>{props.value}</Moment> : <span>-</span>}
        </span>
      ),
    },

    {
      Header: "Klicks",
      accessor: "points",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
  ];
  return (
    <Card
      title="Free clicks"
      style={{ marginTop: 15 }}
      button={<SendCoins refId={recruiter.refId} />}
    >
      <Table
        data={freeClicks}
        columns={columns}
        noDataText="No shared link"
        minRows={5}
        manual={false}
      />
    </Card>
  );
};

export default FreeClicks;
