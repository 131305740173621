import React from "react";
import Card from "../../../Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import NewStatus from "./NewStatus";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader";

import DeleteStatus from "./DeleteStatus";
import UpdateStatus from "./UpdateStatus";
import UpdateStatusDate from "./UpdateStatusDate";
import StatusReminderEmail from "./StatusReminderEmail";
import ShowMore from "@tedconf/react-show-more";
import Button from "@material-ui/core/Button";
import { DASHBOARD } from "../../../../config";
import Moment from "react-moment";
import { V3 } from "../../../../lib/api";
import { useSnackbar } from "notistack";
import {
  DELETE_STATUS,
  GET_STATUSES,
  STATUSES_LOADING,
} from "../../../../redux/types";
function getStatusName(id) {
  switch (id) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf";
    case "3":
      return "Ersttermin";
    case "4":
      return "Nachfassen am";
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "In Verhandlung";
    case "8":
      return "Nicht Verlängert";
    case "9":
      return "Angebot";
    default:
      return "No Status";
  }
}

function StatusActivity(props) {
  const { getStatuses, companyId, data } = props;

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  React.useEffect(() => {
    let query = `?company=${companyId}&limit=1000`;
    dispatch({
      type: STATUSES_LOADING,
    });
    V3.get(`crm/statuses${query}`).then((response) => {
      dispatch({
        type: GET_STATUSES,
        payload: response.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatuses, companyId]);

  const handleDelete = (id) => {
    dispatch({
      type: STATUSES_LOADING,
    });
    V3.delete(`crm/statuses/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_STATUS,
          payload: id,
        });

        enqueueSnackbar("Status deleted successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
      });
  };
  const handlePaid = (id, paid, company, status) => {
    const { user } = props.auth;
    props.updatePaid(id, paid, user, company, status);
  };

  const { statuses, loading } = useSelector((state) => state.status);
  const priceFormatter = (price) => {
    if (price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  return (
    <Loader loading={loading}>
      <Card
        title="Status"
        style={{ minHeight: 400 }}
        button={<NewStatus data={data} />}
      >
        <ShowMore items={statuses} by={4}>
          {({ current, onMore }) => (
            <div style={{ minHeight: 350 }} key={"showmore"}>
              <List>
                {current.map((status, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        {status.user ? (
                          <Avatar
                            src={status.user.profile.picture}
                            alt={status.user.profile.name}
                          />
                        ) : (
                          <Avatar
                            src={`${DASHBOARD}/images/bot.jpg`}
                            alt="TWO JOBS BOT"
                          />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {getStatusName(status.type)}
                            {status?.noSale ? ` - Kein Verkauf` : ""}
                            {status?.notExtended ? ` - Keine Verlängerung` : ""}
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <p
                              style={{
                                margin: 0,
                                fontSize: 16,
                              }}
                            >
                              {status.amount > 0 && (
                                <React.Fragment>
                                  {priceFormatter(status.amount)} CHF{" "}
                                </React.Fragment>
                              )}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: 16,
                              }}
                            >
                              {status.date && (
                                <React.Fragment>
                                  <strong>Date:</strong>
                                  <Moment format="DD-MM-YYYY HH.:mm">
                                    {status.date}
                                  </Moment>
                                </React.Fragment>
                              )}
                            </p>
                            {status?.potential ? (
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: 16,
                                }}
                              >
                                {status.potential.available && (
                                  <React.Fragment>
                                    <strong>Pipeline Date:</strong>
                                    <Moment format="DD-MM-YYYY HH.:mm">
                                      {status.potential.available}
                                    </Moment>
                                  </React.Fragment>
                                )}
                              </p>
                            ) : null}

                            <span>
                              {status.user
                                ? status.user.profile.name
                                : `Two Jobs Bot`}{" "}
                              -{" "}
                              <Moment format="DD-MM-YYYY HH.:mm">
                                {status.createdAt}
                              </Moment>
                            </span>
                            {status?.rejects?.length > 0 && (
                              <p style={{ margin: 0 }}>
                                <strong style={{ fontSize: 16 }}>
                                  Reason:{" "}
                                </strong>
                                {status.rejects.map((el, index) => (
                                  <span>
                                    {el.name}
                                    {index < status.rejects.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                ))}
                              </p>
                            )}

                            <p style={{ margin: 0 }}>{status.description}</p>
                          </React.Fragment>
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {status.type === "6" && (
                          <div
                            style={{
                              borderRight: "1px solid #ccc",
                              paddingRight: 15,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {!status.paid && (
                              <StatusReminderEmail
                                id={status._id}
                                data={status}
                              />
                            )}

                            <UpdateStatus
                              handleChange={() =>
                                handlePaid(
                                  status._id,
                                  !status.paid,
                                  status.company,
                                  getStatusName(status.type)
                                )
                              }
                              checked={status.paid}
                            />
                          </div>
                        )}
                        {(status.type === "6" || status.type === "4") && (
                          <UpdateStatusDate data={status} />
                        )}
                        <DeleteStatus
                          onDelete={() => handleDelete(status._id)}
                        />
                      </div>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
              {statuses.length > 0 && (
                <div style={{ padding: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!onMore}
                    onClick={() => {
                      if (!!onMore) onMore();
                    }}
                  >
                    {!onMore ? "Nicht mehr" : "Mehr anzeigen"}
                  </Button>
                </div>
              )}
            </div>
          )}
        </ShowMore>
      </Card>
    </Loader>
  );
}
export default StatusActivity;
