/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Header from "../../components/Page/Header";
import GroupIcon from "@material-ui/icons/Group";
import { V3 } from "../../lib/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

function Contacting() {
  const [data, setData] = useState({});
  const [contacting, setContacting] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recruiters, setRecruiter] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [linkedInAcc, setLinkedInAcc] = useState([]);
  const [contactingNote, setContactingNote] = useState("");
  const [openContactingNote, setOpenContactingNote] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    V3.get(`/users?limit=1000&roles=admin,employee,editor`).then(
      (recruiterRes) => {
        setRecruiter(recruiterRes.data.data);
      }
    );
    V3.get(`/contactings/jobs`).then((res) => {
      setJobs(res.data.data);
    });
    V3.get(`/contactings/companies`).then((res) => {
      setCompanies(res.data.data);
    });
    V3.get(`/linkedin-accounts?limit=100000000000`).then((res) => {
      setLinkedInAcc(res.data.data);
    });
  }, []);

  var max = new Date().getFullYear();
  var min = 2021;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push({ key: `${i}`, label: `${i}`, value: `${i}` });
  }

  let weeks = [];
  for (let index = 1; index < 54; index++) {
    weeks.push({ key: `${index}`, label: `${index}`, value: `${index}` });
  }

  const updateContacted = (id, value) => {
    V3.put(`contactings/${id}`, { completed: value }).then(({ data }) => {
      update(data);
    });
  };

  const columns = [
    {
      id: "note",
      Header: "Notes",
      accessor: (d) => d.note,
      Cell: (props) => <div>{props.original?.note || "-"}</div>,
      filterable: false,
    },
    {
      id: "year",
      Header: "Year",
      accessor: (d) => d.year,
      Cell: (props) => (
        <strong>{moment(props.original?.year).format("YYYY")}</strong>
      ),
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?.key : "")}
          style={{ width: "100%" }}
          options={years}
          value={
            filter ? years.find((week) => week?.key === filter?.value) : null
          }
          getOptionLabel={(option) => option.key}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "week",
      Header: "Week",
      accessor: (d) => d.week,
      Cell: (props) => <strong>{`${props.original?.week}`}</strong>,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?.key : "")}
          style={{ width: "100%" }}
          options={weeks}
          value={
            filter ? weeks.find((week) => week?.key === filter?.value) : null
          }
          getOptionLabel={(option) => option.key}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "job",
      Header: "Job",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/jobs/edit/${props.original?.job?._id}`}>
          {props.original.job?.title || "-"}
        </Link>
      ),
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?._id : "")}
          style={{ width: "100%" }}
          value={filter ? jobs.find((job) => job?._id === filter?.value) : null}
          options={jobs}
          getOptionLabel={(option) => option?.title || "-"}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "company",
      Header: "Company",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/companies/edit/${props.original?.company?._id}`}>
          {props.original.company?.name || "-"}
        </Link>
      ),
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?._id : "")}
          style={{ width: "100%" }}
          value={
            filter ? companies.find((job) => job?._id === filter?.value) : null
          }
          options={companies}
          getOptionLabel={(option) => option?.name || "-"}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "contacted",
      Header: (
        <span>
          Contacted <br />
          {data?.contacted || 0}
        </span>
      ),
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original?.contacted}</strong>,
    },
    {
      id: "toContact",
      Header: (
        <span>
          To Contact <br />
          {data?.toContact || 0}
        </span>
      ),
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original?.toContact}</strong>,
    },
    {
      id: "completed",
      Header: "Completed",
      accessor: "completed",
      filterable: true,
      Cell: (props) => (
        <Checkbox
          checked={props.original?.completed}
          onChange={(e) =>
            updateContacted(props.original._id, e.target.checked)
          }
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      ),
      Filter: ({ filter, onChange }) => (
        <FormControl variant="outlined" fullWidth>
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Alles zeigen</option>
            <option value="true">Ja</option>
            <option value="false">Nein</option>
          </select>
        </FormControl>
      ),
    },
    {
      id: "account",
      Header: "Account",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.linkedInAccount
            ? `${props.original.linkedInAccount.accountNumber} ${props.original.linkedInAccount.accountName}`
            : "-"}
        </strong>
      ),
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? linkedInAcc.find((job) => job?._id === filter?.value)
              : null
          }
          options={linkedInAcc}
          getOptionLabel={(option) =>
            `${option.accountNumber} ${option.accountName}` || "-"
          }
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "recruiter",
      Header: "Recruiter",
      accessor: (d) => d._id,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value?._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? recruiters.find((recruiter) => recruiter?._id === filter?.value)
              : null
          }
          options={recruiters}
          getOptionLabel={(option) => option?.profile?.name || "-"}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
      Cell: (props) => (
        <strong>{props.original.recruiter?.profile?.name}</strong>
      ),
    },
    {
      id: "jobsContactingNote",
      Header: "Notice",
      accessor: (d) => d._id,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="yes">Ja</option>
          <option value="no">Nein</option>
        </select>
      ),
      Cell: (props) => (
        <>
          {props.original.jobsContactingNote &&
          props.original.jobsContactingNote.length > 1 ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpenContactingNote(true);
                setContactingNote(props.original.jobsContactingNote);
              }}
            >
              {" "}
              open
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];
  const update = (newData) => {
    let tmp = [...contacting];
    contacting.map((el, index) => {
      if (el._id === newData.data._id) {
        tmp[index] = newData.data;
      }
    });
    setContacting(tmp);
  };

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`contactings${query}`).then(({ data }) => {
      setData(data);
      setContacting(data.data);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Header title="Wochenplanung" icon={<GroupIcon />}></Header>
      <h2>Reminder - Kandidatenpool und Interessentenpool nutzen</h2>
      <Table
        data={contacting}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          handleFetch(query);
        }}
        noDataText="No Data"
        defaultFiltered={[
          {
            id: "week",
            value: `${moment(new Date()).week()}`,
          },
          {
            id: "year",
            value: `${new Date().getFullYear()}`,
          },
          {
            id: "recruiter",
            value: `${user?._id}`,
          },
        ]}
      />
      <Dialog
        open={openContactingNote}
        onClose={() => setOpenContactingNote(false)}
        size="medium"
        fullWidth
      >
        <DialogTitle>Wochenplanung Notice</DialogTitle>
        <DialogContent>
          <div
            style={{ padding: 15 }}
            dangerouslySetInnerHTML={{ __html: contactingNote }}
          ></div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenContactingNote(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Contacting;
