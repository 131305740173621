import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { V3 } from "../../lib/api";
import { DELETE_USER } from "../../redux/types";
import { useSnackbar } from "notistack";
function DeleteUser({ userId, handleDeleteUser }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    V3.delete(`/users/${userId}`)
      .then((res) => {
        if (handleDeleteUser) {
          handleDeleteUser(userId);
        } else {
          dispatch({
            type: DELETE_USER,
            payload: userId,
          });
        }

        enqueueSnackbar("User deleted successfully", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
      });
  };
  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <AiOutlineDelete />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Benutzer löschen</DialogTitle>
        <DialogContent>
          Sind Sie sicher, dass Sie diesen Benutzer löschen möchten? Sie können
          diesen Benutzer nicht mehr wiederherstellen!
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button
            onClick={handleConfirmation}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteUser;
