import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { V3 } from "../../../../lib/api";
import Loader from "../../../../components/GlobalLoader";
function Delete(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setLoading(true);
    V3.delete(`questions/${props.id}`).then(() => {
      props.handleDelete(props.id);
      setLoading(false);
    });

    setOpen(false);
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <span className="action fi" onClick={handleClickOpen}>
        <DeleteIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Question</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <DialogContentText>
            Are u sure u want to delete this? Your wont be able to recover it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
