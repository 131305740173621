import {  API_V3 } from "../../config.js";
const axiosRequest = require("axios");

export const V3 = axiosRequest.create({
  baseURL: API_V3,
});

// Request interceptor for API calls
V3.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "x-auth-token": token,
      };
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);