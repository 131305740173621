import { Button, Chip, Grid, IconButton, Paper } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import Card from "../../../components/Card";
import ActiveSourcingFiles from "../../../components/Job/ActiveSourcingFiles";
import LinkedInLinksInput from "../../../components/Job/LinkedInLinksInput";
import ProspectProfiles from "../prospectProfiles";
import JobProfiles from "../../../components/Job/JobProfiles";
import styled from "styled-components";
import Contacting from "../../../components/Job/contacting";
import ChatImages from "../../../components/Job/ChatImages";
import Editor from "../../../components/Editor";
import InternNotes from "../../../components/Job/InternNotes";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { V3 } from "../../../lib/api";
import { UPDATE_JOB } from "../../../redux/types";
import { useEffect } from "react";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import MUITextField from "@material-ui/core/TextField";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ActiveSourcingOnBoarding from "../../../components/Job/ActiveSourcingOnBoarding";
import JobInterested from "../../../components/Job/JobInterested";
import SelectEPUsers from "../SelectEPUsers";
import TagsInput from "../../../components/Job/TagInput";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

const Wrapper = styled.div`
  margin-bottom: 15px;
  position: sticky;
  top: 80px;
  z-index: 900;
  display: flex;
  justify-content: flex-end;
`;
const RedirectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const Index = ({ tab }) => {
  const { job } = useSelector((state) => state.job);
  const { activeSourcing } = job;
  const [linkedInLinks, setLinkedInLinks] = useState(
    activeSourcing?.linkedInLinks || []
  );
  const [regions, setRegions] = useState([]);
  const [apEmails, setApEmails] = React.useState([]);

  useEffect(() => {
    setLinkedInLinks(
      activeSourcing?.linkedInLinks ? activeSourcing?.linkedInLinks : []
    );
    setApEmails(job.apEmails || []);
  }, [activeSourcing, job]);

  useEffect(() => {
    V3.get("/job-regions").then((res) => {
      setRegions(res.data.data);
    });
  }, []);

  const [disable, setDisable] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = async (values, form) => {
    const { apUsers } = values;
    try {
      const res = await V3.put(`/jobs/${job._id}/active-sourcing`, {
        ...values,
        linkedInLinks,
        jobRegions: values.jobRegions.map((el) => el._id) || [],
        apUsers: apUsers.map((el) => el._id),
        apEmails,
      });
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
      form.setSubmitting(false);

      enqueueSnackbar("Job updated successfully", {
        variant: "success",
      });
      setDisable(false);
    } catch (error) {
      enqueueSnackbar("Could not updated Job", {
        variant: "error",
      });
      setDisable(false);
    }
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        toContact: Yup.number().nullable(),
        interested: Yup.array().nullable(),
        linkedInLinks: Yup.array().nullable(),
        notes: Yup.string().nullable(),
        recruiterNotes: Yup.string().nullable(),
        keyWords: Yup.string().nullable(),
        moreInfo: Yup.string().nullable(),
        age: Yup.string().nullable(),
        region: Yup.string().nullable(),
        sourcingNotes: Yup.string().nullable(),
        education: Yup.string().nullable(),
        languages: Yup.string().nullable(),
        salary: Yup.string().nullable(),
        linkedInAccount: Yup.array().nullable(),
        workloadDeviation: Yup.string().nullable(),
        workDescription: Yup.string().nullable(),
        notizenOnboarding: Yup.string().nullable(),
        toContactNotes: Yup.string().nullable(),
      })}
      enableReinitialize
      onSubmit={_handleSubmit}
      initialValues={{
        toContact: activeSourcing?.toContact,
        interested: activeSourcing?.interested || [],
        linkedInLinks: activeSourcing?.linkedInLinks,
        notes: activeSourcing?.notes,
        recruiterNotes: activeSourcing?.recruiterNotes,
        keyWords: activeSourcing?.keyWords,
        moreInfo: activeSourcing?.moreInfo,
        age: activeSourcing?.age,
        region: activeSourcing?.region,
        sourcingNotes: activeSourcing?.sourcingNotes,
        education: activeSourcing?.education,
        languages: activeSourcing?.languages,
        salary: activeSourcing?.salary,
        linkedInAccount: activeSourcing?.linkedInAccount,
        workloadDeviation: activeSourcing?.workloadDeviation,
        workDescription: activeSourcing?.workDescription,
        notizenOnboarding: activeSourcing?.notizenOnboarding,
        toContactNotes: activeSourcing?.toContactNotes,
        jobRegions: activeSourcing?.jobRegions || [],

        // job data
        contactingNotes: job?.contactingNotes,
        apUsers: job.apUsers || [],
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => {
        return (
          <Form>
            {!job?.published && (
              <Alert severity="error" style={{ marginBottom: 15 }}>
                ACHTUNG Stelle wurde DEAKTIVIERT!
              </Alert>
            )}
            <Wrapper>
              <Paper style={{ padding: 15, width: "fit-content" }}>
                {" "}
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  // type="submit"
                  style={{ padding: "16px 32px", maxWidth: 500 }}
                  disabled={disable}
                  onClick={() => {
                    setDisable(true);
                    handleSubmit();
                  }}
                >
                  Änderungen speichern
                </Button>
              </Paper>
            </Wrapper>

            <Grid container style={{ padding: 15 }} spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <ActiveSourcingOnBoarding job={job} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <ActiveSourcingFiles id={job?._id} />
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 15 }}>
                  <RedirectWrapper>
                    <IconButton
                      component={"a"}
                      href={`https://fra1.digitaloceanspaces.com/twojobs/applications/2023-12/7e96d2e6-f411-4c73-a81f-13cf87361c56.pdf`}
                      target="_blank"
                    >
                      <OpenInNewIcon style={{ color: "#f2b413" }} />
                    </IconButton>
                  </RedirectWrapper>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Suchbegriffe / Key Words"
                        name="keyWords"
                        style={{ margin: 0 }}
                        multiline
                        rows={10}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Ausbildung / MUSS Anforderungen"
                        name="education"
                        style={{ margin: 0 }}
                        multiline
                        rows={10}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Zusätzliche wichtige Informationen zum sourcen"
                        name="sourcingNotes"
                        style={{ margin: 0 }}
                        multiline
                        rows={5}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Sprachen"
                        name="languages"
                        style={{ margin: 0 }}
                        multiline
                        rows={5}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Weitere Infos / Antworten zu Kandidatenfragen"
                        name="moreInfo"
                        style={{ margin: 0 }}
                        multiline
                        rows={5}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Definierte Such - Region"
                        name="region"
                        style={{ margin: 0 }}
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MUIAutocomplete
                        multiple
                        onChange={(event, value) => {
                          setFieldValue("jobRegions", value);
                        }}
                        style={{ width: "100%" }}
                        value={values.jobRegions}
                        options={regions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            variant="outlined"
                            label="Regions"
                            multiline
                            rows={3}
                          />
                        )}
                        renderTags={(tagValues) => {
                          return tagValues.map((t) => (
                            <Chip
                              icon={
                                <a
                                  href={t?.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <OpenInNewIcon />
                                </a>
                              }
                              label={t?.name}
                              onDelete={() =>
                                setFieldValue(
                                  "jobRegions",
                                  tagValues.filter((el) => el._id !== t._id)
                                )
                              }
                            />
                          ));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Notizen Onboarding"
                        name="notizenOnboarding"
                        style={{ margin: 0 }}
                        multiline
                        rows={5}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <LinkedInLinksInput
                        selectedItem={linkedInLinks}
                        setSelectedItem={setLinkedInLinks}
                        fullWidth
                        variant="outlined"
                        id="tags"
                        name="tags"
                        placeholder="hinzufügen"
                        label="Musterprofile"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Alter"
                        name="age"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <JobInterested data={job} />
              </Grid>
              {job?.services?.ap ? (
                <Grid item xs={12} md={12} lg={12}>
                  <Paper style={{ padding: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <SelectEPUsers
                          companyID={job?.company?._id}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TagsInput
                          selectedItem={apEmails}
                          tags={apEmails}
                          setSelectedItem={setApEmails}
                          fullWidth
                          variant="outlined"
                          id="tags"
                          name="tags"
                          placeholder="hinzufügen"
                          label=" Eingereichte Interessentenprofile Email"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ) : null}

              <Grid item xs={12} md={12} lg={12}>
                {job?.services?.ap ? <ProspectProfiles /> : null}
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <JobProfiles tab={tab} id={job?._id} defaultOpen={false} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card
                  title="Schwarze Liste Noticen"
                  style={{ marginTop: 15 }}
                  defaultOpen={true}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job?.company?.blackListNotes
                        ? job?.company?.blackListNotes
                        : "",
                    }}
                    style={{ paddingLeft: 15 }}
                  ></div>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card
                  title="Bevorzugte Firmen"
                  style={{ marginTop: 15 }}
                  defaultOpen={true}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job?.company?.preferredNotes
                        ? job?.company?.preferredNotes
                        : "",
                    }}
                    style={{ paddingLeft: 15 }}
                  ></div>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Lohn"
                        name="salary"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="To contact"
                        name="toContact"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Pensum Abweichung"
                        name="workloadDeviation"
                        style={{ margin: 0 }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      {" "}
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="To contact Notizen"
                        name="toContactNotes"
                        style={{ margin: 0 }}
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ChatImages id={job?._id} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card
                  title="Notizen Recruiter / Suchbegriffe / Region / Seitenanzahl / wie weiter"
                  style={{ marginTop: 15 }}
                  defaultOpen={true}
                >
                  <Editor
                    value={values.recruiterNotes}
                    onChange={(value) => setFieldValue("recruiterNotes", value)}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <InternNotes
                  data={values.notes}
                  id={job?._id}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card
                  title="Wochenplanug Notice"
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    overflow: "hidden",
                  }}
                  closable={false}
                >
                  <Editor
                    value={values.contactingNotes}
                    onChange={(value) =>
                      setFieldValue("contactingNotes", value)
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Contacting
                  jobId={job._id}
                  toContactNumber={job?.activeSourcing?.toContact}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Index;
