import { Button, Grid, Paper } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { V3 } from "../../../lib/api";
import { UPDATE_JOB } from "../../../redux/types";
import AllInOnes from "../../../components/Job/AllInOnes";
import { useSnackbar } from "notistack";

const Wrapper = styled.div`
  margin-bottom: 15px;
  position: sticky;
  top: 80px;
  z-index: 900;
  display: flex;
  justify-content: flex-end;
`;
const AllInOne = () => {
  const { job } = useSelector((state) => state.job);
  const { allInOne } = job;

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = async (values, form) => {
    try {
      const res = await V3.put(`/jobs/${job._id}/all-in-one`, {
        ...values,
      });
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
      form.setSubmitting(false);

      enqueueSnackbar("Job updated successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Could not updated Job", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Formik
        validationSchema={Yup.object().shape({
          contacted: Yup.number().nullable(),
          amount: Yup.number().nullable(),
        })}
        enableReinitialize
        onSubmit={_handleSubmit}
        initialValues={{
          contacted: allInOne?.contacted,
          amount: allInOne?.amount,
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form>
              <Wrapper>
                <Paper style={{ padding: 15, width: "fit-content" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    // type="submit"
                    style={{ padding: "16px 32px", maxWidth: 500 }}
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Änderungen speichern
                  </Button>
                </Paper>
              </Wrapper>

              <Grid container style={{ padding: 15 }} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={{ padding: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="All in One Amount"
                          name="amount"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Contacted"
                          name="contacted"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <AllInOnes job={job} />
    </>
  );
};

export default AllInOne;
