import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../GlobalLoader";
import FileUpload from "../../fileUpload";

function New({ open, setOpen, handleSubmit }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Add File</DialogTitle>
      <Formik
        validationSchema={Yup.object().shape({
          files: Yup.array()
            .min(1, "Paste at least one image")
            .required("Required"),
        })}
        onSubmit={handleSubmit}
        initialValues={{ files: [], description: "", title: "" }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            {isSubmitting && <Loader />}
            <DialogContent>
              <FileUpload
                handleChange={(files) => setFieldValue("files", files)}
              />
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  close
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={values.files < 1}
                >
                  submit
                </Button>
              </DialogActions>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default New;
