import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Table";
import Actions from "../../../../../components/Table/Actions";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "./Delete";
import Edit from "./update";
import AddNew from "./addNew";
import InfoIcon from "@material-ui/icons/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

function QuizAnswers({ answers, question, handleUpdateQuestion }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(answers);
  }, [answers]);

  const handleNew = (newData) => {
    handleUpdateQuestion({ ...newData, open: true });
  };
  const handleDelete = (updatedData) => {
    handleUpdateQuestion({ ...updatedData, open: true });
  };
  const handleUpdate = (updatedData) => {
    handleUpdateQuestion({ ...updatedData, open: true });
  };
  const handleClose = (updatedData) => {
    handleUpdateQuestion({ ...updatedData, open: false });
  };

  const columns = [
    {
      id: "text",
      Header: "Question",
      accessor: (d) => d.question,
      filterable: false,
      Cell: (props) => <>{props.original?.text || "-"}</>,
    },
    {
      id: "correct",
      Header: "Correct",
      accessor: (d) => d.question,
      filterable: false,
      Cell: (props) => <>{props.original?.correct ? "Yes" : "No"}</>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Question">
            <Edit
              question={question?._id}
              answer={props.original}
              handleUpdate={handleUpdate}
            />
          </Tooltip>
          <Tooltip title="Delete this Question">
            <Delete
              question={question?._id}
              answer={props.original._id}
              handleDelete={handleDelete}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <>
      <IconButton onClick={() => handleUpdate({ ...question, open: true })}>
        {" "}
        <InfoIcon />
      </IconButton>
      <Dialog
        open={question?.open}
        onClose={() => handleClose({ ...question })}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: 0 }}>Quiz Questions</p>
            <AddNew id={question?._id} handleNew={handleNew} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Table
            data={data}
            columns={columns}
            loading={false}
            page={1}
            pages={1}
            noDataText="No data"
            minRows={5}
            filterable={false}
            showPaginationBottom={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose({ ...question })}> Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuizAnswers;
