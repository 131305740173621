import React from "react";
import Grid from "@material-ui/core/Grid";
import SharedJobsTable from "../../components/Recruiter/Analytics/SharedJobsTable";

export default function AnalyticSharedJobs() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SharedJobsTable />
      </Grid>
    </Grid>
  );
}
