import * as React from "react";

const SVGComponent = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="20" fill="black" />
    <path
      d="M26.244 10.25H29.552L22.325 18.51L30.827 29.75H24.17L18.956 22.933L12.99 29.75H9.68003L17.41 20.915L9.25403 10.25H16.08L20.793 16.481L26.244 10.25ZM25.083 27.77H26.916L15.084 12.126H13.117L25.083 27.77Z"
      fill="white"
    />
  </svg>
);

export default SVGComponent;
