import React from "react";
import Dropzone from "../components/Media/Library/Dropzone";

function Upload() {
  return (
    <React.Fragment>
      <Dropzone
        clear={false}
        selectedMedia={null}
        setSelectedMedia={() => {}}
        updatedSelected={() => {}}
      />
    </React.Fragment>
  );
}

export default Upload;
