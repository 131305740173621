import Table from "../../../Table";
import React, { useState } from "react";
import { V3 } from "../../../../lib/api";

function Products(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);

  const getProducts = (query) => {
    setLoading(true);
    V3.get(`/products/my/${props.companyId}${query}`).then((response) => {
      setData(response.data);
      setProducts(response.data.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original.title}</strong>,
    },
    {
      id: "points",
      Header: "Benötigte TWO$",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.points}</strong>,
    },
    {
      id: "stock",
      Header: "Bestand",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.stock}</strong>,
    },
    {
      id: "type",
      Header: "Typ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.type === 1 ? "Gutschein" : "Aktivität"}</strong>
      ),
    },
    {
      id: "published",
      Header: "Veröffentlicht",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.published === null && "Ja"}
          {props.original.published === undefined && "Ja"}
          {props.original.published === true && "Ja"}
          {props.original.published === false && "Nein"}{" "}
        </strong>
      ),
    },
  ];

  return (
    <>
      <Table
        data={products}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          getProducts(query);
        }}
        filterable={false}
        noDataText="Keine Produkte"
        minRows={5}
      />
    </>
  );
}

export default Products;
