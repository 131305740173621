import { WEB } from "../config";
import { V3 } from "../lib/api";

export const generateLink = async (link) => {
  const response = await V3.post(`/redirects`, {
    link,
  });

  if (response?.data?.data?.shortid) {
    return `${WEB}/r/${response?.data?.data?.shortid}`;
  } else {
    return "";
  }
};

export const generateInviteLink = (ref) => {
  setTimeout(
    () =>
      window.open(
        `mailto:?body=${WEB}/r/referrals?ref=${ref.shortId}`,
        "_blank",
        "noopener noreferrer"
      ),
    1000
  );
};

export const generateMailToLink = async (referral) => {
  const link = `/register/recruiter?companyRef=${referral?.shortId}`;

  const generatedLink = await generateLink(link);
  let returnLink = `mailto:?subject=Aktiv mitgestalten und Prämien gewinnen!&body=Liebe Mitarbeiterinnen %0D%0ALiebe Mitarbeiter`;

  returnLink += `%0D%0A%0D%0AAls Unternehmen stehen wir regelmässig vor der Herausforderung, positive, optimistische und sympathische Menschen wie euch zu gewinnen.`;
  returnLink += `%0D%0A%0D%0AUm dieses Ziel zu erreichen, haben wir uns für die Zusammenarbeit mit www.two.jobs entschieden. Eine Plattform, die bei der Personalgewinnung ganz neue Wege geht. Das Spannende dabei ist, dass wir euch, unsere Mitarbeiterinnen und Mitarbeiter, gerne mit ins Boot holen möchten.`;
  returnLink += `%0D%0AEs war noch nie so einfach, im persönlichen Umfeld eine neue berufliche Chance weiterzugeben und gleichzeitig «Punkte» zu sammeln, welche im Online-Shop von two.jobs für verschiedene Gutscheine und Produkte eingelöst werden können. `;
  returnLink += `%0D%0AAuch wir als Unternehmen haben die Möglichkeit, eigene Produkte oder Events für das Teilen der Firmeneigenen Stellen zur Verfügung zu stellen.`;
  returnLink += `%0D%0A%0D%0AWie einfach es ist, erfahrt ihr hier: `;
  if (
    referral &&
    referral.company &&
    referral.company.images &&
    referral.company.images.flyer
  ) {
    const flyer = await generateLink(referral.company.images.flyer);
    returnLink += `${flyer}`;
  }
  returnLink += `%0D%0A%0D%0AWichtig:`;
  returnLink += `%0D%0A1.Um von beide Angeboten profitieren zu können, ist es wichtig, dass ihr euch mit folgendem Link registriert: ${generatedLink} und unsere Stellen mit wenigen Klicks teilt.`;
  returnLink += `%0D%0A2.Ladet bei der Anmeldung auch gleich die App von two.jobs herunter, da das Teilen unserer Stellen dann noch einfacher ist.`;
  returnLink += `%0D%0A3.Teilt gleich unsere Stellen und schaut im Online-Shop nach, was für Prämien man mit den Punkten einlösen kann.`;

  returnLink += `%0D%0A%0D%0AHerzlichen Dank für eure wertvolle Unterstützung.`;

  setTimeout(
    () => window.open(returnLink, "_blank", "noopener noreferrer"),
    1000
  );
};
