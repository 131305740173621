export const colors = [
  "#195079",
  "#DA1833",
  "#F6B500",
  "#3366E6",
  "#99FF99",
  "#B34D4D",
  "#00B3E6",
  "#E6B333",
  "#80B300",
  "#E6B3B3",
  "#6680B3",
  "#999966",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const blogCategoriesDE = [
  {
    key: "Vorstellungsgespräch",
    value: "Vorstellungsgespräch",
    label: "Vorstellungsgespräch",
  },
  { key: "Recruiting", value: "Recruiting", label: "Recruiting" },
  { key: "Networking", value: "Networking", label: "Networking" },
  { key: "Karriere", value: "Karriere", label: "Karriere" },
  { key: "Bewerbung", value: "Bewerbung", label: "Bewerbung" },
  { key: "Arbeitsleben", value: "Arbeitsleben", label: "Arbeitsleben" },
];
export const blogCategoriesEN = [
  {
    key: "Job interview",
    value: "Job interview",
    label: "Job interview",
  },
  { key: "Recruiting", value: "Recruiting", label: "Recruiting" },
  { key: "Networking", value: "Networking", label: "Networking" },
  { key: "Career", value: "Career", label: "Career" },
  { key: "Application", value: "Application", label: "Application" },
  { key: "Work life", value: "Work life", label: "Work life" },
];

const crmCompaniesTableCols = [
  "name",
  "consultant",
  "caller",
  "documentSent",
  "status",
  "maa",
  "called",
  "ma",
  "openPositions",
  "cDate",
  "aDate",
  "updatedAt",
  "meeting",
  "erstterminDate",
];

export const getMyCrmCompaniesCols = (cols, myCols = crmCompaniesTableCols) => {
  const tmp = [];

  // eslint-disable-next-line array-callback-return
  cols.map((el) => {
    if (myCols.includes(el.id)) {
      tmp.push(el);
    }
  });

  tmp.push(cols[cols.length - 1]);

  return tmp;
};

export const routes = [
  "/",
  "/pipeline",
  "/media",
  "/analytics",
  "/my-analytics",
  "/recruiters/analytics",
  "/recruiters/analytics/shared-links",
  "/recruiters/analytics/shared-jobs",
  "/recruiters/analytics/inactive",
  "/invoices",
  "/companies/analytics",
  "/job-candidates",
  "/contacting",
  "/statistic/pipeline",
  "/products",
  "/orders",
  "/jobs",
  "/crawled/jobs",
  "/applications",
  "/companies",
  "/alerts",
  "/favorites",
  "/users",
  "/recruiters",
  "/blog",
  "/team",
  "/crm/companies",
  "/crm/candidates",
  "/interested",
  "/gifts",
  "/calendar",
  "/settings/team",
  "/settings/testimonials",
  "/settings/platform",
  "/settings/partners",
  "/bonuses",
  "/reasons",
  "/linkedin",
  "/emails",
  "/quiz",
  "/update-call-template",
  "/notes-template",
  "/routes-template",
  "/categories/jobs",
  "/categories/companies",
  "/categories/candidates",
  "/categories/products",
  "/categories/hashtags",
  "/categories/rejects",
  "/categories/regions",
];
