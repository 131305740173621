import { Grid, Paper } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { V3 } from "../../lib/api";
import styled from "styled-components";
const colors = [
  "#195079",
  "#DA1833",
  "#F6B500",
  "#3366E6",
  "#99FF99",
  "#B34D4D",
  "#00B3E6",
  "#E6B333",
  "#80B300",
  "#E6B3B3",
  "#6680B3",
  "#999966",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];
const TooltipWrapper = styled(Paper)`
  padding: 15px;
  background-color: rgba(66, 135, 245, 0.2);
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function ChartTooltip({ payload }) {
  return (
    <TooltipWrapper>
      <Wrapper>
        <Dot color={colors[0]}></Dot>
        <strong>Paket: </strong>
        {payload && payload[0]?.payload?.packagePoints
          ? payload[0]?.payload?.packagePoints
          : "-"}
      </Wrapper>
      <Wrapper>
        <Dot color={colors[1]}></Dot>
        <strong>ZDL: </strong>
        {payload && payload[0]?.payload?.activeServicesPoints
          ? payload[0]?.payload?.activeServicesPoints
          : "-"}
      </Wrapper>
      <Wrapper>
        <Dot color={colors[2]}></Dot>
        <strong>Total: </strong>
        {payload && payload[0]?.payload?.totalAmountPoints
          ? payload[0]?.payload?.totalAmountPoints
          : "-"}
      </Wrapper>
      <Wrapper>
        <Dot color={colors[3]}></Dot>
        <strong>Prognose: </strong>
        {payload && payload[0]?.payload?.prognosePoints
          ? payload[0]?.payload?.prognosePoints
          : "-"}
      </Wrapper>
    </TooltipWrapper>
  );
}

function PipelinesChart({ query }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (query.length > 0)
      V3.get(`potentials/chart${query ? query : ""}`)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {});
  }, [query]);

  function formatXAxis(tickItem) {
    return moment(tickItem).format("MM/YYYY");
  }

  let packageData = [
    ...data
      .reduce(
        (m, o) =>
          m.set(
            moment(o.available).format("MM/YYYY"),
            Object.assign(
              {
                ...m.get(moment(o.available).format("MM/YYYY")),
                packagePoints: m.get(moment(o.available).format("MM/YYYY"))
                  ?.packagePoints
                  ? m.get(moment(o.available).format("MM/YYYY")).packagePoints +
                    o.package
                  : o.package,
                activeServicesPoints: m.get(
                  moment(o.available).format("MM/YYYY")
                )?.activeServicesPoints
                  ? m.get(moment(o.available).format("MM/YYYY"))
                      .activeServicesPoints + o.activeServices
                  : o.activeServices,
                totalAmountPoints: m.get(moment(o.available).format("MM/YYYY"))
                  ?.totalAmountPoints
                  ? m.get(moment(o.available).format("MM/YYYY"))
                      .totalAmountPoints + o.totalAmount
                  : o.totalAmount,
                prognosePoints: m.get(moment(o.available).format("MM/YYYY"))
                  ?.prognosePoints
                  ? m.get(moment(o.available).format("MM/YYYY"))
                      .prognosePoints + o.prognose
                  : o.prognose,
                linkCreated: m.get(moment(o.available).format("MM/YYYY"))
                  ?.linkCreated
                  ? m.get(moment(o.available).format("MM/YYYY")).linkCreated
                  : moment(o.available),
              } || {},
              o
            )
          ),
        new Map()
      )
      .values(),
  ];
  packageData = _(packageData).orderBy("linkCreated", "asc").value();

  return (
    <Paper style={{ marginBottom: 15 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {data.length > 0 ? (
            <div
              style={{
                width: "100%",
                height: 300,
                marginLeft: -15,
                marginBottom: -15,
                padding: 15,
              }}
            >
              <ResponsiveContainer>
                <BarChart
                  width={500}
                  height={300}
                  data={packageData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="available" tickFormatter={formatXAxis} />
                  <YAxis />
                  <Tooltip content={<ChartTooltip />} />
                  <Legend />
                  <Bar dataKey="packagePoints" stackId="a" fill={colors[0]} />
                  <Bar
                    dataKey="activeServicesPoints"
                    stackId="a"
                    fill={colors[1]}
                  />

                  <Bar
                    dataKey="totalAmountPoints"
                    stackId="a"
                    fill={colors[2]}
                  />
                  <Bar dataKey="prognosePoints" stackId="a" fill={colors[3]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                padding: 15,
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -15,
              }}
            >
              Keine Daten für diesen Zeitraum
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PipelinesChart;
