import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import styled from "styled-components";
import { Switch } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  label {
    color: #637381;
    line-height: 1.4375em;
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function UpdateQuizTemplateAnswer(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { answer } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = async (values, formApi) => {
    setLoading(true);

    V3.put(
      `/quiz-template/${props.template}/questions/${props.question}/answer/${answer._id}`,
      {
        ...values,
      }
    )
      .then((res) => {
        setOpen(false);
        setLoading(false);

        props.handleUpdateQuestions(res.data.data.questions);
      })
      .catch((err) => {
        setOpen(false);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <span variant="contained" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </span>
      {loading && <Loader />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update answer</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              text: Yup.string().required("Required"),
              correct: Yup.boolean().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{ correct: answer.correct, text: answer.text }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Answer"
                  name="text"
                  style={{ margin: 0, marginTop: 15 }}
                  multiline={true}
                  rows={5}
                />

                <Wrapper>
                  <label>Correct</label>
                  <Switch
                    checked={values.correct}
                    onChange={() => setFieldValue("correct", !values?.correct)}
                  />
                </Wrapper>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UpdateQuizTemplateAnswer;
