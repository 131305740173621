import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Logo from "../components/Logo";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import GlobalLoader from "../components/GlobalLoader";
import { V3 } from "../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AuthCode from "react-auth-code-input";
import { LOGIN_SUCCESS, USER_LOADED } from "../redux/types";

const Auth = styled.div`
  display: flex;
  height: 100vh;
`;

const Left = styled.div`
  width: 50%;
  height: 100vh;
  background-image: url("/images/auth.jpg");
  background-size: cover;
  background-position: center center;
  @media (max-width: 960px) {
    width: 0%;
    display: none;
  }
`;
const Right = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .badges > * {
    margin-right: 8px;
  }
  .input {
    margin-top: 15px;
    width: 25%;
    height: 100px;
    padding: 0;
    font-size: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #475467;
    background: #fff;
    background-clip: padding-box;
    border: 2px solid #c4c4c4;
    border-radius: 8px;
    @media (max-width: 900px) {
      height: 90px;
    }
    @media (max-width: 500px) {
      height: 60px;
      font-size: 30px;
    }
  }
  .container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .input:focus {
    appearance: none;
    outline: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .allowed-characters {
    text-align: left;
  }

  .auth--form {
    width: 100%;
    max-width: 400px;

    .auth--submit {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 30px;

    .mob-logo {
      text-align: center;
      width: 100%;
    }
  }
`;
const LoginCode = () => {
  const [msg, setmsg] = useState(null);

  const { isAuthenticated } = useSelector((state) => state.auth);
  const AuthInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);

  const dispatch = useDispatch();
  const { push } = useHistory();
  let { id } = useParams();
  useEffect(() => {
    if (isAuthenticated === true) {
      push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleSubmit = (e) => {
    setmsg(null);
    e.preventDefault();
    setLoading(true);
    const data = {
      token: id,
      code,
    };
    V3.post(`auth/crm/login/code`, data)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch({ type: USER_LOADED, payload: res.data });
        push("/");
        setLoading(false);
      })
      .catch((err) => {
        setmsg(err.response?.data?.message || "Email doesn't exists!");
        setLoading(false);
      });
  };

  return (
    <Auth>
      <Left></Left>
      <Right>
        <div className="auth--form">
          <div>
            <Logo
              style={{
                width: 140,
              }}
            />
            {msg && (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            {loading && <GlobalLoader></GlobalLoader>}
            <AuthCode
              ref={AuthInputRef}
              allowedCharacters="numeric"
              onChange={(e) => {
                setCode(e);
              }}
              containerClassName="container"
              inputClassName="input"
              isPassword={false}
              disabled={loading}
              length={4}
            />

            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="auth--submit"
              disabled={loading}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Right>
    </Auth>
  );
};

export default LoginCode;
