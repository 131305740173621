import { Paper } from "@material-ui/core";
import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import styled from "styled-components";

import JobVisits from "./Widgets/JobVisits";

const COLORS = ["#195079", "#FBE7EA"];

const Wrapper = styled(Paper)`
  position: absolute;
  top: 0;
  right: -20px;
  width: 150px;
  padding: 8px;
  border-color: ${(props) => props.theme.palette.primary.main};
`;

function Chart({ data, total, fromReferrals }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {fromReferrals && fromReferrals > 0 ? (
        <Wrapper>{fromReferrals} Davon durch Ihre Mitarbeiter</Wrapper>
      ) : null}

      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          la
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          fill="#FBE7EA"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <JobVisits data={total} />
    </div>
  );
}

export default Chart;
