import React, { useState } from "react";
import Card from "../Card";
import { Button } from "@material-ui/core";
import Editor from "../Editor";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  GET_RECRUITER,
  RECRUITERS_LOADING,
  SET_RECRUITER_LOADING,
} from "../../redux/types";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
function RecruiterNotes() {
  const [notes, setNotes] = useState("");

  const { recruiter } = useSelector((state) => state.recruiter);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    setNotes(recruiter.notes);
  }, [recruiter]);

  const handleSubmit = () => {
    dispatch({ type: RECRUITERS_LOADING });
    V3.put(`recruiters/${recruiter._id}`, { notes })
      .then((result) => {
        enqueueSnackbar("Recruiter Updated", { variant: "success" });
        dispatch({
          type: GET_RECRUITER,
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_RECRUITER_LOADING,
          payload: false,
        });

        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };
  return (
    <Card
      title="Notes"
      button={
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Update
        </Button>
      }
    >
      <Editor value={notes} onChange={(val) => setNotes(val)} />
    </Card>
  );
}

export default RecruiterNotes;
