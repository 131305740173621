import {
  GET_CRAWLED_JOBS,
  GET_CRAWLED_JOB,
  DELETE_CRAWLED_JOB,
  CRAWLED_JOBS_LOADING,
  UPDATE_CRAWLED_JOB,
} from "../types";

const initialState = {
  crawledJobs: [],
  data: [],
  crawledJob: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CRAWLED_JOBS:
      return {
        ...state,
        crawledJobs: action.payload.data,
        data: action.payload,
        loading: false,
        slug_exists: null,
      };
    case GET_CRAWLED_JOB:
      return {
        ...state,
        crawledJob: action.payload.data,
        loading: false,
      };
    case DELETE_CRAWLED_JOB:
      return {
        ...state,
        crawledJobs: state.crawledJobs.filter(
          (job) => job._id !== action.payload
        ),
        slug_exists: null,
      };
    case CRAWLED_JOBS_LOADING:
      return {
        ...state,
        job: null,
        loading: true,
      };
    case UPDATE_CRAWLED_JOB:
      const foundIndex = state.crawledJobs.findIndex(
        (x) => x._id === action.payload._id
      );
      state.crawledJobs[foundIndex] = action.payload;

      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
}
