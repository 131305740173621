import React, { useEffect, useState } from "react";
import Summary from "../components/Dashboard/Summary";
import Grid from "@material-ui/core/Grid";
import Meetings from "../components/Dashboard/Meetings";
import Sales from "../components/Dashboard/Sales";
import Offers from "../components/Dashboard/Offers";
import Intro from "../components/Dashboard/Intro";
import TopSales from "../components/Dashboard/TopSales";
import { useSelector } from "react-redux";
import { V3 } from "../lib/api";
function Dashboard() {
  const { user } = useSelector((state) => state.auth);

  const [meetings, setMeetings] = useState([]);
  useEffect(() => {
    V3.get(`/crm/companies/meetings?user=${user._id}`).then((res) => {
      setMeetings(res?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ paddingBottom: 100 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Intro meetings={meetings} />
          <Summary />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Sales />
            </Grid>
            <Grid item xs={12}>
              <Offers />
            </Grid>
            {user?.email === "admin@test.com" ||
            user?.email === "sandro.trovato@two.jobs" ||
            user?.email === "kristian.oroshi@two.jobs" ? (
              <Grid item xs={12}>
                <TopSales />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Meetings meetings={meetings} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
