import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data } = props;

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <MoreVertIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Uploaded By</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={document._id}>
              <TableCell component="th" scope="row">
                <span style={{ wordBreak: "break-all" }}>{data.name}</span>
              </TableCell>
              <TableCell>
                {data.user ? data?.user?.profile?.name : "No user"}
              </TableCell>
              <TableCell align="right">
                <Moment>{data.date}</Moment>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <DialogContent style={{ minWidth: 600 }}>
          <h3>Extracted text</h3>
          {data.text}
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AlertDialog;
