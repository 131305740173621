import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../../../components/Table";
import Actions from "../../../../components/Table/Actions";
import Card from "../../../../components/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "./Delete";
import Edit from "./update";
import AddNew from "./addNew";

function InterviewQuestion() {
  const { job, loading } = useSelector((state) => state.job);

  useEffect(() => {
    if (job.interviewQuestions) {
      setData(job.interviewQuestions);
    }
  }, [job]);

  const [data, setData] = useState([]);

  const handleNew = (newData) => {
    setData(newData);
  };
  const handleDelete = (id) => {
    const tmp = data.filter((el) => el._id !== id);
    setData(tmp);
  };
  const handleUpdate = (updatedData) => {
    setData(updatedData);
  };

  if (!job?.applyWithVideo) {
    return null;
  }

  const columns = [
    {
      id: "question",
      Header: "Question",
      accessor: (d) => d.question,
      filterable: false,
      Cell: (props) => <>{props.original?.question || "-"}</>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Profile">
            <Edit
              profile={props.original}
              jobId={job._id}
              handleUpdate={handleUpdate}
            />
          </Tooltip>
          <Tooltip title="Delete this Profile">
            <Delete
              profileId={props.original._id}
              jobId={job._id}
              handleDelete={handleDelete}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <Card
      title="Interview Questions"
      style={{ marginTop: 15 }}
      button={<AddNew id={job._id} handleNew={handleNew} />}
    >
      <Table
        data={data}
        columns={columns}
        loading={loading}
        page={1}
        pages={1}
        noDataText="No data"
        minRows={5}
        filterable={false}
        showPaginationBottom={false}
      />
    </Card>
  );
}

export default InterviewQuestion;
