import { Grid, Switch } from "@material-ui/core";
import React from "react";

function SelectRoutes({ routes, setRoutes }) {
  const onChange = (val) => {
    if (routes.includes(val)) {
      setRoutes(routes.filter((el) => el !== val));
    } else {
      setRoutes([...routes, val]);
    }
  };

  const Route = ({ val, title }) => {
    return (
      <div style={{ marginBottom: 15 }}>
        <Switch checked={routes.includes(val)} onChange={() => onChange(val)} />
        <span style={{ marginLeft: 15 }}>{title}</span>
      </div>
    );
  };
  return (
    <>
      <h3 style={{ fontWeight: 500, margin: 0, marginBottom: 15 }}>Routes</h3>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Route val="/" title="Dashboard" />
        </Grid>
        <Grid item xs={4}>
          <Route val="/pipeline" title="Pipeline" />
        </Grid>
        <Grid item xs={4}>
          <Route val="/media" title="Media" />
        </Grid>
        <Grid item xs={12}>
          <Route val="/calendar" title="Kalendar" />
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ fontWeight: 400, margin: 0, marginBottom: 15 }}>
            Statistiken
          </h3>

          <div style={{ paddingLeft: 15 }}>
            <Route val="/analytics" title="Statistiken" />
            <Route val="/my-analytics" title="Meine Statistiken" />
            <div>
              <h3 style={{ fontWeight: 300, margin: 0, marginBottom: 15 }}>
                Recruiters
              </h3>

              <div style={{ paddingLeft: 15 }}>
                <Route val="/recruiters/analytics" title="Analytics" />
                <Route
                  val="/recruiters/analytics/shared-links"
                  title="Shared"
                />
                <Route
                  val="/recruiters/analytics/shared-jobs"
                  title="Shared jobs"
                />
                <Route val="/recruiters/analytics/inactive" title="Inactive" />
              </div>
            </div>
            <Route val="/invoices" title="Invoices" />
            <Route val="/companies/analytics" title="Unternehmen" />
            <Route val="/job-candidates" title="Kandidaten" />
            <Route val="/contacting" title="Wochenplanung" />
            <Route val="/statistic/pipeline" title="Pipeline" />
          </div>
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ fontWeight: 400, margin: 0, marginBottom: 15 }}>
            Two Jobs
          </h3>
          <Route val="/products" title="Products" />
          <Route val="/orders" title="Bestellungen" />
          <Route val="/jobs" title="Jobs" />
          <Route val="/crawled/jobs" title="Zu Publizieren" />
          <Route val="/applications" title="Bewerbungen" />
          <Route val="/companies" title="Unternehmen" />
          <Route val="/alerts" title="Alerts" />
          <Route val="/favorites" title="Favorites" />
          <Route val="/users" title="Users" />
          <Route val="/recruiters" title="Recruiters" />
          <Route val="/blog" title="Blog" />
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ fontWeight: 400, margin: 0, marginBottom: 15 }}>Crm</h3>
          <Route val="/team" title="Team" />
          <Route val="/crm/companies" title="Unternehmen" />
          <Route val="/crm/candidates" title="Kandidaten" />
          <Route val="/interested" title="Interested" />
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ fontWeight: 400, margin: 0, marginBottom: 15 }}>
            Einstellungen
          </h3>
          <Route val="settings/account" title="Account" />
          <Route val="/settings/team" title="Team" />
          <Route val="/settings/testimonials" title="Testimonials" />
          <Route val="/settings/platform" title="Platform" />
          <Route val="/settings/partners" title="Partners" />
          <Route val="/bonuses" title="Bonuses" />
          <Route val="/reasons" title="Reasons" />
          <Route val="/linkedin" title="Linkedin" />
          <Route val="/emails" title="Emails" />
          <Route val="/quiz" title="Quiz Template" />
          <Route val="/update-call-template" title="Update Call Template" />
          <Route val="/notes-template" title="Notes Template" />
          <Route val="/routes-template" title="Roles Template" />
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ fontWeight: 400, margin: 0, marginBottom: 15 }}>
            Categories
          </h3>
          <Route val="/categories/jobs" title="Jobs" />
          <Route val="/categories/companies" title="Companies" />
          <Route val="/categories/candidates" title="Candidates" />
          <Route val="/categories/products" title="Products" />
          <Route val="/categories/hashtags" title="Hashtags" />
          <Route val="/categories/rejects" title="Rejects" />
          <Route val="/categories/regions" title="Regions" />
        </Grid>
      </Grid>
    </>
  );
}

export default SelectRoutes;
