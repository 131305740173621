import React, { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { V3 } from "../../lib/api";
import Loader from "../GlobalLoader";

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  overflow: hidden;
`;

const DraggableTable = (props) => {
  const [items, setItems] = useState(props.data);
  const [widths, setWidths] = useState([]);
  const [loading, setLoading] = useState(false);

  const { handleDelete } = props;

  useEffect(() => {
    setItems(props.data);
  }, [props.data]);

  const handleReorder = (oldIndex, newIndex) => {
    const orderedItems = arrayMove(items, oldIndex, newIndex);
    setLoading(true);
    setItems(orderedItems);
    V3.put(`/teams/reorder`, { teams: orderedItems }).then((res) => {
      setLoading(false);
    });
  };

  return (
    <>
      {loading && <Loader />}
      <Table component={Paper}>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" align="left">
              Image
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Name
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Publizirt
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Email
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Publiziert am
            </TableCell>
            <TableCell align="right">Aktion</TableCell>
          </TableRow>
        </TableBody>
        <List
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            handleReorder(oldIndex, newIndex);
          }}
          beforeDrag={({ elements, index }) => {
            const cells = Array.from(elements[index].children);
            const widths = cells.map(
              (cell) => window.getComputedStyle(cell).width
            );
            setWidths(widths);
          }}
          renderList={({ children, props, isDragged }) => (
            <TableBody
              style={{
                cursor: isDragged ? "grabbing" : undefined,
              }}
              {...props}
            >
              {children}
            </TableBody>
          )}
          renderItem={({ value, props, isDragged }) => {
            const _widths = isDragged ? widths : [];
            const row = (
              <TableRow {...props}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ width: _widths[0], padding: 0, paddingLeft: 15 }}
                >
                  <ImageWrapper>
                    {value.image && value.image._id && (
                      <LazyLoadImage
                        src={value.image.sm.url}
                        effect="blur"
                        width={40}
                        height={40}
                      />
                    )}
                  </ImageWrapper>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[1] }}
                >
                  <strong>{value && value.name ? value.name : ""}</strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[2] }}
                >
                  <div>
                    {value.published === true && <span>Ja</span>}
                    {value.published === false && <span>Nein</span>}
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[3] }}
                >
                  <strong> {value && value.email ? value.email : ""} </strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[4] }}
                >
                  {value && value.createdAt ? (
                    <Moment>{value.createdAt}</Moment>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: _widths[5], padding: 0 }}
                >
                  <div>
                    <Tooltip title="Edit this team member">
                      <IconButton
                        component={Link}
                        to={`/settings/team/edit/${value._id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Team Mitglied löschen">
                      <IconButton onClick={(e) => handleDelete(value)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
            return isDragged ? <TableBody> {row}</TableBody> : row;
          }}
        />
      </Table>
    </>
  );
};

export default DraggableTable;
