import * as React from 'react';

const Twitter = (props) => (
    <svg
        width={21}
        height={18}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M6.935 17.125c7.545 0 11.673-6.253 11.673-11.673 0-.176-.004-.356-.012-.532a8.334 8.334 0 0 0 2.047-2.124 8.09 8.09 0 0 1-2.355.645 4.126 4.126 0 0 0 1.804-2.27 8.247 8.247 0 0 1-2.605.996 4.108 4.108 0 0 0-6.994 3.742 11.654 11.654 0 0 1-8.456-4.284A4.108 4.108 0 0 0 3.307 7.1a4.108 4.108 0 0 1-1.86-.512v.051a4.102 4.102 0 0 0 3.293 4.023 4.078 4.078 0 0 1-1.851.07 4.111 4.111 0 0 0 3.83 2.852 8.23 8.23 0 0 1-6.076 1.698 11.64 11.64 0 0 0 6.292 1.843Z"
            fill="#98A2B3"
        />
    </svg>
);

export default Twitter;
