import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../../components/Loader";
import KeyboardDatePicker from "../../../Views/crm/candidates/KeyboardDatePicker";
import { V3 } from "../../../lib/api";
import { ADD_EXTERNAL_CLICK } from "../../../redux/types";
import { useSnackbar } from "notistack";

function Delete(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikAPi) => {
    const data = {
      jobID: props.id,
      amount: values.amount,
      createdAt: values.createdAt,
    };

    V3.post(`/external-clicks`, data).then((res) => {
      dispatch({
        type: ADD_EXTERNAL_CLICK,
        payload: res.data.data,
      });
      enqueueSnackbar("External Clicks created successfully", {
        variant: true,
      });
      formikAPi.setSubmitting(false);
      setOpen(false);
    });
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New External Clicks</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              amount: Yup.number().required("Required"),
              createdAt: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              amount: 0,
              createdAt: new Date(),
            }}
          >
            {({
              isSubmitting,
              dirty,
              setFieldValue,
              initialValues,
              values,
            }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Amount of clicks"
                  name="amount"
                  style={{ margin: 0, marginTop: 15 }}
                />

                <Field
                  name="createdAt"
                  component={KeyboardDatePicker}
                  label="Datum"
                  format="dd.MM.yyyy"
                  inputVariant="outlined"
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="Submit">
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
