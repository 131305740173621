import React from "react";
import styled from "styled-components";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

const Card = styled.div`
  overflow: hidden;
  transition: 0.3s ease-in-out;
  height: 100%;
  box-shadow: rgb(63 63 68 / 5%) 0px 0px 0px 1px,
    rgb(34 33 81 / 15%) 0px 1px 3px 0px;
  cursor: pointer;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  border-radius: 10px;
  background-color: #fff;
  &:hover {
    box-shadow: rgb(63 63 68 / 5%) 0px 2px 0px 2px,
      rgb(34 33 81 / 15%) 0px 2px 3px 2px;
  }

  svg {
    width: 40px;
    height: 40px;
  }
`;

export default function Add({ open, ...props }) {
  return (
    <Card {...props} onClick={open}>
      <AddOutlinedIcon />
    </Card>
  );
}
