import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import KeyboardDatePicker from "../../../Views/crm/candidates/KeyboardDatePicker";
import Grid from "@material-ui/core/Grid";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { Switch } from "material-ui-formik-components/Switch";
import { Alert } from "@material-ui/lab";

function New({ add }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = (values, formikApi) => {
    setMessage(null);
    V3.post(`/daysOf`, values)
      .then((res) => {
        add(res.data);
        formikApi.setSubmitting(false);
        setOpen(false);
      })
      .catch((err) => {
        formikApi.setSubmitting(false);
        setMessage(err?.response?.data?.message || "Something went wrong");
      });
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Add
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select your days off.</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              from: Yup.date().nullable().required("Required"),
              to: Yup.date().nullable().required("Required"),
              halfDay: Yup.boolean().required("Required"),
            })}
            onSubmit={handleSubmit}
            initialValues={{ from: null, to: null, halfDay: false }}
          >
            {({ submitForm, isSubmitting, dirty, values }) => (
              <Form>
                <DialogContent style={{ minWidth: 500, padding: 0 }}>
                  {isSubmitting && <Loader />}
                  {message && (
                    <Alert severity="error" style={{ marginBottom: 15 }}>
                      {message}
                    </Alert>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="from"
                        name="from"
                        label="from"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="to"
                        name="to"
                        label="to"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={Switch}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="halfDay"
                        name="halfDay"
                        label={
                          <p style={{ paddingLeft: 15 }}>
                            Only for half of the day
                          </p>
                        }
                        style={{ margin: 0, paddingLeft: 15 }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="submit">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default New;
