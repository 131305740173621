import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../../../../components/Loader";
import { V3 } from "../../../../../lib/api";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import { Switch } from "@material-ui/core";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  label {
    color: #637381;
    line-height: 1.4375em;
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function Delete(props) {
  const [open, setOpen] = React.useState(false);

  const { question, answer } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikAPi) => {
    V3.put(`/questions/${question}/${answer._id}`, values)
      .then((res) => {
        formikAPi.setSubmitting(false);
        props.handleUpdate(res.data.data);
        setOpen(false);
      })
      .catch((error) => console.log("error", error.response));
  };

  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <EditIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bearbeiten Sie diese frage</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              text: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              text: answer?.text,
              correct: answer?.correct,
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question"
                  name="text"
                  style={{ margin: 0, marginTop: 15 }}
                  multiline={true}
                  rows={5}
                />

                <Wrapper>
                  <label>Correct</label>
                  <Switch
                    checked={values.correct}
                    onChange={() => setFieldValue("correct", !values?.correct)}
                  />
                </Wrapper>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="Submit">
                    update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
