import React, { useState } from "react";
import Table from "../../Table";

import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import Card from "../../Card";
import Create from "./create";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function AssignedCandidates({ id }) {
  const [data, setData] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);

  const addItem = (newItem) => {
    setCandidates([newItem, ...candidates]);
  };

  const handelDelete = async (candidateId) => {
    try {
      await V3.delete(`new-job-candidates/${candidateId}`);
      setCandidates(candidates.filter((el) => el._id !== candidateId));
    } catch (error) {}
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.firstname} {props.original.lastname}
        </strong>
      ),
    },

    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => {
        return <>{props.original.email}</>;
      },
    },

    {
      id: "date",
      Header: "Date",
      accessor: (d) => d._id,
      Cell: (props) => {
        return <Moment>{props.original.date}</Moment>;
      },
    },

    {
      id: "files",
      Header: "Documents",
      accessor: (d) => d._id,
      Cell: (props) => {
        return <>{props.original?.files?.length}</>;
      },
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <Tooltip title="Delete Candidate">
          <IconButton onClick={() => handelDelete(props.original._id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Card
        title={"Assigned Candidate"}
        button={<Create jobId={id} addItem={addItem} />}
      >
        <Table
          data={candidates}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={async (state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&${queryString}`;
            setLoading(true);
            try {
              const res = await V3.get(`new-job-candidates${query}`);
              setData(res.data);
              setCandidates(res.data.data);
              setLoading(false);
            } catch (err) {
              setData({});
              setCandidates([]);
              setLoading(false);
            }
          }}
          noDataText="No orders"
          minRows={5}
          defaultFiltered={[
            {
              id: "job",
              value: id,
            },
          ]}
        />
      </Card>
    </React.Fragment>
  );
}

export default AssignedCandidates;
