import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { DatePicker } from "material-ui-formik-components/DatePicker";
import Loader from "../../components/GlobalLoader";
import { Paper, Grid, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { Cantons2 } from "../../data";

import { useDispatch } from "react-redux";
import { V3 } from "../../lib/api";
import TagsInput from "../../components/Job/InterestedTagInput";
import { useSnackbar } from "notistack";
import { ADD_INTERESTED } from "../../redux/types";
import { useHistory } from "react-router-dom";
function New() {
  const [message, setMessage] = React.useState(null);

  const [linkedInAccounts, setLinkedInAccounts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await V3.get(`/linkedin-accounts?limit=100000`);

        setLinkedInAccounts(
          res.data.data.map((el) => {
            return {
              key: el._id,
              label: el.accountName || "-",
              value: el.accountName || "-",
            };
          })
        );

        const response = await V3.get(`/categories/job?limit=999`);
        setCategories(
          response.data.map((category) => {
            return {
              key: category.name,
              value: category.name,
              label: category.name,
            };
          })
        );
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleSubmit = (values, formikApi) => {
    setMessage(null);
    const data = {
      ...values,
      canton: values?.canton?.key || null,
      category: values?.category?.key || null,
      linkedInAccount: values?.linkedInAccount?.key || null,
    };
    V3.post(`/interested`, data)
      .then((res) => {
        dispatch({
          type: ADD_INTERESTED,
          payload: res.data,
        });
        formikApi.setSubmitting(false);
        enqueueSnackbar("Interested created successfully", {
          variant: "success",
        });
        push(`/interested/edit/${res.data.data._id}`);
      })
      .catch((err) => {
        formikApi.setSubmitting(false);
        enqueueSnackbar("Something went wrong", {
          variant: "success",
        });
        setMessage(err?.response?.data?.message || "Something went wrong");
      });
  };
  return (
    <Formik
      validationSchema={Yup.object().shape({
        link: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        jobTile: Yup.string().required("Required"),
        category: Yup.string().nullable().required("Required"),
        canton: Yup.string().required("Required"),
        desiredFunction: Yup.string(),
        linkedInAccount: Yup.object().required("Required"),
        from: Yup.date().nullable(),
        info: Yup.string().nullable(),
        notes: Yup.array(),
      })}
      onSubmit={handleSubmit}
      initialValues={{
        date: new Date(),
        link: "",
        firstName: "",
        lastName: "",
        jobTile: "",
        industry: "",
        canton: "",
        workload: "100",
        linkedInAccount: null,
        notes: [],
        desiredFunction: "",
        category: null,
        from: null,
        info: null,
      }}
    >
      {({ isSubmitting, dirty, values, setFieldValue, handleSubmit }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: 15 }}>
                {isSubmitting && <Loader />}
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="linkedInAccount"
                      options={linkedInAccounts}
                      textFieldProps={{
                        label: "Account",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Link"
                      name="link"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Vorname"
                      name="firstName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Nachname"
                      name="lastName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="canton"
                      options={Cantons2}
                      textFieldProps={{
                        label: "Kanton",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Job Title"
                      name="jobTile"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="category"
                      options={categories}
                      textFieldProps={{
                        label: "Kategorie",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Wunschfunktion"
                      name="desiredFunction"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Pensum"
                      name="workload"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Datum"
                      name="date"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Ab Datum verfügbar"
                      name="from"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TagsInput
                      selectedItem={values.notes}
                      tags={values.notes}
                      setSelectedItem={(val) => setFieldValue("notes", val)}
                      fullWidth
                      variant="outlined"
                      id="notes"
                      name="notes"
                      placeholder="hinzufügen"
                      label="notes"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  // type="submit"
                  disabled={!dirty}
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default New;
