import React, { useState } from "react";
import Table from "../../Table";
import { V3 } from "../../../lib/api";
import { WEB } from "../../../config";
import styled from "styled-components";

const Published = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ecfdf3;
  border: 1px solid #039855;
  border-radius: 10px;
  height: 28px;
  width: 100%;

  p {
    color: #039855;
    font-weight: 500;
    font-size: 12px;
  }
`;

const UnPublished = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fef3f2;
  border: 1px solid #d92d20;
  border-radius: 10px;
  height: 28px;
  width: 100%;

  p {
    color: #d92d20;
    font-weight: 500;
    font-size: 12px;
  }
`;
function Intranet({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [invoices, setInvoices] = useState([]);

  const getInvoices = (query) => {
    setLoading(true);
    V3.get(`/intranet/my/${companyId}${query}`).then((response) => {
      setData(response.data);
      setInvoices(response.data.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "intranetTitle",
      Header: "Titel",
      accessor: (d) => d.invoiceId,
      Cell: (props) => (
        <a
          href={`${WEB}/intranet/${props.original?._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.original.title}
        </a>
      ),
    },
    {
      id: "onShare",
      Header: "TWO$",
      accessor: (d) => d.onShare,
      Cell: (props) => <>{props.original.onShare}</>,
    },

    {
      id: "sharedIntranet",
      Header: "Total Shared",
      accessor: (d) => d.total,
      Cell: (props) => <>{props.original.sharedIntranet}</>,
    },
    {
      id: "visits",
      Header: "Besuche",
      accessor: (d) => d.total,
      Cell: (props) => <>{props.original.visits}</>,
    },
    {
      id: "published",
      Header: "Status",
      accessor: (d) => d.total,
      Cell: (props) => (
        <>
          {" "}
          <>
            {props.original?.published === true ? (
              <Published>
                <p>Published</p>
              </Published>
            ) : (
              <UnPublished>
                <p>Unpublished</p>
              </UnPublished>
            )}
          </>
        </>
      ),
    },
  ];
  return (
    <Table
      data={invoices}
      columns={columns}
      loading={loading}
      page={!data?.pagination ? 0 : data?.pagination?.current - 1}
      pages={!data?.pagination ? 1 : data?.pagination?.total}
      onFetchData={(state) => {
        var queryString = state.filtered
          .map((key) => key.id + "=" + key.value)
          .join("&");
        let query = `?page=${state.page + 1}&${queryString}`;
        getInvoices(query);
      }}
      filterable={false}
      noDataText="Keine Produkte"
      minRows={5}
    />
  );
}

export default Intranet;
