import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { DatePicker } from "material-ui-formik-components/DatePicker";
import Loader from "../../components/GlobalLoader";
import { Paper, Grid, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Cantons2 } from "../../data";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { V3 } from "../../lib/api";

import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import TagsInput from "../../components/Job/InterestedTagInput";
import {
  GET_SINGLE_INTERESTED,
  INTERESTED_LOADING,
  UPDATE_INTEREST,
} from "../../redux/types";
import { useSnackbar } from "notistack";
function Edit() {
  const [message, setMessage] = useState(null);
  const [linkedInAccounts, setLinkedInAccounts] = useState([]);
  const [categories, setCategories] = useState([]);

  const { interest, loading } = useSelector((state) => state.interest);

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await V3.get(`/linkedin-accounts?limit=100000`);

        setLinkedInAccounts(
          res.data.data.map((el) => {
            return {
              key: el._id,
              label: el.accountName || "-",
              value: el.accountName || "-",
            };
          })
        );

        const response = await V3.get(`/categories/job?limit=999`);
        setCategories(
          response.data.map((category) => {
            return {
              key: category.name,
              value: category.name,
              label: category.name,
            };
          })
        );

        dispatch({ type: INTERESTED_LOADING });
        const interestRes = await V3.get(`/interested/${id}`);
        dispatch({ type: GET_SINGLE_INTERESTED, payload: interestRes.data });
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = (values, formikApi) => {
    setMessage(null);
    const data = {
      ...values,
      canton: values?.canton?.key || null,
      category: values?.category?.key || null,
      linkedInAccount: values?.linkedInAccount?.key || null,
    };

    V3.put(`/interested/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_INTEREST,
          payload: res.data,
        });
        formikApi.setSubmitting(false);
        enqueueSnackbar("Interested updated successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        formikApi.setSubmitting(false);
        enqueueSnackbar("Something went wrong", {
          variant: "success",
        });
        setMessage(err?.response?.data?.message || "Something went wrong");
      });
  };

  if (loading || !interest) {
    return <Loader />;
  }
  const acc = {
    key: interest?.linkedInAccount?._id,
    label: interest?.linkedInAccount?.accountName,
    value: interest?.linkedInAccount?.accountName,
  };
  return (
    <Formik
      validationSchema={Yup.object().shape({
        link: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        jobTile: Yup.string().required("Required"),
        category: Yup.string().nullable().required("Required"),
        canton: Yup.string().required("Required"),
        linkedInAccount: Yup.object().required("Required"),
        desiredFunction: Yup.string(),
        from: Yup.date().nullable(),
        info: Yup.string().nullable(),
        notes: Yup.array(),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        linkedInAccount: acc,
        link: interest?.link,
        firstName: interest?.firstName,
        lastName: interest?.lastName,
        jobTile: interest?.jobTile,
        category: {
          key: interest?.category,
          label: interest?.category,
          value: interest?.category,
        },
        canton: Cantons2.find((el) => el.key === interest?.canton),
        workload: interest?.workload,
        date: interest?.date,
        notes: interest?.notes,
        from: interest?.from || null,
        info: interest?.info,
        desiredFunction: interest?.desiredFunction
          ? interest?.desiredFunction
          : "",
      }}
    >
      {({ isSubmitting, dirty, values, setFieldValue, handleSubmit }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: 15 }}>
                {isSubmitting && <Loader />}
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="linkedInAccount"
                      options={linkedInAccounts}
                      textFieldProps={{
                        label: "Account",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Link"
                      name="link"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Vorname"
                      name="firstName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Nachname"
                      name="lastName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="canton"
                      options={Cantons2}
                      textFieldProps={{
                        label: "Kanton",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Job Title"
                      name="jobTile"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="category"
                      options={categories}
                      variant="outlined"
                      component={Autocomplete}
                      style={{ margin: 0 }}
                      textFieldProps={{
                        label: "Kategorie",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Wunschfunktion"
                      name="desiredFunction"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Pensum"
                      name="workload"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Datum"
                      name="date"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Ab Datum verfügbar"
                      name="from"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TagsInput
                      selectedItem={values.notes}
                      tags={values.notes}
                      setSelectedItem={(val) => setFieldValue("notes", val)}
                      fullWidth
                      variant="outlined"
                      id="notes"
                      name="notes"
                      placeholder="hinzufügen"
                      label="notes"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  // type="submit"
                  disabled={!dirty}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default Edit;
