import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#98A2B3" rx={20} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10 15 8.165 5.715c.661.463.992.695 1.351.784a2 2 0 0 0 .968 0c.36-.09.69-.32 1.351-.784L30 15M14.8 28h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C30 25.72 30 24.88 30 23.2v-6.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C27.72 12 26.88 12 25.2 12H14.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C10 14.28 10 15.12 10 16.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C12.28 28 13.12 28 14.8 28Z"
    />
  </svg>
);
export default SvgComponent;
