import * as React from 'react';

const Xing = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M4.271 14.014c.368 0 .683-.185.944-.556 2.389-3.53 3.632-5.368 3.729-5.514l-2.38-3.458c-.251-.363-.57-.544-.957-.544H2.139c-.223 0-.377.06-.465.181-.106.121-.101.27.015.448l2.336 3.373c.01.009.01.012 0 .012L.354 13.374c-.097.153-.097.298 0 .435.096.137.246.205.45.205H4.27ZM20.189 0h-3.497c-.377 0-.687.181-.928.544l-7.705 11.39 4.918 7.522c.232.363.552.544.958.544h3.468c.212 0 .367-.06.464-.181.096-.138.092-.287-.014-.448l-4.875-7.436v-.012L20.638.629c.107-.17.107-.318 0-.447C20.553.06 20.403 0 20.19 0Z"
            fill="#98A2B3"
        />
    </svg>
);

export default Xing;
