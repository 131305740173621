import { Paper } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const CardWrapper = styled(Paper)`
  padding: 15px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: 960px) {
    padding: 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .content {
    color: #fff;
    @media (max-width: 600px) {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    h3 {
      color: #667085;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 8px;
      line-height: 1;
      @media (max-width: 600px) {
        margin-bottom: 0;
        max-width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    h2 {
      color: #101828;
      font-weight: 600;
      font-size: 24px;
      line-height: 1;
      margin: 0;
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  }
`;
export default function SharedPointsCard({ icon, value, title }) {
  return (
    <CardWrapper>
      <div className="icon">{icon}</div>
      <div className="content">
        <h3>{title}</h3>
        <h2>{value}</h2>
      </div>
    </CardWrapper>
  );
}
