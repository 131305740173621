import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../components/GlobalLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "../../components/Table";
import { useEffect } from "react";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import Header from "../../components/Page/Header";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
function Categories() {
  const [open, setOpen] = React.useState(false);
  const [currentCategory, setCurrentCategory] = React.useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setLoading(true);
    V3.get("/rejects").then((result) => {
      setData(result.data.data);
      setLoading(false);
    });
  }, []);

  const _handleSubmit = (values, formikApi) => {
    setMessage(null);
    const { name, description } = values;

    const dataValue = {
      name,
      description,
    };
    formikApi.resetForm({});
    setLoading(true);
    V3.post("/rejects", dataValue)
      .then((result) => {
        enqueueSnackbar("Created successfully", { variant: "success" });
        setData([result.data.data, ...data]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setMessage(err?.response?.data?.message || "Something went wrong");
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const handleDelete = () => {
    V3.delete(`/rejects/${currentCategory}`)
      .then((result) => {
        setData(data.filter((el) => el._id !== currentCategory));
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCurrentCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.name}</strong>,
    },
    {
      id: "description",
      Header: "Beschreibung",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.description}</strong>,
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      Cell: (props) => (
        <IconButton onClick={() => handleClickOpen(props.original._id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Kein Verkauf" />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {message && (
            <Alert style={{ marginBottom: 15 }} severity="error">
              {message}
            </Alert>
          )}
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              description: Yup.string(),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              name: "",
              description: "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Kategorie"
                  name="name"
                  style={{ marginTop: 0 }}
                />

                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Beschreibung"
                  name="description"
                  multiline
                  rows={6}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 30 }}
                >
                  Create
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={8}>
          <Table
            data={data}
            columns={columns}
            loading={loading}
            noDataText="No categories"
            manual={false}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kategorie löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wirklich diese Kategorie löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Categories;
