import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { V3 } from "../../lib/api";

function SelectEPUsers({ companyID, values, setFieldValue }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (companyID)
      V3.get(`users?company=${companyID}`).then((res) => {
        setUsers(res.data.data);
      });
  }, [companyID]);

  return (
    <Autocomplete
      multiple
      options={users}
      getOptionLabel={(option) => option?.profile?.name}
      onChange={(e, val) => setFieldValue("apUsers", val)}
      value={values.apUsers}
      getOptionSelected={(option, value) => {
        return option._id === value._id;
      }}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select"
          variant="outlined"
          label="Eingereichte Interessentenprofile Users"
        />
      )}
    />
  );
}

export default SelectEPUsers;
