import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../../Card";
import Add from "./Add";
import { V3 } from "../../../lib/api";
import Loader from "../../GlobalLoader";
import { useSnackbar } from "notistack";

import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "./Edit";
import { useConfirm } from "material-ui-confirm";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import moment from "moment";

function Notes() {
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.company);

  const confirm = useConfirm();

  useEffect(() => {
    if (company?.notesArray)
      setNotes(
        company?.notesArray.sort((a, b) => (a?.date > b?.date ? -1 : 1))
      );
  }, [company]);

  const handleSubmit = (data) => {
    setLoading(true);
    V3.post(`/crm/companies/${company._id}/notes`, data)
      .then((res) => {
        const tmp = res.data.data?.notesArray;
        setNotes(tmp.reverse());
        setLoading(false);
        enqueueSnackbar("Notes created successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };
  const handleEdit = (note, id) => {
    setLoading(true);
    V3.put(`/crm/companies/${company._id}/notes/${id}`, note)
      .then((res) => {
        setNotes(
          res.data.data?.notesArray.sort((a, b) => (a?.date > b?.date ? -1 : 1))
        );
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.response?.data?.message || "Something went wrong",
          {
            variant: "error",
          }
        );
      });
  };
  const handleDelete = (id) => {
    confirm({
      title: `Delete Note`,
      description: `Are you sure you wont to delete this note.`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/crm/companies/${company._id}/notes/${id}`)
        .then((res) => {
          setNotes(
            res.data.data?.notesArray.sort((a, b) =>
              a?.date > b?.date ? -1 : 1
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar(
            err?.response?.data?.message || "Something went wrong",
            {
              variant: "error",
            }
          );
        });
    });
  };

  return (
    <>
      {loading && <Loader />}
      <Card title="Notes" button={<Add handleSubmit={handleSubmit} />}>
        <div style={{ padding: 15 }}>
          {notes.map((note) => (
            <div style={{ marginBottom: 15 }}>
              <Card
                defaultOpen={false}
                title={`           From:
                  ${
                    note?.user?.profile?.name
                      ? note?.user?.profile?.name
                      : "User"
                  } At:${moment(note.date).format("DD/MM/YYYY")} ${
                  note?.title ? `Title:${note?.title}` : ""
                }`}
                button={
                  <div>
                    {note?.email?.url && (
                      <Tooltip title="Open Email">
                        <IconButton
                          onClick={() => {
                            window.open(note?.email?.url, "_blank");
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Edit data={note} handleSubmit={handleEdit} />
                    {user?._id === note?.user?._id ? (
                      <Tooltip title="Delete Note">
                        <IconButton onClick={() => handleDelete(note._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                }
              >
                <div
                  style={{ padding: 15 }}
                  dangerouslySetInnerHTML={{ __html: note.note }}
                ></div>
              </Card>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}

export default Notes;
