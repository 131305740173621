import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";

import PropTypes from "prop-types";
import Products from "./Products";
import OtherProducts from "./OtherProducts";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ProductsDashboard({ companyId }) {
  const [value, setValue] = React.useState(0);

  return (
    <>
      <Paper style={{ marginBottom: 30 }}>
        <Tabs
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={(e, val) => setValue(val)}
        >
          <Tab label="Eigene Produkte" {...a11yProps(0)} />
          <Tab label="Produkte, die Sie verwenden können" {...a11yProps(1)} />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <Products companyId={companyId} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <OtherProducts companyId={companyId} />
      </TabPanel>
    </>
  );
}

export default ProductsDashboard;
