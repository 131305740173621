import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraggableTable from "../../components/DraggableTeamTable";
import Header from "../../components/Page/Header";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import Button from "@material-ui/core/Button";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../components/GlobalLoader";
import { V3 } from "../../lib/api";
import { DELETE_TEAM_PERSON, GET_TEAM, TEAM_LOADING } from "../../redux/types";
import { useSnackbar } from "notistack";

function Team() {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({
      type: TEAM_LOADING,
    });

    V3.get(`/teams?limit=1000`).then((res) => {
      dispatch({
        type: GET_TEAM,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (info) => {
    confirm({
      title: `Team Mitglied löschen`,
      description:
        "Team Mitglied wirklich löschen? Eine Wiederherstellung ist nicht mehr möglich!",
    }).then(() => {
      V3.delete(`/teams/${info._id}`)
        .then((res) => {
          dispatch({
            type: DELETE_TEAM_PERSON,
            payload: info._id,
          });
          enqueueSnackbar("Team member has been deleted", {
            variant: "success",
          });
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong! Please try again", {
            variant: "error",
          });
        });
    });
  };

  const { persons, data, loading } = useSelector((state) => state.team);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Header title="Team" size={data.total} icon={<GroupIcon />}>
        <Button
          color="primary"
          variant="contained"
          style={{ float: "right" }}
          component={Link}
          to="/settings/team/new"
        >
          Neu
        </Button>
      </Header>
      <DraggableTable data={persons} handleDelete={handleDelete} />
    </React.Fragment>
  );
}

export default Team;
