import React from "react";
import clsx from "clsx";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Logo from "../Logo";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Links from "./Links";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: theme.breakpoints.up("sm") ? drawerWidth : 500,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",

    width: theme.breakpoints.up("sm") ? theme.spacing(9) + 1 : 500,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    paddingLeft: 25,
    ...theme.mixins.toolbar,
  },

  navIcon: {
    paddingLeft: 8,
  },
  item: {
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
}));

export default function MiniDrawer({ open, setOpen }) {
  const classes = useStyles();
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.up("lg"));
  return (
    <Drawer
      variant={matches ? "permanent" : "temporary"}
      open={open}
      onClose={() => setOpen(!open)}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: matches ? open : false,
        [classes.drawerClose]: matches ? !open : false,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Logo style={{ height: 40 }} />
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <div>
        <Links setOpen={setOpen} matches={matches} />
      </div>
    </Drawer>
  );
}
