import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { V3 } from "../../lib/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs";

function AddTimeReport() {
  const [timeReport, setTimeReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const time = timeReport?.time;
  const lates = time?.length ? time[time?.length - 1] : null;

  useEffect(() => {
    setLoading(true);
    V3.get(`time-reports/latest`)
      .then((result) => {
        setLoading(false);
        setTimeReport(result.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleChange = () => {
    if (timeReport && !timeReport.end) {
      setLoading(true);
      V3.put(`time-reports/${timeReport._id}`, { end: dayjs(new Date()) }).then(
        (result) => {
          setLoading(false);
          setTimeReport(result.data.data);
        }
      );
    } else {
      setLoading(true);
      V3.post(`time-reports`, { start: dayjs(new Date()) }).then((result) => {
        setLoading(false);
        setTimeReport(result.data.data);
      });
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleChange()}
      fullWidth
    >
      {loading ? (
        <CircularProgress color="#fff" size={19} style={{ margin: 3 }} />
      ) : lates && !lates.end ? (
        "Gehen"
      ) : (
        "Kommen"
      )}
    </Button>
  );
}

export default AddTimeReport;
