import React, { useEffect, useState } from "react";
import Card from "../../Card";
import Table from "../../Table";
import Action from "../../Table/Actions";
import { V3 } from "../../../lib/api";
import { Button, Chip, Grid, IconButton, Tooltip } from "@material-ui/core";

import moment from "moment";

import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs, { convertSecToHHmm } from "../../../utils/DayJs";
import { useSelector } from "react-redux";
import EditUserTimeReport from "./Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useConfirm } from "material-ui-confirm";
import { useLocation } from "react-router-dom";

function UserTimeReports({ id }) {
  const date = moment(new Date()).startOf("month");
  const [timeReports, setTimeReports] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(moment(new Date()));

  const [total, setTotal] = useState(null);

  const { user } = useSelector((state) => state.auth);

  const { search } = useLocation();
  useEffect(() => {
    const queryParameters = new URLSearchParams(search);
    const timeReport = queryParameters.get("timeReport");
    if (timeReport) {
      V3.get(`time-reports/${timeReport}`).then((res) => {
        handleApprove(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const confirm = useConfirm();

  const handleApprove = async (data) => {
    let changedTimes = `<ul>`;

    for (let index = 0; index < data.time.length; index++) {
      const time = data.time[index];
      const old = data.oldTime[index];
      if (time.start !== old.start || time.end !== old.end) {
        changedTimes += `<li><span style="color:red">Start:${moment(
          old.start
        ).format("HH:mm")} End:${moment(old.end).format(
          "HH:mm"
        )}</span> -> <span style="color:green">Start:${moment(
          time.start
        ).format("HH:mm")} End:${moment(time.end).format("HH:mm")}</span></li>`;
      }
    }
    changedTimes += `</ul>`;
    confirm({
      title: `Verify Time Report`,
      description: (
        <div>
          Are you sure you want to verify this time report <br />
          <div dangerouslySetInnerHTML={{ __html: changedTimes }}></div>
        </div>
      ),
    }).then(async () => {
      try {
        setLoading(true);
        const response = await V3.put(`time-reports/${data._id}/verify`);
        setTimeReports((state) => {
          return state.map((el) => {
            if (el._id === data._id) {
              return response.data.data;
            } else {
              return el;
            }
          });
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      id: "form",
      Header: "Date",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {dayjs(props.original.start).tz("Europe/Berlin").format("DD/MM/YYYY")}
        </strong>
      ),
    },

    {
      id: "time",
      Header: "Time",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {props.original.time?.length
            ? props.original.time.map((el) => (
                <Chip
                  style={{ marginRight: 8 }}
                  label={`${
                    el.start
                      ? dayjs(el.start).tz("Europe/Berlin").format("HH:mm")
                      : "-"
                  }-${
                    el.end
                      ? dayjs(el.end).tz("Europe/Berlin").format("HH:mm")
                      : "-"
                  }`}
                />
              ))
            : "-"}
        </>
      ),
    },
    {
      id: "amount",
      Header: "Amount",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.amount
            ? convertSecToHHmm(props.original.amount)
            : "-"}
        </strong>
      ),
    },
    {
      id: "action",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          <Action>
            {" "}
            <EditUserTimeReport
              data={props.original}
              handleUpdateData={handleUpdateData}
            />
          </Action>

          {user?.role === "admin" &&
          props.original?.time?.length &&
          !props.original?.time[props.original?.time?.length - 1]?.end ? (
            <Button
              color="primary"
              variant="contained"
              style={{ padding: "6px 16px", marginLeft: 15 }}
              onClick={() => {
                setLoading(true);
                V3.put(`time-reports/${props.original._id}`, {
                  end: dayjs(new Date()),
                }).then((result) => {
                  setLoading(false);
                  setTimeReports(
                    timeReports.map((el) => {
                      if (el._id === props.original._id) {
                        return result.data.data;
                      } else {
                        return el;
                      }
                    })
                  );
                });
              }}
            >
              Gehen
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  if (
    user.email === "admin@test.com" ||
    user.email === "laila.crivelli@two.jobs" ||
    user.email === "kristian.oroshi@two.jobs"
  ) {
    columns.splice(3, 0, {
      id: "verify",
      Header: "Verify",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {!props.original.verified ? (
            <Tooltip title="Approve">
              <IconButton onClick={() => handleApprove(props.original)}>
                <CheckCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Approved">
              <IconButton>
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </strong>
      ),
    });
  }

  const handleUpdateData = (data) => {
    setTimeReports(
      timeReports.map((el) => {
        if (el._id === data._id) {
          return data;
        } else {
          return el;
        }
      })
    );
  };

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/users/${id}/time-reports${query}`)
      .then((result) => {
        setData(result.data);
        setTimeReports(result.data.data);
        setTotal(result.data.totalHr);
        setLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <Card
      title={`User Time Reports: ${total}`}
      style={{ marginTop: 15 }}
      button={
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </>
      }
    >
      <Table
        data={timeReports}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        filterable={false}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          handleFetch(query);
        }}
        minRows={5}
        noDataText="No days off"
        defaultFiltered={[
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
    </Card>
  );
}

export default UserTimeReports;
