import React, { useState } from "react";
import Moment from "react-moment";
import Table from "../Table";
import moment from "moment";
import Filters from "../RecruiterDashboard/Filters";
import { V3 } from "../../lib/api";
import styled from "styled-components";
import { Avatar, IconButton, Tooltip } from "@material-ui/core";
import Facebook from "../Icons/recruiter/facebook";
import Twitter from "../Icons/recruiter/twitter";
import Xing from "../Icons/recruiter/xing";
import Linkedin from "../Icons/recruiter/linkedin";
import Whatsapp from "../Icons/recruiter/whatsapp";
import Mail from "../Icons/recruiter/mail";
import ExternalLink from "../Icons/recruiter/external-link";
import LinkIcon from "../Icons/recruiter/link";
import Instagram from "../Icons/recruiter/instagram";
export const getJobImage = (job) => {
  if (job?.isAnonymous) {
    return "https://storage.googleapis.com/twostorage/companies/avatar/X256_61af7d6a79c7e90013d72349.jpeg";
  }
  if (job?.jobImage) {
    return job.jobImage;
  }
  if (job?.company?.images?.avatarX256) {
    return job?.company?.images?.avatarX256;
  }
  return "/images/building.png";
};

const JobName = styled.div`
  display: flex;
  align-items: flex-start;

  div {
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
      color: #101828;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .MuiAvatar-img {
    object-fit: contain;
  }
`;

function SharedIntranets(props) {
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(3, "months")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const columns = [
    {
      id: "title",
      Header: "Title",
      accessor: (d) => d._id,
      Cell: (props) => {
        const intranet = props.original;
        return (
          <JobName
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt=""
              style={{
                borderRadius: "8px",
              }}
              src={getJobImage(intranet?.intranetId)}
            />
            <div>
              <h3>{intranet?.intranetId?.title || "-"}</h3>
            </div>
          </JobName>
        );
      },
      width: 300,
    },
    {
      id: "createdAt",
      Header: "Datum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      Header: (
        <Tooltip title="Als Link geteilt">
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      ),
      accessor: "link",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },

    {
      Header: (
        <Tooltip title="Auf Facebook geteilt">
          <IconButton>
            <Facebook />
          </IconButton>
        </Tooltip>
      ),
      accessor: "facebook",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Instagram geteilt">
          <IconButton>
            <Instagram />
          </IconButton>
        </Tooltip>
      ),
      accessor: "instagram",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Twitter geteilt">
          <IconButton>
            <Twitter />
          </IconButton>
        </Tooltip>
      ),
      accessor: "twitter",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Xing geteilt">
          <IconButton>
            <Xing />
          </IconButton>
        </Tooltip>
      ),
      accessor: "xing",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Linkedin geteilt">
          <IconButton>
            <Linkedin />
          </IconButton>
        </Tooltip>
      ),
      accessor: "linkedin",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Whatsapp geteilt">
          <IconButton>
            <Whatsapp />
          </IconButton>
        </Tooltip>
      ),
      accessor: "whatsapp",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Auf Whatsapp geteilt">
          <IconButton>
            <Mail />
          </IconButton>
        </Tooltip>
      ),
      accessor: "mail",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Weiterleitungen">
          <IconButton>
            <ExternalLink />
          </IconButton>
        </Tooltip>
      ),
      accessor: "jobId",
      filterable: false,
      Cell: (props) => {
        return <span className="number amount">{props.original?.visits}</span>;
      },
      width: 80,
    },
    {
      Header: "Total",
      accessor: "total",
      filterable: false,
      Cell: (props) => {
        return (
          <strong className="number amount">{props.original?.points}</strong>
        );
      },
      width: 80,
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Filters setEndDate={setEndDate} setStartDate={setStartDate} />
      </div>
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        minRows={5}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);

          V3.get(`/intranet/shared/my/${props.userId}${query}`).then((res) => {
            setLoading(false);
            setData(res.data);
            setOrders(res.data.data);
          });
        }}
        noDataText="No orders"
        defaultFiltered={[
          {
            id: "startDate",
            value: startDate.format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: endDate.format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
}

export default SharedIntranets;
