import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../lib/api";
import Loader from "../GlobalLoader";
import Editor from "../Editor";
import { WEB } from "../../config";

function NewProductEmail({ product }) {
  const [open, setOpen] = useState(false);
  const handleSubmit = (values, formik) => {
    V3.post("/products/email", { ...values, product: product?._id })
      .then((result) => {
        formik.setSubmitting(false);
        setOpen(false);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setOpen(true)}
      >
        Email recruiters
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Send Notification to all recruiters</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Required"),
            title: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            title: "two.jobs Shop",
            description: `Jetzt neues Produkt im Shop entdecken! <br/>  <a href="${
              WEB
            }/shop/${product.sId}">${product?.title}</a> <br/> <a href="${
             WEB
            }/shop/${
              product.sId
            }" style="text-decoration: none; outline: none; color: #ffffff; padding: 12px 24px; mso-text-raise: 3px; background-color: #126de5; border-radius: 4px;">Zum Produkt</a> <br/> ${
              product?.gallery?.length
                ? `<img src="${product?.gallery[0]?.md?.url}"/> `
                : ""
            }`,
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent style={{ minWidth: 500 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="title"
                  name="title"
                  style={{ margin: 0, marginBottom: 15 }}
                />
                <Editor
                  value={values.description}
                  onChange={(value) => setFieldValue("description", value)}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus type="submit">
                  send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default NewProductEmail;
