import React, { useEffect, useState } from "react";
import { V3 } from "../../lib/api";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Loader from "../GlobalLoader";

const Wrapper = styled(Paper)`
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  h4 {
    margin: 0;
  }
`;

function SelectQuizTemplate({ addData }) {
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    V3.get("quiz-template").then((result) => {
      setTemplates(result.data.data);
    });
  }, []);

  const handelSubmit = async () => {
    try {
      setLoading(true);
      const res = await V3.post(`questions/quiz-template`, {
        job: params.id,
        template: selected._id,
      });

      addData(res.data.data);
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setOpen(true)}
        style={{ marginRight: 15 }}
      >
        Templates
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>Select Quiz Template</DialogTitle>
        {loading && <Loader />}
        <DialogContent>
          <Grid container spacing={2}>
            {templates.map((el, index) => (
              <Grid item xs={12} md={3} key={`template-${index}`}>
                {" "}
                <Wrapper
                  onClick={() => setSelected(el)}
                  style={
                    selected?._id === el._id
                      ? { border: "1px solid #195079" }
                      : {}
                  }
                >
                  <h4>{el?.title}</h4>

                  <ul>
                    {" "}
                    {el?.questions?.map((q, qIndex) => (
                      <li
                        key={`questions-${qIndex}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                        }}
                      >
                        {" "}
                        <Avatar src={q?.image?.md?.url} />
                        <span style={{ marginLeft: 8 }}> {q.title}</span>
                      </li>
                    ))}
                  </ul>
                </Wrapper>
              </Grid>
            ))}
          </Grid>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              close
            </Button>
            <Button
              color="primary"
              variant="outlined"
              disabled={!selected}
              onClick={() => handelSubmit()}
            >
              Select
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SelectQuizTemplate;
