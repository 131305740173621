import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/Card";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Activity from "../../../components/Crm/Companies/Activity";
import StatusActivity from "../../../components/Crm/Companies/Status/StatusActivity";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import ContactPersons from "../../../components/Crm/Companies/ContactPersons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteDocument from "../../../components/Crm/Companies/DeleteDocument";
import IconButton from "@material-ui/core/IconButton";
import Invoices from "../../../components/Crm/Companies/Invoices";
import CompanyOffers from "../../../components/Crm/Companies/CompanyOffers";
import ShowMore from "react-show-more";
import styled from "styled-components";
import UpdateCalls from "../../../components/Crm/Companies/UpdateCalls";
import Notes from "../../../components/Company/Notes";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../../lib/api";
import Loader from "../../../components/GlobalLoader";
import LaunchIcon from "@material-ui/icons/Launch";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import {
  COMPANIES_LOADING,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../../redux/types";
import { useSnackbar } from "notistack";

function getStatusName(id) {
  switch (id) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf";
    case "3":
      return "Ersttermin";
    case "4":
      return "Nachfassen am";
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "In Verhandlung";
    case "8":
      return "Nicht Verlängert";
    default:
      return "No Status";
  }
}
const getStatusGift = (id) => {
  switch (`${id}`) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf - Popcorn";
    case "3":
      return "Termin offen";
    case "4":
      if (id.noSale) {
        return "Kein Verkauf - Popcorn";
      } else {
        return "Nachfassen am – Telefonteam - Gummibärchen";
      }
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "Platziert";
    case "8":
      return "Nicht verlängert - Tee";
    default:
      return "No Status";
  }
};

const MoreWrapper = styled.strong`
  color: ${(props) => props.theme.palette.primary.main};
`;

function ViewCompany() {
  let { id } = useParams();

  const [categories, setCategories] = useState([]);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({
      type: COMPANIES_LOADING,
    });
    V3.get(`/crm/companies/${id}`).then((res) => {
      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      });
    });
    V3.get(`/categories/company?limit=999`).then((response) => {
      setCategories(
        response.data.data.map((category) => {
          return {
            key: category.name,
            value: category.name,
            label: category.name,
          };
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getPrice = (price) => {
    if (price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  const parseUrl = (url) => {
    if (!/^https?:\/\//gim.test(url)) {
      return "https://" + url;
    } else if (!/^https?:\/\//gim.test(url) && !/^http?:\/\//gim.test(url)) {
      return "https://" + url;
    } else {
      return url;
    }
  };

  const { loading, company, sales, offers, consultantCompany, presents } =
    useSelector((state) => state.company);

  const _handleSubmit = (values, formik) => {
    V3.put(`crm/companies/${id}/basic`, {
      ...values,
      category: values?.category?.key || null,
    })
      .then((res) => {
        dispatch({
          type: UPDATE_COMPANY,
          payload: res.data.data,
        });
        enqueueSnackbar("Company updated successfully", {
          variant: "success",
        });
        formik.setSubmitting(false);
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Something went wrong",
          {
            variant: "error",
          }
        );
      });
  };
  return (
    <React.Fragment>
      {company !== null && !loading && (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            state: Yup.string().nullable(),
            city: Yup.string().nullable(),
            zip: Yup.number().nullable(),
            website: Yup.string(),
            category: Yup.object().nullable().required("Required"),
            street: Yup.string(),
            openPositions: Yup.number().nullable(),
            maa: Yup.number().nullable(),
          })}
          onSubmit={_handleSubmit}
          enableReinitialize={true}
          initialValues={{
            name: company.name,
            website: company.website,
            openPositions: company.openPositions
              ? parseInt(company.openPositions)
              : null,
            state: company.state,
            city: company.city,
            street: company.street,
            zip: company.zip,
            maa: company.maa,
            category:
              company?.category && company?.category !== "Uncategorized"
                ? {
                    key: company.category,
                    label: company.category,
                    value: company.category,
                  }
                : null,
          }}
        >
          {({ isSubmitting, dirty, values }) => (
            <Form>
              {isSubmitting && <Loader />}
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table style={{ overflow: "hidden" }}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="name"
                                  label="Name"
                                  name="name"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Website</TableCell>
                              <TableCell
                                // align="right"
                                style={{
                                  wordBreak: "break-word",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <LinkWrapper>
                                  {company.website ? (
                                    <a
                                      href={parseUrl(company.website)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {company.website}
                                    </a>
                                  ) : (
                                    "No Website"
                                  )}
                                </LinkWrapper> */}
                                <IconButton
                                  style={{ marginRight: 8 }}
                                  onClick={() => {
                                    window.open(parseUrl(values.website));
                                  }}
                                >
                                  <LaunchIcon />
                                </IconButton>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="website"
                                  label="Website"
                                  name="website"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>State</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="state"
                                  label="Land"
                                  name="state"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Stadt</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="city"
                                  label="Ort"
                                  name="city"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Street</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="street"
                                  label="Strasse"
                                  name="street"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> ZIP</TableCell>
                              <TableCell align="right">
                                {" "}
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="zip"
                                  label="PLZ"
                                  name="zip"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper>
                        <Table style={{ overflow: "hidden" }}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Status</TableCell>
                              <TableCell align="right">
                                {getStatusName(company.statusType)}
                                {company?.statusId?.noSale
                                  ? " - Kein Verkauf"
                                  : ""}
                                {company?.statusId?.notExtended
                                  ? " - Keine Verlängerung"
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Umsatz</TableCell>
                              <TableCell align="right">
                                {getPrice(sales)} CHF
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Offers</TableCell>
                              <TableCell align="right">
                                {getPrice(offers)} CHF
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Kategorie</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={Autocomplete}
                                  fullWidth
                                  name="category"
                                  options={categories}
                                  variant="outlined"
                                  textFieldProps={{
                                    label: "Kategorie",
                                    variant: "outlined",
                                    style: { margin: 0 },
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Offene Jobs</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="number"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="openPositions"
                                  label="Open Positions"
                                  name="openPositions"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Anzahl MA</TableCell>
                              <TableCell align="right">
                                <Field
                                  component={TextField}
                                  type="number"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="maa"
                                  label="MAA"
                                  name="maa"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Doc. gesendet</TableCell>
                              <TableCell align="right">
                                {company.documentSentDate &&
                                  company.documentSent && (
                                    <React.Fragment>
                                      <Moment>
                                        {company.documentSentDate}
                                      </Moment>{" "}
                                      -{" "}
                                    </React.Fragment>
                                  )}

                                {company.documentSent ? "Ja" : "Nein"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>GESCHENK</TableCell>
                              <TableCell align="right">
                                {company?.statusId?.sendGift ? "Ja" : "Nein"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Card title="Geschenk History" defaultOpen={false}>
                        <Table>
                          <TableHead>
                            <TableCell>Contact Person</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Date</TableCell>
                          </TableHead>
                          {presents.map((el) => (
                            <TableRow>
                              <TableCell>
                                {el?.sendGiftTo?.firstName
                                  ? `${el?.sendGiftTo?.firstName} ${el?.sendGiftTo?.lastName}`
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {el?.user?.profile?.name || "-"}
                              </TableCell>
                              <TableCell>{getStatusGift(el.type)}</TableCell>
                              <TableCell align="right">
                                <Moment>{el.createdAt}</Moment>
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <ContactPersons
                        data={company.contactPersons}
                        companyId={company._id}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Invoices companyId={company._id} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CompanyOffers companyId={company._id} />
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        title="Notizen zum Abo (durch Berater auszufüllen)"
                        defaultOpen={false}
                      >
                        <div style={{ padding: 15 }}>
                          <ShowMore
                            lines={1}
                            more={<MoreWrapper>Show more</MoreWrapper>}
                            less={<MoreWrapper>Show less</MoreWrapper>}
                            anchorClass="my-anchor-class"
                            width={100}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: company.subscriptionNotes,
                              }}
                            />
                          </ShowMore>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Notes />
                    </Grid>
                    {company.reason && company.reason.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Card title="Potential">
                          <div
                            style={{ padding: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: company.potential,
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    {company.reason && company.reason.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Card title="Grund für kein Abschluss">
                          <div
                            style={{ padding: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: company.reason,
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    {company.nextContact && company.nextContact.length && (
                      <Grid item xs={12} md={12}>
                        <Card title="Wann ist der nächste Kontakt geplant">
                          <div
                            style={{ padding: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: company.nextContact,
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    {company.svpn && company.svpn.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Card title="SVNP">
                          <div
                            style={{ padding: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: company.svpn,
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    {company.backOffice && company.backOffice.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Card title="Innendienst">
                          <div
                            style={{ padding: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: company.backOffice,
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Card
                        title="Documents"
                        contentStyles={{ paddingBottom: 0 }}
                      >
                        {company.documents && company.documents.length > 0 ? (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Uploaded By</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">Aktion</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {company.documents.map((document) => (
                                <TableRow key={document._id}>
                                  <TableCell component="th" scope="row">
                                    {document.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {document.user &&
                                    document.user.profile &&
                                    document.user.profile.name
                                      ? document.user.profile.name
                                      : "No user"}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Moment>{document.date}</Moment>
                                  </TableCell>
                                  <TableCell align="right">
                                    {" "}
                                    <IconButton
                                      component="a"
                                      href={document.url}
                                      target="_blank"
                                    >
                                      <CloudDownloadIcon />
                                    </IconButton>
                                    <DeleteDocument
                                      companyId={company._id}
                                      documentId={document._id}
                                      documentName={document.name}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <p style={{ margin: 0, padding: 15 }}>No documents</p>
                        )}
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <UpdateCalls id={company._id} />
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                      <StatusActivity companyId={company._id} data={company} />
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                      <Activity companyId={company._id} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                      style={{ padding: "16px 32px" }}
                      disabled={!dirty}
                    >
                      Aktualisieren
                    </Button>
                  </Paper>
                  <Paper style={{ padding: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      fullWidth
                      to={`/crm/companies/edit/${company._id}`}
                    >
                      Bearbeiten
                    </Button>
                  </Paper>

                  <Paper style={{ marginTop: 15 }}>
                    <Table component={Paper}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Erstellungsdatum</TableCell>
                          <TableCell align="right">
                            <Moment>{company.createdAt}</Moment>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Erfasser</TableCell>
                          <TableCell align="right">
                            {company.caller && company.caller.profile.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Berater</TableCell>
                          <TableCell align="right">
                            {company?.consultant &&
                              company?.consultant?.profile?.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Hat Tochterunternehmen</TableCell>
                          <TableCell align="right">
                            {company.hasDaughterCompanies ? "Ja" : "Nein"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Info From Parent Company</TableCell>
                          <TableCell align="right">
                            {company.infoFromParentCompany ? "Ja" : "Nein"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                  {consultantCompany && consultantCompany.length > 0 && (
                    <Paper style={{ marginTop: 15 }}>
                      <Table component={Paper}>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={2}>Companies</TableCell>
                          </TableRow>
                          {consultantCompany.map((el) => (
                            <TableRow>
                              <TableCell>{el?.name}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  component="a"
                                  target="_blank"
                                  href={`/companies/analytics/${el._id}`}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
}

export default ViewCompany;
