import React, { useState, useEffect } from "react";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import GroupIcon from "@material-ui/icons/Group";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Delete from "../components/Alerts/Delete";
import { V3 } from "../lib/api";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const Alerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ filtered: [], page: 0 });
  const [load, setLoad] = useState(false);
  const [startDate, setStartDate] = React.useState(
    moment(`01/01/${new Date().getFullYear() - 1}`).format()
  );
  const [endDate, setEndDate] = React.useState(moment().format());

  useEffect(() => {
    let queryString = state.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${state.page + 1}${
      startDate ? `&startDate=${moment(startDate).format("DD/MM/YYYY")}` : ""
    }${
      endDate ? `&endDate=${moment(endDate).format("DD/MM/YYYY")}` : ""
    }&${queryString}`;
    setLoading(true);
    V3.get(`/alerts/all${query}`).then((res) => {
      setLoading(false);
      setData(res.data);
      setAlerts(res.data.data);
    });
  }, [state, startDate, endDate]);

  const handleExport = () => {
    setLoad(true);
    let queryString = state.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${state.page + 1}${
      startDate ? `&startDate=${moment(startDate).format("DD/MM/YYYY")}` : ""
    }${
      endDate ? `&endDate=${moment(endDate).format("DD/MM/YYYY")}` : ""
    }&${queryString}`;
    V3.get(`/alerts/all/export${query}`).then((res) => {
      window.open(res.data.data?.url);
      setLoad(false);
    });
  };

  const columns = [
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props?.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "jobCategories",
      Header: "Kategorie",
      filterable: false,
      accessor: (d) => d.jobCategories,
      Cell: (props) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {props?.original?.jobCategories?.length > 0
            ? props?.original?.jobCategories.map((el) => el?.name).join(", ")
            : "-"}
        </div>
      ),
    },
    {
      id: "cantons",
      Header: "Cantons",
      filterable: false,
      accessor: (d) => d.cantons,
      Cell: (props) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {props?.original?.cantons?.length > 0
            ? props?.original?.cantons.map((el) => el).join(", ")
            : "-"}
        </div>
      ),
    },

    {
      id: "createdAt",
      Header: () => <span style={{ textAlign: "right" }}>Datum</span>,
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },

    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Delete this alert">
            <Delete
              alertId={props.original._id}
              handleDelete={() =>
                setAlerts(alerts.filter((el) => el._id !== props.original._id))
              }
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Alerts" icon={<GroupIcon />} size={data?.total}>
        <Grid container>
          <Grid item xs={4}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="From"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={4} align="right">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleExport()}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Header>
      <Header
        title="Unsubscribed"
        icon={<GroupIcon />}
        size={`${data?.unsubscribed || 0}`}
      ></Header>
      <Table
        data={alerts}
        columns={columns}
        loading={loading || load}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        filterable={true}
        onFetchData={(filterState) => {
          setState(filterState);
        }}
        noDataText="No alerts"
      />
    </React.Fragment>
  );
};

export default Alerts;
