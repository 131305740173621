import React from "react";

export default function Logo(props) {
  return (
    <svg {...props} viewBox="0 0 299.86 153.71">
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M151.44 153.7a326.7 326.7 0 005.56-36.55c1.12-11.71-8-17.88-20-15.65-12.9 2.42-25.11 3.1-29.71 1.43-10.72-3.92-15.1-18-9.8-31.48s18.31-21.22 29-17.32c3.12 1.14 9 3 15.71 5.49 8.64 3.16 14.81-3.21 13.19-14.88A313.17 313.17 0 00146.15.93l-.26-.93H9.23A9.05 9.05 0 000 8.87v136a9.05 9.05 0 009.23 8.87zM290.64 0a9.05 9.05 0 019.22 8.87v136a9.06 9.06 0 01-9.22 8.88H154.05c2.42-10.62 4.61-27.29 5.69-37.26a16.84 16.84 0 00-19.88-18.18c-13.1 2.1-26.33 4-31 2.52-10.89-3.41-14.5-17-9.12-28.67s14.68-20.89 29.48-15.07c3.17 1 8.39 2.93 15.18 5.07 8.77 2.74 14.83-6 13.19-16.17-2.4-16.16-5.83-30.56-9.59-45.99z"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <title>{"Two Jobs Startseite"}</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            d="M151.44 153.7a326.7 326.7 0 005.56-36.55c1.12-11.71-8-17.88-20-15.65-12.9 2.42-25.11 3.1-29.71 1.43-10.72-3.92-15.1-18-9.8-31.48s18.31-21.22 29-17.32c3.12 1.14 9 3 15.71 5.49 8.64 3.16 14.81-3.21 13.19-14.88A313.17 313.17 0 00146.15.93l-.26-.93H9.23A9.05 9.05 0 000 8.87v136a9.05 9.05 0 009.23 8.87zM290.64 0a9.05 9.05 0 019.22 8.87v136a9.06 9.06 0 01-9.22 8.88H154.05c2.42-10.62 4.61-27.29 5.69-37.26a16.84 16.84 0 00-19.88-18.18c-13.1 2.1-26.33 4-31 2.52-10.89-3.41-14.5-17-9.12-28.67s14.68-20.89 29.48-15.07c3.17 1 8.39 2.93 15.18 5.07 8.77 2.74 14.83-6 13.19-16.17-2.4-16.16-5.83-30.56-9.59-45.99z"
            fillRule="evenodd"
            fill="#da1a32"
          />
          <g clipPath="url(#prefix__a)">
            <path
              d="M86.22 52.3V36.89H28V52.3h18.79v64.3h20.4V52.3zm112.87-15.41h-22.32l-11.91 50.34-10.94-50.34h-17.86l-10.94 50.34-12-50.34H90.68l21.26 79.71h23.8l9.14-37.36 9.36 37.36h23.7l21.15-79.71zm68.33 14.24a27 27 0 00-10.52-10.2c-5.42-3-12.22-4.46-20.3-4.46s-14.88 1.53-20.3 4.46a26.35 26.35 0 00-10.52 10.2c-3.08 4.15-4.57 11.91-4.57 23.07v5.2c0 9.14.85 15.63 2.66 19.56a27.56 27.56 0 0012.43 13.92c5.32 2.87 12.12 4.36 20.3 4.36s15-1.49 20.3-4.36A27.14 27.14 0 00269.34 99c1.7-3.72 2.55-10.2 2.55-19.56V74.2c0-11.16-1.49-18.82-4.47-23.07zm-43.15 46.23c-1.7-1.38-3.29-6-3.29-14.13V71c0-8.93 1.7-13.71 3.72-15 2.13-2.12 6.06-3.29 11.9-3.5 5.85.21 9.78 1.38 11.91 3.5 2 1.28 3.29 6.59 3.29 15.41v12.14c0 8.18-1.27 12.43-3 13.81-1.7 2.45-5.73 3.72-12.22 3.83-6.46-.11-10.58-1.38-12.31-3.83z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
