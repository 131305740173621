import * as React from "react";
const linkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} x={2} fill="#1275B1" rx={20} />
    <path
      fill="#fff"
      d="M16.523 13.115c0 1.168-1.012 2.115-2.261 2.115-1.25 0-2.262-.947-2.262-2.115C12 11.947 13.013 11 14.262 11s2.261.947 2.261 2.115ZM12.31 16.785h3.865V28.5H12.31V16.785ZM22.4 16.785h-3.866V28.5H22.4v-5.994c0-1.384.472-2.774 2.358-2.774 2.13 0 2.118 1.811 2.108 3.214-.013 1.835.018 3.706.018 5.554h3.866v-6.183c-.033-3.948-1.061-5.767-4.446-5.767-2.01 0-3.256.913-3.904 1.738v-1.503Z"
    />
  </svg>
);
export default linkedin;
