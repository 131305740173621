import * as React from 'react';

const Mail = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <g clipPath="url(#a)">
            <path
                d="m17.988 15-5.536-5M7.69 10l-5.535 5m-.417-9.167 6.804 4.763c.551.386.827.579 1.126.653.265.066.542.066.807 0 .3-.074.575-.267 1.126-.653l6.804-4.763M5.738 16.667h8.667c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092c.273-.535.273-1.235.273-2.635V7.333c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092c-.535-.273-1.235-.273-2.635-.273H5.738c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.092 1.092c-.273.535-.273 1.235-.273 2.635v5.334c0 1.4 0 2.1.273 2.635a2.5 2.5 0 0 0 1.092 1.092c.535.273 1.235.273 2.635.273Z"
                stroke="#98A2B3"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(.071)"
                    d="M0 0h20v20H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default Mail;
