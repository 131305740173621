import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import clsx from "clsx";
import Moment from "react-moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { CloudDownload, Launch } from "@material-ui/icons";

import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  type: {
    padding: "6px 12px",
    width: "100%",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 10,
  },
  status: {
    padding: "6px 12px",
    width: "100%",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 10,
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  typeCandidate: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  typeCompany: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  typeTask: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },

  status1: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  status2: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  status3: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },
  selected: {
    border: "solid 3px",
    borderColor: theme.palette.primary.main,
  },
}));

function getType(id) {
  switch (id) {
    case 1:
      return "CANDIDATE";
    case 2:
      return "TASK";
    case 3:
      return "COMPANY";
    default:
      return "No Status";
  }
}

export default function SimpleCard({
  data,
  tags = [],
  selectedCandidates,
  setSelectedCandidates,
}) {
  const theme = useTheme();
  const classes = useStyles();
  let pdfString = "";
  if (data && data.documents && data.documents.length > 0) {
    data.documents.forEach((el) => {
      if (el.text) {
        pdfString += el.text;
      }
    });
  }
  tags.forEach((el) => {
    const reg = new RegExp(el, "ig");
    pdfString = pdfString.replaceAll(
      reg,
      `<span style="background-color: ${theme.palette.orange.main};border-radius:5px;padding: 2px;padding-left: 4px;padding-right: 4px;color: ${theme.palette.orange.contrastText};">${el}</span>`
    );
  });

  let notesString = "";
  if (data?.notes) {
    notesString = data.notes;
  }

  tags.forEach((el) => {
    const reg = new RegExp(el, "ig");
    notesString = notesString.replaceAll(
      reg,
      `<span style="background-color: ${theme.palette.orange.main};border-radius:5px;padding: 2px;padding-left: 4px;padding-right: 4px;color: ${theme.palette.orange.contrastText};">${el}</span>`
    );
  });

  const index = selectedCandidates.indexOf(data.id);

  const handleClick = (e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      if (index < 0) {
        setSelectedCandidates([...selectedCandidates, data.id]);
      } else {
        let tmp = [...selectedCandidates];
        tmp.splice(index, 1);
        setSelectedCandidates(tmp);
      }
    }
  };

  let cardClasses = [classes.root];
  if (index > -1) {
    cardClasses.push(classes.selected);
  }
  const handleOpenPdf = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  };
  return (
    <Card className={cardClasses} variant="outlined" onClick={handleClick}>
      <CardContent style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Link to={`/crm/candidate/${data.id}`}>
              <CardActionArea>
                <div style={{ padding: 0 }}>
                  <Table
                    size="small"
                    component={Paper}
                    style={{ width: "100%", overflow: "hidden" }}
                    variant="outlined"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell>Kandidaten Name</TableCell>
                        <TableCell align="right">{data.title}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Erstellt durch</TableCell>
                        <TableCell align="right">
                          <Moment>{data.createdAt}</Moment>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Geburtsdatum</TableCell>
                        <TableCell align="right">
                          <Moment>{data.birthday}</Moment>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell align="right">
                          {data.city}, {data.zip}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">{data.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Telephone</TableCell>
                        <TableCell align="right">{data.phone}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Grid container spacing={2} style={{ padding: 15 }}>
                    <Grid md={12}>
                      <div
                        className={clsx(classes.type, {
                          [classes.typeCandidate]: data.type === 1,
                          [classes.typeTask]: data.type === 2,
                          [classes.typeCompany]: data.type === 3,
                        })}
                      >
                        {getType(data.type)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </CardActionArea>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            style={{
              width: "100%",
              padding: 5,
              maxHeight: "272px",
              overflow: "auto",
            }}
          >
            <Table size="small" component={Paper} variant="outlined">
              <TableBody>
                {data?.documents?.map((el) => (
                  <TableRow>
                    <TableCell>
                      {el.name.length > 20
                        ? `${el.name.substring(0, 20)}...`
                        : el.name}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleOpenPdf(el.url)}>
                        <Launch />
                      </IconButton>
                      <IconButton
                        component="a"
                        href={el.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CloudDownload />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data?.sendedEmail?.length > 0 && (
              <>
                <p>History</p>
                <Table
                  size="small"
                  component={Paper}
                  variant="outlined"
                  style={{ marginTop: 15 }}
                >
                  <TableBody>
                    {data?.sendedEmail?.map((el) => (
                      <TableRow>
                        <TableCell>{el.job?.title}</TableCell>
                        <TableCell>{el.user?.profile?.name}</TableCell>
                        <TableCell align="right">
                          <Moment>{el.date}</Moment>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            style={{ maxHeight: "272px" }}
          >
            <Paper
              style={{
                overflow: "auto",
                height: data?.notes ? "46%" : "92%",
                margin: 5,
                paddingLeft: 5,
                width: "100%",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: `<p>${pdfString}</p>` }}
                style={{ width: "100%", maxWidth: 700 }}
              ></div>
            </Paper>
            {data?.notes && (
              <Paper
                style={{
                  overflowY: "scroll",
                  height: "46%",
                  margin: 5,
                  paddingTop: 5,
                  paddingLeft: 5,
                  width: "100%",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: `<p>${notesString}</p>` }}
                ></div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
