import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { V3 } from "../../../lib/api";
import { DELETE_CANDIDATE } from "../../../redux/types";
import { useSnackbar } from "notistack";
import Loader from "../../GlobalLoader";
function DeleteCandidate({ candidateId }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    setLoading(true);
    V3.delete(`/crm/candidates/${candidateId}`)
      .then((res) => {
        dispatch({
          type: DELETE_CANDIDATE,
          payload: candidateId,
        });
        enqueueSnackbar("Candidate deleted successfully", {
          variant: "success",
        });
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
        setOpen(false);
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <AiOutlineDelete />
      </span>
      {loading && <Loader />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kandidaten löschen</DialogTitle>
        <DialogContent>
          Kandidaten wirklich löschen? Eine Wiederherstellung ist nicht mehr
          möglich!
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button
            onClick={handleConfirmation}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteCandidate;
