import React, { useState } from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../../lib/api";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

function Blog() {
  const [data, setData] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const confirm = useConfirm();

  const handleDelete = (id) => {
    confirm({
      title: `Delete Blog`,
      description: `Are you sure you wont to delete this blog.`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/blog/${id}`).then(() => {
        setBlogs(blogs.filter((el) => el._id !== id));
        setLoading(false);
      });
    });
  };
  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link to={`/blog/${props.original._id}`}>
          <strong>{props.original?.de?.title}</strong>
        </Link>
      ),
    },

    {
      id: "createdAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      filterable: false,
      width: 200,
      Cell: (props) => (
        <div>
          <Moment>{props.original.createdAt}</Moment>
        </div>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this blog">
            <span>
              <EditIcon
                onClick={() => history.push(`blog/${props.original._id}`)}
              />
            </span>
          </Tooltip>

          <Tooltip title="Delete this blog">
            <span
              className="action fi"
              onClick={() => handleDelete(props.original._id)}
            >
              <DeleteIcon />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      {" "}
      <Header title="Blogs">
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/blog/add"
        >
          Add
        </Button>
      </Header>
      <Table
        data={blogs}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setLoading(true);
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          V3.get(`/blog${query}`)
            .then((res) => {
              setData(res.data);
              setBlogs(res.data.data);
              setLoading(false);
            })
            .catch(() => {
              setData({});
              setBlogs([]);
              setLoading(false);
            });
        }}
        noDataText="No Bonus"
        filterable={false}
      />
    </React.Fragment>
  );
}

export default Blog;
