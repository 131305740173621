import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import usePrice from "../../hooks/usePrice";
const TooltipWrapper = styled(Paper)`
  padding: 15px;
  background-color: rgba(66, 135, 245, 0.2);
`;

export default function MonthlyTooltip({ active, payload, label, user }) {
  const amount = usePrice(
    active && payload && payload[0] && payload[0].payload.amount
  );
  if (active && payload && label) {
    return (
      <TooltipWrapper>
        <p>
          <strong>Betrag: </strong>
          {amount}
          <strong> CHF</strong>
        </p>
        <p>
          <strong>Date: </strong>
          {payload && payload[0] && payload[0].payload.date}
        </p>
      </TooltipWrapper>
    );
  }

  return null;
}
