import React, { useEffect, useState } from "react";
import { V3 } from "../../lib/api";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Loader from "../Loader";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import Card from "../Card";
import { KeyboardDatePicker } from "@material-ui/pickers";

function TopSales() {
  const [data, setData] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState(-1);
  const [startDate, setStartDate] = React.useState(
    `01.01.${new Date().getFullYear()}`
  );
  const [endDate, setEndDate] = React.useState(moment(new Date()));

  useEffect(() => {
    setLoading(true);
    V3.get(`crm/companies/sales/top`, {
      params: {
        limit,
        sort,
        page: 1,
        startDate: moment(startDate).format("DD.MM.YYYY"),
        endDate: moment(endDate).format("DD.MM.YYYY"),
      },
    }).then((res) => {
      setData(res.data.data);
      setShowMore(res?.data?.data?.length === limit);
      setLoading(false);
    });
  }, [limit, sort, startDate, endDate]);

  return (
    <Card
      title="Top Companies"
      button={
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="From"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      }
    >
      <Loader loading={loading}>
        <Table>
          <TableHead>
            <TableCell>Company</TableCell>
            <TableCell align="right">
              Amount
              <IconButton onClick={() => setSort(sort === 1 ? -1 : 1)}>
                {sort === 1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </IconButton>
            </TableCell>
          </TableHead>
          <TableBody>
            {data.map((el) => (
              <TableRow>
                <TableCell>{el?.company?.name}</TableCell>
                <TableCell align="right">{parseInt(el?.amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: 15 }}
          disabled={!showMore}
          onClick={() => setLimit(limit + 20)}
        >
          show more
        </Button>
      </Loader>
    </Card>
  );
}

export default TopSales;
