import React from "react";
import Card from "../../Card";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CVCandidateInfo from "./CvCandidate";
import LinkedinApplications from "./LinkedinApplications/index";
import InfoIcon from "@material-ui/icons/Info";
import { V3 } from "../../../lib/api";
import { DELETE_JOB, GET_JOBS, JOBS_LOADING } from "../../../redux/types";
import Table from "../../../components/Table";
import { Link } from "react-router-dom";
import moment from "moment";
import { IconButton } from "@material-ui/core";
const ServicesWrapper = styled.div`
  .active {
    color: green;
  }
  .inactive {
    color: red;
  }
`;

function Services({ services }) {
  if (!services) {
    return null;
  }
  return (
    <ServicesWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Tooltip title="Active sourcing">
            {services.ad ? (
              <CheckCircleOutlineIcon className="active" />
            ) : (
              <RadioButtonUncheckedIcon className="inactive" />
            )}
          </Tooltip>
        </Grid>
      </Grid>
    </ServicesWrapper>
  );
}

function ActiveJobs({ published, companyId }) {
  const dispatch = useDispatch();

  const activateDeactivateJob = (job) => {
    dispatch({
      type: JOBS_LOADING,
    });
    V3.put(`/jobs/${job._id}/publish`, { publish: !job.published }).then(
      (res) => {
        dispatch({
          type: DELETE_JOB,
          payload: job._id,
        });
      }
    );
  };
  const { jobs, data, loading } = useSelector((state) => state.job);

  let columns = [
    {
      id: "title",
      Header: "Titel",
      width: 220,
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link
          to={`/jobs/edit/${props.original._id}`}
          style={{ textTransform: "none" }}
        >
          <strong>{props.original.title}</strong>
        </Link>
      ),
    },
    {
      id: "anonymous",
      Header: "Anonymous",

      accessor: (d) => d.anonymous,
      Cell: (props) => <>{props.original.isAnonymous ? "Ja" : "Nein"}</>,
    },
    {
      id: "services",
      Header: (
        <>
          <div style={{ marginBottom: 10 }}>Zusatzdienstleistungen</div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Tooltip title="Active sourcing">
                <strong>
                  AS
                  <HelpOutlineIcon style={{ width: 15 }} />
                </strong>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ),

      accessor: (d) => d.services,
      Cell: (props) => <Services services={props.original?.services} />,
    },
    {
      id: "allVisits",
      Header: "Besuche",

      accessor: (d) => d.services,
      Cell: (props) => props.original.allVisits,
    },
    {
      id: "whatsappClicks",
      Header: "Whatsapp Klicks",

      accessor: (d) => d.whatsappClicks,
      Cell: (props) => props.original.whatsappClicks || "-",
    },
    {
      id: "externalApply",
      Header: "Weiterleitung",

      accessor: (d) => d.externalApply,
      Cell: (props) => {
        const job = props.original;

        if (
          !job?.noExternalApplyUrl &&
          job?.externalApplyUrl &&
          job?.externalApplyEnabled
        ) {
          return <>{Math.ceil(job?.click)}</>;
        } else {
          return "-";
        }
      },
    },
    {
      id: "CVCandidateInfo",
      Header: "Eingereichte CV's",

      accessor: (d) => d.CVCandidateInfo,
      Cell: (props) => {
        const job = props.original;
        return <CVCandidateInfo data={job} />;
      },
    },
    {
      id: "CVCandidateInfo",
      Header: "Bewerbungen ohne CV",

      accessor: (d) => d.CVCandidateInfo,
      Cell: (props) => {
        return props.original?.linkedinApplication?.length > 0 ? (
          <LinkedinApplications data={props.original} />
        ) : (
          "-"
        );
      },
    },
  ];

  if (!published) {
    columns.push({
      id: "deactivated",
      Header: "Deaktiviert",

      accessor: (d) => d.CVCandidateInfo,
      Cell: (props) => {
        const job = props.original;
        return job?.deactivatedFrom && job?.deactivated ? (
          <Tooltip
            title={
              <div>
                <p>
                  Deaktiviert von:
                  {job?.deactivatedFrom?.profile?.name}
                </p>
                <p>
                  Deaktiviert um:
                  {moment(job?.deactivated).format("DD/MM/YYYY")}
                </p>
              </div>
            }
          >
            <InfoIcon />
          </Tooltip>
        ) : (
          "-"
        );
      },
    });
  }

  columns = [
    ...columns,
    {
      id: "deactivated",
      Header: "Stelle deaktiviert",
      accessor: (d) => d.createdAt,
      Cell: (props) => (
        <>
          {props.original.deactivated ? (
            <Moment>{props.original.deactivated}</Moment>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "createdAt",
      Header: "Stelle publiziert am",

      accessor: (d) => d.createdAt,
      Cell: (props) => {
        return <Moment>{props.original.createdAt}</Moment>;
      },
    },

    {
      id: "actions",
      Header: "Aktionen",

      accessor: (d) => d.createdAt,
      Cell: (props) => {
        return (
          <Button
            onClick={(e) => activateDeactivateJob(props.original)}
            variant="outlined"
            color="primary"
            size="small"
          >
            {props.original.published ? "deaktivieren" : "aktivieren"}
          </Button>
        );
      },
    },
    {
      id: "_id",
      Header: "Zur Stelle",

      accessor: (d) => d._id,
      Cell: (props) => {
        return (
          <IconButton
            onClick={() =>
              window.open(`https://two.jobs/job/${props.original.slug}`)
            }
          >
            <OpenInNewIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Card title="Ihre Stellen auf TWO.jobs" style={{ marginTop: 15 }}>
      <Table
        data={jobs}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data.pagination.current - 1}
        pages={!data?.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          dispatch({
            type: JOBS_LOADING,
          });
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          V3.get(`/jobs/my/${companyId}${query}`).then((res) => {
            dispatch({
              type: GET_JOBS,
              payload: res.data,
            });
          });
        }}
        noDataText="No jobs"
        filterable={false}
        defaultFiltered={[
          {
            id: "published",
            value: published,
          },
        ]}
      />
    </Card>
  );
}

export default ActiveJobs;
