import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Card from "../Card";
import moment from "moment";
import { V3 } from "../../lib/api";

import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import usePrice from "../../hooks/usePrice";
import ChartTooltip from "../Chart/Tooltip";
import { KeyboardDatePicker } from "@material-ui/pickers";

function Offers({ user }) {
  const date = moment().startOf("year");
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(date);
  const [endDate, setEndDate] = React.useState(moment(new Date()));
  const [distinct, setDistinct] = React.useState([]);
  function formatXAxis(tickItem) {
    return moment(tickItem).format("D.MM.YYYY");
  }

  const fetchData = (start, end) => {
    if (user) {
      V3.get(
        `/crm/companies/offers?user=${user}&start=${moment(start).format(
          "DD/MM/YYYY"
        )}&end=${moment(end).format("DD/MM/YYYY")}`
      ).then((res) => {
        setData(res.data.offers);
        setDistinct(res.data.distinct);
      });
    }
  };
  React.useEffect(() => {
    fetchData(moment(startDate), moment(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, startDate, endDate]);

  function count(array, key) {
    return array.reduce(function (r, a) {
      return r + a[key];
    }, 0);
  }
  const still = distinct.filter((company) => company.statusType === "6");
  const meetings = distinct.filter((company) => company.statusType === "3");
  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <Card
          title="Offerten"
          style={{ height: "100%" }}
          button={
            <Grid container spacing={2} style={{ maxWidth: 500 }}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {data.length > 0 ? (
                <div
                  style={{
                    width: "calc(100% + 20px)",
                    height: 300,
                    marginLeft: -15,
                    marginBottom: -15,
                    padding: 15,
                  }}
                >
                  <ResponsiveContainer>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={formatXAxis} />
                      <YAxis />
                      <Tooltip content={<ChartTooltip />} />
                      <Line type="monotone" dataKey="amount" stroke="#DA1A32" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div
                  style={{
                    padding: 15,
                    height: 300,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: -15,
                  }}
                >
                  Keine Daten für diesen Zeitraum
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  borderLeft: "1px solid rgba(241, 241, 241, 0.2)",
                  height: "100%",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Offers done
                      </TableCell>
                      <TableCell align="right">{data.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Betrag of offers done
                      </TableCell>
                      <TableCell align="right">
                        {usePrice(count(data, "amount"))} CHF
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Unique companies
                      </TableCell>
                      <TableCell align="right">{distinct.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Companies with offer status
                      </TableCell>
                      <TableCell align="right">{still.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Meetings with those companies
                      </TableCell>
                      <TableCell align="right">{meetings.length}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

export default Offers;
