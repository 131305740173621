import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";
import HelpIcon from "@material-ui/icons/Help";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { V3 } from "../../lib/api";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done";
const Icon = styled.div`
  margin-bottom: 20px;
  svg {
    width: 50px;
    height: 50px;
  }
`;
function Card({ icon, size, title }) {
  return (
    <Paper
      style={{
        padding: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Icon>{icon ? <>{icon}</> : <HelpIcon />}</Icon>
      <h1 style={{ margin: 0, textAlign: "center" }}>{size}</h1>
      <h3 style={{ margin: 0, textAlign: "center" }}>{title}</h3>
    </Paper>
  );
}

function Summary() {
  const [data, setData] = useState(null);

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    V3.get(`/users/${user._id}/admin-summary`).then((res) => {
      setData(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingOverlay
      active={false}
      spinner={<SyncLoader color="#fff" />}
      style={{ width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card
            size={data ? data.appointments : 0}
            title="Termine"
            icon={<MeetingRoomIcon />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            size={data ? data.sentOffers : 0}
            title="Erstellte Offerten"
            icon={<LocalOfferIcon />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            size={data ? data.documentSent : 0}
            title="Doc. gesendet"
            icon={<DescriptionIcon />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            size={data ? data.closedDeals : 0}
            title="Geschäft abgeschlossen"
            icon={<DoneIcon />}
          />
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
}

export default Summary;
