import { SET_THEME, GET_SETTINGS, SET_SELECTED_CANDIDATES } from "../types";
const initialState = {
  fetching: false, //todo: delete
  theme: false,
  loading: false, //todo: delete
  settings: null,
  selectedCandidates: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case SET_SELECTED_CANDIDATES:
      return {
        ...state,
        selectedCandidates: action.payload,
      };

    default:
      return state;
  }
}
