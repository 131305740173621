import React, { useState } from "react";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { V3 } from "../lib/api";
import { Cantons2 } from "../data";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Loader from "../components/GlobalLoader";
const Team = () => {
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState([]);
  const [query, setQuery] = useState("");

  const [open, setOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link
          to={`/recruiter/${
            props.original?.recruiter ? props.original?.recruiter?._id : ""
          }`}
        >
          <strong>
            {props.original?.profile &&
            props.original?.profile?.name?.length > 0
              ? props.original.profile.name
              : "-"}
          </strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "phone",
      Header: "Tel.",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`tel:+${props.original.phone} `}
          >
            {props.original.phone}
          </a>
        </strong>
      ),
    },
    {
      id: "canton",
      Header: "Kanton",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.profile?.canton || "-"}</strong>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          {Cantons2.map((el) => (
            <option value={el.value}>{el.label}</option>
          ))}
        </select>
      ),
    },
    {
      id: "createdAt",
      Header: "Datum",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },
    {
      id: "verified",
      Header: "Genehmigt",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <strong>
          {props.original.recruiter && props.original.recruiter._id && (
            <React.Fragment>
              {props.original.recruiter.verified ? "Yes" : "No"}
            </React.Fragment>
          )}
        </strong>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Auf two.jobs">
            <a
              href={`/recruiter/${props.original?.recruiter?._id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="fi"
            >
              <FiExternalLink></FiExternalLink>
            </a>
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Recruiters" size={data.total}>
        <IconButton onClick={() => setOpen(true)}>
          <ImportExportIcon />
        </IconButton>
      </Header>
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let querys = `?page=${
            state.page + 1
          }&roles=recruiter&recruiter=true&${queryString}`;
          setLoading(true);

          V3.get(`users${querys}`).then((res) => {
            setQuery(querys);
            setData(res.data);
            setUsers(res.data.data);
            setLoading(false);
          });
        }}
        noDataText="No recruiters"
        filterable={true}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Export Recruiters</DialogTitle>
        <DialogContent>
          {exportLoading && <Loader />}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              setExportLoading(true);
              V3.get(`users/recruiter/export${query}`).then((res) => {
                if (res.data?.data?.url) {
                  window.open(res.data?.data?.url, "_blank");
                }
                setExportLoading(false);
              });
            }}
            style={{ marginBottom: 15 }}
          >
            All
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              setExportLoading(true);
              V3.get(`recruiters/export/excel/crm?company=false`).then(
                (res) => {
                  if (res.data?.data?.url) {
                    window.open(res.data?.data?.url, "_blank");
                  }
                  setExportLoading(false);
                }
              );
            }}
            style={{ marginBottom: 15 }}
          >
            Normal
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              setExportLoading(true);
              V3.get(`recruiters/export/excel/crm?company=true`).then((res) => {
                if (res.data?.data?.url) {
                  window.open(res.data?.data?.url, "_blank");
                }
                setExportLoading(false);
              });
            }}
            style={{ marginBottom: 15 }}
          >
            Company Recruiters
          </Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default Team;
