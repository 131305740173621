import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";
import CountUp from "react-countup";
import { V3 } from "../../lib/api";
export default function Dashboard() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    V3.get(`/settings/lengths`).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<SyncLoader color="#fff" />}
      styles={{
        wrapper: {
          height: "100%",
        },
      }}
    >
      <Table
        component={Paper}
        style={{ overflow: "hidden", position: "relative", height: "100%" }}
      >
        <React.Fragment>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total companies
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.companies : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total jobs
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.jobs : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total recruiters
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.recruiters : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Kandidaten
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.candidates : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total users
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.users : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total CRM Kandidaten
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.crmCandidates : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total CRM Companies
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.crmCompanies : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Bewerbungen
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.applications : 0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total geteilte Links
              </TableCell>
              <TableCell align="right">
                <CountUp end={data ? data.sharedLinks : 0} />
              </TableCell>
            </TableRow>
          </TableBody>
        </React.Fragment>
      </Table>
    </LoadingOverlay>
  );
}
