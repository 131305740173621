import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import SendNewProductEmailTo from "./SendNewProductEmailTo";
import RegisteredUsers from "./RegisteredUsers";
import PropTypes from "prop-types";
import Referrals from "./Referrals";
import MultipleUsersChart from "./MultipleUsersChart";
import MultipleSharedLinksChart from "./MultipleSharedLinksChart";
import Progress from "../Progress";
import { V3 } from "../../../../lib/api";
import TopSellers from "./TopSellers";
import EmployeesOrders from "./EmployeesOrders";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ReferralsDashboard({ company, setCompany }) {
  const [value, setValue] = React.useState(0);
  const [progress, setProgress] = React.useState(null);
  const [length, setLength] = React.useState(0);
  const [companyData, setCompanyData] = React.useState(null);

  useEffect(() => {
    V3.get(`/company-referrals/analytics/my/${company._id}`).then(
      (response) => {
        setProgress(response.data.progress);
        setLength(response.data.length);
        setCompanyData(response.data.companyData);
      }
    );
  }, [company]);

  return (
    <>
      <Paper style={{ marginBottom: 30 }}>
        <Tabs
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={(e, val) => setValue(val)}
        >
          <Tab label="Start" {...a11yProps(0)} />
          <Tab label="Registrierte Mitarbeiter" {...a11yProps(1)} />
          <Tab label="Geteilte Links" {...a11yProps(2)} />
          <Tab label="Mitarbeiter" {...a11yProps(3)} />
          <Tab label="Top Mitarbeiter" {...a11yProps(4)} />
          <Tab label="Mitarbeiter Bestellungen" {...a11yProps(5)} />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        {length > 0 ? (
          <Progress data={progress} companyData={companyData} />
        ) : null}
        <SendNewProductEmailTo companyData={company} setCompany={setCompany} />
        <Referrals companyId={company._id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MultipleUsersChart companyId={company._id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MultipleSharedLinksChart companyId={company._id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <RegisteredUsers companyId={company._id} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TopSellers companyId={company._id} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EmployeesOrders companyId={company._id} />
      </TabPanel>
    </>
  );
}

export default ReferralsDashboard;
