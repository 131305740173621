import "react-step-progress-bar/styles.css";

import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import styled from "styled-components";

const Present = styled.div`
  background: ${(props) => (props.accomplished ? "#00d94f" : "#ccc")};
  padding: 3px;
  border-radius: 50px;
  border: 3px solid #fff;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 5px 0px rgba(0, 0, 0, 0.042), 0px 1px 10px 0px rgba(0, 0, 0, 0.036);

  svg {
    color: ${(props) => (props.accomplished ? "#fff" : "#000")};
  }
`;
const Wrapper = styled.div`
  transform: translateY(-20px);
  p {
    content: "${(props) => props.percentage}%";
    text-align: center;
  }
`;

const ProgressWrapper = styled.div`
  .RSPBprogressBar {
    z-index: 999 !important;
  }
  .RSPBprogression {
    cursor: pointer;
    &:after {
      content: ${(props) =>
        `${props.percentage}% Ihrer Mitarbeiter haben sich bereits als Online Recruiter registriert`};
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      background-color: #fff;
      top: 35px;
      text-align: center;
      line-height: 30px;
      border-radius: 8px;
      border: 1px solid #ccc;
      opacity: 0;
      transition: 0.3s ease;
      z-index: 99999;
      padding: 12px 32px;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`;

function Progress({ data, companyData: company }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (data && company && company.totalEmployees) {
      setPercentage(
        (data.totalEmployeesRegisteredAmount / company.totalEmployees) * 100
      );
    }
  }, [data, company]);

  if (
    !company ||
    !company.totalEmployees ||
    (company &&
      company.bonuses &&
      company.bonuses.length < 1 &&
      company.totalEmployees < 1)
  ) {
    return null;
  }

  const { bonuses } = company;

  return (
    <ProgressWrapper
      percentage={percentage.toFixed(2)}
      style={{ marginBottom: 60, marginTop: 60 }}
    >
      <ProgressBar
        percent={percentage}
        filledBackground="#00d94f"
        stepPositions={bonuses
          .filter((el) => !el?.completed)
          .map((el) => el.amount)}
      >
        {bonuses
          .filter((el) => !el?.completed)
          .map((element, index) => (
            <Step transition="scale" key={index}>
              {({ accomplished, position }) => (
                <Wrapper>
                  <p>{element.amount}%</p>
                  <Tooltip title={element.description}>
                    <Present accomplished={accomplished}>
                      {accomplished && <CheckIcon />}
                    </Present>
                  </Tooltip>
                </Wrapper>
              )}
            </Step>
          ))}
      </ProgressBar>
    </ProgressWrapper>
  );
}

export default Progress;
