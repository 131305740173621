import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  AUTH_ERROR,
  GET_SETTINGS,
  USER_LOADED,
  USER_LOADING,
} from "../redux/types";
import { V3 } from "../lib/api";
import { useSnackbar } from "notistack";

function GlobalWrapper({ children }) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: USER_LOADING });
      try {
        const settingsRest = await V3.get(`/settings`);
        dispatch({
          type: GET_SETTINGS,
          payload: settingsRest.data.data,
        });
        const res = await V3.get(`/auth/account`);
        if (
          res.data.data.role === "admin" ||
          res.data.data.role === "employee" ||
          res.data.data.role === "editor"
        ) {
          localStorage.setItem("token", res.data.token);
          dispatch({
            type: USER_LOADED,
            payload: res.data,
          });
        } else {
          enqueueSnackbar("Email doesn't exists!", {
            variant: "error",
          });
          dispatch({
            type: AUTH_ERROR,
          });
        }
      } catch (error) {
        enqueueSnackbar("No token, authorization denied", {
          variant: "error",
        });
        dispatch({
          type: AUTH_ERROR,
        });
      }
    };
    fetchData();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}

export default GlobalWrapper;
