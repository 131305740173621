import React, { useEffect, useState } from "react";
import Card from "../Card";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { V3 } from "../../lib/api";
import { Link } from "react-router-dom";
import Loader from "../Loader";

function TopProductsSold({ title, withDate }) {
  const [startDate, setStartDate] = useState(
    `01.01.${new Date().getFullYear()}`
  );
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (withDate) {
      V3.get(`statistic/order`, {
        params: {
          startDate: moment(startDate).format("DD/MM/YYYY"),
          endDate: moment(endDate).format("DD/MM/YYYY"),
        },
      }).then((res) => {
        setData(res.data.data);
        setLoading(false);
      });
    } else {
      V3.get(`statistic/order/top`).then((res) => {
        setData(res.data.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Card
      title={title}
      button={
        withDate ? (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        ) : null
      }
    >
      <Loader loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">Sold</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((el) => el?._id?.length > 0)
              .map((el) => (
                <TableRow>
                  <TableCell>
                    {el?._id?.length ? (
                      <Link
                        to={`/products/edit/${el?._id[0]._id}`}
                        target="_blank"
                      >
                        <strong> {el?._id[0].title}</strong>
                      </Link>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="right">{el.count}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Loader>
    </Card>
  );
}

export default TopProductsSold;
