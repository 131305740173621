import React, { useEffect, useState } from "react";
import Loader from "../../components/GlobalLoader";
import { Grid, Paper, Button, Typography, Tabs, Tab } from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import PropTypes from "prop-types";
import { blogCategoriesDE, blogCategoriesEN } from "../../utils/data";
import Editor from "../../components/Editor";
import MediaChoose from "../../components/Media/Chooser";
import styled from "styled-components";
import { V3 } from "../../lib/api";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";

const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function EditBlog() {
  const [message, setMessage] = useState(null);
  const history = useHistory();
  const params = useParams();
  const [blog, setBlog] = useState(null);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    V3.get(`blog/${params.id}`).then((res) => {
      setBlog(res.data.data);
    });
  }, [params]);

  const _handleSubmit = async (values, formikAPi) => {
    setMessage(null);
    const data = { ...values };

    if (data?.en?.category?.key) {
      data.en.category = data?.en?.category?.key;
    }
    if (data?.de?.category?.key) {
      data.de.category = data?.de?.category?.key;
    }
    if (data?.image?._id) {
      data.image = data?.image?._id;
    }

    data.createdBy = data?.team?.key;
    try {
      await V3.put(`blog/${params.id}`, data);
      history.push("/blog");
      formikAPi.setSubmitting(false);
    } catch (error) {
      formikAPi.setSubmitting(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    V3.get(`teams`).then((res) =>
      setTeam(
        res.data.data.map((el) => {
          return { key: el._id, value: el.name, label: el.name };
        })
      )
    );
  }, []);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        image: Yup.object().nullable().required("Required"),
        team: Yup.object().nullable().required("Required"),
        en: Yup.object({
          title: Yup.string().nullable().required("Required"),
          description: Yup.string().nullable().required("Required"),
          category: Yup.object().nullable().required("Required"),
        }),
        de: Yup.object({
          title: Yup.string().nullable().required("Required"),
          description: Yup.string().nullable().required("Required"),
          category: Yup.object().nullable().required("Required"),
        }),
      })}
      onSubmit={_handleSubmit}
      enableReinitialize
      initialValues={{
        tab: 0,
        image: blog?.image,
        team: blog?.createdBy?._id
          ? {
              key: blog?.createdBy?._id,
              label: blog?.createdBy?.name,
              value: blog?.createdBy?.name,
            }
          : null,
        en: blog?.en
          ? {
              ...blog?.en,
              category: blog?.en?.category
                ? {
                    key: blog?.en?.category,
                    label: blog?.en?.category,
                    value: blog?.en?.category,
                  }
                : null,
            }
          : null,
        de: blog?.de
          ? {
              ...blog?.de,
              category: blog?.de?.category
                ? {
                    key: blog?.de?.category,
                    label: blog?.de?.category,
                    value: blog?.de?.category,
                  }
                : null,
            }
          : null,
      }}
    >
      {({ isSubmitting, values, setFieldValue, errors }) => (
        <Form>
          {(isSubmitting || !blog) && <Loader />}

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper style={{ marginBottom: 30 }}>
                <Tabs
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={values.tab}
                  onChange={(e, val) => setFieldValue("tab", val)}
                >
                  <Tab label="German" {...a11yProps(0)} />
                  <Tab label="English" {...a11yProps(1)} />
                </Tabs>
              </Paper>
              {message && (
                <Alert severity="error" style={{ marginBottom: 30 }}>
                  {message}
                </Alert>
              )}

              <TabPanel value={values.tab} index={0}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={Autocomplete}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="team"
                        name="team"
                        options={team}
                        style={{ margin: 0 }}
                        textFieldProps={{
                          label: "Team",
                          variant: "outlined",
                          style: { margin: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="de.title"
                        label="Title"
                        name="de.title"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Autocomplete}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="de.category"
                        name="de.category"
                        options={blogCategoriesDE}
                        style={{ margin: 0 }}
                        textFieldProps={{
                          label: "Category",
                          variant: "outlined",
                          style: { margin: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Editor
                        value={values?.de?.description}
                        onChange={(value) =>
                          setFieldValue("de.description", value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
              <TabPanel value={values.tab} index={1}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={Autocomplete}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="team"
                        name="team"
                        options={team}
                        style={{ margin: 0 }}
                        textFieldProps={{
                          label: "Team",
                          variant: "outlined",
                          style: { margin: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="en.title"
                        label="Title"
                        name="en.title"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Autocomplete}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="en.category"
                        name="en.category"
                        options={blogCategoriesEN}
                        style={{ margin: 0 }}
                        textFieldProps={{
                          label: "Category",
                          variant: "outlined",
                          style: { margin: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Editor
                        value={values?.en?.description}
                        onChange={(value) =>
                          setFieldValue("en.description", value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
              <Paper style={{ marginBottom: 30 }}>
                <MediaChoose onChange={(val) => setFieldValue("image", val)}>
                  <div
                    style={{
                      minHeight: 250,
                      lineHeight: "250px",
                      cursor: "pointer",
                    }}
                  >
                    {values.image ? (
                      <Preview>
                        <img
                          src={values.image ? values.image.lg.url : ""}
                          alt={values.image ? values.image.lg.url : ""}
                        />
                      </Preview>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        Klicken Sie, um ein Bild auszuwählen
                      </p>
                    )}
                  </div>
                </MediaChoose>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  Update
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditBlog;
