import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  useTheme,
} from "@material-ui/core";
import Editor from "../../Editor";
import FileUpload from "./FileUpload";

import Draggable from "react-draggable";
import styled from "styled-components";
import { uploadFile } from "../../../utils/AWS";
import { V3 } from "../../../lib/api";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";

function PaperComponent(props) {
  const theme = useTheme();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{ boxShadow: theme.shadows[24], borderWidth: 2 }}
      />
    </Draggable>
  );
}

const PasteArea = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  border: solid 1px ${(props) => props.theme.palette.primary.main};
  border-radius: 8px;
  color: ${(props) => props.theme.palette.primary.main};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Add({ handleSubmit }) {
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (open) {
      V3.get(`notes-template?limit:1000`).then((res) => {
        setTemplates(
          res.data.data.map((el) => {
            return {
              key: el._id,
              value: el._id,
              label: el?.title,
              description: el?.description,
            };
          })
        );
      });
    }
  }, [open]);

  const handlenSubmit = async ({ title, note, files }) => {
    const data = { title, note };

    if (files?.length) {
      const file = files[0];

      const url = await uploadFile("note-emails", file);

      if (url) {
        data.email = { url, name: file?.name || "" };
      }
    }

    handleSubmit(data);
    setOpen(false);
  };

  const handlePaste = (e, setFieldValue) => {
    if (e.clipboardData.files.length) {
      const newFiles = [];
      for (let index = 0; index < e.clipboardData.files.length; index++) {
        const fileObject = e.clipboardData.files[index];
        const file = {
          getRawFile: () => fileObject,
          name: fileObject.name,
          size: fileObject.size,
          status: 2,
          progress: 0,
        };
        newFiles.push(file.getRawFile());
      }
      setFieldValue("files", newFiles);
    }
  };
  return (
    <>
      <Button color="primary" variant="outlined" onClick={() => setOpen(true)}>
        Add
      </Button>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        BackdropProps={{
          open: false,
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Note
        </DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            note: Yup.string(),
            title: Yup.string().required("Required"),
          })}
          onSubmit={handlenSubmit}
          initialValues={{ files: [], note: "" }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  style={{ margin: 0 }}
                />
                <div style={{ marginBottom: 15 }}>
                  <FileUpload
                    handleChange={(data) => setFieldValue("files", data)}
                    files={values.files}
                  />
                </div>
                <PasteArea onPaste={(e) => handlePaste(e, setFieldValue)}>
                  Paste Area
                </PasteArea>

                <Field
                  component={Autocomplete}
                  fullWidth
                  name="template"
                  options={templates}
                  onChange={(e, val) => {
                    setFieldValue("template", val);
                    setFieldValue("note", val?.description || "");
                  }}
                  textFieldProps={{
                    label: "Template",
                    variant: "outlined",
                    style: { margin: 0, marginBottom: 15 },
                  }}
                />

                <Editor
                  value={values.note}
                  onChange={(value) => setFieldValue("note", value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Add;
