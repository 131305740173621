import React, { useState } from "react";

import { V3 } from "../../../lib/api";

import TableComponent from "../../Table";
import { Button, Tooltip } from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";
import Moment from "react-moment";
import styled from "styled-components";
const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  .last-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 590px) {
      flex-direction: column;
      align-content: flex-start !important;
    }
    h4 {
      font-size: 14px;
      padding: 11px 24px;
      color: #fff;
      color: ${(props) => (props.published ? "#344054" : "#fff")};
      background-color: ${(props) => (props.published ? "#fff" : "#195079")};
      border-radius: 8px;
      border: 1px solid ${(props) => (props.published ? "#D0D5DD" : "#195079")};
      font-weight: 500;
    }
  }
`;

const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 5px;
  width: max-content;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

function AllInOnes({ company }) {
  const [data, setData] = useState({});
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [published, setPublished] = useState(true);

  const columns = [
    {
      id: "firstName",
      Header: "First Name",
      accessor: (d) => d.firstName,
      Cell: (props) => <strong>{props.original?.firstName || "-"}</strong>,
    },
    {
      id: "lastName",
      Header: "Last Name",
      accessor: (d) => d.lastName,
      Cell: (props) => <strong>{props.original?.lastName || "-"}</strong>,
    },
    {
      id: "message",
      Header: "Message",
      accessor: (d) => d.message,
      Cell: (props) => (
        <>
          {props.original?.message ? (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{ __html: props.original?.message }}
                ></div>
              }
            >
              <InfoIcon />
            </Tooltip>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      id: "createdAt",
      Header: "Created",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original?.createdAt}</Moment>,
    },
  ];

  console.log("company", company);

  return (
    <>
      <HeadWrapper published={published}>
        <ButtonsWrapper>
          <Button
            onClick={() => setPublished(false)}
            variant="contained"
            className={`custom-btn ${!published ? "active" : "not-Active"}`}
            color="primary"
          >
            Archiviert
          </Button>
          <Button
            onClick={() => setPublished(true)}
            variant="contained"
            className={`custom-btn ${published ? "active" : "not-Active"}`}
            color="primary"
          >
            Nicht archiviert
          </Button>
        </ButtonsWrapper>
        <div className="last-wrapper">
          <h4>
            Konto Anschriften:&nbsp;&nbsp;
            {company?.allInOne || 0}
          </h4>
        </div>
      </HeadWrapper>

      <TableComponent
        data={applications}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        defaultFiltered={[
          {
            id: "archived",
            value: !published,
          },
        ]}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);
          V3.get(`all-in-one/my/${company?._id}${query}`).then((res) => {
            setData(res.data);
            setApplications(res.data.data);
            setLoading(false);
          });
        }}
        noDataText="No data"
        filterable={false}
      />
    </>
  );
}

export default AllInOnes;
