import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Pages from "./Pages";
import { useSelector } from "react-redux";
import GlobalLoader from "../GlobalLoader";
import ScrollToTop from "./ScrollToTop";
function Routes({ theme, setTheme }) {
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  return (
    <Router basename="/">
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <Route
          render={({ location }) => (
            <React.Fragment>
              <ScrollToTop />
              <Pages
                location={location}
                isAuthenticated={isAuthenticated}
                setTheme={setTheme}
                theme={theme}
              />
            </React.Fragment>
          )}
        />
      )}
    </Router>
  );
}

export default Routes;
