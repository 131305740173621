import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 236.8 348.94"
        {...props}>
        <g data-name="Layer 2">
            <path
                d="m204.71 93.7 13.88-21.15-16.87-11.08-12.21 18.6A118.81 118.81 0 0 0 174 70.19l38.8-59.11L195.92 0l-40.81 62.15a118.41 118.41 0 0 0-125.42 191L13.11 278.4 30 289.48l14.57-22.19a117.07 117.07 0 0 0 15.22 10.34l-39.56 60.23 16.88 11.08 41.21-62.76A118.41 118.41 0 0 0 204.71 93.7zM30.2 193a90.11 90.11 0 0 1 88.44-108.42 90 90 0 0 1 20.21 2.37L46 228.34A89.83 89.83 0 0 1 30.2 193zm30 50.47 98.2-149.55a89.51 89.51 0 0 1 9.45 5.4c2.12 1.39 4.16 2.86 6.13 4.39L75.37 253.88a90.81 90.81 0 0 1-15.18-10.39zm133.62-19.3a90.2 90.2 0 0 1-99.36 37.43l94.2-143.46a90.22 90.22 0 0 1 5.15 106.05z"
                fill={props.color ? props.color : '#d91f33'}
                data-name="Layer 1"
            />
        </g>
    </svg>
);

export default SvgComponent;
