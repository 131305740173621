import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AiOutlineDelete } from "react-icons/ai";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
function DeleteAlert(props) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    V3.delete(`/alerts/${props.alertId}`)
      .then((res) => {
        props.handleDelete();
        enqueueSnackbar("Alert deleted successfully", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
      });
  };
  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <AiOutlineDelete />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete alert</DialogTitle>
        <DialogContent>
          Are u sure u want to delete this alert? You wont be able to recover
          this alert anymore!
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button
            onClick={handleConfirmation}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteAlert;
