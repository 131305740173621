import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Chip,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../GlobalLoader";
import styled from "styled-components";
const PastArea = styled.div`
  margin-top: 5px;
  padding: 10px;
  font-style: italic;
  color: ${(props) => props.theme.palette.primary.main};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 8px;
  height: 150px;
`;

function New({ open, setOpen, handleSubmit }) {
  const handlePaste = (e, setFieldValue) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const file = {
        getRawFile: () => fileObject,
        name: fileObject.name,
        size: fileObject.size,
        status: 2,
        progress: 0,
      };
      setFieldValue("files", [file]);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Add Chat Image</DialogTitle>
      <Formik
        validationSchema={Yup.object().shape({
          files: Yup.array()
            .min(1, "Paste at least one image")
            .required("Required"),
        })}
        onSubmit={handleSubmit}
        initialValues={{ files: [], description: "", title: "" }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            {isSubmitting && <Loader />}
            <DialogContent onPaste={(e) => handlePaste(e, setFieldValue)}>
              {values.files.map((el) => (
                <Chip
                  label={el.name}
                  onDelete={() => setFieldValue("files", [])}
                />
              ))}
              <div>
                <PastArea>Paste Area</PastArea>
              </div>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  close
                </Button>
                <Button variant="outlined" color="primary" type="submit">
                  submit
                </Button>
              </DialogActions>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default New;
