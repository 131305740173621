import {
  GET_JOB_VISITS,
  GET_JOB_VISIT,
  ADD_JOB_VISIT,
  DELETE_JOB_VISIT,
  JOB_VISITS_LOADING,
} from "../types";

const initialState = {
  jobVisits: [],
  jobVisit: null,
  data: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOB_VISITS:
      return {
        ...state,
        jobVisits: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_JOB_VISIT:
      return {
        ...state,
        jobVisit: action.payload.data,
        loading: false,
      };
    case DELETE_JOB_VISIT:
      return {
        ...state,
        jobVisits: state.jobVisits.filter(
          (jobVisit) => jobVisit._id !== action.payload
        ),
      };
    case ADD_JOB_VISIT:
      return {
        ...state,
        jobVisits: [...action.payload, ...state.jobVisits],
      };

    case JOB_VISITS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
