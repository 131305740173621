import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
import { V3 } from "../../../lib/api";
import { SET_INTERESTED } from "../../../redux/types";

function EditInterested({ data, job }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values, formApi) => {
    V3.put(`jobs/${job}/interested/${data._id}`, values).then((res) => {
      dispatch({ type: SET_INTERESTED, payload: res.data?.data?.interested });
      setOpen(false);
    });
  };

  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditIcon />
      </span>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Interessenten ohne Tel</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            linkedinProfile: Yup.string().nullable(),
            name: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            name: data?.name,
            linkedinProfile: data?.linkedinProfile,
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="name"
                  style={{ margin: 0, marginBottom: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Linkedin Profile"
                  name="linkedinProfile"
                  style={{ margin: 0, marginBottom: 15 }}
                />

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    close
                  </Button>
                  <Button variant="outlined" color="primary" type="submit">
                    submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default EditInterested;
