import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";
const ImageCard = styled.div`
  border: 2px solid ${(props) => props.theme.palette.divider};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 5px;
  .img {
    height: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  &:hover {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const UploadItem = (props) => {
  const { file, progress, data } = props.file;

  const [preview, setPreview] = React.useState(null);

  React.useEffect(() => {
    let reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <ImageCard>
      <div
        className="img"
        style={{
          background: `url(${data ? data.sm.url : preview})`,
        }}
      />
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={progress}
      />
    </ImageCard>
  );
};

export default UploadItem;
