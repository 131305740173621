import Table from "../../../Table";
import React, { useState } from "react";
import { V3 } from "../../../../lib/api";

function OtherProducts(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);

  const getProducts = (query) => {
    setLoading(true);
    V3.get(`/products/my/others/${props.companyId}${query}`).then(
      (response) => {
        setData(response.data);
        setProducts(response.data.data);
        setLoading(false);
      }
    );
  };

  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => (
        <div className="image-wrapper">
          <img
            src={
              props.original.gallery?.length
                ? props.original.gallery[0]?.lg?.url || "/images/not-found.jpg"
                : "/images/not-found.jpg"
            }
            alt={props.original?.title}
            style={{
              width: 50,
              height: 50,
              borderRadius: 8,
              overflow: "hidden",
            }}
          />
        </div>
      ),
    },
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original.title}</strong>,
    },

    {
      id: "type",
      Header: "Typ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.type === 1 ? "Gutschein" : "Aktivität"}</strong>
      ),
    },
  ];

  return (
    <>
      <Table
        data={products}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          getProducts(query);
        }}
        filterable={false}
        noDataText="Keine Produkte"
        minRows={5}
      />
    </>
  );
}

export default OtherProducts;
