import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Page/Header";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import Button from "@material-ui/core/Button";
import DraggableTable from "../components/DraggableProductTable";
import { useDebounce } from "use-debounce";
import { V3 } from "../lib/api";
import { GET_PRODUCTS, PRODUCTS_LOADING } from "../redux/types";

function Products() {
  const { products, data } = useSelector((state) => state.product);

  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: PRODUCTS_LOADING,
    });
    V3.get(`/products/all?search=${search}&limit=999`).then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <React.Fragment>
      <Header title="Products" size={data.total} icon={<GroupIcon />}>
        <Button
          component={Link}
          to="/products/new"
          color="primary"
          variant="contained"
          style={{ float: "right" }}
        >
          Neu
        </Button>
      </Header>
      <DraggableTable data={products} search={search} setSearch={setSearch} />
    </React.Fragment>
  );
}

export default Products;
