import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import ImageUpload from "../../components/imageUpload";
import Actions from "../../components/Table/Actions";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../../lib/api";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Alert from "@material-ui/lab/Alert";
import Loader from "../../components/GlobalLoader";
import Card from "../Card";
import QuizTemplateAnswers from "./Answers";

function QuizTemplateQuestions({ template }) {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  //   for editing

  const [question, setQuestion] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  useEffect(() => {
    if (openEdit) {
      setAvatarPreview(question?.image?.md?.url);
      setAvatar(null);
    } else {
      setAvatarPreview(null);
      setAvatar(null);
    }
  }, [question, openEdit]);

  const onAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    setQuestions(template.questions || []);
  }, [template]);

  const confirm = useConfirm();

  const handleUpdateQuestions = (newData) => {
    setQuestions(newData);
  };

  const handleDelete = (id) => {
    confirm({
      title: `Delete Quiz`,
      description: `Are you sure you wont to delete this quiz.`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/quiz-template/${template._id}/questions/${id}`).then(() => {
        setQuestions(questions.filter((el) => el._id !== id));
        setLoading(false);
      });
    });
  };

  const handleSubmit = async (values, formApi) => {
    const data = { ...values };

    if (avatar) {
      const formData = new FormData();

      formData.append("images", avatar);

      try {
        const res = await V3.post("medias", formData);
        if (res?.data?.data?.length) {
          data.image = res?.data?.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    try {
      const res = await V3.post(
        `quiz-template/${template._id}/questions`,
        data
      );
      setQuestions(res.data.data.questions);
      setOpen(false);
    } catch (error) {
      formApi.setSubmitting(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const handleSubmitUpdate = async (values, formApi) => {
    const data = { ...values };

    if (avatar) {
      const formData = new FormData();

      formData.append("images", avatar);

      try {
        const res = await V3.post("medias", formData);
        if (res?.data?.data?.length) {
          data.image = res?.data?.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    console.log("data", data);
    try {
      const res = await V3.put(
        `quiz-template/${template._id}/questions/${question._id}`,
        data
      );
      setQuestions(res.data.data.questions);
      setOpenEdit(false);
    } catch (error) {
      formApi.setSubmitting(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const columns = [
    {
      id: "image",
      Header: "Image",
      accessor: (d) => d.image,
      filterable: false,
      Cell: (props) => <Avatar src={props.original?.image?.md?.url} />,
    },
    {
      id: "title",
      Header: "Title",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original?.title}</strong>,
    },
    {
      id: "answers",
      Header: "Answers",
      accessor: (d) => d.answers,
      Cell: (props) => (
        <QuizTemplateAnswers
          answers={props.original.answers}
          template={template._id}
          questions={props.original._id}
          handleUpdateQuestions={handleUpdateQuestions}
        />
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Question">
            <span>
              <EditIcon
                onClick={() => {
                  setQuestion(props.original);
                  setOpenEdit(true);
                }}
              />
            </span>
          </Tooltip>

          <Tooltip title="Delete this Question">
            <span
              className="action fi"
              onClick={() => handleDelete(props.original._id)}
            >
              <DeleteIcon />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Card
        title={"Questions"}
        button={
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      >
        <Table
          data={questions}
          columns={columns}
          loading={loading}
          page={1}
          pages={1}
          showPaginationBottom={false}
          noDataText="No Data"
          filterable={false}
          minRows={5}
        />
      </Card>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {" "}
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Create new Question</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Title"
                      name="title"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ImageUpload
                      imagePreviewUrl={avatarPreview}
                      onImageChange={onAvatarChange}
                      id="quiz-image"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        maxWidth="sm"
        fullWidth
      >
        {" "}
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          //   enableReinitialize={true}
          onSubmit={handleSubmitUpdate}
          initialValues={{
            title: question?.title,
            image: question?.image?._id,
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Update Question</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {message && (
                    <div
                      style={{ width: "100%", padding: 10, marginBottom: 15 }}
                    >
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  )}

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Title"
                      name="title"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ImageUpload
                      imagePreviewUrl={avatarPreview}
                      onImageChange={onAvatarChange}
                      id="quiz-image"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenEdit(false)} color="primary">
                  Close
                </Button>
                <Button color="primary" autoFocus type="submit">
                  Update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default QuizTemplateQuestions;
