import React from "react";
import CrawledJobs from "../components/Company/crawledJobs";

function Jobs() {
  return (
    <>
      <CrawledJobs />
    </>
  );
}

export default Jobs;
