import * as React from "react";

const SVGComponent = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9999 7.5L17.9999 2.5M17.9999 2.5H12.9999M17.9999 2.5L11.3332 9.16667M8.83321 4.16667H6.99988C5.59975 4.16667 4.89968 4.16667 4.3649 4.43915C3.8945 4.67883 3.51205 5.06129 3.27236 5.53169C2.99988 6.06647 2.99988 6.76654 2.99988 8.16667V13.5C2.99988 14.9001 2.99988 15.6002 3.27236 16.135C3.51205 16.6054 3.8945 16.9878 4.3649 17.2275C4.89968 17.5 5.59975 17.5 6.99988 17.5H12.3332C13.7333 17.5 14.4334 17.5 14.9682 17.2275C15.4386 16.9878 15.821 16.6054 16.0607 16.135C16.3332 15.6002 16.3332 14.9001 16.3332 13.5V11.6667"
      stroke="#98A2B3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGComponent;
