import React from "react";
import { Field } from "formik";
import { TextField as FormikTextField } from "material-ui-formik-components/TextField";
import KeyboardDatePicker from "../../../../Views/crm/candidates/KeyboardDatePicker";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Card from "../../../Card";
const Position = styled.div`
  border: 2px solid ${(props) => props.theme.palette.divider};
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  .header {
    display: flex;
    padding: 15px;
    border-bottom: 2px solid ${(props) => props.theme.palette.divider};

    > div {
      width: 50%;

      &:last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
`;
function Offer({ values, setFieldValue, company }) {
  const addServiceItem = () => {
    setFieldValue("services", {
      description: values.services.description,
      items: [
        ...values.services.items,
        { name: "", jobs: 0, description: "", price: 0 },
      ],
    });
  };

  const calculateAmount = (discountAmount) => {
    const tmpTotalPackage = values.package.items.reduce((a, b) => {
      const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
      return totalRetail;
    }, 0);
    const tmpTotalServices = values.services.items.reduce((a, b) => {
      const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
      return totalRetail;
    }, 0);
    const tmpTotalAllInOne = values.allInOne.items.reduce((a, b) => {
      const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
      return totalRetail;
    }, 0);
    const tmpTotalJobQuiz = values.jobQuiz.items.reduce((a, b) => {
      const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
      return totalRetail;
    }, 0);

    const discount = parseInt(
      discountAmount ? discountAmount : values.discount || 0
    );

    setFieldValue(
      "amount",
      tmpTotalPackage +
        tmpTotalServices +
        tmpTotalAllInOne +
        tmpTotalJobQuiz -
        discount
    );
  };

  const totalPackage = values.package.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);
  const totalServices = values.services.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);
  const totalAllInOne = values.allInOne.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);
  const totalJobQuiz = values.jobQuiz.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);

  const updatePackageItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.package;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("package", data);
  };
  const updatePackageItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.package;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("package", data);
  };
  const updateServiceItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.services;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("services", data);
  };
  const updateServiceItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.services;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("services", data);
  };

  const updatePackageDescription = (evt) => {
    const value = evt.target.value;

    const data = values.package;

    data.description = value;
    setFieldValue("package", data);
  };
  const updateServiceDescription = (evt) => {
    const value = evt.target.value;

    const data = values.services;

    data.description = value;
    setFieldValue("services", data);
  };
  const updateAllInOneDescription = (evt) => {
    const value = evt.target.value;

    const data = values.allInOne;

    data.description = value;
    setFieldValue("allInOne", data);
  };

  const updateAllInOneItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.allInOne;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("allInOne", data);
  };

  const updateAllInOneItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.allInOne;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("allInOne", data);
  };
  const updateJobQuizDescription = (evt) => {
    const value = evt.target.value;

    const data = values.jobQuiz;

    data.description = value;
    setFieldValue("jobQuiz", data);
  };

  const updateJobQuizItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.jobQuiz;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("jobQuiz", data);
  };

  const updateJobQuizItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.jobQuiz;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("jobQuiz", data);
  };

  const contactPersons = company.contactPersons.map((person) => {
    return {
      key: person.email,
      name: person.firstName + " " + person.lastName,
      email: person.email,
      phone: person.phone,
      label: person.firstName + " " + person.lastName,
      value: person.firstName + " " + person.lastName,
    };
  });

  return (
    <React.Fragment>
      <Divider style={{ margin: "30px 0" }} />

      <Card
        title="Offer info"
        contentStyles={{ padding: 15 }}
        button={
          <>
            <Field
              component={KeyboardDatePicker}
              label="Start Date"
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="startDate"
              size="small"
              style={{ maxWidth: 200, marginRight: 15 }}
            />
            <Field
              component={KeyboardDatePicker}
              label="End Date"
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="endDate"
              size="small"
              style={{ maxWidth: 200 }}
            />
          </>
        }
      >
        <Paper style={{ padding: 15, marginBottom: 15 }}>
          <Field
            component={Autocomplete}
            fullWidth
            required
            name="contactPerson"
            options={contactPersons}
            textFieldProps={{
              label: "Contact Person",
              variant: "outlined",
              style: { margin: 0 },
            }}
            style={{ margin: 0 }}
          />
        </Paper>
        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 1"
                fullWidth
                value={values.package.description}
                onChange={updatePackageDescription}
                disabled
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalPackage}</strong>
              </span>
            </div>
          </div>

          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paket</TableCell>
                  <TableCell align="left">Anzahl Stellen</TableCell>
                  <TableCell align="left">Laufzeit Monate</TableCell>
                  <TableCell align="right">Paketpreis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.package.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Paket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl Stellen"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Laufzeit Monate"
                        fullWidth
                        value={row.months}
                        name="months"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Paketpreis"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          if (e?.target?.value) {
                            e.target.value = parseInt(e.target.value);
                          }
                          updatePackageItemPrice(e, index);
                          calculateAmount();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Position>

        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 2"
                fullWidth
                value={values.services.description}
                onChange={updateServiceDescription}
                disabled
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalServices}</strong>
              </span>
            </div>
          </div>
          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Leistungspaket</TableCell>
                  <TableCell align="left">Anzahl</TableCell>
                  <TableCell align="left">Betrifft folgende Stellen</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.services.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Leistungspaket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Betrifft folgende Stellen"
                        fullWidth
                        value={row.description}
                        name="description"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Preis Zusatzdienstleistungen"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          updateServiceItemPrice(e, index);
                          calculateAmount();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <Button onClick={addServiceItem}>Add item</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Position>
        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 3"
                fullWidth
                value={values.allInOne.description}
                onChange={updateAllInOneDescription}
                disabled
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalAllInOne}</strong>
              </span>
            </div>
          </div>
          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Leistungspaket</TableCell>
                  <TableCell align="left">Anzahl</TableCell>
                  <TableCell align="left">Betrifft folgende Stellen</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.allInOne.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Leistungspaket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Betrifft folgende Stellen"
                        fullWidth
                        value={row.description}
                        name="description"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Preis Zusatzdienstleistungen"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          updateAllInOneItemPrice(e, index);
                          calculateAmount();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Position>
        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 4"
                fullWidth
                value={values.jobQuiz.description}
                onChange={updateJobQuizDescription}
                disabled
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalJobQuiz}</strong>
              </span>
            </div>
          </div>
          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Leistungspaket</TableCell>
                  <TableCell align="left">Anzahl</TableCell>
                  <TableCell align="left">Betrifft folgende Stellen</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.jobQuiz.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Leistungspaket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updateJobQuizItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updateJobQuizItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Betrifft folgende Stellen"
                        fullWidth
                        value={row.description}
                        name="description"
                        onChange={(e) => updateJobQuizItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Preis Zusatzdienstleistungen"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          updateJobQuizItemPrice(e, index);
                          calculateAmount();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Position>
        <Paper style={{ marginTop: 15, padding: 15 }}>
          <Field
            component={FormikTextField}
            type="number"
            label="Discount"
            variant="outlined"
            name="discount"
            value={values.discount}
            onChange={(e) => {
              setFieldValue("discount", e.target.value);
              calculateAmount(e.target.value);
            }}
            style={{ margin: 0 }}
          />
        </Paper>
      </Card>
    </React.Fragment>
  );
}

export default Offer;
