import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { V3 } from "../../lib/api";

function Asynchronous({
  values,
  setFieldValue,
  setFieldTouched,
  autoUpdateField = false,
  checkConsultant,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [name, setName] = React.useState(
    values.crmCompany ? values.crmCompany.name : ""
  );
  const loading = open && options.length === 0;
  React.useEffect(() => {
    if (open) {
      V3.get(
        `/crm/companies?archived=false&withInvoice=true${
          name ? `&name=${name}` : ""
        }`
      ).then((res) => {
        setOptions(res.data.data);
      });
    }
  }, [name, open]);

  const handleChange = (e) => {
    if (e && e.target) {
      setName(e.target.value ? e.target.value : "");
    }
  };
  const handleSelect = (e, value) => {
    setName(value ? value.name : "");

    setFieldValue("crmCompany", value);
    if (autoUpdateField) {
      setFieldValue("name", value?.name);
      setTimeout(() => setFieldTouched("name", true));
      setFieldValue("website", value?.website);
      setFieldTouched("website", true);
      setFieldValue("state", value?.state);
      setFieldTouched("state", true);
      setFieldValue("city", value?.city);
      setFieldTouched("city", true);
      setFieldValue("street", value?.street);
      setFieldTouched("street", true);
      setFieldValue("zip", value?.zip);
      setFieldTouched("zip", true);
      if (value?.statusId?.invoiceId?.services?.items?.length > 0) {
        const { items } = value?.statusId?.invoiceId?.services;
        const hasActiveSourcing = items.find((item) =>
          item.name.includes("Active Sourcing")
        );

        if (hasActiveSourcing) {
          const amount = parseInt(hasActiveSourcing?.jobs, 10) || 0;
          setFieldValue("activeSourcing", amount);
          setFieldTouched("activeSourcing", true);
        }
      }
      if (value?.statusId?.invoiceId?.contactPerson?.name) {
        const contactPerson = value?.statusId?.invoiceId?.contactPerson;

        const names = contactPerson.name.split(" ");

        setFieldValue("email", contactPerson.email);
        setFieldTouched("email", true);
        if (names.length > 1) {
          setFieldValue("firstname", names[0]);
          setFieldTouched("firstname", true);
          setFieldValue("lastname", names[1]);
          setFieldTouched("lastname", true);
        }
        setFieldValue("phone", contactPerson.phone);
        setFieldTouched("phone", true);
      }
      checkConsultant(value?.consultant, setFieldValue, setFieldTouched);
    }
    if (value?._id)
      V3.get(`companies/${value._id}/check/parent`).then((res) => {
        if (res?.data?.company) {
          setFieldValue("parentCompany", false);
          setFieldValue("daughterCompany", true);
        } else if (res?.data?.companies > 0) {
          setFieldValue("parentCompany", true);
          setFieldValue("daughterCompany", false);
        } else {
          setFieldValue("parentCompany", false);
          setFieldValue("daughterCompany", false);
        }
      });
  };
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={values.crmCompany}
      getOptionSelected={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={handleSelect}
      onInputChange={handleChange}
      inputValue={name}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Crm Company"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default Asynchronous;
