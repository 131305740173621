import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  padding: 60px 30px;
  text-align: center;
  cursor: pointer;
`;

export default function Empty({ isDragActive, open }) {
  return (
    <Wrapper onClick={open}>
      <CloudUploadOutlinedIcon />
      <p>
        {isDragActive ? (
          <>
            <strong>Drop ketu</strong> per te ngarkuar dokumentin
          </>
        ) : (
          <>
            <strong>Click to upload </strong>or drag and drop
          </>
        )}
      </p>
      <p>PNG, JPG or GIF (max. 800x400px)</p>
    </Wrapper>
  );
}
