import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Links from "../../components/Recruiter/Links";
import FreeClicks from "../../components/Recruiter/FreeClicks";
import Loader from "../../components/Loader";
import Orders from "../../components/Recruiter/Orders";

import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import RecruiterBasicInfo from "../../components/RecruiterDashboard/RecruiterBasicInfo";
import GlobalLoader from "../../components/GlobalLoader";
import RecruiterCharts from "../../components/RecruiterDashboard/RecruiterCharts";
import { useParams } from "react-router-dom";
import { V3 } from "../../lib/api";
import { GET_RECRUITER, RECRUITERS_LOADING } from "../../redux/types";
import SharedIntranets from "../../components/Recruiter/SharedIntranets";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ViewRecruiter = () => {
  const [value, setValue] = useState(0);

  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: RECRUITERS_LOADING,
    });
    V3.get(`/recruiters/${id}`).then((res) => {
      dispatch({
        type: GET_RECRUITER,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { recruiter, loading } = useSelector((state) => state.recruiter);

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <Loader loading={loading || !recruiter}>
      <Paper style={{ marginBottom: 30 }}>
        <Tabs
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={(e, val) => setValue(val)}
        >
          <Tab label="Basic Info" {...a11yProps(0)} />

          <Tab label="Charts" {...a11yProps(1)} />
          <Tab label="Shared links" {...a11yProps(2)} />
          <Tab label="Free clicks" {...a11yProps(3)} />
          <Tab label="Bestellungen" {...a11yProps(4)} />
          <Tab label="Shared Intranet" {...a11yProps(4)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <RecruiterBasicInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RecruiterCharts />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Links userId={recruiter?.user?._id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FreeClicks refId={recruiter?._id} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Orders userId={recruiter?.user?._id} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <SharedIntranets userId={recruiter?.user?._id} />
      </TabPanel>
    </Loader>
  );
};

export default ViewRecruiter;
