import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "../../GlobalLoader";
import { useSelector } from "react-redux";
import { V3 } from "../../../lib/api";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { DatePicker } from "material-ui-formik-components/DatePicker";
import { TextField } from "material-ui-formik-components/TextField";

function New({ open, setOpen, handleSubmit }) {
  const [users, setUsers] = useState([]);

  const { job } = useSelector((state) => state.job);

  useEffect(() => {
    if (open) {
      V3.get(`/users?company=${job?.company?._id}&limit=400`).then(
        (response) => {
          const usersData = response.data.data.map((user) => {
            return {
              key: user._id,
              value: user.profile.name,
              label: user.profile.name,
            };
          });

          setUsers(usersData);
        }
      );
    }
  }, [open, job]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add Active Sourcing Onboarding</DialogTitle>
      <Formik
        validationSchema={Yup.object().shape({
          users: Yup.array().nullable(),
          email: Yup.string().email("Select a user or enter email").nullable(),
          phone: Yup.string().nullable(),
          firstName: Yup.string().nullable(),
          lastName: Yup.string().nullable(),
          date: Yup.date().required("Required"),
        })}
        onSubmit={handleSubmit}
        initialValues={{ users: [], date: new Date() }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            {isSubmitting && <Loader />}
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={Autocomplete}
                    fullWidth
                    name="users"
                    options={users}
                    multiple
                    textFieldProps={{
                      label: "User",
                      variant: "outlined",
                      style: { margin: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="firstName"
                    id="firstName"
                    label="First Name"
                    inputVariant="outlined"
                    fullWidth
                    type="text"
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="lastName"
                    id="lastName"
                    label="Last Name"
                    inputVariant="outlined"
                    fullWidth
                    type="text"
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    id="email"
                    label="Email"
                    inputVariant="outlined"
                    fullWidth
                    type="email"
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="phone"
                    id="phone"
                    label="Phone"
                    inputVariant="outlined"
                    fullWidth
                    type="phone"
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={DatePicker}
                    name="date"
                    id="date"
                    label="Date"
                    inputVariant="outlined"
                    fullWidth
                    format="dd.MM.yyyy"
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  close
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={values.files < 1}
                >
                  submit
                </Button>
              </DialogActions>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default New;
