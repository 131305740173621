import React, { useState, useEffect } from "react";
import Card from "../../Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NewContactPerson from "./NewContactPerson";
import DeleteContactPerson from "./DeleteContactPerson";
import EditContactPerson from "./EditContactPerson";
import styled from "styled-components";
import MuiLink from "@material-ui/core/Link";
import { Checkbox } from "@material-ui/core";
import { V3 } from "../../../lib/api";
const LinkWrapper = styled.strong`
  color: ${(props) => props.theme.palette.primary.main};
`;
export default function ContactPersons({ data, companyId }) {
  const [contactPersons, setContactPersons] = useState(data);
  const handleChange = (appointmentEmail, id) => {
    V3.put(`/crm/companies/update-contact/${companyId}/appointment`, {
      contactId: id,
      appointmentEmail: appointmentEmail,
    }).then((res) => {
      setContactPersons(res.data.data?.contactPersons);
    });
  };

  useEffect(() => {
    setContactPersons(data);
  }, [data]);

  return (
    <Card
      title="Contact persons"
      button={<NewContactPerson companyId={companyId} />}
    >
      {contactPersons && contactPersons.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Function</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Mobile</TableCell>
              <TableCell align="right">Second Mobile</TableCell>
              <TableCell align="right">SendEmail</TableCell>
              <TableCell align="right">Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactPersons.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.firstName + " " + row.lastName}
                </TableCell>
                <TableCell align="right">{row.role}</TableCell>
                <TableCell
                  align="right"
                  component={MuiLink}
                  href={`mailto:${row.email}`}
                  target="_blank"
                  underline="none"
                >
                  <LinkWrapper>{row.email}</LinkWrapper>
                </TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">{row.phoneSeconde || "-"}</TableCell>
                <TableCell align="right">
                  <Checkbox
                    color="primary"
                    checked={row.appointmentEmail}
                    onChange={(e) =>
                      handleChange(!row.appointmentEmail, row._id)
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <EditContactPerson
                    companyId={companyId}
                    contactPerson={row}
                  />
                  <DeleteContactPerson
                    companyId={companyId}
                    contactPerson={row._id}
                    oldContact={row.firstName + " " + row.lastName}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p style={{ paddingLeft: 15 }}>No contact persons</p>
      )}
    </Card>
  );
}
