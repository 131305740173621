import React, { useEffect, useState } from "react";
import { V3 } from "../../lib/api";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import MUITextField from "@material-ui/core/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../components/GlobalLoader";
import { Alert } from "@material-ui/lab";

function HashtagSelector({ onChange, value }) {
  const [hashtags, setHashtags] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    V3.get("hashtags").then((res) => {
      setHashtags(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {" "}
      <MUIAutocomplete
        multiple
        options={hashtags}
        getOptionLabel={(option) => option.title}
        onChange={onChange}
        value={value}
        getOptionSelected={(option, value) => {
          return option._id === value._id;
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {" "}
                  <InputAdornment position="start">
                    <Tooltip title="Add hashtag">
                      <AddCircleIcon
                        style={{ color: "#e0e0e0", cursor: "pointer" }}
                        onClick={() => setOpen(true)}
                      />
                    </Tooltip>
                  </InputAdornment>
                  {params?.InputProps?.startAdornment}
                </>
              ),
            }}
            placeholder="Select"
            variant="outlined"
            label="Hashtags"
          />
        )}
      />
      <Dialog open={open} onClose={() => setOpen()} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogTitle>Add Category</DialogTitle>
          <Formik
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Required"),
            })}
            onSubmit={(values, formApi) => {
              setMessage(null);
              V3.post("hashtags", values)
                .then((res) => {
                  setHashtags([...hashtags, res.data.data]);
                  onChange(null, [...value, res.data.data]);
                  setOpen(false);
                  formApi.setSubmitting(false);
                })
                .catch((error) => {
                  formApi.setSubmitting(false);
                  setMessage(error.response.data.message);
                });
            }}
            initialValues={{
              title: "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Hashtag"
                  name="title"
                  style={{ marginTop: 0 }}
                />

                <DialogActions>
                  <Button type="submit" variant="contained" color="primary">
                    Create Hashtag
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default HashtagSelector;
