import React, { useState } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WorkIcon from "@material-ui/icons/Work";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { NavLink } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import TimelineIcon from "@material-ui/icons/Timeline";
import { makeStyles, fade } from "@material-ui/core/styles";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import RateReviewIcon from "@material-ui/icons/RateReview";
import NotifyRecruiters from "../../Notify/Recruiters";
import PageviewIcon from "@material-ui/icons/Pageview";
import BookIcon from "@material-ui/icons/Book";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  navIcon: {
    paddingLeft: 8,
  },
  item: {
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
}));

const includes = (path, userPaths) => {
  if (userPaths.find((el) => el === path)) {
    return true;
  } else {
    return false;
  }
};
const includesOne = (paths, userPaths) => {
  const checkedPaths = userPaths.filter((path) => {
    if (paths.find((el) => el === path)) {
      return true;
    } else {
      return false;
    }
  });

  if (checkedPaths?.length) {
    return true;
  } else {
    return false;
  }
};

export default function Links({ setOpen, matches }) {
  const classes = useStyles();

  const [openCrm, setOpenCrm] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [openRecruiter, setOpenRecruiter] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleClick = () => {
    setOpenCrm(!openCrm);
  };
  const handleClickTwo = () => {
    setOpenTwo(!openTwo);
  };

  const handleAnalytics = () => {
    setOpenAnalytics(!openAnalytics);
  };
  const handleSettings = () => {
    setOpenSettings(!openSettings);
  };
  const handleCategories = () => {
    setOpenCategories(!openCategories);
  };

  return (
    <List className={classes.list}>
      <ListItem
        onClick={() => setOpen(matches ? true : false)}
        className={classes.item}
        button
        component={NavLink}
        exact={true}
        to="/"
      >
        <ListItemIcon className={classes.navIcon}>
          <DashboardIcon />
        </ListItemIcon>

        <ListItemText primary="Dashboard" />
      </ListItem>
      {includes("/pipeline", user.routes) ? (
        <ListItem
          className={classes.item}
          button
          component={NavLink}
          exact={true}
          to="/pipeline"
        >
          <ListItemIcon className={classes.navIcon}>
            <PageviewIcon />
          </ListItemIcon>
          <ListItemText primary="Pipeline" />
        </ListItem>
      ) : null}
      {includes("/media", user.routes) ? (
        <ListItem button component={NavLink} exact={true} to="/media">
          <ListItemIcon className={classes.navIcon}>
            <PermMediaIcon />
          </ListItemIcon>
          <ListItemText primary="Media" />
        </ListItem>
      ) : null}

      {includesOne(
        [
          "/analytics",
          "/my-analytics",
          "/invoices",
          "/companies/analytics",
          "/job-candidates",
          "/contacting",
          "/statistic/pipeline",
          "/recruiters/analytics",
          "/recruiters/analytics/shared-links",
          "/recruiters/analytics/shared-jobs",
          "/recruiters/analytics/inactive",
        ],
        user.routes
      ) ? (
        <>
          {" "}
          <ListItem
            button
            onClick={handleAnalytics}
            style={{
              backgroundColor: openAnalytics ? "#195079" : "transparent",
            }}
          >
            <ListItemIcon className={classes.navIcon}>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary="Statistiken" />
            {openAnalytics ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAnalytics} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={() => setOpen(matches ? true : false)}
            >
              {includes("/analytics", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/analytics"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Statistiken" />
                </ListItem>
              ) : null}
              {includes("/my-analytics", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to={`/my-analytics/${user && user._id}`}
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Meine Statistiken" />
                </ListItem>
              ) : null}

              {includesOne(
                [
                  "/recruiters/analytics/shared-links",
                  "/recruiters/analytics/shared-jobs",
                  "/recruiters/analytics/inactive",
                ],
                user.routes
              ) ? (
                <>
                  <ListItem
                    button
                    onClick={() => setOpenRecruiter(!openRecruiter)}
                    style={{
                      backgroundColor: openRecruiter
                        ? "#195079"
                        : "transparent",
                    }}
                  >
                    <ListItemIcon className={classes.navIcon}></ListItemIcon>
                    <ListItemText primary="Recruiters" />
                    {openRecruiter ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openRecruiter} timeout="auto" unmountOnExit>
                    {includes("/recruiters/analytics", user.routes) ? (
                      <ListItem
                        className={classes.item}
                        button
                        component={NavLink}
                        exact={true}
                        to="/recruiters/analytics"
                      >
                        <ListItemIcon
                          className={classes.navIcon}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Analytics"
                          style={{ marginLeft: 15 }}
                        />
                      </ListItem>
                    ) : null}
                    {includes(
                      "/recruiters/analytics/shared-links",
                      user.routes
                    ) ? (
                      <ListItem
                        className={classes.item}
                        button
                        component={NavLink}
                        exact={true}
                        to="/recruiters/analytics/shared-links"
                      >
                        <ListItemIcon
                          className={classes.navIcon}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Shared"
                          style={{ marginLeft: 15 }}
                        />
                      </ListItem>
                    ) : null}
                    {includes(
                      "/recruiters/analytics/shared-jobs",
                      user.routes
                    ) ? (
                      <ListItem
                        className={classes.item}
                        button
                        component={NavLink}
                        exact={true}
                        to="/recruiters/analytics/shared-jobs"
                      >
                        <ListItemIcon
                          className={classes.navIcon}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Shared jobs"
                          style={{ marginLeft: 15 }}
                        />
                      </ListItem>
                    ) : null}
                    {includes("/recruiters/analytics/inactive", user.routes) ? (
                      <ListItem
                        className={classes.item}
                        button
                        component={NavLink}
                        exact={true}
                        to="/recruiters/analytics/inactive"
                      >
                        <ListItemIcon
                          className={classes.navIcon}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Inactive"
                          style={{ marginLeft: 15 }}
                        />
                      </ListItem>
                    ) : null}

                    <Divider />
                  </Collapse>
                </>
              ) : null}

              {includes("/invoices", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/invoices"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Invoices" />
                </ListItem>
              ) : null}

              {includes("/companies/analytics", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/companies/analytics"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Unternehmen" />
                </ListItem>
              ) : null}
              {includes("/job-candidates", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/job-candidates"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Kandidaten" />
                </ListItem>
              ) : null}
              {includes("/contacting", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/contacting"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Wochenplanung" />
                </ListItem>
              ) : null}
              {includes("/statistic/pipeline", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/statistic/pipeline"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Pipeline" />
                </ListItem>
              ) : null}
            </List>
            <Divider />
          </Collapse>
        </>
      ) : null}

      {includesOne(
        [
          "/products",
          "/orders",
          "/jobs",
          "/crawled/jobs",
          "/applications",
          "/companies",
          "/alerts",
          "/favorites",
          "/users",
          "/recruiters",
          "/blog",
        ],
        user.routes
      ) ? (
        <>
          <ListItem
            button
            onClick={handleClickTwo}
            style={{ backgroundColor: openTwo ? "#195079" : "transparent" }}
          >
            <ListItemIcon className={classes.navIcon}>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="TwoJobs" />
            {openTwo ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openTwo} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={() => setOpen(matches ? true : false)}
            >
              {includes("/products", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/products"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <StorefrontIcon />
                  </ListItemIcon>

                  <ListItemText primary="Products" />
                </ListItem>
              ) : null}
              {includes("/orders", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/orders"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <ReceiptIcon />
                  </ListItemIcon>

                  <ListItemText primary="Bestellungen" />
                </ListItem>
              ) : null}
              {includes("/jobs", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/jobs"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <WorkIcon />
                  </ListItemIcon>

                  <ListItemText primary="Jobs" />
                </ListItem>
              ) : null}
              {includes("/crawled/jobs", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/crawled/jobs"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <RateReviewIcon />
                  </ListItemIcon>

                  <ListItemText primary="Zu Publizieren" />
                </ListItem>
              ) : null}
              {includes("/applications", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/applications"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <PictureAsPdfIcon />
                  </ListItemIcon>

                  <ListItemText primary="Bewerbungen" />
                </ListItem>
              ) : null}
              {includes("/companies", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/companies"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Unternehmen" />
                </ListItem>
              ) : null}
              {includes("/alerts", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/alerts"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <GroupIcon />
                  </ListItemIcon>

                  <ListItemText primary="Alerts" />
                </ListItem>
              ) : null}
              {includes("/favorites", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/favorites"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <GroupIcon />
                  </ListItemIcon>

                  <ListItemText primary="Favorites" />
                </ListItem>
              ) : null}
              {includes("/users", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/users"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <GroupIcon />
                  </ListItemIcon>

                  <ListItemText primary="Users" />
                </ListItem>
              ) : null}
              {includes("/recruiters", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/recruiters"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <PersonIcon />
                  </ListItemIcon>

                  <ListItemText primary="Recruiters" />
                </ListItem>
              ) : null}
              {includes("/blog", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/blog"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <BookIcon />
                  </ListItemIcon>

                  <ListItemText primary="Blog" />
                </ListItem>
              ) : null}
            </List>
            <Divider />
          </Collapse>
        </>
      ) : null}

      {includesOne(
        ["/team", "/crm/companies", "/crm/candidates", "/interested"],
        user.routes
      ) ? (
        <>
          {" "}
          <ListItem
            button
            onClick={handleClick}
            style={{ backgroundColor: openCrm ? "#195079" : "transparent" }}
          >
            <ListItemIcon className={classes.navIcon}>
              <DataUsageIcon />
            </ListItemIcon>
            <ListItemText primary="CRM" />
            {openCrm ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCrm} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={() => setOpen(matches ? true : false)}
            >
              {includes("/team", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/team"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <GroupIcon />
                  </ListItemIcon>

                  <ListItemText primary="Team" />
                </ListItem>
              ) : null}
              {includes("/crm/companies", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/crm/companies"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Unternehmen" />
                </ListItem>
              ) : null}
              {includes("/crm/candidates", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/crm/candidates"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <PersonIcon />
                  </ListItemIcon>

                  <ListItemText primary="Kandidaten" />
                </ListItem>
              ) : null}
              {includes("/interested", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/interested"
                >
                  <ListItemIcon className={classes.navIcon}>
                    <PersonIcon />
                  </ListItemIcon>

                  <ListItemText primary="Interested" />
                </ListItem>
              ) : null}
            </List>
            <Divider />
          </Collapse>
        </>
      ) : null}
      {includes("/calendar", user.routes) ? (
        <ListItem
          onClick={() => setOpen(matches ? true : false)}
          className={classes.item}
          button
          component={NavLink}
          exact={true}
          to="/calendar"
        >
          <ListItemIcon className={classes.navIcon}>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Kalender" />
        </ListItem>
      ) : null}

      {includesOne(
        [
          "/settings/team",
          "settings/testimonials",
          "/settings/platform",
          "/settings/partners",
          "/bonuses",
          "/reasons",
          "/linkedin",
          "/emails",
          "/quiz",
          "/update-call-template",
          "/notes-template",
          "/routes-template",
          "/categories/jobs",
          "/categories/companies",
          "/categories/candidates",
          "/categories/products",
          "/categories/hashtags",
          "/categories/rejects",
          "/categories/regions",
        ],
        user.routes
      ) ? (
        <>
          {" "}
          <ListItem
            button
            onClick={handleSettings}
            style={{
              backgroundColor: openSettings ? "#195079" : "transparent",
            }}
          >
            <ListItemIcon className={classes.navIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Einstellungen" />
            {openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={() => setOpen(matches ? true : false)}
            >
              {includes("/settings/team", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/settings/team"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Team" />
                </ListItem>
              ) : null}
              {includes("/settings/testimonials", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/settings/testimonials"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Testimonials" />
                </ListItem>
              ) : null}
              {includes("/settings/platform", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/settings/platform"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Platform" />
                </ListItem>
              ) : null}
              {includes("/settings/partners", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/settings/partners"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Partners" />
                </ListItem>
              ) : null}
              {includes("/bonuses", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/bonuses"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Bonuses" />
                </ListItem>
              ) : null}
              {includes("/reasons", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/reasons"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Reasons" />
                </ListItem>
              ) : null}
              {includes("/linkedin", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/linkedin"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="LinkedIn" />
                </ListItem>
              ) : null}

              <NotifyRecruiters />
              {includes("/emails", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/emails"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Emails" />
                </ListItem>
              ) : null}
              {includes("/quiz", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/quiz"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Quiz Templates" />
                </ListItem>
              ) : null}
              {includes("/update-call-template", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/update-call-template"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Update Call Template" />
                </ListItem>
              ) : null}
              {includes("/notes-template", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/notes-template"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Notes Template" />
                </ListItem>
              ) : null}
              {includes("/routes-template", user.routes) ? (
                <ListItem
                  className={classes.item}
                  button
                  component={NavLink}
                  exact={true}
                  to="/routes-template"
                >
                  <ListItemIcon className={classes.navIcon}></ListItemIcon>
                  <ListItemText primary="Roles Template" />
                </ListItem>
              ) : null}

              {includesOne(
                [
                  "/categories/jobs",
                  "/categories/companies",
                  "/categories/candidates",
                  "/categories/products",
                  "/categories/hashtags",
                  "/categories/rejects",
                  "/categories/regions",
                ],
                user.routes
              ) ? (
                <>
                  <ListItem
                    button
                    onClick={handleCategories}
                    style={{
                      backgroundColor: openCategories
                        ? "#195079"
                        : "transparent",
                    }}
                  >
                    <ListItemIcon className={classes.navIcon} />
                    <ListItemText primary="Categories" />
                    {openCategories ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openCategories} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      onClick={() => setOpen(matches ? true : false)}
                    >
                      {includes("/categories/jobs", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/jobs"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Jobs" />
                        </ListItem>
                      ) : null}
                      {includes("/categories/companies", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/companies"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Companies" />
                        </ListItem>
                      ) : null}

                      {includes("/categories/candidates", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/candidates"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Candidates" />
                        </ListItem>
                      ) : null}

                      {includes("/categories/products", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/products"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Products" />
                        </ListItem>
                      ) : null}

                      {includes("/categories/hashtags", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/hashtags"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Hashtags" />
                        </ListItem>
                      ) : null}

                      {includes("/categories/rejects", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/rejects"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Rejects" />
                        </ListItem>
                      ) : null}

                      {includes("/categories/regions", user.routes) ? (
                        <ListItem
                          className={classes.item}
                          button
                          component={NavLink}
                          exact={true}
                          to="/categories/regions"
                        >
                          <ListItemIcon className={classes.navIcon} />
                          <ListItemText primary="Regions" />
                        </ListItem>
                      ) : null}
                    </List>
                  </Collapse>
                </>
              ) : null}
            </List>
          </Collapse>
        </>
      ) : null}
    </List>
  );
}
