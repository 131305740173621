import { V3 } from "../../../lib/api";
import React, { useEffect, useState } from "react";
import HistoryIcon from "@material-ui/icons/History";

import InfoIcon from "@material-ui/icons/Info";

import Moment from "react-moment";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  darken,
  lighten,
} from "@material-ui/core";
import Loader from "../../Loader";
import { FeedBackWrapper, newCandidateFeedbacks } from "./FeedBack";

const CandidateFeedBackHistory = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      V3.get(`new-job-candidates/status`, {
        params: { limit: 999, candidate: candidate._id },
      }).then((res) => {
        setData(res.data.data);
        setLoading(false);
      });
    }
  }, [open, candidate]);

  const newFeedBacks = [
    ...newCandidateFeedbacks,
    { id: 4, value: 4, label: "Weitergeleitet", color: "#98A2B3" },
  ];

  const empty = [];
  if (data.length < 5) {
    for (let index = 0; index < 5 - data?.length; index++) {
      empty.push(
        <TableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
          }}
        >
          <TableCell component="th" scope="row">
            -
          </TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: "-",
                  }}
                ></div>
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>-</TableCell>
          <TableCell align="right">-</TableCell>
        </TableRow>
      );
    }
  }

  return (
    <>
      <IconButton
        aria-label="expand row"
        size="small"
        className="icon-button"
        onClick={() => setOpen(true)}
      >
        <HistoryIcon />
      </IconButton>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Verlauf</DialogTitle>
        <DialogContent>
          {loading && <Loader loading={loading} style={{ zIndex: 9 }} />}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Subjekt</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Erstellt am</TableCell>
                  <TableCell align="right">Weitergeleitet an</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const defaultStatus = newFeedBacks[1];
                  const status = newFeedBacks.find(
                    (s) => s.value === row?.type
                  );
                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {!status ? (
                          <FeedBackWrapper
                            style={{
                              color: darken(defaultStatus?.color, 1),
                              backgroundColor: lighten(
                                defaultStatus?.color,
                                0.3
                              ),
                              cursor: "pointer",
                            }}
                          >
                            {defaultStatus?.label}
                          </FeedBackWrapper>
                        ) : (
                          <FeedBackWrapper
                            style={{
                              color: darken(status?.color, 1),
                              backgroundColor: lighten(status?.color, 0.3),
                              cursor: "pointer",
                            }}
                          >
                            {status?.label}
                          </FeedBackWrapper>
                        )}
                      </TableCell>
                      <TableCell>{row.subject}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: row.description,
                              }}
                            ></div>
                          }
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Moment>{row.createdAt}</Moment>
                      </TableCell>
                      <TableCell align="right">
                        <>{row.sendEmailTo}</>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {empty}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions style={{ padding: 0, marginTop: 15 }}>
            <Button
              style={{ width: "50%" }}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setOpen(false)}
            >
              Schließen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CandidateFeedBackHistory;
