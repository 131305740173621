import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { DatePicker } from "material-ui-formik-components/DatePicker";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../../../lib/api";
import Loader from "../../../GlobalLoader";
import { useDispatch } from "react-redux";
import { UPDATE_STATUS } from "../../../../redux/types";
export default function AlertDialog({ data }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (values, formikApi) => {
    const statusData = {};
    if (data?.type === "6") {
      statusData.startDate = values.startDate;
      statusData.endDate = values.endDate;
    }
    if (data?.type === "4") {
      statusData.date = values.date;
    }
    V3.put(`crm/statuses/${data._id}/date`, values).then((res) => {
      formikApi.setSubmitting(false);
      setOpen(false);
      dispatch({ type: UPDATE_STATUS, payload: res.data.data });
    });
  };
  return (
    <React.Fragment>
      <Tooltip title="Edit Date">
        <IconButton onClick={() => setOpen(true)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Formik
        validationSchema={Yup.object().shape(
          data?.type === "6"
            ? {
                startDate: Yup.date().required("Required"),
                endDate: Yup.date().required("Required"),
              }
            : data.type === "4"
            ? {
                date: Yup.date().required("Required"),
                description: Yup.string().nullable(),
              }
            : {}
        )}
        onSubmit={onSubmit}
        initialValues={{
          startDate: data?.invoiceId?.startDate,
          endDate: data?.invoiceId?.endDate,
          date: data?.date,
          description: data?.description,
        }}
      >
        {({ submitForm, isSubmitting, dirty, values, errors }) => (
          <Form>
            {isSubmitting && <Loader />}
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Change Date</DialogTitle>
              <DialogContent>
                {data?.type === "6" && (
                  <>
                    <Field
                      component={DatePicker}
                      name="startDate"
                      id="startDate"
                      label="Start Date"
                      inputVariant="outlined"
                      fullWidth
                      format="dd.MM.yyyy"
                      autoOk
                      disableToolbar
                      style={{ margin: 0 }}
                    />
                    <Field
                      component={DatePicker}
                      name="endDate"
                      id="endDate"
                      label="End Date"
                      inputVariant="outlined"
                      fullWidth
                      format="dd.MM.yyyy"
                      autoOk
                      disableToolbar
                      style={{ margin: 0, marginTop: 15 }}
                    />
                  </>
                )}
                {data?.type === "4" && (
                  <>
                    <Field
                      component={DatePicker}
                      name="date"
                      id="date"
                      label="Date"
                      inputVariant="outlined"
                      fullWidth
                      format="dd.MM.yyyy"
                      autoOk
                      disableToolbar
                      style={{ margin: 0 }}
                    />
                    <Field
                      component={TextField}
                      name="description"
                      id="description"
                      label="Beschreibung"
                      inputVariant="outlined"
                      fullWidth
                      multiline
                      rows={6}
                      style={{ margin: 0, marginTop: 15 }}
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
                <Button variant="outlined" color="primary" onClick={submitForm}>
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
