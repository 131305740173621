import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "./Notification";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

import List from "@material-ui/core/List";
import { V3 } from "../../lib/api";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  drop: {
    position: "absolute",
    right: 0,
    top: 70,
    minWidth: 350,
    minHeight: 200,
  },
  avatar: {
    padding: 5,
    marginRight: 10,
    color: theme.palette.text.primary,
  },
  user: {
    padding: 15,
  },
  userName: {
    display: "block",
    fontWeight: 600,
  },

  backdrop: {
    zIndex: 1300,
  },
}));

const CardContent = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open === true ? "0px" : "-100%")};
  height: 100vh;
  width: 450px;
  transition: 0.3s ease-in-out;
  background: ${(props) => props.theme.palette.background.paper};
  z-index: 1400;
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 960px) {
    width: 100vw;
  }
`;

const NoNotifications = styled.div`
  padding: 30px;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NotificationsWrapper = styled.div`
  padding: 0px;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  position: relative;
`;
function Notifications() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleCard = () => {
    setOpen(!open);
  };

  useEffect(() => {
    V3.get(`/notifications/my`).then((res) => {
      setNotifications(res.data.data);
    });
  }, []);
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <IconButton
          color="inherit"
          component="span"
          className={classes.avatar}
          onClick={handleCard}
          style={{ padding: 12, marginRight: 20 }}
        >
          <NotificationsNoneIcon style={{ width: 30, height: 30 }} />
        </IconButton>
      </div>

      <Backdrop className={classes.backdrop} open={open} onClick={handleCard} />

      <CardContent open={open}>
        <Grid container style={{ height: 64 }}>
          <Grid
            item
            xs={10}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              <h1
                style={{
                  margin: 0,
                  fontSize: 18,
                  marginLeft: 15,
                }}
              >
                Notifications
              </h1>
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <IconButton
                style={{ marginRight: 15 }}
                color="inherit"
                component="span"
                onClick={handleCard}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider />

        {notifications.length > 0 ? (
          <NotificationsWrapper onClick={handleCard}>
            <List>
              {notifications.map((notification) => (
                <Notification data={notification} />
              ))}
            </List>
          </NotificationsWrapper>
        ) : (
          <NoNotifications>
            <h2>Your notifications live here</h2>
            <p>You don't have any notifications!</p>
          </NoNotifications>
        )}
      </CardContent>
    </React.Fragment>
  );
}
export default Notifications;
