import React, { useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Header from "../../components/Page/Header";
import { useHistory } from "react-router-dom";
import Loader from "../../components/GlobalLoader";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

function New() {
  const [message, setMessage] = useState(null);

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, formApi) => {
    V3.post(`/linkedin-accounts`, values)
      .then((res) => {
        enqueueSnackbar("Account created successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);
        push("/linkedin");
      })
      .catch((err) => {
        formApi.setSubmitting(false);
        enqueueSnackbar("Could not created Account", {
          variant: "error",
        });
        setMessage(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Header title="Add New LinkedIn Accounts"></Header>
      <Formik
        validationSchema={Yup.object().shape({
          accountName: Yup.string().required("Required"),
          vpn: Yup.string().required("Required"),
          password: Yup.string().required("Required"),
          notes: Yup.string().required("Required"),
          email: Yup.string()
            .email("Must be a valid Email")
            .required("Required"),
          accountNumber: Yup.number().required("Required"),
        })}
        onSubmit={handleSubmit}
        initialValues={{}}
      >
        {({ isSubmitting }) => (
          <Form>
            {isSubmitting && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <Paper style={{ padding: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="accountNumber"
                        label="Account Number"
                        name="accountNumber"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="accountName"
                        label="Account Name"
                        name="accountName"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="vpn"
                        label="VPN"
                        name="vpn"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="notes"
                        label="Notes"
                        name="notes"
                        multiline
                        rows={6}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    Add
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default New;
