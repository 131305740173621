import {
  GET_RECRUITERS,
  GET_RECRUITER,
  RECRUITERS_LOADING,
  GET_FREE_CLICKS,
  GIVE_FREE_CLICKS,
  SET_USER_TO_RECRUITER,
  SET_RECRUITER_LOADING,
} from "../types";

const initialState = {
  recruiters: [],
  data: [],
  sharedLinksData: [],
  freeClicks: [],
  freeClicksData: [],
  freeClicksLoading: true,
  recruiter: null,
  loading: true,
  links: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECRUITERS:
      return {
        ...state,
        recruiters: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_RECRUITER:
      return {
        ...state,
        recruiter: action.payload.data,
        loading: false,
      };
    case GIVE_FREE_CLICKS:
      return {
        ...state,
        freeClicks: [action.payload, ...state.freeClicks],
      };
    case GET_FREE_CLICKS:
      return {
        ...state,
        freeClicks: action.payload.data,
        freeClicksData: action.payload,
        freeClicksLoading: false,
      };
    case RECRUITERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_TO_RECRUITER:
      return {
        ...state,
        recruiter: { ...state.recruiter, user: action.payload },
      };
    case SET_RECRUITER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
