import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card";

import Verify from "../Recruiter/Verify";
import { useConfirm } from "material-ui-confirm";
import { V3 } from "../../lib/api";
import { SET_USER_TO_RECRUITER } from "../../redux/types";
import RecruiterNotes from "../Recruiter/RecruiterNotes";
import GlobalLoader from "../GlobalLoader";

function RecruiterBasicInfo() {
  const { recruiter } = useSelector((state) => state.recruiter);
  const [loading, setLoading] = useState(false);

  const confirm = useConfirm();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    confirm({
      title: recruiter?.user?.blocked ? "Un Block User" : "Block User",
      description: recruiter?.user?.blocked
        ? "Are you sure you want to unblock this user"
        : "Are you sure you want to block this user",
    }).then(() => {
      setLoading(true);
      V3.put(`users/${recruiter?.user?._id}/block`, {
        blocked: !recruiter?.user?.blocked,
      }).then((res) => {
        dispatch({ type: SET_USER_TO_RECRUITER, payload: res.data.data });
        setLoading(false);
      });
    });
  };

  const handleClearRecruiterData = () => {
    setLoading(true);
    V3.delete(`recruiters/${recruiter._id}/data`)
      .then((result) => {
        window.location.reload(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card
          title="User info"
          button={
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleConfirm()}
            >
              {recruiter?.user?.blocked ? "Un Block User" : "Block User"}
            </Button>
          }
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">Name</TableCell>
                <TableCell align="right">
                  {recruiter.user.profile.name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">Tel.</TableCell>
                <TableCell align="right">
                  {recruiter.user.profile.phone}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">Email</TableCell>
                <TableCell align="right">{recruiter.user.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card title="Location">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">Stadt</TableCell>
                <TableCell align="right">{recruiter.canton}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Street</TableCell>
                <TableCell align="right">{recruiter.street}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">ZIP</TableCell>
                <TableCell align="right">{recruiter.zip}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card
          title="Recruitment info"
          style={{ marginTop: 15 }}
          button={
            <React.Fragment>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 15 }}
                onClick={() => handleClearRecruiterData()}
              >
                Clear Data
              </Button>
              {recruiter.verified === false && (
                <Verify recruiterId={recruiter._id} />
              )}
            </React.Fragment>
          }
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">Referer Id</TableCell>
                <TableCell align="right">{recruiter.refId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">TWO$</TableCell>
                <TableCell align="right">{recruiter.points}</TableCell>
              </TableRow>
              {recruiter?.user?.registeredForCompany ? (
                <TableRow>
                  <TableCell component="th">Company TWO$</TableCell>
                  <TableCell align="right">{recruiter.companyPoints}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <RecruiterNotes />
      </Grid>
    </Grid>
  );
}

export default RecruiterBasicInfo;
