import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

export default function LinkedInLinks({ data }) {
  const [open, setOpen] = useState(false);
  let customCandidates = "";

  if (data && data.customCandidatesV2) {
    // eslint-disable-next-line array-callback-return
    data.customCandidatesV2.map((el) => {
      customCandidates += `${el.name} ( ${moment(el.date).format(
        "DD/MM/YYYY"
      )} ), `;
    });
  }
  let cvCandidates = "";
  // deleted from Job
  if (data && data.cvCandidate) {
    if (Array.isArray(data.cvCandidate)) {
      // eslint-disable-next-line array-callback-return
      data.cvCandidate.map((el) => {
        cvCandidates += el?.name ? `${el?.name || ""}, ` : "";
      });
    } else {
      cvCandidates = data.cvCandidate;
    }
  }
  if (!customCandidates?.length && !cvCandidates?.length) {
    return "-";
  }
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
      >
        Info
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Kandidaten</TableCell>
                <TableCell>{cvCandidates}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Custom Kandidat</TableCell>
                <TableCell>{customCandidates}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
