import React from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Delete from "../../components/Bonuses/Delete";
import Moment from "react-moment";
import Add from "../../components/Bonuses/Create";
import Edit from "../../components/Bonuses/Edit";
import { useDispatch, useSelector } from "react-redux";
import { V3 } from "../../lib/api";
import { BONUSES_LOADING, GET_BONUSES } from "../../redux/types";

function Bonuses(props) {
  const { bonuses, data, loading } = useSelector((state) => state.bonus);

  const dispatch = useDispatch();
  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link to={`/bonuses/${props.original._id}`}>
          <strong>{props.original.title}</strong>
        </Link>
      ),
    },

    {
      id: "createdAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      filterable: false,
      width: 200,
      Cell: (props) => (
        <div>
          <Moment>{props.original.createdAt}</Moment>
        </div>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Edit data={props.original} />
          <Tooltip title="Delete this job">
            <Delete jobId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Bonuses" size={data.total}>
        <Add />
      </Header>

      <Table
        data={bonuses}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          dispatch({
            type: BONUSES_LOADING,
          });
          V3.get(`/bonuses${query}`).then((res) => {
            dispatch({
              type: GET_BONUSES,
              payload: res.data,
            });
          });
        }}
        noDataText="No Bonus"
        filterable={false}
      />
    </React.Fragment>
  );
}

export default Bonuses;
