import { V3 } from "../../../lib/api";

import { colors } from "../../../utils/data";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Chart } from "react-charts";
import Empty from "../Empty";

import Card from "../../Card";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const LoadingClicksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 300px;
  h3 {
    font-weight: 500;
    font-size: 20px;
  }

  img {
    max-width: 70px;
  }
`;
const ChartWrapper = styled.div`
  position: relative;
  height: 300px;
`;

const generateEmptyData = (start, end) => {
  const emptyData = [];

  const startDate = moment(start, "DD/MM/YYYY");
  const endDate = moment(end, "DD/MM/YYYY");

  if (startDate > endDate) {
    return [];
  }

  while (startDate < endDate) {
    emptyData.push({
      amount: 0,
      createdAt: startDate.format(),
      clicks: 0,
    });
    startDate.add(1, "month");
  }

  return emptyData;
};

const Wrapper = styled.div`
  padding: 30px;

  @media (max-width: 550px) {
    padding: 15px;
  }
`;

function JobClicksChart({ id, startDate, endDate, published = true }) {
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [distinct, setDistinct] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    V3.get(`/companies/jobs-clicks/${id}`, {
      params: {
        published: published,
        startDate: moment(startDate).format("DD/MM/YYYY"),
        endDate: moment(endDate).format("DD/MM/YYYY"),
      },
    }).then((res) => {
      setData(res.data.data);
      setDistinct(res.data.distinct);
      setJobs(res.data.jobs);
      setDataLoading(false);
    });
  }, [id, published, startDate, endDate]);

  const finalDataNew = distinct.map((job, index) => {
    const jobObject = jobs.find((jobitem) => jobitem._id === job);
    const filteredLinks = data.filter((el) => el?.job === job);
    let myCLicks = [
      ...[
        ...generateEmptyData(
          moment(startDate).format("DD/MM/YYYY"),
          moment(endDate).format("DD/MM/YYYY")
        ),
        ...filteredLinks,
      ]
        .reduce(
          (m, o) =>
            m.set(
              moment(o?.createdAt).format("MM/YYYY"),
              Object.assign(
                {
                  ...m.get(moment(o?.createdAt).format("MM/YYYY")),
                  clicks: m.get(moment(o?.createdAt).format("MM/YYYY"))?.clicks
                    ? m.get(moment(o?.createdAt).format("MM/YYYY")).clicks +
                      o.amount
                    : o.amount,
                } || {},
                { ...o }
              )
            ),
          new Map()
        )
        .values(),
    ];
    myCLicks = _(myCLicks).orderBy("createdAt", "asc").value();

    return {
      label: jobObject ? jobObject.title : "No job title",
      color: colors[index],
      data: myCLicks,
    };
  });

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => {
        return moment(datum?.createdAt).format("MM/YYYY");
      },
      elementType: "area",
      stacked: false,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum?.clicks,
        stacked: false,
        elementType: "area",
      },
    ],
    []
  );

  if (finalDataNew?.length === 0) {
    finalDataNew.push({
      label: "No job title",
      color: colors[0],
      data: generateEmptyData(
        moment(startDate).format("DD/MM/YYYY"),
        moment(endDate).format("DD/MM/YYYY")
      ),
    });
  }

  return (
    <Card title="Klicks auf Ihren Stelleninseraten" closable={false}>
      {!dataLoading && finalDataNew?.length > 0 && (
        <Wrapper>
          <ChartWrapper>
            <Chart
              datatype="ordinal"
              options={{
                defaultColors: colors,
                data: finalDataNew,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </ChartWrapper>
        </Wrapper>
      )}
      {dataLoading && (
        <LoadingClicksWrapper>
          <CircularProgress size={30} />
        </LoadingClicksWrapper>
      )}
      {!dataLoading && finalDataNew?.length === 0 && (
        <>
          <Empty title="Kein Klicks auf Ihren Stelleninseraten" />
        </>
      )}
    </Card>
  );
}

export default JobClicksChart;
