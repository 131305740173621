import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch, FormControlLabel } from "@material-ui/core";
import Loader from "../../../components/GlobalLoader";
import styled from "styled-components";
import Editor from "../../../components/Editor";
import { V3 } from "../../../lib/api";
import FileUpload from "../../../components/fileUpload";
import { uploadFile } from "../../../utils/AWS";
import moment from "moment";

const Wrapper = styled.div`
  .MuiTypography-body1 {
    padding-left: 15px;
  }
`;

function Delete(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = async (values, formApi) => {
    setLoading(true);
    const data = {
      profile: values.profile,
      phone: values.phone,
      name: values.name,
      email: values.email,
      sendEmail: values.sendEmail,
      description: values.description,
      createdAt: new Date(),
    };

    const files = [];

    if (values?.files.length) {
      for (let index = 0; index < values?.files.length; index++) {
        const file = values?.files[index];
        const url = await uploadFile(
          `prospect-profiles/${moment().format("YYYY-MM")}`,
          file
        );
        files.push(url);
      }
    }

    data.files = files;

    V3.put(`/jobs/${props.id}/prospect-profiles`, data).then((res) => {
      setOpen(false);
      setLoading(false);
      props.handleNew(res.data.data, res.data.interestedHasBeenDeleted);
    });
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
      {loading && <Loader />}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Fügen Sie neue eingereichte Interessentenprofile hinzu
        </DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              profile: Yup.string().required("Required"),
              name: Yup.string().required("Required"),
              phone: Yup.string(),
              email: Yup.string().email().required("Required"),
              sendEmail: Yup.boolean().required("Required"),
              description: Yup.string(),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              sendEmail: true,
              files: [],
            }}
          >
            {({
              isSubmitting,
              setFieldValue,

              values,
            }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="name"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Tel."
                  name="phone"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Profile"
                  name="profile"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Field
                  component={TextField}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  name="email"
                  style={{ margin: 0, marginTop: 15 }}
                />
                <Wrapper>
                  <FormControlLabel
                    style={{ paddingLeft: 15, marginTop: 15, marginBottom: 15 }}
                    control={
                      <Switch
                        checked={values.sendEmail}
                        onChange={(e) =>
                          setFieldValue("sendEmail", e.target.checked)
                        }
                        color="secondary"
                        style={{ marginRight: 15 }}
                      />
                    }
                    label="Send Email"
                  />
                </Wrapper>
                <div style={{ marginBottom: 15 }}>
                  <Editor
                    value={values.description}
                    onChange={(val) => setFieldValue("description", val)}
                  />
                </div>

                <FileUpload
                  handleChange={(files) => setFieldValue("files", files)}
                />

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
