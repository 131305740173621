import React, { useEffect, useState } from "react";
import Card from "../Card";
import moment from "moment";
import { Grid } from "@material-ui/core";
import Loader from "../GlobalLoader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { V3 } from "../../lib/api";
export default function CompareMonths() {
  const [startDate, setStartDate] = React.useState(
    moment(`01/01/${new Date().getFullYear() - 1}`).format()
  );
  const [endDate, setEndDate] = React.useState(
    moment(`01/01/${new Date().getFullYear()}`).format()
  );
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    V3.get(`/statistic`, {
      params: {
        firstMonth: moment(startDate).format("MM.YYYY"),
        secondMonth: moment(endDate).format("MM.YYYY"),
      },
    }).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  }, [startDate, endDate]);

  return (
    <Card
      title="Compare statistics"
      button={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="From"
              format="MM/yyyy"
              value={startDate}
              views={["year", "month"]}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="To"
              format="MM/yyyy"
              value={endDate}
              views={["year", "month"]}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      }
    >
      {loading && <Loader />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Statistic for {moment(startDate).format("MM/YYYY")}
                </TableCell>
                <TableCell component="th" scope="row"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Activated Jobs
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.jobs || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Deactivated Jobs
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.deactivatedJobs || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Application
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.application +
                    data?.firstMonth?.candidates || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Contacting
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.contacting || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Prospect Profiles
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.prospectProfile || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Candidates
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.firstMonth?.candidates || "0"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} md={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Statistic for {moment(endDate).format("MM/YYYY")}
                </TableCell>
                <TableCell component="th" scope="row"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Activated Jobs
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.jobs || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Deactivated Jobs
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.deactivatedJobs || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Application
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.application +
                    data?.secondMonth?.candidates || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Contacting
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.contacting || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Prospect Profiles
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.prospectProfile || "0"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="td" scope="row">
                  Candidates
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  {data?.secondMonth?.candidates || "0"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Card>
  );
}
