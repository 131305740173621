import React from "react";
import LayoutDefault from "../../../Layouts/Default";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Default = ({
  component: Component,
  setTheme,
  theme,
  path,
  checkPath,
  ...rest
}) => {
  const { isLoading, isAuthenticated, user } = useSelector(
    (state) => state.auth
  );
  const { push } = useHistory();
  if (isLoading === false) {
    let flag = user?.routes.find((el) =>
      el.includes(path.replace("/:id", "")) ? true : false
    );

    if (path.includes("job")) {
      flag = user?.routes.find((el) => (el === "/jobs" ? true : false));
    }

    if (checkPath && !flag) {
      push("/");
    }

    return (
      <Route
        {...rest}
        path={path}
        render={(props) =>
          isAuthenticated && user ? (
            <LayoutDefault setTheme={setTheme} theme={theme}>
              <Component {...props} />
            </LayoutDefault>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  } else {
    return <React.Fragment />;
  }
};

export default Default;
