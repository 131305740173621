import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../../../components/Loader";
import { V3 } from "../../../../lib/api";
import EditIcon from "@material-ui/icons/Edit";

function Delete(props) {
  const [open, setOpen] = React.useState(false);

  const { profile } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikAPi) => {
    V3.put(
      `/jobs/${props.jobId}/interview-question/${profile._id}`,
      values
    ).then((res) => {
      formikAPi.setSubmitting(false);
      props.handleUpdate(res.data.data.interviewQuestions);
      setOpen(false);
    });
  };

  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <EditIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bearbeiten Sie diese frage</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              question: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              question: profile?.question,
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question"
                  name="question"
                  style={{ margin: 0, marginTop: 15 }}
                  multiline={true}
                  rows={5}
                />

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="Submit">
                    update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
