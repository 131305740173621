import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { TextField as FormikTextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import KeyboardDatePicker from "../../../../Views/crm/candidates/KeyboardDatePicker";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "../../../Card";
import { V3 } from "../../../../lib/api";
import Progress from "../../../Company/Widgets/Progress";
import Editor from "../../../Editor";

const Position = styled.div`
  border: 2px solid ${(props) => props.theme.palette.divider};
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  .header {
    display: flex;
    padding: 15px;
    border-bottom: 2px solid ${(props) => props.theme.palette.divider};

    > div {
      width: 50%;

      &:last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
`;
function Invoice({ values, setFieldValue, company }) {
  const [progress, setProgress] = useState(null);
  const [length, setLength] = useState(0);
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    V3.get(`/company-referrals/analytics?crmCompany=${company._id}`).then(
      (response) => {
        setProgress(response.data.progress);
        setLength(response.data.length);
        setCompanyData(response.data.companyData);
      }
    );
  }, [company]);

  const addServiceItem = () => {
    setFieldValue("services", {
      description: values.services.description,
      items: [
        ...values.services.items,
        { name: "", jobs: 0, description: "", price: 0 },
      ],
    });
  };

  let totalPackage = values.package.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);

  if (values.package.discount) {
    totalPackage -= values.package.discount;
  }
  let totalServices = values.services.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);

  if (values.services.discount) {
    totalServices -= values.services.discount;
  }
  let totalAllInOne = values.allInOne.items.reduce((a, b) => {
    const totalRetail = +a + +parseFloat(b.price ? b.price : 0);
    return totalRetail;
  }, 0);
  if (values.allInOne.discount) {
    totalAllInOne -= values.allInOne.discount;
  }

  const updatePackageItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.package;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("package", data);
  };
  const updateAllInOneItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.allInOne;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("allInOne", data);
  };
  const updatePackageItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.package;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("package", data);
  };
  const updateAllInOneItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.allInOne;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("allInOne", data);
  };
  const updateServiceItem = (evt, index) => {
    const value = evt.target.value;

    const data = values.services;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("services", data);
  };
  const updateServiceItemPrice = (evt, index) => {
    const value = evt.target.valueAsNumber;

    const data = values.services;

    data.items[index] = {
      ...data.items[index],
      [evt.target.name]: value,
    };

    setFieldValue("services", data);
  };

  const updatePackageDescription = (evt) => {
    const value = evt.target.value;

    const data = values.package;

    data.description = value;
    setFieldValue("package", data);
  };
  const updatePackageDiscount = (evt) => {
    if (evt?.target?.value) {
      evt.target.value = parseInt(evt.target.value);
    }
    const value = evt.target.valueAsNumber;

    const data = values.package;

    data.discount = value;
    setFieldValue("package", data);
  };
  const updateServiceDescription = (evt) => {
    const value = evt.target.value;

    const data = values.services;

    data.description = value;
    setFieldValue("services", data);
  };
  const updateAllInOneDescription = (evt) => {
    const value = evt.target.value;

    const data = values.allInOne;

    data.description = value;
    setFieldValue("allInOne", data);
  };
  const updateServiceDiscount = (evt) => {
    if (evt?.target?.value) {
      evt.target.value = parseInt(evt.target.value);
    }
    const value = evt.target.valueAsNumber;

    const data = values.services;

    data.discount = value;
    setFieldValue("services", data);
  };
  const updateAllInOneDiscount = (evt) => {
    if (evt?.target?.value) {
      evt.target.value = parseInt(evt.target.value);
    }
    const value = evt.target.valueAsNumber;

    const data = values.allInOne;

    data.discount = value;
    setFieldValue("allInOne", data);
  };

  const contactPersons = company.contactPersons
    .map((person) => {
      return {
        ...person,
        key: person.email,
        name: person.firstName + " " + person.lastName,
        email: person.email,
        phone: person.phone,
        label: person.firstName + " " + person.lastName,
        value: person.firstName + " " + person.lastName,
      };
    })
    .filter((el) => el.key !== null && el?.key !== undefined && el?.key !== "");

  return (
    <React.Fragment>
      {length > 0 ? (
        <Progress data={progress} companyData={companyData} />
      ) : null}
      <Divider style={{ margin: "30px 0" }} />
      {/* vertragesnr */}
      <Card
        title="Invoice info"
        contentStyles={{ padding: 15 }}
        button={
          <>
            <div style={{ width: 180, display: "flex", alignItems: "center" }}>
              Show invoice
              <Field
                component={Switch}
                name="show"
                id="show"
                style={{ width: "auto", marginLeft: 25 }}
              />
            </div>
            <div style={{ width: 180, display: "flex", alignItems: "center" }}>
              Send Email
              <Field
                component={Switch}
                name="sendEmail"
                id="sendEmail"
                style={{ width: "auto", marginLeft: 25 }}
              />
            </div>
            <Field
              component={KeyboardDatePicker}
              label="Start Date"
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="startDate"
              size="small"
              style={{ maxWidth: 200, marginRight: 15 }}
            />
            <Field
              component={KeyboardDatePicker}
              label="End Date"
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="endDate"
              size="small"
              style={{ maxWidth: 200 }}
            />
          </>
        }
      >
        <Paper style={{ marginBottom: 15, padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Company"
                fullWidth
                value={values?.to.name}
                name="to.name"
                onChange={(e) => setFieldValue("to.name", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                fullWidth
                value={values?.to.street}
                name="to.street"
                onChange={(e) => setFieldValue("to.street", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Kanton"
                fullWidth
                value={values?.to.city}
                name="to.city"
                onChange={(e) => setFieldValue("to.city", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Zip"
                fullWidth
                value={values?.to.zip}
                name="to.zip"
                onChange={(e) => setFieldValue("to.zip", e.target.value)}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ padding: 15, marginBottom: 15 }}>
          <Field
            component={Autocomplete}
            fullWidth
            required
            name="contactPerson"
            label="Contact Person"
            variant="outlined"
            options={contactPersons}
            textFieldProps={{
              label: "Contact Person",
              variant: "outlined",
              style: { margin: 0 },
            }}
            style={{ margin: 0 }}
          />
        </Paper>
        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 1"
                fullWidth
                value={values.package.description}
                onChange={updatePackageDescription}
                disabled
              />
            </div>
            <div>
              <TextField
                label="Discount"
                type="number"
                value={values.package.discount}
                onChange={updatePackageDiscount}
                style={{ marginLeft: 15 }}
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalPackage}</strong>
              </span>
            </div>
          </div>

          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paket</TableCell>
                  <TableCell align="left">Anzahl Stellen</TableCell>
                  <TableCell align="left">Laufzeit Monate</TableCell>
                  <TableCell align="right">Paketpreis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.package.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Paket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl Stellen"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Laufzeit Monate"
                        fullWidth
                        value={row.months}
                        name="months"
                        onChange={(e) => updatePackageItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Paketpreis"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          if (e?.target?.value) {
                            e.target.value = parseInt(e.target.value);
                          }
                          updatePackageItemPrice(e, index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Editor
            label="Free text"
            value={values.services.vertragesnr}
            onChange={(val) => setFieldValue("vertragesnr", val)}
            height={300}
          />
        </Position>

        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 2"
                fullWidth
                value={values.services.description}
                onChange={updateServiceDescription}
                disabled
              />
            </div>
            <div>
              <TextField
                label="Discount"
                type="number"
                value={values.services.discount}
                onChange={updateServiceDiscount}
                style={{ marginLeft: 15 }}
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalServices}</strong>
              </span>
            </div>
          </div>
          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Leistungspaket</TableCell>
                  <TableCell align="left">Anzahl</TableCell>
                  <TableCell align="left">Betrifft folgende Stellen</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.services.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Leistungspaket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Betrifft folgende Stellen"
                        fullWidth
                        value={row.description}
                        name="description"
                        onChange={(e) => updateServiceItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Preis Zusatzdienstleistungen"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          if (e?.target?.value) {
                            e.target.value = parseInt(e.target.value);
                          }
                          updateServiceItemPrice(e, index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <Button onClick={addServiceItem}>Add item</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <Editor
            label="Free text"
            value={values.services.vertragesnrActiveSourcing}
            onChange={(val) => setFieldValue("vertragesnrActiveSourcing", val)}
            height={300}
          />
        </Position>
        <Position>
          <div className="header">
            <div>
              <TextField
                label="Position 3"
                fullWidth
                value={values.allInOne.description}
                onChange={updateAllInOneDescription}
                disabled
              />
            </div>
            <div>
              <TextField
                label="Discount"
                type="number"
                value={values.allInOne.discount}
                onChange={updateAllInOneDiscount}
                style={{ marginLeft: 15 }}
              />
            </div>
            <div>
              <span>
                Total: <strong>{totalAllInOne}</strong>
              </span>
            </div>
          </div>
          <div className="items">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Leistungspaket</TableCell>
                  <TableCell align="left">Anzahl</TableCell>
                  <TableCell align="left">Betrifft folgende Stellen</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.allInOne.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <TextField
                        label="Leistungspaket"
                        fullWidth
                        value={row.name}
                        name="name"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        type="number"
                        label="Anzahl"
                        fullWidth
                        value={row.jobs}
                        name="jobs"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Betrifft folgende Stellen"
                        fullWidth
                        value={row.description}
                        name="description"
                        onChange={(e) => updateAllInOneItem(e, index)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="Preis Zusatzdienstleistungen"
                        fullWidth
                        value={row.price}
                        name="price"
                        onChange={(e) => {
                          if (e?.target?.value) {
                            e.target.value = parseInt(e.target.value);
                          }
                          updateAllInOneItemPrice(e, index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Editor
            label="Free text"
            value={values.vertragesnrAllInOne}
            onChange={(val) => setFieldValue("vertragesnrAllInOne", val)}
            height={300}
          />
        </Position>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 15 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Active Services</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Active Sourcing"
                    checked={values.activeServices.ad}
                    onChange={(e) =>
                      setFieldValue("activeServices.ad", e.target.checked)
                    }
                  />
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 15 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gifts</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Active Sourcing"
                    checked={values.giftedServices.ad}
                    onChange={(e) =>
                      setFieldValue("giftedServices.ad", e.target.checked)
                    }
                  />
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
        <Paper style={{ marginTop: 15, padding: 15 }}>
          <Field
            component={FormikTextField}
            type="number"
            label="Discount"
            variant="outlined"
            name="discount"
            style={{ margin: 0 }}
          />
        </Paper>
      </Card>
    </React.Fragment>
  );
}

export default Invoice;
