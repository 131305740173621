import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/Table";
import Actions from "../../../components/Table/Actions";
import Card from "../../../components/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "./Delete";
import AddNew from "./addNew";
import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import { GET_JOB_VISITS, JOB_VISITS_LOADING } from "../../../redux/types";

function Jobs() {
  const { jobVisits, data, loading } = useSelector((state) => state?.jobVisit);
  const { job } = useSelector((state) => state?.job);
  const { user } = useSelector((state) => state?.auth);

  const dispatch = useDispatch();

  let toAddVisits = 0;

  if (data && data.total && job.visits) {
    toAddVisits = job.visits - data.total;
  }

  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      filterable: false,
      Cell: (props) => (
        <strong>{props.original.title || "User generated"}</strong>
      ),
    },
    {
      id: "datum",
      Header: "Datum",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          {props.original?.user ? (
            <Tooltip title="Delete this Visit">
              <Delete visitId={props.original._id} />
            </Tooltip>
          ) : (
            "-"
          )}
        </Actions>
      ),
    },
  ];
  if (
    user?.email === "kristian.oroshi@two.jobs" ||
    user?.email === "admin@test.com"
  ) {
    return (
      <Card
        title={`Jobs Visits - Total Visits: ${data.total} ${
          toAddVisits > 0 ? ` - You need to add ${toAddVisits} Visit` : ""
        } `}
        style={{ marginTop: 15 }}
        button={<AddNew id={job._id} />}
      >
        <Table
          data={jobVisits}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&job=${job._id}&${queryString}`;
            dispatch({
              type: JOB_VISITS_LOADING,
            });

            V3.get(`/job-visits${query}`).then((res) => {
              dispatch({
                type: GET_JOB_VISITS,
                payload: res.data,
              });
            });
          }}
          noDataText="No job Visits"
          filterable={false}
        />
      </Card>
    );
  }
  return null;
}

export default Jobs;
