import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "../Table";
import { V3 } from "../../lib/api";
import { IconButton, Tooltip } from "@material-ui/core";
import Facebook from "../Icons/recruiter/facebook";
import Twitter from "../Icons/recruiter/twitter";
import Xing from "../Icons/recruiter/xing";
import Linkedin from "../Icons/recruiter/linkedin";
import Whatsapp from "../Icons/recruiter/whatsapp";
import Mail from "../Icons/recruiter/mail";
import ExternalLink from "../Icons/recruiter/external-link";
import LinkIcon from "../Icons/recruiter/link";
import Instagram from "../Icons/recruiter/instagram";
import moment from "moment";
import Filters from "../RecruiterDashboard/Filters";
const AboutLinks = (props) => {
  const [links, setLinks] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(`01/01/2019`, "DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const [data, setData] = useState({ page: 1, limit: 20 });

  const [loading, setLoading] = useState(true);

  const getLinks = async (query) => {
    setLoading(true);
    try {
      const res = await V3.get(
        `shared-links/recruiter/${props.userId}${query}`
      );
      setData(res.data);
      setLinks(res.data.data);
      setLoading(false);
    } catch (error) {
      setData({});
      setLinks([]);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "Job/Company",
      accessor: "job",
      Cell: (props) => (
        <strong>
          {props.original.jobId && props.original.jobId._id ? (
            <Link to={`/jobs/edit/${props.original.jobId._id}`}>
              {props.original.jobId.title}
            </Link>
          ) : props.original.companyId && props.original.companyId._id ? (
            <Link to={`/companies/edit/${props.original.companyId._id}`}>
              {props.original.companyId.name}
            </Link>
          ) : (
            "-"
          )}
        </strong>
      ),
      width: 200,
    },
    {
      Header: "Geteilt am",
      accessor: "updatedAt",

      Cell: (props) => (
        <span className="number date">
          {props.value ? <Moment>{props.value}</Moment> : <span>-</span>}
        </span>
      ),
    },
    {
      Header: (
        <Tooltip title="Als Link geteilt">
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      ),
      accessor: "link",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },

    {
      Header: (
        <Tooltip title="Auf Facebook geteilt">
          <IconButton>
            <Facebook />
          </IconButton>
        </Tooltip>
      ),
      accessor: "facebook",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Instagram geteilt">
          <IconButton>
            <Instagram />
          </IconButton>
        </Tooltip>
      ),
      accessor: "instagram",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Twitter geteilt">
          <IconButton>
            <Twitter />
          </IconButton>
        </Tooltip>
      ),
      accessor: "twitter",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Xing geteilt">
          <IconButton>
            <Xing />
          </IconButton>
        </Tooltip>
      ),
      accessor: "xing",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Linkedin geteilt">
          <IconButton>
            <Linkedin />
          </IconButton>
        </Tooltip>
      ),
      accessor: "linkedin",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Whatsapp geteilt">
          <IconButton>
            <Whatsapp />
          </IconButton>
        </Tooltip>
      ),
      accessor: "whatsapp",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },
    {
      Header: (
        <Tooltip title="Auf Whatsapp geteilt">
          <IconButton>
            <Mail />
          </IconButton>
        </Tooltip>
      ),
      accessor: "mail",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
      width: 80,
    },

    {
      Header: "Teilen / Klicks",
      accessor: "points",
      filterable: false,
      Cell: (props) => <span className="number amount">{props.value}</span>,
    },
    {
      Header: (
        <Tooltip title="Weiterleitungen">
          <IconButton>
            <ExternalLink />
          </IconButton>
        </Tooltip>
      ),
      accessor: "jobId",
      filterable: false,
      Cell: (props) => {
        let externalClicks = 0;

        // eslint-disable-next-line no-unused-expressions
        data?.externalClicks?.map((el) => {
          if (el.jobID === props?.value?.slug) {
            externalClicks += el.points || 2;
          }
        });

        return <span className="number amount">{externalClicks}</span>;
      },
      width: 80,
    },
    {
      Header: "Total",
      accessor: "job",
      Cell: (props) => {
        let externalClicks = 0;

        // eslint-disable-next-line no-unused-expressions
        data?.externalClicks?.map((el) => {
          if (el.jobID === props.original.jobId?.slug) {
            externalClicks += el.points || 2;
          }
        });

        return <strong>{externalClicks + props.original.points || 0}</strong>;
      },
      width: 200,
    },
  ];
  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Filters
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <Table
        data={links}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        minRows={5}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          getLinks(query);
        }}
        noDataText="No Data"
        defaultFiltered={[
          {
            id: "startDate",
            value: startDate.format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: endDate.format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
};

export default AboutLinks;
