import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Editor from "../../components/Editor";
import Card from "../../components/Card";
import { Switch } from "material-ui-formik-components/Switch";
import GalleryBuilder from "../../components/GalleryBuilder";
import { Cantons2 } from "../../data";
import ImageUpload from "../../components/imageUpload";
import { V3 } from "../../lib/api";
import { ADD_PRODUCT } from "../../redux/types";
import { useSnackbar } from "notistack";

function AddProduct() {
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  const [categories, setCategories] = useState([]);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  useEffect(() => {
    V3.get(`/categories/products?limit=999`).then((res) => {
      setCategories(
        res?.data?.data?.map((el) => {
          return {
            key: el?._id,
            label: el?.de?.name,
            value: el?.de?.name,
          };
        })
      );
    });
  }, []);

  const onAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const onThumbnailChange = (event) => {
    setThumbnail(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setThumbnailPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const _handleSubmit = async (values) => {
    const { type, category, gallery, canton } = values;

    const newProductData = {
      ...values,
      type: type.key,
      category: category?.key,
      canton: canton?.key,
      gallery: gallery.map((el) => el._id),
    };

    if (avatar) {
      try {
        const formData = new FormData();

        formData.append("images", avatar);

        const res = await V3.post(`medias`, formData);

        if (res.data.data?.length) {
          newProductData.logo = res.data.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    if (thumbnail) {
      try {
        const formData = new FormData();

        formData.append("images", thumbnail);

        const res = await V3.post(`medias`, formData);

        if (res.data.data?.length) {
          newProductData.thumbnail = res.data.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    V3.post(`/products`, newProductData).then((res) => {
      dispatch({
        type: ADD_PRODUCT,
        payload: res.data.data,
      });
      enqueueSnackbar("Product created successfully", {
        variant: "success",
      });
      push("/products");
    });
  };
  return (
    <Formik
      validationSchema={Yup.object().shape(
        {
          title: Yup.string().nullable().required("Required"),
          type: Yup.object().nullable().required("Required"),
          description: Yup.string().nullable(),
          descriptionTitle: Yup.string().nullable(),
          terms: Yup.string().nullable(),
          rabatCode: Yup.string().nullable(),
          stock: Yup.number().nullable(),
          points: Yup.number().nullable().required("Required"),
          chfPrice: Yup.number().nullable().required("Required"),
          internalNote: Yup.string().nullable(),
          video: Yup.string().nullable(),
          favoriteProduct: Yup.boolean(),
          canton: Yup.object()
            .nullable()
            .when("type", {
              is: (type) => {
                return type?.key === 2;
              },
              then: Yup.object().required("Required"),
            }),
        },
        ["type"]
      )}
      onSubmit={_handleSubmit}
      enableReinitialize
      initialValues={{
        title: "",
        description: `<p><span style="font-family: Arial; font-size: 18px;">​<br>`,
        descriptionTitle: "Beschreibung",
        gallery: [],
        rabatCode: "",
        terms: `<p><span style="font-family: Arial; font-size: 18px;">​<br>`,
        internalNote: `<p><span style="font-family: Arial; font-size: 18px;">​<br>`,
        points: 0,
        chfPrice: 0,
        stock: 0,
        video: "",
        favoriteProduct: false,
        canton: null,
        type: null,
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, initialValues, values }) => (
        <Form>
          {isSubmitting && <Loader></Loader>}
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  style={{ margin: 0 }}
                />
              </Paper>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="video"
                  label="Video"
                  name="video"
                  style={{ margin: 0 }}
                />
              </Paper>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="descriptionTitle"
                  label="Description Title"
                  name="descriptionTitle"
                  style={{ margin: 0 }}
                />
              </Paper>

              <Card
                title="Beschreibung"
                style={{ marginBottom: 15, overflow: "hidden" }}
              >
                <Editor
                  value={initialValues.description}
                  onChange={(value) => setFieldValue("description", value)}
                />
              </Card>
              <Card title="Terms" style={{ marginBottom: 15, marginTop: 15 }}>
                <Editor
                  value={initialValues.terms}
                  onChange={(value) => setFieldValue("terms", value)}
                />
              </Card>
              <GalleryBuilder
                images={values.gallery}
                onChange={(value) => {
                  setFieldValue("gallery", value);
                }}
                onRemove={(item) => {
                  setFieldValue(
                    "gallery",
                    values.gallery.filter((i) => i._id !== item._id)
                  );
                }}
              />
              <Card title="Internal Note">
                <Editor
                  value={values.internalNote}
                  onChange={(value) => setFieldValue("internalNote", value)}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!dirty}
                  style={{ padding: "16px 32px" }}
                >
                  Create
                </Button>
              </Paper>
              <Paper style={{ overflow: "hidden" }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="points"
                          label="Klicks"
                          name="points"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="chfPrice"
                          label="CHF"
                          name="chfPrice"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="stock"
                          label="Bestand"
                          name="stock"
                          style={{ margin: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          name="type"
                          options={[
                            {
                              key: 1,
                              label: "Gutschein",
                              value: "Gutschein",
                            },
                            {
                              key: 2,
                              label: "Aktivität",
                              value: "Aktivität",
                            },
                          ]}
                          component={Autocomplete}
                          textFieldProps={{
                            label: "Typ",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          name="category"
                          options={categories}
                          component={Autocomplete}
                          textFieldProps={{
                            label: "Category",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    {values?.type?.key === 2 && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="canton"
                            options={Cantons2}
                            component={Autocomplete}
                            textFieldProps={{
                              label: "Kanton",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {values.type && values.type.value === "Gutschein" && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            fullWidth
                            id="rabatCode"
                            label="Rabat Code"
                            name="rabatCode"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Product - Favorite
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field
                            component={Switch}
                            name="favoriteProduct"
                            id="favoriteProduct"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
              <Paper style={{ marginTop: 15 }}>
                <ImageUpload
                  imagePreviewUrl={avatarPreview}
                  onImageChange={onAvatarChange}
                  id="avatar"
                  label={"Logo"}
                  onRemove={() => {
                    setAvatar(null);
                    setAvatarPreview(null);
                  }}
                />
              </Paper>
              <Paper style={{ marginTop: 15 }}>
                <ImageUpload
                  imagePreviewUrl={thumbnailPreview}
                  onImageChange={onThumbnailChange}
                  id="thumbnail"
                  label={"Thumbnail"}
                  onRemove={() => {
                    setThumbnail(null);
                    setThumbnailPreview(null);
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AddProduct;
