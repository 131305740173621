import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { TextField } from "@material-ui/core";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 15;
  position: relative;
  z-index: 99;
  width: 100%;
`;
const InputWrapper = styled.div`
  background: #fff;
  overflow: hidden;
  width: "100%";
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  input {
    color: #000 !important;
    background-color: #fff;
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
  .search--location {
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 20px;
    left: 20px;
    input {
      padding: 15px 20px;
      border: 2px solid rgba(25, 80, 121, 0.6);
      &:focus {
        border: 2px solid rgba(25, 80, 121, 1);
      }
    }
  }
  .autocomplete-dropdown-container {
    background: #fff !important;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    position: absolute;
    > div {
      line-height: 40px;
      padding: 0 20px;
      border-bottom: 1px solid #ccc;
      color: #000 !important;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: rgba(25, 80, 121, 0.4);
      }
    }
  }
`;

function Location({ address, setAddress }) {
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setAddress(address);
      })
      .catch((error) => console.error("Error", error));
  };
  return (
    <Wrapper>
      <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search--location">
            <InputWrapper>
              <TextField
                fullWidth
                {...getInputProps({
                  placeholder: "Search Address",
                  className: "location-search-input",
                })}
              />

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </InputWrapper>
          </div>
        )}
      </PlacesAutocomplete>
    </Wrapper>
  );
}

export default Location;
