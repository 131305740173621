import React, { forwardRef } from "react";
import styled from "styled-components";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useElementSize } from "usehooks-ts";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";

const Wrapper = styled(Grid)`
  position: relative;
  button {
    position: absolute;
    top: 25px;
    right: 10px;
  }
`;

const Card = styled.div`
  overflow: hidden;
  transition: 0.3s ease-in-out;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Item = forwardRef(
  ({ id, withOpacity, isDragging, style, onRemove, ...props }, ref) => {
    const inlineStyles = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      borderRadius: "10px",
      cursor: isDragging ? "grabbing" : "grab",
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    const [squareRef, { width }] = useElementSize();

    return (
      <Wrapper item xs={6} md={3} ref={squareRef} style={{ height: width }}>
        {id.loading ? (
          <Card ref={ref} style={inlineStyles} {...props}>
            <CircularProgress />
          </Card>
        ) : (
          <>
            <Card ref={ref} style={inlineStyles} {...props}>
              <img src={id?.sm?.url} alt="" />
            </Card>
            {onRemove && (
              <IconButton
                variant="contained"
                style={{
                  border: "1px solid #000",
                  backgroundColor: "#fff",
                  marginRight: 8,
                }}
                onClick={() => onRemove(id)}
              >
                <DeleteOutlinedIcon style={{ color: "#000" }} />
              </IconButton>
            )}
          </>
        )}
      </Wrapper>
    );
  }
);

export default Item;
