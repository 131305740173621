import * as React from "react";

function SvgComponent() {
  return (
    <svg
      className='prefix__animated'
      id='prefix__freepik_stories-events'
      viewBox='0 0 500 500'>
      <style>
        {
          "@keyframes floating{0%,to{opacity:1;transform:translateY(0)}50%{transform:translateY(-10px)}}svg#prefix__freepik_stories-events:not(.prefix__animated) .prefix__animable{opacity:0}"
        }
      </style>
      <g
        id='prefix__freepik--background-complete--inject-67'
        className='prefix__animable'
        style={{
          transformOrigin: "250px 226.87px",
        }}>
        <path
          style={{
            transformOrigin: "250px 382.525px",
          }}
          id='prefix__el3oghk7ijm07'
          className='prefix__animable'
          fill='#ebebeb'
          d='M0 382.4h500v.25H0z'
        />
        <path
          style={{
            transformOrigin: "406.45px 398.615px",
          }}
          id='prefix__elawd8mggr65'
          className='prefix__animable'
          fill='#ebebeb'
          d='M363 398.49h86.9v.25H363z'
        />
        <path
          style={{
            transformOrigin: "362.005px 389.335px",
          }}
          id='prefix__elz1ulrwrow9'
          className='prefix__animable'
          fill='#ebebeb'
          d='M357.66 389.21h8.69v.25h-8.69z'
        />
        <path
          style={{
            transformOrigin: "289.715px 397.625px",
          }}
          id='prefix__elwj4gxi9j5if'
          className='prefix__animable'
          fill='#ebebeb'
          d='M280.12 397.5h19.19v.25h-19.19z'
        />
        <path
          style={{
            transformOrigin: "63.26px 391.015px",
          }}
          id='prefix__el02xoij8z3psm'
          className='prefix__animable'
          fill='#ebebeb'
          d='M52.46 390.89h21.6v.25h-21.6z'
        />
        <path
          style={{
            transformOrigin: "96.925px 391.015px",
          }}
          id='prefix__elykgf9rdaafe'
          className='prefix__animable'
          fill='#ebebeb'
          d='M82.96 390.89h27.93v.25H82.96z'
        />
        <path
          style={{
            transformOrigin: "231.09px 395.115px",
          }}
          id='prefix__elsg8uth3f0di'
          className='prefix__animable'
          fill='#ebebeb'
          d='M207.67 394.99h46.84v.25h-46.84z'
        />
        <path
          d='M237 337.8H43.91a5.71 5.71 0 01-5.7-5.71V60.66a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71v271.38a5.71 5.71 0 01-5.71 5.71zM43.91 55.2a5.46 5.46 0 00-5.45 5.46v271.43a5.46 5.46 0 005.45 5.46H237a5.47 5.47 0 005.46-5.46V60.66A5.47 5.47 0 00237 55.2z'
          style={{
            transformOrigin: "140.46px 196.4px",
          }}
          id='prefix__elnycg15tdkul'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M453.31 337.8h-193.1a5.72 5.72 0 01-5.71-5.71V60.66a5.72 5.72 0 015.71-5.66h193.1a5.71 5.71 0 015.69 5.66v271.43a5.71 5.71 0 01-5.69 5.71zM260.21 55.2a5.47 5.47 0 00-5.46 5.46v271.43a5.47 5.47 0 005.46 5.46h193.1a5.47 5.47 0 005.46-5.46V60.66a5.47 5.47 0 00-5.46-5.46z'
          style={{
            transformOrigin: "356.75px 196.4px",
          }}
          id='prefix__eldft8uo65m5'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          style={{
            transformOrigin: "131.325px 183.505px",
          }}
          id='prefix__elxm7s87xm5u'
          className='prefix__animable'
          fill='#e6e6e6'
          d='M54.01 72.94h154.63v221.13H54.01z'
        />
        <path
          style={{
            transformOrigin: "134.73px 183.505px",
          }}
          id='prefix__elq816xfdyyq'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M56.18 72.94h157.1v221.13H56.18z'
        />
        <path
          style={{
            transformOrigin: "131.325px 296.095px",
          }}
          id='prefix__el37y5c661yo9'
          className='prefix__animable'
          fill='#e6e6e6'
          d='M54.01 294.07h154.63v4.05H54.01z'
        />
        <path
          style={{
            transformOrigin: "137.2px 296.095px",
          }}
          id='prefix__eln0mcfhnd1jj'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M58.65 294.07h157.1v4.05H58.65z'
        />
        <path
          style={{
            transformOrigin: "134.73px 183.505px",
          }}
          className='prefix__animable'
          fill='#fafafa'
          transform='rotate(90)'
          d='M30.66 111.45H238.8v144.11H30.66z'
          id='prefix__ellkn1gyr306n'
        />
        <path
          style={{
            transformOrigin: "155.74px 183.505px",
          }}
          id='prefix__eld16zpq19gp7'
          className='prefix__animable'
          fill='#fff'
          d='M129.26 287.57l34.96-208.13h18l-34.96 208.13h-18z'
        />
        <path
          style={{
            transformOrigin: "110.32px 183.505px",
          }}
          id='prefix__ellv4t1s2ng4'
          className='prefix__animable'
          fill='#fff'
          d='M83.84 287.57L118.8 79.44h18l-34.96 208.13h-18z'
        />
        <path
          d='M65.68 183.1c-.27 0-.49-.33-.49-.74V83.9c0-.41.22-.73.49-.73s.5.32.5.73v98.46c0 .41-.18.74-.5.74z'
          style={{
            transformOrigin: "65.685px 133.135px",
          }}
          id='prefix__el60si2q8h5vj'
          className='prefix__animable'
          fill='#f0f0f0'
        />
        <path
          style={{
            transformOrigin: "172.115px 183.505px",
          }}
          id='prefix__el4ia1x6wlt18'
          className='prefix__animable'
          fill='#fff'
          d='M151.13 287.57l34.96-208.13h7.01l-34.96 208.13h-7.01z'
        />
        <path
          style={{
            transformOrigin: "206.38px 183.5px",
          }}
          className='prefix__animable'
          fill='#e6e6e6'
          transform='rotate(90)'
          d='M102.31 183.09h208.14v.82H102.31z'
          id='prefix__elo3g4ars46hk'
        />
        <path
          style={{
            transformOrigin: "137.205px 90.085px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 98.21H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__elajx2hkjd3q6'
        />
        <path
          style={{
            transformOrigin: "137.205px 116.79px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 124.91H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__el82fqicn6999'
        />
        <path
          style={{
            transformOrigin: "137.205px 143.5px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 151.62H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__el40fk096rbpr'
        />
        <path
          style={{
            transformOrigin: "137.205px 170.205px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 178.33H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__el6ln36bumuap'
        />
        <path
          style={{
            transformOrigin: "137.205px 196.91px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 205.03H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__elvkpt4wky01'
        />
        <path
          style={{
            transformOrigin: "137.205px 223.62px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 231.74H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__elpw8ysch232j'
        />
        <path
          style={{
            transformOrigin: "137.205px 103.435px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 111.56H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__elxnnf8x9xjtb'
        />
        <path
          style={{
            transformOrigin: "137.205px 130.145px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 138.27H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__elecvcka34bxk'
        />
        <path
          style={{
            transformOrigin: "137.205px 156.85px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 164.97H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__el0jsb8b0xijt'
        />
        <path
          style={{
            transformOrigin: "137.205px 183.56px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 191.68H60.19l-.6-16.24h154.63l.6 16.24z'
          id='prefix__els1ru530pvvo'
        />
        <path
          style={{
            transformOrigin: "137.205px 210.265px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 218.39H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__elxdw52nf41fc'
        />
        <path
          style={{
            transformOrigin: "137.205px 236.975px",
          }}
          className='prefix__animable'
          fill='#e0e0e0'
          opacity={0.5}
          d='M214.82 245.1H60.19l-.6-16.25h154.63l.6 16.25z'
          id='prefix__el05ma8w7tggok'
        />
        <path
          style={{
            transformOrigin: "281.66px 301.775px",
          }}
          id='prefix__elbidygxqedwd'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M266.25 224.55h30.82V379h-30.82z'
        />
        <path
          style={{
            transformOrigin: "348.16px 380.7px",
          }}
          id='prefix__elcyjm66xaxu9'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M272.17 379h151.98v3.4H272.17z'
        />
        <path
          style={{
            transformOrigin: "363.565px 301.775px",
          }}
          className='prefix__animable'
          fill='#f5f5f5'
          transform='rotate(180)'
          d='M297.07 224.55h132.99V379H297.07z'
          id='prefix__elfv5u6jirxen'
        />
        <path
          d='M414.76 311.57H312.35a3.68 3.68 0 01-3.67-3.67V284a3.68 3.68 0 013.67-3.68h16.93a3.71 3.71 0 013.27 2.07 3.82 3.82 0 003.37 2h55.29a3.81 3.81 0 002.69-1.12 4.12 4.12 0 00.72-1 3.63 3.63 0 013.24-2h16.9a3.68 3.68 0 013.68 3.68v23.95a3.68 3.68 0 01-3.68 3.67zm-102.41-30.24a2.68 2.68 0 00-2.67 2.68v23.89a2.68 2.68 0 002.67 2.67h102.41a2.68 2.68 0 002.68-2.67V284a2.68 2.68 0 00-2.68-2.68h-16.9a2.64 2.64 0 00-2.35 1.42 4.83 4.83 0 01-.91 1.25 4.78 4.78 0 01-3.39 1.41h-55.29a4.82 4.82 0 01-4.25-2.54 2.74 2.74 0 00-2.39-1.54z'
          style={{
            transformOrigin: "363.56px 295.92px",
          }}
          id='prefix__elnnybttlugzq'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M414.76 353.3H312.35a3.68 3.68 0 01-3.67-3.67v-23.89a3.68 3.68 0 013.67-3.68h16.93a3.71 3.71 0 013.27 2.07 3.82 3.82 0 003.37 2h55.29a3.73 3.73 0 002.68-1.12 3.9 3.9 0 00.73-1 3.63 3.63 0 013.24-2h16.9a3.68 3.68 0 013.68 3.68v23.89a3.68 3.68 0 01-3.68 3.72zm-102.41-30.24a2.68 2.68 0 00-2.67 2.68v23.89a2.68 2.68 0 002.67 2.67h102.41a2.68 2.68 0 002.68-2.67v-23.89a2.68 2.68 0 00-2.68-2.68h-16.9a2.64 2.64 0 00-2.35 1.42 4.83 4.83 0 01-.91 1.25 4.72 4.72 0 01-3.39 1.41h-55.29a4.82 4.82 0 01-4.25-2.54 2.74 2.74 0 00-2.39-1.54z'
          style={{
            transformOrigin: "363.56px 337.655px",
          }}
          id='prefix__elvxpwf9llwu'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M418.44 371.88H308.68V363h109.76zm-108.76-1h107.76V364H309.68z'
          style={{
            transformOrigin: "363.56px 367.44px",
          }}
          id='prefix__elk8yypm8cg0a'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          d='M414.76 269.85H312.35a3.68 3.68 0 01-3.67-3.67v-23.89a3.68 3.68 0 013.67-3.68h16.93a3.71 3.71 0 013.27 2.06 3.81 3.81 0 003.37 2h55.29a3.81 3.81 0 002.69-1.12 4.12 4.12 0 00.72-1 3.63 3.63 0 013.24-2h16.9a3.68 3.68 0 013.68 3.68v23.89a3.68 3.68 0 01-3.68 3.73zm-102.41-30.24a2.68 2.68 0 00-2.67 2.68v23.89a2.68 2.68 0 002.67 2.67h102.41a2.68 2.68 0 002.68-2.67v-23.89a2.68 2.68 0 00-2.68-2.68h-16.9a2.64 2.64 0 00-2.35 1.42 4.83 4.83 0 01-.91 1.25 4.76 4.76 0 01-3.39 1.41h-55.29a4.83 4.83 0 01-4.25-2.54 2.74 2.74 0 00-2.39-1.54z'
          style={{
            transformOrigin: "363.56px 254.2px",
          }}
          id='prefix__elor4vrqz3mcg'
          className='prefix__animable'
          fill='#ebebeb'
        />
        <path
          style={{
            transformOrigin: "365.62px 223.305px",
          }}
          id='prefix__eluti7z44v5ka'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M297.07 222.06h137.1v2.49h-137.1z'
        />
        <path
          style={{
            transformOrigin: "279.5px 223.305px",
          }}
          className='prefix__animable'
          fill='#e6e6e6'
          transform='rotate(180)'
          d='M261.94 222.06h35.12v2.49h-35.12z'
          id='prefix__elxffosuny59'
        />
        <path
          style={{
            transformOrigin: "345.505px 136.645px",
          }}
          id='prefix__el4dp7qx4gzd8'
          className='prefix__animable'
          fill='#e6e6e6'
          d='M286.69 78.27h117.63v116.75H286.69z'
        />
        <path
          style={{
            transformOrigin: "349.28px 136.645px",
          }}
          id='prefix__el1ra83owtzec'
          className='prefix__animable'
          fill='#f0f0f0'
          d='M287.89 78.27h122.78v116.75H287.89z'
        />
        <path
          style={{
            transformOrigin: "349.28px 136.65px",
          }}
          className='prefix__animable'
          fill='#fff'
          transform='rotate(90)'
          d='M296.57 80.93h105.42v111.44H296.57z'
          id='prefix__elxwxefwmlx3'
        />
        <path
          d='M349.28 180.05a43.4 43.4 0 1143.4-43.4 43.45 43.45 0 01-43.4 43.4zm0-86.05a42.65 42.65 0 1042.65 42.65A42.69 42.69 0 00349.28 94z'
          style={{
            transformOrigin: "349.28px 136.65px",
          }}
          id='prefix__elpjrdank30kh'
          className='prefix__animable'
          fill='#f5f5f5'
        />
      </g>
      <g
        id='prefix__freepik--Shadow--inject-67'
        className='prefix__animable'
        style={{
          transformOrigin: "250px 416.24px",
        }}>
        <ellipse
          id='prefix__freepik--path--inject-67'
          cx={250}
          cy={416.24}
          rx={193.89}
          ry={11.32}
          style={{
            transformOrigin: "250px 416.24px",
          }}
          className='prefix__animable'
          fill='#f5f5f5'
        />
      </g>
      <g
        id='prefix__freepik--event-calendar--inject-67'
        className='prefix__animable'
        style={{
          transformOrigin: "214.275px 280.835px",
          animation: "1.5s Infinite linear floating",
          animationDelay: "0s",
        }}>
        <path
          style={{
            transformOrigin: "199.025px 283.405px",
          }}
          id='prefix__elz2yxv9f8acp'
          className='prefix__animable'
          fill='#407bff'
          d='M67.78 359.58h244.89l17.6-152.35H85.39L67.78 359.58z'
        />
        <path
          style={{
            transformOrigin: "199.025px 283.405px",
          }}
          className='prefix__animable'
          fill='#fff'
          opacity={0.4}
          d='M67.78 359.58h244.89l17.6-152.35H85.39L67.78 359.58z'
          id='prefix__el1if2lm0sxaj'
        />
        <path
          style={{
            transformOrigin: "223.08px 286.26px",
          }}
          id='prefix__elzztfjji87tf'
          className='prefix__animable'
          fill='#407bff'
          d='M360.77 365.29H115.88L85.39 207.23h244.88l30.5 158.06z'
        />
        <path
          style={{
            transformOrigin: "223.08px 286.26px",
          }}
          className='prefix__animable'
          fill='#fff'
          opacity={0.6}
          d='M360.77 365.29H115.88L85.39 207.23h244.88l30.5 158.06z'
          id='prefix__ely20baplr48b'
        />
        <path
          style={{
            transformOrigin: "224.37px 292.955px",
          }}
          id='prefix__eljh4trujqeei'
          className='prefix__animable'
          fill='#fff'
          d='M351.39 356.81H121.98L97.35 229.1h229.41l24.63 127.71z'
        />
        <g
          style={{
            transformOrigin: "224.37px 292.955px",
          }}
          className='prefix__animable'
          opacity={0.5}
          id='prefix__elar41w6ur7n'>
          <path
            style={{
              transformOrigin: "125.53px 248.07px",
            }}
            id='prefix__el6z3qu7r88a2'
            className='prefix__animable'
            fill='#407bff'
            d='M139.84 260.07h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "305.9px 248.07px",
            }}
            id='prefix__elx0whrqzoryo'
            className='prefix__animable'
            fill='#407bff'
            d='M320.21 260.07h-24l-4.62-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "275.83px 248.07px",
            }}
            id='prefix__el0gynkae7k1ca'
            className='prefix__animable'
            fill='#407bff'
            d='M290.14 260.07h-23.99l-4.63-24h24l4.62 24z'
          />
          <path
            style={{
              transformOrigin: "245.77px 248.07px",
            }}
            id='prefix__elfptzsrx627'
            className='prefix__animable'
            fill='#407bff'
            d='M260.08 260.07h-23.99l-4.63-24h24l4.62 24z'
          />
          <path
            style={{
              transformOrigin: "215.71px 248.07px",
            }}
            id='prefix__el1wp0w0pvlka'
            className='prefix__animable'
            fill='#407bff'
            d='M230.02 260.07h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "185.65px 248.07px",
            }}
            id='prefix__el245esoa42lw'
            className='prefix__animable'
            fill='#407bff'
            d='M199.96 260.07h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "155.59px 248.07px",
            }}
            id='prefix__elbrlrnpbuc44'
            className='prefix__animable'
            fill='#407bff'
            d='M169.9 260.07h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "131.3px 277.995px",
            }}
            className='prefix__animable'
            fill='#407bff'
            opacity={0.5}
            d='M145.61 289.99h-23.99L116.99 266h23.99l4.63 23.99z'
            id='prefix__elj8o6nwl40d'
          />
          <path
            style={{
              transformOrigin: "311.67px 277.995px",
            }}
            id='prefix__el171soy0ahzt'
            className='prefix__animable'
            fill='#407bff'
            d='M325.98 289.99h-23.99L297.36 266h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "281.61px 277.995px",
            }}
            className='prefix__animable'
            fill='#407bff'
            opacity={0.5}
            d='M295.92 289.99h-23.99L267.3 266h23.99l4.63 23.99z'
            id='prefix__elcjg1264b9w8'
          />
          <path
            style={{
              transformOrigin: "251.55px 277.995px",
            }}
            id='prefix__elzo4dyzi7r0s'
            className='prefix__animable'
            fill='#407bff'
            d='M265.86 289.99h-24L237.24 266h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "221.48px 277.995px",
            }}
            id='prefix__elhqn6p0ph95s'
            className='prefix__animable'
            fill='#407bff'
            d='M235.79 289.99H211.8L207.17 266h24l4.62 23.99z'
          />
          <path
            style={{
              transformOrigin: "191.42px 277.995px",
            }}
            id='prefix__el75jz7pamwin'
            className='prefix__animable'
            fill='#407bff'
            d='M205.73 289.99h-23.99L177.11 266h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "161.36px 277.995px",
            }}
            id='prefix__elkvlz0p9aj'
            className='prefix__animable'
            fill='#407bff'
            d='M175.67 289.99h-23.99L147.05 266h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "137.07px 307.915px",
            }}
            id='prefix__els93a73um0y'
            className='prefix__animable'
            fill='#407bff'
            d='M151.38 319.91h-23.99l-4.63-23.99h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "317.44px 307.915px",
            }}
            id='prefix__elxnzo98q2ses'
            className='prefix__animable'
            fill='#407bff'
            d='M331.75 319.91h-23.99l-4.63-23.99h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "287.38px 307.915px",
            }}
            id='prefix__el7okzqs4cb0t'
            className='prefix__animable'
            fill='#407bff'
            d='M301.69 319.91H277.7l-4.63-23.99h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "257.32px 307.915px",
            }}
            id='prefix__eltiurm05babs'
            className='prefix__animable'
            fill='#407bff'
            d='M271.63 319.91h-23.99l-4.63-23.99H267l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "227.26px 307.915px",
            }}
            id='prefix__el7r04l8t8it3'
            className='prefix__animable'
            fill='#407bff'
            d='M241.57 319.91h-24l-4.62-23.99h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "197.19px 307.915px",
            }}
            id='prefix__el3trldinv1j9'
            className='prefix__animable'
            fill='#407bff'
            d='M211.5 319.91h-23.99l-4.63-23.99h24l4.62 23.99z'
          />
          <path
            style={{
              transformOrigin: "167.13px 307.915px",
            }}
            id='prefix__el4k9gx188kf4'
            className='prefix__animable'
            fill='#407bff'
            d='M181.44 319.91h-23.99l-4.63-23.99h23.99l4.63 23.99z'
          />
          <path
            style={{
              transformOrigin: "142.84px 337.84px",
            }}
            id='prefix__elifqbqlm52j'
            className='prefix__animable'
            fill='#407bff'
            d='M157.15 349.84h-23.99l-4.63-24h24l4.62 24z'
          />
          <path
            style={{
              transformOrigin: "323.21px 337.84px",
            }}
            id='prefix__el2kzcgaln5ca'
            className='prefix__animable'
            fill='#407bff'
            d='M337.52 349.84h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "293.15px 337.84px",
            }}
            id='prefix__elf2589j1h66i'
            className='prefix__animable'
            fill='#407bff'
            d='M307.46 349.84h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "263.09px 337.84px",
            }}
            id='prefix__elg7tke9a226c'
            className='prefix__animable'
            fill='#407bff'
            d='M277.4 349.84h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "233.03px 337.84px",
            }}
            id='prefix__elhdvx5k3c0up'
            className='prefix__animable'
            fill='#407bff'
            d='M247.34 349.84h-23.99l-4.63-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "202.97px 337.84px",
            }}
            id='prefix__el8b8kug07ccq'
            className='prefix__animable'
            fill='#407bff'
            d='M217.28 349.84h-24l-4.62-24h23.99l4.63 24z'
          />
          <path
            style={{
              transformOrigin: "172.91px 337.84px",
            }}
            className='prefix__animable'
            fill='#407bff'
            opacity={0.5}
            d='M187.22 349.84h-24l-4.62-24h23.99l4.63 24z'
            id='prefix__el0akdhvoh65gb'
          />
        </g>
        <path
          d='M115.59 218.08l-.31-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.45 9.27h-1.58c-1.18-6.09.9-10.85 4.72-10.85s7.74 4.76 8.91 10.85-.91 10.85-4.73 10.85z'
          style={{
            transformOrigin: "113.503px 207.23px",
          }}
          id='prefix__el197qj41tat8'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M139.27 218.08l-.31-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.45 9.27h-1.58c-1.18-6.09.9-10.85 4.72-10.85s7.74 4.76 8.91 10.85-.91 10.85-4.73 10.85z'
          style={{
            transformOrigin: "137.183px 207.23px",
          }}
          id='prefix__el6jy9q50lypg'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M163 218.08l-.31-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.42 4.25-3.45 9.27H154c-1.18-6.09.9-10.85 4.72-10.85s7.74 4.76 8.91 10.85-.86 10.85-4.63 10.85z'
          style={{
            transformOrigin: "160.815px 207.23px",
          }}
          id='prefix__elk34l5bcfn4p'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M186.62 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.91-10.85 4.73-10.85s7.73 4.76 8.91 10.85-.92 10.85-4.75 10.85z'
          style={{
            transformOrigin: "184.551px 207.23px",
          }}
          id='prefix__elzgzn31x9ru'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M210.3 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.91-10.85 4.73-10.85s7.73 4.76 8.91 10.85-.92 10.85-4.75 10.85z'
          style={{
            transformOrigin: "208.231px 207.23px",
          }}
          id='prefix__eltjmkgjcimke'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M234 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.9-10.85 4.73-10.85s7.73 4.76 8.91 10.85-.94 10.85-4.75 10.85z'
          style={{
            transformOrigin: "231.93px 207.23px",
          }}
          id='prefix__elq9u5vh9huh'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M257.66 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.9-10.85 4.73-10.85s7.73 4.76 8.91 10.85-.92 10.85-4.75 10.85z'
          style={{
            transformOrigin: "255.591px 207.23px",
          }}
          id='prefix__elyb43blajnp'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M281.34 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.9-10.85 4.73-10.85s7.73 4.76 8.91 10.85-.93 10.85-4.75 10.85z'
          style={{
            transformOrigin: "279.271px 207.23px",
          }}
          id='prefix__elhkxo0q268e'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M305 218.08l-.3-1.59c2.83 0 4.41-4.24 3.44-9.26s-4.18-9.27-7-9.27-4.41 4.25-3.44 9.27h-1.59c-1.17-6.09.9-10.85 4.73-10.85s7.73 4.76 8.9 10.85-.9 10.85-4.74 10.85z'
          style={{
            transformOrigin: "302.925px 207.23px",
          }}
          id='prefix__elx4pyb9z7x3'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          style={{
            transformOrigin: "158.315px 244.695px",
          }}
          id='prefix__elutdvoipe9q'
          className='prefix__animable'
          fill='#407bff'
          d='M202.69 256.69h-84.12l-4.63-23.99h84.12l4.63 23.99z'
        />
        <path
          style={{
            transformOrigin: "224.23px 274.62px",
          }}
          id='prefix__elgg4is33dok'
          className='prefix__animable'
          fill='#407bff'
          d='M238.54 286.62h-24l-4.62-24h23.99l4.63 24z'
        />
        <path
          d='M313.6 317.34l-1.3-1.13a39.6 39.6 0 00-8.83-5.85l.83-2.45a43.18 43.18 0 018.34 5.29 54.83 54.83 0 0117-16.21l1.79 2.44a52.59 52.59 0 00-17.06 16.71z'
          style={{
            transformOrigin: "317.45px 307.165px",
          }}
          id='prefix__elwpl460xppre'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          style={{
            transformOrigin: "224.23px 274.615px",
          }}
          id='prefix__el2xfv15xd7v8'
          className='prefix__animable'
          fill='#fff'
          d='M229.74 266.59h-2.17l-3.63 6.57-6.15-6.57h-2.17l7.52 8.03-4.43 8.02h2.18l3.62-6.56 6.15 6.56h2.18l-7.53-8.02 4.43-8.03z'
        />
        <path
          d='M274.21 324.06l4 20.81h-20.8l-4-20.81h20.81m1.29-1.59h-24l4.63 24h24l-4.62-24z'
          style={{
            transformOrigin: "265.825px 334.47px",
          }}
          id='prefix__el5mj0luvdyxu'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          style={{
            transformOrigin: "184.89px 304.545px",
          }}
          id='prefix__elcl7vd0sn9hg'
          className='prefix__animable'
          fill='#263238'
          d='M214.23 316.54h-54.05l-4.63-23.99h54.05l4.63 23.99z'
        />
        <path
          d='M177 302.12a3.69 3.69 0 01-3.72 4.61 5.85 5.85 0 01-5.5-4.61 3.7 3.7 0 013.72-4.61 5.86 5.86 0 015.5 4.61z'
          style={{
            transformOrigin: "172.392px 302.12px",
          }}
          id='prefix__eldcgbq9gxc4a'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M178.11 306.48a5.32 5.32 0 01-4.48 2.21 8 8 0 01-5.32-2.2 4.8 4.8 0 00-2.44 5.08h16.64a7.71 7.71 0 00-4.4-5.09z'
          style={{
            transformOrigin: "174.151px 309.025px",
          }}
          id='prefix__eldmi89nad9ts'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          style={{
            transformOrigin: "195.765px 301.725px",
          }}
          id='prefix__elisoi2ikmhz'
          className='prefix__animable'
          fill='#fff'
          d='M205.22 303.05h-18.4l-.51-2.65h18.4l.51 2.65z'
        />
        <path
          style={{
            transformOrigin: "193.775px 307.355px",
          }}
          id='prefix__elc7v03iy2cuq'
          className='prefix__animable'
          fill='#fff'
          d='M200.15 308.68h-12.24l-.51-2.65h12.24l.51 2.65z'
        />
        <path
          d='M296.76 234.94c4.74 0 11.45.69 16.52 4a8 8 0 013.71 5c.89 3.93-1.2 8.22-3.51 10.55-3.65 3.68-9.33 6-16 6.46-.76.06-1.54.09-2.31.09a37.52 37.52 0 01-13.7-2.72c-4.29-1.71-8.84-4-10.12-8.46a7.32 7.32 0 01.79-6.2 9.43 9.43 0 012.93-2.86 38.82 38.82 0 0118.36-5.75c1.11-.07 2.23-.11 3.33-.11m-.3-1.54c-1.19 0-2.34 0-3.42.12a40.16 40.16 0 00-19 5.94 10.65 10.65 0 00-3.29 3.22 8.73 8.73 0 00-.91 7.33c1.5 5.18 6.63 7.87 11.29 9.72a39 39 0 0014.33 2.84c.81 0 1.61 0 2.41-.08 6.34-.46 12.66-2.62 16.82-6.82s6.81-13.11-.74-18c-5.09-3.29-11.75-4.27-17.5-4.27z'
          style={{
            transformOrigin: "294.077px 247.985px",
          }}
          id='prefix__elvbdf4gc7fv'
          className='prefix__animable'
          fill='#263238'
        />
      </g>
      <g
        id='prefix__freepik--Character--inject-67'
        className='prefix__animable prefix__animator-active'
        style={{
          transformOrigin: "369.282px 263.766px",
        }}>
        <path
          style={{
            transformOrigin: "388.855px 399.79px",
          }}
          id='prefix__eljzezlgpgp6'
          className='prefix__animable'
          fill='#b55b52'
          d='M387.02 409.16l7.16-1.9-3.49-16.84-7.16 1.91 3.49 16.83z'
        />
        <path
          d='M394.41 405.94l-7.91 2.6a.53.53 0 00-.37.65l1.68 6.39a1.31 1.31 0 001.64.85c2.74-1 4-1.55 7.48-2.69 2.14-.71 7.32-2.3 10.27-3.27s1.92-3.86.58-3.72c-6 .66-9.51.13-12-.77a2.05 2.05 0 00-1.37-.04z'
          style={{
            transformOrigin: "397.607px 411.166px",
          }}
          id='prefix__el6a4dtpuoz0d'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          style={{
            transformOrigin: "388.01px 395.715px",
          }}
          className='prefix__animable'
          opacity={0.2}
          d='M383.53 392.33l7.16-1.91 1.8 8.68-7.17 1.91-1.79-8.68z'
          id='prefix__elerr6u9v544f'
        />
        <path
          d='M365.17 220.72s10 60.35 13 81.4c3.25 23.05 15.42 92.39 15.42 92.39l-12 4.54s-19.15-68.17-24.44-90.58c-5.75-24.33-18.41-87.75-18.41-87.75z'
          style={{
            transformOrigin: "366.165px 309.885px",
          }}
          id='prefix__elofplohr0u8e'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M349.1 245.47c5.12 12.79 5.7 34.53 3.62 43.44-3.14-14.63-6.74-32.13-9.51-45.74.99-3.49 2.79-5.48 5.89 2.3z'
          style={{
            transformOrigin: "348.528px 264.501px",
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elwpabb2r5nal'
        />
        <path
          style={{
            transformOrigin: "386.49px 395.81px",
          }}
          id='prefix__eljn6b9cydl9i'
          className='prefix__animable'
          fill='#263238'
          d='M381.05 400.75l13.93-4.18-.4-5.7-16.58 4.85 3.05 5.03z'
        />
        <path
          d='M397.31 406.35a9.51 9.51 0 01-2 .36.19.19 0 01-.17-.08.19.19 0 010-.19c.17-.23 1.72-2.25 2.69-2.2a.66.66 0 01.53.34.94.94 0 01.09 1 2 2 0 01-1.14.77zm-1.59 0c1.35-.17 2.26-.51 2.48-.94a.64.64 0 00-.07-.63.32.32 0 00-.26-.17c-.52-.05-1.5.93-2.15 1.72z'
          style={{
            transformOrigin: "396.834px 405.475px",
          }}
          id='prefix__elxwys8gpy0wh'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          d='M395.39 406.7a.11.11 0 01-.08 0 .17.17 0 01-.13-.1c0-.09-.86-2.06-.38-3a.91.91 0 01.62-.46.66.66 0 01.76.31c.42.71-.15 2.53-.71 3.17-.03.06-.05.08-.08.08zm0-3.17a.55.55 0 00-.3.26 3.84 3.84 0 00.27 2.39 3.45 3.45 0 00.5-2.53c-.05-.09-.14-.19-.39-.14z'
          style={{
            transformOrigin: "395.49px 404.911px",
          }}
          id='prefix__el5oybnfpcfti'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          d='M363.65 158.59c.29.47.69 1.13 1.06 1.7l1.14 1.8c.79 1.19 1.57 2.38 2.4 3.55 1.63 2.33 3.35 4.59 5.13 6.74a60 60 0 005.62 5.93 30.19 30.19 0 006 4.36l-1.58-.45a16.29 16.29 0 004.71.27c.86-.08 1.74-.19 2.64-.35s1.81-.38 2.73-.63a48.13 48.13 0 005.57-1.89c.93-.38 1.86-.77 2.79-1.19s1.88-.89 2.69-1.27l.27-.13a3.25 3.25 0 014.33 1.52 3.28 3.28 0 01-.59 3.65 40.585 40.585 0 01-5.36 4.67 43.65 43.65 0 01-6.12 3.74 33.54 33.54 0 01-7.09 2.61 31.31 31.31 0 01-4 .65 25.18 25.18 0 01-4.25 0 4.18 4.18 0 01-1.17-.29l-.4-.17a38.38 38.38 0 01-9.17-5.61 60.64 60.64 0 01-7.33-7 85.18 85.18 0 01-6.14-7.64q-1.41-2-2.72-4c-.43-.69-.86-1.37-1.28-2.08s-.8-1.37-1.26-2.23a6.49 6.49 0 0111.29-6.42z'
          style={{
            transformOrigin: "380.442px 174.555px",
          }}
          id='prefix__el7voq1rkn7u5'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M369.81 176.88c-3.9-8-10.55-7.54-13.23-5.07l.76 1.11a85.27 85.27 0 005.86 7.4 66.23 66.23 0 006.92 6.77c.78.64 1.59 1.27 2.41 1.87a32 32 0 00-2.72-12.08z'
          style={{
            transformOrigin: "364.555px 179.589px",
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__el3r4zjlcj8a4'
        />
        <path
          d='M406.77 176l5-4.17 3 6.47s-7.45 5.33-9.16 3.45l-.2-2.41a3.93 3.93 0 011.36-3.34z'
          style={{
            transformOrigin: "410.082px 176.991px",
          }}
          id='prefix__els7zqvzn1dp'
          className='prefix__animable'
          fill='#b55b52'
        />
        <path
          style={{
            transformOrigin: "415.88px 175.035px",
          }}
          id='prefix__elune22e8m69b'
          className='prefix__animable'
          fill='#b55b52'
          d='M418.44 172.62l1.57 5-5.28.65-2.98-6.47 6.69.82z'
        />
        <path
          style={{
            transformOrigin: "328.565px 400.335px",
          }}
          id='prefix__elv4r5livw15n'
          className='prefix__animable'
          fill='#b55b52'
          d='M324.38 408.92h7.41l.96-17.17h-7.41l-.96 17.17z'
        />
        <path
          d='M332.18 408.06h-8.33a.53.53 0 00-.55.51l-.41 6.59a1.32 1.32 0 001.3 1.32c2.9-.05 4.29-.22 7.95-.22 2.25 0 8.46.23 11.57.23s3-3.08 1.71-3.36c-5.92-1.24-9.87-3-12-4.61a2 2 0 00-1.24-.46z'
          style={{
            transformOrigin: "334.581px 412.275px",
          }}
          id='prefix__elk7x8xob5imm'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          style={{
            transformOrigin: "328.795px 396.185px",
          }}
          className='prefix__animable'
          opacity={0.2}
          d='M325.34 391.76h7.41l-.5 8.85h-7.41l.5-8.85z'
          id='prefix__elp2y68q25l4g'
        />
        <path
          d='M350.69 220.72s-.64 64.41-1.84 85.64c-1.32 23.24-13.42 92.11-13.42 92.11h-13.58s5.92-67 5.1-90c-.89-25-1.43-87.75-1.43-87.75z'
          style={{
            transformOrigin: "336.27px 309.595px",
          }}
          id='prefix__elkxk88gpjw39'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          style={{
            transformOrigin: "329.095px 395.895px",
          }}
          id='prefix__elflvhyqxob0p'
          className='prefix__animable'
          fill='#263238'
          d='M320.76 398.47h16.56l.81-5.15-18.07.8.7 4.35z'
        />
        <path
          d='M334.8 409.35a9.5 9.5 0 01-2-.26.17.17 0 01-.06-.31c.24-.17 2.33-1.6 3.24-1.24a.64.64 0 01.4.48 1 1 0 01-.21.94 2 2 0 01-1.37.39zm-1.5-.51c1.33.26 2.31.22 2.65-.12a.62.62 0 00.13-.62.3.3 0 00-.2-.24c-.48-.19-1.72.43-2.58.98z'
          style={{
            transformOrigin: "334.541px 408.42px",
          }}
          id='prefix__elnb6m4t6j4ai'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          d='M332.87 409.09a.11.11 0 01-.08 0 .16.16 0 01-.09-.14c0-.09-.18-2.22.57-2.94a.89.89 0 01.73-.24.67.67 0 01.63.52c.17.81-.94 2.36-1.67 2.8a.15.15 0 01-.09 0zm1-3a.53.53 0 00-.37.15 3.82 3.82 0 00-.49 2.35c.65-.54 1.38-1.72 1.27-2.25 0-.1-.08-.22-.33-.25z'
          style={{
            transformOrigin: "333.664px 407.43px",
          }}
          id='prefix__ellr1ngpildd'
          className='prefix__animable'
          fill='#407bff'
        />
        <path
          d='M362.05 160.49a7.21 7.21 0 00-5.47-5 45 45 0 00-5.55-.95 147.89 147.89 0 00-16.85 0c-2.94.14-5.94.5-8.33.85h-.09a4.73 4.73 0 00-4 5.5 232.84 232.84 0 013.69 30.11c.58 14.67-.21 26.19.07 29.73h39.65c2.41-35.81-.7-52.08-3.12-60.24z'
          style={{
            transformOrigin: "343.864px 187.515px",
          }}
          id='prefix__el6km6vs6yb26'
          className='prefix__animable'
          fill='#e6e6e6'
        />
        <path
          d='M335.3 153.88c3.12 12.66 6 44.74 5.95 69.82h-16.76c-.3-3.74.54-15.88-.07-31.35a247.72 247.72 0 00-3.85-31.69 5 5 0 014.18-5.8h.09c2.52-.37 5.68-.75 8.78-.9z'
          style={{
            transformOrigin: "330.873px 188.79px",
          }}
          id='prefix__elvon268q1vg'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M364.09 160.67a8.39 8.39 0 00-6-5.67 74.78 74.78 0 00-8-.82c3.17 11 9.39 44.51 7.54 69.52h10.58c2.51-37.53-1.76-56.17-4.12-63.03z'
          style={{
            transformOrigin: "359.533px 188.94px",
          }}
          id='prefix__eldt7mxalrlzu'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          style={{
            transformOrigin: "381.705px 201.2px",
          }}
          id='prefix__eli6ro3ftzbua'
          className='prefix__animable'
          fill='#407bff'
          d='M425.89 195.34L347 229.1l-9.48-22.04 78.9-33.76 9.47 22.04z'
        />
        <path
          d='M359.31 208.15a4.61 4.61 0 11-6.06-2.42 4.61 4.61 0 016.06 2.42z'
          style={{
            transformOrigin: "355.072px 209.965px",
          }}
          id='prefix__el81ldoex5zg'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M361.3 212.05a6.56 6.56 0 01-9 3.86 6 6 0 00-1.14 6l15.3-6.54a6 6 0 00-5.16-3.32z'
          style={{
            transformOrigin: "358.632px 216.98px",
          }}
          id='prefix__ele9sllmhg00a'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          style={{
            transformOrigin: "391.095px 194.125px",
          }}
          id='prefix__ellh9019v866k'
          className='prefix__animable'
          fill='#fff'
          d='M414.67 185.48l-46.11 19.73-1.04-2.44 46.11-19.73 1.04 2.44z'
        />
        <path
          style={{
            transformOrigin: "390.49px 200.505px",
          }}
          id='prefix__elvwt89wwof4a'
          className='prefix__animable'
          fill='#fff'
          d='M411.24 193.07l-40.45 17.31-1.05-2.44 40.45-17.31 1.05 2.44z'
        />
        <path
          d='M329.67 173.5c-.28-8.41-6.49-11.62-8.24-9.36a2.43 2.43 0 00-.21.29c1.09 6.48 2.42 15.4 3 24.19 2.95-1.7 5.71-7.13 5.45-15.12z'
          style={{
            transformOrigin: "325.454px 176.029px",
          }}
          className='prefix__animable'
          opacity={0.3}
          id='prefix__elcq3rg6kloou'
        />
        <path
          d='M350.23 154.52c-4.9-1.16-5.76-4.19-5.43-7.52a19.92 19.92 0 01.39-2.48l-6.14-5.1-4.51-3.74c1.25 5.43 2.66 15.32-1 18.94 0 0 3 5 11.43 4.56 7.03-.39 5.26-4.66 5.26-4.66z'
          style={{
            transformOrigin: "341.984px 147.444px",
          }}
          id='prefix__eljhq8z5kokc9'
          className='prefix__animable'
          fill='#b55b52'
        />
        <path
          d='M341.6 163.65a11.89 11.89 0 012.76-4.44 25.48 25.48 0 01-10-6.12l-2.57 1s.91 6.53 9.81 9.56z'
          style={{
            transformOrigin: "338.075px 158.37px",
          }}
          id='prefix__elzzf0s9a5evp'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M348.46 162.53a12.41 12.41 0 00-4.1-3.32c2.47-.7 3.43-5.71 3.43-5.71l2.41.5s4.45 6.53-1.74 8.53z'
          style={{
            transformOrigin: "348.054px 158.015px",
          }}
          id='prefix__elrjwkmyxbxmi'
          className='prefix__animable'
          fill='#fff'
        />
        <path
          d='M345.19 144.52a19.92 19.92 0 00-.39 2.48c-2.29-.46-5.35-2.9-5.67-5.24a10.66 10.66 0 01-.08-2.34z'
          style={{
            transformOrigin: "342.105px 143.21px",
          }}
          className='prefix__animable'
          opacity={0.2}
          id='prefix__el10ywxjg1tcas'
        />
        <path
          d='M348.05 117.85c3.09-.56 6.69 2.68 5 9.26s-9.72-8.41-5-9.26z'
          style={{
            transformOrigin: "350.033px 123.281px",
          }}
          id='prefix__el1l533okjp6x'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M332.68 127.87c1.57 7.21 2.12 11.5 6.38 14.79a9.59 9.59 0 0015.51-6.91c.56-6.71-2.09-17.37-9.68-19.41a9.7 9.7 0 00-12.21 11.53z'
          style={{
            transformOrigin: "343.531px 130.328px",
          }}
          id='prefix__elukaqi4ye3ej'
          className='prefix__animable'
          fill='#b55b52'
        />
        <path
          d='M335.58 135.68c1.06-2.5 2-9.46 1.14-12.35 3.45-.11 10.7-.75 13.72-4.11 4.64-5.16-1.48-11.26-9.18-6.4-5 3.13-15.79 2.51-14 6-5.26 5.79.65 12.33 8.32 16.86z'
          style={{
            transformOrigin: "338.652px 123.361px",
          }}
          id='prefix__elnri30af3958'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M327.25 119.08a.25.25 0 01-.19-.09c-.64-.79-.84-1.45-.62-2 .53-1.32 3.28-1.68 6.45-2.08 3-.38 6.32-.81 7.38-2.16a.26.26 0 01.35 0 .24.24 0 010 .35c-1.19 1.51-4.5 1.93-7.7 2.34-2.77.36-5.63.72-6 1.78-.15.38 0 .89.53 1.52a.25.25 0 010 .35.3.3 0 01-.2-.01z'
          style={{
            transformOrigin: "333.525px 115.892px",
          }}
          id='prefix__eleh9rkpk7akl'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M331.57 133.69a6.92 6.92 0 003.68 2.85c1.93.58 2.59-1.2 1.81-3.08-.71-1.7-2.57-4.05-4.38-3.8s-2.2 2.34-1.11 4.03z'
          style={{
            transformOrigin: "334.173px 133.147px",
          }}
          id='prefix__elbefs9uy08bf'
          className='prefix__animable'
          fill='#b55b52'
        />
        <path
          d='M343 128.59c.09.59.48 1 .87 1s.62-.56.53-1.15-.48-1-.87-1-.62.56-.53 1.15z'
          style={{
            transformOrigin: "343.7px 128.515px",
          }}
          id='prefix__el30qgyv8bkob'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M349.71 127.79c.09.59.48 1 .87 1s.63-.57.53-1.16-.48-1-.86-1-.63.57-.54 1.16z'
          style={{
            transformOrigin: "350.412px 127.71px",
          }}
          id='prefix__elt9jipi9txqh'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M348.68 128.34a22.46 22.46 0 003.62 4.72 3.4 3.4 0 01-2.76.86z'
          style={{
            transformOrigin: "350.49px 131.146px",
          }}
          id='prefix__els89hifn8yo'
          className='prefix__animable'
          fill='#a02724'
        />
        <path
          d='M347.36 135.81a5.12 5.12 0 01-3.73-1.54.17.17 0 11.26-.23 5 5 0 004.12 1.38.17.17 0 01.2.15.17.17 0 01-.15.19 5.76 5.76 0 01-.7.05z'
          style={{
            transformOrigin: "345.899px 134.896px",
          }}
          id='prefix__el0qszel2vlyns'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M340.81 126.68a.41.41 0 01-.17-.05.35.35 0 01-.13-.48 3.36 3.36 0 012.62-1.77.35.35 0 01.37.33.35.35 0 01-.33.37 2.66 2.66 0 00-2.05 1.42.35.35 0 01-.31.18z'
          style={{
            transformOrigin: "341.982px 125.53px",
          }}
          id='prefix__el8gx73ojiu4'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M352.72 125.15a.34.34 0 01-.29-.16 2.94 2.94 0 00-2.22-1.34.35.35 0 110-.7 3.64 3.64 0 012.8 1.65.36.36 0 01-.1.49.35.35 0 01-.19.06z'
          style={{
            transformOrigin: "351.463px 124.05px",
          }}
          id='prefix__el2obyuf6c067'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M330.58 165.75c.05-.06 0 0 0 0l-.07.11-.17.28-.36.63c-.25.45-.49.91-.73 1.39-.46 1-.91 2-1.31 3a43.43 43.43 0 00-1.9 6.44 48.2 48.2 0 00-.81 13.77 18.45 18.45 0 00.39 2.88 15.61 15.61 0 00.95 2.79 32.81 32.81 0 003.39 5.82 82.72 82.72 0 009.76 11l.14.14a3.24 3.24 0 01-3.73 5.21 52.56 52.56 0 01-7.18-4.43 55 55 0 01-6.51-5.52 37.5 37.5 0 01-5.6-7.11 24.78 24.78 0 01-2.15-4.45 26.82 26.82 0 01-1.21-4.83 51.17 51.17 0 01-.8-8.77 49.23 49.23 0 01.67-8.89 47.2 47.2 0 012.33-8.74 41.39 41.39 0 011.85-4.23c.36-.7.73-1.39 1.15-2.08.21-.35.43-.7.66-1.06l.37-.54.5-.69a6.5 6.5 0 0110.37 7.83z'
          style={{
            transformOrigin: "326.758px 187.503px",
          }}
          id='prefix__elslwqfc39op'
          className='prefix__animable'
          fill='#263238'
        />
        <path
          d='M341.84 213.94l4.13-.22-4.79 8s-6.68-.85-6-4.26l.93-1a8.52 8.52 0 015.73-2.52z'
          style={{
            transformOrigin: "340.551px 217.72px",
          }}
          id='prefix__el9cbgbqqgd38'
          className='prefix__animable'
          fill='#b55b52'
        />
        <path
          style={{
            transformOrigin: "345.715px 219.405px",
          }}
          id='prefix__el01tyl2rv3dpl'
          className='prefix__animable'
          fill='#b55b52'
          d='M350.25 216.59l-3.6 8.5-5.47-3.34 4.79-8.03 4.28 2.87z'
        />
      </g>
      <defs>
        <filter id='prefix__active' height='200%'>
          <feMorphology
            in='SourceAlpha'
            result='DILATED'
            operator='dilate'
            radius={2}
          />
          <feFlood floodColor='#32DFEC' floodOpacity={1} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
        <filter id='prefix__hover' height='200%'>
          <feMorphology
            in='SourceAlpha'
            result='DILATED'
            operator='dilate'
            radius={2}
          />
          <feFlood floodColor='red' floodOpacity={0.5} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
          <feColorMatrix values='0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0' />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgComponent;
