import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";
import { Avatar, TableHead } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
import Card from "../../Card";
import ShowMore from "react-show-more";
const MoreWrapper = styled.strong`
  color: ${(props) => props.theme.palette.primary.main};
`;

const Consultant = styled.div`
  border: 1px solid #eaecf0;
  background-color: #fff;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
  overflow: hidden;

  .head {
    background: #f9fafb;
    padding: 13px 15px;

    h2 {
      color: #667085;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin: 0;
    }
  }
  .content {
    padding: 24px 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .name {
    color: #1d2939;
    margin-top: 15px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
  }

  .role {
    color: #667085;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
  .social-items {
    margin-top: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    .item {
      padding: 8px;
      border: 2px solid #eaecf0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      transition: 0.2s ease-in;
      &:hover {
        background-color: #fafafa;
      }
    }
  }
`;
const PageWrapper = styled(Paper)`
  margin-bottom: 15px;
  border: none !important;
  background-color: transparent !important;
`;
const SubscriptionWrapper = styled.div`
  border: 1px solid #eaecf0;
  background-color: #fff;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
  overflow: hidden;
  .MuiTableRow-root {
    th {
      &.MuiTableCell-root {
        color: #101828;
        font-weight: 500;
      }
    }
    td {
      color: #667085;
    }
    &:last-child {
      .MuiTableCell-root {
        border-bottom: 0;
      }
    }
  }
  .MuiTableHead-root {
    .MuiTableCell-root {
      background: #f9fafb;
      padding: 13px 15px;
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;
      h2 {
        margin: 0;
        color: #667085;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .icon-wrapper {
    padding: 8px;
    border: 2px solid #eaecf0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: 0.2s ease-in;
    width: max-content;
    margin-left: auto;
    &:hover {
      background-color: #fafafa;
    }
  }
`;

function Services({ subscription, consultant, company, activeSourcing }) {
  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <SubscriptionWrapper>
            <Table>
              <TableHead>
                <TableCell>
                  <h2>Ihr Reichweitenpaket</h2>
                </TableCell>
                <TableCell />
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Abo Beginn
                  </TableCell>
                  <TableCell align="right">
                    {subscription?.startDate ? (
                      <Moment>{subscription?.startDate}</Moment>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Gebuchte Stellen
                  </TableCell>
                  <TableCell align="right">
                    {subscription?.jobsNumber || "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Verfügbare Stellen
                  </TableCell>
                  <TableCell align="right">
                    {subscription?.availableJobs}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Active Sourcing
                  </TableCell>
                  <TableCell align="right">
                    {subscription?.activeServices?.ad ? (
                      <>{activeSourcing || 0}</>
                    ) : activeSourcing > 0 ? (
                      activeSourcing
                    ) : (
                      <span>Nein</span>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    All in One
                  </TableCell>
                  <TableCell align="right">
                    {company?.allInOne || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Letzte Abrechnung
                  </TableCell>
                  <TableCell align="right">
                    <a
                      href={
                        subscription?.url?.includes("https://")
                          ? subscription?.url
                          : `https://${subscription?.url}`
                      }
                      className="icon-wrapper"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadIcon />
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </SubscriptionWrapper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <Consultant>
                <div className="head">
                  <h2>Ihr Kundenberater</h2>
                </div>
                <div className="content">
                  <Avatar
                    src={
                      consultant?.image && consultant?.image._id
                        ? consultant.image.md.url
                        : "/images/no-user.jpg"
                    }
                    style={{
                      width: 120,
                      height: 120,
                      border: "1px solid #eaecf0",
                    }}
                  />
                  <h3 className="name">{consultant?.name || "-"}</h3>
                  <p className="role">{consultant?.role || "-"}</p>
                  <div className="social-items">
                    {consultant?.phone && (
                      <Link to={`tel:${consultant?.phone}`} className="item">
                        <PhoneIcon />
                      </Link>
                    )}
                    {consultant?.email && (
                      <Link to={`mailto:${consultant?.email}`} className="item">
                        <MailIcon />
                      </Link>
                    )}
                  </div>
                </div>
              </Consultant>
            </Grid>
          </Grid>
        </Grid>
        {company?.blackListNotes && (
          <Grid item xs={12} md={6}>
            <Card title="Schwarze Liste Noticen" defaultOpen={true}>
              <div
                style={{ padding: 15 }}
                dangerouslySetInnerHTML={{ __html: company?.blackListNotes }}
              ></div>
            </Card>
          </Grid>
        )}
        {subscription && subscription.subscriptionNotes && (
          <Grid item xs={12} md={6}>
            <Card
              title="Notizen zum Abo (durch Berater auszufüllen)"
              defaultOpen={true}
            >
              <div style={{ padding: 15 }}>
                <ShowMore
                  lines={1}
                  more={<MoreWrapper>Show more</MoreWrapper>}
                  less={<MoreWrapper>Show less</MoreWrapper>}
                  anchorClass="my-anchor-class"
                  width={100}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subscription.subscriptionNotes,
                    }}
                  />
                </ShowMore>
              </div>
            </Card>
          </Grid>
        )}
        {subscription && subscription.companyNotes && (
          <Grid item xs={12} md={6}>
            <Card title="Notizen" defaultOpen={true}>
              <div style={{ padding: 15 }}>
                <ShowMore
                  lines={1}
                  more={<MoreWrapper>Show more</MoreWrapper>}
                  less={<MoreWrapper>Show less</MoreWrapper>}
                  anchorClass="my-anchor-class"
                  width={100}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subscription.companyNotes,
                    }}
                  />
                </ShowMore>
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    </PageWrapper>
  );
}

export default Services;
