import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Card from "../Card";
import moment from "moment";
import { useSelector } from "react-redux";
import { V3 } from "../../lib/api";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import usePrice from "../../hooks/usePrice";
import ChartTooltip from "../Chart/Tooltip";
import MonthlyTooltip from "../Chart/MonthlyTooltip";
import Loader from "../Loader";

const CheckIsWithCard = ({ withCard = true, children }) => {
  if (withCard) {
    return (
      <Card title="Verkauf" style={{ height: "100%" }}>
        {children}
      </Card>
    );
  } else {
    return <>{children}</>;
  }
};

function Sales({ user, withCard = true, start, end }) {
  const [data, setData] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(false);
  const { startDate, endDate } = useSelector((state) => state.team);

  function formatXAxis(tickItem) {
    return moment(tickItem).format("D.MM.YYYY");
  }

  const fetchData = () => {
    if (user && moment(startDate).isValid() && moment(endDate).isValid()) {
      setLoading(true);
      V3.get(
        `/teams/${user}/analytics?start=${moment(
          start ? start : startDate
        ).format("DD/MM/YYYY")}&end=${moment(end ? end : endDate).format(
          "DD/MM/YYYY"
        )}&currentStart=${moment(new Date())
          .startOf("month")
          .format("DD/MM/YYYY")}&currentEnd=${moment(new Date())
          .endOf("month")
          .format("DD/MM/YYYY")}`
      ).then((res) => {
        setAnalytics(res.data.data);
        if (res.data.data?.sales) {
          setData(res.data.data?.sales);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, startDate, endDate, start, end]);

  function count(array, key) {
    return array.reduce(function (r, a) {
      return r + a[key];
    }, 0);
  }

  const calculatePercentage = (total, amount) => {
    if (total && amount) {
      const tmp = amount * 100;
      return tmp / total;
    } else {
      return 0;
    }
  };

  const totalAmount = count(data, "amount");
  let averageTotal = 0;
  if (totalAmount && data && data.length) {
    averageTotal = totalAmount / data.length;
  }

  const completedPercentege = calculatePercentage(
    analytics?.clients,
    analytics?.completedClients
  ).toFixed(2);

  function groupByMonthYear(response) {
    // output
    const groupedData = [];

    // Using map for lookup to avoid iterating again on the grouped data
    const referenceMap = new Map();

    // destructing month, year and id from the response
    for (const { _id, amount, date } of response) {
      const groupKey = moment(date).format("MM/YYYY");

      // check if the month and year reference is already seen using the groupKey MMYYYY
      if (referenceMap.has(groupKey)) {
        referenceMap.get(groupKey)._id.push(_id);
        referenceMap.get(groupKey).amount += amount;
        // early return
        continue;
      }

      // simply add a new entry if it doesn't exist
      const data = {
        month: moment(date).format("MM"),
        year: moment(date).format("YYYY"),
        date: moment(date).format("MM/YYYY"),
        endDate: moment(date).endOf("month").format(),
        _id: [_id],
        amount,
      };

      groupedData.push(data);
      referenceMap.set(groupKey, data);
    }

    return groupedData;
  }

  const sales = groupByMonthYear(data);

  sales.sort((a, b) => {
    return moment(a.endDate).isBefore(moment(b.endDate)) ? -1 : 1;
  });

  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <CheckIsWithCard withCard={withCard}>
          <Loader loading={loading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {data.length > 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: 300,
                      marginLeft: -15,
                      marginBottom: -15,
                      padding: 15,
                    }}
                  >
                    <ResponsiveContainer>
                      <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatXAxis} />
                        <YAxis />
                        <Tooltip content={<ChartTooltip />} />
                        <Line
                          type="monotone"
                          dataKey="amount"
                          stroke="#03fc41"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: 15,
                      height: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: -15,
                    }}
                  >
                    Keine Daten für diesen Zeitraum
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                {sales.length > 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: 300,
                      marginLeft: -15,
                      marginBottom: -15,
                      padding: 15,
                    }}
                  >
                    <ResponsiveContainer>
                      <LineChart data={sales}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          // tickFormatter={formatSaleXAxis}
                        />
                        <YAxis />
                        <Tooltip content={<MonthlyTooltip />} />
                        <Line
                          type="monotone"
                          dataKey="amount"
                          stroke="#03fc41"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: 15,
                      height: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: -15,
                    }}
                  >
                    Keine Daten für diesen Zeitraum
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    borderLeft: "1px solid rgba(241, 241, 241, 0.2)",
                    height: "100%",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Betrag of sales done
                        </TableCell>
                        <TableCell align="right">
                          {usePrice(totalAmount.toFixed(0))} CHF
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Unternehmen mit Status "Erfolgreich abgeschlossen"
                        </TableCell>
                        <TableCell align="right">
                          {analytics?.completedCompanies || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: 40 }}
                        >
                          Davon mit einem vereinbarten "Update Call"
                        </TableCell>
                        <TableCell align="right" style={{ paddingRight: 40 }}>
                          {analytics?.updateCallCompanies || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: 40 }}
                        >
                          Davon mit "Mitarbeiter Aktivierung" aktiv
                        </TableCell>
                        <TableCell align="right" style={{ paddingRight: 40 }}>
                          {analytics?.activeSourcingCompanies || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Verkauf pro Abschluss (ink. Verlängerungen)
                        </TableCell>
                        <TableCell align="right">
                          {usePrice(averageTotal.toFixed(0))} CHF
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div
                  style={{
                    borderTop: "1px solid rgba(241, 241, 241, 0.2)",
                    height: "100%",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Kundenbesuche
                        </TableCell>
                        <TableCell align="right">
                          {analytics?.clients || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Gewonnene Kunden
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {analytics?.completedClients || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Verlängerungen
                        </TableCell>
                        <TableCell align="right">
                          {analytics?.extended || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Abschlussquote total
                        </TableCell>
                        <TableCell align="right">
                          {completedPercentege}%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Verlängert
                        </TableCell>
                        <TableCell align="right">
                          {calculatePercentage(
                            analytics?.completedClients,
                            analytics?.extended
                          ).toFixed(2)}
                          %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </Loader>
        </CheckIsWithCard>
      </Grid>
    </React.Fragment>
  );
}
export default Sales;
