import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useEffect } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import dayjs from "../../../utils/DayJs";
import { V3 } from "../../../lib/api";
import { Alert } from "@material-ui/lab";
import Loader from "../../GlobalLoader";
import { useConfirm } from "material-ui-confirm";

function EditUserTimeReport({ data, handleUpdateData }) {
  const [open, setOpen] = useState(false);
  const [timeReports, setTimeReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const confirm = useConfirm();
  useEffect(() => {
    setTimeReports(data?.time || []);
  }, [data]);

  const handleUpdate = async () => {
    setLoading(true);
    setMessage(null);
    try {
      const response = await V3.put(`time-reports/${data._id}/time`, {
        time: timeReports,
      });

      handleUpdateData(response.data.data);
      setOpen(false);
      confirm({
        title: `Time Report Updated`,
        description: `Time Report has been updated but need to be verified`,
      });
    } catch (error) {
      setLoading(false);
      setMessage(error?.response?.data?.message);
    }
  };
  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditIcon />
      </span>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update time Report</DialogTitle>

        <DialogContent>
          {message && <Alert severity="error">{message}</Alert>}
          {loading && <Loader />}

          {timeReports.map((el, index) => (
            <div style={{ display: "flex", alignItems: "center" }} key={index}>
              <KeyboardDateTimePicker
                margin="normal"
                id="time-picker"
                label="Start"
                format="dd/MM/yyyy HH:mm"
                key={index}
                value={dayjs(el?.start)}
                onChange={(e) => {
                  const tmp = [...timeReports];
                  tmp[index].start = dayjs(e);
                  setTimeReports(tmp);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
              <span style={{ fontSize: 24, margin: 15 }}>-</span>
              <KeyboardDateTimePicker
                margin="normal"
                id="time-picker"
                format="dd/MM/yyyy HH:mm"
                label="End"
                value={el?.end ? dayjs(el?.end) : null}
                onChange={(e) => {
                  const tmp = [...timeReports];
                  tmp[index].end = dayjs(e);
                  setTimeReports(tmp);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </div>
          ))}
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="primary"
            >
              close
            </Button>
            <Button
              onClick={() => handleUpdate()}
              variant="contained"
              color="primary"
            >
              update
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditUserTimeReport;
