import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#DA1833" rx={20} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29 17v-6m0 0h-6m6 0-8 8m-3-6h-2.2c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C11 15.28 11 16.12 11 17.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C13.28 29 14.12 29 15.8 29h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C27 26.72 27 25.88 27 24.2V22"
    />
  </svg>
);
export default SvgComponent;
