import React from "react";
import Grid from "@material-ui/core/Grid";
import SharedLinksTable from "../../components/Recruiter/Analytics/SharedLinksTable";

export default function AnalyticSharedLink() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SharedLinksTable />
      </Grid>
    </Grid>
  );
}
