import {
  GET_CANDIDATES,
  GET_CANDIDATE,
  ADD_CANDIDATE,
  DELETE_CANDIDATE,
  CANDIDATES_LOADING,
} from "../types";

const initialState = {
  candidates: [],
  data: [],
  candidate: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CANDIDATES:
      return {
        ...state,
        candidates: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_CANDIDATE:
      return {
        ...state,
        candidate: action.payload.data,
        loading: false,
      };
    case DELETE_CANDIDATE:
      return {
        ...state,
        candidates: state.candidates.filter(
          (candidate) => candidate._id !== action.payload
        ),
      };
    case ADD_CANDIDATE:
      return {
        ...state,
        candidates: [action.payload, ...state.candidates],
      };
    case CANDIDATES_LOADING:
      return {
        ...state,
        candidate: null,
        loading: true,
      };
    default:
      return state;
  }
}
