import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { useDispatch } from "react-redux";
import { SET_INTERESTED } from "../../../redux/types";
import { Alert } from "@material-ui/lab";

function New({ data }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const handleSubmit = (values, formApi) => {
    V3.put(`jobs/${data._id}/interested`, values)
      .then((res) => {
        dispatch({ type: SET_INTERESTED, payload: res.data?.data?.interested });
        setOpen(false);
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        formApi.setSubmitting(false);
        setMessage(err?.response?.data?.message);
      });
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Add
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Interessenten ohne Tel</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            linkedinProfile: Yup.string().nullable(),
            name: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{ name: null, linkedinProfile: null }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}

                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="name"
                  style={{ margin: 0, marginBottom: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Linkedin Profile"
                  name="linkedinProfile"
                  style={{ margin: 0, marginBottom: 15 }}
                />

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    close
                  </Button>
                  <Button variant="outlined" color="primary" type="submit">
                    submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default New;
