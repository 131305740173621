import React, { useState, useEffect } from "react";
import { Button, CardActionArea, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import styled from "styled-components";
import { upload } from "../../utils/AWS";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import Loader from "../../components/Loader";

const VideoWrapper = styled.div`
  .video-react-video {
    max-height: 300px;
    max-width: 300px;
  }
  video {
    width: 100%;
    max-height: 500px;
    max-width: 600px;
  }
`;

const FileUploadWrapper = styled.div`
  .dropzone {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: #fff; */
    cursor: pointer;
    box-sizing: border-box;

    .button {
      margin-top: 20px;
    }

    svg {
      width: 60px;
      height: 60px;
    }
  }
`;

function UploadImage({ video2, onChange }) {
  const [videoPreview, setVideoPreview] = useState(video2);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVideoPreview(video2);
  }, [video2]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length) {
      setLoading(true);
      const data = await upload(acceptedFiles[0]);
      setVideoPreview(data);
      onChange(data);
      setLoading(false);
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Card title="Upload Video" style={{ marginTop: 15 }}>
      <div style={{ padding: 15 }}>
        <Grid container spacing={2}>
          {videoPreview && (
            <Grid item xs={12} lg={4}>
              <VideoWrapper>
                {/* <Player fluid={false} src={videoPreview} /> */}
                <video controls={true} src={videoPreview} />
              </VideoWrapper>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  setVideoPreview(null);
                  onChange(null);
                }}
              >
                remove
              </Button>
            </Grid>
          )}

          <Grid item xs={12} lg={videoPreview ? 8 : 12}>
            <Card variant="outlined">
              <Loader loading={loading}>
                <CardActionArea>
                  <FileUploadWrapper>
                    <div
                      {...getRootProps({
                        className: `dropzone ${isDragActive ? "active" : ""}`,
                      })}
                    >
                      <input {...getInputProps()} />

                      <FaCloudUploadAlt></FaCloudUploadAlt>

                      {isDragActive ? (
                        <p style={{ textAlign: "center" }}>
                          Legen Sie die Dateien hier ab ...
                        </p>
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          Ziehen Sie einige Dateien per Drag 'n' Drop hierher
                          oder klicken Sie, um Dateien auszuwählen.
                        </p>
                      )}

                      <span className="button outline">Dokumente suchen</span>
                    </div>
                  </FileUploadWrapper>
                </CardActionArea>
              </Loader>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}

export default UploadImage;
