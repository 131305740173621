import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
  max-width: 400px;
  margin: 0 auto;
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    max-width: 60px;
  }
`;

function EmptyJobs({ title, style }) {
  return (
    <Wrapper style={style}>
      <img src="/images/emptyTable.png" alt="" />
      <h2>{title}</h2>
    </Wrapper>
  );
}

export default EmptyJobs;
