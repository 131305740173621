import React from "react";
import { useDispatch } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
import { V3 } from "../../lib/api";
import { DESTROY_SESSION, LOGOUT_SUCCESS } from "../../redux/types";
const LogOut = () => {
  const dispatch = useDispatch();

  const handleLogOut = () => {
    // User loading
    V3.delete(`/auth/logout`).then(() => {
      dispatch({ type: DESTROY_SESSION });
      dispatch({ type: LOGOUT_SUCCESS });
    });
  };

  return (
    <ListItem component={Link} button to="/" onClick={() => handleLogOut()}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItem>
  );
};

export default LogOut;
