import {
  GET_BONUSES,
  GET_ALL_BONUSES,
  GET_BONUS,
  CREATE_BONUS,
  DELETE_BONUS,
  BONUSES_LOADING,
  UPDATE_BONUS,
  ADD_BONUS,
  REMOVE_BONUS,
} from "../types";

const initialState = {
  bonuses: [],
  data: [],
  bonus: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BONUSES:
      return {
        ...state,
        bonuses: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_ALL_BONUSES:
      return {
        ...state,
        bonuses: action.payload,
        loading: false,
      };
    case GET_BONUS:
      return {
        ...state,
        bonus: action.payload.data,
        loading: false,
      };
    case DELETE_BONUS:
      return {
        ...state,
        bonuses: state.bonuses.filter((bonus) => bonus._id !== action.payload),
      };
    case CREATE_BONUS:
      return {
        ...state,
        bonuses: [action.payload, ...state.bonuses],
      };
    case BONUSES_LOADING:
      return {
        ...state,
        bonuses: [],
        bonus: null,
        loading: true,
      };
    case ADD_BONUS:
      return {
        ...state,
        bonus: { ...state.bonus, bonuses: action.payload },
      };
    case REMOVE_BONUS:
      return {
        ...state,
        bonus: { ...state.bonus, bonuses: action.payload },
        loading: false,
      };
    case UPDATE_BONUS: {
      state.bonuses.forEach((element, index) => {
        if (element._id === action.payload._id) {
          state.bonuses[index] = action.payload;
        }
      });
      return {
        ...state,
        bonuses: state.bonuses,
      };
    }

    default:
      return state;
  }
}
