import React, { useEffect, useState } from "react";
import Header from "../../../components/Page/HeaderCompany";
import AssignedCandidates from "../../../components/Company/Widgets/AssignedCandidates";
import { V3 } from "../../../lib/api";
import Loader from "../../../components/GlobalLoader";
import Invoices from "../../../components/Company/Widgets/Invoices";
import ActiveSourcing from "../../../components/Company/Widgets/ActiveSourcing";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Users from "../Users";
import { IconButton, Button } from "@material-ui/core";
import styled from "styled-components";
import ListIcon from "@material-ui/icons/List";
import CrawledJobs from "../../../components/Company/crawledJobs";
import Dashboard from "../../../components/Company/Dashboard/index";
import Jobs from "../../../components/Company/Dashboard/Jobs";
import LinkedinApplications from "../../../components/Company/Widgets/LinkedinApplications";
import ReferralsDashboard from "../../../components/Company/Widgets/ReferralsDashboard/index";
import ProductsDashboard from "../../../components/Company/Widgets/Products/index";
import moment from "moment";
import VideoApplies from "../../../components/Company/Widgets/VideoApplies";
import AllInOnes from "../../../components/Company/Widgets/AllInOnes";
import { useParams } from "react-router-dom";
import Intranet from "../../../components/Company/Widgets/Intranet";

const DaughterCompaniesWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 12px;
  position: absolute;
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 15px 15px;
  z-index: 99999;
  width: 100%;
  max-width: 300px;
  p {
    cursor: pointer;
    padding: 8px 0;
    margin: 0;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CompanyAnalytics() {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [daughterCompanies, setDaughterCompanies] = useState([]);

  const [value, setValue] = React.useState(0);
  const [selectedCompany, setSelectedCompany] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "year")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSelectedCompany(id);
    V3.get(`auth/my-accounts/${id}`).then((res) => {
      setDaughterCompanies(res.data.data);
    });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    if (selectedCompany) {
      V3.get(`/companies/${selectedCompany}`).then((response) => {
        setCompany(response.data.data);
        setLoading(false);
      });
    }
  }, [selectedCompany]);

  if (loading) return <Loader />;

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <Header
          title={`${company.name}`}
          icon={
            company?.parentCompany || company?.daughterCompany ? (
              <IconButton
                onClick={() => setOpen(!open)}
                style={{ marginLeft: 15 }}
              >
                <ListIcon color="#000" />
              </IconButton>
            ) : null
          }
        >
          <Button
            component="a"
            href={`/crm/company/${company.crmCompany?._id}`}
            target="_blank"
            color="primary"
            variant="contained"
          >
            CRM Company
          </Button>
        </Header>
        {open && (
          <DaughterCompaniesWrapper>
            {daughterCompanies.map((el) => {
              return (
                <p
                  onClick={() => {
                    setSelectedCompany(el._id);
                    setOpen(false);
                  }}
                >
                  {" "}
                  {el?.name}
                </p>
              );
            })}
          </DaughterCompaniesWrapper>
        )}
      </div>

      <div>
        <Paper style={{ marginBottom: 30 }}>
          <Tabs
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            <Tab label="Dashboard" {...a11yProps(0)} />
            <Tab label="Publizierte Jobs" {...a11yProps(1)} />
            {company?.jobCrawling && <Tab label="crawler" {...a11yProps(2)} />}

            <Tab
              label="Active Sourcing"
              {...a11yProps(company?.jobCrawling ? 3 : 2)}
            />
            <Tab
              label="Kandidaten"
              {...a11yProps(company?.jobCrawling ? 4 : 3)}
            />
            <Tab
              label="All In One"
              {...a11yProps(company?.jobCrawling ? 5 : 4)}
            />
            <Tab
              label="Bewerbungen Ohne CV"
              {...a11yProps(company?.jobCrawling ? 6 : 5)}
            />
            <Tab
              label="Video Applications"
              {...a11yProps(company?.jobCrawling ? 7 : 6)}
            />
            <Tab
              label="Mitarbeiter Aktivierung"
              {...a11yProps(company?.jobCrawling ? 8 : 7)}
              style={
                company?.bonuses?.length > 0 && company?.bonuses[0].emailSent
                  ? { color: "#fff", backgroundColor: "green" }
                  : {}
              }
            />
            <Tab
              label="Mitarbeiter Produkte"
              {...a11yProps(company?.jobCrawling ? 9 : 8)}
            />
            {company && company.crmCompany && (
              <Tab
                label="invoices"
                {...a11yProps(company?.jobCrawling ? 10 : 9)}
              />
            )}
            <Tab label="Users" {...a11yProps(company?.jobCrawling ? 11 : 10)} />
            <Tab
              label="Intranet"
              {...a11yProps(company?.jobCrawling ? 12 : 11)}
            />
          </Tabs>
        </Paper>

        <TabPanel value={value} index={0}>
          <Dashboard
            company={company}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Jobs id={company?._id} />
        </TabPanel>
        {company?.jobCrawling && (
          <TabPanel value={value} index={2}>
            <CrawledJobs
              companyId={selectedCompany}
              company={company}
              setCompany={setCompany}
            />
          </TabPanel>
        )}

        <TabPanel value={value} index={company?.jobCrawling ? 3 : 2}>
          <ActiveSourcing company={company} />
        </TabPanel>

        <TabPanel value={value} index={company?.jobCrawling ? 4 : 3}>
          <AssignedCandidates companyId={company?._id} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 5 : 4}>
          <AllInOnes company={company} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 6 : 5}>
          <LinkedinApplications company={company} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 7 : 6}>
          <VideoApplies company={company} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 8 : 7}>
          <ReferralsDashboard company={company} setCompany={setCompany} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 9 : 8}>
          <ProductsDashboard companyId={company?._id} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 10 : 9}>
          <Invoices companyId={selectedCompany} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 11 : 10}>
          <Users companyId={selectedCompany} />
        </TabPanel>
        <TabPanel value={value} index={company?.jobCrawling ? 12 : 11}>
          <Intranet companyId={selectedCompany} />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}

export default CompanyAnalytics;
