import React, { useState, useEffect } from "react";
import { V3 } from "../../../../lib/api";
import Card from "../../../Card";
import Table from "../../../Table";
import Actions from "../../../Table/Actions";
import Moment from "react-moment";
import Add from "./Add";
import Edit from "./Edit";
import InfoIcon from "@material-ui/icons/Info";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

function Index({ id, companyId }) {
  const [company, setCompany] = useState({ updateCalls: [] });
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      V3.get(`/crm/companies/${id}`).then((res) => {
        setCompany(res.data.data);
        setLoading(false);
      });
    }
  }, [id]);

  const getNote = (data) => {
    let notes = data?.notes || "";

    if (data?.notesArray?.length) {
      let notesArray = data.notesArray;
      if (companyId) {
        notesArray = data.notesArray.filter((el) => el.company === companyId);
      }
      notesArray.forEach((el) => {
        notes = notes + `</br> ${el.note}`;
      });
    }

    return notes;
  };

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: (d) => d.date,
      filterable: false,

      Cell: (props) => (
        <div>
          <Moment>{props.original.date}</Moment>
        </div>
      ),
    },
    {
      id: "nextCall",
      Header: "Next Call",
      accessor: (d) => d.nextCall,
      filterable: false,

      Cell: (props) => (
        <div>
          <Moment>{props.original.nextCall}</Moment>
        </div>
      ),
    },
    {
      id: "notes",
      Header: "Notes",
      accessor: (d) => d.createdAt,
      filterable: false,

      Cell: (props) => (
        <div>
          <span>
            {props.original?.notes?.length > 0 ? (
              <IconButton
                onClick={() => {
                  setNotes(getNote(props.original));
                  setOpen(true);
                }}
              >
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getNote(props.original) || "",
                      }}
                    ></div>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,

      Cell: (props) => (
        <Actions>
          <Edit data={props.original} id={id} setCompany={setCompany} />
          <Tooltip
            title="Delete this job"
            onClick={() => handleDelete(props.original._id)}
          >
            <DeleteIcon jobId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  const handleDelete = (callId) => {
    setLoading(true);
    V3.delete(`/crm/companies/update-call/${id}/${callId}`).then((res) => {
      setCompany(res.data.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        title={`Update Calls -${
          company?.nextUpdateCall
            ? ` Next call is on ${moment(company?.nextUpdateCall).format(
                "DD/MM/YYYY"
              )}`
            : ""
        }`}
        button={<Add id={id} setCompany={setCompany} />}
      >
        <Table
          data={company?.updateCalls.sort((a, b) => {
            return moment(a?.nextCall || new Date()).diff(
              moment(b?.nextCall || new Date())
            );
          })}
          columns={columns}
          loading={loading}
          page={0}
          pages={1}
          onFetchData={(state) => {}}
          noDataText="No update Calls"
          filterable={false}
          minRows={5}
          showPaginationBottom={false}
        />
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Notes</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notes }}></div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Index;
