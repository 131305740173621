import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { useDispatch } from "react-redux";
import { V3 } from "../../../lib/api";
import { ADD_CONTACT_PERSON } from "../../../redux/types";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import Loader from "../../GlobalLoader";
function AlertDialog({ companyId }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formApi) => {
    setMessage(null);
    const { firstName, lastName, email, phone, role, phoneSeconde, linkedin } =
      values;
    const data = {
      firstName,
      lastName,
      email,
      phone,
      role,
      phoneSeconde,
      linkedin,
    };
    V3.put(`/crm/companies/add-contact/${companyId}`, data)
      .then((response) => {
        enqueueSnackbar("Contact person created successfully", {
          variant: "success",
        });

        formApi.setSubmitting(false);

        dispatch({
          type: ADD_CONTACT_PERSON,
          payload: response.data.data,
        });
        setOpen(false);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message);
        enqueueSnackbar("Could not created contact person", {
          variant: "error",
        });
        formApi.setSubmitting(false);
      });
  };
  return (
    <React.Fragment>
      <Button
        color="primary"
        size="small"
        onClick={handleClickOpen}
        variant="outlined"
      >
        Neu
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <Formik
          validationSchema={Yup.object().shape({
            firstName: Yup.string().nullable().required("Field is required"),
            lastName: Yup.string().nullable().required("Field is required"),
            email: Yup.string().nullable(),
            phone: Yup.string(),
            phoneSeconde: Yup.string(),
            role: Yup.string(),
            linkedin: Yup.string().nullable(),
          })}
          onSubmit={_handleSubmit}
          initialValues={{
            firstName: null,
            lastName: null,
            email: "",
            phone: "",
            phoneSeconde: "",
            role: "",
            linkedin: "",
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              <DialogTitle>Add contact person</DialogTitle>
              <DialogContent>
                {isSubmitting && <Loader />}
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstName"
                      label="Vorname"
                      name="firstName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastName"
                      label="Nachname"
                      name="lastName"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Tel."
                      name="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phoneSeconde"
                      label="Seconde Tel."
                      name="phoneSeconde"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="role"
                      label="Function"
                      name="role"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="linkedin"
                      label="Linkedin"
                      name="linkedin"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "15px 24px" }}>
                <Button onClick={handleClose} color="primary">
                  Schließen
                </Button>
                <Button color="primary" type="submit" disabled={!dirty}>
                  Create contact person
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default AlertDialog;
