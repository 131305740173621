import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import Paper from "@material-ui/core/Paper";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MediaChoose from "../../../components/Media/Chooser";
import { V3 } from "../../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

function FeaturedImage({ values, setFieldValue }) {
  const [selectImage, setSelectedImage] = React.useState(values.image);

  React.useEffect(() => {
    setFieldValue("image", selectImage ? selectImage._id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <Paper>
      <MediaChoose onChange={setSelectedImage}>
        <div style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}>
          {selectImage ? (
            <Preview>
              <img
                src={selectImage ? selectImage.lg.url : ""}
                alt={selectImage ? selectImage.lg.url : ""}
              />
            </Preview>
          ) : (
            <p style={{ textAlign: "center" }}>
              Klicken Sie, um ein Bild auszuwählen
            </p>
          )}
        </div>
      </MediaChoose>
      {selectImage && (
        <div style={{ padding: 15 }}>
          <Button
            onClick={(e) => setSelectedImage(null)}
            fullWidth
            variant="outlined"
          >
            Aktuelles Bild entfernen
          </Button>
        </div>
      )}
    </Paper>
  );
}

function AddTeamMember() {
  const [data, setData] = useState(null);
  const [message, setMessage] = useState(null);

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    V3.get(`/teams/${id}`).then((res) => {
      setData(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _handleSubmit = (values, formApi) => {
    setMessage(null);
    V3.put(`/teams/${id}`, values)
      .then((res) => {
        setData(res?.data?.data);

        enqueueSnackbar("Team person updated", {
          variant: "success",
        });
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        enqueueSnackbar("Could not updated Team person", {
          variant: "error",
        });
        formApi.setSubmitting(false);
      });
  };

  if (!data) {
    return <Loader />;
  }
  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().nullable().required("Required"),
        role: Yup.string().nullable().required("Required"),
        email: Yup.string()
          .email("Please add a valid email")
          .required("Required"),
        linkedIn: Yup.string(),
        phone: Yup.string().nullable(),
        published: Yup.boolean(),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        name: data.name,
        role: data.role,
        email: data.email,
        phone: data.phone,
        published: data.published,
        image: data.image,
        linkedIn: data.linkedIn,
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, values }) => (
        <Form>
          {isSubmitting && <Loader></Loader>}

          <Container style={{ maxWidth: 1024 }}>
            {message && (
              <Alert severity="error" style={{ marginBottom: 15 }}>
                {message}{" "}
              </Alert>
            )}
            <Paper style={{ padding: 15, marginBottom: 15 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FeaturedImage
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Name"
                    name="name"
                    style={{ margin: 0 }}
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Role"
                    name="role"
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    name="email"
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Mobile"
                    name="phone"
                  />
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="LinkedIn"
                    name="linkedIn"
                  />
                  <Paper
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <span>Published</span>
                    <div>
                      <Field
                        component={Switch}
                        color="primary"
                        name="published"
                      />
                    </div>
                  </Paper>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: 15 }}
                    fullWidth
                    disabled={!dirty}
                    type="submit"
                  >
                    Aktualisieren
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Form>
      )}
    </Formik>
  );
}

export default AddTeamMember;
