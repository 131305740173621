import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import MUITextField from "@material-ui/core/TextField";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { CONTACTING_LOADING } from "../../../redux/types";
import { useDispatch } from "react-redux";
import { DatePicker } from "material-ui-formik-components/DatePicker";

function New({ contactingData, update }) {
  const [open, setOpen] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [linkedIn, setLinkedIn] = useState([]);
  const dispatch = useDispatch();

  const linkedInAccounts = linkedIn.map((el) => {
    return {
      key: el._id,
      value: `${el.accountNumber} ${el.accountName}`,
      label: `${el.accountNumber} ${el.accountName}`,
    };
  });

  useEffect(() => {
    if (open === true) {
      V3.get(`/users?limit=1000&roles=admin,employee,editor`).then(
        (recruiterRes) => {
          setRecruiters(recruiterRes.data.data);
        }
      );
      V3.get(`/linkedin-accounts/all`).then((res) => {
        setLinkedIn(res.data.data);
      });
    }
  }, [open]);

  const handleSubmit = (values, formikApi) => {
    dispatch({
      type: CONTACTING_LOADING,
    });
    const data = {
      ...values,
      recruiter: values.recruiter._id,
      linkedInAccount: values?.linkedInAccount?.key
        ? values?.linkedInAccount?.key
        : null,
    };

    V3.put(`/contactings/${contactingData._id}`, data).then((res) => {
      update(res.data);
      formikApi.setSubmitting(false);
      setOpen(false);
    });
  };

  return (
    <>
      <Tooltip title="Edit Week">
        <span className="action fi" onClick={() => setOpen(true)}>
          <EditIcon style={{ margin: 0, marginRight: 0 }} />
        </span>
      </Tooltip>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Edit Week</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              week: Yup.number().min(1).required("Required"),
              toContact: Yup.number().min(0).required("Required"),
              contacted: Yup.number().min(0).required("Required"),
              year: Yup.date(),
              recruiter: Yup.object()
                .typeError("Please select a recruiter")
                .nullable()
                .required("Required"),
              linkedInAccount: Yup.object()
                .typeError("Please select a Account")
                .nullable(),
            })}
            onSubmit={handleSubmit}
            initialValues={{
              week: contactingData.week,
              toContact: contactingData.toContact,
              contacted: contactingData.contacted,
              recruiter: contactingData.recruiter,
              account: contactingData?.account,
              note: contactingData?.note || "",
              year: contactingData?.year,
              linkedInAccount: contactingData?.linkedInAccount
                ? {
                    key: contactingData?.linkedInAccount._id,
                    value: `${contactingData?.linkedInAccount.accountNumber} ${contactingData?.linkedInAccount.accountName}`,
                    label: `${contactingData?.linkedInAccount.accountNumber} ${contactingData?.linkedInAccount.accountName}`,
                  }
                : null,
            }}
          >
            {({ submitForm, isSubmitting, dirty, values, setFieldValue }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="week"
                      label="Week"
                      name="week"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="contacted"
                      label="Contacted"
                      name="contacted"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="toContact"
                      label="To Contact"
                      name="toContact"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      name="year"
                      id="year"
                      label="Year"
                      inputVariant="outlined"
                      fullWidth
                      format="yyyy"
                      views={["year"]}
                      openTo="year"
                      autoOk
                      disableToolbar
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MUIAutocomplete
                      onChange={(event, value) =>
                        setFieldValue("recruiter", value)
                      }
                      style={{ width: "100%" }}
                      value={values.recruiter}
                      options={recruiters}
                      getOptionLabel={(option) => option?.profile?.name || "-"}
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          variant="outlined"
                          label="Recruiter"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="linkedInAccount"
                      options={linkedInAccounts}
                      component={Autocomplete}
                      textFieldProps={{
                        label: "Account",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="note"
                      label="Reminder - Kandidatenpool und Interessentenpool nutzen"
                      name="note"
                      style={{ margin: 0 }}
                      multiline
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button color="primary" autoFocus type="submit">
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default New;
