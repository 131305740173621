import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Card from "../../components/Card";
import ImageUpload from "../../components/imageUpload";
import FileUpload from "../../components/flyerUpload";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import {
  TextField as MUITextField,
  Switch as MuiSwitch,
} from "@material-ui/core";
import CrmCompanySelector from "./CrmCompanySelector";
import Users from "./Users";
import { Switch } from "material-ui-formik-components/Switch";
import Editor from "../../components/Editor";
import Bonuses from "../../components/Company/Widgets/Bonuses/index";
import { V3 } from "../../lib/api";
import TagsInput from "../../components/Job/TagInput";
import { KeyboardDatePicker } from "material-ui-formik-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import styled from "styled-components";
import {
  COMPANIES_LOADING,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../redux/types";
import { useSnackbar } from "notistack";
// import from

const PhoneWrapper = styled.div`
  input {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
`;

const selectValues = [
  {
    key: "Dashboard",
    label: "Dashboard",
    value: "Dashboard",
  },
  {
    key: "Tool",
    label: "Tool",
    value: "Tool",
  },
  {
    key: "E-Mail",
    label: "E-Mail",
    value: "E-Mail",
  },
];

function AddCompany() {
  const [users, setUsers] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [teamMembers, setTeamMembers] = React.useState([]);
  const { company, loading } = useSelector((state) => state.company);
  const { id } = useParams();

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({
          type: COMPANIES_LOADING,
        });
        const res = await V3.get(`/companies/${id}`);
        dispatch({
          type: GET_COMPANY,
          payload: res.data,
        });

        const response = await V3.get(`/users?roles=admin,employee&limit=400`);
        setUsers(
          response.data.data.map((user) => {
            return {
              key: user?._id,
              value: user?.profile?.name,
              label: user?.profile?.name,
            };
          })
        );
        const companyUserRes = await V3.get(
          `/users?roles=company&company=${id}`
        );
        setCompanyUsers(
          companyUserRes.data.data.map((user) => {
            return {
              key: user?._id,
              value: user?.profile?.name,
              label: user?.profile?.name,
            };
          })
        );
        const teamRes = await V3.get(`/teams?limit=400`);
        setTeamMembers(
          teamRes.data.data.map((user) => {
            return {
              key: user?._id,
              value: user?.name,
              label: user?.name,
            };
          })
        );
      } catch (error) {}
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = async (values, formikAPi) => {
    const {
      crmCompany,
      dashboardSelect,
      emailsForCrawler,
      sendCrawlerEmailTo,
      jobs,
      con,
      avatar,
      avatarPreview,
      flyer,
      flyerPreview,
      members,
      sendNewJobEmailTo,
      sendNewCandidateEmailTo,
      ...rest
    } = values;

    let images = { ...company?.images };

    if (avatar || flyer?.length) {
      const formData = new FormData();

      if (avatar) {
        formData.append("avatar", avatar);
      }
      if (flyer && flyer?.length > 0) {
        formData.append("flyer", flyer[0]);
      }
      const res = await V3.put(`companies/files`, formData);

      if (res?.data?.data) {
        images = { ...images, ...res?.data?.data };
      }
    }

    if (!flyerPreview && !flyer?.length) {
      images.flyer = null;
    }

    const data = {
      ...rest,
      dashboardSelect: dashboardSelect?.key,
      noCrawlerEmailTo: [],
      sendCrawlerEmailTo: sendCrawlerEmailTo?.length
        ? sendCrawlerEmailTo.map((el) => el.key)
        : [],
      sendNewJobEmailTo: sendNewJobEmailTo?.length
        ? sendNewJobEmailTo.map((el) => el.key)
        : [],
      sendNewCandidateEmailTo: sendNewCandidateEmailTo?.length
        ? sendNewCandidateEmailTo.map((el) => el.key)
        : [],
      emailsForCrawler: emailsForCrawler?.length ? emailsForCrawler : [],
      consultant: con?.key,
      crmCompany: crmCompany ? crmCompany._id : null,
      consultants: members.map((el) => el.key),
      avatar: avatarPreview,
      flyer: flyerPreview,
      images: images,
      availableJobs: jobs,
    };
    V3.put(`/companies/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_COMPANY,
          payload: res.data.data,
        });
        enqueueSnackbar("Company updated successfully", {
          variant: "success",
        });
        push("/companies");
        formikAPi.setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated Company", {
          variant: "error",
        });
        formikAPi.setSubmitting(false);
      });
  };

  const getDashboardSelect = (value) => {
    for (let index = 0; index < selectValues.length; index++) {
      const element = selectValues[index];
      if (value === element.key) {
        return element;
      }
    }
    return {
      key: "",
      label: "",
      value: "",
    };
  };

  if (loading && !company) return <div></div>;
  return (
    <>
      {company !== null && (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required("Required"),
            website: Yup.string(),
            street: Yup.string(),
            zip: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            impressionsRate: Yup.number().nullable(),
            activeSourcing: Yup.number().nullable(),
            allInOne: Yup.number().nullable(),
            totalEmployees: Yup.number().required("Required"),
            description: Yup.string().nullable(),
            dashboardSelect: Yup.string().nullable(),
            dashboardEmail: Yup.string().nullable(),
            dashboardTool: Yup.string().nullable(),
            blackListNotes: Yup.string().nullable(),
            preferredNotes: Yup.string().nullable(),
            jobCrawling: Yup.boolean(),
            crmCompany: Yup.object().nullable().required("Required"),
            jobs: Yup.number().nullable().required("Required"),
            daughterCompany: Yup.boolean().nullable(),
            parentCompany: Yup.boolean().nullable(),
            deactivateCrawlerEmail: Yup.boolean().nullable(),
            sendMonthlyCrawlerEmail: Yup.boolean().nullable(),
            nextCrawlerEmail: Yup.date().nullable(),
            jobsIframe: Yup.string().nullable(),
            addCandidates: Yup.boolean().nullable(),
          })}
          onSubmit={_handleSubmit}
          enableReinitialize
          initialValues={{
            name: company && company.name,
            website: company && company.website,
            street: company && company.street,
            zip: company && company.zip,
            city: company && company.city,
            state: company && company.state,
            impressionsRate: company && company.impressionsRate,
            crmCompany: company && company.crmCompany,
            activeSourcing: (company && company.activeSourcing) || "",
            allInOne: (company && company.allInOne) || null,
            description: (company && company.description) || "",
            dashboardSelect:
              company &&
              company.dashboardSelect &&
              getDashboardSelect(company.dashboardSelect || ""),
            dashboardEmail: (company && company.dashboardEmail) || "",
            dashboardTool: (company && company.dashboardTool) || "",
            toolLink: (company && company.toolLink) || "",
            toolUsername: (company && company.toolUsername) || "",
            toolPassword: (company && company.toolPassword) || "",
            toolNote: (company && company.toolNote) || "",
            jobCrawling: company && company.jobCrawling,
            totalEmployees: company && company.totalEmployees,
            jobs: company && company.availableJobs,
            blackListNotes: (company && company.blackListNotes) || "",
            preferredNotes: (company && company.preferredNotes) || "",
            daughterCompany: company.daughterCompany,
            parentCompany: company.parentCompany,
            emailsForCrawler: company.emailsForCrawler || [],
            deactivateCrawlerEmail: company.deactivateCrawlerEmail || false,
            sendMonthlyCrawlerEmail: company.sendMonthlyCrawlerEmail || false,
            nextCrawlerEmail: company.nextCrawlerEmail || null,
            jobsIframe: company.jobsIframe || "",
            addCandidates: company.addCandidates || true,
            whatsapp: company?.whatsapp || "41",
            contactWithWhatsapp: company?.contactWithWhatsapp,
            applyWithLinkedin: company?.applyWithLinkedin,
            applyWithVideo: company?.applyWithVideo,
            applyNotes: company?.applyNotes,
            sendCrawlerEmailTo: company?.sendCrawlerEmailTo?.length
              ? company.sendCrawlerEmailTo.map((user) => {
                  return {
                    key: user?._id,
                    value: user?.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],
            noCrawlerEmailTo: company?.noCrawlerEmailTo?.length
              ? company.noCrawlerEmailTo.map((user) => {
                  return {
                    key: user?._id,
                    value: user.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],

            con: company?.consultant?._id
              ? {
                  key: company?.consultant?._id,
                  value: company?.consultant?.name,
                  label: company?.consultant?.name,
                }
              : null,
            avatar: null,
            avatarPreview: company?.images?.avatarX256 || null,
            flyer: null,
            flyerPreview: company?.images?.flyer || null,
            intranet: company?.intranet || false,
            members: company
              ? company?.consultants?.map((user) => {
                  return {
                    key: user?._id,
                    label: user?.profile?.name,
                    value: user?.profile?.name,
                  };
                })
              : [],
            sendNewJobEmailTo: company?.sendNewJobEmailTo?.length
              ? company.sendNewJobEmailTo.map((user) => {
                  return {
                    key: user?._id,
                    value: user?.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],
            sendNewCandidateEmailTo: company?.sendNewCandidateEmailTo?.length
              ? company.sendNewCandidateEmailTo.map((user) => {
                  return {
                    key: user?._id,
                    value: user?.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],
          }}
        >
          {({ isSubmitting, setFieldValue, initialValues, values }) => (
            <Form>
              {isSubmitting && <Loader />}

              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="jobs"
                          label="Available Jobs"
                          name="jobs"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="name"
                          label="Name"
                          name="name"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="website"
                          label="Website"
                          name="website"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    {" "}
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="jobsIframe"
                      label="Iframe"
                      name="jobsIframe"
                      style={{ margin: 0 }}
                      multiline
                      rows={6}
                    />
                  </Paper>
                  <Card
                    title="Noticen"
                    style={{ marginBottom: 15, overflow: "hidden" }}
                  >
                    <Editor
                      value={initialValues.description}
                      height={300}
                      onChange={(value) => setFieldValue("description", value)}
                    />
                  </Card>
                  <Card
                    title="Schwarze Liste Noticen"
                    style={{ marginBottom: 15, overflow: "hidden" }}
                  >
                    <Editor
                      value={initialValues.blackListNotes}
                      height={300}
                      onChange={(value) =>
                        setFieldValue("blackListNotes", value)
                      }
                    />
                  </Card>
                  <Card
                    title="Bevorzugen Noticen"
                    style={{ marginBottom: 15, overflow: "hidden" }}
                  >
                    <Editor
                      value={initialValues.preferredNotes}
                      height={300}
                      onChange={(value) =>
                        setFieldValue("preferredNotes", value)
                      }
                    />
                  </Card>
                  <Card
                    title="Apply Notes"
                    style={{ marginBottom: 15, overflow: "hidden" }}
                  >
                    <Editor
                      value={values.applyNotes}
                      height={300}
                      onChange={(value) => setFieldValue("applyNotes", value)}
                    />
                  </Card>
                  <Users companyId={company._id} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card title="Location" contentStyles={{ padding: 15 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="street"
                              label="Strasse"
                              name="street"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="zip"
                              label="PLZ"
                              name="zip"
                              style={{ margin: 0 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="city"
                              label="Ort"
                              name="city"
                              style={{ margin: 0 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="state"
                              label="Land"
                              name="state"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Bonuses totalEmployees={values.totalEmployees} />
                    </Grid>
                    {values.jobCrawling ? (
                      <Grid item xs={12} md={12}>
                        <Card
                          title="Crawler"
                          style={{ marginBottom: 15, overflow: "hidden" }}
                        >
                          <Grid container spacing={2} style={{ padding: 15 }}>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={Switch}
                                name="deactivateCrawlerEmail"
                                label={
                                  <p style={{ paddingLeft: 15 }}>
                                    Deactivate emails from crawler
                                  </p>
                                }
                                style={{ marginLeft: 15 }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={Switch}
                                name="sendMonthlyCrawlerEmail"
                                label={
                                  <p style={{ paddingLeft: 15 }}>
                                    Send monthly emails from crawler
                                  </p>
                                }
                                style={{ marginLeft: 15 }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TagsInput
                                selectedItem={values?.emailsForCrawler}
                                tags={values?.emailsForCrawler}
                                setSelectedItem={(data) =>
                                  setFieldValue("emailsForCrawler", data)
                                }
                                fullWidth
                                variant="outlined"
                                id="emailsForCrawler"
                                name="emailsForCrawler"
                                placeholder="hinzufügen"
                                label="Crawler emails"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                component={KeyboardDatePicker}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="nextCrawlerEmail"
                                label="Next email will be send"
                                name="nextCrawlerEmail"
                                style={{ margin: 0 }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <MUIAutocomplete
                                multiple
                                options={companyUsers}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, val) =>
                                  setFieldValue("sendCrawlerEmailTo", val)
                                }
                                value={values.sendCrawlerEmailTo}
                                getOptionSelected={(option, value) => {
                                  return option.key === value.key;
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    placeholder="Select"
                                    variant="outlined"
                                    label="Send Email To"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      style={{ padding: "16px 32px" }}
                      // disabled={flyer.length < 1 && !flyerPreview}
                    >
                      Aktualisieren
                    </Button>
                  </Paper>
                  <Paper>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <CrmCompanySelector
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="impressionsRate"
                              label="Impressions Rate"
                              name="impressionsRate"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <MUIAutocomplete
                              multiple
                              options={users}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, val) =>
                                setFieldValue("members", val)
                              }
                              value={values.members}
                              getOptionSelected={(option, value) => {
                                return option.key === value.key;
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  placeholder="Select"
                                  variant="outlined"
                                  label="Consultants"
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <MUIAutocomplete
                              options={teamMembers}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, val) => setFieldValue("con", val)}
                              value={values.con}
                              getOptionSelected={(option, value) => {
                                return option.key === value.key;
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  placeholder="Select"
                                  variant="outlined"
                                  label="Kundenberater"
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Hauptunternehmen
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={MuiSwitch}
                                name="parentCompany"
                                checked={values.parentCompany}
                                onChange={() =>
                                  setFieldValue(
                                    "parentCompany",
                                    !values?.parentCompany
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Tochterunternehmen
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={MuiSwitch}
                                name="daughterCompany"
                                checked={values.daughterCompany}
                                onChange={() =>
                                  setFieldValue(
                                    "daughterCompany",
                                    !values?.daughterCompany
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="activeSourcing"
                              label="Active sourcing"
                              name="activeSourcing"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="allInOne"
                              label="All in One"
                              name="allInOne"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="totalEmployees"
                              label="Total Employees"
                              name="totalEmployees"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Jobs Crawling
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field component={Switch} name="jobCrawling" />
                            </div>
                          </TableCell>
                        </TableRow>{" "}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Bewerben mit video
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field component={Switch} name="applyWithVideo" />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Bewerben ohne CV
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={Switch}
                                name="applyWithLinkedin"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Contact With Whatsapp
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={Switch}
                                name="contactWithWhatsapp"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {values?.contactWithWhatsapp && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <PhoneWrapper>
                                {" "}
                                <PhoneInput
                                  country={"us"}
                                  value={values.whatsapp}
                                  onChange={(phone) =>
                                    setFieldValue("whatsapp", phone)
                                  }
                                  style={{ width: "100%" }}
                                  fullWidth
                                />
                              </PhoneWrapper>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={2}>
                            <MUIAutocomplete
                              multiple
                              options={companyUsers}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, val) =>
                                setFieldValue("sendNewJobEmailTo", val)
                              }
                              value={values.sendNewJobEmailTo}
                              getOptionSelected={(option, value) => {
                                return option.key === value.key;
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  placeholder="Select"
                                  variant="outlined"
                                  label="Send New Job Email To"
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={2}>
                            <MUIAutocomplete
                              multiple
                              options={companyUsers}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, val) =>
                                setFieldValue("sendNewCandidateEmailTo", val)
                              }
                              value={values.sendNewCandidateEmailTo}
                              getOptionSelected={(option, value) => {
                                return option.key === value.key;
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  placeholder="Select"
                                  variant="outlined"
                                  label="Send New Candidate Email To"
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={Autocomplete}
                              fullWidth
                              name="dashboardSelect"
                              options={selectValues}
                              variant="outlined"
                              textFieldProps={{
                                variant: "outlined",
                                style: { margin: 0 },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {values.dashboardSelect?.key === "E-Mail" && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Field
                                component={TextField}
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="dashboardEmail"
                                label="Email"
                                name="dashboardEmail"
                                style={{ margin: 0 }}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {values.dashboardSelect?.key === "Tool" && (
                          <>
                            {" "}
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="dashboardTool"
                                  label="Tool"
                                  name="dashboardTool"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="toolLink"
                                  label="Link"
                                  name="toolLink"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="toolUsername"
                                  label="Username"
                                  name="toolUsername"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="toolPassword"
                                  label="Password"
                                  name="toolPassword"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="toolNote"
                                  label="Note"
                                  name="toolNote"
                                  style={{ margin: 0 }}
                                  multiline
                                  rows={6}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Add Candidates
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field component={Switch} name="addCandidates" />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Intranet
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field component={Switch} name="intranet" />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                  <Paper style={{ marginTop: 15 }}>
                    <ImageUpload
                      imagePreviewUrl={values.avatarPreview}
                      onImageChange={(event) => {
                        setFieldValue("avatar", event.target.files[0]);
                        let reader = new FileReader();

                        reader.onloadend = () => {
                          setFieldValue("avatarPreview", reader.result);
                        };

                        reader.readAsDataURL(event.target.files[0]);
                      }}
                      id="avatar"
                    />
                  </Paper>
                  <Paper style={{ marginTop: 15 }}>
                    <FileUpload
                      handleChange={(e) => setFieldValue("flyer", e)}
                      handleDelete={() => setFieldValue("flyerPreview", null)}
                      id="flyer"
                      flyer={values.flyerPreview}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default AddCompany;
