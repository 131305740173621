import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Card from "../../../components/Card";
import Editor from "../../../components/Editor";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { V3 } from "../../../lib/api";
import KeyboardDatePicker from "../candidates/KeyboardDatePicker";
import { Select, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { ADD_COMPANY_CRM } from "../../../redux/types";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const AddCompany = () => {
  const [users, setUsers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [message, setMessage] = useState(null);

  const [categories, setCategories] = useState([]);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = (values, formApi) => {
    setMessage(null);
    let {
      consultant,
      caller,
      category,
      phone,
      firstname,
      lastname,
      role,
      contactEmail,
      documentSentDate,
      ...rest
    } = values;

    if (documentSentDate === "null") {
      documentSentDate = null;
    }

    const data = {
      ...rest,
      consultant: consultant.key,
      caller: caller.key,
      category: category.key,
      author: user._id,
      contactPersons: [
        {
          phone,
          firstName: firstname,
          lastName: lastname,
          role,
          email: contactEmail,
        },
      ],
    };

    V3.post(`/crm/companies`, data)
      .then((res) => {
        dispatch({
          type: ADD_COMPANY_CRM,
          payload: res.data,
        });
        enqueueSnackbar("Company created successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);

        push("/crm/companies");
      })
      .catch((err) => {
        formApi.setSubmitting(false);
        enqueueSnackbar("Could not created Company", {
          variant: "error",
        });
        setMessage(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await V3.get(`/categories/company`).then((response) => {
        setCategories(
          response.data.data.map((category) => {
            return {
              key: category.name,
              value: category.name,
              label: category.name,
            };
          })
        );
      });
      await V3.get(`/users?roles=admin,employee&limit=1000`).then(
        (response) => {
          setUsers(
            response.data.data.map((user) => {
              return {
                key: user._id,
                value: user.profile.name,
                label: user.profile.name,
              };
            })
          );
        }
      );
    };
    fetchData();
  }, []);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
        consultant: Yup.string().nullable().required("Required"),
        caller: Yup.string().nullable().required("Required"),
        category: Yup.string().nullable().required("Required"),
        website: Yup.string(),
        street: Yup.string(),
        openPositions: Yup.number(),
        documentSent: Yup.boolean(),
        phone: Yup.number().nullable(),
        firstname: Yup.string(),
        lastname: Yup.string(),
        role: Yup.string(),
        contactEmail: Yup.string().email(),
        conversationDate: Yup.string().nullable(),
        documentSentDate: Yup.string().nullable(),
        meeting: Yup.boolean(),
        subscriptionNotes: Yup.string(),
        hasDaughterCompanies: Yup.boolean(),
        infoFromParentCompany: Yup.boolean(),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        name: "",
        website: "",
        email: "",
        openPositions: "",
        state: "Schweiz",
        city: "",
        street: "",
        zip: "",
        notes: "",
        consultant: {
          key: "62f0ce9dc6a86d0016375a94",
          value: "Aktuell Vakant",
          label: "Aktuell Vakant",
        },
        caller: "",
        category: "",
        documentSent: false,
        documentSentDate: null,
        meeting: false,
        phone: "",
        firstname: "",
        lastname: "",
        role: "",
        contactEmail: "",
        conversationDate: null,
        subscriptionNotes: "",
        metingStatus: 0,
        hasDaughterCompanies: false,
        infoFromParentCompany: false,
        maa: 0,
      }}
    >
      {({ isSubmitting, dirty, setFieldValue, values }) => (
        <Form>
          {isSubmitting && <Loader></Loader>}

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              {message && (
                <Alert severity="error" style={{ marginBottom: 15 }}>
                  {message}
                </Alert>
              )}

              <Card
                title="Company Info"
                style={{ marginBottom: 15, marginTop: 0 }}
                contentStyles={{ padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="website"
                      label="Website"
                      name="website"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="openPositions"
                      label="Open Positions"
                      name="openPositions"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="maa"
                      label="Anzahl MA"
                      name="maa"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Card
                title="Contact Person"
                style={{ marginTop: 15, marginBottom: 15 }}
                contentStyles={{ padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstname"
                      label="Firstname"
                      name="firstname"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastname"
                      label="Lastname"
                      name="lastname"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Tel."
                      name="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="role"
                      label="Function"
                      name="role"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="contactEmail"
                      label="Email"
                      name="contactEmail"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card
                        title="Documents Sent"
                        contentStyles={{ padding: 15, height: "100%" }}
                        button={
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{ float: "right", width: "140px" }}
                          >
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              style={{ textAlign: "right" }}
                            >
                              No
                            </Grid>
                            <Grid item xs={4} alignItems="center">
                              <Field component={Switch} name="documentSent" />
                            </Grid>
                            <Grid item xs={4} alignItems="center">
                              Yes
                            </Grid>
                          </Grid>
                        }
                      >
                        <Field
                          name="documentSentDate"
                          component={KeyboardDatePicker}
                          label="Datum"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          style={{ margin: 0 }}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card
                        title="Termin"
                        contentStyles={{ padding: 15, height: "100%" }}
                        button={
                          <Select
                            value={values.metingStatus}
                            onChange={(e) =>
                              setFieldValue("metingStatus", e.target.value)
                            }
                          >
                            <MenuItem value={0}>Offen</MenuItem>
                            <MenuItem value={1}>Ja</MenuItem>
                            <MenuItem value={2}>Nein</MenuItem>
                          </Select>
                        }
                      >
                        <Field
                          name="conversationDate"
                          component={KeyboardDatePicker}
                          label="Tel. am"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          helperText={
                            (values.metingStatus === 1 ||
                              values.metingStatus === 2) &&
                            !values.conversationDate
                              ? "required"
                              : ""
                          }
                          error={
                            (values.metingStatus === 1 ||
                              values.metingStatus === 2) &&
                            !values.conversationDate
                          }
                          style={{ margin: 0 }}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Card
                title="Location"
                style={{ marginTop: 15 }}
                contentStyles={{ padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="street"
                      label="Strasse"
                      name="street"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="zip"
                      label="PLZ"
                      name="zip"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="city"
                      label="Ort"
                      name="city"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="state"
                      label="Land"
                      name="state"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    title="Notizen zum Abo (durch Berater auszufüllen)"
                    style={{ marginTop: 15 }}
                    defaultOpen={false}
                  >
                    <Editor
                      height={200}
                      value={values.subscriptionNotes}
                      onChange={(value) =>
                        setFieldValue("subscriptionNotes", value)
                      }
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={
                    !dirty ||
                    ((values.metingStatus === 1 || values.metingStatus === 2) &&
                      !values.conversationDate)
                  }
                  style={{ padding: "16px 32px" }}
                >
                  Create
                </Button>
              </Paper>
              <Paper style={{ padding: "0px" }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="consultant"
                          options={users}
                          textFieldProps={{
                            label: "Consultant",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="caller"
                          options={users}
                          textFieldProps={{
                            label: "Caller",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="category"
                          options={categories}
                          textFieldProps={{
                            label: "Kategorie",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Hat Tochterunternehmen</TableCell>
                      <TableCell>
                        <Field
                          style={{
                            display: "flex",
                            alignItems: "end",
                          }}
                          component={Switch}
                          name="hasDaughterCompanies"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Info From Parent Company</TableCell>
                      <TableCell>
                        <Field
                          style={{
                            display: "flex",
                            alignItems: "end",
                          }}
                          component={Switch}
                          name="infoFromParentCompany"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddCompany;
