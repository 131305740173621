import Table from "../../../Table";
import React, { useState } from "react";
import { V3 } from "../../../../lib/api";
import Filters from "../filters";

import moment from "moment";
import Moment from "react-moment";
import { IconButton } from "@material-ui/core";
import DownloadIcon from "../../../Icons/downloadIcon";
function EmployeesOrders({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(3, "months"));
  const [endDate, setEndDate] = useState(moment(new Date()));

  const getData = (query) => {
    setLoading(true);
    V3.get(`orders/me/recruiter/${companyId}${query}`).then((response) => {
      setData(response.data);
      setUsers(response.data.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "sId",
      Header: "Bestellnr",
      accessor: (d) => d._id,
      Cell: (props) => {
        return <strong>#{props.original?.sId}</strong>;
      },
    },
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original?.firstname || "-"} {props.original?.lastname || "-"}
        </span>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original?.email || "-"}
        </span>
      ),
    },
    {
      id: "products",
      Header: "Produkt",
      accessor: (d) => d._id,
      Cell: (props) => <>{props.original.products?.length}</>,
    },
    {
      id: "Points",
      Header: "Two$",
      accessor: (d) => d._id,
      Cell: (props) => <>{props.original.total}</>,
    },
    {
      id: "createdAt",
      Header: "Datum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "pdf",
      Header: "Bestellung",
      accessor: (d) => d._id,
      Cell: (props) => (
        <div>
          {" "}
          <IconButton
            component="a"
            className="icon-button"
            href={
              props.original?.pdf?.includes("https://")
                ? props.original?.pdf
                : `https://${props.original?.pdf}`
            }
            target="_blank"
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <Filters
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
      />
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${
            state.page + 1
          }&limit=20&withVisits=true&${queryString}`;
          getData(query);
        }}
        filterable={false}
        noDataText="Keine Empfehlungen"
        minRows={5}
        defaultFiltered={[
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
}

export default EmployeesOrders;
