import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { V3 } from "../../lib/api";
import { COMPANIES_LOADING, DELETE_COMPANY } from "../../redux/types";
import { useSnackbar } from "notistack";
function Delete({ companyId }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch({
      type: COMPANIES_LOADING,
    });
    V3.delete(`/companies/${companyId}`)
      .then((res) => {
        dispatch({
          type: DELETE_COMPANY,
          payload: companyId,
        });
        enqueueSnackbar("Company deleted successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message || "Something went wrong", {
          variant: "error",
        });
      });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span className="action fi" onClick={handleClickOpen}>
        <DeleteIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Unternehmen löschen</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <DialogContentText>
            Are u sure u want to delete this company? Your wont be able to
            recover it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
