import React, { useState } from "react";
import Table from "../../Table";
import { V3 } from "../../../lib/api";
import Moment from "react-moment";
import { IconButton } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
function Invoices({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [invoices, setInvoices] = useState([]);

  const getInvoices = (query) => {
    setLoading(true);
    V3.get(`/invoices/me/${companyId}${query}`).then((response) => {
      setData(response.data);
      setInvoices(response.data.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "invoiceId",
      Header: "Rechnungs-Nr.",
      accessor: (d) => d.invoiceId,
      Cell: (props) => <strong>{props.original.invoiceId}</strong>,
    },
    {
      id: "createdAt",
      Header: "Datum:",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },

    {
      id: "total",
      Header: "Betrag",
      accessor: (d) => d.total,
      Cell: (props) => <strong>{props.original.total + " CHF"}</strong>,
    },
    {
      id: "url",
      Header: "Betrag",
      accessor: (d) => d.url,
      Cell: (props) => (
        <IconButton component="a" href={props.original.url} target="_blank">
          <CloudDownloadIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <Table
      data={invoices}
      columns={columns}
      loading={loading}
      page={!data?.pagination ? 0 : data?.pagination?.current - 1}
      pages={!data?.pagination ? 1 : data?.pagination?.total}
      onFetchData={(state) => {
        var queryString = state.filtered
          .map((key) => key.id + "=" + key.value)
          .join("&");
        let query = `?page=${state.page + 1}&${queryString}`;
        getInvoices(query);
      }}
      filterable={false}
      noDataText="Keine Produkte"
      minRows={5}
    />
  );
}

export default Invoices;
