import React, { useState } from "react";
import styled from "styled-components";
import { Chart } from "react-charts";
import { useEffect } from "react";
import { V3 } from "../../../../lib/api";
import { CircularProgress, Paper } from "@material-ui/core";
const LoadingClicksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 300px;
`;
const ChartWrapper = styled.div`
  position: relative;
  height: 300px;
`;
const months = [
  {
    id: "01",
    name: "Januar",
  },
  {
    id: "02",
    name: "Februar",
  },
  {
    id: "03",
    name: "März",
  },
  {
    id: "04",
    name: "April",
  },
  {
    id: "05",
    name: "Mai",
  },
  {
    id: "06",
    name: "Juni",
  },
  {
    id: "07",
    name: "Juli",
  },
  {
    id: "08",
    name: "August",
  },
  {
    id: "09",
    name: "September",
  },
  {
    id: "10",
    name: "Oktober",
  },
  {
    id: "11",
    name: "November",
  },
  {
    id: "12",
    name: "Dezember",
  },
];
var colors = [
  "#195079",
  "#DA1833",
  "#F6B500",
  "#3366E6",
  "#99FF99",
  "#B34D4D",
  "#00B3E6",
  "#E6B333",
  "#80B300",
  "#E6B3B3",
  "#6680B3",
  "#999966",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

function UsersChart({ companyId }) {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    V3.get(`/company-referrals/analytics/my/${companyId}`).then((response) => {
      setReferrals(response.data.data);
      setLoading(false);
    });
  }, [companyId]);

  const finalDataNew = referrals.map((referral, index) => {
    return {
      label: referral.title,
      color: colors[index],
      data: months.map((month) => {
        const length = referral.users.filter(
          (item) => item.createdAt.substring(5, 7) === month.id
        ).length;
        return {
          category: month.name,
          value: length,
          color: colors[index],
          index: 1,
        };
      }),
    };
  });

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.category,
      elementType: "area",
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => parseInt(datum?.value),
        stacked: false,
        elementType: "area",
      },
    ],
    []
  );

  if (loading) {
    return (
      <LoadingClicksWrapper>
        <CircularProgress size={30} />
      </LoadingClicksWrapper>
    );
  }
  return (
    <Paper>
      <div style={{ padding: 30 }}>
        <ChartWrapper>
          <Chart
            options={{
              defaultColors: colors,
              data:
                finalDataNew?.length > 0
                  ? finalDataNew
                  : [
                      {
                        label: "",
                        color: "#195079",
                        data: [{ category: "", value: 0 }],
                      },
                    ],
              primaryAxis,
              secondaryAxes,
            }}
          />
        </ChartWrapper>
      </div>
    </Paper>
  );
}

export default UsersChart;
