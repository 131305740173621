import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import MediaChoose from "../Media/Chooser";
import Button from "@material-ui/core/Button";
const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export default function FeaturedImage({ values, setFieldValue }) {
  const [selectImage, setSelectedImage] = React.useState(values.image);

  React.useEffect(() => {
    setFieldValue("image", selectImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <Paper variant="outlined" style={{ marginTop: 15 }}>
      <MediaChoose onChange={setSelectedImage}>
        <div style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}>
          {selectImage ? (
            <Preview>
              <img
                src={selectImage ? selectImage.md.url : ""}
                alt={selectImage ? selectImage.md.url : ""}
              />
            </Preview>
          ) : (
            <p style={{ textAlign: "center" }}>
              Click to select an cover image
            </p>
          )}
        </div>
      </MediaChoose>
      {selectImage && (
        <div style={{ padding: 15 }}>
          <Button
            onClick={(e) => setSelectedImage(null)}
            fullWidth
            variant="outlined"
          >
            Aktuelles Bild entfernen
          </Button>
        </div>
      )}
    </Paper>
  );
}
