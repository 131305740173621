import { combineReducers } from "redux";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import candidateReducer from "./candidateReducer";
import jobReducer from "./jobReducer";
import usersReducer from "./usersReducer";
import applicationReducer from "./applicationReducer";
import globalReducer from "./globalReducer";
import recruiterReducer from "./recruiterReducer";
import statusReducer from "./statusReducer";
import activityReducer from "./activityReducer";
import searchReducer from "./searchReducer";
import productReducer from "./productReducer";
import teamReducer from "./teamReducer";
import uploadReducer from "./uploadReducer";
import crawledJobReducer from "./crawledJobReducer";
import jobVisitsReducer from "./jobVisitsReducer";
import externalClickReducer from "./externalClickReducer";
import bonusReducer from "./BonusReducer";
import interestedReducer from "./interestedReducer";
import ContactingReducer from "./contactingReducer";

import { DESTROY_SESSION } from "../types";

// Combine all reducers.
const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  company: companyReducer,
  candidate: candidateReducer,
  application: applicationReducer,
  job: jobReducer,
  global: globalReducer,
  recruiter: recruiterReducer,
  status: statusReducer,
  activity: activityReducer,
  search: searchReducer,
  product: productReducer,
  team: teamReducer,
  UploadFile: uploadReducer,
  crawledJob: crawledJobReducer,
  jobVisit: jobVisitsReducer,
  externalClick: externalClickReducer,
  bonus: bonusReducer,
  interest: interestedReducer,
  contacting: ContactingReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
