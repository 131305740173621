import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { ADD_BONUS } from "../../redux/types";
import { Alert } from "@material-ui/lab";
import Loader from "../GlobalLoader";
function Add({ data }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values) => {
    V3.post(`/bonuses/${data._id}/add`, values)
      .then((res) => {
        dispatch({
          type: ADD_BONUS,
          payload: res.data.data,
        });
        setOpen(false);
        enqueueSnackbar("Bonus created successfully", {
          variant: "success",
        });
      })

      .catch((err) => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        setMessage(err?.response?.data?.message);
      });
  };
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        Add
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Bonus</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Required"),
            amount: Yup.number().min(1).max(100).required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent style={{ minWidth: 500 }}>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="amount"
                      label="Amount"
                      name="amount"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus type="submit">
                  Add
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Add;
