import React from "react";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Header from "../Modal/Header";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Moment from "react-moment";
import { V3 } from "../../lib/api";
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #f4f4f4;
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }
  }
`;

const Sidebar = styled.div`
  display: flex;
  width: 30%;
  border-left: 1px solid ${(props) => props.theme.palette.divider};
  flex-direction: column;
`;
export default function View({ open, setOpen, data, setMedia, media }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    V3.delete(`/medias/${data._id}`).then((res) => {
      window.location.reload();
    });
  };
  if (!data) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      style={{ margin: 30, borderRadius: 5, overflow: "hidden" }}
    >
      <Wrapper>
        <ImageWrapper>
          <LazyLoadImage
            alt={data.title}
            src={data.original.url}
            effect="blur"
          />
        </ImageWrapper>

        <Sidebar>
          <Header title="Options">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Header>

          <div style={{ padding: 30 }}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      File Name
                    </TableCell>
                    <TableCell align="right">{data.title}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      File Type
                    </TableCell>
                    <TableCell align="right">{data.fileType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      File Size
                    </TableCell>
                    <TableCell align="right">{data.fileSize}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Upload Date
                    </TableCell>
                    <TableCell align="right">
                      <Moment>{data.createdAt}</Moment>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Last updated
                    </TableCell>
                    <TableCell align="right">
                      <Moment>{data.updatedAt}</Moment>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>

            <p style={{ margin: 0, marginTop: 15, fontWeight: 600 }}>Sizes:</p>
            <ButtonGroup color="primary" fullWidth>
              <Button target="_blank" component="a" href={data.xs.url}>
                XS
              </Button>
              <Button target="_blank" component="a" href={data.sm.url}>
                SM
              </Button>
              <Button target="_blank" component="a" href={data.md.url}>
                MD
              </Button>
              <Button target="_blank" component="a" href={data.lg.url}>
                LG
              </Button>
              <Button target="_blank" component="a" href={data.original.url}>
                Original
              </Button>
            </ButtonGroup>

            <Button
              style={{ color: "red", marginTop: 15 }}
              variant="outlined"
              fullWidth
              onClick={handleDelete}
            >
              Löschen
            </Button>
          </div>
        </Sidebar>
      </Wrapper>
    </Dialog>
  );
}
