import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as TextFieldMui,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import * as Yup from "yup";
import KeyboardDatePicker from "../../../../Views/crm/candidates/KeyboardDatePicker";
import Editor from "../../../Editor";
import Loader from "../../../GlobalLoader";
import { V3 } from "../../../../lib/api";
import EditIcon from "@material-ui/icons/Edit";

function Add({ id, setCompany, data }) {
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState([]);

  const handleSubmit = (values, formik) => {
    const { template, ...rest } = values;
    const updateData = { ...rest, template: template?._id || null };
    V3.put(`/crm/companies/update-call/${id}/${data._id}`, updateData).then(
      (res) => {
        setCompany(res.data.data);
        formik.setSubmitting(false);
        setOpen(false);
      }
    );
  };

  useEffect(() => {
    if (open)
      V3.get(`update-call-template?limit=900`).then((res) => {
        setTemplates(res.data.data);
      });
  }, [open]);
  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditIcon />
      </span>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Update Call</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            notes: Yup.string(),
            date: Yup.date().nullable().required("Required"),
            nextCall: Yup.date().nullable().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            notes: data.notes,
            date: data.date,
            nextCall: data.nextCall,
            template: data?.template,
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                <Field
                  component={KeyboardDatePicker}
                  name="date"
                  id="date"
                  label="Date"
                  inputVariant="outlined"
                  fullWidth
                  format="dd.MM.yyyy"
                  autoOk
                  disableToolbar
                  style={{ marginTop: 0 }}
                />
                <Field
                  component={KeyboardDatePicker}
                  name="nextCall"
                  id="Next Call"
                  label="Next Call"
                  inputVariant="outlined"
                  fullWidth
                  format="dd.MM.yyyy"
                  autoOk
                  disableToolbar
                  style={{ marginBottom: 24 }}
                />
                <Field
                  component={Autocomplete}
                  options={templates}
                  value={values.template}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, val) => {
                    setFieldValue("template", val);
                  }}
                  fullWidth
                  style={{ marginBottom: 24 }}
                  renderInput={(params) => (
                    <TextFieldMui
                      {...params}
                      variant="outlined"
                      label="Template"
                    />
                  )}
                />
                <Editor
                  value={values.notes}
                  onChange={(value) => setFieldValue("notes", value)}
                />
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                  <Button color="primary" variant="outlined" type="submit">
                    Update
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Add;
