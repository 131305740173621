import React, { useState, useEffect } from "react";
import Loader from "../../components/GlobalLoader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Card from "../../components/Card";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
function Platform() {
  const [data, setData] = useState(null);
  const [message, setMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    V3.get(`/settings`).then((res) => {
      setData(res.data.data);
    });
  }, []);
  const _handleSubmit = (values, formikAPi) => {
    setMessage(null);
    V3.put(`/settings`, values)
      .then((res) => {
        formikAPi.setSubmitting(false);
        setData(res.data);
        enqueueSnackbar("Settings updated successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        setMessage(err?.response?.date?.message);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

  if (!data) {
    return <Loader />;
  }
  return (
    <Formik
      validationSchema={Yup.object().shape({
        totalRecruiters: Yup.number().nullable(),
        impressionsRate: Yup.number().nullable().required("Required"),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        totalRecruiters: data?.totalRecruiters || 0,
        impressionsRate: data.impressionsRate,
      }}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          {isSubmitting && <Loader />}
          <Container style={{ maxWidth: 1024 }}>
            {message && (
              <Alert severity="error" style={{ marginBottom: 15 }}>
                {message}
              </Alert>
            )}
            <Card title="Companies" contentStyles={{ padding: 15 }}>
              <Field
                component={TextField}
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Impressions Rate"
                style={{ margin: 0 }}
                name="impressionsRate"
              />
            </Card>

            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 15 }}
              fullWidth
              disabled={!dirty}
              type="submit"
            >
              Aktualisieren
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  );
}

export default Platform;
