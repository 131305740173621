import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table";
import { Link } from "react-router-dom";
import LinkIcon from "../../Icons/link";
import Facebook from "../../Icons/facebook";
import Linkedin from "../../Icons/linkedin";
import Twitter from "../../Icons/twitter";
import Whatsapp from "../../Icons/whatsapp";
import Xing from "../../Icons/xing";
import Mail from "../../Icons/mail";
import Two from "../../Icons/Two";
import { useTheme } from "@material-ui/core";
import { V3 } from "../../../lib/api";
import { GET_RECRUITERS, SET_RECRUITER_LOADING } from "../../../redux/types";

const Links = () => {
  const theme = useTheme();
  const { recruiters, data, loading } = useSelector((state) => state.recruiter);
  const [filters, setFilters] = useState({ filtered: [], page: 0 });

  const dispatch = useDispatch();

  useEffect(() => {
    var queryString = filters.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filters.page + 1}&${queryString}`;
    dispatch({
      type: SET_RECRUITER_LOADING,
      payload: true,
    });
    V3.get(`/recruiters/analytics${query}`).then((res) => {
      dispatch({
        type: GET_RECRUITERS,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const calculateData = (link) => {
    let sharedLinksPoints = 0;
    let facebook = 0;
    let twitter = 0;
    let whatsapp = 0;
    let linkedin = 0;
    let xing = 0;
    let mail = 0;

    let total = 0;
    let points = 0;
    let companyPoints = 0;
    let externalClicks = 0;

    // eslint-disable-next-line array-callback-return
    link.sharedLinks.map((el) => {
      if (
        el.facebook !== true &&
        el.twitter !== true &&
        el.xing !== true &&
        el.whatsapp !== true &&
        el.mail !== true &&
        el.linkedin !== true
      ) {
        sharedLinksPoints += el.points;
        total += el.points;
      }
      if (el.forCompany) {
        companyPoints += el.points;
      } else {
        points += el.points;
      }
      if (el.facebook) {
        facebook += el.points;
        total += el.points;
      }
      if (el.twitter) {
        twitter += el.points;
        total += el.points;
      }
      if (el.xing) {
        xing += el.points;
        total += el.points;
      }
      if (el.whatsapp) {
        whatsapp += el.points;
        total += el.points;
      }
      if (el.mail) {
        mail += el.points;
        total += el.points;
      }
      if (el.linkedin) {
        linkedin += el.points;
        total += el.points;
      }
    });

    if (link?.externalClick?.length > 0) {
      // eslint-disable-next-line array-callback-return
      link.externalClick.map((el) => {
        externalClicks = externalClicks + el.points || 2;
        points = points + el.points || 2;
      });
    }

    return {
      sharedLinksPoints,
      facebook,
      twitter,
      whatsapp,
      linkedin,
      xing,
      mail,
      total,
      points,
      companyPoints,
      externalClicks,
    };
  };
  const columns = [
    {
      id: "recruiter",
      Header: "Recruiter",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>
          <Link to={`/recruiter/${original ? original._id : ""}`}>
            {original && original.user
              ? original.user.name
                ? original.user.profile.name
                : `${original.user.profile.firstname || ""} ${
                    original.user.profile.lastname || ""
                  }`
              : "User Deleted"}{" "}
          </Link>
        </strong>
      ),
    },
    {
      id: "points",
      Header: <LinkIcon />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).sharedLinksPoints}</strong>
      ),
    },
    {
      id: "facebook",
      Header: <Facebook />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).facebook}</strong>
      ),
    },
    {
      id: "twitter",
      Header: <Twitter />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).twitter}</strong>
      ),
    },
    {
      id: "linkedin",
      Header: <Linkedin />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).linkedin}</strong>
      ),
    },
    {
      id: "xing",
      Header: <Xing />,
      accessor: (d) => d._id,
      Cell: ({ original }) => <strong>{calculateData(original).xing}</strong>,
    },
    {
      id: "whatsapp",
      Header: <Whatsapp />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).whatsapp}</strong>
      ),
    },
    {
      id: "mail",
      Header: <Mail />,
      accessor: (d) => d._id,
      Cell: ({ original }) => <strong>{calculateData(original).mail}</strong>,
    },
    {
      id: "total",
      Header: "Klicks",
      accessor: (d) => d._id,
      Cell: ({ original }) => <strong>{calculateData(original).total}</strong>,
    },
    {
      id: "externalClicks",
      Header: "Weiterleitungen",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).externalClicks}</strong>
      ),
    },
    {
      id: "points",
      Header: <Two height={20} />,
      accessor: (d) => d._id,
      Cell: ({ original }) => <strong>{calculateData(original).points}</strong>,
    },
    {
      id: "companyPoints",
      Header: <Two height={20} color={theme.palette.primary.main} />,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>{calculateData(original).companyPoints}</strong>
      ),
    },
  ];

  return (
    <>
      <Table
        data={recruiters}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setFilters(state);
        }}
        noDataText="No shared links"
      />
    </>
  );
};

export default Links;
