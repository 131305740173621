/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardDatePicker from "../../crm/candidates/KeyboardDatePicker";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { Select } from "material-ui-formik-components/Select";
import { Gender } from "../../../data";
import Card from "../../../components/Card";
import DaysOf from "../../../components/User/DaysOf/index";
import { V3 } from "../../../lib/api";
import TagsInput from "../../../components/Job/TagInput";
import SickDays from "../../../components/User/SickDays";
import UserTimeReports from "../../../components/User/UserTimeReports";
import SelectRoutes from "../../../components/RoutesTemplate/SelectRoutes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GET_USER, UPDATE_USER, USERS_LOADING } from "../../../redux/types";
import { useSnackbar } from "notistack";
import Loader from "../../../components/GlobalLoader";
import Editor from "../../../components/Editor";
import UserFiles from "../../../components/User/UserFiles";
const EditUser = () => {
  const [routesTemplates, setRoutesTemplates] = useState([]);
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const { user, loading } = useSelector((state) => state.users);

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();

  const queryParameters = new URLSearchParams(search);
  const fromTeam = queryParameters.get("fromTeam");

  const { id } = useParams();
  useEffect(() => {
    dispatch({
      type: USERS_LOADING,
    });
    V3.get(`/users/${id}`).then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    V3.get(`routes-template?limit=1000`).then((res) => {
      setRoutesTemplates(
        res.data.data.map((el) => {
          return {
            key: el._id,
            value: el._id,
            label: el.title,
            routes: el?.routes,
          };
        })
      );
    });
  }, []);

  const _handleSubmit = (values) => {
    const {
      firstname,
      lastname,
      gender,
      userFunction,
      routesTemplate,
      files,
      ...rest
    } = values;
    const data = {
      ...rest,
      name: firstname + " " + lastname,
      firstname,
      lastname,
      gender: gender && gender.label,
      function: userFunction,
      routesTemplate: routesTemplate ? routesTemplate?.key : null,
    };

    V3.put(`/users/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: id,
        });
        enqueueSnackbar("User updated successfully", {
          variant: "success",
        });

        if (fromTeam) {
          push("/team");
        } else push("/users");
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
      });
  };

  const handlePassword = (values) => {
    const { password, confirmPassword } = values;

    const data = {
      firstname: user?.profile?.firstname,
      lastname: user?.profile?.lastname,
      name: `${user?.profile?.firstname} ${user?.profile?.lastname}`,
      gender: user?.profile?.gender,
      phone: user?.profile?.phone,
      linkedIn: user?.profile?.linkedIn,
      street: user?.profile?.street,
      zip: user?.profile?.zip,
      birthday: user?.profile?.birthday,
      password,
      confirm: confirmPassword,
    };

    V3.put(`/users/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: id,
        });
        enqueueSnackbar("Password updated successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  const handleRoleChange = (role) => {
    V3.put(`/users/${user._id}/role`, { role: role }).then((res) => {});
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      {user && (
        <Formik
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string().required("Required"),
            workingPercentage: Yup.number().min(0).max(100),
            bankNumber: Yup.string(),
          })}
          onSubmit={_handleSubmit}
          enableReinitialize={true}
          initialValues={{
            firstname: !user.profile ? "" : user.profile.firstname,
            lastname: !user.profile ? "" : user.profile.lastname,
            gender: {
              key: !user.profile ? "" : user.profile.gender,
              label: !user.profile ? "" : user.profile.gender,
              value: !user.profile ? "" : user.profile.gender,
            },
            birthday: !user.profile ? "" : user.profile.birthday,
            phone: !user.profile ? "" : user.profile.phone,
            email: user.email,
            linkedIn: !user.profile ? "" : user.profile.linkedIn,
            role: !user ? "" : user.role,
            bankNumber: !user ? "" : user.bankNumber,
            street: !user ? "" : user.street,
            zip: !user ? "" : user.zip,
            ahv: !user ? "" : user.ahv,
            workingPercentage: !user ? 0 : user.workingPercentage,
            startedWorking: user?.startedWorking || null,
            borrowedObjects: user?.borrowedObjects,
            notes: user?.notes || "",
            routes: user?.routes || [],
            files: user?.files || [],
            routesTemplate: user?.routesTemplate
              ? {
                  key: user?.routesTemplate?._id,
                  label: user?.routesTemplate?.title,
                  value: user?.routesTemplate?._id,
                }
              : null,
          }}
        >
          {({ submitForm, dirty, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Paper style={{ padding: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="firstname"
                          label="Vorname"
                          name="firstname"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="lastname"
                          label="Nachname"
                          name="lastname"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="gender"
                          options={Gender}
                          textFieldProps={{
                            label: "Geschlecht",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="birthday"
                          component={KeyboardDatePicker}
                          label="Geburtsdatum"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          placeholder="15/04/2020"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="phone"
                          label="Tel."
                          name="phone"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="linkedIn"
                          label="LinkedIn"
                          name="linkedIn"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="bankNumber"
                          label="Bank Nummer"
                          name="bankNumber"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="ahv"
                          label="AHV"
                          name="ahv"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TagsInput
                          selectedItem={values?.borrowedObjects}
                          setSelectedItem={(value) => {
                            setFieldValue("borrowedObjects", value);
                          }}
                          fullWidth
                          variant="outlined"
                          id="borrowedObjects"
                          name="borrowedObjects"
                          label="Geliehene Gegenstände"
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="street"
                          label="Strasse"
                          name="street"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="zip"
                          label="PLZ"
                          name="zip"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Field
                          component={Select}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Role"
                          name="role"
                          style={{ margin: 0 }}
                          onChange={(e) => {
                            setFieldValue("role", e.target.value);
                            handleRoleChange(e.target.value);
                          }}
                          options={[
                            {
                              key: "admin",
                              value: "admin",
                              label: "Admin",
                            },
                            {
                              key: "employee",
                              value: "employee",
                              label: "Employee",
                            },
                            {
                              key: "editor",
                              value: "editor",
                              label: "Editor",
                            },
                            {
                              key: "recruiter",
                              value: "recruiter",
                              label: "Recruiter",
                            },
                            {
                              key: "company",
                              value: "company",
                              label: "Company",
                            },
                          ]}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="email"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Editor
                          value={values.notes}
                          onChange={(val) => setFieldValue("notes", val)}
                        />
                      </Grid>
                      {(authUser?.email == "admin@test.com" ||
                        authUser?.email == "kristian.oroshi@two.jobs" ||
                        authUser?.email == "laila.crivelli@two.jobs" ||
                        authUser?.email == "sandro.trovato@two.jobs") && (
                        <Grid item xs={12} md={12}>
                          <UserFiles
                            files={values.files}
                            user={user._id}
                            setFiles={(val) => setFieldValue("files", val)}
                          />
                        </Grid>
                      )}

                      {(user?.role == "admin" ||
                        user?.role == "editor" ||
                        user?.role == "employee") &&
                        (authUser?.email == "admin@test.com" ||
                          authUser?.email == "kristian.oroshi@two.jobs") && (
                          <>
                            <Grid item xs={12}>
                              <Field
                                component={Autocomplete}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Role"
                                name="routesTemplate"
                                onChange={(e, val) => {
                                  setFieldValue("routesTemplate", val);
                                  setFieldValue("routes", val?.routes);
                                }}
                                style={{ margin: 0 }}
                                options={routesTemplates}
                                textFieldProps={{
                                  label: "Template",
                                  variant: "outlined",
                                  style: { margin: 0, marginBottom: 15 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SelectRoutes
                                routes={values.routes}
                                setRoutes={(val) =>
                                  setFieldValue("routes", val)
                                }
                              />
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={!dirty}
                      onClick={submitForm}
                      style={{ padding: "16px 32px" }}
                    >
                      Änderungen speichern
                    </Button>
                  </Paper>

                  <Paper>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th">Registriert am</TableCell>
                          <TableCell align="right">
                            <Moment>{user.createdAt}</Moment>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Letztes Update</TableCell>
                          <TableCell align="right">
                            <Moment>{user.updatedAt}</Moment>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Old User</TableCell>
                          <TableCell align="right">
                            {user.oldUser ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Rolle</TableCell>
                          <TableCell align="right">
                            <span style={{ textTransform: "uppercase" }}>
                              {user.role}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {authUser &&
                    (authUser.email == "kristian.oroshi@two.jobs" ||
                      authUser.email == "laila.crivelli@two.jobs" ||
                      authUser.email == "admin@test.com") ? (
                      <div style={{ padding: 15 }}>
                        <Field
                          name="startedWorking"
                          component={KeyboardDatePicker}
                          label="Started Working"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          style={{ margin: 0 }}
                        />

                        <Field
                          component={TextField}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="workingPercentage"
                          label="Working Percentage"
                          name="workingPercentage"
                          style={{ margin: 0, marginTop: 15 }}
                        />
                      </div>
                    ) : (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th">
                              Started Working
                            </TableCell>
                            <TableCell align="right">
                              <Moment>{user.startedWorking}</Moment>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th">
                              Working Percentage
                            </TableCell>
                            <TableCell align="right">
                              <span>{user.workingPercentage}</span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          {user &&
            (user.role == "admin" ||
              user.role == "employee" ||
              user.role == "editor") && <DaysOf />}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          {user &&
            (user.role == "admin" ||
              user.role == "employee" ||
              user.role == "editor") && <UserTimeReports id={user?._id} />}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          {user &&
            (user.role == "admin" ||
              user.role == "employee" ||
              user.role == "editor") && <SickDays />}
        </Grid>
      </Grid>

      {user && !["admin", "editor", "employee"].includes(user?.role) && (
        <Formik
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Password is required")
              .min(8, "Minimum 8 characters"),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "Passwords must match"
            ),
          })}
          onSubmit={handlePassword}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
        >
          {({ dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Card
                    title="Passwort wechseln"
                    style={{ marginTop: 15 }}
                    contentStyles={{ padding: 15 }}
                  >
                    <Field
                      component={TextField}
                      type="password"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Passwort"
                      name="password"
                    />
                    <Field
                      component={TextField}
                      type="password"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Passwort bestätigen"
                      name="confirmPassword"
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!dirty}
                      style={{ padding: "16px 32px", marginTop: 15 }}
                    >
                      Passwort wechseln
                    </Button>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default EditUser;
