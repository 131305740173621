import Table from "../../../Table";
import React, { useState } from "react";
import { V3 } from "../../../../lib/api";

function RegisteredUsers({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);

  const getData = (query) => {
    setLoading(true);
    V3.get(`/company-referrals/${companyId}/users${query}`).then((response) => {
      setData(response.data);
      setUsers(response.data.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.profile?.name
            ? props.original.profile?.name
            : props.original.profile?.firstname +
              " " +
              props.original.profile?.lastname}
        </strong>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original.email || "-"}
        </span>
      ),
    },
  ];

  return (
    <>
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${
            state.page + 1
          }&limit=10&withVisits=true&${queryString}`;
          getData(query);
        }}
        filterable={false}
        noDataText="Keine Empfehlungen"
        minRows={5}
      />
    </>
  );
}

export default RegisteredUsers;
