import React, { useState } from "react";

import { V3 } from "../../../lib/api";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { Link } from "react-router-dom";

import TableComponent from "../../Table";
import { IconButton } from "@material-ui/core";
import Documents from "./Documents";

function VideoApplies({ company }) {
  const [data, setData] = useState({});
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      id: "candidate",
      Header: "Kandidat",
      accessor: (d) => d.candidate,
      Cell: (props) => (
        <strong>
          {props.original?.firstName || "-"} {props.original?.lastName || "-"}
        </strong>
      ),
    },

    {
      id: "job",
      Header: "Stelle",
      accessor: (d) => d.job,
      Cell: (props) => (
        <Link to={`/jobs/edit/${props.original?.job?._id}`}>
          <strong>{props.original?.job?.title || "-"}</strong>
        </Link>
      ),
    },
    {
      id: "videoApplyUrl",
      Header: "Video",
      accessor: (d) => d.videoApplyUrl,
      Cell: (props) => (
        <IconButton
          component={"a"}
          target="_blank"
          variant="outlined"
          color="primary"
          href={props.original?.videoApplyUrl}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },

    {
      id: "fiels",
      Header: "Dokumente",
      accessor: (d) => d.total,
      Cell: (props) => (
        <div className="actions">
          <Documents files={props.original.files} />
        </div>
      ),
      filterable: false,
    },
  ];

  return (
    <>
      <TableComponent
        data={applications}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);
          V3.get(`video-apply/my/${company?._id}${query}`).then((res) => {
            setData(res.data);
            setApplications(res.data.data);
            setLoading(false);
          });
        }}
        noDataText="No jobs"
        filterable={false}
      />
    </>
  );
}

export default VideoApplies;
