import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../lib/api";
import Loader from "../GlobalLoader";

function NotifyRecruiters({ itemClassName, iconClassName }) {
  const [open, setOpen] = useState(false);
  const handleSubmit = (values, formik) => {
    V3.post("/recruiters/notify", values)
      .then(() => {
        formik.setSubmitting(false);
        setOpen(false);
      })
      .catch((err) => {});
  };
  return (
    <>
      <ListItem
        className={itemClassName}
        button
        exact={true}
        onClick={() => setOpen(true)}
      >
        <ListItemIcon className={iconClassName}></ListItemIcon>
        <ListItemText primary="Notify Recruiters" />
      </ListItem>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Send Notification to all recruiters</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Required"),
            title: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent style={{ minWidth: 500 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="title"
                  name="title"
                  style={{ margin: 0, marginBottom: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  style={{ margin: 0 }}
                  multiline
                  rows={6}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus type="submit">
                  Add
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default NotifyRecruiters;
