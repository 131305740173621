import React, { useState, useEffect } from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import { Tooltip, Button, Grid, Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";

const Title = styled(Grid)`
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    justify-content: center;
    margin-bottom: 30px;
  }
`;

function Jobs() {
  const [week, setWeek] = useState(moment(new Date()).week());
  const [filters, setFilters] = useState({ page: 0, filtered: [] });

  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [allContacting, setAllContacting] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    var queryString = filters.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filters.page + 1}&week=${week}&${queryString}`;
    setLoading(true);

    V3.get(`/linkedin-accounts${query}`).then((res) => {
      setData(res.data);
      setAccounts(res.data.data);
      setAllContacting(res.data.allToContact);
      setLoading(false);
    });
  }, [filters, week]);

  let weeks = [];
  for (let index = 1; index < 54; index++) {
    weeks.push({ key: `${index}`, label: `${index}`, value: `${index}` });
  }

  const columns = [
    {
      id: "accountNumber",
      Header: "Account Number",
      accessor: (d) => d.accountNumber,
      Cell: (props) => <strong>{props.original.accountNumber}</strong>,
    },
    {
      id: "accountName",
      Header: "Account Name",
      accessor: (d) => d.accountName,
      Cell: (props) => <strong>{props.original.accountName}</strong>,
    },
    {
      id: "vpn",
      Header: "VPN",
      accessor: (d) => d.vpn,
      Cell: (props) => <strong>{props.original.vpn}</strong>,
    },
    {
      id: "email",
      Header: "E-mail",
      accessor: (d) => d.email,
      Cell: (props) => (
        <strong style={{ textTransform: "none" }}>
          {props.original.email}
        </strong>
      ),
    },
    {
      id: "password",
      Header: "Password",
      accessor: (d) => d.password,
      Cell: (props) => (
        <strong style={{ textTransform: "none" }}>
          {props.original.password}
        </strong>
      ),
    },

    {
      id: "toContact",
      Header: "To Contact",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => (
        <strong>
          {props.original?.toContact ? props.original.toContact : "-"}
        </strong>
      ),
    },
    {
      id: "recruiters",
      Header: "Recruiters",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => (
        <>
          {props.original?.recruiters &&
          props.original?.recruiters?.length > 0 ? (
            <strong>
              {props.original?.recruiters.map(
                (el) => `${el?.recruiter?.profile?.name},`
              )}
            </strong>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit Account">
            <span>
              <Link to={`/linkedin/edit/${props.original._id}`}>
                <EditIcon />
              </Link>
            </span>
          </Tooltip>
          <Tooltip title="Delete Account">
            <span>
              <DeleteIcon onClick={() => handleDelete(props.original)} />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];

  const confirm = useConfirm();
  const handleDelete = (info) => {
    confirm({
      title: `Delete ${info.accountName}`,
      description: `Are you sure you wont to delete ${info.accountName}`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/linkedin-accounts/${info._id}`)
        .then((res) => {
          setAccounts(accounts.filter((el) => el._id !== info._id));
          enqueueSnackbar("Account deleted successfully", {
            variant: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar(
            err?.response?.data?.error || "Something went wrong",
            {
              variant: "error",
            }
          );
          setLoading(false);
        });
    });
  };
  return (
    <React.Fragment>
      <Header title="LinkedIn Accounts" size={data.total}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Header title="To Contact" size={100}></Header> */}
            <Title items xs={12} md={12}>
              <h1>To Contact</h1>

              <Chip label={allContacting} style={{ marginLeft: 15 }} />
            </Title>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Autocomplete
              onChange={(event, value) => setWeek(value ? value?.key : "")}
              style={{ width: "100%" }}
              options={weeks}
              value={week ? weeks.find((w) => w?.key === week) : null}
              getOptionLabel={(option) => option.key}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              component={Link}
              color="primary"
              variant="contained"
              to="/linkedin/add"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Header>

      <Table
        data={accounts}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setFilters(state);
        }}
        noDataText="No Accounts"
        filterable={false}
      />
    </React.Fragment>
  );
}

export default Jobs;
