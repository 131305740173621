import React, { useEffect, useState } from "react";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import { Button, Grid, TextField as MUITextField } from "@material-ui/core";
import { V3 } from "../../../lib/api";
import TagsInput from "../../Job/TagInput";
import Loader from "../../Loader";
import { useSnackbar } from "notistack";

function SendCrawlerEmailTo(props) {
  const [members, setMembers] = React.useState([]);
  const [emailsForCrawler, setEmailsForCrawler] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { companyData } = props;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (companyData) {
      V3.get(`/users?company=${companyData?._id}&limit=400`).then(
        (response) => {
          const usersData = response?.data?.data?.map((user) => {
            return {
              key: user?._id,
              value: user?.profile.name,
              label: user?.profile.name,
            };
          });

          setUsers(usersData);
        }
      );
      setEmailsForCrawler(companyData.emailsForCrawler);
    }
  }, [companyData]);

  useEffect(() => {
    const usersData = companyData?.sendCrawlerEmailTo?.map((user) => {
      return {
        key: user?._id,
        value: user?.profile?.name,
        label: user?.profile?.name,
      };
    });

    setMembers(usersData || []);
  }, [companyData]);

  const onMembersChange = (event, value) => {
    setMembers(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    V3.put(`/companies/${companyData._id}/crawler-mail`, {
      sendCrawlerEmailTo: members.map((el) => el.key),
      emailsForCrawler,
    })
      .then((res) => {
        props.setCompany({
          ...companyData,
          sendCrawlerEmailTo: res?.data?.data?.sendCrawlerEmailTo,
          emailsForCrawler: res?.data?.data?.emailsForCrawler,
        });
        setLoading(false);
        enqueueSnackbar("Company updated", { variant: "success" });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Some thing went wrong", { variant: "success" });
      });
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginBottom: 15,
      }}
    >
      <Grid item xs={12} md={4}>
        {loading && <Loader />}
        <MUIAutocomplete
          multiple
          options={users}
          getOptionLabel={(option) => option.label}
          onChange={onMembersChange}
          value={members}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <MUITextField
              {...params}
              placeholder="Select"
              variant="outlined"
              label="Send Email To"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TagsInput
          selectedItem={emailsForCrawler}
          tags={emailsForCrawler}
          setSelectedItem={(data) => setEmailsForCrawler(data)}
          fullWidth
          variant="outlined"
          id="emailsForCrawler"
          name="emailsForCrawler"
          placeholder="hinzufügen"
          label="Crawler emails"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          fullWidth
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}

export default SendCrawlerEmailTo;
