import React, { useEffect, useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Header from "../../components/Page/Header";
import { useParams } from "react-router-dom";
import Loader from "../../components/GlobalLoader";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

function New() {
  const { id } = useParams();
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, formApi) => {
    V3.put(`/linkedin-accounts/${id}`, values)
      .then((res) => {
        setAccount(res.data.data);
        enqueueSnackbar("Account updated successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated Account", {
          variant: "error",
        });
        formApi.setSubmitting(false);
        setMessage(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    V3.get(`/linkedin-accounts/${id}`).then((res) => {
      setLoading(false);
      setAccount(res.data.data);
    });
  }, [id]);

  if (!account) {
    return <Loader />;
  }

  return (
    <>
      <Header title="Edit LinkedIn Accounts"></Header>
      <Formik
        validationSchema={Yup.object().shape({
          accountName: Yup.string().required("Required"),
          vpn: Yup.string().required("Required"),
          password: Yup.string().required("Required"),
          notes: Yup.string().required("Required"),
          email: Yup.string()
            .email("Must be a valid Email")
            .required("Required"),
          accountNumber: Yup.number().required("Required"),
        })}
        onSubmit={handleSubmit}
        initialValues={{
          accountName: account.accountName,
          vpn: account.vpn,
          password: account.password,
          notes: account.notes,
          email: account.email,
          accountNumber: account.accountNumber,
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            {(isSubmitting || loading) && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <Paper style={{ padding: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="accountNumber"
                        label="Account Number"
                        name="accountNumber"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="accountName"
                        label="Account Name"
                        name="accountName"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="vpn"
                        label="VPN"
                        name="vpn"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="notes"
                        label="Notes"
                        name="notes"
                        multiline
                        rows={6}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!dirty}
                    fullWidth
                  >
                    Update
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default New;
