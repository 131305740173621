import React, { useEffect, useState } from "react";
import Head from "../../components/Analytics/Head";
import Grid from "@material-ui/core/Grid";
import Sales from "../../components/Team/Sales";
import Offers from "../../components/Team/Offers";
import Appointments from "../../components/Team/Appointments";
import Documents from "../../components/Team/Documents";
import { V3 } from "../../lib/api";
function TeamMember(props) {
  const [user, setUser] = useState(null);
  useEffect(() => {
    V3.get(`/users/${props.match.params.id}`).then((res) => {
      setUser(res.data.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head user={user} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Sales user={user && user._id} />
        <Offers user={user && user._id} />

        <Grid container style={{ margin: 0 }}>
          <Grid item xs={12} md={12} style={{ marginBottom: 15 }}>
            <Appointments user={user && user._id} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Documents user={user && user._id} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TeamMember;
