import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Card from "../Card";
import moment from "moment";
import { V3 } from "../../lib/api";

import ChartTooltip from "../Chart/Tooltip";
import usePrice from "../../hooks/usePrice";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";

function Offers() {
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().startOf("year"));
  const [endDate, setEndDate] = React.useState(moment(new Date()));
  function formatXAxis(tickItem) {
    return moment(tickItem).format("D.MM.YYYY");
  }

  const fetchData = (start, end) => {
    V3.get(
      `/crm/companies/offers?start=${moment(start).format(
        "DD/MM/YYYY"
      )}&end=${moment(end).format("DD/MM/YYYY")}`
    ).then((res) => {
      setData(res.data.offers);
    });
  };

  React.useEffect(() => {
    fetchData(moment(startDate), moment(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  const totalSales = usePrice(data && data.reduce((a, b) => +a + +b.amount, 0));
  return (
    <Card
      title="Offerten"
      style={{ height: "350px", marginTop: 15 }}
      button={
        <>
          <span style={{ marginRight: 15, fontSize: 16 }}>
            <strong>Total:</strong> {totalSales} CHF
          </span>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </>
      }
    >
      {data.length > 0 ? (
        <div
          style={{
            width: "calc(100% + 20px)",
            height: 300,
            marginLeft: -15,
            marginBottom: -15,
            padding: 15,
          }}
        >
          <ResponsiveContainer>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip content={<ChartTooltip user />} />
              <Line type="monotone" dataKey="amount" stroke="#DA1A32" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          style={{
            padding: 15,
            height: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: -15,
          }}
        >
          Keine Daten für diesen Zeitraum
        </div>
      )}
    </Card>
  );
}

export default Offers;
