/* eslint-disable array-callback-return */
import {
  GET_SEARCH,
  SEARCH_LOADING,
  SET_TAGS,
  UPDATE_CANDIDATES,
  SET_SEARCH,
} from "../types";

const initialState = {
  searchData: [],
  data: [],
  loading: false,
  tags: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        searchData: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_SEARCH:
      return {
        ...state,
        searchData: [...state.searchData, ...action.payload.data],
        data: action.payload,
        loading: false,
      };

    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case UPDATE_CANDIDATES:
      const searchData = [...state.searchData];
      action.payload.map((el) => {
        searchData.map((candidate, index) => {
          if (el._id === candidate._id) {
            searchData[index] = el;
          }
        });
      });

      return {
        ...state,
        searchData,
      };
    default:
      return state;
  }
}
