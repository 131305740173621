import React, { useEffect, useState } from "react";
import { V3 } from "../../../lib/api";
import Services from "../Widgets/Services";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Loader from "../../GlobalLoader";

import UpdateCalls from "../../Crm/Companies/UpdateCalls";

import { Cell, Pie, PieChart } from "recharts";
import Chart from "../Chart";
import ExternalClicks from "../Widgets/ExternalClicks";
import Impressions from "../Widgets/Impressions";
import Filters from "../Widgets/filters";

const ChartWrapper = styled.div`
  padding: 30px 0px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;

  h2 {
    color: #1d2939;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
  }
  p {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
  }
`;

function Dashboard({ company, startDate, setStartDate, endDate, setEndDate }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    V3.get(`/companies/${company?._id}/analytics`, {
      params: {
        startDate: startDate.format("DD/MM/YYYY"),
        endDate: endDate.format("DD/MM/YYYY"),
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [startDate, endDate, company]);

  const COLORS = ["#195079", "#FBE7EA"];

  const chartData = [
    {
      name: "TotalVisits",
      value:
        data?.thisYearVisitsFromReferrals > 0
          ? data?.thisYearVisits
          : data?.thisYearVisits / 3,
    },
    {
      name: "TotalVisits",
      value:
        data?.thisYearVisitsFromReferrals > 0
          ? data?.thisYearVisitsFromReferrals
          : data?.thisYearVisits,
    },
  ];

  const data2 = [
    { name: "TotalVisits", value: data.totalImpressions / 3 },
    { name: "TotalVisits", value: data.totalImpressions },
  ];

  const data3 = [
    { name: "TotalVisits", value: data?.totalExternalClicks / 3 },
    { name: "TotalVisits", value: data?.totalExternalClicks },
  ];

  return (
    <>
      {loading && <Loader />}

      <Services
        subscription={data.latestSubscription}
        consultant={data.consultant}
        company={data?.company}
        activeSourcing={company?.activeSourcing}
      />
      <Filters
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
      />
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} md={12}>
          <ChartWrapper>
            <Grid
              container
              spacing={2}
              style={{
                marginBottom: 15,
              }}
            >
              <Grid item xs={12} md={4} style={{ padding: 30 }}>
                <h2
                  style={{
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  Reichweite
                </h2>
                <p
                  style={{
                    margin: 0,
                    textAlign: "center",
                    marginBottom: 20,
                  }}
                >
                  Ihrer Stelleninserate
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <PieChart width={200} height={200}>
                    <Pie
                      data={data2}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={70}
                      outerRadius={90}
                      fill="#FBE7EA"
                    >
                      {data2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                  <Impressions data={data.totalImpressions} />
                </div>
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: 30 }}>
                <h2
                  style={{
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  Gesamte Klicks{" "}
                </h2>
                <p
                  style={{
                    margin: 0,
                    textAlign: "center",
                    marginBottom: 20,
                  }}
                >
                  auf Ihren Stelleninseraten
                </p>

                <Chart
                  data={chartData}
                  total={data.thisYearVisits}
                  fromReferrals={data.thisYearVisitsFromReferrals}
                />
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: 30 }}>
                <>
                  <h2
                    style={{
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    Weiterleitung
                  </h2>
                  <p
                    style={{
                      margin: 0,
                      textAlign: "center",
                      marginBottom: 20,
                    }}
                  >
                    über two.jobs auf Ihre Jobseite
                  </p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <PieChart width={200} height={200}>
                      <Pie
                        data={data3}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="#FBE7EA"
                        // label
                      >
                        {data3.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                    <ExternalClicks
                      visits={Math.ceil(data.totalExternalClicks)}
                    />
                  </div>
                </>
              </Grid>
            </Grid>
          </ChartWrapper>
        </Grid>
      </Grid>
      <div style={{ marginTop: 15 }}>
        <UpdateCalls
          id={data?.company?.crmCompany?._id}
          companyId={data?.company?._id}
        />
      </div>
    </>
  );
}

export default Dashboard;
