import React, { useState } from "react";

import { V3 } from "../../../lib/api";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { Link } from "react-router-dom";

import TableComponent from "../../Table";
import { IconButton } from "@material-ui/core";

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  .last-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 590px) {
      flex-direction: column;
      align-content: flex-start !important;
    }
    h4 {
      font-size: 14px;
      padding: 11px 24px;
      color: #fff;
      color: ${(props) => (props.published ? "#344054" : "#fff")};
      background-color: ${(props) => (props.published ? "#fff" : "#195079")};
      border-radius: 8px;
      border: 1px solid ${(props) => (props.published ? "#D0D5DD" : "#195079")};
      font-weight: 500;
    }
  }
`;

const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 5px;
  width: max-content;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

function LinkedinApplications({ company }) {
  const [data, setData] = useState({});
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [published, setPublished] = useState(true);

  const columns = [
    {
      id: "candidate",
      Header: "Kandidat",
      accessor: (d) => d.candidate,
      Cell: (props) => (
        <strong>
          {props.original?.firstName || "-"} {props.original?.lastName || "-"}
        </strong>
      ),
    },
    {
      id: "phone",
      Header: "Tel.",
      accessor: (d) => d.candidate,
      Cell: (props) => <>{props.original?.phone || "-"}</>,
    },
    {
      id: "job",
      Header: "Stelle",
      accessor: (d) => d.job,
      Cell: (props) => (
        <Link to={`/jobs/edit/${props.original?.job?._id}`}>
          <strong>{props.original?.job?.title || "-"}</strong>
        </Link>
      ),
    },
    {
      id: "job",
      Header: "Profile Link",
      accessor: (d) => d.job,
      Cell: (props) => (
        <IconButton
          component={"a"}
          target="_blank"
          variant="outlined"
          color="primary"
          href={props.original?.linkedinProfileLink}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <HeadWrapper published={published}>
        <ButtonsWrapper>
          <Button
            onClick={() => setPublished(false)}
            variant="contained"
            className={`custom-btn ${!published ? "active" : "not-Active"}`}
            color="primary"
          >
            Archiviert
          </Button>
          <Button
            onClick={() => setPublished(true)}
            variant="contained"
            className={`custom-btn ${published ? "active" : "not-Active"}`}
            color="primary"
          >
            Nicht archiviert
          </Button>
        </ButtonsWrapper>
      </HeadWrapper>

      <TableComponent
        data={applications}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);
          V3.get(`linkedin-applications/my/${company?._id}${query}`).then(
            (res) => {
              setData(res.data);
              setApplications(res.data.data);
              setLoading(false);
            }
          );
        }}
        noDataText="No jobs"
        filterable={false}
        defaultFiltered={[
          {
            id: "published",
            value: !published,
          },
        ]}
      />
    </>
  );
}

export default LinkedinApplications;
