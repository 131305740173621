export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_USERS = "GET_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const SET_SELECTED_CANDIDATES = "SET_SELECTED_CANDIDATES";

// Comapny
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const COMPANIES_LOADING = "COMPANIES_LOADING";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const ADD_CONTACT_PERSON = "ADD_CONTACT_PERSON";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
export const ADD_COMPANY_BONUS = "ADD_COMPANY_BONUS";
export const REMOVE_COMPANY_BONUS = "REMOVE_COMPANY_BONUS";
export const SET_COMPANY_BONUSES = "SET_COMPANY_BONUSES";
export const UPDATE_CRM_COMPANY = "UPDATE_CRM_COMPANY";

// Candidate
export const GET_CANDIDATES = "GET_CANDIDATES";
export const GET_CANDIDATE = "GET_CANDIDATE";
export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const DELETE_CANDIDATE = "DELETE_CANDIDATE";
export const CANDIDATES_LOADING = "CANDIDATES_LOADING";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";
// Bonuses
export const GET_BONUSES = "GET_BONUSES";
export const GET_ALL_BONUSES = "GET_ALL_BONUSES";
export const GET_BONUS = "GET_BONUS";
export const CREATE_BONUS = "CREATE_BONUS";
export const DELETE_BONUS = "DELETE_BONUS";
export const BONUSES_LOADING = "BONUSES_LOADING";
export const UPDATE_BONUS = "UPDATE_BONUS";
export const ADD_BONUS = "ADD_BONUS";
export const REMOVE_BONUS = "REMOVE_BONUS";

// Linkedin
export const GET_LINKEDIN_ACCOUNTS = "GET_LINKEDIN_ACCOUNTS";
export const GET_ALL_LINKEDIN_ACCOUNTS = "GET_ALL_LINKEDIN_ACCOUNTS";
export const GET_LINKEDIN_ACCOUNT = "GET_LINKEDIN_ACCOUNT";
export const CREATE_LINKEDIN_ACCOUNT = "CREATE_LINKEDIN_ACCOUNT";
export const DELETE_LINKEDIN_ACCOUNT = "DELETE_LINKEDIN_ACCOUNT";
export const LINKEDIN_ACCOUNT_LOADING = "LINKEDIN_ACCOUNT_LOADING";
export const UPDATE_LINKEDIN_ACCOUNTS = "UPDATE_LINKEDIN_ACCOUNTS";

// Job
export const GET_JOBS = "GET_JOBS";
export const GET_JOB = "GET_JOB";
export const SET_JOB = "SET_JOB";
export const ADD_JOB = "ADD_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const JOBS_LOADING = "JOBS_LOADING";
export const UPDATE_JOB = "UPDATE_JOB";
export const FILTERS = "FILTERS";
export const SET_INTERESTED = "SET_INTERESTED";
export const SET_JOB_NOTES_ACTIVITIES = "SET_JOB_NOTES_ACTIVITIES";

// Crawled Job
export const GET_CRAWLED_JOBS = "GET_CRAWLED_JOBS";
export const GET_CRAWLED_JOB = "GET_CRAWLED_JOB";
export const DELETE_CRAWLED_JOB = "DELETE_CRAWLED_JOB";
export const CRAWLED_JOBS_LOADING = "CRAWLED_JOBS_LOADING";
export const UPDATE_CRAWLED_JOB = "UPDATE_CRAWLED_JOB";

//Applications

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATION = "GET_APPLICATION";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const APPLICATIONS_LOADING = "APPLICATIONS_LOADING";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

//contacting
export const GET_CONTACTINGS = "GET_APPLICATIONS";
export const GET_CONTACTING = "GET_APPLICATION";
export const ADD_CONTACTING = "ADD_APPLICATION";
export const DELETE_CONTACTING = "DELETE_CONTACTING";
export const CONTACTING_LOADING = "APPLICATIONS_LOADING";
export const UPDATE_CONTACTING = "UPDATE_APPLICATION";

// Global
export const SET_THEME = "SET_THEME";

//Recruiter
export const GET_RECRUITERS = "GET_RECRUITERS";
export const GET_RECRUITER = "GET_RECRUITER";
export const RECRUITERS_LOADING = "RECRUITERS_LOADING";
export const SET_USER_TO_RECRUITER = "SET_USER_TO_RECRUITER";
export const SET_RECRUITER_LOADING = "SET_RECRUITER_LOADING";

//ACTIVITY
export const GET_ACTIVITY = "GET_ACTIVITY";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ACTIVITY_LOADING = "ACTIVITY_LOADING";

//Status
export const GET_STATUSES = "GET_STATUSES";
export const ADD_STATUS = "ADD_STATUS";
export const STATUSES_LOADING = "STATUSES_LOADING";
export const DELETE_STATUS = "DELETE_STATUS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_PAID = "UPDATE_PAID";

// Search
export const GET_SEARCH = "GET_SEARCH";
export const SET_SEARCH = "SET_SEARCH";
export const SET_TAGS = "SET_TAGS";
export const UPDATE_CANDIDATES = "UPDATE_CANDIDATES";
export const SEARCH_LOADING = "SEARCH_LOADING";

//Interested
export const GET_INTERESTED = "GET_INTERESTED";
export const GET_SINGLE_INTERESTED = "GET_SINGLE_INTERESTED";
export const UPDATE_INTEREST = "UPDATE_INTEREST";
export const INTERESTED_LOADING = "INTERESTED_LOADING";
export const ADD_INTERESTED = "ADD_INTERESTED";
export const DELETE_INTERESTED = "DELETE_INTERESTED";

// Shop
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_ONLY_PRODUCTS = "GET_ONLY_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

// TEAM
export const GET_TEAM = "GET_TEAM";
export const TEAM_LOADING = "TEAM_LOADING";
export const ADD_TEAM_PERSON = "ADD_TEAM_PERSON";
export const DELETE_TEAM_PERSON = "DELETE_TEAM_PERSON";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";

// JOB VISITS
export const GET_JOB_VISITS = "GET_JOB_VISITS";
export const GET_JOB_VISIT = "GET_JOB_VISIT";
export const JOB_VISITS_LOADING = "JOB_VISITS_LOADING";
export const ADD_JOB_VISIT = "ADD_JOB_VISIT";
export const DELETE_JOB_VISIT = "DELETE_JOB_VISIT";

// EXTERNAL CLICKS
export const GET_EXTERNAL_CLICKS = "GET_EXTERNAL_CLICKS";
export const GET_EXTERNAL_CLICK = "GET_EXTERNAL_CLICK";
export const EXTERNAL_CLICK_LOADING = "EXTERNAL_CLICK_LOADING";
export const ADD_EXTERNAL_CLICK = "ADD_EXTERNAL_CLICK";
export const DELETE_EXTERNAL_CLICK = "DELETE_EXTERNAL_CLICK";

// Upload
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";
export const CLEAR_UPLOAD = "CLEAR_UPLOAD";
export const SET_UPLOADED_MEDIA = "SET_UPLOADED_MEDIA";

export const GET_FREE_CLICKS = "GET_FREE_CLICKS";
export const GIVE_FREE_CLICKS = "GIVE_FREE_CLICKS";

export const GET_COMPANIES_CRM = "GET_COMPANIES_CRM";
export const REMOVE_FROM_LIST_CRM = "REMOVE_FROM_LIST_CRM";
export const DELETE_COMPANY_CRM = "DELETE_COMPANY_CRM";
export const ADD_COMPANY_CRM = "ADD_COMPANY_CRM";
