import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import Card from "../../../components/Card";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import Document from "../../../components/Crm/Candidates/Document";
import Activity from "../../../components/Crm/Candidates/Activity";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import UploadDocuments from "./UploadDocuments";
import LaunchIcon from "@material-ui/icons/Launch";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Loader from "../../../components/GlobalLoader";
import { V3 } from "../../../lib/api";
import axios from "axios";
import Status from "../../../components/Crm/Candidates/Status";
import JobInfos from "../../../components/Crm/Candidates/JobInfos";

import {
  CANDIDATES_LOADING,
  GET_CANDIDATE,
  UPDATE_CANDIDATE,
} from "../../../redux/types";
import { useSnackbar } from "notistack";
import DocumentHistory from "../../../components/Crm/Candidates/DocumentHistory";

function ViewCandidate() {
  let { id } = useParams();

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: CANDIDATES_LOADING,
    });
    V3.get(`/crm/candidates/${id}`).then((res) => {
      dispatch({
        type: GET_CANDIDATE,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSendEmail = ({ jobLink }, formikApi) => {
    formikApi.setSubmitting(true);
    const data = {
      jobLink,
      candidates: [id],
    };
    V3.post(`/crm/candidates/email`, data).then((res) => {
      formikApi.setSubmitting(false);
      setOpen(false);
    });
  };

  const { candidate, loading } = useSelector((state) => state.candidate);

  const handleOpenPdf = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  };

  const _handleSubmit = (values, formApi) => {
    formApi.setSubmitting(true);
    const { linkedinProfileLink } = values;
    const data = {
      linkedinProfileLink,
    };

    V3.put(`/crm/candidates/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_CANDIDATE,
          payload: res.data,
        });
        enqueueSnackbar("Candidate updated successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated Candidate", {
          variant: "error",
        });
        formApi.setSubmitting(false);
      });
  };

  const handleDocumentActivity = (document, data) => {
    V3.post(`/crm/candidates/upload-documents`, {
      candidate: candidate._id,
      document,
      ...data,
    }).then((res) => {});
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      {candidate !== null && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Table component={Paper}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Vorname</TableCell>
                      <TableCell align="right">{candidate.firstName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Nachname</TableCell>
                      <TableCell align="right">{candidate.lastName}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">{candidate.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Kanton</TableCell>
                      <TableCell align="right">{candidate.city}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} md={6}>
                <Table component={Paper}>
                  <TableBody>
                    {candidate.expectedSalary && (
                      <TableRow>
                        <TableCell>Salary expectations</TableCell>
                        <TableCell align="right">
                          {candidate.expectedSalary}CHF
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Geburtsdatum</TableCell>
                      <TableCell align="right">
                        {candidate.birthday ? (
                          <Moment>{candidate.birthday}</Moment>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Mobile</TableCell>
                      <TableCell align="right">{candidate.phone}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ marginTop: 15, padding: 15 }}>
                <Formik
                  validationSchema={Yup.object().shape({
                    linkedinProfileLink: Yup.string().nullable(),
                  })}
                  onSubmit={_handleSubmit}
                  initialValues={{
                    linkedinProfileLink: candidate.linkedinProfileLink,
                  }}
                >
                  {({ values, isSubmitting }) => (
                    <Form>
                      {isSubmitting && <Loader />}
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="linkedinProfileLink"
                            label="Linkedin Profile Link"
                            name="linkedinProfileLink"
                            style={{ margin: 0, marginBottom: 13 }}
                          />
                          <a
                            href={values.linkedinProfileLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {values.linkedinProfileLink}
                          </a>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            bearbeiten
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Card
                title="Documents"
                style={{ marginTop: 15 }}
                contentStyles={{ paddingBottom: 0 }}
                button={<UploadDocuments id={id} />}
              >
                {candidate.documents && candidate.documents.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Uploaded By</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">History</TableCell>
                        <TableCell align="right">Aktion</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {candidate.documents.map((document) => (
                        <TableRow key={document._id}>
                          <TableCell>
                            <span style={{ wordBreak: "break-all" }}>
                              {document.name}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            {document.user
                              ? document.user.profile.name
                              : "No user"}
                          </TableCell>
                          <TableCell align="right">
                            <Moment>{document.date}</Moment>
                          </TableCell>
                          <TableCell align="right">
                            <DocumentHistory document={document._id} />
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <IconButton
                              onClick={() => {
                                handleDocumentActivity(document._id, {
                                  openedDoc: true,
                                });
                                handleOpenPdf(document.url);
                              }}
                            >
                              <LaunchIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleDocumentActivity(document._id, {
                                  downloadedDoc: true,
                                });
                                window.open(document.url, "_blank");
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                            <Document data={document} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: 0, padding: 15 }}>No documents</p>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card title="Notizen" style={{ marginTop: 15 }}>
                <div
                  style={{ padding: 15 }}
                  dangerouslySetInnerHTML={{
                    __html: candidate.notes,
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <JobInfos candidateId={candidate?._id} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper style={{ padding: 15 }}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                fullWidth
                to={`/crm/candidates/edit/${candidate._id}`}
              >
                Bearbeiten
              </Button>
            </Paper>
            <Paper style={{ padding: 15, marginTop: 15 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => setOpen(true)}
              >
                Send Email
              </Button>
            </Paper>

            <Paper
              style={{ marginTop: 15, overflow: "hidden", marginBottom: 15 }}
            >
              <Table component={Paper}>
                <TableBody>
                  <TableRow>
                    <TableCell>Erstellungsdatum</TableCell>
                    <TableCell align="right">
                      <Moment>{candidate.createdAt}</Moment>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Status candidateId={candidate._id} />
            <Activity candidateId={candidate._id} />
          </Grid>
        </Grid>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Formik
          validationSchema={Yup.object().shape({
            jobLink: Yup.string().required("Required"),
          })}
          onSubmit={handleSendEmail}
          initialValues={{ jobLink: "" }}
        >
          {({ submitForm, isSubmitting, dirty, values }) => (
            <Form>
              {isSubmitting && <Loader />}

              <DialogTitle>Send Email to Candidate</DialogTitle>
              <DialogContent style={{ minWidth: 500 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="jobLink"
                  label="Job Link"
                  name="jobLink"
                  style={{ margin: 0 }}
                />
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default ViewCandidate;
