import React from "react";
export default function ExternalClicks({ visits }) {
  return (
    <h1
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        margin: 0,
        fontSize: "40px",
      }}
    >
      {visits}
    </h1>
  );
}
