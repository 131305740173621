import _ from "lodash";
import moment from "moment";
import React from "react";
import { Chart } from "react-charts";

import TwoBlueIcon from "./Icons/two-blue";
import TwoRedIcon from "./Icons/two-red";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ChartWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  min-height: 300px;
  background: #ffffff;
  border: 1px solid #eaecf0;

  border-radius: 8px;
  padding: 25px;
  @media (max-width: 960px) {
    padding: 15px;
  }
  .chart-holder {
    position: relative;
    min-height: 300px;
    width: 100%;
  }
  .head {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icons {
      svg {
        margin-left: 5px;
      }
    }
    span {
      color: #101828;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const generateEmptyData = (start, end) => {
  const emptyData = [];

  const startDate = moment(start, "DD/MM/YYYY");
  const endDate = moment(end, "DD/MM/YYYY");

  if (startDate > endDate) {
    return [];
  }

  while (startDate < endDate) {
    emptyData.push({ links: 0, createdAt: startDate.format() });
    startDate.add(1, "month");
  }

  return emptyData;
};

function SharedJobsChart({ links, start, end }) {
  const { recruiter } = useSelector((state) => state.recruiter);
  const startDate = start
    ? start
    : moment().subtract(3, "months").format("DD/MM/YYYY");
  const endDate = end ? end : moment().format("DD/MM/YYYY");
  let myLinks = links.filter((el) => el.forCompany !== true);
  let myCompanyLinks = links.filter((el) => el.forCompany === true);

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => moment(datum?.createdAt).format("MM/YYYY"),
      elementType: "area",
      stacked: false,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum?.links,
        stacked: false,
        elementType: "area",
      },
    ],
    []
  );

  const chartData = [];

  myLinks = [
    ...[...generateEmptyData(startDate, endDate), ...myLinks]
      .reduce(
        (m, o) =>
          m.set(
            moment(o?.createdAt).format("MM/YYYY"),
            Object.assign(
              {
                ...m.get(moment(o?.createdAt).format("MM/YYYY")),
                links: m.get(moment(o?.createdAt).format("MM/YYYY"))?.links
                  ? m.get(moment(o?.createdAt).format("MM/YYYY")).links +
                    o.points
                  : o.points,
                radius: undefined,
              } || {},
              { ...o, radius: undefined }
            )
          ),
        new Map()
      )
      .values(),
  ];
  myLinks = _(myLinks).orderBy("createdAt", "asc").value();
  myCompanyLinks = [
    ...[...generateEmptyData(startDate, endDate), ...myCompanyLinks]
      .reduce(
        (m, o) =>
          m.set(
            moment(o?.createdAt).format("MM/YYYY"),
            Object.assign(
              {
                ...m.get(moment(o?.createdAt).format("MM/YYYY")),
                links: m.get(moment(o?.createdAt).format("MM/YYYY"))?.links
                  ? m.get(moment(o?.createdAt).format("MM/YYYY")).links +
                    o.points
                  : o.points,
                radius: undefined,
              } || {},
              { ...o, radius: undefined }
            )
          ),
        new Map()
      )
      .values(),
  ];
  myCompanyLinks = _(myCompanyLinks).orderBy("createdAt", "asc").value();

  if (links.length > 0) {
    chartData.push({
      data: myLinks,
      label: "Two$",
    });
    chartData.push({
      data: myCompanyLinks,
      label: "Two$",
    });
  } else {
    chartData.push({
      data: [{ createdAt: moment(new Date()).format(), links: 0 }],
      label: "Two$",
    });
  }

  return (
    <>
      <ChartWrapper>
        <div className="head">
          <span>Gesammelte TWO$</span>
          <HeadWrapper>
            <div className="icons">
              <TwoRedIcon />
              {recruiter?.user?.registeredForCompany && <TwoBlueIcon />}
            </div>
          </HeadWrapper>
        </div>
        <div className="chart-holder">
          <Chart
            options={{
              defaultColors: ["#DA1833", "#195079"],
              data: chartData,
              primaryAxis,
              secondaryAxes,
            }}
          />
        </div>
      </ChartWrapper>
    </>
  );
}

export default SharedJobsChart;
