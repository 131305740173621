import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import Card from "../../../components/Card";
import styled from "styled-components";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import CustomCandidatesTagInput from "../../../components/Job/CustomCandidatesTagInput";
import { V3 } from "../../../lib/api";
import { UPDATE_JOB } from "../../../redux/types";
import Loader from "../../../components/GlobalLoader";
import AssignedCandidates from "../../../components/Job/AssignedCandidates";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const selectValues = [
  {
    key: "Dashboard",
    label: "Dashboard",
    value: "Dashboard",
  },
  {
    key: "Tool",
    label: "Tool",
    value: "Tool",
  },
  {
    key: "E-Mail",
    label: "E-Mail",
    value: "E-Mail",
  },
];

const getDashboardSelect = (value) => {
  for (let index = 0; index < selectValues.length; index++) {
    const element = selectValues[index];
    if (value === element.key) {
      return element;
    }
  }
  return {
    key: "",
    label: "",
    value: "",
  };
};

const getApplicationType = (type) => {
  switch (type) {
    case 0:
      return "-";
    case 1:
      return "Weitergeleitet";
    case 2:
      return "Ablehnen";
    case 3:
      return "Aufgenommen";
    case 4:
      return "Stelle besetzt";
    case 5:
      return "Erledigt";
    case 6:
      return "Weitergeleitet (Dashboard)";

    default:
      return "-";
  }
};

const Wrapper = styled.div`
  margin-bottom: 15px;
  position: sticky;
  top: 80px;
  z-index: 900;
  display: flex;
  justify-content: flex-end;
`;

const Index = () => {
  const { job } = useSelector((state) => state.job);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  console.log("job", job);

  const [disable, setDisable] = useState(false);

  const [customCandidatesV2, setCustomCandidatesV2] = useState(
    job?.customCandidatesV2 || []
  );

  const _handleSubmit = async (values, formikAPi) => {
    let { dashboardSelect } = values;

    const data = {
      ...values,
      customCandidatesV2,
      dashboardSelect: dashboardSelect ? dashboardSelect.key : null,
    };
    try {
      const res = await V3.put(`/jobs/${job._id}/apply-data`, data);
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
      formikAPi.setSubmitting(false);

      enqueueSnackbar("Job updated successfully", {
        variant: "success",
      });
      setDisable(false);
    } catch (error) {
      enqueueSnackbar("Could not updated Job", {
        variant: "error",
      });
    }
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({})}
      enableReinitialize
      onSubmit={_handleSubmit}
      initialValues={{
        dashboardSelect:
          job &&
          job.dashboardSelect &&
          getDashboardSelect(job.dashboardSelect || ""),
        dashboardEmail: job?.dashboardEmail,
        toolLink: job?.toolLink,
        dashboardTool: job?.dashboardTool,
        toolPassword: job?.toolPassword,
        toolUsername: job?.toolUsername,
        toolNote: job?.toolNote,
        applyNotes: job?.applyNotes,
        customCandidatesV2: job?.customCandidatesV2 || [],
      }}
    >
      {({ isSubmitting, values, handleSubmit }) => {
        return (
          <Form>
            {job?.company?.allInOneActivated && (
              <Alert severity="info" style={{ marginBottom: 15 }}>
                All in one is active
              </Alert>
            )}
            <Wrapper>
              <Paper style={{ padding: 15, width: "fit-content" }}>
                {" "}
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  // type="submit"
                  style={{ padding: "16px 32px", maxWidth: 500 }}
                  disabled={disable}
                  onClick={() => {
                    setDisable(true);
                    handleSubmit();
                  }}
                >
                  Änderungen speichern
                </Button>
              </Paper>
            </Wrapper>

            {isSubmitting && <Loader />}
            <Grid container style={{ padding: 15 }} spacing={2}>
              <Grid item xs={12}>
                <Card title="Bewerbungen" style={{ marginTop: 15 }}>
                  {job.applications.length > 0 ? (
                    <Table id="applications">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell align="right">Typ</TableCell>
                          <TableCell align="right">Tel.</TableCell>
                          <TableCell align="right">Documents</TableCell>
                          <TableCell align="right">Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {job.applications.map((row) => (
                          <TableRow key={row._id}>
                            <TableCell component="th">
                              <Link to={`/applications/edit/${row._id}`}>
                                {row?.profile?.name}
                              </Link>
                            </TableCell>
                            <TableCell>{row?.email}</TableCell>
                            <TableCell>
                              {getApplicationType(row?.infoStatus)}
                            </TableCell>
                            <TableCell align="right">
                              {row?.type === 1 && "With Resume"}
                              {row?.type === 2 && "Without Resume"}
                              {row?.type === 3 && "Has Questions"}
                            </TableCell>
                            <TableCell align="right">
                              {row?.profile?.phone}
                            </TableCell>
                            <TableCell align="right">
                              {row?.files?.length}
                            </TableCell>
                            <TableCell align="right">
                              <Moment>{row.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <p style={{ padding: "0 15px" }}>No applications done</p>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Card title="Bewerbung" style={{ marginTop: 15 }}>
                  <Table>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="dashboardSelect"
                          options={selectValues}
                          variant="outlined"
                          textFieldProps={{
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    {values.dashboardSelect?.key === "E-Mail" && (
                      <TableRow>
                        <TableCell>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dashboardEmail"
                            label="Email"
                            name="dashboardEmail"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {values.dashboardSelect?.key === "Tool" && (
                      <>
                        {" "}
                        <TableRow>
                          <TableCell>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="dashboardTool"
                              label="Tool"
                              name="dashboardTool"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolLink"
                              label="Link"
                              name="toolLink"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolUsername"
                              label="Username"
                              name="toolUsername"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolPassword"
                              label="Password"
                              name="toolPassword"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="toolNote"
                              label="Note"
                              name="toolNote"
                              style={{ margin: 0 }}
                              multiline
                              rows={6}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </Table>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 15, marginBottom: 15, marginTop: 15 }}>
                  <CustomCandidatesTagInput
                    selectedItem={customCandidatesV2}
                    setSelectedItem={setCustomCandidatesV2}
                    fullWidth
                    variant="outlined"
                    id="tags"
                    placeholder="hinzufügen"
                    label="Extern eingereicht"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Card title="Company Apply Notes">
                  <div
                    style={{ paddingLeft: 15 }}
                    dangerouslySetInnerHTML={{
                      __html: job?.company?.applyNotes || "",
                    }}
                  ></div>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Notizen Bewerbungen"
                    name="applyNotes"
                    style={{ margin: 0, borderColor: "yellow" }}
                    multiline
                    rows={5}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <AssignedCandidates id={job._id} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Index;
