import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FileUpload from "../../../components/fileUpload";
import { useDispatch } from "react-redux";
import Loader from "../../../components/GlobalLoader";
import { V3 } from "../../../lib/api";
import { GET_CANDIDATE } from "../../../redux/types";
import { useSnackbar } from "notistack";
function UploadDocuments({ id }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (selectorFiles) => {
    setFiles(selectorFiles);
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    // props.uploadDocuments(props.id, formData, handleFinish);
    V3.post(`/crm/candidates/${id}/upload-documents`, formData).then((res) => {
      dispatch({
        type: GET_CANDIDATE,
        payload: res.data,
      });
      enqueueSnackbar("Documents uploaded", {
        variant: "success",
      });
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Dokument hochladen
      </Button>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload documents</DialogTitle>
        <DialogContent>
          <FileUpload handleChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            autoFocus
            disabled={files.length > 0 ? false : true}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadDocuments;
