import React, { useEffect, useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Table from "../../Table";
import Actions from "../../Table/Actions";
import AddQuizTemplateAnswer from "./addNew";
import { useConfirm } from "material-ui-confirm";
import { V3 } from "../../../lib/api";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateQuizTemplateAnswer from "./update";

function QuizTemplateAnswers({
  answers,
  handleUpdateQuestions,
  template,
  questions,
}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirm = useConfirm();

  useEffect(() => {
    setData(answers || []);
  }, [answers]);

  const handleDelete = (id) => {
    confirm({
      title: `Delete Answer`,
      description: `Are you sure you wont to delete this answer.`,
    }).then(() => {
      setLoading(true);
      V3.delete(
        `/quiz-template/${template}/questions/${questions}/answer/${id}`
      ).then((res) => {
        handleUpdateQuestions(res.data.data.questions);

        setLoading(false);
      });
    });
  };

  const columns = [
    {
      id: "text",
      Header: "Answer",
      accessor: (d) => d.text,
      Cell: (props) => <strong>{props.original?.text}</strong>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <UpdateQuizTemplateAnswer
            template={template}
            question={questions}
            handleUpdateQuestions={handleUpdateQuestions}
            answer={props.original}
          />

          <Tooltip title="Delete this Answer">
            <span
              className="action fi"
              onClick={() => handleDelete(props.original._id)}
            >
              <DeleteIcon />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <>
      <Tooltip title="Answers">
        <IconButton onClick={() => setOpen(true)}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: 0 }}>Answers</p>{" "}
            <AddQuizTemplateAnswer
              template={template}
              question={questions}
              handleUpdateQuestions={handleUpdateQuestions}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Table
            data={data}
            columns={columns}
            loading={loading}
            page={1}
            pages={1}
            showPaginationBottom={false}
            noDataText="No Data"
            filterable={false}
            minRows={5}
          />
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(false)}
            >
              close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default QuizTemplateAnswers;
