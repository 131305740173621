import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import {
  Button,
  IconButton,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Card from "../../../components/Card";
import Moment from "react-moment";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Axios from "axios";
import fileDownload from "js-file-download";
import { V3 } from "../../../lib/api";
// import { Switch } from "material-ui-formik-components";
// import Editor from "../../../components/Editor";
import GlobalLoader from "../../../components/GlobalLoader";
import {
  APPLICATIONS_LOADING,
  GET_APPLICATION,
  UPDATE_APPLICATION,
} from "../../../redux/types";

function EditApplication() {
  const { application } = useSelector((state) => state?.application);
  const { id } = useParams();

  const [openAcceptDashboard, setOpenAcceptDashboard] = useState(false);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const getApplication = async (id) => {
    try {
      dispatch({
        type: APPLICATIONS_LOADING,
      });

      const res = await V3.get(`/applications/${id}`);
      dispatch({
        type: GET_APPLICATION,
        payload: res.data,
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    getApplication(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = (values, formikAPi) => {
    const {
      firstname,
      lastname,
      phone,
      email,
      message,
      status,
      twoStatus,
      type,
      note,
    } = values;

    const data = {
      profile: { name: firstname + " " + lastname, firstname, lastname, phone },
      email,
      message,
      note,
      status: status && status.key,
      twoStatus: twoStatus && twoStatus.key,
      type: type && type.key,
    };

    V3.put(`/applications/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_APPLICATION,
          payload: id,
        });
        enqueueSnackbar("Application updated successfully", {
          variant: "success",
        });
        formikAPi.setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated Application", {
          variant: "error",
        });
        formikAPi.setSubmitting(false);
      });
  };

  const handleAccept = (info) => {
    confirm({
      title: `Weitergeleitet ${info.profile.name}`,
      description: (
        <>
          <Alert severity="warning">
            Falls Sie dem Kandidaten eine andere Nachricht zustellen möchten,
            bitte verwenden Sie seine E-Mail Adresse!{" "}
          </Alert>
          <Alert severity="info" style={{ marginTop: 15 }}>
            Bitte alle weiteren E-Mails wie z.B. Terminierung des
            Vorstellungsgesprächs direkt dem Kandidaten zusenden!
          </Alert>
          <p style={{ marginTop: 30 }}>
            Es geht eine automatische E-Mail an den Kandidaten, dass er in die
            engere Auswahl kommt.
          </p>
        </>
      ),
    }).then(() => {
      V3.put(`applications/status/${info._id}`, {
        status: 1,
        email: info.email,
      })
        .then((res) => {
          enqueueSnackbar("Kandidat wurde akzeptiert!", {
            variant: "success",
          });

          getApplication(id);
        })
        .catch((err) => {
          enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
            variant: "error",
          });
        });
    });
  };
  const handleAcceptDashboard = (values) => {
    V3.put(`/applications/status/${application._id}`, {
      status: 6,
      email: application.email,
      ...values,
    })
      .then((res) => {
        enqueueSnackbar("Kandidat wurde akzeptiert!", {
          variant: "success",
        });

        getApplication(id);
        setOpenAcceptDashboard(false);
      })
      .catch((err) => {
        enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
          variant: "error",
        });
      });
  };
  const handleAcceptTwoJobs = (info) => {
    confirm({
      title: `Aufnahme ${info.profile.name}`,
      description: (
        <>
          <Alert severity="warning">
            Falls Sie dem Kandidaten eine andere Nachricht zustellen möchten,
            bitte verwenden Sie seine E-Mail Adresse!{" "}
          </Alert>
          <p style={{ marginTop: 30 }}>
            Es geht eine automatische E-Mail an den Kandidaten, dass er in der
            two.jobs datenbank aufgehnomen wurde.
          </p>
        </>
      ),
    }).then(() => {
      V3.put(`/applications/status/${info._id}`, {
        status: 3,
        email: info.email,
      })
        .then((res) => {
          enqueueSnackbar("Kandidat wurde aufgehnomen!", {
            variant: "success",
          });

          getApplication(id);
        })
        .catch((err) => {
          enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
            variant: "error",
          });
        });
    });
  };
  const handleDecline = (info) => {
    confirm({
      title: `Ablehnen ${info.profile.name}`,
      description: (
        <>
          <Alert severity="warning">
            Falls Sie dem Kandidaten eine andere Nachricht zustellen möchten,
            bitte verwenden Sie seine E-Mail Adresse!{" "}
          </Alert>
          <p style={{ marginTop: 30 }}>
            Es geht eine automatische E-Mail mit der Absage an den Kandidaten.
          </p>
        </>
      ),
    }).then(() => {
      V3.put(`/applications/status/${info._id}`, {
        status: 2,
        email: info.email,
      })
        .then((res) => {
          enqueueSnackbar("Kandidat wurde abgelehnt.", {
            variant: "success",
          });

          getApplication(id);
        })
        .catch((err) => {
          enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
            variant: "error",
          });
        });
    });
  };
  const handleDeclineTwoJobs = (info) => {
    confirm({
      title: `Stelle besetzt ${info.profile.name}`,
      description: (
        <>
          <Alert severity="warning">
            Falls Sie dem Kandidaten eine andere Nachricht zustellen möchten,
            bitte verwenden Sie seine E-Mail Adresse!{" "}
          </Alert>
          <p style={{ marginTop: 30 }}>
            Es geht eine automatische E-Mail mit der Absage an den Kandidaten.
          </p>
        </>
      ),
    }).then(() => {
      V3.put(`/applications/status/${info._id}`, {
        status: 4,
        email: info.email,
      })
        .then((res) => {
          enqueueSnackbar("Kandidat wurde abgelehnt.", {
            variant: "success",
          });

          getApplication(id);
        })
        .catch((err) => {
          enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
            variant: "error",
          });
        });
    });
  };
  const handleCompleatedTwoJobs = (info) => {
    confirm({
      title: `${info.profile.name} Erledigt`,
      description: (
        <>
          <Alert severity="warning">
            Falls Sie dem Kandidaten eine andere Nachricht zustellen möchten,
            bitte verwenden Sie seine E-Mail Adresse!{" "}
          </Alert>
        </>
      ),
    }).then(() => {
      V3.put(`/applications/status/${info._id}`, {
        status: 5,
        email: info.email,
      })
        .then((res) => {
          enqueueSnackbar("Kandidat wurde abgelehnt.", {
            variant: "success",
          });

          getApplication(id);
        })
        .catch((err) => {
          enqueueSnackbar("Etwas ging schief! Bitte nochmals versuchen.", {
            variant: "error",
          });
        });
    });
  };

  const handleDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };

  return (
    <React.Fragment>
      {application !== null && (
        <Formik
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string().required("Required"),
          })}
          onSubmit={_handleSubmit}
          initialValues={{
            firstname: application.profile.firstname,
            lastname: application.profile.lastname,
            phone: application.profile.phone,
            email: application.email,
            message: application.message,
            note: application.note,
            status: {
              key: application.status,
              label: application.status,
              value: application.status,
            },
            twoStatus: {
              key: application.twoStatus,
              label: application.twoStatus,
              value: application.twoStatus,
            },
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader></Loader>}

              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="firstname"
                          label="Vorname"
                          name="firstname"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="lastname"
                          label="Nachname"
                          name="lastname"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="phone"
                          label="Tel."
                          name="phone"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="email"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="message"
                          label="Nachricht"
                          name="message"
                          style={{ margin: 0 }}
                          multiline
                          rows="6"
                        />
                      </Grid>
                    </Grid>
                  </Paper>

                  <Card title="Documents">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Dokument Name</TableCell>
                          <TableCell align="right">Herunterladen</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {application.files.map((file) => {
                          var parts = file.split("/");
                          var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

                          return (
                            <TableRow key={lastSegment}>
                              <TableCell component="th" scope="row">
                                {lastSegment}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  color="primary"
                                  variant="outlined"
                                  onClick={() =>
                                    handleDownload(file, lastSegment)
                                  }
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                                <IconButton
                                  component="a"
                                  color="primary"
                                  variant="outlined"
                                  href={`${file}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Card>

                  {application.job && application.job._id && (
                    <Card title="Job info" style={{ marginTop: 15 }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Job Title
                            </TableCell>
                            <TableCell align="right">
                              <a
                                href={`https://crm.two.jobs/jobs/edit/${application.job._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                {application.job.title}
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Category
                            </TableCell>
                            <TableCell align="right">
                              {application.job.category}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Created At
                            </TableCell>
                            <TableCell align="right">
                              <Moment>{application.job.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  )}

                  {application.isFromDashboard && application.pool && (
                    <Card title="info" style={{ marginTop: 15 }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Is From Dahsboard
                            </TableCell>
                            <TableCell align="right">
                              {application?.isFromDashboard ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Email Sendet
                            </TableCell>
                            <TableCell align="right">
                              <Moment>{application?.pool?.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper style={{ marginBottom: 15, overflow: "hidden" }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={2}>
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                              disabled={!dirty}
                              style={{ padding: "16px 32px" }}
                            >
                              Änderungen speichern
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>

                  <Card
                    title="Status"
                    style={{ marginTop: 15 }}
                    contentStyles={{ padding: 15 }}
                  >
                    {application.email ? (
                      <>
                        {application.infoStatus === 0 && (
                          <>
                            <Button
                              variant="contained"
                              style={{
                                background: "green",
                                color: "#fff",
                                marginBottom: 10,
                              }}
                              fullWidth
                              startIcon={<ThumbUpIcon />}
                              onClick={(e) => handleAccept(application)}
                            >
                              Weitergeleitet
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                background: "green",
                                color: "#fff",
                                marginBottom: 10,
                              }}
                              fullWidth
                              startIcon={<ThumbUpIcon />}
                              onClick={(e) => setOpenAcceptDashboard(true)}
                            >
                              Weitergeleitet (Dashboard)
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{
                                background: "red",
                                color: "#fff",
                                marginBottom: 10,
                              }}
                              startIcon={<ThumbDownAltIcon />}
                              onClick={(e) => handleDecline(application)}
                            >
                              Ablehnen
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={(e) => handleAcceptTwoJobs(application)}
                            >
                              Aufnahme
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{
                                background: "rgba(0, 98, 255, 0.2)",
                                marginTop: 10,
                              }}
                              onClick={(e) => handleDeclineTwoJobs(application)}
                            >
                              Stelle besetzt
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{
                                background: "#bbf49b",
                                marginTop: 10,
                              }}
                              onClick={(e) =>
                                handleCompleatedTwoJobs(application)
                              }
                            >
                              Erledigt
                            </Button>
                          </>
                        )}

                        {application.infoStatus === 1 && "Weitergeleitet"}
                        {application.infoStatus === 2 && "Ablehnen"}
                        {application.infoStatus === 3 && "Aufgenommen"}
                        {application.infoStatus === 4 && "Stelle besetzt"}
                        {application.infoStatus === 5 && "Erledigt"}
                        {application.infoStatus === 6 &&
                          "Weitergeleitet (Dashboard)"}
                      </>
                    ) : (
                      "-"
                    )}
                  </Card>
                  <Paper style={{ padding: 14, marginTop: 15 }}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="note"
                      label="Note"
                      name="note"
                      style={{ margin: 0 }}
                      multiline
                      rows="10"
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <Dialog
        open={openAcceptDashboard}
        onClose={() => setOpenAcceptDashboard(false)}
      >
        <Formik
          validationSchema={Yup.object().shape({})}
          onSubmit={handleAcceptDashboard}
          initialValues={{
            isOneInAll: false,
            message: "",
          }}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              {isSubmitting && <GlobalLoader />}
              <DialogTitle>
                Weitergeleitet {application?.profile?.name}
              </DialogTitle>
              <DialogContent>
                <Alert severity="warning">
                  Falls Sie dem Kandidaten eine andere Nachricht zustellen
                  möchten, bitte verwenden Sie seine E-Mail Adresse!{" "}
                </Alert>
                <Alert severity="info" style={{ marginTop: 15 }}>
                  Bitte alle weiteren E-Mails wie z.B. Terminierung des
                  Vorstellungsgesprächs direkt dem Kandidaten zusenden!
                </Alert>
                <p style={{ marginTop: 30 }}>
                  Es geht eine automatische E-Mail an den Kandidaten, dass er in
                  die engere Auswahl kommt.
                </p>
                {/* <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={Switch}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="allInOne"
                      label={
                        <p style={{ margin: 0, marginLeft: 15 }}>All in one</p>
                      }
                      name="allInOne"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  {values?.allInOne && (
                    <Grid item xs={12} md={12}>
                      <Editor
                        value={values.message}
                        onChange={(e) => setFieldValue("message", e)}
                      />
                    </Grid>
                  )}
                </Grid> */}
              </DialogContent>
              <DialogActions>
                <Button>cancel</Button>
                <Button color="primary" type="submit">
                  {" "}
                  ok
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default EditApplication;
