import React from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Authorize from "../../../../utils/Authorize";
export default function AlertDialog({ handleChange, checked }) {
  return (
    <React.Fragment>
      <Authorize roles={["admin"]}>
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>No</Grid>
          <Grid item>
            <Switch checked={checked} onChange={handleChange} name='paid' />
          </Grid>
          <Grid item>Yes</Grid>
        </Grid>
      </Authorize>
    </React.Fragment>
  );
}
