import {
  GET_APPLICATIONS,
  ADD_APPLICATION,
  DELETE_APPLICATION,
  APPLICATIONS_LOADING,
  GET_APPLICATION,
} from "../types";

const initialState = {
  data: [],
  applications: [],
  application: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS:
      return {
        ...state,
        applications: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_APPLICATION:
      return {
        ...state,
        application: action.payload.data,
        loading: false,
      };
    case DELETE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(
          (application) => application?._id !== action.payload
        ),
        loading: false,
      };
    case ADD_APPLICATION:
      return {
        ...state,
        applications: [action.payload, ...state.applications],
        loading: false,
      };
    case APPLICATIONS_LOADING:
      return {
        ...state,
        application: null,
        loading: true,
      };
    default:
      return state;
  }
}
