import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { UPDATE_BONUS } from "../../redux/types";

function Edit({ data }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values) => {
    V3.put(`/bonuses/${data._id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_BONUS,
          payload: res.data.data,
        });
        enqueueSnackbar("Bonus updated successfully", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        enqueueSnackbar("Could not updated Bonus", {
          variant: "error",
        });
        setOpen(false);
      });
  };

  return (
    <>
      <span>
        <EditIcon onClick={() => setOpen(true)} />
      </span>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            title: data.title,
          }}
        >
          {() => (
            <Form>
              <DialogTitle>Create Bonus</DialogTitle>
              <DialogContent style={{ minWidth: 500 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  style={{ margin: 0 }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
                <Button color="primary" autoFocus type="submit">
                  Update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Edit;
