import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import KeyboardDatePicker from "./KeyboardDatePicker";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Card from "../../../components/Card";
import Editor from "../../../components/Editor";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { V3 } from "../../../lib/api";
import TableHead from "@material-ui/core/TableHead";
import Moment from "react-moment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import UploadDocuments from "./UploadDocuments";
import Document from "../../../components/Crm/Candidates/Document";
import DeleteDocument from "../../../components/Crm/Candidates/DeleteDocument";
import { Cantons2 } from "../../../data";
import { useEffect } from "react";
import {
  CANDIDATES_LOADING,
  GET_CANDIDATE,
  UPDATE_CANDIDATE,
} from "../../../redux/types";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const EditCandidate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: CANDIDATES_LOADING,
      });

      V3.get(`/crm/candidates/${id}`).then((res) => {
        dispatch({
          type: GET_CANDIDATE,
          payload: res.data,
        });
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _handleSubmit = (values, formApi) => {
    setMessage(null);
    const { firstName, lastName, city, ...rest } = values;
    const data = {
      ...rest,
      firstName,
      lastName,
      name: firstName + " " + lastName,
      city: city && city.label,
    };

    V3.put(`/crm/candidates/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_CANDIDATE,
          payload: res.data,
        });
        enqueueSnackbar("Candidate updated successfully", {
          variant: "success",
        });

        push("/crm/candidates");
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        formApi.setSubmitting(false);
      });
  };

  const { candidate } = useSelector((state) => state.candidate);
  return (
    <React.Fragment>
      {candidate !== null && (
        <Formik
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("Required"),
            actDescription: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            birthday: Yup.string().nullable(),
            email: Yup.string().email().required("Required"),
            city: Yup.string().required("Required"),
            state: Yup.string(),
            zip: Yup.string(),
            street: Yup.string(),
            notes: Yup.string(),
            linkedinProfileLink: Yup.string().nullable(),
          })}
          onSubmit={_handleSubmit}
          initialValues={{
            actDescription: "",
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            email: candidate.email,
            phone: candidate.phone,

            city: {
              key: candidate.city,
              label: candidate.city,
              value: candidate.city,
            },
            birthday: candidate.birthday,
            state: candidate.state,

            street: candidate.street,
            zip: candidate.zip,

            category: {
              key: candidate.category,
              label: candidate.category,
              value: candidate.category,
            },
            notes: candidate.notes,
            linkedinProfileLink: candidate.linkedinProfileLink,
          }}
        >
          {({ isSubmitting, dirty, initialValues, setFieldValue, values }) => (
            <Form>
              {isSubmitting && <Loader></Loader>}
              {message && (
                <Alert severity="error" style={{ marginBottom: 15 }}>
                  {message}
                </Alert>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Card
                    title="Candidate Info"
                    style={{ marginBottom: 15, marginTop: 0 }}
                    contentStyles={{ padding: 15 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="firstName"
                          label="Vorname"
                          name="firstName"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="lastName"
                          label="Nachname"
                          name="lastName"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="email"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type="phone"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="phone"
                          label="Tel."
                          name="phone"
                          style={{ margin: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Field
                          name="birthday"
                          component={KeyboardDatePicker}
                          label="Geburtsdatum"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          placeholder="15/04/2020"
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="linkedinProfileLink"
                          label="Linkedin Profile Link"
                          name="linkedinProfileLink"
                          style={{ margin: 0, marginBottom: 13 }}
                        />
                        <a
                          href={values.linkedinProfileLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {values.linkedinProfileLink}
                        </a>
                      </Grid>
                    </Grid>
                  </Card>

                  <Card
                    title="Documents"
                    style={{ marginTop: 15, marginBottom: 15 }}
                    contentStyles={{ paddingBottom: 0 }}
                    button={<UploadDocuments id={id} />}
                  >
                    {candidate.documents && candidate.documents.length > 0 ? (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Uploaded By</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Aktion</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {candidate.documents.map((document) => (
                            <TableRow key={document._id}>
                              <TableCell>
                                <span style={{ wordBreak: "break-all" }}>
                                  {document.name}
                                </span>
                              </TableCell>
                              <TableCell align="right">
                                {document.user
                                  ? document?.user?.profile?.name
                                  : "No user"}
                              </TableCell>
                              <TableCell align="right">
                                <Moment>{document.date}</Moment>
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <IconButton
                                  component="a"
                                  href={document.url}
                                  target="_blank"
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                                <Document data={document} />
                                <DeleteDocument
                                  id={candidate._id}
                                  documentId={document._id}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p style={{ margin: 0, padding: 15 }}>No documents</p>
                    )}
                  </Card>

                  <Card title="Notizen">
                    <Editor
                      height={200}
                      value={values.notes}
                      onChange={(value) => setFieldValue("notes", value)}
                    />
                  </Card>

                  <Card
                    title="Location"
                    style={{ marginTop: 15 }}
                    contentStyles={{ padding: 15 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={Autocomplete}
                          fullWidth
                          name="city"
                          options={Cantons2}
                          textFieldProps={{
                            label: "Kanton",
                            variant: "outlined",
                            style: { margin: 0 },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!dirty}
                      style={{ padding: "16px 32px" }}
                    >
                      Aktualisieren
                    </Button>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      label="Verlauf"
                      name="actDescription"
                      id="actDescription"
                      fullWidth
                      multiline
                      rows={6}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default EditCandidate;
