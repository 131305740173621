import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import HistoryIcon from "@material-ui/icons/History";
import Table from "../../Table";

function DocumentHistory({ document }) {
  const [data, setData] = useState({});
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original?.user?.profile?.name}</strong>,
    },
    {
      id: "Activity",
      Header: "Activity",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.openedDoc
            ? "Open"
            : props?.original?.downloadedDoc
            ? "Download"
            : "-"}
        </strong>
      ),
    },

    {
      id: "createdAt",
      Header: "Datum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
  ];
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <HistoryIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Document History</DialogTitle>
        <DialogContent>
          <Table
            data={activities}
            columns={columns}
            loading={loading}
            page={!data.pagination ? 0 : data.pagination.current - 1}
            pages={!data.pagination ? 1 : data.pagination.total}
            onFetchData={(state) => {
              var queryString = state.filtered
                .map((key) => key.id + "=" + key.value)
                .join("&");
              let query = `?page=${
                state.page + 1
              }&document=${document}&${queryString}`;
              setLoading(true);

              V3.get(`/crm/activities${query}`).then((res) => {
                setData(res.data);
                setActivities(res.data.data);
                setLoading(false);
              });
            }}
            filterable={false}
            noDataText="No Activities"
            minRows={5}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DocumentHistory;
