import * as React from "react";

const SVGComponent = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={8} fill="#F2F8FD" />
    <g clipPath="url(#clip0_5554_453816)">
      <path
        d="M26.1989 9.80495L25.0708 9.06424L12.8543 27.6691L13.9824 28.4099L26.1989 9.80495Z"
        fill="#194061"
      />
      <path
        d="M27.0925 12.4677L25.9645 11.727L13.8363 30.1975L14.9643 30.9382L27.0925 12.4677Z"
        fill="#194061"
      />
      <path
        d="M19.9994 27.4955C18.5166 27.4955 17.0671 27.0557 15.8341 26.2319C14.6012 25.4081 13.6403 24.2372 13.0728 22.8673C12.5054 21.4973 12.3569 19.9899 12.6462 18.5356C12.9355 17.0812 13.6495 15.7453 14.698 14.6968C15.7465 13.6483 17.0824 12.9343 18.5367 12.645C19.9911 12.3557 21.4985 12.5042 22.8685 13.0716C24.2384 13.6391 25.4094 14.6 26.2332 15.8329C27.057 17.0658 27.4967 18.5154 27.4967 19.9982C27.4967 21.9866 26.7068 23.8935 25.3008 25.2995C23.8948 26.7056 21.9878 27.4955 19.9994 27.4955ZM19.9994 14.1552C18.8432 14.1549 17.7128 14.4976 16.7514 15.1398C15.7899 15.782 15.0405 16.6949 14.5979 17.7631C14.1554 18.8313 14.0395 20.0067 14.265 21.1407C14.4905 22.2747 15.0472 23.3164 15.8648 24.134C16.6824 24.9515 17.7241 25.5083 18.8581 25.7338C19.9921 25.9593 21.1675 25.8435 22.2357 25.4009C23.3039 24.9583 24.2168 24.2089 24.859 23.2474C25.5012 22.2859 25.8439 21.1556 25.8437 19.9994C25.8433 18.4495 25.2275 16.9632 24.1315 15.8672C23.0356 14.7713 21.5493 14.1555 19.9994 14.1552Z"
        fill="#194061"
      />
    </g>
    <defs>
      <clipPath id="clip0_5554_453816">
        <rect width={30} height={30} fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
