import React from "react";
import FullInfo from "../components/Analytics/FullInfo";
import Head from "../components/Analytics/Head";
import Grid from "@material-ui/core/Grid";
import Sales from "../components/Analytics/Sales";
import Offers from "../components/Analytics/Offers";
import Summary from "../components/Analytics/Summary";
import CompareMonths from "../components/Analytics/CompareMonths";
import { useSelector } from "react-redux";
function Analytics() {
  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <Head user={user} style={{ marginTop: 10 }} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} md={9}>
          <Summary />
          <Sales />
        </Grid>
        <Grid item xs={12} md={3}>
          <FullInfo />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Offers />
        </Grid>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={9}>
          <CompareMonths />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Analytics;
