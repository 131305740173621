import React, { useState } from "react";
import Table from "../../components/Table";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";

function Testimonials() {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [testimonials, setTestimonials] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleDelete = (info) => {
    confirm({
      title: `Testimonial löschen`,
      description:
        "Testimonial wirklich löschen? Eine Wiederherstellung ist nicht mehr möglich!",
    }).then(() => {
      setLoading(true);

      V3.delete(`/testimonials/${info._id}`)
        .then((res) => {
          setTestimonials(testimonials.filter((el) => el._id !== info._id));
          enqueueSnackbar("Testimonial has been deleted", {
            variant: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar("Something went wrong! Please try again", {
            variant: "error",
          });
        });
    });
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link to={`/settings/testimonials/edit/${props.original._id}`}>
          <strong>
            {props.original?.de?.name
              ? props.original?.de?.name
              : props.original?.en?.name || "-"}
          </strong>
        </Link>
      ),
    },
    {
      id: "published",
      Header: "Publiziert",
      accessor: (d) => d.published,
      Cell: (props) => (
        <div>
          {props.original.published === true && <span>Ja</span>}
          {props.original.published === false && <span>Nein</span>}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },

    {
      id: "createdAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <div>
          <Tooltip title="Edit this testimonial">
            <IconButton
              component={Link}
              to={`/settings/testimonials/edit/${props.original._id}`}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete this testimonial">
            <IconButton onClick={(e) => handleDelete(props.original)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Testimonials" size={data.total} icon={<GroupIcon />}>
        <Button
          color="primary"
          variant="contained"
          style={{ float: "right" }}
          component={Link}
          to="/settings/testimonials/new"
        >
          Neu
        </Button>
      </Header>
      <Table
        data={testimonials}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);

          V3.get(`/testimonials${query}`).then((res) => {
            setLoading(false);
            setData(res.data);
            setTestimonials(res.data.data);
          });
        }}
        noDataText="No testimonials"
        filterable={true}
      />
    </React.Fragment>
  );
}

export default Testimonials;
