import React, { useState } from "react";
import Table from "../../../../components/Table";
import Actions from "../../../../components/Table/Actions";
import Card from "../../../../components/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "./Delete";
import Edit from "./update";
import AddNew from "./addNew";
import { V3 } from "../../../../lib/api";
import QuizAnswers from "./answers";
import SelectQuizTemplate from "../../../../components/QuizTemplates/Select";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Avatar, IconButton } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { WEB } from "../../../../config";

function QuizQuestions({ jobId }) {
  const [data, setData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { job } = useSelector((state) => state.job);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyNotification = () => {
    enqueueSnackbar("Link zu Ihrer Zwischenablage hinzugefügt", {
      variant: "info",
    });
  };

  const handleNew = (newData) => {
    setQuestions([...questions, newData]);
  };
  const handleDelete = (id) => {
    const tmp = questions.filter((el) => el._id !== id);
    setQuestions(tmp);
  };
  const handleUpdate = (updatedData) => {
    setQuestions(
      questions.map((el) => {
        if (el._id === updatedData._id) {
          return updatedData;
        } else {
          return el;
        }
      })
    );
  };

  const addData = (newData) => {
    setQuestions([...questions, ...newData]);
  };

  const columns = [
    {
      id: "image",
      Header: "Image",
      accessor: (d) => d.image,
      filterable: false,
      Cell: (props) => <Avatar src={props.original?.image?.md?.url} />,
    },
    {
      id: "title",
      Header: "Question",
      accessor: (d) => d.question,
      filterable: false,
      Cell: (props) => <>{props.original?.title || "-"}</>,
    },
    {
      id: "title",
      Header: "Answers",
      accessor: (d) => d.question,
      filterable: false,
      Cell: (props) => (
        <QuizAnswers
          answers={props.original.answer || []}
          question={props.original}
          handleUpdateQuestion={handleUpdate}
        />
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Question">
            <Edit question={props.original} handleUpdate={handleUpdate} />
          </Tooltip>
          <Tooltip title="Delete this Question">
            <Delete id={props.original._id} handleDelete={handleDelete} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <Card
      title="Quiz Questions"
      style={{ marginTop: 15 }}
      button={
        <div style={{ display: "flex" }}>
          <Tooltip title="Quiz link">
            <CopyToClipboard
              text={`${WEB}/job/${job?.slug}/job-quiz`}
              onCopy={handleCopyNotification}
              style={{ marginRight: 15 }}
            >
              <IconButton className="icon-button">
                <FileCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>

          <SelectQuizTemplate addData={addData} />
          <AddNew id={jobId} handleNew={handleNew} />
        </div>
      }
    >
      <Table
        data={questions}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination.total}
        noDataText="No data"
        minRows={5}
        filterable={false}
        onFetchData={async (state) => {
          // setTableState(state);
          var queryString = state?.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&job=${jobId}&${queryString}`;
          setLoading(true);
          try {
            const { data } = await V3.get(`questions${query}`);
            setData(data);
            setQuestions(data.data);
            setLoading(false);
          } catch (error) {
            setData({});
            setQuestions([]);
            setLoading(false);
          }
        }}
      />
    </Card>
  );
}

export default QuizQuestions;
