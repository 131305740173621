import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import Loader from "../GlobalLoader";

function Verify({ recruiterId }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    V3.put(`/recruiters/verify/${recruiterId}`, {
      verified: true,
    })
      .then((res) => {
        setLoading(false);
        enqueueSnackbar("Recruiter verified successfully", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Recruiter bestätigen
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Recruiter bestätigen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Recruiter bestätigen? Eine E-Mail geht raus mit der Bestätigung an
            Recruiter!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default Verify;
