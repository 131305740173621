import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Card";
import Table from "../../../Table";
import Actions from "../../../Table/Actions";
import Add from "./Add";
import SelectBonusTemplate from "./SelectBonusTemplate";
import { useConfirm } from "material-ui-confirm";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { V3 } from "../../../../lib/api";
import { REMOVE_COMPANY_BONUS } from "../../../../redux/types";
function Bonuses({ totalEmployees }) {
  const { company, loading } = useSelector((state) => state.company);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const columns = [
    {
      id: "description",
      Header: "Description",
      filterable: false,
      accessor: (d) => d.description,
      Cell: (props) => <strong>{props.original.description}</strong>,
    },
    {
      id: "amount",
      Header: "Amount",
      filterable: false,
      accessor: (d) => d.amount,
      Cell: (props) => <strong>{props.original.amount}</strong>,
    },

    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Delete this Bonus">
            <DeleteIcon
              onClick={() => handleDelete(company._id, props.original._id)}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  const handleDelete = (companyId, bonusId) => {
    confirm({
      title: `Delete Bonus`,
      description: "You wont be able to restore this anymore!",
    }).then(() => {
      V3.delete(`/companies/${companyId}/bonus/${bonusId}`).then((res) => {
        dispatch({
          type: REMOVE_COMPANY_BONUS,
          payload: res.data.data,
        });
      });
    });
  };

  return (
    <Card
      title="Bonuses"
      button={
        <>
          <SelectBonusTemplate
            id={company._id}
            totalEmployees={totalEmployees}
          />
          <Add />
        </>
      }
    >
      <Table
        data={company.bonuses}
        pages={0}
        columns={columns}
        loading={loading}
        filterable={false}
        noDataText="No bonuses"
        minRows={5}
      />
    </Card>
  );
}

export default Bonuses;
