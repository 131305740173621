import {
  GET_USERS,
  GET_USER,
  USERS_LOADING,
  DELETE_USER,
  ADD_USER,
} from "../types";

const initialState = {
  users: [],
  user: null,
  data: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload.data,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case USERS_LOADING:
      return {
        ...state,
        user: null,
        loading: true,
      };
    default:
      return state;
  }
}
