import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../components/Table";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { V3 } from "../lib/api";
import { Link } from "react-router-dom";

function Invoices() {
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    V3.get(`/users?roles=admin,employee&limit=100`).then((res) => {
      setUsers(res.data.data);
    });
  }, []);

  const getInvoices = (query) => {
    setLoading(true);

    V3.get(`/invoices${query}`).then((res) => {
      setData(res?.data);
      setInvoices(res?.data?.data);
      setLoading(false);
    });
  };

  const { user } = useSelector((state) => state.auth);
  const columns = [
    {
      id: "startDate",
      Header: "Start Date",
      width: 120,
      accessor: (d) => d.title,
      Cell: (props) => <Moment>{props.original.startDate}</Moment>,
    },
    {
      id: "endDate",
      Header: "End Date",
      width: 120,
      accessor: (d) => d.title,
      Cell: (props) => <Moment>{props.original.endDate}</Moment>,
    },
    {
      id: "company",
      Header: "Company",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong>
          <Link
            to={`/crm/company/${
              props.original.company && props.original.company._id
            }`}
          >
            {props.original.company && props.original.company._id
              ? props.original.company.name
              : "-"}
          </Link>
        </strong>
      ),
    },

    {
      id: "user",
      Header: "Berater",
      accessor: (d) => d._id,
      filterable: true,
      Cell: ({ original }) => {
        return (
          <div>
            <span>
              {original && original.user && original.user.profile.name}
            </span>
          </div>
        );
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
          disabled={user && user.role === "employee"}
        >
          <option value="">Alles zeigen</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.profile.name}
            </option>
          ))}
        </select>
      ),
    },

    {
      id: "name",
      Header: "Contact person",
      accessor: (d) => d.title,
      filterable: true,
      Cell: ({ original }) => (
        <strong>
          {original.contactPerson && <span>{original.contactPerson.name}</span>}
        </strong>
      ),
    },

    {
      id: "email",
      Header: "Email",
      accessor: (d) => d.title,
      filterable: true,
      Cell: ({ original }) => (
        <span>
          {original.contactPerson && (
            <a
              style={{ textTransform: "lowercase" }}
              href={original.contactPerson.email}
            >
              {original.contactPerson.email}
            </a>
          )}
        </span>
      ),
    },
    {
      id: "phone",
      Header: "Phone",
      accessor: (d) => d.title,
      filterable: true,
      Cell: ({ original }) => (
        <strong>
          {original.contactPerson && (
            <a href={`tel:+${original.contactPerson.phone}`}>
              {original.contactPerson.phone}
            </a>
          )}
        </strong>
      ),
    },
    {
      id: "paket",
      Header: "Paket",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original?.package?.description}</strong>,
    },

    {
      id: "notes",
      Header: "Notes",
      accessor: (d) => d.title,
      filterable: true,
      Cell: (props) => <strong>{props.original.notes}</strong>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 80,
      Cell: (props) => (
        <>
          <Tooltip title="Download Invoice">
            <IconButton component="a" href={props.original.url} target="_blank">
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Invoices" size={data.total} icon={<GroupIcon />} />
      <Table
        data={invoices}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          getInvoices(query);
        }}
        noDataText="No invoices"
        filterable={false}
        defaultFiltered={[
          {
            id: "user",
            value: user && user.role === "employee" ? user._id : "",
          },
        ]}
      />
    </React.Fragment>
  );
}

export default Invoices;
