/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import Item from "./Item";
import Add from "./Add";
import styled from "styled-components";
import Empty from "./Empty";
import EmptyHover from "./EmptyHover";
import { useDropzone } from "react-dropzone";
import { V3 } from "../../lib/api";
import { useElementSize } from "usehooks-ts";
import { Grid } from "@material-ui/core";

const GalleryWrapper = styled.div`
  border: 2px solid #eaecf0;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  position: relative;

  &.dropping {
    border: 2px dashed #5e85a1;
    background-color: #e8eef2;
  }
`;

const GalleryBuilder = ({ onChange, images, onRemove }) => {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = images.findIndex((item) => item._id === active.id._id);
        const newIndex = images.findIndex((item) => item._id === over?.id._id);
        onChange(arrayMove(images, oldIndex, newIndex));
      }

      setActiveId(null);
    },
    [images]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      // eslint-disable-next-line array-callback-return
      acceptedFiles.map((el) => {
        formData.append("images", el);
      });
      const newImages = Array.from(new Array(acceptedFiles.length)).map(() => {
        return {
          _id: Math.random().toString(36).substr(2, 9),
          loading: true,
        };
      });
      onChange([...images, ...newImages]);
      try {
        setLoading(true);
        const res = await V3.post("/medias", formData);
        onChange([
          ...images.filter((item) => item.loading !== true),
          ...res.data.data,
        ]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [images]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,

    accept: ["image/jpeg", "image/png", "image/gif"],
    noClick: true,
    disabled: loading,
  });

  const [squareRef, { width }] = useElementSize();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <h3 style={{}}>Galerie</h3>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <GalleryWrapper
            className={isDragActive && images.length === 0 ? `dropping` : ""}
          >
            {images.length > 0 ? (
              <>
                <EmptyHover isDragActive={isDragActive} />
                <Grid container spacing={2} style={{ padding: 15 }}>
                  {images.map((item) => (
                    <SortableItem
                      key={item._id}
                      id={item}
                      onRemove={onRemove}
                    />
                  ))}
                  <Grid ref={squareRef} item xs={6} md={3}>
                    <Add
                      open={open}
                      style={{
                        width: width - 16,
                        height: width - 16,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Empty isDragActive={isDragActive} open={open} />
            )}
          </GalleryWrapper>
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <Item
            style={{ width: width, height: width }}
            id={activeId}
            isDragging
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default GalleryBuilder;
