import * as React from "react";

const Whatsapp = (props) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.214 19.375a8.75 8.75 0 1 0-7.613-4.434l-1.137 4.434 4.572-1.06a8.711 8.711 0 0 0 4.178 1.06Zm0-1.346a7.404 7.404 0 1 0-6.067-3.16l-.664 2.487 2.531-.633a7.37 7.37 0 0 0 4.2 1.306Z"
      fill="#BFC8D0"
    />
    <path
      d="M17.714 10A7.5 7.5 0 0 1 5.96 16.177l-2.564.641.672-2.519A7.5 7.5 0 1 1 17.714 10Z"
      fill="#98A2B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.214 18.75a8.75 8.75 0 1 0-7.613-4.434L1.464 18.75l4.572-1.06a8.711 8.711 0 0 0 4.178 1.06Zm0-1.346a7.404 7.404 0 1 0-6.067-3.16l-.664 2.487 2.531-.633a7.37 7.37 0 0 0 4.2 1.306Z"
      fill="#fff"
    />
    <path
      d="M8.027 5.937c-.208-.417-.527-.38-.85-.38-.576 0-1.474.69-1.474 1.974 0 1.053.463 2.205 2.026 3.93 1.509 1.663 3.491 2.523 5.137 2.494 1.645-.03 1.984-1.445 1.984-1.924 0-.211-.132-.317-.223-.346-.56-.269-1.594-.77-1.83-.865-.235-.094-.358.034-.434.103-.214.203-.637.803-.782.937-.144.135-.36.067-.45.016-.33-.133-1.227-.531-1.942-1.224-.884-.857-.936-1.152-1.102-1.414-.134-.21-.036-.339.013-.395.19-.22.453-.56.571-.728.118-.168.025-.424-.032-.584-.241-.685-.446-1.26-.612-1.594Z"
      fill="#fff"
    />
  </svg>
);

export default Whatsapp;
