export const Gender = [
  {
    key: "Männlich",
    label: "Männlich",
    value: "Männlich",
  },
  {
    key: "Weiblich",
    label: "Weiblich",
    value: "Weiblich",
  },
  {
    key: "Other",
    label: "Other",
    value: "Other",
  },
];

export const Statuses = [
  { key: 1, label: "No Status", value: "No Status" },
  { key: 2, label: "Kein Verkauf", value: "completed" },
  { key: 3, label: "Ersttermin", value: "openmeeting" },
  { key: 4, label: "Nachfassen am", value: "statuscontact" },
  { key: 5, label: "Offerte", value: "offer" },
  { key: 6, label: "Erfolgreich abgeschlossen", value: "success" },
  { key: 7, label: "In Verhandlung", value: "Platziert" },
  { key: 8, label: "Nicht Verlängert", value: "Nicht Verlängert" },
];

export const JobTypes = [
  {
    key: "alljobs",
    text: "All Jobs",
    value: "All Jobs",
  },
  {
    key: "Freiberuflich",
    text: "Freiberuflich",
    value: "Freiberuflich",
  },
  {
    key: "Teilzeit",
    text: "Teilzeit",
    value: "Teilzeit",
  },
  {
    key: "Vollzeit",
    text: "Vollzeit",
    value: "Vollzeit",
  },
];

export const ApplicationsTypes = [
  {
    key: 1,
    label: "With Resume",
    value: "With Resume",
  },
  {
    key: 2,
    label: "Without Resume",
    value: "Without Resume",
  },
  {
    key: 3,
    label: "No resume",
    value: "No resume",
  },
];

export const ApplicationsStatus = [
  {
    key: "In Bearbeitung",
    label: "In Bearbeitung",
    value: "In Bearbeitung",
  },
  {
    key: "Weitergeleitet",
    label: "Weitergeleitet",
    value: "Weitergeleitet",
  },
  {
    key: "Absage",
    label: "Absage",
    value: "Absage",
  },
  {
    key: "Einladung",
    label: "Einladung",
    value: "Einladung",
  },
];
export const ApplicationsStatusAdmin = [
  {
    key: "In Bearbeitung",
    label: "In Bearbeitung",
    value: "In Bearbeitung",
  },

  {
    key: "Abgeschlossen",
    label: "Abgeschlossen",
    value: "Abgeschlossen",
  },
];

export const JobTypesSelect = [
  {
    key: "Freiberuflich",
    label: "Freiberuflich",
    value: "Freiberuflich",
  },
  {
    key: "Teilzeit",
    label: "Teilzeit",
    value: "Teilzeit",
  },
  {
    key: "Vollzeit",
    label: "Vollzeit",
    value: "Vollzeit",
  },
  {
    key: "Flexibel",
    label: "Flexibel",
    value: "Flexibel",
  },
];

export const Cantons = [
  {
    key: "Aargau",
    text: "Aargau",
    value: "Aargau",
  },
  {
    key: "Appenzell Innerrhoden",
    text: "Appenzell Innerrhoden",
    value: "Appenzell Innerrhoden",
  },
  {
    key: "Appenzell Ausserrhoden",
    text: "Appenzell Ausserrhoden",
    value: "Appenzell Ausserrhoden",
  },
  {
    key: "Basel-Stadt",
    text: "Basel-Stadt",
    value: "Basel-Stadt",
  },
  {
    key: "Basel-Landschaft",
    text: "Basel-Landschaft",
    value: "Basel-Landschaft",
  },
  {
    key: "Bern",
    text: "Bern",
    value: "Bern",
  },
  {
    key: "Fribourg",
    text: "Fribourg",
    value: "Fribourg",
  },
  {
    key: "Genève",
    text: "Genève",
    value: "Genève",
  },
  {
    key: "Glarus",
    text: "Glarus",
    value: "Glarus",
  },
  {
    key: "Graubünden",
    text: "Graubünden",
    value: "Graubünden",
  },
  {
    key: "Jura",
    text: "Jura",
    value: "Jura",
  },
  {
    key: "Luzern",
    text: "Luzern",
    value: "Luzern",
  },
  {
    key: "Neuchâtel",
    text: "Neuchâtel",
    value: "Neuchâtel",
  },
  {
    key: "Nidwalden",
    text: "Nidwalden",
    value: "Nidwalden",
  },
  {
    key: "Obwalden",
    text: "Obwalden",
    value: "Obwalden",
  },
  {
    key: "Schaffhausen",
    text: "Schaffhausen",
    value: "Schaffhausen",
  },
  {
    key: "Schwyz",
    text: "Schwyz",
    value: "Schwyz",
  },
  {
    key: "Solothurn",
    text: "Solothurn",
    value: "Solothurn",
  },
  {
    key: "St. Gallen",
    text: "St. Gallen",
    value: "St. Gallen",
  },
  {
    key: "Thurgau",
    text: "Thurgau",
    value: "Thurgau",
  },
  {
    key: "Ticino",
    text: "Ticino",
    value: "Ticino",
  },
  {
    key: "Uri",
    text: "Uri",
    value: "Uri",
  },
  {
    key: "Valais",
    text: "Valais",
    value: "Valais",
  },
  {
    key: "Vaud",
    text: "Vaud",
    value: "Vaud",
  },
  {
    key: "Zug",
    text: "Zug",
    value: "Zug",
  },
  {
    key: "Zürich",
    text: "Zürich",
    value: "Zürich",
  },
  {
    key: "Österreich",
    text: "Österreich",
    value: "Österreich",
  },
  {
    key: "Deutschland",
    text: "Deutschland",
    value: "Deutschland",
  },
];

export const NoticePeriod = [
  {
    key: "Sofort",
    text: "Sofort",
    value: "Sofort",
  },
  {
    key: "1 Monat",
    text: "1 Monat",
    value: "1 Monat",
  },
  {
    key: "2 Monat",
    text: "2 Monat",
    value: "2 Monat",
  },
  {
    key: "3 Monat",
    text: "3 Monat",
    value: "3 Monat",
  },
  {
    key: "6 Monat",
    text: "6 Monat",
    value: "6 Monat",
  },
  {
    key: "12 Monat",
    text: "12 Monat",
    value: "12 Monat",
  },
];

export const Cantons2 = [
  {
    key: "Aargau",
    label: "Aargau",
    value: "Aargau",
  },
  {
    key: "Appenzell Innerrhoden",
    label: "Appenzell Innerrhoden",
    value: "Appenzell Innerrhoden",
  },
  {
    key: "Appenzell Ausserrhoden",
    label: "Appenzell Ausserrhoden",
    value: "Appenzell Ausserrhoden",
  },
  {
    key: "Basel-Stadt",
    label: "Basel-Stadt",
    value: "Basel-Stadt",
  },
  {
    key: "Basel-Landschaft",
    label: "Basel-Landschaft",
    value: "Basel-Landschaft",
  },
  {
    key: "Bern",
    label: "Bern",
    value: "Bern",
  },
  {
    key: "Fribourg",
    label: "Freiburg",
    value: "Fribourg",
  },
  {
    key: "Genève",
    label: "Genf",
    value: "Genève",
  },
  {
    key: "Glarus",
    label: "Glarus",
    value: "Glarus",
  },
  {
    key: "Graubünden",
    label: "Graubünden",
    value: "Graubünden",
  },
  {
    key: "Jura",
    label: "Jura",
    value: "Jura",
  },
  {
    key: "Luzern",
    label: "Luzern",
    value: "Luzern",
  },
  {
    key: "Neuchâtel",
    label: "Neuchâtel",
    value: "Neuchâtel",
  },
  {
    key: "Nidwalden",
    label: "Nidwalden",
    value: "Nidwalden",
  },
  {
    key: "Obwalden",
    label: "Obwalden",
    value: "Obwalden",
  },
  {
    key: "Schaffhausen",
    label: "Schaffhausen",
    value: "Schaffhausen",
  },
  {
    key: "Schwyz",
    label: "Schwyz",
    value: "Schwyz",
  },
  {
    key: "Solothurn",
    label: "Solothurn",
    value: "Solothurn",
  },
  {
    key: "St. Gallen",
    label: "St. Gallen",
    value: "St. Gallen",
  },
  {
    key: "Thurgau",
    label: "Thurgau",
    value: "Thurgau",
  },
  {
    key: "Ticino",
    label: "Ticino",
    value: "Ticino",
  },
  {
    key: "Uri",
    label: "Uri",
    value: "Uri",
  },
  {
    key: "Valais",
    label: "Wallis",
    value: "Valais",
  },
  {
    key: "Vaud",
    label: "Waadt",
    value: "Vaud",
  },
  {
    key: "Zug",
    label: "Zug",
    value: "Zug",
  },
  {
    key: "Zürich",
    label: "Zürich",
    value: "Zürich",
  },
  {
    key: "Österreich",
    label: "Österreich",
    value: "Österreich",
  },
  {
    key: "Deutschland",
    label: "Deutschland",
    value: "Deutschland",
  },
  {
    key: "Frankreich",
    label: "Frankreich",
    value: "Frankreich",
  },
  {
    key: "Italien",
    label: "Italien",
    value: "Italien",
  },
  {
    key: "Liechtenstein",
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    key: "Ausland",
    label: "Ausland",
    value: "Ausland",
  },
];

export const companyConsultants = [
  {
    key: "5dbc7b763a44e9004fcb0594",
    label: "Mischa Weiss",
    value: "Mischa Weiss",
  },
  {
    key: "610aa6018165a000136fa191",
    label: "Rita Popp",
    value: "Rita Popp",
  },
  {
    key: "615420cb8b50620013921c99",
    label: "Manuela Brändli",
    value: "Manuela Brändli",
  },
  {
    key: "63b43253583fa20023aa4b2c",
    label: "Paula Küttel",
    value: "Paula Küttel",
  },
  {
    key: "63b98ee032c8b70022ac2ea7",
    label: "Laila Crivelli",
    value: "Laila Crivelli",
  },
  {
    key: "5dbb5d7719eb81004ee98cf5",
    label: "Kristian Oroshi",
    value: "Kristian Oroshi",
  },
  {
    key: "5dbb5bf519eb81004ee98cf1",
    label: "Marija Milic",
    value: "Marija Milic",
  },

  {
    key: "5f6decd8f7cc32001c18fef2",
    label: "Sabrina Tittmann",
    value: "Sabrina Tittmann",
  },
  {
    key: "5df22b6ae7d2a60055fb7080",
    label: "Sandro De simon",
    value: "Sandro De simon",
  },
  {
    key: "5dbc41db3a44e9004fcb0591",
    label: "Sandro Trovato",
    value: "Sandro Trovato",
  },
  {
    key: "621b5ce1b81311001c920699",
    label: "Carmen Ballauf",
    value: "Carmen Ballauf",
  },
  {
    key: "61c36020d4ed6d001ca03f27",
    label: "Tugba Pamuk",
    value: "Tugba Pamuk",
  },
  {
    key: "627512f54af3b70013fdfffc",
    label: "Karin Hangartner",
    value: "Karin Hangartner",
  },
  {
    key: "62d003c4a2172f001d1a4584",
    label: "Natalie Plöschberger",
    value: "Natalie Plöschberger",
  },
  {
    key: "642d75b8505e1300212821c1",
    label: "Leana Meier",
    value: "Leana Meier",
  },
  {
    key: "6350f94ef47c6a0016f96318",
    label: "Jasmin Trampitsch",
    value: "Jasmin Trampitsch",
  },
  {
    key: "6502ad1bd761a9d31ac77c14",
    label: "Devrim Cimen",
    value: "Devrim Cimen",
  },
  {
    key: "63033740cbf0ed0016b1656b",
    label: "Marco Mumenthaler",
    value: "Marco Mumenthaler",
  },
  {
    key: "64c22c8ce91fb952b637c96f",
    label: "Michelle Bleiker",
    value: "Michelle Bleiker",
  },
];
export const linkedInAccounts = [
  {
    key: "MW",
    label: "MW",
    value: "MW",
  },
  {
    key: "ZM",
    label: "ZM",
    value: "ZM",
  },
  {
    key: "AO",
    label: "AO",
    value: "AO",
  },
  {
    key: "KO",
    label: "KO",
    value: "KO",
  },
];

export default {
  Cantons,
  Cantons2,
  Gender,
  JobTypesSelect,
  JobTypes,
};
