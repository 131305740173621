import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  useTheme,
  TextField,
} from "@material-ui/core";
import Editor from "../../Editor";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Draggable from "react-draggable";

function PaperComponent(props) {
  const theme = useTheme();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{ boxShadow: theme.shadows[24], borderWidth: 2 }}
      />
    </Draggable>
  );
}

function Add({ handleSubmit, data }) {
  const [open, setOpen] = useState(false);

  const [note, setNote] = useState("");
  const [title, setTitle] = useState("");
  useEffect(() => {
    setNote(data?.note);
    setTitle(data?.title);
  }, [data]);

  const onSubmit = () => {
    handleSubmit({ note, title }, data._id);
    setOpen(false);
    setNote("");
    setTitle("");
  };

  return (
    <>
      <Tooltip title="Edit Note">
        <IconButton onClick={() => setOpen(true)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        BackdropProps={{
          open: false,
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Edit Note
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={title}
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginBottom: 15 }}
          />
          <Editor value={note} onChange={(value) => setNote(value)} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" variant="outlined" onClick={onSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Add;
