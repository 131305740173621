/* eslint-disable eqeqeq */
import {
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Chip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import { V3 } from "../lib/api";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CountUp from "react-countup";
import Header from "../components/Page/Header";
import { useSelector } from "react-redux";

import EditPipeline from "../components/Pipelines/Edit";
import PipelinesChart from "../components/Pipelines/PipelinesChart";
import { Link } from "react-router-dom";
const Status = styled.div`
  width: 100%;
  display: block;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  background: #ccc;
  overflow: hidden;

  ${(props) => props.status === 1 && "    background: #ccc;"}
  ${(props) =>
    props.status === 2 &&
    `
    background: #ff5a5d;
  `};
  ${(props) =>
    props.status === 3 &&
    `
    background: #534c98;
  `};
  ${(props) =>
    props.status === 4 &&
    `
    background: #f87829;
  `};
  ${(props) =>
    props.status === 5 &&
    `
    background: #0080ff;
  `};
  ${(props) =>
    props.status === 6 &&
    `
    background: #41d7ba;
    overflow:hidden;
  `};
  ${(props) =>
    props.status === 7 &&
    `
    background: #4dcffe;
  `};
  ${(props) =>
    props.status === 8 &&
    `
    background: #ff5f5f;
  `};
`;
function getStatusName(id) {
  switch (id) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf";
    case "3":
      return "Ersttermin";
    case "4":
      return "Nachfassen am";
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "In Verhandlung";
    case "8":
      return "Nicht Verlängert";
    default:
      return "No Status";
  }
}
function Wrapper({ size, title, loading }) {
  return (
    <Paper
      style={{
        padding: 15,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {loading ? (
        <CircularProgress style={{ margin: 18 }} />
      ) : (
        <h1>
          <CountUp end={size} />{" "}
        </h1>
      )}

      <h3 style={{ fontSize: 16 }}>{title}</h3>
    </Paper>
  );
}

function Pipeline() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [pipelines, setPipelines] = useState([]);
  const [filters, setFilters] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [recruiters, setRecruiter] = useState([]);
  const [filterDate, setFilterDate] = useState(
    moment(new Date()).startOf("month")
  );
  const [endDate, setEndDate] = useState(moment(new Date()).endOf("month"));
  const [statistic, setStatistic] = useState({});
  const [chance, setChance] = useState(null);

  const { user } = useSelector((state) => state.auth);

  const [chartQuery, setChartQuery] = useState("");

  useEffect(() => {
    if (filters) {
      let queryString = filters.filtered
        .map((key) => key.id + "=" + key.value)
        .join("&");
      let query = `?limit=1000&page=${filters.page + 1}${
        filterDate && moment(filterDate).isValid()
          ? `&startDate=${moment(filterDate).format("DD/MM/YYYY")}`
          : ""
      }${
        endDate && moment(endDate).isValid()
          ? `&endDate=${moment(endDate).format("DD/MM/YYYY")}`
          : ""
      }${chance ? `&chance=${chance}` : ""}&${queryString}`;
      setChartQuery(query);

      getJobCandidates(query);
    }
  }, [filters, filterDate, endDate, chance]);
  useEffect(() => {
    V3.get(`/crm/companies?limit=1000&sort=name`).then((res) => {
      setCompanies(res.data.data);

      V3.get(`/users?limit=1000&roles=admin,editor,employee&sales=true`).then(
        (recruiterRes) => {
          setRecruiter(recruiterRes.data.data);
        }
      );
    });
  }, []);

  const getJobCandidates = (query) => {
    setLoading(true);
    V3.get(`/potentials${query}`).then((response) => {
      setData(response.data);
      setPipelines(response.data.data);
      setStatistic(response.data.statistic);
      setLoading(false);
    });
  };

  const handleUpdate = (id, data) => {
    setPipelines(
      pipelines.map((el) => {
        if (el._id === id) {
          return data;
        } else {
          return el;
        }
      })
    );
  };
  const columns = [
    {
      id: "company",
      Header: "Company",
      accessor: (d) => d.company?.name,
      Cell: (props) => (
        <Link
          to={`/crm/company/${props.original.company?._id}`}
          target="_blank"
        >
          <strong>{props.original.company?.name}</strong>
        </Link>
      ),
      filterable: true,

      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? companies.find((company) => company._id === filter.value)
              : null
          }
          options={companies}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "user",
      Header: "User",
      accessor: (d) => d.user?._id,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? recruiters.find((company) => company._id === filter.value)
              : null
          }
          options={recruiters}
          getOptionLabel={(option) =>
            `${option?.profile?.firstname} ${option?.profile?.lastname}`
          }
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
      Cell: (props) => (
        <strong>
          {props.original.user?.profile?.name
            ? props.original.user?.profile?.name
            : `${props.original.user?.profile?.firstname} ${props.original.user?.profile?.lastname}`}
        </strong>
      ),
    },
    {
      id: "package",
      Header: "Paket",
      accessor: (d) => d.package,
      Cell: (props) => <>CHF {props.original.package || 0}</>,
      filterable: false,
    },
    {
      id: "activeServices",
      Header: "ZDL",
      accessor: (d) => d.activeServices,
      Cell: (props) => <>CHF {props.original.activeServices || 0}</>,
      filterable: false,
    },
    {
      id: "totalAmount",
      Header: "Total",
      accessor: (d) => d.totalAmount,
      Cell: (props) => <>CHF {props.original.totalAmount || 0}</>,
      filterable: false,
    },
    {
      id: "chance",
      Header: "Chance",
      accessor: (d) => d.chance,
      Cell: (props) => <>{props.original.chance || 0}%</>,
      filterable: false,
    },
    {
      id: "prognose",
      Header: "Prognose",
      accessor: (d) => d.prognose,
      Cell: (props) => <>CHF {props.original.prognose || 0}</>,
      filterable: false,
    },

    {
      id: "status",
      Header: "Status",
      accessor: "status",

      Cell: (props) => (
        <Status status={props.original.statusType}>
          {getStatusName(props.original.statusType)}
        </Status>
      ),

      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="1">No Status</option>
          <option value="2">Kein Verkauf</option>
          <option value="3">Ersttermin</option>
          <option value="4">Nachfassen am</option>
          <option value="5">Offerte</option>
          <option value="6">Erfolgreich abgeschlossen</option>
          <option value="7">In Verhandlung</option>
          <option value="8">Nicht Verlängert</option>
        </select>
      ),
    },
    {
      id: "available",
      Header: "Kommt im",
      filterable: false,
      accessor: (d) => d.available,
      Cell: (props) => (
        <>{moment(props.original.available).format("MM/YYYY")}</>
      ),
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <EditPipeline data={props.original} handleUpdate={handleUpdate} />
        </Actions>
      ),
    },
  ];
  return (
    <>
      <Header title="Pipelines">
        <>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Month"
            format="dd/MM/yyyy"
            value={filterDate}
            onChange={(date) => setFilterDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{ marginRight: 15 }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Month"
            format="dd/MM/yyyy"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </>
      </Header>

      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} md={2}>
          <Wrapper
            size={statistic ? statistic.package : "0"}
            title="Paket"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Wrapper
            size={statistic ? statistic.activeServices : "0"}
            title="ZDL"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Wrapper
            size={statistic ? statistic.totalAmount : "0"}
            title="Total"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Wrapper
            size={statistic ? statistic.prognose : "0"}
            title="Prognose"
            loading={loading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Chip
                label="0-30"
                clickable
                color={chance == "0-30" ? "primary" : ""}
                onClick={() => {
                  if (chance == "0-30") {
                    setChance(null);
                  } else {
                    setChance("0-30");
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Chip
                label="31-50"
                clickable
                color={chance == "31-50" ? "primary" : ""}
                onClick={() => {
                  if (chance == "31-50") {
                    setChance(null);
                  } else {
                    setChance("31-50");
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Chip
                label="51-80"
                clickable
                color={chance == "51-80" ? "primary" : ""}
                onClick={() => {
                  if (chance == "51-80") {
                    setChance(null);
                  } else {
                    setChance("51-80");
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Chip
                label="81-100"
                clickable
                color={chance == "81-100" ? "primary" : ""}
                onClick={() => {
                  if (chance == "81-100") {
                    setChance(null);
                  } else {
                    setChance("81-100");
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PipelinesChart query={chartQuery} />
      <Table
        data={pipelines}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => setFilters(state)}
        filterable={true}
        noDataText="Keine Kandidate"
        defaultFiltered={[
          {
            id: "user",
            value: user._id,
          },
        ]}
      />
    </>
  );
}

export default Pipeline;
