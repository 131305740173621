import React, { useEffect, useState } from "react";
import Table from "../../Table";
import { Link } from "react-router-dom";
import { V3 } from "../../../lib/api";
import { Button, Grid } from "@material-ui/core";
import Header from "../../Page/Header";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

const SharedJobsTable = () => {
  const [filters, setFilters] = useState({ filtered: [], page: 0 });

  const [data, setData] = useState({});
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(3, "months")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  useEffect(() => {
    setLoading(true);
    var queryString = filters.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filters.page + 1}&${queryString}`;
    V3.get(`shared-links/jobs${query}`).then((res) => {
      setData(res.data);
      setJobs(res.data.data);
      setLoading(false);
    });
  }, [filters]);

  const handleExport = () => {
    setLoading(true);
    var queryString = filters.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filters.page + 1}&${queryString}`;
    V3.get(`shared-links/jobs/export${query}`).then((res) => {
      window.open(res?.data?.excelFile?.url, "_blank");
      setLoading(false);
    });
  };

  const columns = [
    {
      id: "job",
      Header: "Job",
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <strong>
          <Link
            to={`/jobs/edit/${original ? original._id : ""}`}
            target="_blank"
          >
            {original?.job?.title || "-"}
          </Link>
        </strong>
      ),
    },
    {
      id: "job",
      Header: "Shared",
      accessor: (d) => d._id,
      Cell: ({ original }) => <strong>{original?.sharedJobs || "-"}</strong>,
    },
  ];

  return (
    <>
      <Header title="SharedJobs" size={data.total}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <KeyboardDatePicker
              id="date-picker-dialog"
              label="From"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
            <Button
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              onClick={() => handleExport()}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Header>
      <Table
        data={jobs}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setFilters(state);
        }}
        noDataText="No shared links"
        defaultFiltered={[
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
};

export default SharedJobsTable;
