import * as React from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DayView,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AllDayPanel,
  AppointmentTooltip,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { connectProps } from "@devexpress/dx-react-core";

import Paper from "@material-ui/core/Paper";
import { priorities } from "../components/Calendar/tasks";
import moment from "moment";
import TooltipContent from "../components/Calendar/Tooltip";
import FlexibleSpace from "../components/Calendar/TypeSelector";
import { V3 } from "../lib/api";
import { useEffect } from "react";
import { useState } from "react";
const filterData = (items, type) =>
  items.filter((item) => !type || item.type === type);

const makeQueryString = (currentDate, currentViewName) => {
  const format = "YYYY-MM-DDTHH:mm:ss";
  const start = moment(currentDate).startOf(currentViewName.toLowerCase());
  const end = start.clone().endOf(currentViewName.toLowerCase());
  return encodeURI(
    `/users/calendar?start=${start.format(format)}&end=${end.format(format)}`
  );
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentViewName, setCurrentViewName] = useState("Week");
  const [data, setData] = useState([]);
  const [currentPriority, setCurrentPriority] = useState(0);
  const [resources, setResources] = useState([
    {
      fieldName: "type",
      title: "Priority",
      instances: priorities,
    },
  ]);

  const getCalendar = () => {
    const queryString = makeQueryString(currentDate, currentViewName);

    V3.get(queryString).then((res) => {
      setData(res?.data?.data);
    });
  };

  const currentViewNameChange = (newCurrentViewName) => {
    setCurrentViewName(newCurrentViewName);
    getCalendar();
  };
  const currentDateChange = (newCurrentDate) => {
    setCurrentDate(newCurrentDate);
    getCalendar();
  };

  const priorityChange = (value) => {
    const nextResources = [
      {
        ...resources[0],
        instances: value > 0 ? [priorities[value - 1]] : priorities,
      },
    ];
    setCurrentPriority(value);
    setResources(nextResources);
  };
  const flexibleSpace = connectProps(FlexibleSpace, () => {
    return {
      priority: currentPriority,
      priorityChange: priorityChange,
    };
  });

  useEffect(() => {
    getCalendar();
    flexibleSpace.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      style={{ height: "calc(100vh - 64px)", margin: -15, borderRadius: 0 }}
    >
      <Scheduler data={filterData(data, currentPriority)}>
        <ViewState
          currentDate={currentDate}
          currentViewName={currentViewName}
          onCurrentViewNameChange={currentViewNameChange}
          onCurrentDateChange={currentDateChange}
        />

        <DayView />
        <WeekView />
        <MonthView />
        <AllDayPanel />
        <Appointments />
        <Resources data={resources} />
        <Toolbar flexibleSpaceComponent={flexibleSpace} />
        <DateNavigator />
        <ViewSwitcher />
        <AppointmentTooltip contentComponent={TooltipContent} />
      </Scheduler>
    </Paper>
  );
};

export default Calendar;
