import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

function WarningMessage({ open, setOpen }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <p>This Company has no available jobs to publish</p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WarningMessage;
