import * as React from "react";
const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={20} height={20} x={0.625} fill="#98A2B3" rx={10} />
    <g fill="#fff" clipPath="url(#a)">
      <path d="M10.625 5.08c1.603 0 1.793.008 2.423.036.586.025.903.124 1.114.206.279.108.48.239.689.448.21.21.34.41.447.689.082.21.18.53.207 1.113.028.633.035.823.035 2.423 0 1.603-.007 1.793-.035 2.424-.026.586-.125.902-.207 1.113-.107.279-.239.48-.447.69-.211.21-.41.339-.69.447-.21.082-.529.18-1.113.206-.632.028-.822.035-2.423.035-1.603 0-1.793-.007-2.423-.035-.586-.026-.903-.124-1.114-.206a1.857 1.857 0 0 1-.689-.448c-.21-.21-.34-.41-.447-.689-.082-.21-.18-.53-.207-1.113-.028-.633-.035-.823-.035-2.424 0-1.603.007-1.793.035-2.423.026-.586.125-.902.207-1.113.107-.28.239-.48.447-.69.211-.21.41-.34.69-.447.21-.082.529-.18 1.113-.206.63-.028.82-.036 2.423-.036Zm0-1.08c-1.629 0-1.833.007-2.473.035-.637.028-1.075.131-1.455.28a2.929 2.929 0 0 0-1.064.693 2.94 2.94 0 0 0-.694 1.062c-.148.382-.25.817-.279 1.455-.028.642-.035.846-.035 2.475s.007 1.833.035 2.473c.028.637.131 1.075.28 1.455.154.396.358.731.693 1.064.333.333.668.54 1.062.692.382.147.817.25 1.455.279.64.028.844.035 2.473.035s1.833-.007 2.472-.035c.638-.029 1.076-.132 1.456-.28a2.934 2.934 0 0 0 1.062-.69c.332-.334.539-.669.69-1.063.149-.382.252-.817.28-1.455.028-.64.035-.844.035-2.473s-.007-1.832-.035-2.472c-.028-.638-.131-1.076-.28-1.456a2.811 2.811 0 0 0-.686-1.066 2.933 2.933 0 0 0-1.062-.692c-.382-.147-.817-.25-1.455-.279-.642-.03-.846-.037-2.475-.037Z" />
      <path d="M10.625 6.918a3.083 3.083 0 1 0 .002 6.166 3.083 3.083 0 0 0-.002-6.166Zm0 5.081a2 2 0 1 1 0-3.999 2 2 0 0 1 0 4ZM14.548 6.796a.72.72 0 1 1-1.439 0 .72.72 0 0 1 1.44 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4.625 4h12v12h-12z" />
      </clipPath>
    </defs>
  </svg>
);
export default Instagram;
