import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { size, toArray } from "lodash";
import UploadItem from "./UploadItem";
import styled from "styled-components";
import {
  FAILURE_UPLOAD_FILE,
  SET_UPLOADED_MEDIA,
  SET_UPLOAD_PROGRESS,
  SUCCESS_UPLOAD_FILE,
} from "../../../redux/types";
import { V3 } from "../../../lib/api";
const ImageCard = styled.div`
  border: 2px solid ${(props) => props.theme.palette.divider};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 5px;
  .img {
    height: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  &:hover {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const MediaWrapper = styled.ul`
  display: flex !important;
  flex-wrap: wrap !important;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    width: 10%;
    padding: 10px !important;
    position: relative;
    cursor: pointer;

    &.selected > div {
      border: 2px solid ${(props) => props.theme.palette.primary.main};
      box-shadow: 0 0 0 3px ${(props) => props.theme.palette.primary.main} !important;
      outline: 2px solid transparent;
      outline-offset: -6px;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
`;

const UploadProgress = (props) => {
  const { library } = props;

  const { fileProgress } = useSelector((state) => state.UploadFile);
  const uploadedFileAmount = size(fileProgress);
  const [selected, setSelected] = React.useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    props.updatedSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(
      (file) => file.progress === 0
    );
    uploadFile(fileToUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFileAmount]);

  const uploadFile = (files) => {
    if (files.length) {
      files.forEach(async (file) => {
        const formPayload = new FormData();
        formPayload.append("images", file.file);

        try {
          await V3.post("/medias", formPayload, {
            onUploadProgress: (progress) => {
              const { loaded, total } = progress;

              const percentageProgress = Math.floor((loaded / total) * 100);
              dispatch({
                type: SET_UPLOAD_PROGRESS,
                payload: {
                  id: file.id,
                  percentageProgress,
                },
              });
            },
          }).then((res) => {
            const fileData = res.data.data.find(
              (media) => media.fileName === file.file.name
            );
            dispatch({
              type: SET_UPLOADED_MEDIA,
              payload: {
                id: file.id,
                data: fileData,
              },
            });
          });
          dispatch({
            type: SUCCESS_UPLOAD_FILE,
            payload: file.id,
          });
        } catch (error) {
          console.log("error", error.response);
          dispatch({
            type: FAILURE_UPLOAD_FILE,
            payload: file.id,
          });
        }
      });
    }
  };

  return (
    <MediaWrapper>
      {uploadedFileAmount > 0 && (
        <>
          {size(fileProgress)
            ? toArray(fileProgress)
                .reverse()
                .map((file) => {
                  if (!file.data) {
                    return (
                      <li
                        className="disabled"
                        key={file.id}
                        onClick={(e) => setSelected(file.data)}
                      >
                        <UploadItem file={file} />
                      </li>
                    );
                  }
                  return (
                    <li
                      className={
                        selected && selected._id === file.data._id
                          ? "selected"
                          : ""
                      }
                      key={file.id}
                      onClick={(e) => setSelected(file.data)}
                    >
                      <UploadItem file={file} />
                    </li>
                  );
                })
            : null}
        </>
      )}

      {library.map((media) => (
        <li
          className={selected && selected._id === media._id ? "selected" : ""}
          key={media._id}
          onClick={(e) => setSelected(media)}
        >
          <ImageCard>
            <div
              className="img"
              style={{ background: `url(${media?.sm?.url})` }}
            />
          </ImageCard>
        </li>
      ))}
    </MediaWrapper>
  );
};

export default UploadProgress;
