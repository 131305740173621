import React, { useState } from "react";
import { useEffect } from "react";

import useSocket from "../../hooks/useSocket";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

function UserInJob(props) {
  const { socket } = useSocket();
  const { user } = useSelector((state) => state.auth);

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (props.match.params.id) {
      socket.emit("joinJob", {
        job: props.match.params.id,
        user,
      });

      socket.on("newUsersInJob", (data) => {
        setActiveUsers(data.users);
      });
    }

    if (window) {
      // run event when tab closes
      window.addEventListener("beforeunload", (event) => {
        socket.emit("removeMeFromJob", {
          job: props.match.params.id,
          user,
        });
      });
    }

    return () => {
      socket.off("newUserInJob");

      socket.emit("removeMeFromJob", {
        job: props.match.params.id,
        user,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  return (
    <div style={{ marginTop: 15 }}>
      {activeUsers
        ?.filter((el) => el?.author?._id !== user._id)
        ?.map((el) => (
          <Alert key={el?._id} style={{ marginTop: 15 }}>
            {el?.author?.profile?.name} is working in this job
          </Alert>
        ))}
    </div>
  );
}

export default withRouter(UserInJob);
