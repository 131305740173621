import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import MUITextField from "@material-ui/core/TextField";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { useDispatch } from "react-redux";
import { CONTACTING_LOADING } from "../../../redux/types";
import { DatePicker } from "material-ui-formik-components/DatePicker";

function New({ add, job }) {
  const [open, setOpen] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [linkedIn, setLinkedIn] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    V3.get(`/users?limit=1000&roles=admin,employee,editor`).then(
      (recruiterRes) => {
        setRecruiters(recruiterRes.data.data);
      }
    );
    V3.get(`/linkedin-accounts/all`).then((res) => {
      setLinkedIn(res.data.data);
    });
  }, []);

  const handleSubmit = (values, formikApi) => {
    dispatch({
      type: CONTACTING_LOADING,
    });
    const data = {
      ...values,
      recruiter: values.recruiter._id,
      job,
      linkedInAccount: values?.linkedInAccount?.key
        ? values?.linkedInAccount?.key
        : null,
    };

    V3.post(`/contactings`, data).then((res) => {
      add(res.data);
      formikApi.setSubmitting(false);
      setOpen(false);
    });
  };

  const linkedInAccounts = linkedIn.map((el) => {
    return {
      key: el._id,
      value: `${el.accountNumber} ${el.accountName}`,
      label: `${el.accountNumber} ${el.accountName}`,
    };
  });

  return (
    <>
      <Button
        variant="outlined"
        style={{ backgroundColor: "#f2b413", color: "#fff" }}
        onClick={() => setOpen(true)}
      >
        New
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Add new Week</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              week: Yup.number().min(1).required("Required"),
              toContact: Yup.number().min(0).required("Required"),
              contacted: Yup.number().min(0).required("Required"),
              year: Yup.date().required("Required"),
              recruiter: Yup.object()
                .typeError("Please select a recruiter")
                .nullable()
                .required("Required"),
              linkedInAccount: Yup.object()
                .typeError("Please select a Account")
                .nullable(),
              note: Yup.string().nullable(),
            })}
            onSubmit={handleSubmit}
            initialValues={{ contacted: 0, note: "", year: new Date() }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="week"
                      label="Week"
                      name="week"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="contacted"
                      label="Contacted"
                      name="contacted"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="toContact"
                      label="To Contact"
                      name="toContact"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MUIAutocomplete
                      onChange={(event, value) =>
                        setFieldValue("recruiter", value)
                      }
                      style={{ width: "100%" }}
                      value={values.recruiter}
                      options={recruiters}
                      getOptionLabel={(option) => option?.profile?.name || "-"}
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          variant="outlined"
                          label="Recruiter"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="linkedInAccount"
                      options={linkedInAccounts}
                      component={Autocomplete}
                      textFieldProps={{
                        label: "Account",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={DatePicker}
                      name="year"
                      id="year"
                      label="Year"
                      inputVariant="outlined"
                      fullWidth
                      format="yyyy"
                      views={["year"]}
                      openTo="year"
                      autoOk
                      disableToolbar
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="note"
                      label="Reminder - Kandidatenpool und Interessentenpool nutzen"
                      name="note"
                      style={{ margin: 0 }}
                      multiline
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button color="primary" autoFocus type="submit">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default New;
