import React, { useState } from "react";

import Table from "../components/Table";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import AddNewPartner from "../components/Partner/New";
import { V3 } from "../lib/api";
function Partners() {
  const [partners, setPartners] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = (info) => {
    confirm({
      title: `Delete partner ${info.name}`,
      description: "You wont be able to restore this partner anymore!",
    }).then(() => {
      setLoading(true);
      V3.delete(`/partners/${info._id}`)
        .then((res) => {
          setPartners(partners.filter((el) => el._id !== info._id));
          enqueueSnackbar("Partner deleted successfully", {
            variant: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "success",
          });
          setLoading(false);
        });
    });
  };

  const handleAdd = (data) => {
    setPartners([data, ...partners]);
  };

  const columns = [
    {
      id: "image",
      Header: "Bild",
      width: 300,
      accessor: (d) => d._id,
      Cell: ({ original }) => (
        <img
          src={original.image ? original.image.xs.url : "null"}
          alt={original.image ? original.image.xs.url : "null"}
        />
      ),
    },
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.name}</strong>,
    },

    {
      id: "createdAt",
      Header: "Datum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },

    {
      id: "actions",
      Header: "Aktionen",
      accessor: (d) => d._id,
      Cell: (props) => (
        <IconButton onClick={(e) => handleDelete(props.original)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Header title="Partners" size={data.total}>
        <AddNewPartner handleAdd={handleAdd} />
      </Header>
      <Table
        data={partners}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);

          V3.get(`/partners${query}`).then((res) => {
            setData(res.data);
            setPartners(res.data.data);
            setLoading(false);
          });
        }}
        filterable={false}
        noDataText="No partners"
      />
    </>
  );
}
export default Partners;
