import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobTypesSelect } from "../../../data";
import Loader from "../../../components/Loader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import LocationPicker from "../../../components/LocationPicker";
import Moment from "react-moment";
import Card from "../../../components/Card";
import Editor from "../../../components/Editor";
import Slug from "../edit/slug";
import Company from "../../../components/Modals/Companies/index";
import { DASHBOARD } from "../../../config";
import styled from "styled-components";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { Select } from "material-ui-formik-components/Select";
import KeyboardDatePicker from "../../crm/candidates/KeyboardDatePicker";
import MediaChoose from "../../../components/Media/Chooser";
import Video from "../../../components/Job/Video";
import MUITextField from "@material-ui/core/TextField";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { Cantons2 } from "../../../data";

import UploadImage from "../UploadImage";
import { V3 } from "../../../lib/api";
import UploadVideo from "../UploadVideo";

import Publish from "../../../components/Job/Publish";
import TagsInput from "../../../components/Job/TagInput";
import NewJobEmail from "../../../components/Notify/NewJobEmail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { FormHelperText } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { UPDATE_JOB } from "../../../redux/types";
import { useParams } from "react-router-dom";
import HashtagSelector from "../../../components/Job/HashtagSelector";
import GalleryBuilder from "../../../components/GalleryBuilder";

const PhoneWrapper = styled.div`
  input {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
`;

const Avatar = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 10;
  margin-top: -40px;
  margin-left: 15px;
  position: relative;
  img {
    width: 100%;
  }
`;

const StyledLink = styled.a`
  margin: 0px;
  margin-right: 15px;
  min-width: 200px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

function FeaturedImage({ values, setFieldValue, errors, touched }) {
  const [selectImage, setSelectedImage] = React.useState(values.shareImage);

  React.useEffect(() => {
    setFieldValue("shareImage", selectImage ? selectImage._id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <>
      {" "}
      <Paper
        style={
          errors?.shareImage && touched.shareImage ? { borderColor: "red" } : {}
        }
      >
        <MediaChoose onChange={setSelectedImage}>
          <div
            style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}
          >
            {selectImage ? (
              <Preview>
                <img
                  src={selectImage ? selectImage.lg.url : ""}
                  alt={selectImage ? selectImage.lg.url : ""}
                />
              </Preview>
            ) : (
              <p style={{ textAlign: "center" }}>
                Klicken Sie, um ein Bild auszuwählen
              </p>
            )}
          </div>
        </MediaChoose>
        {selectImage && (
          <div style={{ padding: 15 }}>
            <Button
              onClick={(e) => setSelectedImage(null)}
              fullWidth
              variant="outlined"
            >
              Aktuelles Bild entfernen
            </Button>
          </div>
        )}
      </Paper>
      {errors?.shareImage && touched.shareImage ? (
        <FormHelperText error>{errors?.shareImage}</FormHelperText>
      ) : null}
    </>
  );
}

function EditJob() {
  const { job, loading } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth);
  const [pdf, setPdf] = useState(null);
  const [company, setCompany] = useState(null);
  const [categories, setCategories] = useState([]);
  const [address, setAddress] = useState(null);

  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { id: jobID } = useParams();

  useEffect(() => {
    const prevTitle = document?.title;
    if (job && document) {
      document.title = job?.title;
    }

    return () => {
      document.title = prevTitle;
    };
  }, [job]);

  const handlePdfChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    V3.post(`/jobs/pdf`, formData).then((res) => {
      setPdf(res?.data?.data);
    });
  };

  const getCanton = (value) => {
    if (!value) {
      return null;
    }
    let el = null;
    Cantons2.forEach((element) => {
      if (element.key === value) {
        el = element;
      }
    });
    return el;
  };
  useEffect(() => {
    V3.get(`/categories/job`).then((response) => {
      setCategories(response.data);
    });
    V3.get(`/users?company=${job?.company?._id}&limit=400`).then((response) => {
      const usersData = response.data.data.map((user) => {
        return {
          key: user._id,
          value: user.profile.name,
          label: user.profile.name,
        };
      });

      setUsers(usersData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading === false && job) {
      if (job.location) {
        setAddress(!job?.location ? "" : job?.location?.formattedAddress);
      }

      setCompany(job.company);
      setPdf({
        url: job && job.pdfUrl,
        text: job && job.pdfExtract,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const handleCompanyChange = (value, setFieldValue) => {
    setFieldValue(
      "sendNewJobEmailTo",
      value?.sendNewJobEmailTo?.length
        ? value?.sendNewJobEmailTo.map((user) => {
            return {
              key: user?._id,
              value: user?.profile?.name,
              label: user?.profile?.name,
            };
          })
        : []
    );
    setCompany(value);
  };

  const _handleSubmit = (values, formikAPi) => {
    try {
      let {
        type,
        category,
        ad,
        ap,
        kanton,
        hashtags,
        applyWithLinkedinUsers,
        allInOne,
        shareImage,
        sendNewJobEmailTo,
        gallery,
      } = values;

      const data = {
        ...values,
        type: type ? type.key : undefined,
        category: category ? category.key : undefined,
        company: company ? company._id : undefined,
        pdfUrl: pdf?.url,
        pdfExtract: pdf?.text,
        kanton: kanton?.key,
        shareImage: shareImage?._id ? shareImage._id : shareImage,
        services: {
          ad,
          ap,
          allInOne,
        },
        address,
        hashtags: hashtags.map((el) => el._id),
        gallery: gallery.map((el) => el._id),

        applyWithLinkedinUsers: applyWithLinkedinUsers.map((el) => el.key),
        sendNewJobEmailTo: sendNewJobEmailTo.map((el) => el.key),
      };

      V3.put(`/jobs/${jobID}`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_JOB,
            payload: jobID,
            data: res.data.data,
          });

          enqueueSnackbar("Job updated successfully", {
            variant: "success",
          });
          formikAPi.setSubmitting(false);
          // push("/jobs/edit/" + jobID);
        })
        .catch((error) => {
          console.log(
            " error?.response?.data?.message",
            error?.response?.data?.message
          );
          formikAPi.setSubmitting(false);
          enqueueSnackbar(
            error?.response?.data?.message || "Something went wrong",
            {
              variant: "error",
            }
          );
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const CategoriesDrop = categories.map((category) => {
    return {
      key: category.name,
      value: category.name,
      label: category.name,
    };
  });

  return (
    <React.Fragment>
      {job && <Slug slug={job.slug} jobId={job._id} />}
      {job !== null && (
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().nullable().required("Required"),
            video: Yup.string().url(),
            type: Yup.string().nullable().required("Required"),
            category: Yup.string().nullable().required("Required"),
            share: Yup.boolean(),
            verified: Yup.boolean(),
            description: Yup.string(),
            shareImage: Yup.string().nullable().required("Required"),
            // externalApplyUrl: Yup.string().when("noExternalApplyUrl", {
            //   is: (noExternalApplyUrl) => !noExternalApplyUrl,
            //   then: Yup.string().required("Required"),
            // }),
            kanton: Yup.string().required("Required"),
            externalApplyClicks: Yup.number()
              .typeError("Required")
              .min(0)
              .required("Required"),
            externalApplyRate: Yup.number()
              .typeError("Required")
              .min(0)
              .max(100)
              .required("Required"),
            expireDate: Yup.string().nullable(),
            customCandidates: Yup.array().nullable(),
            addressTwo: Yup.string().nullable(),
            cvCandidate: Yup.array().nullable(),
            isAnonymous: Yup.boolean(),
          })}
          enableReinitialize
          onSubmit={_handleSubmit}
          initialValues={{
            title: job.title,
            video: job.video,
            video2: job.video2,
            category: {
              key: job.category,
              label: job.category,
              value: job.category,
            },
            type: {
              key: job.type,
              label: job.type,
              value: job.type,
            },
            share: job.share,
            shareTitle: job.shareTitle,
            shareDescription: job.shareDescription,
            description: job.description,
            sortDate: job.sortDate ? job.sortDate : job.createdAt,
            ad: job.services.ad,
            ap: job.services.ap,
            allInOne: job.services.allInOne,
            shareImage: job.shareImage,
            jobType: job.jobType ? job.jobType : "text",
            externalApplyUrl: job.externalApplyUrl,
            externalApplyClicks:
              job.externalApplyClicks === null ||
              job.externalApplyClicks === undefined
                ? 0
                : job.externalApplyClicks,
            externalApplyRate:
              job.externalApplyRate === null ||
              job.externalApplyRate === undefined
                ? 0
                : job.externalApplyRate,
            externalApplyEnabled:
              job.externalApplyEnabled === null ||
              job.externalApplyEnabled === undefined
                ? false
                : job.externalApplyEnabled,
            kanton: getCanton(job.kanton),
            expireDate: job.expireDate || null,
            addressTwo: job.addressTwo || null,

            applyWithLinkedin: (job && job.applyWithLinkedin) || false,
            applyWithLinkedinUsers:
              job?.applyWithLinkedinUsers?.map((el) => {
                return {
                  key: el._id,
                  value: el.profile.name,
                  label: el.profile.name,
                };
              }) || [],
            applyWithLinkedinEmails: (job && job.applyWithLinkedinEmails) || [],
            hashtags: (job && job.hashtags) || [],
            applyWithEmail: (job && job.applyWithEmail) || false,
            applyEmail: (job && job.applyEmail) || "",
            applyWithLink: (job && job.applyWithLink) || false,
            applyLink: (job && job.applyLink) || "",
            isAnonymous: (job && job.isAnonymous) || false,
            featured: (job && job.featured) || false,
            noExternalApplyUrl: job.noExternalApplyUrl || false,
            whatsapp: job.whatsapp || "41",
            contactWithWhatsapp: job.contactWithWhatsapp || false,
            applyWithVideo: job.applyWithVideo || false,
            shareWithVideo: job.shareWithVideo || false,
            applyWithExternalLink: job.applyWithExternalLink || false,
            gallery: job.gallery || [],
            sendNewJobEmailTo: job?.sendNewJobEmailTo?.length
              ? job?.sendNewJobEmailTo.map((user) => {
                  return {
                    key: user?._id,
                    value: user?.profile?.name,
                    label: user?.profile?.name,
                  };
                })
              : [],
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            handleSubmit,
            errors,
            touched,
          }) => {
            return (
              <Form>
                {isSubmitting && <Loader />}

                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Paper style={{ padding: 15, marginBottom: 15 }}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="title"
                        label="Titel"
                        name="title"
                        style={{ margin: 0 }}
                      />
                    </Paper>

                    <Card
                      title="External apply"
                      style={{ marginBottom: 15, marginTop: 15 }}
                      defaultOpen={values.externalApplyEnabled}
                      closable={false}
                      button={
                        <div style={{ width: 40, float: "right" }}>
                          <Field
                            component={Switch}
                            name="externalApplyEnabled"
                          />
                        </div>
                      }
                    >
                      <div style={{ padding: 15 }}>
                        <StyledLink
                          href={values.externalApplyUrl}
                          target="_blank"
                        >
                          {values.externalApplyUrl}
                        </StyledLink>
                      </div>
                      <div style={{ padding: 15 }}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.noExternalApplyUrl}
                                onChange={(e) =>
                                  setFieldValue(
                                    "noExternalApplyUrl",
                                    e.target.checked
                                  )
                                }
                                name="noExternalApplyUrl"
                              />
                            }
                            label="No External Apply Url"
                          />
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="External apply url"
                            name="externalApplyUrl"
                            style={{ margin: 0 }}
                            disabled={values.noExternalApplyUrl}
                          />
                        </div>

                        {user &&
                          (user.email === "kristian.oroshi@two.jobs" ||
                            user.email === "admin@test.com") && (
                            <>
                              <Field
                                component={TextField}
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="External apply clicks"
                                name="externalApplyClicks"
                              />
                              <Field
                                component={TextField}
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="External apply rate"
                                name="externalApplyRate"
                                style={{ marginBottom: 0 }}
                              />
                            </>
                          )}
                      </div>
                    </Card>

                    {values.jobType === "text" ? (
                      <Paper style={{ marginBottom: 15, overflow: "hidden" }}>
                        <Editor
                          value={values.description}
                          onChange={(value) =>
                            setFieldValue("description", value)
                          }
                        />
                      </Paper>
                    ) : (
                      <Card
                        title="Job PDF"
                        contentStyles={{ padding: 15 }}
                        button={
                          <input
                            type="file"
                            name="jobPdf"
                            onChange={handlePdfChange}
                          />
                        }
                      >
                        {pdf && (
                          <object
                            data={pdf.url}
                            type="application/pdf"
                            width="100%"
                            height="800px"
                          >
                            <embed
                              src={pdf.url}
                              type="application/pdf"
                              width="100%"
                              height="800px"
                            />
                          </object>
                        )}
                      </Card>
                    )}

                    <Video values={values} setFieldValue={setFieldValue} />

                    <UploadVideo
                      video2={values?.video2}
                      onChange={(val) => {
                        setFieldValue("video2", val);
                      }}
                    />

                    <Card
                      title="Share options"
                      style={{ marginBottom: 30, marginTop: 30 }}
                      closable={false}
                      button={
                        <div style={{}}>
                          <Field
                            component={Switch}
                            label={
                              <span style={{ paddingLeft: 8 }}>
                                Video share
                              </span>
                            }
                            name="shareWithVideo"
                          />
                        </div>
                      }
                    >
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div style={{ padding: 15 }}>
                            <FeaturedImage
                              setFieldValue={setFieldValue}
                              values={values}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div style={{ padding: 15 }}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="shareTitle"
                              label="Titel"
                              name="shareTitle"
                              style={{ marginTop: 0 }}
                            />
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="shareDescription"
                              label="Beschreibung"
                              name="shareDescription"
                              multiline
                              rows="8"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>

                    <Card
                      title="Address"
                      style={{ marginBottom: 30, marginTop: 30 }}
                      contentStyles={{ overflow: "inherit" }}
                      closable={false}
                    >
                      <Grid container spacing={2} style={{ padding: 15 }}>
                        <Grid item xs={12} md={6} lg={6}>
                          <LocationPicker
                            address={address}
                            setAddress={setAddress}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="addressTwo"
                            label="Address Two"
                            name="addressTwo"
                            style={{ margin: 0 }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <GalleryBuilder
                      images={values.gallery}
                      onChange={(value) => {
                        setFieldValue("gallery", value);
                      }}
                      onRemove={(item) => {
                        setFieldValue(
                          "gallery",
                          values.gallery.filter((i) => i._id !== item._id)
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Paper
                      style={{
                        padding: 15,
                        marginBottom: 15,
                        position: "sticky",
                        top: 80,
                        zIndex: 900,
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        // type="submit"
                        style={{ padding: "16px 32px" }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Änderungen speichern
                      </Button>
                    </Paper>
                    <Paper
                      style={{
                        padding: 15,
                        marginBottom: 15,
                        // position: "sticky",
                        top: 80,
                        zIndex: 900,
                      }}
                    >
                      <NewJobEmail job={job} />
                    </Paper>
                    <Paper style={{ padding: "15px 15px", marginBottom: 15 }}>
                      {company && (
                        <Paper
                          variant="outlined"
                          style={{
                            overflow: "hidden",
                            borderRadius: 5,
                            marginBottom: 15,
                          }}
                        >
                          <div
                            style={{
                              height: 240,
                              overflow: "hidden",
                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            F
                            <img
                              src={
                                company?.images?.coverX400
                                  ? company.images.coverX400
                                  : DASHBOARD + "/images/not-found.png"
                              }
                              alt={company.name}
                              style={{ width: "100%" }}
                            />
                          </div>

                          <Avatar>
                            <img
                              src={
                                company?.images?.avatarX128
                                  ? company.images.avatarX128
                                  : DASHBOARD + "/images/not-found.png"
                              }
                              alt={company.name}
                            />
                          </Avatar>
                          <div style={{ padding: 15 }}>
                            <h2 style={{ margin: 0 }}>{company.name}</h2>
                          </div>
                        </Paper>
                      )}

                      <Company
                        onChange={(value) =>
                          handleCompanyChange(value, setFieldValue)
                        }
                        company={company}
                      />
                    </Paper>
                    <Card title="Options" style={{ padding: "0" }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <MUIAutocomplete
                                multiple
                                options={users}
                                getOptionLabel={(option) => option?.label}
                                onChange={(e, val) =>
                                  setFieldValue("sendNewJobEmailTo", val)
                                }
                                value={values.sendNewJobEmailTo}
                                getOptionSelected={(option, value) => {
                                  return option.key === value.key;
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    placeholder="Select"
                                    variant="outlined"
                                    label="Send New Job Email To"
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Field
                                name="jobType"
                                options={[
                                  {
                                    label: "Text",
                                    value: "text",
                                    key: "text",
                                  },
                                  {
                                    label: "PDF",
                                    value: "pdf",
                                    key: "pdf",
                                  },
                                ]}
                                component={Select}
                                style={{ margin: 0 }}
                                textFieldProps={{
                                  label: "Job type",
                                  variant: "outlined",
                                }}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Created By
                            </TableCell>
                            <TableCell align="right">
                              {job.user && job.user.profile
                                ? job.user.profile.name
                                : "No User"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Publiziert am
                            </TableCell>
                            <TableCell align="right">
                              <Moment>{job.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Field
                                name="type"
                                options={JobTypesSelect}
                                component={Autocomplete}
                                textFieldProps={{
                                  label: "Typ",
                                  variant: "outlined",
                                  style: { margin: 0 },
                                }}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={2}>
                              <Field
                                name="kanton"
                                options={Cantons2}
                                component={Autocomplete}
                                textFieldProps={{
                                  label: "Kanton",
                                  variant: "outlined",
                                  style: { margin: 0 },
                                }}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={2}>
                              <Field
                                name="category"
                                options={CategoriesDrop}
                                variant="outlined"
                                component={Autocomplete}
                                style={{ margin: 0 }}
                                textFieldProps={{
                                  label: "Kategorie",
                                  variant: "outlined",
                                  style: { margin: 0 },
                                }}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={2}>
                              <HashtagSelector
                                onChange={(e, val) =>
                                  setFieldValue("hashtags", val)
                                }
                                value={values.hashtags}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Anonym
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="isAnonymous"
                                  id="isAnonymous"
                                />
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              <Publish job={job} />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Teilen Online Recruiter
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="share"
                                  id="share"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bewerben mit video
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="applyWithVideo"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bewerben ohne CV
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="applyWithLinkedin"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          {values.applyWithLinkedin && (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  colSpan={2}
                                >
                                  <MUIAutocomplete
                                    multiple
                                    options={users}
                                    getOptionLabel={(option) => option?.label}
                                    onChange={(e, val) =>
                                      setFieldValue(
                                        "applyWithLinkedinUsers",
                                        val
                                      )
                                    }
                                    value={values.applyWithLinkedinUsers}
                                    getOptionSelected={(option, value) => {
                                      return option.key === value.key;
                                    }}
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                      <MUITextField
                                        {...params}
                                        placeholder="Select"
                                        variant="outlined"
                                        label="Bewerben ohne CV Users"
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  colSpan={2}
                                >
                                  <TagsInput
                                    selectedItem={
                                      values.applyWithLinkedinEmails
                                    }
                                    setSelectedItem={(val) =>
                                      setFieldValue(
                                        "applyWithLinkedinEmails",
                                        val
                                      )
                                    }
                                    fullWidth
                                    variant="outlined"
                                    id="tags"
                                    placeholder="hinzufügen"
                                    label="Bewerben ohne CV Emails"
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bewerben via Kunden E-Mail
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="applyWithEmail"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          {values.applyWithEmail && (
                            <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  label="Apply Email"
                                  name="applyEmail"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bewerben via Kunden Link
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="applyWithLink"
                                />
                              </div>
                            </TableCell>
                          </TableRow>

                          {values.applyWithLink && (
                            <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                <Field
                                  component={TextField}
                                  type="text"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  label="Apply Link"
                                  name="applyLink"
                                  style={{ margin: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Bewerben via External Apply Url
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="applyWithExternalLink"
                                />
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Stelle zuoberst fixieren
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="featured"
                                  id="featured"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Contact With Whatsapp
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field
                                  component={Switch}
                                  name="contactWithWhatsapp"
                                  id="contactWithWhatsapp"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          {values?.contactWithWhatsapp && (
                            <TableRow>
                              <TableCell align="right" colSpan={2}>
                                <PhoneWrapper>
                                  {" "}
                                  <PhoneInput
                                    country={"us"}
                                    value={values.whatsapp}
                                    onChange={(phone) =>
                                      setFieldValue("whatsapp", phone)
                                    }
                                    style={{ width: "100%" }}
                                    fullWidth
                                  />
                                </PhoneWrapper>
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              <Field
                                name="sortDate"
                                component={KeyboardDatePicker}
                                label="Datum sortieren"
                                format="dd.MM.yyyy"
                                inputVariant="outlined"
                                style={{ margin: 0 }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              <Field
                                name="expireDate"
                                component={KeyboardDatePicker}
                                label="Datum ablaufen"
                                format="dd.MM.yyyy"
                                inputVariant="outlined"
                                style={{ margin: 0 }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Deactivated
                            </TableCell>{" "}
                            <TableCell component="th" scope="row" align="right">
                              {job?.deactivated ? (
                                <Moment>{job?.deactivated}</Moment>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Deactivated From
                            </TableCell>{" "}
                            <TableCell component="th" scope="row" align="right">
                              {job?.deactivatedFrom?.profile?.name || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Deactivate Reason
                            </TableCell>{" "}
                            <TableCell component="th" scope="row" align="right">
                              {job?.deactivateReason || "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>

                    <Card title="Services" style={{ marginTop: 15 }}>
                      <Table>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            All in One
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={Switch}
                                name="allInOne"
                                onChange={(e) => {
                                  setFieldValue("allInOne", e.target.checked);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Active sourcing
                          </TableCell>
                          <TableCell align="right">
                            <div style={{ width: 40, float: "right" }}>
                              <Field
                                component={Switch}
                                name="ad"
                                onChange={(e) => {
                                  setFieldValue("ad", e.target.checked);
                                  setFieldValue("ap", e.target.checked);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>

                        {values.ad && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Eingereichte Interessentenprofile
                            </TableCell>
                            <TableCell align="right">
                              <div style={{ width: 40, float: "right" }}>
                                <Field component={Switch} name="ap" />
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Table>
                    </Card>
                    <UploadImage job={job} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </React.Fragment>
  );
}

export default EditJob;
