import React, { useState, useEffect } from "react";
import Card from "../../Card";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { V3 } from "../../../lib/api";

import Table from "../../Table";
import Actions from "../../Table/Actions";
import Edit from "./Edit";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Moment from "react-moment";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_CONTACTING } from "../../../redux/types";
import { useSnackbar } from "notistack";

function ChatImages({ id, tab }) {
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [link, setLink] = useState("");
  const [data, setData] = useState({ total: 0 });
  const [recruiters, setRecruiters] = useState([]);
  const [filterState, setFilterState] = useState({ page: 0, filtered: [] });
  const [isRequesting, setIsRequesting] = useState(false);
  const [activeSourcing, setActiveSourcing] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);

  const { job } = useSelector((state) => state.job);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (job?.company?.daughterCompany) {
      V3.get(`company/${job?.company._id}/parent`)
        .then((res) => {
          setActiveSourcing(res.data?.data?.activeSourcing || 0);
        })
        .catch(() => {
          setActiveSourcing(job?.company?.activeSourcing || 0);
        });
    } else {
      setActiveSourcing(job?.company?.activeSourcing || 0);
    }
  }, [job]);

  useEffect(() => {
    if (tab === 2 && job?.services?.ad && activeSourcing < 1) {
      setOpenAlert(true);
    }
  }, [tab, job, activeSourcing]);

  useEffect(() => {
    var queryString = filterState?.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${
      filterState?.page + 1
    }&job=${id}&limit=5&${queryString}`;
    handleFetch(query);
  }, [filterState, id]);

  const confirm = useConfirm();

  const handleSubmit = () => {
    if (!isRequesting && link.length > 0) {
      setIsRequesting(true);
      const reqData = {
        job: id,
        link,
      };
      V3.post(`/job-profiles`, reqData)
        .then((res) => {
          setChats([res.data.data, ...chats]);
          setLink("");
          const total = data.total || 0;
          setData({ ...data, total: total + 1 });
          if (res.data.contacting) {
            dispatch({
              type: UPDATE_CONTACTING,
              payload: { ...res.data, data: res.data.contacting },
            });
          }
          const tmpActiveSourcing = activeSourcing - 1;
          setActiveSourcing(tmpActiveSourcing);
          if (tmpActiveSourcing < 1) {
            setOpenAlert(true);
          }

          setIsRequesting(false);

          if (res.data.double) {
            enqueueSnackbar("Angeschrieben Profile ist bereits vorhanden", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Angeschrieben Profile erstellt", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
          setIsRequesting(false);
        });
    }
  };

  const handleExport = () => {
    setLoading(true);
    var queryString = filterState?.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${
      filterState?.page + 1
    }&job=${id}&limit=5&${queryString}`;
    V3.get(`/job-profiles/exports${query}`).then(({ data }) => {
      setLoading(false);
      window.open(data.excelFile.url, "_blank");
    });
  };

  const handleDelete = (info) => {
    confirm({
      title: `Delete profile`,
      description: "Are you sure You wont to delete this profile",
    }).then(() => {
      V3.delete(`/job-profiles/${info._id}`).then((res) => {
        const tmp = chats.filter((job) => job._id !== info._id);
        setChats(tmp);
        setData({ ...data, total: data.total - 1 });
        dispatch({
          type: UPDATE_CONTACTING,
          payload: { ...res.data, data: res.data.contacting },
        });
      });
    });
  };

  useEffect(() => {
    V3.get(`/users?limit=1000&roles=admin,employee,editor`).then(
      (recruiterRes) => {
        setRecruiters(recruiterRes.data.data);
      }
    );
  }, []);

  const handleChange = (data) => {
    const tmp = chats.map((el) => {
      if (el._id === data._id) {
        return data;
      } else {
        return el;
      }
    });
    setChats(tmp);
  };

  const columns = [
    {
      id: "link",
      Header: "Link",
      filterable: false,
      accessor: (d) => d.link,
      Cell: (props) => (
        <a
          href={props.original?.link || ""}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textTransform: "none" }}
        >
          {props.original?.link || "-"}
        </a>
      ),
    },
    {
      id: "user",
      Header: "User",
      Cell: (props) => (
        <strong>{props.original?.user?.profile?.name || "-"}</strong>
      ),
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={
            filter ? recruiters.find((job) => job._id === filter.value) : null
          }
          options={recruiters}
          getOptionLabel={(option) => option?.profile?.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
    },
    {
      id: "createdAt",
      Header: "Created",
      filterable: false,
      Cell: (props) =>
        props.original?.createdAt ? (
          <Moment>{props.original?.createdAt}</Moment>
        ) : (
          "-"
        ),
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Edit data={props.original} handleChange={handleChange} />
          <span onClick={() => handleDelete(props.original)}>
            <DeleteIcon />
          </span>
        </Actions>
      ),
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/job-profiles${query}`).then(({ data }) => {
      setData(data);
      setChats(data.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        title={
          <span
            style={{
              display: "flex",
              paddingTop: 8,
              alignItems: "center",
            }}
          >
            Angeschrieben Profile
            <Chip label={data?.total} style={{ marginLeft: 15 }} />
          </span>
        }
        button={
          <>
            <Tooltip title="Export">
              <IconButton onClick={handleExport}>
                <ImportExportIcon />
              </IconButton>
            </Tooltip>

            <TextField
              label="Link"
              fullWidth
              style={{ margin: 10 }}
              value={link}
              onChange={(e) => setLink(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </>
        }
      >
        {" "}
        <Table
          data={chats}
          columns={columns}
          loading={loading || isRequesting}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            setFilterState(state);
          }}
          filterable={true}
          minRows={5}
          noDataText="No Data"
        />
      </Card>
      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <DialogTitle>Achtung</DialogTitle>
        <DialogContent>AS Kontingent aufgebraucht"</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenAlert(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChatImages;
