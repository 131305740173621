import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { V3 } from "../../lib/api";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";

import Slider from "@material-ui/core/Slider";
import TagsInput from "../../components/Job/TagInput";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Cantons2 } from "../../data";
import moment from "moment";

import Backdrop from "@material-ui/core/Backdrop";
import clsx from "clsx";
import styled from "styled-components";
import { SET_SEARCH, SET_TAGS } from "../../redux/types";
const Quality = styled.div`
  padding: 6px 12px;
  width: 32px;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  margin-right: 5px;

  ${(props) =>
    props.quality === "A" &&
    `
    background: #41d7ba;
  `};
  ${(props) =>
    props.quality === "B" &&
    `
    background: #f87829;
  `};
  ${(props) =>
    props.quality === "C" &&
    `
    background: #ff5a5d;
  `};
`;
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "550px",
    margin: "0 auto",
  },
  drop: {
    position: "absolute",
    minWidth: 550,
    left: "50%",
    transform: "translateX(-50%)",
  },
  searchWrapper: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    flexDirection: "column",
    position: "absolute",
    top: -25,
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    zIndex: 100,
  },
  input: {
    width: "100%",
    height: "48px",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    paddingLeft: "15px",
    margin: "0 auto",
    display: "block",
    color: theme.palette.text.primary,
    fontSize: 16,
  },
  searchBtn: {
    position: "absolute",
    right: 0,
    borderRadius: 5,
  },
  advBtn: {
    position: "absolute",
    right: 48,
    borderRadius: 5,
    transition: ".3s ease",
  },
  advWrapper: {
    minHeight: 300,
    color: theme.palette.text.primary,
    padding: 15,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  type: {
    padding: "6px 12px",
    width: "100px",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
  },
  typeCandidate: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  typeCompany: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  typeTask: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },
  backdrop: {
    zIndex: 20,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getUrl(index) {
  switch (index.type) {
    case 1:
      return `/crm/candidate/${index.id}`;
    case 2:
      return `/task/${index.id}`;
    case 3:
      return `/crm/company/${index.id}`;
    default:
      return "No Status";
  }
}
function getType(id) {
  switch (id) {
    case 1:
      return "CANDIDATE";
    case 2:
      return "TASK";
    case 3:
      return "COMPANY";
    default:
      return "No Status";
  }
}

function Search() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const [search, setSearch] = React.useState("");
  const [city, setCity] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [advOpen, setAdvOpen] = React.useState(false);
  const [value, setValue] = React.useState([0, 80]);
  const [tabValue, setTabValue] = React.useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const { tags } = useSelector((state) => state.search);

  const { push } = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAdvClick = () => {
    setAdvOpen(!advOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOpen(true);
    const searchString = e.target.value;

    V3.get(
      `/search/companies?limit=5${
        searchString ? `&search=${searchString}` : ""
      }`
    ).then((res) => {
      setData(res.data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAdvOpen(false);
    setOpen(false);
    let tagsString = "";
    // eslint-disable-next-line array-callback-return
    tags.map((el) => {
      tagsString += `${el},`;
    });
    dispatch({ type: SET_SEARCH, payload: { data: [], page: 1 } });
    push(
      `/search/${tabValue === 0 ? "companies" : "candidates"}?limit=21${
        search ? `&search=${search}` : ""
      }&min=${value[0]}&max=${value[1]}${city ? `&city=${city}` : ""}${
        startDate ? `&startDate=${moment(startDate).format("YYYY-MM-DD")}` : ""
      }${endDate ? `&endDate=${moment(endDate).format("YYYY-MM-DD")}` : ""}${
        tags && tags.length > 0 ? `&tags=${tagsString}` : ""
      }${`&phone=${phone}`}`
    );
  };
  const handleSimpleSubmit = (e) => {
    e.preventDefault();
    setAdvOpen(false);
    setOpen(false);
    push(`/search/companies?limit=21${search ? `&search=${search}` : ""}`);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        <Backdrop
          className={classes.backdrop}
          open={advOpen}
          onClick={handleAdvClick}
        ></Backdrop>
        <div className={classes.wrapper}>
          <form onSubmit={handleSimpleSubmit} className={classes.searchWrapper}>
            <input
              type="text"
              placeholder="Suchen..."
              onClick={handleClick}
              className={classes.input}
              onChange={handleSearch}
            />
            <IconButton
              className={classes.advBtn}
              onClick={handleAdvClick}
              style={{
                right: advOpen ? 0 : 48,
              }}
            >
              <FilterListIcon />
            </IconButton>

            {!advOpen && (
              <IconButton
                className={classes.searchBtn}
                onClick={handleSimpleSubmit}
                disabled={search && search.length > 0 ? false : true}
              >
                <SearchIcon />
              </IconButton>
            )}

            <Collapse in={advOpen} timeout="auto" unmountOnExit>
              <div className={classes.advWrapper}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Kompanie" {...a11yProps(0)} />
                    <Tab label="Kandidat" {...a11yProps(1)} />
                  </Tabs>
                </div>

                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={Cantons2}
                        getOptionLabel={(option) => option.label}
                        fullWidth
                        onChange={(e, b) => setCity(b && b.key)}
                        value={{
                          key: city,
                          label: city,
                          value: city,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Kanton"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Telefon"
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <TagsInput
                        selectedItem={tags}
                        tags={tags}
                        setSelectedItem={(newTags) => {
                          dispatch({
                            type: SET_TAGS,
                            payload: newTags,
                          });
                        }}
                        fullWidth
                        variant="outlined"
                        id="tags"
                        name="tags"
                        placeholder="hinzufügen"
                        label="Tags"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={12}>
                      <strong style={{ marginBottom: 25, display: "block" }}>
                        Aged
                      </strong>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={0}
                        max={80}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <strong style={{ display: "block" }}>Created</strong>
                    </Grid>
                    <Grid item xs={6} style={{ alignItems: "center" }}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="startDate"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="endDate"
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={Cantons2}
                        getOptionLabel={(option) => option.label}
                        fullWidth
                        onChange={(e, b) => setCity(b && b.key)}
                        value={{
                          key: city,
                          label: city,
                          value: city,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Kanton"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Telefon"
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ marginTop: 15, float: "right", marginBottom: 15 }}
                >
                  Search
                </Button>
              </div>
            </Collapse>
          </form>

          <Backdrop
            className={classes.backdrop}
            open={!advOpen && open}
            onClick={handleClose}
          ></Backdrop>
          {!advOpen && open && (
            <Paper className={classes.drop} style={{ top: 45, zIndex: 200 }}>
              {data ? (
                <List>
                  <React.Fragment>
                    {data.data.length > 0 ? (
                      <React.Fragment>
                        {data.data.map((index) => (
                          <React.Fragment>
                            <ListItem
                              key={index.id}
                              button
                              onClick={handleClickAway}
                              component={Link}
                              to={getUrl(index)}
                            >
                              <ListItemText primary={index.title} />
                              {getType(index.type) === "CANDIDATE" && (
                                <Quality quality={index.quality}>
                                  {index.quality}
                                </Quality>
                              )}

                              <div
                                className={clsx(classes.type, {
                                  [classes.typeCandidate]: index.type === 1,
                                  [classes.typeTask]: index.type === 2,
                                  [classes.typeCompany]: index.type === 3,
                                })}
                              >
                                {getType(index.type)}
                              </div>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : (
                      <p style={{ margin: 0, padding: 15 }}>Keine Vorschläge</p>
                    )}
                  </React.Fragment>
                </List>
              ) : (
                <p style={{ margin: 0, padding: 15 }}>Keine Vorschläge</p>
              )}
            </Paper>
          )}
        </div>
      </>
    </ClickAwayListener>
  );
}

export default Search;
