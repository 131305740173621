import React, { useState } from "react";
import Card from "../../Card";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { V3 } from "../../../lib/api";

import Table from "../../Table";

import Moment from "react-moment";
import { Field, Form, Formik } from "formik";
import { TextField } from "material-ui-formik-components";
import * as Yup from "yup";
import Loader from "../../GlobalLoader";
import Editor from "../../../components/Editor";
import { useDispatch } from "react-redux";
import { UPDATE_JOB } from "../../../redux/types";

function AllInOnes({ job }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = (values, form) => {
    V3.post(`/all-in-one`, {
      ...values,
      job: job?._id,
      company: job?.company?._id,
    }).then((res) => {
      setChats([res.data.data, ...chats]);
      setOpen(false);
      form.setSubmitting(false);
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.job,
      });
    });
  };

  const columns = [
    {
      id: "firstName",
      Header: "First Name",
      accessor: (d) => d.firstName,
      Cell: (props) => <strong>{props.original?.firstName || "-"}</strong>,
    },
    {
      id: "lastName",
      Header: "Last Name",
      accessor: (d) => d.lastName,
      Cell: (props) => <strong>{props.original?.lastName || "-"}</strong>,
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d.email,
      Cell: (props) => (
        <div style={{ textTransform: "none" }}>
          {props.original?.email || "-"}
        </div>
      ),
    },
    {
      id: "message",
      Header: "Message",
      accessor: (d) => d.message,
      Cell: (props) => (
        <>
          {props.original?.message ? (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{ __html: props.original?.message }}
                />
              }
            >
              <InfoIcon />
            </Tooltip>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "createdAt",
      Header: "Created",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original?.createdAt || "-"}</Moment>,
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/all-in-one${query}`).then(({ data }) => {
      setData(data);
      setChats(data.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        title="All In Ones"
        button={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      >
        <div>
          <Table
            data={chats}
            columns={columns}
            loading={loading}
            page={!data.pagination ? 0 : data.pagination.current - 1}
            pages={!data.pagination ? 1 : data.pagination.total}
            onFetchData={(state) => {
              var queryString = state.filtered
                .map((key) => key.id + "=" + key.value)
                .join("&");
              let query = `?page=${state.page + 1}&job=${
                job?._id
              }&limit=5&${queryString}`;
              handleFetch(query);
            }}
            filterable={false}
            minRows={1}
            noDataText="No Data"
          />
        </div>
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Active Sourcing Onboarding</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Select a user or enter email")
              .nullable(),

            firstName: Yup.string().nullable().required("Required"),
            lastName: Yup.string().nullable().required("Required"),
            message: Yup.string(),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      id="firstName"
                      label="First Name"
                      inputVariant="outlined"
                      fullWidth
                      type="text"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      id="lastName"
                      label="Last Name"
                      inputVariant="outlined"
                      fullWidth
                      type="text"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="email"
                      id="email"
                      label="Email"
                      inputVariant="outlined"
                      fullWidth
                      type="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {" "}
                    <Grid item xs={12}>
                      <Editor
                        value={values.message}
                        onChange={(val) => setFieldValue("message", val)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    close
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={values.files < 1}
                  >
                    submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default AllInOnes;
