import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobTypesSelect } from "../../../data";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import MuiSwitch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { Select } from "material-ui-formik-components/Select";
import LocationPicker from "../../../components/LocationPicker";
import Card from "../../../components/Card";
import Editor from "../../../components/Editor";
import Company from "../../../components/Modals/Companies/index";
import { DASHBOARD } from "../../../config";
import styled from "styled-components";
import MediaChoose from "../../../components/Media/Chooser";
import { Alert } from "@material-ui/lab";
import Video from "../../../components/Job/Video";
import MUITextField from "@material-ui/core/TextField";
import MUISwitch from "@material-ui/core/Switch";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import { Cantons2 } from "../../../data";
import { V3 } from "../../../lib/api";
import GalleryBuilder from "../../../components/GalleryBuilder";

import CustomCandidatesTagInput from "../../../components/Job/CustomCandidatesTagInput";
import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";
import SelectEPUsers from "../SelectEPUsers";
import WarningMessage from "../../../components/Job/WarningMessage";
import TagsInput from "../../../components/Job/TagInput";
import { ADD_JOB } from "../../../redux/types";
import { useSnackbar } from "notistack";
import HashtagSelector from "../../../components/Job/HashtagSelector";

const StyledLink = styled.a`
  margin: 0px;
  margin-right: 15px;
  min-width: 200px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const Preview = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

function FeaturedImage({ values, setFieldValue, errors, touched }) {
  const [selectImage, setSelectedImage] = React.useState(values.shareImage);

  React.useEffect(() => {
    setFieldValue("shareImage", selectImage ? selectImage._id : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectImage]);

  return (
    <>
      {" "}
      <Paper
        style={
          errors?.shareImage && touched.shareImage ? { borderColor: "red" } : {}
        }
      >
        <MediaChoose onChange={setSelectedImage}>
          <div
            style={{ minHeight: 250, lineHeight: "250px", cursor: "pointer" }}
          >
            {selectImage ? (
              <Preview>
                <img
                  src={selectImage ? selectImage.lg.url : ""}
                  alt={selectImage ? selectImage.lg.url : ""}
                />
              </Preview>
            ) : (
              <p style={{ textAlign: "center" }}>
                Klicken Sie, um ein Bild auszuwählen
              </p>
            )}
          </div>
        </MediaChoose>
        {selectImage && (
          <div style={{ padding: 15 }}>
            <Button
              onClick={(e) => setSelectedImage(null)}
              fullWidth
              variant="outlined"
            >
              Aktuelles Bild entfernen
            </Button>
          </div>
        )}
      </Paper>
      {errors?.shareImage && touched.shareImage ? (
        <FormHelperText error>{errors?.shareImage}</FormHelperText>
      ) : null}
    </>
  );
}

const Avatar = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 10;
  margin-top: -40px;
  margin-left: 15px;
  position: relative;
  img {
    width: 100%;
  }
`;

function AddJob() {
  const { user } = useSelector((state) => state.auth);
  const [pdf, setPdf] = useState(null);
  const [company, setCompany] = useState(null);
  const [categories, setCategories] = useState([]);
  const [address, setAddress] = useState(null);
  const [openWarningEmail, setOpenWarningEmail] = useState(false);
  const [users, setUsers] = useState([]);
  const [apEmails, setApEmails] = React.useState([]);
  const [applyWithLinkedin, setApplyWithLinkedin] = useState(false);

  const [customCandidatesV2, setCustomCandidatesV2] = useState([]);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  useEffect(() => {
    V3.get(`/categories/job`).then((response) => {
      setCategories(response.data);
    });
  }, []);

  useEffect(() => {
    if (company) {
      V3.get(`/users?company=${company?._id}&limit=400`).then((response) => {
        const usersData = response.data.data.map((user) => {
          return {
            key: user._id,
            value: user.profile.name,
            label: user.profile.name,
          };
        });

        setUsers(usersData);
      });
    }
  }, [company]);

  const handleCompanyChange = (value, setFieldValue) => {
    setCompany(value);
    setApplyWithLinkedin(value?.applyWithLinkedin);

    if (value?._id === "631b2a61fd860e001620b79a") {
      setFieldValue("applyWithExternalLink", true);
    }

    setFieldValue(
      "sendNewJobEmailTo",
      value?.sendNewJobEmailTo?.length
        ? value?.sendNewJobEmailTo.map((user) => {
            return {
              key: user?._id,
              value: user?.profile?.name,
              label: user?.profile?.name,
            };
          })
        : []
    );
    V3.get(`companies/${value?._id}/check`)
      .then((res) => {
        if (res.data.data) {
        } else {
          setOpenWarningEmail(true);
        }
      })
      .catch((err) => {
        setOpenWarningEmail(true);
      });
  };

  const handlePdfChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    V3.post(`/jobs/pdf`, formData).then((res) => {
      setPdf(res.data.data);
    });
  };

  const _handleSubmit = (values, formikAPi) => {
    let {
      type,
      category,
      ad,
      eb,
      qd,
      ap,
      kanton,
      hashtags,
      apUsers,
      applyWithLinkedinUsers,
      allInOne,
      sendNewJobEmailTo,
      gallery,
    } = values;

    const data = {
      ...values,
      applyWithLinkedin,
      user: user._id,
      type: type ? type.key : undefined,
      category: category ? category.key : undefined,
      company: company ? company._id : undefined,
      address,
      coins: 0,
      pdfUrl: pdf && pdf.url ? pdf.url : "",
      pdfExtract: pdf && pdf.text ? pdf.text : "",
      kanton: kanton.value,
      customCandidatesV2,
      services: {
        ad,
        eb,
        qd,
        ap,
        allInOne,
      },
      workers: [],
      hashtags: hashtags.map((el) => el._id),
      apUsers: apUsers.map((el) => el._id),
      apEmails,
      applyWithLinkedinUsers: applyWithLinkedinUsers.map((el) => el.key),
      sendNewJobEmailTo: sendNewJobEmailTo.map((el) => el.key),
      gallery: gallery.map((el) => el._id),
    };

    V3.get(`companies/${company._id}/check`)
      .then((res) => {
        if (res.data.data) {
          V3.post(`/jobs`, data)
            .then((res) => {
              dispatch({
                type: ADD_JOB,
                payload: res.data.data,
              });
              enqueueSnackbar("Job created successfully", {
                variant: "success",
              });
              push(`/jobs/edit/${res.data.data._id}`);
              formikAPi.setSubmitting(false);
            })
            .catch((err) => {
              enqueueSnackbar("Could not created Job", {
                variant: "error",
              });
              formikAPi.setSubmitting(false);
            });
        } else {
          formikAPi.setSubmitting(false);
          setOpenWarningEmail(true);
        }
      })
      .catch((err) => {
        formikAPi.setSubmitting(false);
        setOpenWarningEmail(true);
      });
  };

  const CategoriesDrop = categories.map((category) => {
    return {
      key: category.name,
      value: category.name,
      label: category.name,
    };
  });
  return (
    <>
      <Formik
        validationSchema={Yup.object().shape({
          title: Yup.string().nullable().required("Required"),
          video: Yup.string().url(),
          visits: Yup.number(),
          type: Yup.string().nullable().required("Required"),
          category: Yup.string().nullable().required("Required"),
          share: Yup.boolean(),
          verified: Yup.boolean(),
          published: Yup.boolean(),
          finished: Yup.boolean(),
          description: Yup.string(),
          featured: Yup.boolean(),
          jobType: Yup.string(),
          kanton: Yup.string().nullable().required("Required"),
          recruiter: Yup.object()
            .typeError("Please select a recruiter")
            .nullable(),
          externalApplyUrl: Yup.string().when("noExternalApplyUrl", {
            is: (noExternalApplyUrl) => !noExternalApplyUrl,
            then: Yup.string().required("Required"),
          }),
          externalApplyClicks: Yup.number()
            .typeError("Required")
            .min(0)
            .required("Required"),
          externalApplyEnabled: Yup.boolean(),
          externalApplyRate: Yup.number()
            .typeError("Required")
            .min(0)
            .max(100)
            .required("Required"),
          customCandidate: Yup.array().nullable(),
          addressTwo: Yup.string().nullable(),
          applyNotes: Yup.string().nullable(),
          contactingNotes: Yup.string().nullable(),
          isAnonymous: Yup.boolean(),
          shareImage: Yup.string().nullable().required("Required"),
        })}
        onSubmit={_handleSubmit}
        initialValues={{
          title: "",
          video: "",
          category: "",
          type: "",
          share: true,
          verified: true,
          published: true,
          finished: false,
          visits: 0,
          shareTitle: "",
          shareDescription: "",
          description: "",
          featured: false,
          jobType: "text",
          externalApplyUrl: "",
          externalApplyClicks: 0,
          externalApplyRate: 2,
          externalApplyEnabled: true,
          recruiter: null,
          kanton: null,
          customCandidate: [],
          addressTwo: null,
          applyNotes: "",
          contactingNotes: null,
          ap: false,
          allInOne: false,
          hashtags: [],
          applyWithLinkedinUsers: [],
          applyWithLinkedinEmails: [],
          applyWithEmail: false,
          applyEmail: "",
          applyWithLink: false,
          applyLink: "",
          isAnonymous: false,
          noExternalApplyUrl: false,
          apUsers: [],
          sendNewJobEmailTo: [],
          shareWithVideo: false,
          applyWithExternalLink: false,
          gallery: [],
        }}
      >
        {({
          isSubmitting,
          dirty,
          setFieldValue,
          initialValues,
          values,
          errors,
          touched,
        }) => (
          <Form>
            {isSubmitting && <Loader />}
            {console.log("values", values)}
            {address || values.addressTwo ? null : (
              <Alert severity="error" style={{ marginBottom: 15 }}>
                Please select a location from the map or write a location.
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    style={{ margin: 0 }}
                  />
                </Paper>
                <Card
                  title="External apply"
                  style={{ marginBottom: 15, marginTop: 15 }}
                  defaultOpen={values.externalApplyEnabled}
                  closable={false}
                  button={
                    <div style={{ width: 40, float: "right" }}>
                      <Field component={Switch} name="externalApplyEnabled" />
                    </div>
                  }
                >
                  <div style={{ padding: 15 }}>
                    <StyledLink href={values.externalApplyUrl} target="_blank">
                      {values.externalApplyUrl}
                    </StyledLink>
                  </div>
                  <div style={{ padding: 15 }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.noExternalApplyUrl}
                            onChange={(e) =>
                              setFieldValue(
                                "noExternalApplyUrl",
                                e.target.checked
                              )
                            }
                            name="noExternalApplyUrl"
                          />
                        }
                        label="No External Apply Url"
                      />
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="External apply url"
                        name="externalApplyUrl"
                        style={{ margin: 0 }}
                        disabled={values.noExternalApplyUrl}
                      />
                    </div>
                    {user &&
                      (user.email === "kristian.oroshi@two.jobs" ||
                        user.email === "admin@test.com") && (
                        <>
                          <Field
                            component={TextField}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="External apply clicks"
                            name="externalApplyClicks"
                          />
                          <Field
                            component={TextField}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="External apply rate"
                            name="externalApplyRate"
                            style={{ marginBottom: 0 }}
                          />
                        </>
                      )}
                  </div>
                </Card>
                {values.jobType === "text" ? (
                  <Paper style={{ marginBottom: 15, overflow: "hidden" }}>
                    <Editor
                      value={initialValues.description}
                      onChange={(value) => setFieldValue("description", value)}
                    />
                  </Paper>
                ) : (
                  <Card
                    title="Job PDF"
                    contentStyles={{ padding: 15 }}
                    button={
                      <input
                        type="file"
                        name="jobPdf"
                        onChange={handlePdfChange}
                      />
                    }
                  >
                    {pdf && (
                      <object
                        data={pdf.url}
                        type="application/pdf"
                        width="100%"
                      >
                        <embed
                          src={pdf.url}
                          type="application/pdf"
                          width="100%"
                        />
                      </object>
                    )}
                  </Card>
                )}

                <Video values={values} setFieldValue={setFieldValue} />
                <Card
                  title="Share options"
                  style={{ marginBottom: 30, marginTop: 30 }}
                  button={
                    <div style={{}}>
                      <Field
                        component={Switch}
                        label={
                          <span style={{ paddingLeft: 8 }}>Video share</span>
                        }
                        name="shareWithVideo"
                      />
                    </div>
                  }
                >
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <div style={{ padding: 15 }}>
                        <FeaturedImage
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ padding: 15 }}>
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="shareTitle"
                          label="Title"
                          name="shareTitle"
                          style={{ marginTop: 0 }}
                        />
                        <Field
                          component={TextField}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="shareDescription"
                          label="Beschreibung"
                          name="shareDescription"
                          multiline
                          rows="8"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Card>

                <Card
                  title="Address"
                  style={{ marginBottom: 30, marginTop: 30 }}
                  contentStyles={{ overflow: "inherit" }}
                  closable={false}
                >
                  <Grid container spacing={2} style={{ padding: 15 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <LocationPicker
                        address={address}
                        setAddress={setAddress}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="addressTwo"
                        label="Address Two"
                        name="addressTwo"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Card>

                <Paper style={{ padding: 15, marginBottom: 15, marginTop: 15 }}>
                  <CustomCandidatesTagInput
                    selectedItem={customCandidatesV2}
                    setSelectedItem={setCustomCandidatesV2}
                    fullWidth
                    variant="outlined"
                    id="tags"
                    placeholder="hinzufügen"
                    label="Extern eingereicht"
                  />
                </Paper>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Notizen Bewerbungen"
                    name="applyNotes"
                    style={{ margin: 0, borderColor: "yellow" }}
                    multiline
                    rows={5}
                  />
                </Paper>
                <GalleryBuilder
                  images={values.gallery}
                  onChange={(value) => {
                    setFieldValue("gallery", value);
                  }}
                  onRemove={(item) => {
                    setFieldValue(
                      "gallery",
                      values.gallery.filter((i) => i._id !== item._id)
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper style={{ padding: 15, marginBottom: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={
                      !dirty || (address || values.addressTwo ? false : true)
                    }
                    style={{ padding: "16px 32px" }}
                  >
                    Create
                  </Button>
                </Paper>

                <Paper style={{ padding: "15px 15px", marginBottom: 15 }}>
                  {company && (
                    <Paper
                      variant="outlined"
                      style={{
                        overflow: "hidden",
                        borderRadius: 5,
                        marginBottom: 15,
                      }}
                    >
                      <div
                        style={{
                          height: 240,
                          overflow: "hidden",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            company?.images?.coverX400
                              ? company.images.coverX400
                              : DASHBOARD + "/images/not-found.png"
                          }
                          alt={company.name}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <Avatar>
                        <img
                          src={
                            company?.images?.avatarX128
                              ? company.images.avatarX128
                              : DASHBOARD + "/images/not-found.png"
                          }
                          alt={company.name}
                        />
                      </Avatar>
                      <div style={{ padding: 15 }}>
                        <h2 style={{ margin: 0 }}>{company.name}</h2>
                      </div>
                    </Paper>
                  )}

                  <Company
                    onChange={(val) => handleCompanyChange(val, setFieldValue)}
                    company={company}
                  />
                </Paper>

                <Paper>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <MUIAutocomplete
                            multiple
                            options={users}
                            getOptionLabel={(option) => option?.label}
                            onChange={(e, val) =>
                              setFieldValue("sendNewJobEmailTo", val)
                            }
                            value={values.sendNewJobEmailTo}
                            getOptionSelected={(option, value) => {
                              return option.key === value.key;
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <MUITextField
                                {...params}
                                placeholder="Select"
                                variant="outlined"
                                label="Send New Job Email To"
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="jobType"
                            options={[
                              {
                                label: "Text",
                                value: "text",
                                key: "text",
                              },
                              {
                                label: "PDF",
                                value: "pdf",
                                key: "pdf",
                              },
                            ]}
                            component={Select}
                            style={{ margin: 0 }}
                            textFieldProps={{
                              label: "Job type",
                              variant: "outlined",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            component={TextField}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="visits"
                            label="Besuch"
                            name="visits"
                            style={{ margin: 0 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="type"
                            options={JobTypesSelect}
                            component={Autocomplete}
                            textFieldProps={{
                              label: "Typ",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="category"
                            options={CategoriesDrop}
                            variant="outlined"
                            component={Autocomplete}
                            style={{ margin: 0 }}
                            textFieldProps={{
                              label: "Kategorie",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <HashtagSelector
                            onChange={(e, val) =>
                              setFieldValue("hashtags", val)
                            }
                            value={values.hashtags}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                          <Field
                            name="kanton"
                            options={Cantons2}
                            component={Autocomplete}
                            textFieldProps={{
                              label: "Kanton",
                              variant: "outlined",
                              style: { margin: 0 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Anonym
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="isAnonymous"
                              id="isAnonymous"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Publiziert
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="published"
                              id="published"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Finished
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="finished"
                              id="finished"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Teilen Online Recruiter
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field component={Switch} name="share" id="share" />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bewerben ohne CV
                        </TableCell>
                        <TableCell align="right">
                          <div
                            style={{
                              width: 40,
                              float: "right",
                              marginRight: 11,
                            }}
                          >
                            <MUISwitch
                              checked={applyWithLinkedin}
                              onChange={() => {
                                setApplyWithLinkedin(!applyWithLinkedin);
                              }}
                              name="applyWithLinkedin"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      {applyWithLinkedin && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              <MUIAutocomplete
                                multiple
                                options={users}
                                getOptionLabel={(option) => option?.label}
                                onChange={(e, val) =>
                                  setFieldValue("applyWithLinkedinUsers", val)
                                }
                                value={values.applyWithLinkedinUsers}
                                getOptionSelected={(option, value) => {
                                  return option.key === value.key;
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    placeholder="Select"
                                    variant="outlined"
                                    label="Bewerben ohne CV Users"
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                              <TagsInput
                                selectedItem={values.applyWithLinkedinEmails}
                                setSelectedItem={(val) =>
                                  setFieldValue("applyWithLinkedinEmails", val)
                                }
                                fullWidth
                                variant="outlined"
                                id="tags"
                                placeholder="hinzufügen"
                                label="Bewerben ohne CV Emails"
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bewerben via Kunden E-Mail
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field component={Switch} name="applyWithEmail" />
                          </div>
                        </TableCell>
                      </TableRow>
                      {values.applyWithEmail && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Apply Email"
                              name="applyEmail"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bewerben via Kunden Link
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field component={Switch} name="applyWithLink" />
                          </div>
                        </TableCell>
                      </TableRow>
                      {values.applyWithLink && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={2}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Apply Link"
                              name="applyLink"
                              style={{ margin: 0 }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bewerben via External Apply Url
                        </TableCell>
                        <TableCell align="right">
                          <div
                            style={{
                              float: "right",
                              paddingRight: 10,
                            }}
                          >
                            <MuiSwitch
                              checked={values.applyWithExternalLink}
                              onChange={() =>
                                setFieldValue(
                                  "applyWithExternalLink",
                                  !values.applyWithExternalLink
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Stelle zuoberst fixieren
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field
                              component={Switch}
                              name="featured"
                              id="featured"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>

                <Card title="Services" style={{ marginTop: 15 }}>
                  <Table>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        All in One
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="allInOne" />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Active sourcing
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ width: 40, float: "right" }}>
                          <Field component={Switch} name="ad" />
                        </div>
                      </TableCell>
                    </TableRow>
                    {values.ad && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Eingereichte Interessentenprofile
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ width: 40, float: "right" }}>
                            <Field component={Switch} name="ap" />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {values.ap && (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <SelectEPUsers
                            companyID={company?._id}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {values.ap && (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <TagsInput
                            selectedItem={apEmails}
                            tags={apEmails}
                            setSelectedItem={setApEmails}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="hinzufügen"
                            label=" Eingereichte Interessentenprofile Email"
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <WarningMessage open={openWarningEmail} setOpen={setOpenWarningEmail} />
    </>
  );
}

export default AddJob;
