import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../Loader";
import { V3 } from "../../lib/api";
import { GIVE_FREE_CLICKS } from "../../redux/types";
import { useDispatch } from "react-redux";
function SendCoins(props) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikApi) => {
    const data = {
      ...values,
      refId: props.refId,
    };
    V3.post(`/free-coins`, data)
      .then((response) => {
        formikApi.setSubmitting(false);
        setOpen(false);
        dispatch({
          type: GIVE_FREE_CLICKS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        formikApi.setSubmitting(false);
      });
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Give free TWO$
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string().nullable().required("Required"),
            points: Yup.number()
              .min(1, "Amount should be bigger than 0")
              .nullable()
              .required("Required"),
          })}
          onSubmit={_handleSubmit}
          initialValues={{
            description: "",
            points: null,
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader />}

              <DialogTitle>Give TWO$ to recruiter</DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Description"
                  name="description"
                  style={{ margin: 0 }}
                />

                <Field
                  component={TextField}
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Amount"
                  name="points"
                  min="0"
                  style={{ marginBottom: 0 }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Schließen
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  disabled={!dirty}
                >
                  Send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default SendCoins;
