import React, { useState } from "react";
import Card from "../../Card";
import { Button, Tooltip } from "@material-ui/core";
import { V3 } from "../../../lib/api";

import Table from "../../Table";
import Actions from "../../Table/Actions";

import LaunchIcon from "@material-ui/icons/Launch";
import New from "./New";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
}));

function ChatImages({ id }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const confirm = useConfirm();
  const classes = useStyles();

  const handleSubmit = (data) => {
    const formData = new FormData();

    data.files.forEach((file) => {
      formData.append("image", file.getRawFile());
    });
    const { title, description } = data;
    formData.append("title", title);
    formData.append("description", description);
    formData.append("job", id);

    V3.post(`/chat-images`, formData).then((res) => {
      setChats([res.data.data, ...chats]);
      setOpen(false);
    });
  };

  const handleDelete = (info) => {
    confirm({
      title: `Delete chat`,
      description: "Are you sure You wont to delete this chat",
    }).then(() => {
      V3.delete(`/chat-images/${info._id}`).then((res) => {
        const tmp = chats.filter((job) => job._id !== info._id);
        setChats(tmp);
      });
    });
  };

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      setLoading(true);
      const fileObject = e.clipboardData.files[0];
      const formData = new FormData();
      const file = {
        getRawFile: () => fileObject,
        name: fileObject.name,
        size: fileObject.size,
        status: 2,
        progress: 0,
      };
      formData.append("image", file.getRawFile());

      formData.append("title", "");
      formData.append("description", "");
      formData.append("job", id);

      V3.post(`/chat-images`, formData).then((res) => {
        setChats([res.data.data, ...chats]);
        setLoading(false);
      });
    }
  };

  const columns = [
    {
      id: "image",
      Header: "Image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.image ? (
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.customWidth }}
              title={
                <span>
                  <img
                    style={{ maxWidth: "500px", width: "100%", height: "auto" }}
                    src={props.original?.image}
                    alt={props.original?.image}
                  ></img>
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          ) : (
            "-"
          )}
        </strong>
      ),
    },
    {
      id: "censoredImage",
      Header: "Client Image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.censoredImage ? (
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.customWidth }}
              title={
                <span>
                  <img
                    style={{ maxWidth: "500px", width: "100%", height: "auto" }}
                    src={props.original?.censoredImage}
                    alt={props.original?.censoredImage}
                  ></img>
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          ) : (
            "-"
          )}
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <a
            href={`${props.original?.image}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LaunchIcon />
          </a>
          <span onClick={() => handleDelete(props.original)}>
            <DeleteIcon />
          </span>
        </Actions>
      ),
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/chat-images${query}`).then(({ data }) => {
      setData(data);
      setChats(data.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        title="Chat Images"
        button={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      >
        <div onPaste={(e) => handlePaste(e)}>
          <Table
            data={chats}
            columns={columns}
            loading={loading}
            page={!data.pagination ? 0 : data.pagination.current - 1}
            pages={!data.pagination ? 1 : data.pagination.total}
            onFetchData={(state) => {
              var queryString = state.filtered
                .map((key) => key.id + "=" + key.value)
                .join("&");
              let query = `?page=${
                state.page + 1
              }&job=${id}&limit=5&${queryString}`;
              handleFetch(query);
            }}
            filterable={false}
            minRows={5}
            noDataText="No Data"
          />
        </div>
      </Card>
      <New open={open} setOpen={setOpen} handleSubmit={handleSubmit} />
    </>
  );
}

export default ChatImages;
