import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import Router from "./components/Router";
import store from "./store";
import { Provider } from "react-redux";
import * as moment from "moment";
import Moment from "react-moment";
import BatteryInfo from "./components/BatteryInfo";
import Network from "./components/Network";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import "react-image-lightbox/style.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { API_V3 } from "./config";
import { SocketProvider } from "./providers/SocketProvider";
import { useEffect } from "react";
import GlobalWrapper from "./components/GlobalWrapper";
import { SET_THEME } from "./redux/types";


Moment.globalMoment = moment;
Moment.globalFormat = "DD.MM.YYYY";

const App = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") === "false" ? false : true
  );

  const handleSetTheme = (type) => {
    setTheme(type);
    localStorage.setItem("theme", type);
    store.dispatch({
      type: SET_THEME,
      payload: type,
    });
  };

  useEffect(() => {
    return () => {
      const theme = localStorage.getItem("theme") === "false" ? false : true;
      setTheme(theme);
    };
  }, []);
  return (
    <SocketProvider url={API_V3}>
      <StyledThemeProvider theme={theme ? lightTheme : darkTheme}>
        <ThemeProvider theme={theme ? lightTheme : darkTheme}>
          <CssBaseline />
          <BatteryInfo />
          <Network />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConfirmProvider>
              <SnackbarProvider maxSnack={6}>
                <Provider store={store}>
                  <GlobalWrapper>
                    <Router theme={theme} setTheme={handleSetTheme} />
                  </GlobalWrapper>
                </Provider>
              </SnackbarProvider>
            </ConfirmProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </SocketProvider>
  );
};

export default App;
