import React, { useState } from "react";
import Card from "../Card";
import Table from "../Table";
import { V3 } from "../../lib/api";
import { Link } from "react-router-dom";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

function TopProductsSoldThisMonth() {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [createdJobs, setCreatedJobs] = useState(0);

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "month")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const columns = [
    {
      id: "user",
      Header: "User",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {props.original?._id?.length ? (
            <Link
              to={`/products/edit/${props.original?._id[0]._id}`}
              target="_blank"
            >
              <strong> {props.original?._id[0].title}</strong>
            </Link>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "products",
      Header: "Products",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.count}</strong>,
    },
    {
      id: "price",
      Header: "Total Price",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.count * props.original?._id[0]?.chfPrice} CHF
        </strong>
      ),
    },
    {
      id: "products",
      Header: "Total",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original?.count * props.original?._id[0]?.points} Two$
        </strong>
      ),
    },
  ];

  let total = 0;
  let totalCHF = 0;

  // eslint-disable-next-line array-callback-return
  products.map((el) => {
    total += el?.count * el?._id[0]?.points;
    totalCHF += el?._id[0]?.chfPrice ? el?.count * el?._id[0]?.chfPrice : 0;
  });

  return (
    <Card
      title={"Die meistverkauften Produkte für einen Monat"}
      style={{ marginBottom: 15 }}
      button={
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <KeyboardDatePicker
              id="date-picker-dialog"
              label="From"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <strong>
              Total: {total} Two$, Total Price: {totalCHF} CHF / Total Jobs{" "}
              {createdJobs} = {(totalCHF / createdJobs).toFixed(0)} CHF
            </strong>
          </Grid>
        </Grid>
      }
    >
      <Table
        data={products}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);
          V3.get(`statistic/order/products${query}`).then((res) => {
            setData(res.data);
            setProducts(res.data.data);
            setCreatedJobs(res.data.createdJobs);
            setLoading(false);
          });
        }}
        noDataText="Keine Produkte"
        minRows={5}
        showPaginationBottom={false}
        defaultFiltered={[
          {
            id: "startDate",
            value: startDate ? moment(startDate).format("DD/MM/YYYY") : null,
          },
          {
            id: "endDate",
            value: endDate ? moment(endDate).format("DD/MM/YYYY") : null,
          },
        ]}
      />
    </Card>
  );
}

export default TopProductsSoldThisMonth;
