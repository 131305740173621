import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Switch } from "material-ui-formik-components/Switch";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import Card from "../../../components/Card";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { V3 } from "../../../lib/api";
import FileUpload from "../../../components/fileUpload";
import KeyboardDatePicker from "../candidates/KeyboardDatePicker";
import Button from "@material-ui/core/Button";
import Editor from "../../../components/Editor";
import { Select, MenuItem } from "@material-ui/core";

import Activity from "../../../components/Crm/Companies/Activity";
import StatusActivity from "../../../components/Crm/Companies/Status/StatusActivity";
import ContactPersons from "../../../components/Crm/Companies/ContactPersons";
import Notes from "../../../components/Company/Notes";
import {
  COMPANIES_LOADING,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../../redux/types";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
const AddCompany = () => {
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState(null);

  const { id } = useParams();

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await V3.get(`/crm/companies/${id}`);
        dispatch({
          type: GET_COMPANY,
          payload: res.data,
        });
        const categoryRes = await V3.get(`/categories/company`);
        setCategories(
          categoryRes.data.data.map((category) => {
            return {
              key: category.name,
              value: category.name,
              label: category.name,
            };
          })
        );
        const userRes = await V3.get(`/users?roles=admin,employee&limit=1000`);
        setUsers(
          userRes.data.data.map((el) => {
            return {
              key: el._id,
              value: el.profile.name,
              label: el.profile.name,
            };
          })
        );
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (selectorFiles) => {
    setFiles(selectorFiles);
  };
  const _handleSubmit = async (values, formikAPi) => {
    const { consultant, caller, category, ...rest } = values;
    const data = { ...rest };

    data.consultant = consultant?.key;
    data.caller = caller?.key;
    data.category = category?.key;

    if (files.length) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      await V3.post(`crm/companies/${id}/documents`, formData);
    }
    dispatch({
      type: COMPANIES_LOADING,
    });
    V3.put(`/crm/companies/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_COMPANY,
          payload: res.data.data,
        });
        enqueueSnackbar("Company updated successfully", {
          variant: "success",
        });
        push("/crm/companies");
        formikAPi.setSubmitting(false);
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        enqueueSnackbar("Could not updated Company", {
          variant: "error",
        });
        formikAPi.setSubmitting(false);
      });
  };

  const { company, loading } = useSelector((state) => state.company);

  return (
    <React.Fragment>
      {company !== null && (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            category: Yup.object().nullable().required("Required"),
            state: Yup.string().nullable(),
            city: Yup.string().nullable(),
            zip: Yup.number().nullable(),
            consultant: Yup.object().nullable(),
            caller: Yup.object().nullable(),
            website: Yup.string(),
            street: Yup.string(),
            openPositions: Yup.number().nullable(),
            notes: Yup.string(),
            documentSent: Yup.boolean(),
            meeting: Yup.boolean(),
            documentSentDate: Yup.string().nullable(),
            conversationDate: Yup.string().nullable(),
            subscriptionNotes: Yup.string(),
            hasDaughterCompanies: Yup.boolean(),
            infoFromParentCompany: Yup.boolean(),
            maa: Yup.number().nullable(),
          })}
          enableReinitialize={true}
          onSubmit={_handleSubmit}
          initialValues={{
            name: company.name,
            website: company.website,
            email: company.email,
            openPositions: company.openPositions
              ? parseInt(company.openPositions)
              : 0,
            state: company.state,
            city: company.city,
            street: company.street,
            zip: company.zip,
            notes: company.notes,
            subscriptionNotes: company.subscriptionNotes || "",
            consultant: {
              key: company?.consultant && company?.consultant?._id,
              label: company?.consultant && company?.consultant?.profile?.name,
              value: company?.consultant && company?.consultant?.profile?.name,
            },
            caller: {
              key: company.caller && company.caller._id,
              label: company.caller && company.caller.profile.name,
              value: company.caller && company.caller.profile.name,
            },
            category:
              company?.category && company.category !== "Uncategorized"
                ? {
                    key: company.category,
                    label: company.category,
                    value: company.category,
                  }
                : null,
            documentSent: company.documentSent,
            documentSentDate: company.documentSentDate,
            conversationDate: company.conversationDate,
            meeting: company.meeting,
            metingStatus: company.metingStatus,
            hasDaughterCompanies: company.hasDaughterCompanies,
            infoFromParentCompany: company.infoFromParentCompany,
            maa: company.maa,
          }}
        >
          {({ isSubmitting, dirty, setFieldValue, values }) => (
            <Form>
              {(isSubmitting || loading) && <Loader></Loader>}
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  {message && (
                    <Alert severity="error" style={{ marginBottom: 15 }}>
                      {message}
                    </Alert>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card
                        title="Company Info"
                        contentStyles={{ padding: 15 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="name"
                              label="Name"
                              name="name"
                              style={{ margin: 0 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="website"
                              label="Website"
                              name="website"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="openPositions"
                              label="Open Positions"
                              name="openPositions"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="maa"
                              label="Anzahl MA"
                              name="maa"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <ContactPersons
                        data={company.contactPersons}
                        companyId={company._id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Card
                            title="Documents Sent"
                            contentStyles={{ padding: 15, height: "100%" }}
                            button={
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                style={{ float: "right", width: "140px" }}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  alignItems="center"
                                  style={{ textAlign: "right" }}
                                >
                                  No
                                </Grid>
                                <Grid item xs={4} alignItems="center">
                                  <Field
                                    component={Switch}
                                    name="documentSent"
                                  />
                                </Grid>
                                <Grid item xs={4} alignItems="center">
                                  Yes
                                </Grid>
                              </Grid>
                            }
                          >
                            <Field
                              name="documentSentDate"
                              component={KeyboardDatePicker}
                              label="Datum"
                              format="dd.MM.yyyy"
                              inputVariant="outlined"
                              style={{ margin: 0 }}
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Card
                            title="Termin"
                            contentStyles={{ padding: 15, height: "100%" }}
                            button={
                              <Select
                                value={values.metingStatus}
                                onChange={(e) =>
                                  setFieldValue("metingStatus", e.target.value)
                                }
                              >
                                <MenuItem value={0}>Offen</MenuItem>
                                <MenuItem value={1}>Ja</MenuItem>
                                <MenuItem value={2}>Nein</MenuItem>
                              </Select>
                            }
                          >
                            <Field
                              name="conversationDate"
                              component={KeyboardDatePicker}
                              label="Tel. am"
                              format="dd.MM.yyyy"
                              inputVariant="outlined"
                              style={{ margin: 0 }}
                              helperText={
                                (values.metingStatus === 1 ||
                                  values.metingStatus === 2) &&
                                !values.conversationDate
                                  ? "required"
                                  : ""
                              }
                              error={
                                (values.metingStatus === 1 ||
                                  values.metingStatus === 2) &&
                                !values.conversationDate
                              }
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Card title="Location" contentStyles={{ padding: 15 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="street"
                              label="Strasse"
                              name="street"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="zip"
                              label="PLZ"
                              name="zip"
                              style={{ margin: 0 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="city"
                              label="Ort"
                              name="city"
                              style={{ margin: 0 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="state"
                              label="Land"
                              name="state"
                              style={{ margin: 0 }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        title="Notizen zum Abo (durch Berater auszufüllen)"
                        defaultOpen={false}
                      >
                        <Editor
                          height={200}
                          value={values.subscriptionNotes}
                          onChange={(value) =>
                            setFieldValue("subscriptionNotes", value)
                          }
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Notes />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUpload handleChange={handleChange} />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <StatusActivity companyId={company._id} data={values} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper style={{ padding: 15, marginBottom: 15 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                      style={{ padding: "16px 32px" }}
                      disabled={
                        !dirty ||
                        ((values.metingStatus === 1 ||
                          values.metingStatus === 2) &&
                          !values.conversationDate)
                      }
                    >
                      Aktualisieren
                    </Button>
                  </Paper>
                  <Paper style={{ padding: "0px", marginBottom: 15 }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={Autocomplete}
                              fullWidth
                              name="consultant"
                              options={users}
                              variant="outlined"
                              textFieldProps={{
                                label: "Consultant",
                                variant: "outlined",
                                style: { margin: 0 },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={Autocomplete}
                              fullWidth
                              name="caller"
                              options={users}
                              variant="outlined"
                              textFieldProps={{
                                label: "Caller",
                                variant: "outlined",
                                style: { margin: 0 },
                              }}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Field
                              component={Autocomplete}
                              fullWidth
                              name="category"
                              options={categories}
                              variant="outlined"
                              textFieldProps={{
                                label: "Kategorie",
                                variant: "outlined",
                                style: { margin: 0 },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Hat Tochterunternehmen</TableCell>
                          <TableCell>
                            <Field
                              style={{
                                display: "flex",
                                alignItems: "end",
                              }}
                              component={Switch}
                              name="hasDaughterCompanies"
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Info From Parent Company</TableCell>
                          <TableCell>
                            <Field
                              style={{
                                display: "flex",
                                alignItems: "end",
                              }}
                              component={Switch}
                              name="infoFromParentCompany"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>

                  <Activity companyId={company._id} />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default AddCompany;
