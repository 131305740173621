import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../../../components/Loader";
import { V3 } from "../../../../lib/api";
import EditIcon from "@material-ui/icons/Edit";
import ImageUpload from "../../../../components/imageUpload";

function Delete(props) {
  const [open, setOpen] = React.useState(false);
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  const { question } = props;

  useEffect(() => {
    setAvatarPreview(question?.image?.md?.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = async (values, formikAPi) => {
    const data = { ...values };

    if (avatar) {
      const formData = new FormData();

      formData.append("images", avatar);

      try {
        const res = await V3.post("medias", formData);
        if (res?.data?.data?.length) {
          data.image = res?.data?.data[0]._id;
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    V3.put(`/questions/${question._id}`, data)
      .then((res) => {
        formikAPi.setSubmitting(false);
        props.handleUpdate(res.data.data);
        setOpen(false);
      })
      .catch((error) => console.log("error", error.response));
  };

  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <EditIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bearbeiten Sie diese frage</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Formik
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              title: question?.title,
              job: question?.job?._id,
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question"
                  name="title"
                  style={{ margin: 0, marginTop: 15, marginBottom: 15 }}
                  multiline={true}
                  rows={5}
                />
                <ImageUpload
                  imagePreviewUrl={avatarPreview}
                  onImageChange={onAvatarChange}
                  id="TEST"
                />

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="Submit">
                    update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
