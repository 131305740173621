import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import { Link } from "react-router-dom";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit } from "react-icons/fi";
import Button from "@material-ui/core/Button";
import Delete from "./companies/Delete";
import { V3 } from "../lib/api";
import { COMPANIES_LOADING, GET_COMPANIES } from "../redux/types";

const Companies = () => {
  const { companies, data, loading } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const columns = [
    {
      id: "name",
      Header: " Unternehmen",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/companies/edit/${props.original._id}`}>
          <strong>{props.original.name}</strong>
        </Link>
      ),
    },
    {
      id: "jobs",
      Header: "Jobs",
      accessor: (d) => d.jobs,
      filterable: false,
      Cell: (props) => (
        <span>
          {props.original?.latestSubscriptionInvoice?.invoiceId?.availableJobs}
        </span>
      ),
    },
    {
      id: "activeSourcing",
      Header: "Active Sourcing",
      accessor: (d) => d.jobs,
      filterable: false,
      Cell: (props) => (
        <span>
          {props.original.activeSourcing ? props.original.activeSourcing : "-"}
        </span>
      ),
    },
    {
      id: "city",
      Header: "Stadt",
      filterable: false,
      accessor: (d) => d.city,
      Cell: (props) => <div>{props.original.city}</div>,
    },
    {
      id: "published",
      Header: "Publiziert",
      filterable: false,
      accessor: (d) => d.published,
      Cell: (props) => (
        <div>
          {props.original.published === true && <span>Ja</span>}
          {props.original.published === false && <span>Nein</span>}
        </div>
      ),
    },
    {
      id: "jobCrawling",
      Header: "Crawler",
      filterable: true,
      accessor: (d) => d.published,
      Cell: (props) => (
        <div>
          {props.original.jobCrawling === true && <span>Ja</span>}
          {props.original.jobCrawling === false && <span>Nein</span>}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Bearbeiten">
            <Link to={`/companies/edit/${props.original._id}`}>
              <FiEdit></FiEdit>
            </Link>
          </Tooltip>
          <Tooltip arrow title="Löschen">
            <Delete companyId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Unternehmen" size={data.total}>
        <Button
          component={Link}
          to="/companies/new"
          color="primary"
          variant="contained"
          style={{ float: "right" }}
        >
          Neu
        </Button>
      </Header>
      <Table
        data={companies}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          dispatch({
            type: COMPANIES_LOADING,
          });
          V3.get(`/companies${query}`).then((res) => {
            dispatch({
              type: GET_COMPANIES,
              payload: res.data,
            });
          });
        }}
        filterable={true}
        noDataText="No Companies"
      />
    </React.Fragment>
  );
};

export default Companies;
