/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import Button from "@material-ui/core/Button";
import Delete from "./jobs/Delete";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../lib/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Contacting from "../components/Job/contacting/";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { IconButton } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import Lightbox from "react-image-lightbox";
import styled from "styled-components";
import Loader from "../components/GlobalLoader";
import moment from "moment";
import { FILTERS, GET_JOBS, JOBS_LOADING } from "../redux/types";

const WrapperLightBox = styled.div`
  .ReactModal__Overlay {
    z-index: 99999 !important ;
  }
`;

function Jobs(props) {
  const [companies, setCompanies] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [open, setOpen] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState(null);
  const [showChatImages, setShowChatImages] = useState(false);
  const [chatImages, setChatImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    V3.get(`/companies/list`).then((res) => {
      setCompanies(res.data.data);
    });
  }, []);

  const { jobs, data, loading, filters, page } = useSelector(
    (state) => state.job
  );
  useEffect(() => {
    var queryString = filters.map((key) => key.id + "=" + key.value).join("&");
    let query = `?page=${page + 1}&${queryString}`;
    // &withRecruiters=true&withCrmCompany=true&withContactingInfo=true&
    dispatch({
      type: JOBS_LOADING,
    });
    V3.get(`/jobs/crm${query}`).then((res) => {
      dispatch({
        type: GET_JOBS,
        payload: res.data,
      });
    });
  }, [filters, page]);

  const handelExport = () => {
    setLoadingExport(true);
    var queryString = filters.map((key) => key.id + "=" + key.value).join("&");
    let query = `?page=${page + 1}&${queryString}`;
    V3.get(`/jobs/crm/export${query}`).then((res) => {
      setLoadingExport(false);
      window.open(res.data.data.url);
    });
  };

  const columns = [
    {
      id: "title",
      Header: "Titel",
      width: 220,
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link
          to={`/jobs/edit/${props.original._id}`}
          style={{ textTransform: "none" }}
        >
          <strong>{props.original.title}</strong>
        </Link>
      ),
    },
    {
      id: "company",
      width: 180,
      Header: "Unternehmen",
      accessor: (d) => d._id,
      filterable: true,

      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? companies.find((company) => company._id === filter.value)
              : null
          }
          options={companies}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),

      Cell: (props) => (
        <span>{props.original.company ? props.original.company.name : ""}</span>
      ),
    },
    {
      id: "formattedAddress",
      Header: "Standort",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong>
          {props.original.location && props.original.location.city
            ? props.original.location.city
            : props.original.addressTwo}
        </strong>
      ),
    },

    {
      id: "ad",
      Header: "Active Sourcing",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <span>{props.original.services?.ad ? "Ja" : "Nein"}</span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },
    {
      id: "published",
      Header: "Publiziert",
      accessor: (d) => d.published,
      Cell: (props) => (
        <div>
          {props.original.published === true && <span>Ja</span>}
          {props.original.published === false && <span>Nein</span>}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },
    {
      id: "publishedAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      Cell: (props) => (
        <div>
          <Moment>{props.original.createdAt}</Moment>
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-createdAt">Neu-Alt</option>
          <option value="createdAt">Alt-Neu</option>
        </select>
      ),
    },
    {
      id: "expireDate",
      Header: "Ablaufdatum",
      accessor: (d) => d.expireDate,
      Cell: (props) => (
        <div>
          {props.original.expireDate ? (
            <Moment>{props.original.expireDate}</Moment>
          ) : (
            "-"
          )}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-expireDate">Neu-Alt</option>
          <option value="expireDate">Alt-Neu</option>
        </select>
      ),
    },
    {
      id: "deactivated",
      Header: "deaktiviert",
      accessor: (d) => d.createdAt,
      Cell: (props) => (
        <div>
          {props.original.deactivated ? (
            <Moment>{props.original.deactivated}</Moment>
          ) : (
            "-"
          )}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-deactivated">Neu-Alt</option>
          <option value="deactivated">Alt-Neu</option>
        </select>
      ),
    },

    {
      id: "contacting",
      Header: "kontaktieren",
      accessor: (d) => d.recruiter,
      Cell: (props) => (
        <>
          {props.original.services?.ad ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setJobId(props.original._id);
                setOpen(true);
              }}
            >
              Info
            </Button>
          ) : null}
        </>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="as">Has AS</option>
          <option value="contacting">Has Contacting</option>
          <option value="noContacting">Hasn't Contacting</option>
        </select>
      ),
    },
    {
      id: "contacted",
      Header: "kontaktiert",
      accessor: (d) => d.contacted,
      Cell: (props) => (
        <span>
          {props.original?.contacting?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.contacted,
            0
          )}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="50">0-50</option>
          <option value="100">50-100</option>
          <option value="150">100-150</option>
          <option value="200">150-200</option>
          <option value="250">200+</option>
        </select>
      ),
    },
    {
      id: "toContact",
      Header: "Zu Kontaktieren",
      accessor: (d) => d.toContact,
      Cell: (props) => (
        <span>
          {" "}
          {props.original?.contacting?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.toContact,
            0
          )}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="50">0-50</option>
          <option value="100">50-100</option>
          <option value="150">100-150</option>
          <option value="200">150-200</option>
          <option value="250">200+</option>
        </select>
      ),
    },
    {
      id: "difference",
      Header: "Unterschied",
      accessor: (d) => d.difference,
      Cell: (props) => (
        <span>
          {props.original?.contacting?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.toContact,
            0
          ) -
            props.original?.contacting?.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.contacted,
              0
            )}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="50">0-50</option>
          <option value="100">50-100</option>
          <option value="150">100-150</option>
          <option value="200">150-200</option>
          <option value="250">200+</option>
        </select>
      ),
    },
    {
      id: "internNotes",
      Header: "Interner Hinweis",
      accessor: (d) => d.internNotes,
      Cell: (props) => (
        <>
          {props.original?.activeSourcing?.notes ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setNotes(props.original?.activeSourcing?.notes);
                setShowNotes(true);
              }}
            >
              Info
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Has</option>
          <option value="false">Hasn't</option>
        </select>
      ),
    },
    {
      id: "updateCall",
      Header: "Update Aufruf",
      accessor: (d) => d.internNotes,
      Cell: (props) => (
        <>
          {props.original?.crmCompanyUpdateCall ? (
            <Moment>{props.original?.crmCompanyUpdateCall}</Moment>
          ) : (
            "-"
          )}
        </>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-1">Neu-Alt</option>
          <option value="1">Alt-Neu</option>
        </select>
      ),
    },
    {
      id: "chatImages",
      Header: "Chat Bilder",
      accessor: (d) => d.internNotes,

      Cell: (props) => (
        <>
          {props.original?.chatImages?.length > 0 ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setChatImages(props.original?.chatImages);
                setShowChatImages(true);
                setPhotoIndex(0);
              }}
            >
              Info
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Has</option>
          <option value="false">Hasn't</option>
        </select>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,

      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this job">
            <Link to={`/jobs/edit/${props.original._id}`}>
              <EditIcon />
            </Link>
          </Tooltip>
          <Tooltip title="Delete this job">
            <Delete jobId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Jobs" size={data.total} icon={<GroupIcon />}>
        <Tooltip title="Export" onClick={() => handelExport()}>
          <IconButton
            style={{
              marginRight: 15,
            }}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Filter löschen"
          onClick={() =>
            dispatch({
              type: FILTERS,
              filters: [],
              page: 0,
            })
          }
        >
          <IconButton
            style={{
              backgroundColor: filters.length > 0 ? "#85ca00" : "transparent",
              marginRight: 15,
            }}
          >
            <ClearAllIcon />
          </IconButton>
        </Tooltip>

        <Button
          component={Link}
          to="/jobs/new"
          color="primary"
          variant="contained"
          style={{ float: "right" }}
        >
          Neu
        </Button>
      </Header>
      {loadingExport && <Loader />}

      <Table
        data={jobs}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          dispatch({
            type: FILTERS,
            filters: state.filtered,
            page: state.page,
          });
        }}
        noDataText="Keine Jobs"
        filterable={true}
        defaultFiltered={filters}
      />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogContent>
          <Contacting jobId={jobId} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
            style={{ margin: 15 }}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showNotes}
        onClose={() => setShowNotes(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: notes }}></div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowNotes(false)}
            style={{ margin: 15 }}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      {showChatImages && (
        <WrapperLightBox>
          <Lightbox
            reactModalStyle={{ "z-index": 99999 }}
            mainSrc={chatImages[photoIndex].image}
            imageTitle={`Erstellt am ${moment(
              chatImages[photoIndex].createdAt
            ).format("DD/MM/YYYY")}`}
            nextSrc={chatImages[(photoIndex + 1) % chatImages.length].image}
            prevSrc={
              chatImages[
                (photoIndex + chatImages.length - 1) % chatImages.length
              ].image
            }
            onCloseRequest={() => setShowChatImages(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + chatImages.length - 1) % chatImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % chatImages.length)
            }
          />
        </WrapperLightBox>
      )}
    </React.Fragment>
  );
}

export default Jobs;
