import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOADED } from "../../../redux/types";
import { V3 } from "../../../lib/api";

function HideColum() {
  const [open, setOpen] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { crmCompanyColumns } = user;

  const handleChange = (col, val) => {
    let tmp = [...crmCompanyColumns];

    if (val) {
      tmp.push(col);
    } else {
      tmp = tmp.filter((el) => el !== col);
    }

    dispatch({
      type: USER_LOADED,
      payload: { data: { ...user, crmCompanyColumns: tmp } },
    });

    V3.put(`users/company/columns`, { crmCompanyColumns: tmp });
  };

  return (
    <>
      <Tooltip title="Hide Columns">
        <IconButton onClick={() => setOpen(true)}>
          <VisibilityOffIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Hide Columns</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              Unternehmen
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("name")}
                onChange={(e) => handleChange("name", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>

            <Grid item xs={6}>
              Berater
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("consultant")}
                onChange={(e) => handleChange("consultant", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Erfasser
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("caller")}
                onChange={(e) => handleChange("caller", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Doc. Gesendet
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("documentSent")}
                onChange={(e) => handleChange("documentSent", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Status
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("status")}
                onChange={(e) => handleChange("status", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              MA
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("maa")}
                onChange={(e) => handleChange("maa", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              UpdateCall
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("called")}
                onChange={(e) => handleChange("called", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Ersttermin
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("erstterminDate")}
                onChange={(e) =>
                  handleChange("erstterminDate", e.target.checked)
                }
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              MAA
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("ma")}
                onChange={(e) => handleChange("ma", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Offene Jobs
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("openPositions")}
                onChange={(e) =>
                  handleChange("openPositions", e.target.checked)
                }
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Tel. Am
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("cDate")}
                onChange={(e) => handleChange("cDate", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Nachfassem am
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("aDate")}
                onChange={(e) => handleChange("aDate", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Datum
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("updatedAt")}
                onChange={(e) => handleChange("updatedAt", e.target.checked)}
              />
            </Grid>
            <Grid item xs={12} align="right">
              <Divider />
            </Grid>
            <Grid item xs={6}>
              Termin
            </Grid>
            <Grid item xs={6} align="right">
              <Switch
                checked={crmCompanyColumns.includes("meeting")}
                onChange={(e) => handleChange("meeting", e.target.checked)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="primary"
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HideColum;
