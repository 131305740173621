import * as React from 'react';

const Facebook = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M20.786 10c0-5.523-4.477-10-10-10s-10 4.477-10 10c0 4.991 3.657 9.128 8.437 9.878v-6.987H6.684V10h2.54V7.797c0-2.506 1.492-3.89 3.776-3.89 1.094 0 2.239.195 2.239.195v2.46h-1.261c-1.242 0-1.63.771-1.63 1.563V10h2.774l-.444 2.89h-2.33v6.988c4.78-.75 8.438-4.887 8.438-9.878Z"
            fill="#98A2B3"
        />
    </svg>
);

export default Facebook;
