import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../GlobalLoader";
import { V3 } from "../../../../lib/api";
import { ADD_COMPANY_BONUS } from "../../../../redux/types";
function Add() {
  const [open, setOpen] = useState(false);
  const { company } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const handleSubmit = (values, formApi) => {
    V3.post(`/companies/${company._id}/bonus`, values).then((res) => {
      dispatch({
        type: ADD_COMPANY_BONUS,
        payload: res.data.data,
      });
      formApi.setSubmitting(false);
      setOpen(false);
    });
  };
  return (
    <>
      <Button color="primary" variant="outlined" onClick={() => setOpen(!open)}>
        Add
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Bonus</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Required"),
            amount: Yup.number().min(1).max(100).required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent style={{ minWidth: 500 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="amount"
                      label="Amount"
                      name="amount"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus type="submit">
                  Add
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Add;
