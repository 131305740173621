import React, { useState } from "react";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import GroupIcon from "@material-ui/icons/Group";
import { Tooltip } from "@material-ui/core";
import { V3 } from "../lib/api";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { useConfirm } from "material-ui-confirm";

const Favorites = () => {
  const [load, setLoad] = useState(false);

  const [data, setData] = useState({});
  const [favorites, setFavorites] = useState([]);

  const confirm = useConfirm();

  const handleDelete = (id) => {
    confirm({
      title: `Delete Favorite`,
      description: `Are you sure you wont to delete this favorite.`,
    }).then(() => {
      setLoad(true);
      V3.delete(`favorite/jobs/${id}`).then(() => {
        setFavorites(favorites.filter((el) => el._id !== id));
        setLoad(false);
      });
    });
  };

  const columns = [
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props?.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "job",
      Header: "Job",
      filterable: false,
      accessor: (d) => d.job,
      Cell: (props) => (
        <Link
          to={`jobs/edit/${props?.original?.job._id}`}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {props?.original?.job?.title || "-"}
        </Link>
      ),
    },

    {
      id: "createdAt",
      Header: () => <span style={{ textAlign: "right" }}>Datum</span>,
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },

    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Delete this alert">
            <span onClick={() => handleDelete(props.original._id)}>
              <AiOutlineDelete />
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Favorites" icon={<GroupIcon />} />
      <Table
        data={favorites}
        columns={columns}
        loading={load}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        filterable={true}
        onFetchData={(filterState) => {
          setLoad(true);
          let queryString = filterState.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${filterState.page + 1}&${queryString}`;
          V3.get(`favorite/jobs${query}`).then((res) => {
            setData(res.data);
            setFavorites(res.data.data);
            setLoad(false);
          });
        }}
        noDataText="No data"
      />
    </React.Fragment>
  );
};

export default Favorites;
