import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Header from "../../components/Page/Header";
import { IconButton, Tooltip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { V3 } from "../../lib/api";
import { API_V3 } from "../../config";

function Emails() {
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    setLoading(true);
    V3.get(`mail`).then((res) => {
      setEmails(res.data.data);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      id: "title",
      Header: "Title",
      accessor: (d) => d.title,
      Cell: (props) => (
        <a
          href={`${API_V3}mail/${props.original.name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>{props.original.title}</strong>
        </a>
      ),
    },
    {
      id: "description",
      Header: "Description",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.description}</strong>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Edit this Email">
            <span className="action fi">
              <IconButton
                component={"a"}
                href={`${API_V3}mail/${props.original.name}`}
                target="_blank"
              >
                <OpenInNewIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Emails"></Header>
      <Table
        data={emails}
        columns={columns}
        loading={loading}
        page={1}
        pages={1}
        onFetchData={(state) => {}}
        noDataText="No Emails"
      />
    </React.Fragment>
  );
}

export default Emails;
