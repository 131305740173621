import React from "react";
import { useSelector } from "react-redux";
function Authorize({ roles, children }) {
  const { user, isAuthenticated, isLoading } = useSelector((s) => s.auth);

  if (!isLoading) {
    if (isAuthenticated && user && roles.includes(user.role)) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  } else {
    return <React.Fragment />;
  }
}

export default Authorize;
