import React, { useEffect, useState } from "react";
import Card from "../../Card";

import Table from "../../Table";
import Actions from "../../Table/Actions";
import New from "./New";
import DeleteIcon from "@material-ui/icons/Delete";

import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import { useDispatch } from "react-redux";
import { SET_INTERESTED } from "../../../redux/types";
import { useConfirm } from "material-ui-confirm";
import EditInterested from "./Edit";

function JobInterested({ data }) {
  const [loading, setLoading] = useState(false);
  const [interested, setInterested] = useState([]);
  const confirm = useConfirm();

  const dispatch = useDispatch();

  useEffect(() => {
    setInterested(data?.interested || []);
  }, [data]);

  const handleDelete = (id) => {
    confirm({
      title: "Delete this interested",
      description: "Are you sure you wont to delete this interested",
    }).then(() => {
      setLoading(true);
      V3.delete(`jobs/${data._id}/interested/${id}`).then((res) => {
        dispatch({ type: SET_INTERESTED, payload: res.data?.data?.interested });

        setLoading(false);
      });
    });
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original?.name}</strong>,
    },
    {
      id: "linkedinProfile",
      Header: "Linkedin Profile",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {props.original?.linkedinProfile ? (
            <a
              href={props.original?.linkedinProfile}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textTransform: "none" }}
            >
              {props.original?.linkedinProfile}
            </a>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <EditInterested data={props.original} job={data._id} />
          <span onClick={() => handleDelete(props.original._id)}>
            <DeleteIcon />
          </span>
        </Actions>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Interessenten ohne Tel / Positive Reaktionen"
        button={<New data={data} />}
      >
        <Table
          data={interested}
          columns={columns}
          loading={loading}
          page={0}
          pages={1}
          onFetchData={() => {}}
          filterable={false}
          minRows={5}
          noDataText="No Data"
        />
      </Card>
    </>
  );
}

export default JobInterested;
