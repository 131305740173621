import {
  GET_EXTERNAL_CLICKS,
  GET_EXTERNAL_CLICK,
  ADD_EXTERNAL_CLICK,
  DELETE_EXTERNAL_CLICK,
  EXTERNAL_CLICK_LOADING,
} from "../types";

const initialState = {
  externalClicks: [],
  externalClick: null,
  data: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXTERNAL_CLICKS:
      return {
        ...state,
        externalClicks: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_EXTERNAL_CLICK:
      return {
        ...state,
        externalClick: action.payload.data,
        loading: false,
      };
    case DELETE_EXTERNAL_CLICK:
      return {
        ...state,
        externalClicks: state.externalClicks.filter(
          (externalClick) => externalClick._id !== action.payload
        ),
      };
    case ADD_EXTERNAL_CLICK:
      return {
        ...state,
        externalClicks: [...action.payload, ...state.externalClicks],
      };

    case EXTERNAL_CLICK_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
