import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Card from "../Card";
import moment from "moment";
import { useSelector } from "react-redux";
import { V3 } from "../../lib/api";
import ChartTooltip from "../Chart/Tooltip";
import usePrice from "../../hooks/usePrice";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";
import MonthlyTooltip from "../Chart/MonthlyTooltip";
function Sales() {
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().startOf("year"));
  const [endDate, setEndDate] = React.useState(moment(new Date()));

  const { user } = useSelector((state) => state.auth);

  function formatXAxis(tickItem) {
    return moment(tickItem).format("D.MM.YYYY");
  }

  const fetchData = (start, end) => {
    V3.get(
      `/crm/companies/sales?user=${user._id}&start=${moment(start).format(
        "DD/MM/YYYY"
      )}&end=${moment(end).format("DD/MM/YYYY")}`
    ).then((res) => {
      setData(res.data.sales);
    });
  };

  React.useEffect(() => {
    fetchData(moment(startDate), moment(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const totalSales = usePrice(data && data.reduce((a, b) => +a + +b.amount, 0));

  function groupByMonthYear(response) {
    // output
    const groupedData = [];

    // Using map for lookup to avoid iterating again on the grouped data
    const referenceMap = new Map();

    // destructing month, year and id from the response
    for (const { _id, amount, date } of response) {
      const groupKey = moment(date).format("MM/YYYY");

      // check if the month and year reference is already seen using the groupKey MMYYYY
      if (referenceMap.has(groupKey)) {
        referenceMap.get(groupKey)._id.push(_id);
        referenceMap.get(groupKey).amount += amount;
        // early return
        continue;
      }

      // simply add a new entry if it doesn't exist
      const data = {
        month: moment(date).format("MM"),
        year: moment(date).format("YYYY"),
        date: moment(date).format("MM/YYYY"),
        endDate: moment(date).endOf("month").format(),
        _id: [_id],
        amount,
      };

      groupedData.push(data);
      referenceMap.set(groupKey, data);
    }

    return groupedData;
  }

  const sales = groupByMonthYear(data);

  sales.sort((a, b) => {
    return moment(a.endDate).isBefore(moment(b.endDate)) ? -1 : 1;
  });

  return (
    <Card
      title="Verkauf"
      style={{ height: 680, marginTop: 15 }}
      button={
        <>
          <span style={{ marginRight: 15, fontSize: 16 }}>
            <strong>Total:</strong> {totalSales} CHF
          </span>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          {data.length > 0 ? (
            <div
              style={{
                width: "calc(100% + 20px)",
                height: 300,
                marginLeft: -15,
                marginBottom: -15,
                padding: 15,
              }}
            >
              <ResponsiveContainer>
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tickFormatter={formatXAxis} />
                  <YAxis />
                  <Tooltip content={<ChartTooltip />} />
                  <Line type="monotone" dataKey="amount" stroke="#03fc41" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                padding: 15,
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -15,
              }}
            >
              Keine Daten für diesen Zeitraum
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {sales.length > 0 ? (
            <div
              style={{
                width: "100%",
                height: 300,
                marginLeft: -15,
                marginBottom: -15,
                padding: 15,
              }}
            >
              <ResponsiveContainer>
                <LineChart data={sales}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    // tickFormatter={formatSaleXAxis}
                  />
                  <YAxis />
                  <Tooltip content={<MonthlyTooltip />} />
                  <Line type="monotone" dataKey="amount" stroke="#03fc41" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                padding: 15,
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -15,
              }}
            >
              Keine Daten für diesen Zeitraum
            </div>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default Sales;
