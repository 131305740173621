import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Company from "../components/Search/Company";
import Grid from "@material-ui/core/Grid";
import PageHeader from "../components/Page/Header";
import InfiniteScroll from "react-infinite-scroll-component";
import { V3 } from "../lib/api";
import { GET_SEARCH, SEARCH_LOADING, SET_SEARCH } from "../redux/types";

function Search({ location }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: SEARCH_LOADING });
    V3.get(`/search/companies${location.search}`).then((res) => {
      dispatch({
        type: SET_SEARCH,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  const { searchData, data, loading } = useSelector((s) => s.search);

  const searchMore = () => {
    dispatch({ type: SEARCH_LOADING });
    V3.get(
      `/search/companies${location.search}&page=${
        data?.pagination?.next?.page || 1
      }`
    ).then((res) => {
      dispatch({
        type: GET_SEARCH,
        payload: res.data,
      });
    });
  };

  if (searchData.length > 0) {
    return (
      <div style={{ overflow: "hidden" }}>
        <PageHeader title={"Unternehmen"} size={`${data.total}`} />

        <InfiniteScroll
          key={"scroll"}
          dataLength={searchData?.length - 1}
          next={() => {
            if (!loading) {
              searchMore();
            }
          }}
          style={{ width: "100%", height: "100%", overflow: "unset" }}
          loading={loading}
          hasMore={data?.pagination?.next?.page ? true : false}
          // hasMore={true}
          useWindow={false}
          loader={loading && <h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          endMessage={<h4 style={{ textAlign: "center" }}>No more data</h4>}
        >
          <Grid container spacing={2}>
            {searchData.map((el) => (
              <Grid item xs={12} md={6} lg={4} key={el._id}>
                <Company data={el} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Keine Ergebnisse</h1>
      </div>
    );
  }
}

export default Search;
