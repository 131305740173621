import React, { useEffect } from "react";
import Card from "../../Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";

import ShowMore from "@tedconf/react-show-more";
import Button from "@material-ui/core/Button";
import { DASHBOARD } from "../../../config";
import styled from "styled-components";
import Loader from "../../Loader";
import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import { ACTIVITY_LOADING, GET_ACTIVITY } from "../../../redux/types";
const Wrapper = styled.div`
  min-height: 350px;
  p {
    margin: 0;
    ul {
      padding-left: 30px;
    }
  }
`;
function Activities({ companyId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: ACTIVITY_LOADING,
    });
    let query = `?company=${companyId}&limit=999`;
    V3.get(`/crm/activities${query}`).then((response) => {
      dispatch({
        type: GET_ACTIVITY,
        payload: response.data,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const { activity, loading } = useSelector((state) => state.activity);

  return (
    <Loader loading={loading}>
      <Card title="Aktivität" style={{ minHeight: 400, height: "100%" }}>
        {activity.length <= 0 && (
          <p style={{ margin: 0, textAlign: "center", marginTop: 60 }}>
            No Activity
          </p>
        )}
        <ShowMore items={activity} by={4}>
          {({ current, onMore }) => (
            <Wrapper>
              <List>
                {current.map((item) => (
                  <React.Fragment>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        {item.isBot || !item.user ? (
                          <Avatar
                            src={`${DASHBOARD}/images/bot.jpg`}
                            alt="TWO JOBS BOT"
                          />
                        ) : (
                          <Avatar
                            src={item.user?.profile?.picture}
                            alt={item.user?.profile?.name}
                          />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          item.isBot ? "TWO JOBS BOT" : item.user?.profile?.name
                        }
                        secondary={
                          <React.Fragment>
                            <p>
                              <strong>Date:</strong>{" "}
                              <Moment format="DD.MM.YYYY hh.mm">
                                {item.createdAt}
                              </Moment>
                            </p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
              {activity.length > 0 && (
                <div style={{ padding: 15 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!onMore}
                    onClick={() => {
                      if (!!onMore) onMore();
                    }}
                  >
                    {!onMore ? "Nicht mehr" : "Mehr anzeigen"}
                  </Button>
                </div>
              )}
            </Wrapper>
          )}
        </ShowMore>
      </Card>
    </Loader>
  );
}
export default Activities;
