import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "material-ui-formik-components/Switch";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Statuses } from "../../../../data";
import Invoice from "./Invoice";
import Offer from "./Offer";
import Loader from "../../../GlobalLoader";
import FileUpload from "./FileUpload";
import { DialogTitle, DialogActions, DialogContent } from "../../../Dialog";
import { V3 } from "../../../../lib/api";
import ErrorDialog from "../../../ErrorDialog";
import Card from "../../../Card";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { ADD_ACTIVITY, ADD_STATUS } from "../../../../redux/types";
import { useSnackbar } from "notistack";

const checkIfPotentialIsValid = (values) => {
  if (values.type.key !== 5 && values.type.key !== 7) {
    return {
      potentialPackage: true,
      potentialActiveServices: true,
      potentialChance: true,
      potentialAvailable: true,
      isValid: true,
    };
  }
  const potentialPackage = values.potentialPackage > -1 ? true : false;
  const potentialActiveServices =
    values.potentialActiveServices > -1 ? true : false;
  const potentialChance = values?.potentialChance > -1 ? true : false;
  const potentialAvailable = values.potentialAvailable ? true : false;
  let isValid = false;
  if (
    potentialPackage &&
    potentialActiveServices &&
    potentialChance &&
    potentialAvailable
  ) {
    isValid = true;
  }

  return {
    potentialPackage,
    potentialActiveServices,
    potentialChance,
    potentialAvailable,
    isValid,
  };
};

const checkIfRejectsIsValid = (values) => {
  if (values?.type?.key !== 2) {
    return { isValid: true };
  }

  const isValid = values.rejects.length > 0;
  return { isValid };
};
function NewStatus(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [files, setFiles] = useState([]);
  const [rejects, setRejects] = useState([]);
  const { company } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.auth);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    V3.get("/rejects").then((res) => {
      setRejects(res.data.data);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (selectorFiles) => {
    setFiles(selectorFiles);
  };

  const _onSubmit = async (values, formikApi) => {
    setLoading(true);

    const data = {
      company: company._id,
      user: user._id,
      type: `${values?.type?.key}`,
      amount: values.amount,
      noSale: values.noSale,
      notExtended: values.notExtended,
      date: values.date ? moment(values.date).format() : null,
      description: values.description,
      sendEmailTo: values.sendEmailTo,
      invoice: {
        company: company._id,
        vertragesnr: values.vertragesnr,
        vertragesnrActiveSourcing: values.vertragesnrActiveSourcing,
        vertragesnrAllInOne: values.vertragesnrAllInOne,
        user: user._id,
        invoiceDate: values.data,
        startDate: values.startDate,
        endDate: values.endDate,
        discount: values.discount ? values.discount : 0,
        to: {
          ...values?.to,
          city: `${values?.to?.zip} ${values?.to?.city}`,
        },
        contactPerson: values.contactPerson,
        package: values.package,
        services: values.services,
        allInOne: values.allInOne,
        jobQuiz: values.jobQuiz,
        activeServices: values.activeServices,
        giftedServices: values.giftedServices,
        taxAmount: 7.7,
        sendEmail: values.sendEmail,
        files: null,
        // files: res.data.data,
      },
      setPipeLine: values.setPipeLine,
      potential: {
        package: values.potentialPackage,
        activeServices: values.potentialActiveServices,
        chance: values.potentialChance,
        available: values.potentialAvailable,
        company: company._id,
        statusType: values.type.key,
      },
      rejects: values.rejects.map((el) => el._id),
      sendGift: values.sendGift,
      sendGiftTo: values.sendGiftTo,
    };
    if (data.type === 6) {
      data.sendGift = true;
      data.sendGiftTo = values.contactPerson;
    }
    if (files?.length) {
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file);
        });

        const res = V3.post(`/zip`, formData);
        data.invoice.files = res.data.data;
      } catch (error) {
        console.log("error", error);
      }
    }
    V3.post(`crm/statuses`, data)
      .then((response) => {
        dispatch({
          type: ADD_STATUS,
          payload: response.data.data,
        });
        dispatch({
          type: ADD_ACTIVITY,
          payload: response.data.activity,
        });
        enqueueSnackbar("Status created Successfully", {
          variant: "success",
        });
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        setError(true);

        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        setLoading(false);
        setOpen(false);
      });
  };

  let contactPersons = [];

  if (company?.contactPersons?.length > 0) {
    contactPersons = company.contactPersons
      .filter((el) => el.firstName && el.lastName && el.email)
      .map((person) => {
        return {
          ...person,
          key: person.email,
          name: person.firstName + " " + person.lastName,
          email: person.email,
          phone: person.phone,
          label: person.firstName + " " + person.lastName,
          value: person.firstName + " " + person.lastName,
          _id: person._id,
        };
      });
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <ErrorDialog
        open={error}
        handleClose={() => setError(false)}
      ></ErrorDialog>
      <Button
        color="primary"
        size="small"
        onClick={handleClickOpen}
        variant="outlined"
      >
        New Status
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth fullScreen={true}>
        <DialogTitle onClose={handleClose}>New Status</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            type: Yup.object().nullable(),
            amount: Yup.string().nullable(),
            date: Yup.string().nullable(),
            description: Yup.string(),
            startDate: Yup.string()
              .nullable()
              .when("type", (type) => {
                if (type && type.key === 6)
                  return Yup.date()
                    .nullable(false)
                    .typeError("Select a date")
                    .required("Please select a start date");
              }),
            endDate: Yup.string()
              .nullable()
              .when("type", (type) => {
                if (type && type.key === 6)
                  return Yup.date()
                    .nullable(false)
                    .typeError("Select a date")
                    .required("Please select a start date");
              }),
            contactPerson: Yup.object()
              .nullable()
              .when("type", (type) => {
                if (type && type.key === 6)
                  return Yup.object()
                    .nullable(false)
                    .typeError("Select a contact person")
                    .required("Select a contact person");
              }),
          })}
          onSubmit={_onSubmit}
          initialValues={{
            noSale: false,
            type: Statuses[0],
            amount: null,
            date: null,
            description: "",
            package: {
              description: "Grundpaket Reichweite",
              items: [{ name: "", jobs: 0, months: 0, price: 0 }],
            },
            services: {
              description: "Zusatzdienstleistungen",
              items: [{ name: "", jobs: 0, description: "", price: 0 }],
            },
            allInOne: {
              description: "All In One",
              items: [{ name: "", jobs: 0, description: "", price: 0 }],
            },
            jobQuiz: {
              description: "Job Quiz",
              items: [{ name: "", jobs: 0, description: "", price: 0 }],
            },

            activeServices: {
              ad: false,
              eb: false,
              qd: false,
            },
            giftedServices: {
              ad: false,
              eb: false,
              qd: false,
            },
            startDate: new Date(),
            endDate: moment(new Date()).add(3, "months"),
            contactPerson: null,
            discount: 0,
            sendEmail: true,
            show: true,
            sendEmailTo: [],
            rejects: [],
            sendGift: false,
            potentialAvailable: null,
            vertragesnr: "",
            vertragesnrActiveSourcing: "",
            vertragesnrAllInOne: "",
            to: {
              street: company?.street,
              city: company?.city,
              zip: company?.zip,
              name: company?.name,
            },
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Field
                  component={MuiAutocomplete}
                  fullWidth
                  name="type"
                  value={values.type}
                  options={Statuses}
                  getOptionLabel={(option) => option.label}
                  textFieldProps={{
                    label: "Typ",
                    variant: "outlined",
                  }}
                  onChange={(e, val) => {
                    setFieldValue("type", val);
                    if (val?.key === 3) {
                      setFieldValue("sendGift", true);
                    } else {
                      setFieldValue("sendGift", false);
                    }
                  }}
                  renderInput={(params) => (
                    <MuiTextField {...params} variant="outlined" label="Typ" />
                  )}
                />

                {values.type !== "7" && (
                  <React.Fragment>
                    {values.type && values.type.key === 5 && (
                      <Field
                        component={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="amount"
                        disabled={values?.type?.key === 5}
                        label={
                          values.type.key === 5
                            ? "Betrag"
                            : "Total verkauft exkl. MwSt."
                        }
                        name="amount"
                      />
                    )}

                    {values.type &&
                      values.type.key !== 1 &&
                      values.type.key !== 2 &&
                      values.type.key !== 8 && (
                        <Field
                          component={KeyboardDatePicker}
                          label="Datum"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          placeholder="15/04/2020"
                          name="date"
                          style={{ marginTop: 15 }}
                          fullWidth
                          value={values.date}
                          onChange={(date) => setFieldValue("date", date)}
                        />
                      )}
                    {values?.type?.key === 3 && (
                      <MuiAutocomplete
                        multiple
                        options={contactPersons}
                        getOptionLabel={(option) => option.label}
                        style={{ width: "100%", marginTop: 15 }}
                        onChange={(event, value) =>
                          setFieldValue("sendEmailTo", value)
                        }
                        value={values.sendEmailTo}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            placeholder="Select"
                            variant="outlined"
                            label="Erinnerung an:"
                          />
                        )}
                      />
                    )}
                  </React.Fragment>
                )}
                {(values?.type?.key === 2 ||
                  (values?.type?.key === 4 && values?.noSale)) && (
                  <MuiAutocomplete
                    multiple
                    options={rejects}
                    getOptionLabel={(option) => option.name}
                    style={{ width: "100%", marginTop: 15 }}
                    onChange={(event, value) => setFieldValue("rejects", value)}
                    value={values.rejects}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        label="Grunde"
                        error={!checkIfRejectsIsValid(values).isValid}
                        helperText={
                          !checkIfRejectsIsValid(values).isValid &&
                          "Please select an option"
                        }
                      />
                    )}
                  />
                )}

                {(values.type.key === 8 ||
                  values.type.key === 2 ||
                  values.type.key === 3 ||
                  values.type.key === 4) && (
                  <div
                    style={{
                      width: 180,
                      display: "flex",
                      alignItems: "center",
                      marginTop: 15,
                      marginLeft: 15,
                    }}
                  >
                    Send Gift
                    <Field
                      component={Switch}
                      name="sendGift"
                      id="sendGift"
                      style={{ width: "auto", marginLeft: 25 }}
                    />
                  </div>
                )}

                {values.sendGift && (
                  <Field
                    component={Autocomplete}
                    fullWidth
                    required
                    name="sendGiftTo"
                    options={contactPersons}
                    textFieldProps={{
                      label: "Contact Person",
                      variant: "outlined",
                      style: { margin: 0 },
                    }}
                    style={{ margin: 0, marginTop: 15 }}
                  />
                )}
                {values.type.key === 4 && (
                  <div
                    style={{
                      width: 180,
                      display: "flex",
                      alignItems: "center",
                      marginTop: 15,
                      marginLeft: 15,
                    }}
                  >
                    Kein Verkauf
                    <Field
                      component={Switch}
                      name="noSale"
                      id="noSale"
                      style={{ width: "auto", marginLeft: 25 }}
                    />
                  </div>
                )}
                {values.type.key === 4 &&
                  !values.noSale &&
                  !values.notExtended && (
                    <div
                      style={{
                        width: 180,
                        display: "flex",
                        alignItems: "center",
                        marginTop: 15,
                        marginLeft: 15,
                      }}
                    >
                      Set Pipeline
                      <Field
                        component={Switch}
                        name="setPipeLine"
                        id="setPipeLine"
                        style={{ width: "auto", marginLeft: 25 }}
                      />
                    </div>
                  )}
                {values.type.key === 4 && (
                  <div
                    style={{
                      width: 180,
                      display: "flex",
                      alignItems: "center",
                      marginTop: 15,
                      marginLeft: 15,
                    }}
                  >
                    Keine Verlängerung
                    <Field
                      component={Switch}
                      name="notExtended"
                      id="notExtended"
                      style={{ width: "auto", marginLeft: 25 }}
                    />
                  </div>
                )}
                {((values.type.key === 4 && values.setPipeLine) ||
                  values.type.key === 5 ||
                  values.type.key === 7) && (
                  <Card title={"Auftragpipeline"} style={{ marginTop: 15 }}>
                    {!checkIfPotentialIsValid(values).isValid && (
                      <div style={{ padding: 15 }}>
                        <Alert severity="error">
                          Bitte füllen Sie alle Felder aus
                        </Alert>
                      </div>
                    )}
                    <div style={{ padding: 15 }}>
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="potentialPackage"
                        label="Potential Paket"
                        name="potentialPackage"
                        style={{ marginTop: 0 }}
                      />
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="potentialActiveServices"
                        label="Potential ZDL"
                        name="potentialActiveServices"
                      />
                      <Field
                        component={TextField}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="potentialChance"
                        label="Chance"
                        name="potentialChance"
                      />
                      <Field
                        component={KeyboardDatePicker}
                        label="Kommt in"
                        format="MM.yyyy"
                        inputVariant="outlined"
                        placeholder="04/2020"
                        name="date"
                        views={["month", "year"]}
                        fullWidth
                        value={values.potentialAvailable}
                        onChange={(date) =>
                          setFieldValue("potentialAvailable", date)
                        }
                      />
                    </div>
                  </Card>
                )}

                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Beschreibung"
                  name="description"
                  multiline
                  rows={4}
                />

                {values.type && values.type.key === 6 && (
                  <React.Fragment>
                    <Invoice
                      setFieldValue={setFieldValue}
                      values={values}
                      company={company}
                    />
                    <FileUpload handleChange={handleChange} />
                  </React.Fragment>
                )}
                {values.type && values.type.key === 5 && (
                  <React.Fragment>
                    <Offer
                      setFieldValue={setFieldValue}
                      values={values}
                      company={company}
                    />
                    <FileUpload handleChange={handleChange} />
                  </React.Fragment>
                )}
              </DialogContent>
              <DialogActions style={{ padding: "15px 24px" }}>
                <Button onClick={handleClose} color="primary">
                  Schließen
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  type="submit"
                  disabled={
                    !checkIfPotentialIsValid(values).isValid ||
                    !checkIfRejectsIsValid(values).isValid
                  }
                >
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default NewStatus;
