import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../components/GlobalLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "../../components/Table";
import { useEffect } from "react";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import Header from "../../components/Page/Header";
import { V3 } from "../../lib/api";
import EditIcon from "@material-ui/icons/Edit";
function Categories() {
  const [open, setOpen] = React.useState(false);
  const [currentCategory, setCurrentCategory] = React.useState(false);
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [hashtag, setHashtag] = useState(null);
  useEffect(() => {
    setLoading(false);
    V3.get("/hashtags")
      .then((result) => {
        setHashtags(result.data.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const _handleSubmit = (values, formikApi) => {
    V3.post("/hashtags", values).then((res) => {
      setHashtags([res.data.data, ...hashtags]);
      formikApi.setSubmitting(false);
    });
  };
  const _handleSubmitUpdate = (values, formikApi) => {
    V3.put(`/hashtags/${hashtag?._id}`, values).then((res) => {
      const newData = res.data.data;
      setHashtags(
        hashtags.map((el) => {
          if (el._id === newData._id) {
            return newData;
          } else {
            return el;
          }
        })
      );
      formikApi.setSubmitting(false);
      setOpenEdit(false);
    });
  };

  const handleDelete = () => {
    setLoading(true);
    V3.delete(`/hashtags/${currentCategory}`).then((res) => {
      setHashtags(hashtags.filter((el) => el._id !== currentCategory));
      setLoading(false);
    });
    setOpen(false);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCurrentCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      id: "title",
      Header: "Hashtag",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong style={{ textTransform: "none" }}>
          {props.original.title}
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {" "}
          <IconButton
            onClick={() => {
              setHashtag(props.original);
              setOpenEdit(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleClickOpen(props.original._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Job Hashtags" />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Formik
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Required"),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              title: "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Hashtag"
                  name="title"
                  style={{ marginTop: 0 }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 30 }}
                >
                  Create category
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={8}>
          <Table
            data={hashtags}
            columns={columns}
            loading={loading}
            noDataText="No categories"
            manual={false}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kategorie löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wirklich diese Kategorie löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Kategorie aktualisieren</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          onSubmit={_handleSubmitUpdate}
          enableReinitialize
          initialValues={{
            title: hashtag?.title,
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Hashtag"
                  name="title"
                  style={{ marginTop: 0 }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenEdit(false)}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default Categories;
