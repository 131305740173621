import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Header from "../components/Page/Header";
import { Link } from "react-router-dom";
import { V3 } from "../lib/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import Moment from "react-moment";
import {
  APPLICATIONS_LOADING,
  DELETE_APPLICATION,
  GET_APPLICATIONS,
} from "../redux/types";

function Applications() {
  const { applications, data, loading } = useSelector(
    (state) => state.application
  );

  const [companies, setCompanies] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [recruiters, setRecruiter] = useState([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleAccept = (info) => {
    confirm({
      title: `Bewerbung löschen`,
      description: "Sie können diese Anwendung nicht mehr wiederherstellen!",
    }).then(() => {
      dispatch({
        type: APPLICATIONS_LOADING,
      });
      V3.delete(`/applications/${info._id}`).then((res) => {
        dispatch({
          type: DELETE_APPLICATION,
          payload: info._id,
        });
        enqueueSnackbar("Application deleted", {
          variant: "success",
        });
      });
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await V3.get(`/companies?limit=1000&sort=name`);
        setCompanies(res.data.data);
        const jobsRes = await V3.get(
          `/jobs?limit=1000&published=true&inApplication=true`
        );
        setJobs(jobsRes.data.data);
        const recruiterRes = await V3.get(`/users?limit=1000&role=recruiter`);
        setRecruiter(
          recruiterRes?.data?.data?.filter(
            (user) => user.recruiter !== undefined && user.oldUser === false
          )
        );
      } catch (error) {}
    };
    fetchData();
  }, []);

  const columns = [
    {
      id: "name",
      Header: "Kandidaten Name",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <Link to={`/applications/edit/${props.original._id}`}>
          <strong>{props.original.profile.name}</strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "E-Mail Kandidat",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <a
          href={`mailto:${props.original.email}`}
          style={{ textTransform: "lowercase" }}
        >
          {props.original.email}
        </a>
      ),
    },
    {
      id: "phone",
      Header: "Tel. Kandidat",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <a
          href={`tel:${props.original.profile.phone}`}
          className="lowercase tel--number"
        >
          {props.original.profile.phone}
        </a>
      ),
    },
    {
      id: "job",
      Header: "Jobtitel",
      filterable: true,
      accessor: (d) => d._id,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={filter ? jobs.find((job) => job._id === filter.value) : null}
          options={jobs}
          getOptionLabel={(option) => option?.title || ""}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
      Cell: (props) => (
        <React.Fragment>
          {props.original.job && props.original.job._id && (
            <Link to={`/jobs/edit/${props.original.job._id}`}>
              {props.original.job.title}
            </Link>
          )}
        </React.Fragment>
      ),
    },
    {
      id: "company",
      Header: "Unternehmen",
      filterable: true,
      accessor: (d) => d._id,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) => onChange(value ? value._id : "")}
          style={{ width: "100%" }}
          value={
            filter
              ? companies.find((company) => company._id === filter.value)
              : null
          }
          options={companies}
          getOptionLabel={(option) => option?.name || ""}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
      Cell: (props) => (
        <React.Fragment>
          {props.original.job &&
            props.original.job.company &&
            props.original.job.company._id && (
              <Link to={`/companies/edit/${props.original.job.company._id}`}>
                {props.original.job.company.name}
              </Link>
            )}
        </React.Fragment>
      ),
    },
    {
      id: "infoStatus",
      Header: "Status",
      accessor: (d) => d.infoStatus,
      filterable: true,
      Cell: (props) => {
        if (props.original.infoStatus === 1) {
          return <span>Weitergeleitet</span>;
        } else if (props.original.infoStatus === 2) {
          return <span>Ablehnen</span>;
        } else if (props.original.infoStatus === 3) {
          return <span>Aufgenommen</span>;
        } else if (props.original.infoStatus === 4) {
          return <span>Stelle besetzt</span>;
        } else if (props.original.infoStatus === 5) {
          return <span>Erledigt</span>;
        } else if (props.original.infoStatus === 6) {
          return <span>Weitergeleitet (Dashboard)</span>;
        } else {
          return <span>-</span>;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="1">Weitergeleitet</option>
          <option value="2">Ablehnen</option>
          <option value="3">Aufgenommen</option>
          <option value="4">Stelle besetzt</option>
          <option value="5">Erledigt</option>
          <option value="6">Weitergeleitet (Dashboard)</option>
        </select>
      ),
    },

    {
      id: "refId",
      Header: "Referrer Name",
      accessor: (d) => d.recruiter,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <Autocomplete
          onChange={(event, value) =>
            onChange(value ? value?.recruiter?.refId : "")
          }
          style={{ width: "100%" }}
          value={
            filter
              ? recruiters.find(
                  (recruiter) => recruiter?.recruiter?.refId === filter.value
                )
              : null
          }
          options={recruiters}
          getOptionLabel={(option) =>
            option?.profile ? option?.profile?.name || "" : ""
          }
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ),
      Cell: (props) => (
        <>
          {props.original.recruiter &&
          props.original.recruiter._id &&
          props.original.recruiter.user ? (
            <Link to={`/recruiter/${props.original.recruiter._id}`}>
              <strong>{props.original.recruiter.user.profile.name}</strong>
            </Link>
          ) : (
            <strong>-</strong>
          )}
        </>
      ),
    },
    {
      id: "createdAt",
      Header: "Erstellt",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "action",
      Header: "Aktion",
      accessor: (d) => d.status,
      Cell: (props) => (
        <IconButton onClick={() => handleAccept(props.original)}>
          <Delete />
        </IconButton>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Bewerbungen" size={data.total} />
      <Table
        data={applications}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          dispatch({
            type: APPLICATIONS_LOADING,
          });
          V3.get(`/applications${query}`).then((res) => {
            dispatch({
              type: GET_APPLICATIONS,
              payload: res.data,
            });
          });
        }}
        noDataText="Keine Bewerbungen"
      />
    </React.Fragment>
  );
}

export default Applications;
