import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";
import SendCrawlerEmailTo from "../Widgets/SendCrawlerEmailTo";
import Moment from "react-moment";
import { V3 } from "../../../lib/api";
import { CRAWLED_JOBS_LOADING, GET_CRAWLED_JOBS } from "../../../redux/types";

const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 8px;
  width: max-content;
  margin-bottom: 15px;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

function CrawledJobs(props) {
  const { crawledJobs, data, loading } = useSelector(
    (state) => state.crawledJob
  );
  const { companyId, company, setCompany } = props;

  const [archived, setArchived] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link
          to={`/crawled/jobs/${props.original._id}`}
          style={{ textTransform: "none" }}
        >
          <strong>{props.original.title}</strong>
        </Link>
      ),
    },
    {
      id: "ad",
      Header: "Active Sourcing",
      accessor: (d) => d.ad,
      filterable: false,
      Cell: (props) => (
        <div>
          {props.original.ad === true ? <span>Ja</span> : <span>Nein</span>}
        </div>
      ),
    },
    {
      id: "toContact",
      Header: "To Contact",
      accessor: (d) => d.toContact,
      filterable: false,
      Cell: (props) => (
        <div>
          {props.original.ad === true && props.original.toContact ? (
            <span>{props.original.toContact}</span>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },

    {
      id: "companyPublished",
      Header: "Unternehmen veröffentlicht",
      accessor: (d) => d.companyPublished,
      filterable: false,
      Cell: (props) => (
        <div>
          {props.original.companyPublished === true ? (
            <span>Ja</span>
          ) : (
            <span>Nein</span>
          )}
        </div>
      ),
    },
    {
      id: "companyId",
      Header: "Unternehmen",
      accessor: (d) => d.toContact,
      filterable: false,
      Cell: (props) => (
        <>
          <IconButton
            component={Link}
            to={`/companies/analytics/${props.original.companyId}`}
            target="_blank"
          >
            <OpenInNewIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "createdAt",
      Header: "Erstellt",
      accessor: (d) => d.createdAt,
      filterable: false,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "published",
      Header: "Publiziert",
      accessor: (d) => d.published,
      width: 120,
      filterable: true,
      Cell: (props) => (
        <div>
          {props.original.published === true ? (
            <span>Ja</span>
          ) : (
            <span>Nein</span>
          )}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option key={true} value={true}>
            Ja
          </option>
          <option key={false} value={false}>
            Nein
          </option>
        </select>
      ),
    },
  ];
  return (
    <React.Fragment>
      <ButtonsWrapper>
        <Button
          onClick={() => setArchived(true)}
          variant="contained"
          className={`custom-btn ${archived ? "active" : "not-Active"}`}
          color="primary"
        >
          Überprüft
        </Button>
        <Button
          onClick={() => setArchived(false)}
          variant="contained"
          className={`custom-btn ${!archived ? "active" : "not-Active"}`}
          color="primary"
        >
          Zu überprüfen
        </Button>
      </ButtonsWrapper>
      {company && (
        <SendCrawlerEmailTo companyData={company} setCompany={setCompany} />
      )}

      <Table
        data={crawledJobs}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}${
            companyId ? `&company=${companyId}` : ""
          }&${queryString}`;
          dispatch({ type: CRAWLED_JOBS_LOADING });
          V3.get(`/scraped-jobs${query}`).then((res) => {
            dispatch({
              type: GET_CRAWLED_JOBS,
              payload: res.data,
            });
          });
        }}
        noDataText="No jobs"
        filterable={true}
        defaultFiltered={[
          {
            id: "companyPublished",
            value: archived,
          },
        ]}
      />
    </React.Fragment>
  );
}

export default CrawledJobs;
