import React, { useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Sector } from "recharts";
import Card from "../Card";
import moment from "moment";
import { V3 } from "../../lib/api";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { KeyboardDatePicker } from "@material-ui/pickers";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

function Appointments({ user }) {
  const date = moment().startOf("year");
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(date);
  const [endDate, setEndDate] = React.useState(moment(new Date()));
  const [index, setIndex] = React.useState(0);
  const [noStatus, setNoStatus] = useState(0);
  const [futureCompanies, setFutureCompanies] = useState(0);
  const [pastCompanies, setPastCompanies] = useState(0);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [meeting, setMeeting] = useState(0);

  const fetchData = (start, end) => {
    if (user) {
      V3.get(
        `/crm/companies/appointments?user=${user}&start=${moment(start).format(
          "DD/MM/YYYY"
        )}&end=${moment(end).format("DD/MM/YYYY")}`
      ).then((res) => {
        setData(res.data.data);
        setNoStatus(res.data.noStatus);
        setFutureCompanies(res.data.futureCompanies);
        setPastCompanies(res.data.pastCompanies);
        setAppointmentCount(res.data.appointmentCount);
        setMeeting(res.data.meetings);
      });
    }
  };

  React.useEffect(() => {
    fetchData(moment(startDate), moment(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, startDate, endDate]);

  const onPieEnter = (data, index) => {
    setIndex(index);
  };
  let termineYesTotal = (100 / data) * meeting;

  termineYesTotal = termineYesTotal.toFixed(2);
  if (isNaN(termineYesTotal)) {
    termineYesTotal = 0;
  }

  const chartData = [
    { name: "Termine", value: meeting.length },
    {
      name: "Cancellations",
      value: data.length - meeting.length,
    },
  ];

  return (
    <Card
      title="Gesetzte Termine"
      style={{ height: "100%" }}
      button={
        <Grid container spacing={2} style={{ maxWidth: 500 }}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="From"
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {data ? (
            <div
              style={{
                width: "calc(100% + 20px)",
                height: 300,
                marginLeft: -15,
                marginBottom: -15,
                padding: 15,
              }}
            >
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    activeIndex={index}
                    activeShape={renderActiveShape}
                    data={chartData}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                padding: 15,
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -15,
              }}
            >
              Keine Daten für diesen Zeitraum
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              borderLeft: "1px solid rgba(241, 241, 241, 0.2)",
              height: "100%",
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Unternehmen erreicht
                  </TableCell>
                  <TableCell align="right">{data}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Termine
                  </TableCell>
                  <TableCell align="right">{meeting}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Ersttermin Quote
                  </TableCell>
                  <TableCell align="right">
                    {meeting - appointmentCount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Absagen
                  </TableCell>
                  <TableCell align="right">{data - meeting}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Terminquote
                  </TableCell>
                  <TableCell align="right">{termineYesTotal}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Termin nach Status nachfassen
                  </TableCell>
                  <TableCell align="right">{appointmentCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Zukünftige Nachfassen
                  </TableCell>
                  <TableCell align="right">{futureCompanies}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Vergangene Nachfassen
                  </TableCell>
                  <TableCell align="right">{pastCompanies}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    No Status
                  </TableCell>
                  <TableCell align="right">{noStatus}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Appointments;
