import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../GlobalLoader";
import EditIcon from "@material-ui/icons/Edit";
import { V3 } from "../../../lib/api";

function New({ data, handleChange }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = (formData) => {
    V3.put(`/job-profiles/${data._id}`, formData).then((res) => {
      handleChange(res.data.data);
      setOpen(false);
    });
  };

  return (
    <>
      <span onClick={() => setOpen(true)}>
        <EditIcon />
      </span>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Chat Image</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            description: Yup.string(),
            title: Yup.string(),
          })}
          onSubmit={handleSubmit}
          initialValues={{
            link: data.link || "",
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="link"
                  label="Link"
                  name="link"
                  style={{ margin: 0, marginTop: 15 }}
                />

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    close
                  </Button>
                  <Button variant="outlined" color="primary" type="submit">
                    submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default New;
