import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { V3 } from "../../lib/api";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "../GlobalLoader";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { UPDATE_JOB } from "../../redux/types";
import { useDispatch } from "react-redux";

function Publish({ job }) {
  const [published, setPublished] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const confirm = useConfirm();

  const handleUnpublish = (data) => {
    setLoading(true);
    V3.put(`/jobs/${job._id}/publish`, {
      publish: false,
      deactivateReason: data?.deactivateReason?.value,
    }).then((res) => {
      setLoading(false);
      setPublished(false);
      setOpenDialog(false);
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
    });
  };
  const handlePublish = () => {
    confirm({
      title: `Publish Job: ${job.title}`,
      description: `Are you sure you wont to publish this job.${
        job?.deactivated
          ? ` When this job is published the job count in the clients package will decrease.`
          : ""
      }`,
    }).then(() => {
      setLoading(true);
      V3.put(`/jobs/${job._id}/publish`, { publish: true }).then((res) => {
        setLoading(false);
        setPublished(true);
        dispatch({
          type: UPDATE_JOB,
          payload: job._id,
          data: res.data.data,
        });
      });
    });
  };

  const handelClick = () => {
    if (published) {
      setOpenDialog(true);
    } else {
      handlePublish();
    }
  };

  useEffect(() => {
    if (job) setPublished(job?.published);
  }, [job]);

  const handleSubmit = (values, formApi) => {
    handleUnpublish(values);
  };

  return (
    <>
      <Button
        color={published ? "secondary" : "primary"}
        variant="contained"
        fullWidth
        onClick={() => handelClick()}
      >
        {loading ? <CircularProgress /> : published ? "Unpublish" : "Publish"}
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Unpublish Job: {job.title}</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            deactivateReason: Yup.object().nullable().required("Required"),
          })}
          initialValues={{ deactivateReason: null }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {loading && <Loader />}
              <DialogContent>
                <Field
                  component={Autocomplete}
                  fullWidth
                  name="deactivateReason"
                  options={[
                    {
                      key: "Stelle nicht mehr online",
                      label: "Stelle nicht mehr online",
                      value: "Stelle nicht mehr online",
                    },
                    {
                      key: "Kundenwunsch (Stelle besetzt)",
                      label: "Kundenwunsch (Stelle besetzt)",
                      value: "Kundenwunsch (Stelle besetzt)",
                    },
                    {
                      key: "Kundenwunsch",
                      label: "Kundenwunsch",
                      value: "Kundenwunsch",
                    },
                    {
                      key: "Info Berater",
                      label: "Info Berater",
                      value: "Info Berater",
                    },
                    {
                      key: "Prüfung Innendienst",
                      label: "Prüfung Innendienst",
                      value: "Prüfung Innendienst",
                    },
                  ]}
                  textFieldProps={{
                    label: "Deaktivieren weil",
                    variant: "outlined",
                    style: { margin: 0 },
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="primary">
                  Close
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  unpublish
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Publish;
