import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

export default dayjs;

export const convertSecToHHmm = (sec = 0) => {
  const hour = ((sec - (sec % 3600)) / 3600).toFixed(0);

  const minutes = ((sec % 3600) / 60).toFixed(0);

  return `H:${hour} M:${minutes}`;
};
