import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const Wrapper = styled.div`
  .MuiInputBase-root {
    flex-wrap: wrap !important;

    .MuiInputBase-input {
      width: auto !important;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export default function TagsInput({ ...props }) {
  const classes = useStyles();
  const { placeholder, tags, ...other } = props;
  const [inputValue, setInputValue] = React.useState("");
  const { selectedItem, setSelectedItem } = props;
  const data = selectedItem.map((el) => {
    return { ...el, id: uuidv4() };
  });

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      const newSelectedItem = [...data];
      const duplicatedValues = newSelectedItem.indexOf(
        (el) => el.name === event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push({
        name: event.target.value.trim(),
        date: new Date(),
        id: uuidv4(),
      });
      setSelectedItem(newSelectedItem);
      setInputValue("");
    }
    if (data.length && !inputValue.length && event.key === "Backspace") {
      setSelectedItem(data.slice(0, data.length - 1));
    }
  }
  function handleChange(item) {
    let newSelectedItem = [...data];
    const index = newSelectedItem.indexOf((el) => el.name === item);

    if (index === -1) {
      newSelectedItem = [
        ...newSelectedItem,
        { name: item, date: new Date(), id: uuidv4() },
      ];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = (item) => {
    const tmp = data.filter((el) => el.id !== item.id);
    setSelectedItem(tmp);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  return (
    <Wrapper>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={data}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          return (
            <div>
              <TextField
                InputProps={{
                  startAdornment: data.map((item, index) => (
                    <Chip
                      key={item.id}
                      tabIndex={index}
                      label={item.name}
                      className={classes.chip}
                      onDelete={() => handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />
            </div>
          );
        }}
      </Downshift>
    </Wrapper>
  );
}
TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};
