import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { useSelector } from "react-redux";
const IntroWrapper = styled(Grid)`
  margin: 0 auto;
  width: 100%;
  min-height: 200px;
  padding-top: 30px;
  margin-bottom: 15px;
  background-color: #195079 !important;
  color: #fff !important;
`;
const ImageWrapper = styled(Grid)`
  background-image: url("/images/intro.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    display: none;
  }
`;

function Intro({ meetings }) {
  const { user } = useSelector((state) => state.auth);

  return (
    <IntroWrapper container spacing={0} component={Paper}>
      <ImageWrapper item xs={12} md={4} />
      <Grid
        item
        xs={12}
        md={8}
        style={{
          paddingLeft: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ margin: 0, fontWeight: 400 }}>
          Hi, <strong>{user.profile.name}</strong>
        </h1>
        <p style={{ maxWidth: "80%" }}>
          Du hast <strong>{meetings.length}</strong> Termine
        </p>
      </Grid>
    </IntroWrapper>
  );
}

export default Intro;
