import React from "react";
import InterviewQuestion from "./InterviewQuestion";
import QuizQuestions from "./quiz";

function Questions({ jobId }) {
  return (
    <div>
      <InterviewQuestion />
      <QuizQuestions jobId={jobId} />
    </div>
  );
}

export default Questions;
