import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "../Table";
import moment from "moment";
import Filters from "../RecruiterDashboard/Filters";
import { V3 } from "../../lib/api";

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(3, "months")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const columns = [
    {
      id: "user",
      Header: "User",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/order/${props.original._id}`}>
          {props.original.user && props.original.user.profile.name}
        </Link>
      ),
    },
    {
      id: "products",
      Header: "Products",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.products.length}</strong>,
    },

    {
      id: "stock",
      Header: "Total Bestand",
      accessor: (d) => d._id,
      Cell: (props) => {
        const total = props.original.products.reduce((a, b) => +a + +b.qty, 0);
        return <strong>{total}</strong>;
      },
    },

    {
      id: "points",
      Header: "Total Klicks",
      accessor: (d) => d._id,
      Cell: (props) => {
        const total = props.original.products.reduce(
          (a, b) => (+a + +b.product ? b.product.points : 0 * b.qty),
          0
        );
        return <strong>{total}</strong>;
      },
    },
    {
      id: "orderId",
      Header: "Order Id",
      accessor: (d) => d._id,
      Cell: (props) => {
        return <strong>{props.original.sid}</strong>;
      },
    },
    {
      id: "createdAt",
      Header: "Datum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Filters setEndDate={setEndDate} setStartDate={setStartDate} />
      </div>
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        minRows={5}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&user=${
            props.userId
          }&${queryString}`;
          setLoading(true);

          V3.get(`/orders${query}`).then((res) => {
            setLoading(false);
            setData(res.data);
            setOrders(res.data.data);
          });
        }}
        noDataText="No orders"
        defaultFiltered={[
          {
            id: "startDate",
            value: startDate.format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: endDate.format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
}

export default Orders;
