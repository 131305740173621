import React from "react";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Delete from "../../components/User/Delete";
import AddCompanyUser from "../../components/Company/AddCompanyUser";
import { useState } from "react";
import { V3 } from "../../lib/api";
const Team = (props) => {
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = (query) => {
    setLoading(true);
    V3.get(`/users${query}`).then((response) => {
      setData(response.data);
      setUsers(response.data.data);
      setLoading(false);
    });
  };

  const handleDeleteUser = (id) => {
    setUsers(users.filter((el) => el._id !== id));
  };

  const handleAdd = (newUser) => {
    setUsers([newUser, ...users]);
  };

  const columns = [
    {
      id: "name",
      Header: "Name",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/users/edit/${props.original._id}`}>
          <strong>
            {" "}
            {props.original.profile ? props.original.profile.name : ""}
          </strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "Email",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "phone",
      Header: "Tel.",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`tel:+${props.original.profile.phone} `}
          >
            {props.original.profile.phone}
          </a>
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Datum",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },

    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Edit this user">
            <Link to={`/users/edit/${props.original._id}`}>
              <FiEdit />
            </Link>
          </Tooltip>
          <Tooltip arrow title="Delete this user">
            <Delete
              userId={props.original._id}
              handleDeleteUser={handleDeleteUser}
            />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <Card
      title="Users"
      style={{ marginBottom: 15 }}
      button={
        <AddCompanyUser
          companyId={props.companyId}
          openDefault={loading === false && users.length === 0}
          handleAdd={handleAdd}
        />
      }
    >
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        filterable={false}
        onFetchData={(state) => {
          let query = `?page=${state.page + 1}&company=${
            props.companyId
          }&limit=5`;
          getUsers(query);
        }}
        noDataText="No users"
        minRows={5}
      />
    </Card>
  );
};

export default Team;
