import S3 from "aws-sdk/clients/s3";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const S3_BUCKET = "twojobs";

const s3 = new S3({
  accessKeyId: "DO00PNCVWTZX26ZPCJW8",
  secretAccessKey: "KbHxPKawEABW5ap4KcWiLkzSv4krSjAzw0dpKP32xLA",
  endpoint: "https://fra1.digitaloceanspaces.com",
});

export const upload = async (file) => {
  let { type } = file;

  const fileType = type.split("/")[1].replace("/", "");

  const imageFolder = `job-video/${moment(new Date()).format(
    "YYYY-MM"
  )}/${uuidv4()}.${fileType}`;

  const params = {
    Bucket: S3_BUCKET,
    Key: imageFolder,
    Body: file,
    ContentType: type,
    ACL: "public-read",
  };

  try {
    const data = await new Promise((resolve, reject) => {
      s3.putObject(params).send((err, data) => {
        if (err) {
          reject(err);
        }
        resolve(`https://twojobs.fra1.digitaloceanspaces.com/${imageFolder}`);
      });
    });

    return data;
  } catch (error) {
    console.log("error", error);
  }
};
export const uploadFile = async (folder, file) => {
  let { type, name } = file;

  const splittedName = name.split(".");

  const fileType = splittedName[splittedName.length - 1];

  const imageFolder = `${folder}/${moment(new Date()).format(
    "YYYY-MM"
  )}/${uuidv4()}.${fileType}`;

  const params = {
    Bucket: S3_BUCKET,
    Key: imageFolder,
    Body: file,
    ContentType: type,
    ACL: "public-read",
  };

  try {
    const data = await new Promise((resolve, reject) => {
      s3.putObject(params).send((err, data) => {
        if (err) {
          reject(err);
        }
        resolve(`https://twojobs.fra1.digitaloceanspaces.com/${imageFolder}`);
      });
    });

    return data;
  } catch (error) {}
};
