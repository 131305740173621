import React from "react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export default function index({ value, onChange, height, ...props }) {
  return (
    <SunEditor
      setContents={value}
      onChange={(newContent) => onChange(newContent)}
      setDefaultStyle="font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol'; font-size: 18px;"
      setOptions={{
        height: height ? height : 400,
        buttonList: buttonList.complex,
        font: [
          "Arial",
          "Montserrat",
          "Comic Sans",
          "Courier",
          "Georgia",
          "Impact",
          "Segoe UI",
          "Tahoma",
          "Times New Roman",
          "Trebuchet MS",
        ],
      }}
      {...props}
    />
  );
}
