import Table from "../../../Table";
import React, { useEffect, useState } from "react";
import { V3 } from "../../../../lib/api";
import Filters from "../filters";

import TwoBlueIcon from "../../../RecruiterDashboard/Icons/two-blue";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Table as MuiTable,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Alert, Autocomplete } from "@material-ui/lab";
import Editor from "../../../Editor";
import Loader from "../../../GlobalLoader";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Moment from "react-moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InfoIcon from "@material-ui/icons/Info";
import { Link } from "react-router-dom";

const getBody = (product) => {
  return `<p>Lieber Mitarbeiter<br></p><div><br></div><div>Herzliche Gratulation zu deinen gesammelten TWO$! 🥳 </div><div><br></div><div>Als Dankeschön für deine herausragende Leistung &amp; deinen Einsatz, möchten wir dich darüber informieren, dass du nun ein cooles Produkt mit deinen gesammelten TWO$ einkaufen kannst.</div><div><br></div><p>Klicke einfach auf den folgenden Link, um deinen Gutschein einzulösen &amp; dir dein Wunschprodukt zu sichern:<a href="${process.env.NEXT_PUBLIC_WEB}/shop/${product?.sId}"> Zum Produkt</a></p><div><br></div><div>Wir hoffen, dass dir die Auswahl gefällt!</div><div><br></div><div>Vielen Dank für deinen Einsatz &amp; weiterhin viel Erfolg beim TWO$ sammeln 🚀 </div><div><br></div><div>Beste Grüsse</div><div>Two Jobs Team</div>`;
};
function NotifyRecruiter({ data, companyId }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const [products, setProducts] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      V3.get(`products/my/${companyId}`, {
        params: {
          limit: 999,
          shop: true,
          ltePrice: data?.recruiter?.companyPoints,
        },
      }).then((res) => {
        setProducts(res.data.data);
      });
    }
  }, [open, data, companyId]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <NotificationsNoneIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            message:
              "Hey, herzlichen Glückwunsch! Mit deinen gesammelten TWO$ kannst du dir dieses coole Produkt gönnen! 🎉",
            product: null,
            body: "",
            sendEmail: false,
          }}
          validationSchema={Yup.object().shape({
            message: Yup.string().required("Required"),
            product: Yup.object().required("Required"),
          })}
          onSubmit={async (values, actions) => {
            setMessage(null);
            try {
              await V3.post(`recruiters/notify/product`, {
                ...values,
                user: data?.recruiter?.user._id,
                product: values?.product?._id,
              });

              actions.setSubmitting(false);
              setOpen(false);
              enqueueSnackbar(
                "Die Benachrichtigung wurde erfolgreich zugestellt!",
                {
                  variant: "success",
                }
              );
            } catch (error) {
              setMessage(
                error?.response?.data?.message || "Something went wrong"
              );
              actions.setSubmitting(false);
              enqueueSnackbar("Something went wrong", {
                variant: "error",
              });
            }
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>Benachrichtigung senden</DialogTitle>
              <DialogContent>
                {formik.isSubmitting && <Loader />}
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <TextField
                  label={"Message"}
                  variant="outlined"
                  fullWidth
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && !!formik.errors.message
                      ? true
                      : false
                  }
                  helperText={formik.touched.message && formik.errors.message}
                  style={{ marginBottom: 20, marginTop: 5 }}
                />
                <Autocomplete
                  options={products}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Produkt erstellen"}
                      error={
                        formik.touched.product && !!formik.errors.product
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.product && formik.errors.product
                      }
                    />
                  )}
                  getOptionLabel={(option) => option?.title || "-"}
                  value={formik?.values?.product}
                  onChange={(e, value) => {
                    formik.setFieldValue("product", value);
                    formik.setFieldValue("body", getBody(value));
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <FormHelperText>
                    Recruiter per E-Mail informieren
                  </FormHelperText>
                  <Switch
                    checked={formik.values.sendEmail}
                    onChange={() =>
                      formik.setFieldValue(
                        "sendEmail",
                        !formik.values.sendEmail
                      )
                    }
                  />
                </div>
                {formik?.values?.sendEmail && !formik?.values?.product && (
                  <FormHelperText style={{ color: "#D92D20" }}>
                    Wählen Sie zuerst einen Product aus
                  </FormHelperText>
                )}
                {formik?.values?.sendEmail && formik?.values?.product && (
                  <Editor
                    value={formik?.values.body}
                    onChange={(e) => {
                      // eslint-disable-next-line no-unused-expressions
                      formik?.setFieldValue("body", e);
                    }}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  Senden
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

function EmailHistory({ data }) {
  const [open, setOpen] = useState(false);

  if (!data?.length) {
    return <>-</>;
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <AccessTimeIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Verlauf</DialogTitle>
        <DialogContent>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>Nachricht</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Produkt</TableCell>
                <TableCell>Erstellt am</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((el) => (
                <TableRow key={el._id}>
                  <TableCell>{el.message}</TableCell>
                  <TableCell>
                    {el.sendEmail ? (
                      <Tooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: el.body,
                            }}
                          ></div>
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/company/dashboard/products/${el.product._id}/edit`}
                    >
                      {el.product.title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Moment>{el.createdAt}</Moment>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
function TopSellers({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(
    moment("01/01/2019", "DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  const getData = (query) => {
    setLoading(true);
    V3.get(`shared-links/company/info/${companyId}${query}`).then(
      (response) => {
        setData(response.data);
        setUsers(response.data.data);
        setLoading(false);
      }
    );
  };

  const columns = [
    {
      id: "rank",
      Header: "Rank",
      accessor: (d) => d._id,
      Cell: (props) => {
        return (
          <strong>
            #{(data?.pagination?.current - 1 || 0) * 20 + props.index + 1}
          </strong>
        );
      },
    },
    {
      id: "user",
      Header: "User",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original?.recruiter?.user?.profile?.name || "-"}
        </span>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original?.recruiter?.user?.email || "-"}
        </span>
      ),
    },
    {
      id: "twoBLue",
      Header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>Total gesammelte</span>{" "}
          <TwoBlueIcon />
        </div>
      ),
      accessor: (d) => d._id,
      Cell: (props) => <>{props.original?.amount}</>,
    },
    {
      id: "BLue",
      Header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>Aktuelle</span> <TwoBlueIcon />
        </div>
      ),
      accessor: (d) => d._id,
      Cell: (props) => <>{props.original?.recruiter?.companyPoints}</>,
    },
    {
      id: "BLue",
      Header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>Verlauf</span>
        </div>
      ),
      accessor: (d) => d._id,
      Cell: (props) => (
        <EmailHistory
          data={props.original?.recruiter?.user?.companyNotifications}
        />
      ),
    },
    {
      id: "actions",
      Header: "Aktionen",
      accessor: (d) => d._id,
      Cell: (props) => (
        <NotifyRecruiter data={props.original} companyId={companyId} />
      ),
    },
  ];

  return (
    <>
      <Filters
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
      />
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${
            state.page + 1
          }&limit=20&withVisits=true&${queryString}`;
          getData(query);
        }}
        filterable={false}
        noDataText="Keine Empfehlungen"
        minRows={5}
        defaultFiltered={[
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
    </>
  );
}

export default TopSellers;
