import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Card from "./Card";
import Grid from "@material-ui/core/Grid";
import { V3 } from "../../../lib/api";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";

const Search = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  input {
    min-width: 350px;
    background: transparent;
    outline: none;
    border: none;
    padding-left: 15px;
  }
`;
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ onChange }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [search, setSearch] = React.useState();

  React.useEffect(() => {
    V3.get(`/companies?limit=100${search ? `&search=${search}` : ""}`).then(
      (res) => {
        setCompanies(res.data.data);
      }
    );
  }, [search]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <React.Fragment>
      <Button
        style={{ padding: "12px 32px" }}
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleClickOpen}
      >
        Select Company
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ padding: "0 30px" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Search>
              <input
                onChange={handleChange}
                value={search}
                type="text"
                placeholder="Suchen..."
              />
              <IconButton color="primary">
                <SearchIcon />
              </IconButton>
            </Search>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 30 }}>
          <Grid container spacing={2}>
            {companies.map((company) => (
              <Grid item xs={12} md={4} lg={2}>
                <Card
                  data={company}
                  onChange={onChange}
                  handleClose={handleClose}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
