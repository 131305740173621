import * as React from "react";

const Linkedin = (props) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.88 0H1.835A1.476 1.476 0 0 0 .357 1.476v17.048A1.476 1.476 0 0 0 1.834 20H18.88a1.476 1.476 0 0 0 1.476-1.476V1.476A1.476 1.476 0 0 0 18.881 0ZM6.319 17.038H3.311V7.486h3.007v9.552ZM4.813 6.162a1.726 1.726 0 1 1 1.736-1.724 1.701 1.701 0 0 1-1.736 1.724Zm12.589 10.884h-3.006v-5.218c0-1.54-.654-2.014-1.499-2.014-.891 0-1.766.672-1.766 2.053v5.179H8.124V7.493h2.891v1.324h.04c.29-.588 1.306-1.592 2.858-1.592 1.678 0 3.49.996 3.49 3.912l-.001 5.909Z"
      fill="#98A2B3"
    />
  </svg>
);

export default Linkedin;
