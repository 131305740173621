import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalLoader from "../../../components/GlobalLoader";
import { Paper, Tab, Tabs } from "@material-ui/core";
import BasicInfo from "../basicInfo";
import ActiveSourcing from "../activeSourcing";
import JobVisits from "../visits";
import ExternalClicks from "../clicks";
import ApplyData from "../applyData";
import { Alert } from "@material-ui/lab";
import JobActivities from "../JobActivities";
import UserInJob from "../../../components/Job/UserInJob";

import Questions from "../questions";
import AllInOne from "../allInOne";
import { V3 } from "../../../lib/api";
import { GET_JOB, JOBS_LOADING } from "../../../redux/types";

const checkToAdd = (job) => {
  let add = 0;

  if (job?.services?.ad) {
    add = add + 1;
  }
  if (job?.services?.allInOne) {
    add = add + 1;
  }

  return add;
};

function EditJob(props) {
  const { job, loading } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const usersInJob = useMemo(() => <UserInJob />, []);

  useEffect(() => {
    dispatch({
      type: JOBS_LOADING,
    });

    V3.get(`/jobs/crm/${props.match.params.id}?applications=true`).then(
      (res) => {
        dispatch({
          type: GET_JOB,
          payload: res.data,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !job) {
    return <GlobalLoader />;
  }

  return (
    <React.Fragment>
      <Paper
        style={{
          position: "sticky",
          top: 80,
          zIndex: 999,
          width: "fit-content",
        }}
      >
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newV) => {
            setTab(newV);
          }}
          aria-label="disabled tabs example"
        >
          <Tab label="Job" />
          <Tab label="Bewerbungen" />
          {job?.services?.ad && <Tab label="Active Sourcing" />}
          {job?.services?.allInOne && <Tab label="All in One" />}
          <Tab label="History" />
          <Tab label="Questions" />
          {user?.email === "kristian.oroshi@two.jobs" ||
          user?.email === "admin@test.com" ? (
            <Tab label="Visits" />
          ) : null}
          {user?.email === "kristian.oroshi@two.jobs" ||
          user?.email === "admin@test.com" ? (
            <Tab label="Clicks" />
          ) : null}
        </Tabs>
      </Paper>
      <Alert severity="error" style={{ marginTop: 15 }}>
        Please make sure all your changes are saved!
      </Alert>
      {usersInJob}

      <div style={tab === 0 ? { marginTop: 15 } : { display: "none" }}>
        <BasicInfo />
      </div>
      <div style={tab === 1 ? { marginTop: 15 } : { display: "none" }}>
        <ApplyData />
      </div>
      {job?.services?.ad && (
        <div style={tab === 2 ? { marginTop: 15 } : { display: "none" }}>
          <ActiveSourcing tab={tab} />
        </div>
      )}
      {job?.services?.allInOne && (
        <div
          style={
            tab === 1 + checkToAdd(job)
              ? { marginTop: 15 }
              : { display: "none" }
          }
        >
          <AllInOne />
        </div>
      )}
      <div
        style={
          tab === 2 + checkToAdd(job) ? { marginTop: 15 } : { display: "none" }
        }
      >
        <JobActivities jobId={job?._id} />
      </div>
      <div
        style={
          tab === 3 + checkToAdd(job) ? { marginTop: 15 } : { display: "none" }
        }
      >
        <Questions jobId={job?._id} />
      </div>

      {user?.email === "kristian.oroshi@two.jobs" ||
      user?.email === "admin@test.com" ? (
        <>
          <div
            style={
              tab === 4 + checkToAdd(job)
                ? { marginTop: 15 }
                : { display: "none" }
            }
          >
            <JobVisits />
          </div>
          <div
            style={
              tab === 5 + checkToAdd(job)
                ? { marginTop: 15 }
                : { display: "none" }
            }
          >
            <ExternalClicks />
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
}

export default EditJob;
