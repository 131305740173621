import React from "react";
import Authorize from "../../../../utils/Authorize";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import * as Yup from "yup";
import Loader from "../../../GlobalLoader";
import Editor from "../../../Editor";
import { useSnackbar } from "notistack";
import { V3 } from "../../../../lib/api";
import { useSelector } from "react-redux";

export default function StatusReminderEmail({ id }) {
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  if (
    user?.email !== "admin@test.com" &&
    user?.email !== "kristian.oroshi@two.jobs"
  ) {
    return null;
  }

  const _handleSubmit = (values, form) => {
    try {
      V3.put(`crm/statuses/${id}/remind`, values);
      form.setSubmitting(false);
      setOpen(false);
      enqueueSnackbar("Reminder has been sended", {
        variant: "success",
      });
    } catch (error) {
      form.setSubmitting(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Authorize roles={["admin"]}>
        <Tooltip title="Notify" style={{ marginRight: 15 }}>
          <IconButton onClick={() => setOpen(true)}>
            <NotificationsActiveIcon />
          </IconButton>
        </Tooltip>
      </Authorize>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Formik
          validationSchema={Yup.object().shape({
            subject: Yup.string().required("Required"),
            message: Yup.string().required("Required"),
            email: Yup.string().nullable(),
          })}
          onSubmit={_handleSubmit}
          initialValues={{
            subject: "Fällige Rechnung two.jobs",
            email: "",
            message: `<p>Sehr geehrter Herr <br></p><div>&nbsp;</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Seit einigen Wochen geniessen Sie die Vorteile der verschiedenen two.jobs Dienstleistungen und es freut uns, Sie zu unseren Kunden zählen zu dürfen. </div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">&nbsp;</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Die beiliegende Rechnung ist bei Ihnen vermutlich untergegangen. </div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">&nbsp;</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Dürfen wir Sie bitten diese in den nächsten Tagen zu begleichen.</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m"><br></div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Bei Fragen steht Ihnen Ihr persönlicher Kundenberater gerne zur Verfügung.</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m"><br></div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Einen schönen Tag und freundliche Grüsse</div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m"><br></div><div class="RichText3-paragraph--withVSpacingNormal RichText3-paragraph Typography Typography--m">Das two.jobs Team</div>`,
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogTitle>Invoice Reminder</DialogTitle>
              {isSubmitting && <Loader></Loader>}
              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  style={{ marginTop: 0, marginBottom: 15 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="External Email"
                  name="email"
                  style={{ marginTop: 0, marginBottom: 15 }}
                />
                <Editor
                  value={values.message}
                  onChange={(val) => setFieldValue("message", val)}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(false)}
                >
                  close
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
