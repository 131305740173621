import React from "react";
import Grid from "@material-ui/core/Grid";
import classNames from "clsx";
import Lens from "@material-ui/icons/Lens";
import Event from "@material-ui/icons/Event";
import AccessTime from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/core/styles";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

function getUrl(index) {
  switch (index.type) {
    case 1:
      return `/crm/company/${index.companyId}`;
    case 3:
      return `/tasks`;
    case 2:
      return `/crm/company/${index.companyId}`;
    case 4:
      return `/crm/company/${index.id}`;
    default:
      return "No Status";
  }
}

const useTooltipContentStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: "border-box",
    width: "400px",
  },
  contentContainer: {
    paddingBottom: theme.spacing(1.5),
  },
  text: {
    ...theme.typography.body2,
    display: "inline-block",
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  icon: {
    verticalAlign: "middle",
  },
  contentItemIcon: {
    textAlign: "center",
  },
  grayIcon: {
    color: theme.palette.action.active,
  },
  colorfulContent: {
    color: ({ color }) => color[300],
  },
  lens: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    verticalAlign: "super",
  },
  textCenter: {
    textAlign: "center",
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
}));

function Tooltip({ appointmentData, formatDate, appointmentResources }) {
  const resource = appointmentResources[0];
  const classes = useTooltipContentStyles({ color: resource.color });
  let icon = <PhoneEnabledIcon className={classes.icon} />;
  if (appointmentData.type === 2) {
    icon = <Event className={classes.icon} />;
  }
  if (appointmentData.type === 3) {
    icon = <ListAltIcon className={classes.icon} />;
  }
  if (appointmentData.type === 4) {
    icon = <PhoneEnabledIcon className={classes.icon} />;
  }
  return (
    <div className={classes.content}>
      <Grid
        container
        alignItems="flex-start"
        className={classes.titleContainer}
      >
        <Grid item xs={2} className={classNames(classes.textCenter)}>
          <Lens className={classNames(classes.lens, classes.colorfulContent)} />
        </Grid>
        <Grid item xs={10}>
          <div>
            <div className={classNames(classes.title, classes.dateAndTitle)}>
              {appointmentData.title}
            </div>
            <div className={classNames(classes.text, classes.dateAndTitle)}>
              {formatDate(appointmentData.startDate, {
                day: "numeric",
                weekday: "long",
              })}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          <AccessTime className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.text}>
            {`${formatDate(appointmentData.startDate, {
              hour: "numeric",
              minute: "numeric",
            })}
                - ${formatDate(appointmentData.endDate, {
                  hour: "numeric",
                  minute: "numeric",
                })}`}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        key={`${resource.fieldName}_${resource.id}`}
      >
        <Grid
          className={classNames(
            classes.contentItemIcon,
            classes.icon,
            classes.colorfulContent
          )}
          item
          xs={2}
        >
          {icon}
        </Grid>
        <Grid item xs={10}>
          <span className={classNames(classes.text, classes.colorfulContent)}>
            {resource.text}
          </span>
        </Grid>
      </Grid>
      {appointmentData.type !== 5 && (
        <Button
          component={Link}
          to={getUrl(appointmentData)}
          color="primary"
          variant="outlined"
          style={{ marginLeft: 60, marginTop: 15 }}
        >
          More info
        </Button>
      )}
    </div>
  );
}

export default Tooltip;
