import React from "react";
import moment from "moment";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import usePrice from "../../hooks/usePrice";
const TooltipWrapper = styled(Paper)`
  padding: 15px;
  background-color: rgba(66, 135, 245, 0.2);
`;

export default function Tooltip({ active, payload, label, user }) {
  const amount = usePrice(
    active && payload && payload[0] && payload[0].payload.amount
  );
  if (active && payload && label) {
    return (
      <TooltipWrapper>
        <p>
          <strong>Unternehmen: </strong>
          {payload && payload[0] && payload[0].payload.company
            ? payload[0].payload.company.name
            : "Company deleted"}
        </p>
        {user && (
          <>
            {payload && payload[0] && payload[0].payload.user ? (
              <p>
                <strong>Verkauft durch: </strong>
                {payload[0].payload.user.profile.name}
              </p>
            ) : (
              "User deleted"
            )}
          </>
        )}

        <p>
          <strong>Betrag: </strong>
          {amount}
          <strong> CHF</strong>
        </p>
        <p>
          <strong>Date: </strong>
          {payload &&
            payload[0] &&
            moment(payload[0].payload.date).format("D.MM.YYYY")}
        </p>
      </TooltipWrapper>
    );
  }

  return null;
}
