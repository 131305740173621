import React from "react";

class Impressions extends React.Component {
  render() {
    return (
      <h1
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          margin: 0,
          fontSize: "40px",
        }}
      >
        {Math.round(this.props.data)}
      </h1>
    );
  }
}

export default Impressions;
