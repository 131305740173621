import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { priorities } from "./tasks";
import { Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
const styles = (theme) => ({
  flexibleSpace: {
    margin: "0 auto 0 0",
  },
  prioritySelector: {
    marginLeft: theme.spacing(2),
    minWidth: 140,
    "@media (max-width: 500px)": {
      minWidth: 0,
      fontSize: "0.75rem",
      marginLeft: theme.spacing(0.5),
    },
  },
  addButton: {
    position: "absolute",
    bottom: theme.spacing(1) * 3,
    right: theme.spacing(1) * 4,
  },
  select: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
    minWidth: 200,
    paddingLeft: 5,
  },
});

const usePrioritySelectorItemStyles = makeStyles(({ palette, spacing }) => ({
  bullet: ({ color }) => ({
    backgroundColor: color ? color[400] : palette.divider,
    borderRadius: "50%",
    width: spacing(2),
    height: spacing(2),
    marginRight: spacing(2),
    display: "inline-block",
  }),
  prioritySelectorItem: {
    display: "flex",
    alignItems: "center",
  },
  priorityText: {
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
  priorityShortText: {
    "@media (min-width: 500px)": {
      display: "none",
    },
  },
}));

const PrioritySelectorItem = ({ color, text: resourceTitle }) => {
  const text = resourceTitle || "All";
  const shortText = resourceTitle ? text.substring(0, 1) : "All";
  const classes = usePrioritySelectorItemStyles({ color });

  return (
    <div className={classes.prioritySelectorItem}>
      <span className={classes.bullet} />
      <span className={classes.priorityText}>{text}</span>
      <span className={classes.priorityShortText}>{shortText}</span>
    </div>
  );
};

const PrioritySelector = withStyles(styles, { name: "PrioritySelector" })(
  ({ classes, priorityChange, priority }) => {
    const currentPriority = priority > 0 ? priorities[priority - 1] : {};
    return (
      <FormControl className={classes.prioritySelector}>
        <Select
          className={classes.select}
          disableUnderline
          value={priority}
          onChange={(e) => {
            priorityChange(e.target.value);
          }}
          renderValue={() => (
            <PrioritySelectorItem
              text={currentPriority.text}
              color={currentPriority.color}
            />
          )}>
          <MenuItem value={0}>
            <PrioritySelectorItem />
          </MenuItem>
          {priorities.map(({ id, color, text }) => (
            <MenuItem value={id} key={id.toString()}>
              <PrioritySelectorItem color={color} text={text} id={id} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

function FlexibleSpace({ classes, priority, priorityChange, ...restProps }) {
  return (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      <PrioritySelector priority={priority} priorityChange={priorityChange} />
    </Toolbar.FlexibleSpace>
  );
}

export default withStyles(styles, { name: "FlexibleSpace" })(FlexibleSpace);
