import React, { useState } from "react";
import Card from "../../Card";
import { Button } from "@material-ui/core";
import { V3 } from "../../../lib/api";

import Table from "../../Table";
import Actions from "../../Table/Actions";
import New from "./New";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import Moment from "react-moment";
import { UPDATE_JOB } from "../../../redux/types";
import EditActiveSourcingOnboarding from "./Edit";

function ChatImages({ job }) {
  const [open, setOpen] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const handleSubmit = (values, formApi) => {
    V3.post(`jobs/${job._id}/active-sourcing-onboarding`, {
      ...values,
      users: values.users.map((el) => el.key),
    }).then((res) => {
      setOpen(false);
      dispatch({
        type: UPDATE_JOB,
        payload: job._id,
        data: res.data.data,
      });
      formApi.setSubmitting(false);
    });
  };

  const handleDelete = (info) => {
    confirm({
      title: `Delete Onboarding`,
      description: "Are you sure You wont to delete this onborading",
    }).then(() => {
      V3.delete(`jobs/${job._id}/active-sourcing-onboarding/${info._id}`).then(
        (res) => {
          dispatch({
            type: UPDATE_JOB,
            payload: job._id,
            data: res.data.data,
          });
        }
      );
    });
  };

  const columns = [
    {
      id: "users",
      Header: "Users",
      accessor: (d) => d.users,
      Cell: (props) => (
        <strong>
          {props.original?.users?.length
            ? props.original.users
                .map((el) => el?.profile?.name || "-")
                .join(", ")
            : props.original?.name
            ? props.original?.name
            : "-"}
        </strong>
      ),
    },
    {
      id: "date",
      Header: "Date",
      accessor: (d) => d.date,
      Cell: (props) => <Moment>{props.original?.date}</Moment>,
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d.email,
      Cell: (props) => (
        <span style={{ textTransform: "none" }}>
          {props.original.users?.length
            ? props.original?.users.map((el) => el?.email || "-").join(", ")
            : props.original?.email
            ? props.original?.email
            : "-"}
        </span>
      ),
    },
    {
      id: "phone",
      Header: "Tel.",
      accessor: (d) => d.phone,
      Cell: (props) => (
        <strong>
          {props.original?.users?.length
            ? props.original?.users
                .map((el) => el?.profile?.phone || "-")
                .join(", ")
            : props.original?.phone
            ? props.original?.phone
            : "-"}
        </strong>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <EditActiveSourcingOnboarding data={props.original} />
          <span onClick={() => handleDelete(props.original)}>
            <DeleteIcon />
          </span>
        </Actions>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Active Sourcing Onboarding"
        button={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      >
        <div>
          <Table
            data={job?.activeSourcingOnBoardings || []}
            columns={columns}
            loading={false}
            page={1}
            pages={1}
            onFetchData={() => {}}
            filterable={false}
            minRows={1}
            noDataText="No Data"
          />
        </div>
      </Card>
      <New open={open} setOpen={setOpen} handleSubmit={handleSubmit} />
    </>
  );
}

export default ChatImages;
