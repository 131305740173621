/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import Header from "../../components/Page/Header";
import Moment from "react-moment";
import InfoIcon from "@material-ui/icons/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import styled, { useTheme } from "styled-components";
import { V3 } from "../../lib/api";
import { COMPANIES_LOADING, GET_COMPANIES } from "../../redux/types";

const ToContactWrapper = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: #fff;
  background-color: ${(props) => (props.flag ? "green" : "#e60404")};
`;

function Companies() {
  const { companies, data, loading } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.auth);
  const [filterState, setFilterState] = useState({
    filtered: [],
    page: 0,
  });

  const [note, setNote] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  const theme = useTheme();

  const dispatch = useDispatch();

  let companyNameAndBlackListNoteSearch = "";
  if (filterState?.filtered?.length > 0) {
    // eslint-disable-next-line array-callback-return
    filterState.filtered.map((el) => {
      if (el.id === "companyNameAndBlackListNote") {
        companyNameAndBlackListNoteSearch = el.value;
      }
    });
  }

  const htmlToText = (html) => {
    var temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  };

  useEffect(() => {
    V3.get(`/teams?limit=400`).then((response) => {
      const usersData = response.data.data.map((user) => {
        return {
          key: user._id,
          value: user.name,
          label: user.name,
        };
      });

      setTeamMembers(usersData);
    });
  }, []);

  useEffect(() => {
    var queryString = filterState?.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filterState?.page + 1}&consultant=${
      user._id
    }&populate=parentCompanyData&${queryString}`;
    getCompaniesStats(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const getCompaniesStats = (query) => {
    dispatch({
      type: COMPANIES_LOADING,
    });

    V3.get(`/companies/stats${query}`).then((res) => {
      dispatch({
        type: GET_COMPANIES,
        payload: res.data,
      });
    });
  };

  const columns = [
    {
      id: "companyNameAndBlackListNote",
      Header: "Unternehmen",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/companies/analytics/${props.original._id}`}>
          <strong className="highlight">{props.original.name}</strong>
        </Link>
      ),
    },
    {
      id: "endDate",
      Header: "Abo Ablauf",
      filterable: true,
      accessor: (d) => d.category,
      Cell: (props) =>
        props.original?.latestSubscriptionInvoice?.invoiceId ? (
          <Moment>
            {props.original.latestSubscriptionInvoice.invoiceId.endDate}
          </Moment>
        ) : (
          <span> - </span>
        ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            onChange(event.target.value);
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="Newest">Newest-Oldest</option>
          <option value="Oldest">Oldest-Newest</option>
        </select>
      ),
    },
    {
      id: "endDate",
      Header: "Angehen ab",
      filterable: false,
      accessor: (d) => d.category,
      Cell: (props) =>
        props.original.checkIfToContact ? (
          <ToContactWrapper flag>Ja</ToContactWrapper>
        ) : (
          <ToContactWrapper> Nein </ToContactWrapper>
        ),
    },
    {
      id: "team",
      Header: "Berater",
      filterable: true,
      accessor: (d) => d.category,
      Cell: (props) => props.original?.consultant?.name || "-",
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          {teamMembers.map((user) => (
            <option key={user.key} value={user.key}>
              {user?.value || "-"}
            </option>
          ))}
        </select>
      ),
    },
    {
      id: "jobs",
      Header: "Konto Anschriften",
      accessor: (d) => d.category,
      filterable: true,
      Cell: (props) => (
        <span>
          {props.original?.daughterCompany
            ? props.original?.parentCompanyData?.activeSourcing
            : props.original.activeSourcing || "-"}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            onChange(event.target.value);
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="More">More-Less</option>
          <option value="Less">Less-More</option>
        </select>
      ),
    },
    {
      id: "availableJobs",
      Header: "Verfügbare Stellen",
      accessor: (d) => d.category,
      Cell: (props) => (
        <span>
          {props.original.latestSubscriptionInvoice?.invoiceId?.availableJobs ||
            "-"}
        </span>
      ),
    },
    {
      id: "subscriptionNotes",
      Header: "Notizen zum Abo",
      accessor: (d) => d.category,
      filterable: false,
      Cell: (props) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.original?.crmCompany?.subscriptionNotes ? (
              <InfoIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNote(props.original?.crmCompany?.subscriptionNotes);
                  setOpenDialog(true);
                }}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      id: "blackListNotes",
      Header: "Black List Note",
      filterable: false,
      accessor: (d) => d.blackListNotes,
      Cell: (props) => {
        let note = props.original.blackListNotes || "-";
        note = note.replace(/<[^>]+>/g, "");
        note = htmlToText(note, {
          wordwrap: 130,
        });
        // (?<=>)(.*?)ag(.*?)(?<=<)
        const reg = new RegExp(
          companyNameAndBlackListNoteSearch.replace(" ", `\\s*`).trim(),
          "ig"
        );

        if (companyNameAndBlackListNoteSearch.trim().length > 0) {
          note = note.replaceAll(
            reg,
            `<span style="background-color: ${theme.palette.orange.main};border-radius:5px;padding: 2px;padding-left: 4px;padding-right: 4px;color: ${theme.palette.orange.contrastText};">${companyNameAndBlackListNoteSearch}</span>`
          );
        }

        return (
          <div
            style={{ width: "100%", wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: `<p style="
    word-break: break-word;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height:130px;
    overflow-y:scroll;
    display: block;">${note}</p>`,
            }}
          ></div>
        );
      },
    },
  ];

  let result = [...companies];

  filterState.filtered.map((el) => {
    if (el.id === "endDate" && el.value === "Newest") {
      const nulls = [];
      const notNulls = [];
      companies.map((company) => {
        if (company?.crmCompany?.statusId?.invoiceId?.endDate) {
          notNulls.push(company);
        } else {
          nulls.push(company);
        }
        result = [...notNulls, ...nulls];
      });
    }
  });

  return (
    <React.Fragment>
      <Header title="Unternehmen Statistik" size={data.total} />
      <Table
        data={result}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setFilterState(state);
        }}
        showPaginationBottom={true}
        noDataText="No companies"
      />
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogTitle>Notizen zum Abo</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: note }}></div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Companies;
