import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { V3 } from "../../../lib/api/index";
import Card from "../../Card";
import Table from "../../Table";

function JobInfos({ candidateId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [jobCandidates, setJobCandidates] = useState([]);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (filters && candidateId) {
      let queryString = filters.filtered
        .map((key) => key.id + "=" + key.value)
        .join("&");
      let query = `?page=${
        filters.page + 1
      }&cvCandidate=${candidateId}&${queryString}`;
      getJobCandidates(query);
    }
  }, [filters, candidateId]);

  const getJobCandidates = (query) => {
    setLoading(true);
    V3.get(`/job-candidates${query}`).then((response) => {
      setData(response.data);
      setJobCandidates(response.data.data);
      setLoading(false);
    });
  };
  const columns = [
    {
      id: "company",
      Header: "Company",
      accessor: (d) => d.company.name,
      Cell: (props) => <strong>{props.original.company?.name}</strong>,
    },
    {
      id: "job",
      Header: "Job",
      accessor: (d) => d.job?.title,
      Cell: (props) => <strong>{props.original.job?.title}</strong>,
    },
    {
      id: "amount",
      Header: "Amount",
      accessor: (d) => d.amount,
      Cell: (props) => <strong>{props.original.amount}</strong>,
    },
    {
      id: "candidates",
      Header: "Candidates",
      accessor: (d) => d.candidates,
      Cell: (props) => {
        let candidates = "";
        if (Array.isArray(props.original.cvCandidate)) {
          // eslint-disable-next-line array-callback-return
          props.original.cvCandidate.map((el) => {
            candidates += ` ${el.name},`;
          });
        }

        // eslint-disable-next-line array-callback-return
        props.original.customCandidates.map((el) => {
          candidates += ` ${el},`;
        });

        return <strong>{candidates}</strong>;
      },
    },
    {
      id: "createdAt",
      Header: "Created",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
  ];
  return (
    <Card title="Kandidate Pool" style={{ marginTop: 15 }}>
      <Table
        data={jobCandidates}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => setFilters(state)}
        filterable={false}
        noDataText="Keine Kandidate"
        minRows={5}
      />
    </Card>
  );
}

export default JobInfos;
