import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { useSnackbar } from "notistack";
import { ADD_CONTACT_PERSON } from "../../../redux/types";
function DeleteContactPerson({ contactPerson, oldContact, companyId }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    const data = {
      contactPerson: contactPerson,
      name: oldContact,
    };
    setLoading(true);

    V3.put(`/crm/companies/remove-contact/${companyId}`, data)
      .then((response) => {
        enqueueSnackbar("Contact person removed successfully", {
          variant: "success",
        });
        dispatch({
          type: ADD_CONTACT_PERSON,
          payload: response.data.data,
        });
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Could not removed contact person", {
          variant: "error",
        });
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading && <Loader />}
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kontaktperson löschen</DialogTitle>

        <DialogContent>
          Kontaktperson wirklich löschen? Eine Wiederherstellung ist nicht mehr
          möglich!
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button
            onClick={handleConfirmation}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeleteContactPerson;
