/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import { Link } from "react-router-dom";
import Header from "../../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit } from "react-icons/fi";
import { V3 } from "../../lib/api";
import Button from "@material-ui/core/Button";
import Delete from "../../components/Crm/Companies/Delete";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import Moment from "react-moment";
import styled from "styled-components";
import InfoIcon from "@material-ui/icons/Info";

import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import UnArchive from "../../components/Crm/Companies/UnArchive";
import Loader from "../../components/GlobalLoader";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getMyCrmCompaniesCols } from "../../utils/data";
import HideColum from "../../components/Crm/Companies/HideColum";
import {
  COMPANIES_LOADING,
  GET_COMPANIES_CRM,
  UPDATE_CRM_COMPANY,
} from "../../redux/types";
const TableWrapper = styled.div`
  .rt-td:nth-child(5),
  .rt-th:nth-child(5) {
    width: 180px !important;
  }
  .rt-td:nth-child(1),
  .rt-th:nth-child(1) {
    width: 240px !important;
  }
`;
const ReactDatesWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  > span {
    position: absolute;
    left: 10px;
    top: -10px;
    z-index: 100;
  }

  .DateInput .DateInput_input {
    padding-bottom: 0px !important;
    padding-top: 25px !important;
    font-size: 16px;
    text-align: left !important;
    padding-left: 12px !important;
  }
`;

const Status = styled.div`
  width: 100%;
  display: block;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  background: #ccc;

  ${(props) =>
    props.status === 1 &&
    `
    background: #ccc;
  `};
  ${(props) =>
    props.status === 2 &&
    `
    background: #ff5a5d;
  `};
  ${(props) =>
    props.status === 3 &&
    `
    background: #534c98;
  `};
  ${(props) =>
    props.status === 4 &&
    `
    background: #f87829;
  `};
  ${(props) =>
    props.status === 5 &&
    `
    background: #0080ff;
  `};
  ${(props) =>
    props.status === 6 &&
    `
    background: #41d7ba;
  `};
  ${(props) =>
    props.status === 7 &&
    `
    background: #ccc;
  `};
  ${(props) =>
    props.status === 8 &&
    `
    background: #ff5f5f;
  `};
`;
function getStatusName(id) {
  switch (id) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf";
    case "3":
      return "Ersttermin";
    case "4":
      return "Nachfassen am";
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "In Verhandlung";
    case "8":
      return "Nicht Verlängert";
    default:
      return "No Status";
  }
}

const checkStatius3 = (filters) => {
  let flag = false;
  const tmp = [];
  tmp.map((el) => {
    if (el?.value === "3") {
      flag = true;
    }
  });

  if (filters?.filtered?.length) {
    // eslint-disable-next-line no-unused-expressions
    filters?.filtered?.map((el) => {
      if (el?.value === "3") {
        flag = true;
      }
    });
  }

  return flag;
};

const UpdateCallWrapper = styled.div`
  padding: 5px;

  ${(props) =>
    props.isBefore
      ? `
  background-color:${props.theme.palette.primary.main};
  height: fit-content;
  border-radius:50px;
  color:#fff;
  `
      : ""}
`;

function Companies(props) {
  const date = moment("01.01.2019");
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [startDateApp, setStartDateApp] = useState(date);
  const [endDateApp, setEndDateApp] = useState(moment(new Date()));
  const [filters, setFilters] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasFilters, setHasFilters] = useState(false);
  const [gettingFilters, setGettingFilters] = useState(true);
  const [isInitial, setIsInitial] = useState(true);
  const [loadingExport, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");

  const [openExport, setOpenExport] = useState(false);

  const [openFilters, setOpenFilters] = useState(false);

  const { user: authUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const crmCompaniesTableCols = authUser?.crmCompanyColumns;

  const updateMaCompany = (id, value) => {
    dispatch({
      type: COMPANIES_LOADING,
    });
    V3.put(`/crm/companies/${id}/ma`, { ma: value }).then((res) => {
      dispatch({
        type: UPDATE_CRM_COMPANY,
        payload: res.data.data,
      });
    });
  };

  const getCompanies = (query) => {
    dispatch({
      type: COMPANIES_LOADING,
    });
    V3.get(`/crm/companies${query}`).then((res) => {
      dispatch({
        type: GET_COMPANIES_CRM,
        payload: res.data,
      });
    });
  };

  const handleExport = (state) => {
    setLoading(true);
    setFilters(state);
    setDefaultFilters(state.filtered);
    sessionStorage.setItem("companiesfilters", JSON.stringify(state.filtered));
    setIsInitial(false);
    if (gettingFilters === false && isInitial === true) {
      if (hasFilters === true) {
        var queryString = defaultFilters
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&archived=false&${queryString}`;
        sendExportRequest(query);
      } else {
        var queryString2 = state.filtered
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&archived=false&${queryString2}`;
        sendExportRequest(query);
      }
    }
    if (!isInitial) {
      var queryString3 = state.filtered
        .map((key) => key.id + "=" + encodeURIComponent(key.value))
        .join("&");
      let query = `?page=${state.page + 1}&archived=false&${queryString3}`;
      sendExportRequest(query);
    }
  };
  const handleExportRejects = (state) => {
    setFilters(state);
    setDefaultFilters(state.filtered);
    sessionStorage.setItem("companiesfilters", JSON.stringify(state.filtered));
    setIsInitial(false);
    if (gettingFilters === false && isInitial === true) {
      if (hasFilters === true) {
        var queryString = defaultFilters
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${
          state.page + 1
        }&countUnArchived=true&${queryString}`;
        sendExportRejectsRequest(query);
      } else {
        var queryString2 = state.filtered
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${
          state.page + 1
        }&countUnArchived=true&${queryString2}`;
        sendExportRejectsRequest(query);
      }
    }
    if (!isInitial) {
      var queryString3 = state.filtered
        .map((key) => key.id + "=" + encodeURIComponent(key.value))
        .join("&");
      let query = `?page=${
        state.page + 1
      }&countUnArchived=true&${queryString3}`;
      sendExportRejectsRequest(query);
    }
  };

  const exportHandler = () => {
    handleExport(filters);
  };

  const exportContactPersonsHandler = () => {
    V3.get(`/exports/contact-persons`)
      .then((res) => {
        setLoading(false);
        window.open(res.data.data.url);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const sendExportRejectsRequest = (query) => {
    V3.get(`/exports/rejects${query}`)
      .then((res) => {
        setLoading(false);
        window.open(res.data.data.url);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const sendExportRequest = (query) => {
    V3.get(`/exports${query}`)
      .then((res) => {
        setLoading(false);
        window.open(res.data.data.url);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleFetch = (state) => {
    setFilters(state);
    setDefaultFilters(state.filtered);
    sessionStorage.setItem("companiesfilters", JSON.stringify(state.filtered));
    setIsInitial(false);
    if (gettingFilters === false && isInitial === true) {
      if (hasFilters === true) {
        var queryString = defaultFilters
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&archived=true&${queryString}`;
        getCompanies(query);
      } else {
        var queryString2 = state.filtered
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&archived=true&${queryString2}`;
        getCompanies(query);
      }
    }
    if (!isInitial) {
      var queryString3 = state.filtered
        .map((key) => key.id + "=" + encodeURIComponent(key.value))
        .join("&");
      let query = `?page=${state.page + 1}&archived=true&${queryString3}`;
      getCompanies(query);
    }
  };

  useEffect(() => {
    function findIndexByProperty(data, key, value) {
      for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
          return i;
        }
      }
      return -1;
    }

    var filterdata = sessionStorage.getItem("companiesfilters");
    if (filterdata) {
      const filterData = JSON.parse("[" + filterdata + "]");
      let newFilters = filters;
      newFilters = filterData[0];
      setDefaultFilters(newFilters);
      setHasFilters(true);
      setGettingFilters(false);

      const startIndex = findIndexByProperty(newFilters, "id", "terminStart");
      const endIndex = findIndexByProperty(newFilters, "id", "terminEnd");

      if (startIndex > -1) {
        setStartDate(moment(newFilters[startIndex].value));
      }
      if (endIndex > -1) {
        setEndDate(moment(newFilters[endIndex].value));
      }
    } else {
      setHasFilters(false);
      setGettingFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilters = () => {
    let newFilters = filters;
    newFilters.filtered = [];
    setFilters(newFilters);
    handleFetch(newFilters);

    setStartDate(date);
    setEndDate(moment(new Date()));
  };
  const refreshData = () => {
    handleFetch(filters);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    var start = moment(startDate);
    var end = moment(endDate);
    let newFilters = filters;
    const terminStart = { id: "terminStart", value: start };
    const terminEnd = { id: "terminEnd", value: end };

    function findIndexByProperty(data, key, value) {
      for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
          return i;
        }
      }
      return -1;
    }

    var johnIndex = findIndexByProperty(
      newFilters.filtered,
      "id",
      "terminStart"
    );
    if (johnIndex > -1) {
      newFilters.filtered[johnIndex] = terminStart;
    } else {
      newFilters.filtered.push(terminStart);
    }

    var endIndex = findIndexByProperty(newFilters.filtered, "id", "terminEnd");
    if (endIndex > -1) {
      newFilters.filtered[endIndex] = terminEnd;
    } else {
      newFilters.filtered.push(terminEnd);
    }

    if (start.isValid() && end.isValid()) {
      setFilters(newFilters);
      handleFetch(newFilters);
    }
  };
  const handleDatesChangeApp = ({ startDate, endDate }) => {
    setStartDateApp(startDate);
    setEndDateApp(endDate);

    var start = moment(startDate);
    var end = moment(endDate);
    let newFilters = filters;
    const appStart = { id: "appStart", value: start };
    const appEnd = { id: "appEnd", value: end };

    function findIndexByProperty(data, key, value) {
      for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
          return i;
        }
      }
      return -1;
    }

    var johnIndex = findIndexByProperty(newFilters.filtered, "id", "appStart");
    if (johnIndex > -1) {
      newFilters.filtered[johnIndex] = appStart;
    } else {
      newFilters.filtered.push(appStart);
    }

    var endIndex = findIndexByProperty(newFilters.filtered, "id", "appEnd");
    if (endIndex > -1) {
      newFilters.filtered[endIndex] = appEnd;
    } else {
      newFilters.filtered.push(appEnd);
    }

    if (start.isValid() && end.isValid()) {
      setFilters(newFilters);
      handleFetch(newFilters);
    }
  };

  useEffect(() => {
    V3.get(`/users?roles=admin,employee&limit=100`).then((res) => {
      setUsers(res.data.data);
    });
  }, []);
  const { crmcompanies, data, loading } = useSelector((state) => state.company);
  const columns = [
    {
      id: "name",
      Header: " Unternehmen",
      accessor: "name",
      Cell: (props) => (
        <Link
          to={`/crm/company/${props.original._id}`}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <strong>{props.original.name}</strong>
        </Link>
      ),
    },

    {
      id: "consultant",
      Header: "Berater",
      accessor: (d) => d._id,
      Cell: (props) => {
        return (
          <span>
            {(props.original &&
              props.original.consultant &&
              props.original.consultant.profile.name) ||
              "-"}
          </span>
        );
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.profile.name || "-"}
            </option>
          ))}
        </select>
      ),
    },

    {
      id: "caller",
      Header: "Erfasser",
      accessor: "caller",
      Cell: (props) => (
        <div>
          <span>
            {props.original.caller
              ? props.original.caller.profile.name
              : "Nicht zugeordnet"}
          </span>
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="null">Nicht zugeordnet</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.profile.name || "-"}
            </option>
          ))}
        </select>
      ),
    },

    {
      id: "documentSent",
      Header: "Doc. gesendet",
      accessor: "documentSent",
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
      Cell: (props) => (
        <span>
          {props.original.documentSent === true && <span>Ja</span>}
          {props.original.documentSent === false && <span>Nein</span>}
        </span>
      ),
    },

    {
      id: "status",
      Header: "Status",
      accessor: "status",
      width: 200,
      Cell: (props) => (
        <Status status={props.original.statusType}>
          {getStatusName(props.original.statusType)}
          {props.original?.statusId?.noSale ? " - Kein Verkauf" : ""}
        </Status>
      ),

      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="1">No Status</option>
          <option value="2">Kein Verkauf</option>
          <option value="3">Ersttermin</option>
          <option value="4">Nachfassen am</option>
          <option value="4-no-sale">Nachfassen am - Kein Verkauf </option>
          <option value="4-not-extended">
            Nachfassen am - Nicht Verlängert{" "}
          </option>
          <option value="5">Offerte</option>
          <option value="6">Erfolgreich abgeschlossen</option>
          <option value="7">In Verhandlung</option>
          <option value="8">Nicht Verlängert</option>
        </select>
      ),
    },
    {
      id: checkStatius3(filters) ? "erstterminDate" : "called",
      Header: checkStatius3(filters) ? "Ersttermin" : "Update Call",
      accessor: "called",
      width: 200,
      Cell: (props) => {
        if (checkStatius3(filters)) {
          return <Moment>{props.original?.statusDate}</Moment>;
        }
        if (props.original.nextUpdateCall) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UpdateCallWrapper
                isBefore={moment(props.original.nextUpdateCall).isBefore(
                  new Date()
                )}
              >
                <Moment>{props.original.nextUpdateCall}</Moment>
              </UpdateCallWrapper>

              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        props.original?.updateCalls[
                          props.original?.updateCalls.length - 1
                        ]?.notes,
                    }}
                  ></div>
                }
                // style={{ marginLeft: 10 }}
              >
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setNote(
                      props.original?.updateCalls[
                        props.original?.updateCalls.length - 1
                      ]
                    );
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else {
          return "-";
        }
      },
      Filter: ({ filter, onChange }) => {
        if (checkStatius3(filters)) {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : ""}
            >
              <option value="">Alles zeigen</option>
              <option value="past">Past</option>
              <option value="future">Future</option>
            </select>
          );
        } else {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : ""}
            >
              <option value="">Alles zeigen</option>
              <option value="Old-New">Old-New</option>
              <option value="New-Old">New-Old</option>
              <option value="No-Date">No Date</option>
            </select>
          );
        }
      },
    },
    {
      id: "ma",
      Header: "MA",
      accessor: "ma",
      filterable: true,
      Cell: (props) => (
        <Checkbox
          checked={props.original?.ma}
          onChange={(e) =>
            updateMaCompany(props.original._id, e.target.checked)
          }
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="true">Ja</option>
          <option value="false">Nein</option>
        </select>
      ),
    },

    {
      id: "openPositions",
      Header: "Offene Jobs",
      accessor: "openPositions",
      filterable: true,
      Cell: (props) => <span>{props.value}</span>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-1">Mehr-Weniger</option>
          <option value="1">Weniger-Mehr</option>
        </select>
      ),
    },

    {
      id: "cDate",
      Header: "Tel. am",
      accessor: "conversationDate",
      filterable: true,
      Cell: (props) => (
        <span>
          {props.original.conversationDate ? (
            <Moment>{props.original.conversationDate}</Moment>
          ) : (
            "-"
          )}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-1">Neu-Alt</option>
          <option value="1">Alt-Neu</option>
          <option value="null">Keine Daten</option>
        </select>
      ),
    },
    {
      id: "aDate",
      Header: "Nachfassen am",
      accessor: "appointmentDate",
      filterable: true,
      Cell: (props) => (
        <span>
          {props.original.statusType == 4 && props.original.appointmentDate ? (
            <Moment>{props.original.appointmentDate}</Moment>
          ) : (
            "-"
          )}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="-1">Neu-Alt</option>
          <option value="1">Alt-Neu</option>
          <option value="null">Keine Daten</option>
        </select>
      ),
    },

    {
      id: "updatedAt",
      Header: "Datum",
      accessor: "updatedAt",
      filterable: false,
      Cell: (props) => <Moment>{props.original.updatedAt}</Moment>,
    },
    {
      id: "meeting",
      Header: "Termin",
      accessor: "meeting",
      Cell: (props) => (
        <span>
          {props.original?.metingStatus == 1 && <>Ja</>}
          {props.original?.metingStatus == 2 && <>Nein</>}
          {props.original?.metingStatus == 0 && <>Offen</>}
        </span>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Alles zeigen</option>
          <option value="1">Ja</option>
          <option value="2">Nein</option>
          <option value="0">Offen</option>
        </select>
      ),
    },

    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      filterable: false,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Archive">
            <UnArchive companyId={props.original._id} />
          </Tooltip>
          <Tooltip arrow title="Bearbeiten">
            <Link to={`/crm/companies/edit/${props.original._id}`}>
              <FiEdit></FiEdit>
            </Link>
          </Tooltip>
          <Tooltip arrow title="Löschen">
            <Delete companyId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      {loadingExport && <Loader />}
      <Header title="Archivieren" size={data.total}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <HideColum />
            <Tooltip title="Filters">
              <IconButton
                style={{
                  marginRight: 15,
                }}
                onClick={() => setOpenFilters(true)}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Exports">
              <IconButton
                style={{
                  marginRight: 15,
                }}
                onClick={() => setOpenExport(true)}
              >
                <ImportExportIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Datum">
              <IconButton
                style={{
                  marginRight: 15,
                }}
                onClick={refreshData}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter löschen" onClick={clearFilters}>
              <IconButton
                style={{
                  backgroundColor:
                    defaultFilters.length > 0 ? "#85ca00" : "transparent",
                }}
              >
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div>
            {" "}
            <Button
              color="inherit"
              variant="outlined"
              component={Link}
              to="/crm/companies"
              style={{ marginRight: 15, marginLeft: 15, padding: "13px 32px" }}
            >
              Zurück
            </Button>
            <Button
              component={Link}
              to="/crm/companies/new"
              color="primary"
              style={{ padding: "13px 32px" }}
              variant="contained"
            >
              Neu
            </Button>
          </div>
        </div>
      </Header>
      <TableWrapper>
        {!gettingFilters && (
          <Table
            data={crmcompanies}
            columns={getMyCrmCompaniesCols(columns, crmCompaniesTableCols)}
            loading={loading}
            page={!data.pagination ? 0 : data.pagination.current - 1}
            pages={!data.pagination ? 1 : data.pagination.total}
            filterable={true}
            onFetchData={handleFetch}
            defaultFiltered={defaultFilters}
            noDataText="No Companies"
            sortable={false}
          />
        )}
      </TableWrapper>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Update Call Note</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: note?.notes }}></div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openExport}
        onClose={() => setOpenExport(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Export Options</DialogTitle>
        <DialogContent>
          {(authUser.email == "admin@test.com" ||
            authUser.email == "kristian.oroshi@two.jobs" ||
            authUser.email == "sandro.trovato@two.jobs") && (
            <Button
              color="primary"
              style={{ padding: "13px 32px", marginBottom: "15px" }}
              variant="contained"
              onClick={exportContactPersonsHandler}
              fullWidth
            >
              Contacts
            </Button>
          )}

          <Button
            color="primary"
            style={{ padding: "13px 32px", marginBottom: "15px" }}
            variant="contained"
            fullWidth
            onClick={() => handleExportRejects(filters)}
          >
            Rejects
          </Button>
          <Button
            color="primary"
            style={{ padding: "13px 32px", marginBottom: "15px" }}
            variant="contained"
            fullWidth
            onClick={exportHandler}
          >
            Export
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenExport(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Date Filter</DialogTitle>
        <DialogContent>
          <ReactDatesWrapper>
            <span>Tel. am</span>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={(date) =>
                    handleDatesChange({ startDate: date, endDate })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={(date) =>
                    handleDatesChange({ startDate, endDate: date })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </ReactDatesWrapper>

          <ReactDatesWrapper>
            <span>Nachfassen am</span>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="dd/MM/yyyy"
                  value={startDateApp}
                  onChange={(date) =>
                    handleDatesChangeApp({
                      startDate: date,
                      endDate: endDateApp,
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="dd/MM/yyyy"
                  value={endDateApp}
                  onChange={(date) =>
                    handleDatesChangeApp({
                      startDate: startDateApp,
                      endDate: date,
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </ReactDatesWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilters(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Companies;
