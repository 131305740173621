/* eslint-disable array-callback-return */
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import { V3 } from "../lib/api";
import Moment from "react-moment";
import PageHeader from "../components/Page/Header";
import styled from "styled-components";
import moment from "moment";
import { Button } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";

const ReactDatesWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  > span {
    position: absolute;
    left: 10px;
    top: 5px;
    z-index: 100;
  }

  .DateInput .DateInput_input {
    padding-bottom: 0px !important;
    padding-top: 25px !important;
    font-size: 16px;
    text-align: left !important;
    padding-left: 12px !important;
  }
`;

function JobCandidates() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [jobCandidates, setJobCandidates] = useState([]);
  const [startDateApp, setStartDateApp] = useState(
    moment(new Date()).subtract(1, "week")
  );
  const [endDateApp, setEndDateApp] = useState(moment(new Date()));
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (filters) {
      let queryString = filters.filtered
        .map((key) => key.id + "=" + key.value)
        .join("&");
      let query = `?page=${filters.page + 1}&start=${moment(
        startDateApp
      ).toISOString()}&end=${moment(endDateApp).toISOString()}&${queryString}`;
      getJobCandidates(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, startDateApp, endDateApp]);

  const getJobCandidates = (query) => {
    setLoading(true);
    V3.get(`/job-candidates${query}`).then((response) => {
      setData(response.data);
      setJobCandidates(response.data.data);
      setLoading(false);
    });
  };
  const handleExport = () => {
    setLoading(true);
    let queryString = filters.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${filters.page + 1}&start=${moment(
      startDateApp
    ).toISOString()}&end=${moment(endDateApp).toISOString()}&${queryString}`;
    V3.get(`/job-candidates/export${query}`).then((response) => {
      window.open(response.data.data.url, "_blank");
      setLoading(false);
    });
  };
  const columns = [
    {
      id: "company",
      Header: "Company",
      accessor: (d) => d.company.name,
      Cell: (props) => <strong>{props.original.company?.name}</strong>,
    },
    {
      id: "job",
      Header: "Job",
      accessor: (d) => d.job?.title,
      Cell: (props) => <strong>{props.original.job?.title}</strong>,
    },
    {
      id: "amount",
      Header: "Amount",
      accessor: (d) => d.amount,
      Cell: (props) => <strong>{props.original.amount}</strong>,
    },
    {
      id: "candidates",
      Header: "Candidates",
      accessor: (d) => d.candidates,
      Cell: (props) => {
        let candidates = "";
        if (Array.isArray(props.original.cvCandidate)) {
          props.original.cvCandidate.map((el) => {
            candidates += ` ${el.name},`;
          });
        }
        if (Array.isArray(props.original.customCandidates)) {
          props.original.customCandidates.map((el) => {
            candidates += ` ${el},`;
          });
        }

        return <strong>{candidates}</strong>;
      },
    },
    {
      id: "createdAt",
      Header: "Created",
      accessor: (d) => d.createdAt,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
  ];

  return (
    <>
      <PageHeader title="Kandidaten" size={data?.total}>
        <Button
          style={{ padding: "13px 32px", marginRight: 15 }}
          variant="contained"
          color="primary"
          onClick={handleExport}
        >
          Export
        </Button>
        <ReactDatesWrapper>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={startDateApp}
                onChange={(date) => setStartDateApp(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={endDateApp}
                onChange={(date) => setEndDateApp(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </ReactDatesWrapper>
      </PageHeader>
      <Table
        data={jobCandidates}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => setFilters(state)}
        filterable={false}
        noDataText="Keine Kandidate"
      />
    </>
  );
}

export default JobCandidates;
