import React, { useEffect, useState } from "react";

import Documents from "./Documents";
import { V3 } from "../../../lib/api";
import styled from "styled-components";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Table from "../../Table";
import FeedBack, { newCandidateFeedbacks } from "../Candidate/FeedBack";
import CandidateFeedBackHistory from "../Candidate/CandidateFeedBackHistory";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import { useSnackbar } from "notistack";
import moment from "moment";
import Filters from "./filters";
import Moment from "react-moment";
import { Form, Formik } from "formik";
import GlobalLoader from "../../GlobalLoader";
import ForwardIcon from "../../Icons/ForwardIcon";
import InfoIcon from "@material-ui/icons/Info";
const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 12px 12px;
  .title {
    margin-top: 0;
    margin-bottom: 15px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  .last-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 590px) {
      flex-direction: column;
      align-content: flex-start !important;
    }
    h4 {
      font-size: 14px;
      padding: 11px 24px;
      color: #fff;
      color: ${(props) => (props.published ? "#344054" : "#fff")};
      background-color: ${(props) => (props.published ? "#fff" : "#195079")};
      border-radius: 8px;
      border: 1px solid ${(props) => (props.published ? "#D0D5DD" : "#195079")};
      font-weight: 500;
    }
  }
`;

const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 5px;
  width: max-content;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

const Wrapper = styled.div`
  padding: 16px;
  .button {
    padding: 12px 32px;
  }

  svg {
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 1024px) {
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 300px;
  }
`;

const ForwardedWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
function Checkurl(text) {
  var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
    url2 = /(^|&lt;|\s)(((https?|http?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

  var html = text.trim();
  if (html) {
    html = html
      .replace(
        url1,
        '$1<a style="color:blue; text-decoration:underline;" target="_blank"  href="http://$2">$2</a>$3'
      )
      .replace(
        url2,
        '$1<a style="color:blue; text-decoration:underline;" target="_blank"  href="$2">$2</a>$5'
      );
  }
  return html;
}

function AssignedCandidates({ companyId }) {
  const [archived, setArchived] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [startDate, setStartDate] = useState(moment().subtract(3, "months"));
  const [endDate, setEndDate] = useState(moment());

  const [modalOpen, setModalOpen] = useState(false);
  const [candidate, setCandidate] = useState(null);

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    V3.get(`jobs/my/${companyId}`, {
      params: { published: !archived, limit: 999, select: "title" },
    }).then((res) => {
      setJobs(
        res.data.data.map((el) => {
          return { value: el._id, key: el._id, label: el.title };
        })
      );
    });
  }, [archived, companyId]);

  const getInvoices = (query) => {
    setLoading(true);
    V3.get(`/new-job-candidates/my/${companyId}${query}`).then((response) => {
      setData(response.data);
      setCandidates(response.data.data);
      setLoading(false);
    });
  };

  const updateItem = (item) => {
    setCandidates(
      candidates.map((el) => {
        if (el._id === item._id) {
          return item;
        } else {
          return el;
        }
      })
    );
  };

  const handleArchive = (can) => {
    setLoading(true);
    V3.put(`new-job-candidates/${can._id}`, {
      archived: !can.jobPublished,
    }).then((res) => {
      setCandidates(candidates.filter((el) => el._id !== can._id));
      setLoading(false);
      enqueueSnackbar(
        res.data.data?.jobPublished
          ? "Kandidat erfolgreich dearchiviert"
          : "Kandidat erfolgreich archiviert",
        {
          variant: "success",
        }
      );
    });
  };

  const columns = [
    {
      id: "candidate",
      Header: "Kandidat",
      accessor: (d) => d.firstname,
      Cell: (props) => (
        <strong>
          {props.original.firstname} {props.original.lastname}
        </strong>
      ),
      filterable: true,
    },
    {
      id: "job",
      Header: "Stelle",
      accessor: (d) => d.job,
      Cell: (props) => <>{props.original.job?.title}</>,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value={null}>Alle</option>
          {jobs.map((el) => (
            <option value={el.value}>{el.label}</option>
          ))}
        </select>
      ),
    },
    {
      id: "feedback",
      Header: "Status",
      accessor: (d) => d.job,
      Cell: (props) => (
        <FeedBack data={props.original} updateItem={updateItem} />
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          {newCandidateFeedbacks.map((el) => (
            <option value={el.value}>{el.label}</option>
          ))}
        </select>
      ),
    },
    {
      id: "history",
      Header: "Verlauf",
      accessor: (d) => d.job,
      Cell: (props) => <CandidateFeedBackHistory candidate={props.original} />,
      filterable: false,
    },
    {
      id: "allInOne",
      Header: "All In One",
      accessor: (d) => d.job,
      Cell: (props) =>
        props.original?.allInOne ? (
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: props.original?.allInOne?.message,
                }}
              ></div>
            }
          >
            <InfoIcon />
          </Tooltip>
        ) : (
          "-"
        ),
      filterable: false,
    },
    {
      id: "createdAt",
      Header: "Erstellt am",
      accessor: (d) => d.job,
      Cell: (props) => <Moment>{props.original?.createdAt}</Moment>,
      filterable: false,
    },

    {
      id: "fiels",
      Header: "Dokumente",
      accessor: (d) => d.total,
      Cell: (props) => {
        const forwarded = props.original?.forwardedTo?.length > 0;
        return (
          <div className="actions">
            <Tooltip title={"Weiterleiten"}>
              <Button
                onClick={() => {
                  setModalOpen(true);
                  setCandidate(props.original);
                }}
                size="small"
                className="icon-button"
                variant={forwarded ? "contained" : "outlined"}
                color="primary"
                style={{
                  width: "fit-content",
                  minWidth: 0,
                  padding: 6,
                  color: "#fff",
                  border: 0,
                  marginRight: 8,
                }}
              >
                <ForwardIcon stroke={forwarded ? "#fff" : null} />
              </Button>
            </Tooltip>
            <Tooltip title={data.archived ? "Dearchivieren" : "Archivieren"}>
              <IconButton
                aria-label="expand row"
                size="small"
                className="icon-button"
                variant="outlined"
                onClick={() => handleArchive(props.original)}
              >
                {props.original.jobPublished ? (
                  <ArchiveOutlinedIcon />
                ) : (
                  <UnarchiveOutlinedIcon />
                )}
              </IconButton>
            </Tooltip>
            <Documents files={props.original.files} />
          </div>
        );
      },
      filterable: false,
    },
  ];

  return (
    <>
      {" "}
      <HeadWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setArchived(true)}
            variant="contained"
            className={`custom-btn ${archived ? "active" : "not-Active"}`}
            color="primary"
          >
            Archiviert
          </Button>
          <Button
            onClick={() => setArchived(false)}
            variant="contained"
            className={`custom-btn ${!archived ? "active" : "not-Active"}`}
            color="primary"
          >
            Nicht archiviert
          </Button>
        </ButtonsWrapper>
        <Filters
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          endDate={endDate}
        />
      </HeadWrapper>
      <Table
        data={candidates}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => {
              return key.id + "=" + key?.value;
            })
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          getInvoices(query);
        }}
        filterable={true}
        noDataText="Keine Produkte"
        minRows={5}
        SubComponent={(row) => {
          if (!row.original?.description) {
            return null;
          } else
            return (
              <DescriptionWrapper>
                <h4 className="title">Bemerkungen</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>${Checkurl(row.original?.description)}</p>`,
                  }}
                ></div>
              </DescriptionWrapper>
            );
        }}
        defaultFiltered={[
          {
            id: "published",
            value: !archived,
          },
          {
            id: "startDate",
            value: moment(startDate).format("DD/MM/YYYY"),
          },
          {
            id: "endDate",
            value: moment(endDate).format("DD/MM/YYYY"),
          },
        ]}
      />
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <Wrapper>
          <DialogTitle onClose={handleClose} style={{ padding: 0 }}>
            Kandidat weiterleiten
          </DialogTitle>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={async (values) => {
              try {
                const res = await V3.post(
                  "/new-job-candidates/forward/documents",
                  {
                    email: values.email,
                    candidate: candidate._id,
                  }
                );
                updateItem(res.data.data);
                handleClose();
                enqueueSnackbar(
                  "Der Kandidat wurde erfolgreich weitergeleitet",
                  {
                    variant: "success",
                  }
                );
              } catch (err) {
                console.log(err);
                enqueueSnackbar("Etwas ist schief gelaufen", {
                  variant: "error",
                });
              }
            }}
          >
            {(formik) => (
              <Form>
                {formik.isSubmitting && <GlobalLoader />}

                <DialogContent
                  style={{
                    padding: "16px 0px",
                    marginBottom: 10,
                  }}
                >
                  <TextField
                    name="email"
                    value={formik.values.email}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={formik.handleChange}
                  />
                  {candidate?.forwardedTo?.length ? (
                    <>
                      <h3
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          marginTop: 15,
                        }}
                      >
                        Weitergeleitet an
                      </h3>
                      <ForwardedWrapper>
                        {candidate?.forwardedTo?.map((el, index) => (
                          <Chip
                            label={el?.email || "-"}
                            style={{
                              borderRadius: "16px",
                              border: "2px solid #eaecef",
                              backgroundColor: "#F9FAFB",
                            }}
                            key={`${el.email}-${index}`}
                          />
                        ))}
                      </ForwardedWrapper>
                    </>
                  ) : null}
                </DialogContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Button
                      variant="text"
                      className="button"
                      fullWidth
                      size="medium"
                      style={{
                        fontSize: "16px",
                        borderRadius: "8px",
                        height: "44px",
                      }}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button"
                      type="submit"
                      fullWidth
                      size="medium"
                      style={{
                        color: "#fff",
                        fontSize: "16px",
                        borderRadius: "8px",
                        height: "44px",
                      }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Dialog>
    </>
  );
}

export default AssignedCandidates;
