import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { FiArchive } from "react-icons/fi";
import { REMOVE_FROM_LIST_CRM } from "../../../redux/types";
import { V3 } from "../../../lib/api";
import { useSnackbar } from "notistack";
function AlertDialog({ companyId }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    V3.put(`/crm/companies/${companyId}`, { archived: true }).then(
      (response) => {
        enqueueSnackbar("Company archived", {
          variant: "success",
        });
        dispatch({
          type: REMOVE_FROM_LIST_CRM,
          payload: response.data.data,
        });
      }
    );
  };
  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <FiArchive />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Archive company</DialogTitle>
        <DialogContent>
          Are u sure u want to archive this company!
        </DialogContent>

        <DialogActions style={{ padding: "15px 24px" }}>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
          <Button
            onClick={handleConfirmation}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AlertDialog;
