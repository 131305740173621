import * as React from "react";

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    viewBox="0 0 30.69 30.69"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={15.34}
        y1={382.63}
        x2={15.34}
        y2={347.46}
        gradientTransform="translate(0 -350)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#ffd879" />
        <stop offset={0.15} stopColor="#fccb76" />
        <stop offset={0.26} stopColor="#f5aa6c" />
        <stop offset={0.4} stopColor="#e9755e" />
        <stop offset={0.45} stopColor="#e45f58" />
        <stop offset={0.68} stopColor="#d22a9c" />
        <stop offset={1} stopColor="#6968df" />
      </linearGradient>
      <style>{".cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Icons">
          <g id="Icons-2" data-name="Icons">
            <circle
              cx={15.34}
              cy={15.34}
              r={15.34}
              style={{
                fill: "url(#linear-gradient)",
              }}
            />
            <path
              className="cls-2"
              d="M15.34 19.06a3.72 3.72 0 1 1 3.72-3.72 3.72 3.72 0 0 1-3.72 3.72Zm0-6.43a2.72 2.72 0 1 0 2.72 2.71 2.72 2.72 0 0 0-2.72-2.71Z"
            />
            <circle className="cls-2" cx={19.59} cy={11.23} r={0.46} />
            <path
              className="cls-2"
              d="M18.54 22.61h-6.39a4.07 4.07 0 0 1-4.07-4.07v-6.39a4.07 4.07 0 0 1 4.07-4.07h6.39a4.07 4.07 0 0 1 4.07 4.07v6.39a4.08 4.08 0 0 1-4.07 4.07ZM12.15 9.08a3.07 3.07 0 0 0-3.07 3.07v6.39a3.08 3.08 0 0 0 3.07 3.07h6.39a3.08 3.08 0 0 0 3.07-3.07v-6.39a3.07 3.07 0 0 0-3.07-3.07Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVGComponent;
