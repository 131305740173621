import React from "react";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import moment from "moment";
const WrapperLightBox = styled.div`
  .ReactModal__Overlay {
    z-index: 99999 !important;
  }
`;

function JobChats({
  open,
  setOpen,
  photoIndex,
  setPhotoIndex,
  imageGallery,
  imageUrl,
}) {
  if (!open) {
    return null;
  }

  return (
    <WrapperLightBox>
      <Lightbox
        reactModalStyle={{ "z-index": 99999 }}
        mainSrc={imageGallery[photoIndex][imageUrl]}
        imageTitle={`Created On ${moment(
          imageGallery[photoIndex].createdAt
        ).format("DD/MM/YYYY")}`}
        nextSrc={imageGallery[(photoIndex + 1) % imageGallery.length][imageUrl]}
        prevSrc={
          imageGallery[
            (photoIndex + imageGallery.length - 1) % imageGallery.length
          ][imageUrl]
        }
        onCloseRequest={() => setOpen(false)}
        onMovePrevRequest={() =>
          setPhotoIndex(
            (photoIndex + imageGallery.length - 1) % imageGallery.length
          )
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % imageGallery.length)
        }
      />
    </WrapperLightBox>
  );
}

export default JobChats;
