import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import FileUpload from "../../fileUpload";
import { V3 } from "../../../lib/api";
import Loader from "../../GlobalLoader";

function AssigneeCandidate({ jobId, addItem }) {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (selectorFiles) => {
    setFiles(selectorFiles);
  };

  const handleAssignee = async (values) => {
    const data = { ...values, job: jobId };
    if (files.length) {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file);
      });
      try {
        const res = await V3.post(`zip/files`, formData);
        data.files = res.data.data?.map((el) => el.url);
      } catch (error) {}
    }

    try {
      const candidateRes = await V3.post(`new-job-candidates`, data);
      addItem(candidateRes.data.data);
    } catch (error) {}

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Assignee candidate
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Assignee candidate</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string()
              .email("Please add a valid email")
              .required("Required"),
            description: Yup.string(),
          })}
          onSubmit={handleAssignee}
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            description: "",
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent style={{ minWidth: 450 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="firstname"
                  label="Vorname"
                  name="firstname"
                  style={{ marginTop: 0 }}
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="lastname"
                  label="Nachname"
                  name="lastname"
                />
                <Field
                  component={TextField}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="E-Mail"
                  name="email"
                />
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  rows={6}
                  style={{ marginBottom: 30 }}
                />
                <FileUpload handleChange={handleChange} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" disabled={!dirty} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default AssigneeCandidate;
