import Table from "../../../Table";
import React, { useState } from "react";
import { V3 } from "../../../../lib/api";
import { IconButton } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { generateMailToLink } from "../../../../utils/referrals";
import { useSnackbar } from "notistack";
import { WEB } from "../../../../config";

function Referrals(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [referrals, setReferrals] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyNotification = () => {
    enqueueSnackbar("Link zu Ihrer Zwischenablage hinzugefügt", {
      variant: "info",
    });
  };

  const getReferrals = (query) => {
    setLoading(true);
    V3.get(`/company-referrals/my/${props.companyId}${query}`).then(
      (response) => {
        setData(response.data);
        setReferrals(response.data.data);
        setLoading(false);
      }
    );
  };

  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.title}</strong>,
    },
    {
      id: "totalEmployeesRegistered",
      Header: "Registrierte Mitarbeiter",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span>{props.original.totalEmployeesRegistered || "0"}</span>
      ),
    },
    {
      id: "totalShares",
      Header: "Total Geteilt",
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.totalShares || "0"}</span>,
    },
    {
      id: "jobsVisits",
      Header: "Besuche",
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.jobsVisits || "0"}</span>,
    },
    {
      id: "jobsVisits",
      Header: "Besuche",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {" "}
          <IconButton
            style={{ marginLeft: 10 }}
            onClick={() => generateMailToLink(props.original)}
            className="icon-button"
          >
            <EmailIcon />
          </IconButton>
          <CopyToClipboard
            text={`${WEB}/register/recruiter?companyRef=${props.original?.shortId}`}
            onCopy={handleCopyNotification}
          >
            <IconButton className="icon-button">
              <FileCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={referrals}
        columns={columns}
        loading={loading}
        page={!data?.pagination ? 0 : data?.pagination?.current - 1}
        pages={!data?.pagination ? 1 : data?.pagination?.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&withVisits=true&${queryString}`;
          getReferrals(query);
        }}
        filterable={false}
        noDataText="Keine Empfehlungen"
        minRows={5}
      />
    </>
  );
}

export default Referrals;
