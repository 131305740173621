import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Candidate from "../components/Search/Candidate";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PageHeader from "../components/Page/Header";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../components/GlobalLoader";
import { V3 } from "../lib/api";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { IconButton, Tooltip } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  GET_SEARCH,
  SEARCH_LOADING,
  SET_SEARCH,
  SET_SELECTED_CANDIDATES,
  UPDATE_CANDIDATES,
} from "../redux/types";

function Search({ location }) {
  const { selectedCandidates } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const setSelectedCandidates = (data) => {
    dispatch({
      type: SET_SELECTED_CANDIDATES,
      payload: data,
    });
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    dispatch({ type: SEARCH_LOADING });
    V3.get(`/search/candidates${location.search}`).then((res) => {
      dispatch({
        type: SET_SEARCH,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  let tags = [];

  const params = new URLSearchParams(location.search);
  const tagParams = params.get("tags");
  if (tagParams) {
    const tagsArray = tagParams.split(",");
    tags = tagsArray.map((el) => {
      const tmp = el.replace(",", "");
      if (tmp !== "") {
        return tmp;
      } else {
        return null;
      }
    });
  }

  const handleSendEmail = ({ jobLink }, formikApi) => {
    const data = {
      jobLink,
      candidates: selectedCandidates,
    };
    V3.post(`/crm/candidates/email`, data).then((res) => {
      dispatch({ type: UPDATE_CANDIDATES, payload: res.data.data });
      formikApi.setSubmitting(false);
      setSelectedCandidates([]);
      setOpen(false);
    });
  };

  const { searchData, data, loading } = useSelector((s) => s.search);
  const searchMore = () => {
    dispatch({ type: SEARCH_LOADING });
    V3.get(
      `/search/candidates${location.search}&page=${
        data?.pagination?.next?.page || 1
      }`
    ).then((res) => {
      dispatch({
        type: GET_SEARCH,
        payload: res.data,
      });
    });
  };

  return (
    <>
      <PageHeader title={"Kandidaten"} size={`${data?.total}`}>
        <Tooltip
          title="Filter löschen"
          onClick={() => setSelectedCandidates([])}
        >
          <IconButton
            style={{
              backgroundColor:
                selectedCandidates.length > 0 ? "#85ca00" : "transparent",
              marginRight: 15,
            }}
          >
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={selectedCandidates.length === 0}
        >
          Send email
        </Button>
      </PageHeader>

      <InfiniteScroll
        dataLength={searchData?.length - 1}
        next={() => {
          if (!loading) {
            searchMore();
          }
        }}
        style={{ width: "100%", height: "100%", overflow: "unset" }}
        loading={loading}
        hasMore={data?.pagination?.next?.page ? true : false}
        // hasMore={true}
        useWindow={false}
        loader={loading && <h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        endMessage={<h4 style={{ textAlign: "center" }}>No more data</h4>}
      >
        <Grid container spacing={2}>
          {searchData.map((el) => (
            <Grid item xs={12} md={12} lg={12}>
              <Candidate
                data={el}
                tags={tags}
                selectedCandidates={selectedCandidates}
                setSelectedCandidates={setSelectedCandidates}
              />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Formik
          validationSchema={Yup.object().shape({
            jobLink: Yup.string().required("Required"),
          })}
          onSubmit={handleSendEmail}
          initialValues={{ jobLink: "" }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogTitle>Send Email to Candidate</DialogTitle>
              <DialogContent style={{ minWidth: 500 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="jobLink"
                  label="Job Link"
                  name="jobLink"
                  style={{ margin: 0 }}
                />
                <DialogActions>
                  <Button variant="contained" color="primary">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Search;
