import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#026466" rx={20} />
    <path
      fill="#fff"
      d="M14.514 23.613c.324 0 .601-.167.831-.5 2.104-3.178 3.199-4.832 3.284-4.963l-2.095-3.112c-.222-.327-.503-.49-.844-.49h-3.054c-.196 0-.332.054-.41.163-.093.109-.089.243.013.403l2.058 3.036c.008.008.008.01 0 .01l-3.233 4.876c-.085.138-.085.269 0 .392s.217.185.396.185h3.054ZM28.533 11h-3.08c-.332 0-.604.163-.817.49L17.85 21.74l4.332 6.77c.204.326.485.489.843.489h3.054c.188 0 .324-.054.41-.163.084-.124.08-.258-.013-.403l-4.294-6.693v-.01l6.748-10.165c.093-.152.093-.286 0-.403-.077-.109-.21-.163-.397-.163Z"
    />
  </svg>
);
export default SvgComponent;
