import {
  GET_CONTACTINGS,
  ADD_CONTACTING,
  DELETE_CONTACTING,
  CONTACTING_LOADING,
  GET_CONTACTING,
  UPDATE_CONTACTING,
} from "../types";

const initialState = {
  data: [],
  contactings: [],
  contacting: null,
  contacted: 0,
  toContact: 0,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTACTINGS:
      return {
        ...state,
        contactings: action.payload.data,
        data: action.payload,
        contacted: action.payload?.contacted,
        toContact: action.payload?.toContact,
        loading: false,
      };
    case GET_CONTACTING:
      return {
        ...state,
        contacting: action.payload.data,
        loading: false,
      };
    case DELETE_CONTACTING:
      return {
        ...state,
        contactings: state.contactings.filter(
          (el) => el._id !== action.payload.data._id
        ),
        contacted: action.payload?.contacted,
        toContact: action.payload?.toContact,
        loading: false,
      };
    case ADD_CONTACTING:
      return {
        ...state,
        contactings: [action.payload.data, ...state.contactings],
        contacted: action.payload?.contacted,
        toContact: action.payload?.toContact,
        loading: false,
      };
    case CONTACTING_LOADING:
      return {
        ...state,
        contacting: null,
        loading: true,
      };
    case UPDATE_CONTACTING:
      return {
        ...state,
        contactings: state.contactings.map((contacting) => {
          if (contacting._id === action.payload?.data?._id) {
            return action.payload.data;
          } else {
            return contacting;
          }
        }),
        loading: false,
        contacted: action.payload?.contacted,
        toContact: action.payload?.toContact,
      };
    default:
      return state;
  }
}
