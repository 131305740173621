import * as React from "react";
const facebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <circle cx={20} cy={20} r={17.5} fill="#0163E0" />
    <path
      fill="#fff"
      d="m26.517 25.352.777-4.94h-4.863V17.21c0-1.352.679-2.67 2.857-2.67H27.5v-4.205S25.493 10 23.575 10c-4.006 0-6.623 2.366-6.623 6.648v3.765H12.5v4.939h4.452v11.941c.894.137 1.808.207 2.74.207.931 0 1.846-.07 2.74-.207V25.352h4.085Z"
    />
  </svg>
);
export default facebook;
