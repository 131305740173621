import { Formik, Form, Field } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import * as Yup from "yup";

import React, { useState } from "react";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  darken,
  lighten,
} from "@material-ui/core";
import { V3 } from "../../../lib/api";
import styled from "styled-components";
import Editor from "../../Editor";
import Loader from "../../GlobalLoader";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  label {
    color: #637381;
    line-height: 1.4375em;
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FeedBackWrapper = styled.div`
  padding: 6px 8px;

  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  width: auto;
  display: flex;
  min-width: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export const newCandidateFeedbacks = [
  { value: null, label: "Alle", color: "#98A2B3" },
  { id: 0, value: 0, label: "Offen", color: "#5f98fa" },
  {
    id: 1,
    value: 1,
    label: "Akzeptiert",
    color: "#85E13A",
  },

  { id: 2, value: 2, label: "Abgelehnt", color: "#F04438" },
  { id: 3, value: 3, label: "In Bearbeitung", color: "#98A2B3" },
];

const getEmailData = (data, type) => {
  switch (type) {
    case 0:
      return { subject: "", description: "" };
    case 1:
      return {
        subject: "Ihr Dossier wurde weitergeleitet!",
        description: ` <p style="margin-top: 0px; margin-bottom: 0px">
                                        Lieber Kandidat
                                    </p>
                                    <br />
                                    <p style="margin-top: 0px; margin-bottom: 0px">
                                        Wir beziehen uns auf Ihre Bewerbung f&uuml;r die Stelle (<a
                                            href="${process.env.NEXT_PUBLIC_WEB}/job/${data?.job?.slug}">${process.env.NEXT_PUBLIC_WEB}/job/${data?.job?.slug}</a>).
                                    </p>
                                    <br />
                                    <p style="margin-top: 0px; margin-bottom: 0px">
                                        Ihr Dossier wurde als qualifiziert markiert und der Kunde wird sich bei Ihnen
                                        melden.
                                    </p>
                                    <br />
                                    <p style="margin-top: 0px; margin-bottom: 0px">
                                        Bei Fragen wenden Sie sich bitte direkt an unseren Kunden ${data?.job?.companyName}
                                    </p>
                                    <br />
                                    <p style="margin-top: 0px; margin-bottom: 0px">Zusätzlich nehmen wir Ihre
                                        Bewerbungsunterlagen bei uns in der Datenbank auf und kontaktieren Sie, sobald
                                        wir eine Stelle haben, welche zu Ihrem Profil passt.</p>
                                    <br />
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Freundliche Gr&uuml;sse </p>
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Das TWO Team</p>
                                    <p style="margin-top: 0px;margin-bottom: 0px;">info@two.jobs</p>`,
      };
    case 2:
      return {
        subject: "Leider hat es nicht ganz gereicht!",
        description: ` <p style="margin-top: 0px;margin-bottom: 0px;">Lieber Kandidat</p>
                                    <br />
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Vielen Dank für die Zusendung Ihrer
                                        Bewerbungsunterlagen.</p>
                                    <br />
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Nach eingehender Prüfung anhand der
                                        Kriterien müssen wir Ihnen leider mitteilen, dass wir Ihre Bewerbung für die
                                        Stelle als <a href='${process.env.NEXT_PUBLIC_WEB}/job/${data?.job?.slug}'>${data?.job?.title}</a> leider nicht berücksichtigen
                                        können.
                                        Bei den Bewerbungen stehen uns Kandidaten zur Verfügung, die dem
                                        Anforderungsprofil noch besser entsprechen.</p>
                                    <br />
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Bei weiteren Fragen stehen wir Ihnen
                                        gerne zur Verfügung. </p>
                                    <br />
                                    <p style="margin-top: 0px;margin-bottom: 0px;">Beste Gr&uuml;sse</p>
                                    <p style="margin-top: 0px;margin-bottom: 0px;">${data?.job?.companyName}</p>`,
      };

    default:
      break;
  }
};

const FeedBack = ({ data, updateItem }) => {
  const [candidate, setCandidate] = useState(null);
  const defaultStatus = newCandidateFeedbacks[1];
  const status = newCandidateFeedbacks.find(
    (s) => s.value === data?.feedback?.type
  );

  const _handleSubmit = (values, formApi) => {
    V3.post(`new-job-candidates/status`, {
      ...values,
      candidate: candidate._id,
    })
      .then((res) => {
        updateItem(res.data.data);
        setCandidate(null);
        formApi.setSubmitting(false);
      })
      .catch((error) => console.log("err", error.response));
  };

  return (
    <>
      {!status ? (
        <FeedBackWrapper
          style={{
            color: darken(defaultStatus?.color, 1),
            backgroundColor: lighten(defaultStatus?.color, 0.3),
            cursor: "pointer",
          }}
          onClick={() => {
            setCandidate(data);
          }}
        >
          {defaultStatus?.label}
        </FeedBackWrapper>
      ) : (
        <FeedBackWrapper
          style={{
            color: darken(status?.color, 1),
            backgroundColor: lighten(status?.color, 0.3),
            cursor: "pointer",
          }}
          onClick={() => {
            setCandidate(data);
          }}
        >
          {status?.label}
        </FeedBackWrapper>
      )}

      <Dialog
        open={candidate ? true : false}
        maxWidth="md"
        fullWidth
        onClose={() => setCandidate(null)}
      >
        <Formik
          initialValues={{
            description: "",
            subject: "",
            type: null,
            sendEmail: true,
          }}
          validationSchema={Yup.object().shape({
            type: Yup.number().nullable().required("Required"),
            description: Yup.string(),
            subject: Yup.string(),
            sendEmail: Yup.boolean(),
          })}
          onSubmit={_handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form style={{ padding: 0 }}>
              {isSubmitting && <Loader />}
              <DialogTitle>Neuer Status</DialogTitle>
              <DialogContent style={{ paddingTop: 8 }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.type}
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                      const tmp = getEmailData(data, e.target.value);
                      setFieldValue("description", tmp?.description);
                      setFieldValue("subject", tmp?.subject);
                    }}
                    label="Status"
                  >
                    {newCandidateFeedbacks
                      .filter((el) => el?.value != null)
                      .map((el) => (
                        <MenuItem value={el?.value}>
                          <em>{el?.label}</em>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <Wrapper>
                  <label>E-Mail senden</label>
                  <Switch
                    checked={values.sendEmail}
                    onChange={() =>
                      setFieldValue("sendEmail", !values?.sendEmail)
                    }
                  />
                </Wrapper>

                <Collapse in={values.sendEmail} timeout="auto" unmountOnExit>
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Subjekt"
                    name="subject"
                    style={{ margin: 0, marginBottom: 15 }}
                  />
                  <Editor
                    value={values.description}
                    onChange={(value) => setFieldValue("description", value)}
                  />
                </Collapse>

                <DialogActions style={{ padding: 0, marginTop: 15 }}>
                  <Button
                    onClick={() => setCandidate(null)}
                    color="primary"
                    variant="outlined"
                    fullWidth
                    size="large"
                  >
                    Abbrechen
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="large"
                  >
                    Senden
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default FeedBack;
