import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import KeyboardDatePicker from "../../../Views/crm/candidates/KeyboardDatePicker";
import Grid from "@material-ui/core/Grid";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { TextField } from "material-ui-formik-components/TextField";

function New({ add, id }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = (values, formikApi) => {
    V3.post(`/sick-days`, { user: id, ...values })
      .then((res) => {
        add(res.data);
        formikApi.setSubmitting(false);
        setOpen(false);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Add
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add a Sick Day.</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              startDate: Yup.date().nullable().required("Required"),
              endDate: Yup.date().nullable().required("Required"),
              description: Yup.string().nullable(""),
            })}
            onSubmit={handleSubmit}
            initialValues={{ startDate: null, endDate: null, halfDay: false }}
          >
            {({ submitForm, isSubmitting, dirty, values }) => (
              <Form>
                <DialogContent style={{ minWidth: 500 }}>
                  {isSubmitting && <Loader />}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="startDate"
                        name="startDate"
                        label="From"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={KeyboardDatePicker}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="endDate"
                        name="endDate"
                        label="To"
                        format="dd.MM.yyyy"
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        multiline
                        rows={5}
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" autoFocus type="submit">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default New;
