import React, { useEffect, useState } from "react";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import { TextField as MUITextField } from "@material-ui/core";
import { V3 } from "../../../../lib/api";

function SendNewProductEmailTo(props) {
  const [members, setMembers] = React.useState([]);
  const [users, setUsers] = useState([]);
  const { companyData } = props;

  useEffect(() => {
    V3.get(`/users?company=${companyData._id}&limit=400`).then((response) => {
      const usersData = response.data.data.map((user) => {
        return {
          key: user._id,
          value: user.profile.name,
          label: user.profile.name,
        };
      });

      setUsers(usersData);
    });
  }, [companyData]);

  useEffect(() => {
    const usersData = companyData?.onNewProductSendEmailTo?.map((user) => {
      return {
        key: user?._id,
        value: user?.profile?.name,
        label: user?.profile?.name,
      };
    });

    setMembers(usersData || []);
  }, [companyData]);

  const onMembersChange = (event, value) => {
    setMembers(value);
    V3.put(`/companies/${companyData._id}/email-on-new-product`, {
      onNewProductSendEmailTo: value.map((el) => el.key),
    }).then((res) => {
      props.setCompany({
        ...companyData,
        onNewProductSendEmailTo: res?.data?.data?.onNewProductSendEmailTo,
      });
    });
  };

  return (
    <div
      style={{
        marginBottom: 15,
      }}
    >
      {" "}
      <MUIAutocomplete
        multiple
        options={users}
        getOptionLabel={(option) => option.label}
        onChange={onMembersChange}
        value={members}
        getOptionSelected={(option, value) => {
          return option.key === value.key;
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            placeholder="Select"
            variant="outlined"
            label="Send Email To"
          />
        )}
      />
    </div>
  );
}

export default SendNewProductEmailTo;
