import React, { useState } from "react";
import Card from "../../Card";
import Table from "../../Table";
import Actions from "../../Table/Actions";
import { V3 } from "../../../lib/api";
import New from "./new";
import { useConfirm } from "material-ui-confirm";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getPublicHolidays } from "../../../utils/getPublicHolidays";
import InfoIcon from "@material-ui/icons/Info";
import EditDayOf from "./Edit";
import { useSnackbar } from "notistack";
import dayjs from "../../../utils/DayJs";

function Index() {
  const [daysOf, setDaysOf] = useState([]);
  const [data, setData] = useState({});
  const [daysLeft, setDaysLeft] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const confirm = useConfirm();
  const { user } = useSelector((state) => state.auth);
  const authUser = useSelector((state) => state.users.user);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [daysToApprove, setDaysToApprove] = useState([]);

  const publicHolidays = getPublicHolidays();

  const handleApprove = ({ startDate, endDate, user, _id }) => {
    confirm({
      title: `${user?.profile?.name} `,
      description: `Approve days off from ${moment(startDate).format(
        "DD.MM.YYYY"
      )}-${moment(endDate).format("DD.MM.YYYY")}`,
    }).then(() => {
      setLoading(true);
      V3.put(`/daysOf/approve`, {
        days: [_id],
      })
        .then((result) => {
          let tmp = [...daysOf];
          // eslint-disable-next-line array-callback-return
          tmp.map((el, index) => {
            const item = result.data.data.find((d) => d._id === el._id);
            if (item) {
              tmp[index] = item;
            }
          });
          setDaysOf(tmp);
          setLoading(false);
        })
        .catch((err) => {});
    });
  };
  const handleApproveDays = () => {
    confirm({
      title: `Approve Days`,
      description: `Approve days off`,
    }).then(() => {
      setLoading(true);
      V3.put(`/daysOf/approve`, {
        days: daysToApprove.map((el) => el._id),
      })
        .then((result) => {
          let tmp = [...daysOf];

          // eslint-disable-next-line array-callback-return
          tmp.map((el, index) => {
            const item = result.data.data.find((d) => d._id === el._id);
            if (item) {
              tmp[index] = item;
            }
          });
          setDaysOf(tmp);
          setDaysToApprove([]);
          setLoading(false);
        })
        .catch((err) => {});
    });
  };
  const handleDelete = ({ from, to, _id }) => {
    confirm({
      title: `Delete your days of`,
      description: `Delete day off from ${moment(from).format(
        "DD.MM.YYYY"
      )}-${moment(to).format("DD.MM.YYYY")}`,
    }).then(() => {
      setLoading(true);
      V3.delete(`/daysOf/${_id}`, {
        approved: true,
      })
        .then((result) => {
          let tmp = [...daysOf];
          setDaysOf(tmp.filter((el, index) => el._id !== _id));
          setDaysLeft(result.data.daysLeft);
          setLoading(false);
        })
        .catch((err) => {});
    });
  };
  const handleSendDeleteRequest = (data) => {
    confirm({
      title: `Request to delete your approved day of`,
      description: `Delete day off from ${moment(data.startDat).format(
        "DD.MM.YYYY"
      )}-${moment(data.endDate).format("DD.MM.YYYY")}`,
    }).then(() => {
      V3.delete(`/daysOf/${data._id}`, {
        approved: true,
      })
        .then((result) => {
          enqueueSnackbar("Request for delete Has been sended", {
            variant: "success",
          });
        })
        .catch((err) => {});
    });
  };

  const handleUpdate = (id, data) => {
    const tmpData = daysOf.filter((el) => el._id !== id);
    setDaysOf([...tmpData, ...data.data]);
    setDaysLeft(data.daysLeft);
    enqueueSnackbar("Day of has been updated", {
      variant: "success",
    });
  };

  const columns = [
    {
      id: "form",
      Header: "Form",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {dayjs(props.original.startDate)
            .tz("Europe/Berlin")
            .format("DD/MM/YYYY")}
        </strong>
      ),
    },

    {
      id: "to",
      Header: "To",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {dayjs(props.original.endDate)
            .tz("Europe/Berlin")
            .format("DD/MM/YYYY")}
        </strong>
      ),
    },
    {
      id: "approved",
      Header: "approved",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.approved ? (
            props.original?.requestedForDelete ? (
              <strong
                style={{
                  borderRadius: 15,
                  backgroundColor: "#a665cb",

                  padding: "5px 10px",
                  color: "#fff",
                }}
              >
                Requested for Delete
              </strong>
            ) : (
              <strong
                style={{
                  borderRadius: 15,
                  backgroundColor: "#65CB72",

                  padding: "5px 10px",
                  color: "#fff",
                }}
              >
                Approved
              </strong>
            )
          ) : (
            <strong
              style={{
                borderRadius: 15,
                backgroundColor: "#FD7966",
                padding: "5px 10px",
                color: "#fff",
              }}
            >
              Not Approved
            </strong>
          )}
        </strong>
      ),
    },
    {
      id: "halfDay",
      Header: "Type",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.halfDay ? (
            <strong>Half day</strong>
          ) : (
            <strong>Full Day</strong>
          )}
        </strong>
      ),
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          <Actions>
            {user.email === "sandro.trovato@two.jobs" ||
            user.email === "natalie.ploeschberger@gmx.ch" ||
            user._id === "62d003c4a2172f001d1a4584" ||
            user.email === "admin@test.com" ||
            user.email === "manuela.braendli@two.jobs" ||
            user.email === "laila.crivelli@two.jobs" ||
            user.email === "kristian.oroshi@two.jobs" ? (
              <>
                {props.original?.approved ? (
                  <>
                    <Tooltip arrow title="Approved">
                      <span>
                        <CheckCircleIcon></CheckCircleIcon>
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Delete">
                      <span onClick={() => handleDelete(props.original)}>
                        <DeleteIcon></DeleteIcon>
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Checkbox
                      key={props.original._id}
                      checked={daysToApprove.find(
                        (el) => el._id === props.original._id
                      )}
                      onChange={() => {
                        if (
                          daysToApprove.find(
                            (el) => el._id === props.original._id
                          )
                        ) {
                          setDaysToApprove(
                            daysToApprove.filter(
                              (el) => el._id !== props.original._id
                            )
                          );
                        } else {
                          setDaysToApprove([...daysToApprove, props.original]);
                        }
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    ></Checkbox>
                    <Tooltip arrow title="Approve">
                      <span onClick={() => handleApprove(props.original)}>
                        <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Edit">
                      <EditDayOf
                        data={props.original}
                        handleUpdate={handleUpdate}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Delete">
                      <span onClick={() => handleDelete(props.original)}>
                        <DeleteIcon></DeleteIcon>
                      </span>
                    </Tooltip>
                  </>
                )}
              </>
            ) : props.original?.approved ? (
              <>
                {" "}
                <Tooltip arrow title="Approved">
                  <span>
                    <CheckCircleIcon></CheckCircleIcon>
                  </span>
                </Tooltip>{" "}
                <Tooltip arrow title="Delete">
                  <span onClick={() => handleSendDeleteRequest(props.original)}>
                    <DeleteIcon></DeleteIcon>
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip arrow title="Edit">
                  <EditDayOf
                    data={props.original}
                    handleUpdate={handleUpdate}
                  />
                </Tooltip>
                <Tooltip arrow title="Delete">
                  <span onClick={() => handleDelete(props.original)}>
                    <DeleteIcon></DeleteIcon>
                  </span>
                </Tooltip>
              </>
            )}
          </Actions>
        </>
      ),
    },
  ];

  const handleFetch = (query) => {
    setLoading(true);
    V3.get(`/daysOf${query}`)
      .then((result) => {
        setData(result.data);
        setDaysOf(result.data.data);
        setDaysLeft(result.data.daysLeft);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const add = (data) => {
    setDaysOf([...data.data, ...daysOf]);
    setDaysLeft(data.daysLeft);
  };

  return (
    <Card
      title={`${daysLeft?.toFixed(1)} days left.`}
      style={{ marginTop: 15 }}
      button={
        authUser?._id === user?._id ? (
          <>
            {daysToApprove?.length ? (
              <Tooltip arrow title="Approve">
                <span onClick={() => handleApproveDays()}>
                  <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                </span>
              </Tooltip>
            ) : null}
            <Tooltip title="Feiertage" style={{ marginRight: 15 }}>
              <IconButton onClick={() => setOpen(true)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <New add={add} />
          </>
        ) : null
      }
    >
      <Table
        data={daysOf}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        filterable={false}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&user=${id}&${queryString}`;
          handleFetch(query);
        }}
        minRows={5}
        noDataText="No days off"
      />
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Feiertage</DialogTitle>
        <DialogContent>
          <MuiTable>
            <TableBody>
              {publicHolidays.map((el, index) => (
                <TableRow key={`publicHolidays-${index}`}>
                  <TableCell>{moment(el.date).format("DD.MM.YYYY")}</TableCell>
                  <TableCell>{el.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default Index;
