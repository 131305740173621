import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { V3 } from "../../../lib/api";
import { DELETE_EXTERNAL_CLICK } from "../../../redux/types";
import { useSnackbar } from "notistack";
function Delete(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    V3.delete(`/external-clicks/${props.visitId}`).then((res) => {
      dispatch({
        type: DELETE_EXTERNAL_CLICK,
        payload: props.visitId,
      });
      enqueueSnackbar("External Clicks has been deleted", {
        variant: "success",
      });
    });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span className="action fi" onClick={handleClickOpen}>
        <DeleteIcon />
      </span>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete job</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <DialogContentText>
            Are u sure u want to delete this Click? Your wont be able to recover
            it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Delete;
