import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import GlobalLoader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { GET_CANDIDATE } from "../../../redux/types";
import { useSnackbar } from "notistack";

const DeleteDocument = ({ id, documentId }) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setLoader(true);

    V3.delete(`/crm/candidates/${id}/documents/${documentId}`)
      .then((res) => {
        setLoader(false);
        dispatch({
          type: GET_CANDIDATE,
          payload: res.data,
        });
        setOpen(false);
        enqueueSnackbar("Document deleted", { variant: "success" });
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar("Document deleted", { variant: "error" });
        setOpen(false);
      });
  };
  return (
    <>
      {loader && <GlobalLoader />}

      <IconButton>
        <DeleteIcon onClick={() => setOpen(true)} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          Dieses Dokument löschen
        </DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, dass Sie dieses Dokument löschen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Nein
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteDocument;
