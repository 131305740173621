import React from "react";
import LoadingOverlay from "react-loading-overlay";
import SyncLoader from "react-spinners/SyncLoader";

export default function Loader({ children, loading }) {
  return (
    <LoadingOverlay
      active={loading}
      spinner={<SyncLoader color="#fff" />}
      fadeSpeed={500}
      styles={{ width: "100%" }}
    >
      {children}
    </LoadingOverlay>
  );
}
