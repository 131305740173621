import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import clsx from "clsx";
import Moment from "react-moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
function getStatusName(id) {
  switch (id) {
    case "1":
      return "No Status";
    case "2":
      return "Kein Verkauf";
    case "3":
      return "Termin offen";
    case "4":
      return "Nachfassen am";
    case "5":
      return "Offerte";
    case "6":
      return "Erfolgreich abgeschlossen";
    case "7":
      return "Platziert";
    default:
      return "No Status";
  }
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  type: {
    padding: "6px 12px",
    width: "100px",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 10,
  },
  quality: {
    padding: "6px 12px",
    width: "100px",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 10,
  },
  status: {
    padding: "6px 12px",
    width: "100px",
    borderRadius: 3,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 10,
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  typeCandidate: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  typeCompany: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  typeTask: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },

  qualityA: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  qualityB: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  qualityC: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },
  status1: {
    backgroundColor: "rgba(66, 135, 245, 0.2)",
  },
  status2: {
    backgroundColor: "rgba(255, 157, 44, 0.2)",
  },
  status3: {
    backgroundColor: "rgba(90, 245, 66, 0.2)",
  },
});

function getType(id) {
  switch (id) {
    case 1:
      return "CANDIDATE";
    case 2:
      return "TASK";
    case 3:
      return "COMPANY";
    default:
      return "No Status";
  }
}
// style={{ backgroundColor: "#ff0000" }}

export default function SimpleCard({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Link to={`/crm/company/${data.id}`}>
      <Card
        className={classes.root}
        variant="outlined"
        style={{ backgroundColor: data.archived && theme.palette.archived }}
      >
        <CardActionArea>
          <CardContent style={{ padding: 0, display: "flex" }}>
            <Table
              size="small"
              component={Paper}
              style={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: data.archived && theme.palette.archived,
              }}
              variant="outlined"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Firmenname</TableCell>
                  <TableCell align="right">{data.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kontaktperson</TableCell>
                  <TableCell align="right">
                    {data?.contactPersons?.length > 0
                      ? `${data?.contactPersons[0]?.firstName} ${data?.contactPersons[0]?.lastName}`
                      : "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Geburtsdatum</TableCell>
                  <TableCell align="right">
                    <Moment>{data.birthday}</Moment>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="right">
                    {data.city}, {data.zip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Document Sent</TableCell>
                  <TableCell align="right">
                    {data.documentSent ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Archivieren</TableCell>
                  <TableCell align="right">
                    {data.archived ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Paper
              variant="outlined"
              style={{
                maxWidht: 140,
                padding: 5,
                borderRadius: 0,
                border: "none",
                backgroundColor: data.archived && theme.palette.archived,
              }}
            >
              <div
                className={clsx(classes.type, {
                  [classes.typeCandidate]: data.type === 1,
                  [classes.typeTask]: data.type === 2,
                  [classes.typeCompany]: data.type === 3,
                })}
              >
                {getType(data.type)}
              </div>
              <div
                className={clsx(classes.status, {
                  [classes.status1]: data.status === "1",
                  [classes.status2]: data.status === "2",
                  [classes.status3]: data.status === "3",
                })}
              >
                {getStatusName(data.status)}
              </div>
            </Paper>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
