import {
  GET_TEAM,
  ADD_TEAM_PERSON,
  DELETE_TEAM_PERSON,
  TEAM_LOADING,
  SET_START_DATE,
  SET_END_DATE,
} from "../types";
import moment from "moment";

const initialState = {
  persons: [],
  data: [],
  loading: true,
  links: [],
  startDate: moment(`01/01/${new Date().getFullYear()}`, "DD/MM/YYYY"),
  endDate: moment(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEAM:
      return {
        ...state,
        persons: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case DELETE_TEAM_PERSON:
      return {
        ...state,
        persons: state.persons.filter(
          (person) => person._id !== action.payload
        ),
      };
    case ADD_TEAM_PERSON:
      return {
        ...state,
        persons: [action.payload, ...state.persons],
      };

    case TEAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    default:
      return state;
  }
}
