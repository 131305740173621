import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import Moment from "react-moment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { V3 } from "../../lib/api";
import { useParams } from "react-router-dom";
export default function Order() {
  const [order, setOrder] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    V3.get(`/orders/${id}`).then((res) => {
      setOrder(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!order) return <Loader />;

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Vorname
                  </TableCell>
                  <TableCell align="right">{order.firstname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nachname
                  </TableCell>
                  <TableCell align="right">{order.lastname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    E-mail
                  </TableCell>
                  <TableCell align="right">{order.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tel.
                  </TableCell>
                  <TableCell align="right">{order.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nachricht
                  </TableCell>
                  <TableCell align="right">{order.message}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Order Id
                  </TableCell>
                  <TableCell align="right">{order.sId}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>

          <Card title="Products" style={{ marginTop: 15 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">QTY</TableCell>
                  <TableCell align="right">Klicks</TableCell>
                  <TableCell align="right">Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.products.map((product) => {
                  if (product.product) {
                    return (
                      <TableRow key={product._id}>
                        <TableCell component="th" scope="row">
                          <Avatar
                            src={product.product.image}
                            alt={product.product.title}
                          />
                        </TableCell>
                        <TableCell>{product.product.title}</TableCell>
                        <TableCell align="right">{product.qty}</TableCell>
                        <TableCell align="right">
                          {product.product.points}
                        </TableCell>
                        <TableCell align="right">{product.sId}</TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={product._id}>
                        <TableCell component="th" scope="row">
                          #
                        </TableCell>
                        <TableCell>Product Deleted</TableCell>
                        <TableCell align="right">{product.qty}</TableCell>
                        <TableCell align="right">0</TableCell>
                        <TableCell align="right">{product.sId}</TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Created At
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{order.createdAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Updated At
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{order.updatedAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Products ordered
                  </TableCell>
                  <TableCell align="right">{order.products.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Herunterladen PDF
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      component="a"
                      target="_blank"
                      href={order.pdf}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
