import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/GlobalLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import KeyboardDatePicker from "./KeyboardDatePicker";
import Card from "../../../components/Card";
import { V3 } from "../../../lib/api";
import FileUpload from "../../../components/fileUpload";
import Editor from "../../../components/Editor";
import moment from "moment";

import { Cantons2 } from "../../../data";
import { Alert } from "@material-ui/lab";
import { ADD_CANDIDATE } from "../../../redux/types";
import { useSnackbar } from "notistack";

const AddCandidate = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = React.useState(null);

  const dispatch = useDispatch();

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (selectorFiles) => {
    setFiles(selectorFiles);
  };

  const _handleSubmit = async (values, formApi) => {
    try {
      setMessage(null);
      const { city, ...rest } = values;

      const data = { ...rest, city: city?.key };

      const res = await V3.post(`/crm/candidates`, data);
      dispatch({
        type: ADD_CANDIDATE,
        payload: res.data.data,
      });
      enqueueSnackbar("Candidate created successfully", {
        variant: "success",
      });

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      formApi.setSubmitting(false);
      // props.uploadDocuments(props.id, formData, handleFinish);
      await V3.post(
        `/crm/candidates/${res.data.data._id}/upload-documents`,
        formData
      );
      push(`/crm/candidate/${res.data.data._id}`);
    } catch (error) {
      console.log("error", error);
      setMessage(error?.response?.date?.message);
      formApi.setSubmitting(false);
      enqueueSnackbar("Could not created Candidate", {
        variant: "error",
      });
    }
  };

  const parsePdf = (data, setFieldValue) => {
    setLoading(true);
    const formData = new FormData();
    data.forEach((file) => {
      formData.append("files", file);
    });
    V3.post(`/crm/candidates/extract`, formData)
      .then((response) => {
        if (response.data.emails) {
          setFieldValue("email", response.data.emails[0]);
        }
        if (response.data.phones) {
          setFieldValue("phone", response.data.phones[0]);
        }
        if (response.data.names) {
          setFieldValue("firstName", response.data.names[0]);
          setFieldValue("lastName", response.data.names[1]);
        }
        if (response.data.birthdays) {
          const earliest = response.data.birthdays.reduce(function (pre, cur) {
            const preDate =
              moment(pre, "DD/MM/YYYY") ||
              moment(pre, "DD-MM-YYYY") ||
              moment(pre, "DD.MM.YYYY");
            const curDate =
              moment(cur, "DD/MM/YYYY") ||
              moment(cur, "DD-MM-YYYY") ||
              moment(cur, "DD.MM.YYYY") ||
              moment();
            return preDate < curDate ? preDate : curDate;
          });
          setFieldValue(
            "birthday",
            moment(earliest, "DD/MM/YYYY") ||
              moment(earliest, "DD-MM-YYYY") ||
              moment(earliest, "DD.MM.YYYY")
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        birthday: Yup.string().nullable(),
        email: Yup.string().email().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string(),
        zip: Yup.string(),
        street: Yup.string(),
        phone: Yup.string().nullable(),
        notes: Yup.string(),
        linkedinProfileLink: Yup.string().nullable(),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        birthday: null,
        state: "",
        city: "",
        street: "",
        zip: "",
        phone: "",
        notes: "",
        linkedinProfileLink: null,
      }}
    >
      {({ isSubmitting, dirty, initialValues, setFieldValue, values }) => (
        <Form>
          {(isSubmitting || loading) && <Loader />}

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              {message && (
                <div style={{ width: "100%", padding: 10, marginBottom: 15 }}>
                  <Alert variant="filled" severity="error">
                    {message}
                  </Alert>
                </div>
              )}
              <Card
                title="Candidate Info"
                style={{ marginBottom: 15 }}
                contentStyles={{ padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstName"
                      label="Vorname"
                      name="firstName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastName"
                      label="Nachname"
                      name="lastName"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Tel."
                      name="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Field
                      name="birthday"
                      component={KeyboardDatePicker}
                      label="Geburtsdatum"
                      format="dd.MM.yyyy"
                      inputVariant="outlined"
                      placeholder="15/04/2020"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="linkedinProfileLink"
                      label="Linkedin Profile Link"
                      name="linkedinProfileLink"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Card
                title="Location"
                style={{ marginTop: 15 }}
                contentStyles={{ padding: 15 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="city"
                      options={Cantons2}
                      textFieldProps={{
                        label: "Kanton",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <Card title="Notizen">
                  <Editor
                    height={200}
                    value={initialValues.notes}
                    onChange={(value) => setFieldValue("notes", value)}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <FileUpload
                  handleChange={(data) => {
                    handleChange(data);
                    parsePdf(data, setFieldValue);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!dirty}
                  style={{ padding: "16px 32px" }}
                >
                  Create
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddCandidate;
