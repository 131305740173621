import React, { useState } from "react";
import Table from "../../Table";
import Tooltip from "@material-ui/core/Tooltip";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Card from "../../Card";
import { V3 } from "../../../lib/api";
const Subscriptions = ({ companyId }) => {
  const [invoices, setInvoices] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      id: "startDate",
      Header: "Start Date",
      width: 120,
      accessor: (d) => d.title,
      Cell: (props) => <Moment>{props.original.startDate}</Moment>,
    },
    {
      id: "endDate",
      Header: "End Date",
      width: 120,
      accessor: (d) => d.title,
      Cell: (props) => <Moment>{props.original.endDate}</Moment>,
    },

    {
      id: "to",
      Header: "Contact person",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong>
          {" "}
          {props.original.contactPerson && (
            <>{props.original.contactPerson.name}</>
          )}
        </strong>
      ),
    },

    {
      id: "user",
      Header: "Berater",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong>
          {props.original.user && props.original.user._id
            ? props.original.user.profile.name
            : "-"}
        </strong>
      ),
    },

    {
      id: "email",
      Header: "Email",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong style={{ textTransform: "lowercase" }}>
          {props.original.contactPerson && (
            <>{props.original.contactPerson.email}</>
          )}
        </strong>
      ),
    },
    {
      id: "phone",
      Header: "Phone",
      accessor: (d) => d.title,
      Cell: (props) => (
        <strong>
          {props.original.contactPerson && (
            <>{props.original.contactPerson.phone}</>
          )}
        </strong>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 150,
      Cell: (props) => (
        <>
          {props.original.files && (
            <Tooltip title="Download files">
              <IconButton
                component="a"
                href={props.original.files}
                target="_blank"
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Download Invoice">
            <IconButton component="a" href={props.original.url} target="_blank">
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <Card title="Invoices" defaultOpen={false}>
      <Table
        data={invoices}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${
            state.page + 1
          }&company=${companyId}&limit=5&${queryString}`;
          setLoading(true);

          V3.get(`/invoices${query}`).then((res) => {
            setData(res?.data);
            setInvoices(res?.data?.data);
            setLoading(false);
          });
        }}
        noDataText="No invoices"
        filterable={false}
        minRows={5}
      />
    </Card>
  );
};

export default Subscriptions;
