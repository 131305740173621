import React, { useState } from "react";
import Table from "../components/Table";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import { Grid } from "@material-ui/core";
import TopProductsSold from "../components/Orders/TopProductsSold";
import TopProductsSoldThisMonth from "../components/Orders/TopProductsSoldThisMonth";
import { V3 } from "../lib/api";
function Orders() {
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      id: "user",
      Header: "User",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/order/${props.original._id}`}>
          {props.original.user && props.original.user.profile.name}
        </Link>
      ),
    },
    {
      id: "products",
      Header: "Produkte",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.products.length}</strong>,
    },

    {
      id: "stock",
      Header: "Total Bestand",
      accessor: (d) => d._id,
      Cell: (props) => {
        const total = props.original.products.reduce((a, b) => +a + +b.qty, 0);
        return <strong>{total}</strong>;
      },
    },

    {
      id: "points",
      Header: "Gesamtpunktzahl",
      accessor: (d) => d._id,
      Cell: (props) => {
        const total = props.original.products.reduce((a, b) => {
          if (b.product) {
            return +a + +b.product.points * b.qty;
          } else {
            return +a + +0 * b.qty;
          }
        }, 0);
        return <strong>{total}</strong>;
      },
    },

    {
      id: "createdAt",
      Header: "Auftragsdatum",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      Cell: (props) => (
        <Tooltip title="Download PDF">
          <IconButton
            size="small"
            color="primary"
            component="a"
            href={props.original.pdf}
            target="_blank"
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} md={6}>
          <TopProductsSold withDate={true} title={"Top Produkte"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TopProductsSold title={"Top Produkte aller Zeiten"} />
        </Grid>
      </Grid>
      <TopProductsSoldThisMonth />
      <Header title="Bestellungen" size={data.total} />
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          setLoading(true);
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;

          V3.get(`/orders${query}`).then((res) => {
            setLoading(false);
            setData(res.data);
            setOrders(res.data.data);
          });
        }}
        noDataText="Keine Befehle"
      />
    </React.Fragment>
  );
}

export default Orders;
