import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @media (max-width: 960px) {
    justify-content: center;
    padding-right: 0;
    flex-direction: column;
    > span {
      display: none;
    }
    .DateInput input {
      padding: 5px !important;
    }
  }
`;
const Title = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 960px) {
    justify-content: center;
    h3 {
      line-height: 25px !important;
    }
  }
`;

const Content = styled.div`
  overflow: hidden;
  border-radius: 8px;
  .table {
    border: none !important;
    border-radius: 0 !important;
  }
`;
export default function Card({
  style,
  title,
  button,
  closable = true,
  children,
  contentStyles,
  defaultOpen = true,
}) {
  const [open, setOpen] = React.useState(defaultOpen);

  React.useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClick = () => {
    if (closable) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  return (
    <Paper style={style} variant="outlined">
      <Grid container spacing={0}>
        <Title
          item
          xs={12}
          md={5}
          onClick={handleClick}
          style={{ cursor: closable ? "pointer" : "inherit" }}
        >
          <h3 style={{ margin: 0, lineHeight: "58px", paddingLeft: 15 }}>
            {title}
          </h3>
        </Title>
        <ButtonWrapper item xs={12} md={7}>
          {button}

          {closable && (
            <IconButton
              onClick={handleClick}
              style={{ cursor: "pointer", marginLeft: 15 }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </ButtonWrapper>
      </Grid>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <>
          <Divider />
          <Content style={contentStyles}>{children}</Content>
        </>
      </Collapse>
    </Paper>
  );
}
