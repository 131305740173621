import React, { useEffect } from "react";
import Head from "../../components/Team/Head";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Sales from "../../components/Team/Sales";
import Offers from "../../components/Team/Offers";
import Appointments from "../../components/Team/Appointments";
import Documents from "../../components/Team/Documents";
import { V3 } from "../../lib/api";
import { GET_USER, USERS_LOADING } from "../../redux/types";
function TeamMember(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: GET_USER,
      payload: USERS_LOADING,
    });
    V3.get(`/users/${props.match.params.id}`).then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = useSelector((state) => state.users);
  return (
    <React.Fragment>
      <Head user={user} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Sales user={user && user._id} />
        <Offers user={user && user._id} />

        <Grid container spacing={2} style={{ margin: 0 }}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} style={{ margin: 0 }}>
              <Grid item xs={12} md={12} style={{ marginBottom: 15 }}>
                <Appointments user={user && user._id} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Documents user={user && user._id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default TeamMember;
