import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import React, { useState } from "react";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../../lib/api";
import Loader from "../../GlobalLoader";

function Status({ candidateId }) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(null);

  const handleSubmit = (values, form) => {
    V3.put(`crm/candidates/email`, {
      ...values,
      status: type,

      candidate: candidateId,
    }).then((res) => {
      setOpen(false);
      form.setSubmitting(false);
    });
  };
  return (
    <>
      <Paper style={{ marginTop: 15, marginBottom: 15, padding: 15 }}>
        <Button
          variant="contained"
          style={{
            background: "green",
            color: "#fff",
            marginBottom: 10,
          }}
          fullWidth
          startIcon={<ThumbUpIcon />}
          onClick={(e) => {
            setOpen(true);
            setType(1);
          }}
        >
          Weitergeleitet
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={(e) => {
            setOpen(true);
            setType(3);
          }}
        >
          Aufnahme
        </Button>
        <Button
          variant="contained"
          fullWidth
          style={{
            background: "rgba(0, 98, 255, 0.2)",
            marginTop: 10,
          }}
          onClick={(e) => {
            setOpen(true);
            setType(4);
          }}
        >
          Stelle besetzt
        </Button>
      </Paper>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Enter Job Titel</DialogTitle>
        <Formik
          validationSchema={Yup.object().shape({
            link: Yup.string(),
            title: Yup.string(),
            company: Yup.string(),
          })}
          onSubmit={handleSubmit}
          initialValues={{ link: "", title: "", company: "" }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              {isSubmitting && <Loader />}
              <DialogContent>
                {type === 4 ? (
                  <>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="title"
                      label="Title"
                      name="title"
                      style={{ margin: 0, marginBottom: 15 }}
                    />
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="company"
                      label="Company"
                      name="company"
                      style={{ margin: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="link"
                      label="Link"
                      name="link"
                      style={{ margin: 0 }}
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  close
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!dirty}
                  type="submit"
                >
                  Send
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default Status;
