import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function LinkedInLinks({ data }) {
  const [open, setOpen] = useState(false);
  let notes = "";
  if (data?.notes) {
    const regex = /(?:\r\n|\r|\n)/gm;
    notes = data?.notes.replaceAll(regex, "<br/>");
  }
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
      >
        Info
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div
                    dangerouslySetInnerHTML={{ __html: `<p>${notes}</p>` }}
                  ></div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
