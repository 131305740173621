import {
  GET_INTERESTED,
  GET_SINGLE_INTERESTED,
  UPDATE_INTEREST,
  INTERESTED_LOADING,
  DELETE_INTERESTED,
} from "../types";

const initialState = {
  interested: [],
  data: [],
  interest: null,
  loading: true,
  active: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INTERESTED:
      return {
        ...state,
        interested: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_SINGLE_INTERESTED:
      return {
        ...state,
        interest: action.payload.data,
        loading: false,
      };
    case DELETE_INTERESTED:
      return {
        ...state,
        interested: state.interested.filter(
          (interest) => interest._id !== action.payload
        ),
        loading: false,
      };
    case UPDATE_INTEREST:
      state.interested.forEach((element, index) => {
        if (element._id === action.payload.data._id) {
          state.interested[index] = action.payload.data;
        }
      });
      return {
        ...state,
        interested: state.interested,
        active: action.payload.data,
      };
    case INTERESTED_LOADING:
      return {
        ...state,
        interest: null,
        loading: true,
      };
    default:
      return state;
  }
}
