import React from "react";
import Grid from "@material-ui/core/Grid";
import Statistic from "../../components/Recruiter/Analytics/Statistic";

export default function Analytics() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Statistic />
      </Grid>
    </Grid>
  );
}
