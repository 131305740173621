import React, { useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles, fade } from "@material-ui/core/styles";
import { V3 } from "../../../lib/api";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import ActiveSourcingNotes from "./ActiveSourcingNotice";

import InfoIcon from "@material-ui/icons/Info";
import Moment from "react-moment";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import JobChats from "./JobChats";
import ActiveSourcingOnBoarding from "../../../components/Job/ActiveSourcingOnBoarding";
import TableComponent from "../../Table";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 30,
  },
  colorPrimary: {
    backgroundColor: fade(theme.palette.secondary.main, 0.1),
  },
  bar: {
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  .last-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 590px) {
      flex-direction: column;
      align-content: flex-start !important;
    }
    h4 {
      font-size: 14px;
      padding: 11px 24px;
      color: #fff;
      color: ${(props) => (props.published ? "#344054" : "#fff")};
      background-color: ${(props) => (props.published ? "#fff" : "#195079")};
      border-radius: 8px;
      border: 1px solid ${(props) => (props.published ? "#D0D5DD" : "#195079")};
      font-weight: 500;
    }
  }
`;

const ButtonsWrapper = styled.div`
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 5px;
  width: max-content;

  .custom-btn {
    padding: 8px 12px;
    color: #667085;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    margin-right: 8px;
    text-transform: inherit;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      color: #344054;
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }
`;

function ActiveSourcing({ company }) {
  const [data, setData] = useState({});
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [published, setPublished] = useState(true);
  const [imageGallery, setImageGallery] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("image");
  const [jobData, setJobData] = useState(null);

  const [openKritetrien, setOpenKritetrien] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const [interested, setInterested] = useState([]);
  const [openInterested, setOpenInterested] = useState(false);

  const columns = [
    {
      id: "title",
      Header: "Gebucht für",
      accessor: (d) => d.title,
      Cell: (props) => (
        <Link
          to={`/jobs/edit/${props.original._id}`}
          style={{ textTransform: "none" }}
        >
          <strong>{props.original.title}</strong>
        </Link>
      ),
    },
    {
      id: "isAnonymous",
      Header: "Anonymous",
      accessor: (d) => d.isAnonymous,
      Cell: (props) => <>{props.original.isAnonymous ? "Ja" : "Nein"}</>,
    },
    {
      id: "isAnonymous",
      Header: "Arbeitsort",
      accessor: (d) => d.isAnonymous,
      Cell: (props) => (
        <>
          {props.original.addressTwo
            ? props.original.addressTwo
            : props.original?.location?.city || "-"}
        </>
      ),
    },
    {
      id: "interested",
      Header: "Positive Reaktion",
      accessor: (d) => d.interested,
      Cell: (props) => {
        const interestedDAta = props.original.interested;
        return (
          <>
            {interestedDAta && interestedDAta.length > 0 ? (
              <Tooltip title={"info"}>
                <IconButton
                  onClick={() => {
                    setInterested(interestedDAta);
                    setOpenInterested(true);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      id: "prospectProfiles",
      Header: "Weitergeleitete Interessenten",
      accessor: (d) => d.prospectProfiles,
      Cell: (props) => {
        const prospectProfiles = props.original.prospectProfiles;

        return (
          <>
            {prospectProfiles && prospectProfiles.length > 0 ? (
              <Tooltip
                title={
                  <div>
                    {prospectProfiles
                      .filter((el) => !el?.deleted)
                      .map((el) => (
                        <p>{el.name ? el.name : "-"}</p>
                      ))}
                  </div>
                }
              >
                <IconButton
                  onClick={() => {
                    setProfiles(prospectProfiles.filter((el) => !el?.deleted));
                    setOpenDialog(true);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      id: "contacted",
      Header: "Kontaktiert",
      accessor: (d) => d.contacted,
      Cell: (props) => (
        <>{props.original?.contacted ? props.original?.contacted : "-"} </>
      ),
    },
    {
      id: "isAnonymous",
      Header: "Interne Notiz",
      accessor: (d) => d.isAnonymous,
      Cell: (props) => (
        <>
          {props.original?.activeSourcing?.notes ? (
            <ActiveSourcingNotes
              data={props.original?.activeSourcing}
            ></ActiveSourcingNotes>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "chats",
      Header: "Chats",
      accessor: (d) => d.chats,
      Cell: (props) => (
        <>
          {props.original?.chatImages &&
          props.original?.chatImages.length > 0 ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setImageGallery(props.original?.chatImages);
                setPhotoIndex(0);
                setOpen(true);
                setImageUrl("image");
              }}
            >
              Open
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    // {
    //   id: "chats",
    //   Header: "Client Chats",
    //   accessor: (d) => d.chats,
    //   Cell: (props) => (
    //     <>
    //       {props.original?.chatImages &&
    //       props.original?.chatImages.filter((el) => el.censoredImage).length >
    //         0 ? (
    //         <Button
    //           color="primary"
    //           variant="outlined"
    //           onClick={() => {
    //             setImageGallery(
    //               props.original?.chatImages.filter((el) => el.censoredImage)
    //             );
    //             setPhotoIndex(0);
    //             setOpen(true);
    //             setImageUrl("censoredImage");
    //           }}
    //         >
    //           Open
    //         </Button>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   ),
    // },
    {
      id: "chats",
      Header: "",
      width: 300,
      accessor: (d) => d.chats,
      Cell: (props) => {
        const percentage =
          (props.original?.contacted /
            props.original?.activeSourcing?.toContact) *
          100;
        return (
          <>
            <BorderLinearProgress
              variant="determinate"
              value={percentage > 100 ? 100 : percentage}
              style={{ width: 300 }}
            />
          </>
        );
      },
    },
    {
      id: "Kriterien",
      Header: "Kriterien",

      accessor: (d) => d.Kriterien,
      Cell: (props) => (
        <IconButton
          onClick={() => {
            setSelectedJob(props.original);
            setOpenKritetrien(true);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
    {
      id: "onboarding",
      Header: "Onboarding",
      accessor: (d) => d.onboarding,
      Cell: (props) => (
        <IconButton
          onClick={() => {
            setJobData(props.original);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
    {
      id: "toContact",
      Header: "Geplante Anschriften",
      accessor: (d) => d.onboarding,
      Cell: (props) => <>{props.original?.activeSourcing?.toContact}</>,
    },
  ];

  return (
    <>
      <HeadWrapper published={published}>
        <ButtonsWrapper>
          <Button
            onClick={() => setPublished(false)}
            variant="contained"
            className={`custom-btn ${!published ? "active" : "not-Active"}`}
            color="primary"
          >
            Archiviert
          </Button>
          <Button
            onClick={() => setPublished(true)}
            variant="contained"
            className={`custom-btn ${published ? "active" : "not-Active"}`}
            color="primary"
          >
            Nicht archiviert
          </Button>
        </ButtonsWrapper>
        <div className="last-wrapper">
          <h4>
            Konto Anschriften:&nbsp;&nbsp;
            {company?.activeSourcing || 0}
          </h4>
        </div>
      </HeadWrapper>

      <TableComponent
        data={jobs}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          setLoading(true);
          V3.get(`jobs/my/active-sourcing/${company?._id}${query}`).then(
            (res) => {
              setData(res.data);
              setJobs(res.data.data);
              setLoading(false);
            }
          );
        }}
        noDataText="No jobs"
        filterable={false}
        defaultFiltered={[
          {
            id: "published",
            value: published,
          },
        ]}
      />

      <JobChats
        open={open}
        imageGallery={imageGallery}
        photoIndex={photoIndex}
        setOpen={setOpen}
        setPhotoIndex={setPhotoIndex}
        imageUrl={imageUrl}
      />

      <Dialog
        maxWidth="md"
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Eingereichte Interessenten mit Tel</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Tel.</TableCell>
                <TableCell>Profile</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
              {profiles.map((el) => (
                <TableRow>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.phone}</TableCell>
                  <TableCell>
                    <a
                      href={el.profile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {el.profile}
                    </a>
                  </TableCell>
                  <TableCell>
                    {el.createdAt ? <Moment>{el?.createdAt}</Moment> : "_"}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{ maxHeight: 300, overflow: "scroll" }}
                      dangerouslySetInnerHTML={{ __html: el.description }}
                    ></div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openInterested}
        onClose={() => setOpenInterested(false)}
      >
        <DialogTitle>Interessenten ohne Tel</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Profile</TableCell>
              </TableRow>
              {interested.map((el) => (
                <TableRow>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>
                    {el?.createdAt ? <Moment>{el.createdAt}</Moment> : "-"}
                  </TableCell>

                  <TableCell align="right">
                    {el.linkedinProfile ? (
                      <a
                        href={el.linkedinProfile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {el.linkedinProfile}
                      </a>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenInterested(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openKritetrien}
        onClose={() => setOpenKritetrien(false)}
      >
        <DialogTitle>Kriterien</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            value={selectedJob?.activeSourcing?.keyWords}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Suchbegriffe / Key Words"
            name="activeSourcing.keyWords"
            style={{ margin: 0, marginBottom: 15 }}
            multiline
            rows={5}
          />
          <TextField
            value={selectedJob?.activeSourcing?.sourcingNotes}
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            label="Zusätzliche wichtige Informationen zum sourcen"
            name="activeSourcing.sourcingNotes"
            style={{ margin: 0, marginBottom: 15 }}
            multiline
            rows={5}
          />
          <TextField
            value={selectedJob?.activeSourcing?.education}
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            label="Ausbildung / MUSS Anforderungen"
            name="activeSourcing.education"
            style={{ margin: 0, marginBottom: 15 }}
            multiline
            rows={5}
          />
          <TextField
            value={selectedJob?.activeSourcing?.region}
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            label="Definierte Such - Region"
            name="activeSourcing.education"
            style={{ margin: 0 }}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpenKritetrien(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={jobData ? true : false}
        onClose={() => setJobData(null)}
      >
        <DialogTitle>Onboarding</DialogTitle>
        <DialogContent>
          <ActiveSourcingOnBoarding job={jobData} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setJobData(null)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActiveSourcing;
