import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit, FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import Delete from "../components/User/Delete";
import { V3 } from "../lib/api";
import { GET_USERS, USERS_LOADING } from "../redux/types";
const Team = () => {
  const { users, data, loading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/users/edit/${props.original._id}`}>
          <strong>
            {" "}
            {props.original.profile ? props.original.profile.name : ""}
          </strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "phone",
      Header: "Tel.",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`tel:+${props.original.phone} `}
          >
            {props.original.phone}
          </a>
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Datum",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },
    {
      id: "role",
      Header: "Rolle",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.role}</strong>,
    },
    {
      id: "actions",
      Header: "Aktion",
      filterable: false,
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Auf two.jobs">
            <a
              href={`/companies/${props.original._id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="fi"
            >
              <FiExternalLink></FiExternalLink>
            </a>
          </Tooltip>
          <Tooltip arrow title="Bearbeiten Sie diesen Benutzer">
            <Link to={`/users/edit/${props.original._id}`}>
              <FiEdit></FiEdit>
            </Link>
          </Tooltip>
          <Tooltip arrow title="Lösche diesen Benutzer">
            <Delete userId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Users" size={data.total} icon={<GroupIcon />} />
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        filterable={true}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          dispatch({
            type: USERS_LOADING,
          });
          V3.get(`/users${query}`).then((res) => {
            dispatch({
              type: GET_USERS,
              payload: res.data,
            });
          });
        }}
        noDataText="No users"
      />
    </React.Fragment>
  );
};

export default Team;
