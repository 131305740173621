import * as React from "react";

const Link = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.518 14.303 8.34 15.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L8.34 3.697m-2.328 8.22 5.834-5.834"
      stroke="#98A2B3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Link;
