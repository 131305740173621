import React, { useState } from "react";
import ImageUpload from "../../components/imageUpload";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Loader from "../../components/GlobalLoader";
import { V3 } from "../../lib/api";

function UploadImage({ job }) {
  const [avatarPreview, setAvatarPreview] = useState(job?.jobImage);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const onAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("image", avatar);
    setLoading(true);
    V3.put(`/jobs/${job._id}/image`, formData).then(() => {
      setLoading(false);
    });
  };

  return (
    <Paper style={{ marginTop: 15, padding: 15 }}>
      {loading && <Loader />}
      <ImageUpload
        imagePreviewUrl={avatarPreview}
        onImageChange={onAvatarChange}
        id="avatar"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: 15 }}
        onClick={handleSubmit}
        disabled={!avatar}
      >
        Upload
      </Button>
    </Paper>
  );
}

export default UploadImage;
