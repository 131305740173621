import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dropzone from "./Dropzone";

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding: 0 15px;
  h1 {
    line-height: 60px;
    margin: 0;
  }
`;
const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  padding: 15px 15px;
  height: 60px;
`;
function Upload(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedMedia, setSelectedMedia] = React.useState(null);

  const updatedSelected = (data) => {
    setSelectedMedia(data);
  };

  const selectImage = () => {
    props.onChange(selectedMedia);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <span onClick={(e) => setOpen(true)}>{props.children}</span>
      <Dialog
        fullScreen
        open={open}
        onClose={(e) => setOpen(false)}
        style={{ margin: 30, borderRadius: 5, overflow: "hidden" }}
      >
        <ModalHeader>
          <h1 style={{ fontSize: 20 }}>Select Image</h1>
          <IconButton onClick={(e) => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <Dropzone
          clear={open}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          updatedSelected={updatedSelected}
        />

        <ModalActions>
          <Button
            variant="outlined"
            onClick={(e) => setOpen(false)}
            style={{ padding: "12px 32px", marginRight: 15 }}
          >
            Schließen
          </Button>
          <Button variant="contained" color="primary" onClick={selectImage}>
            Bild auswählen
          </Button>
        </ModalActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Upload;
