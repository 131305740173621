import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button, ButtonGroup } from "@material-ui/core";

const Wrapper = styled.div`
  button {
    padding: 8px 15px;
    border-radius: 8px;
  }
  @media (max-width: 600px) {
    margin-top: 10px;
  }
  .MuiButtonBase-root {
    border-color: #d0d5dd !important;
    background: #fff;
    &:hover {
      background-color: #f2f4f7;
      color: #344054;
    }
  }
  .MuiButton-outlined {
    border-color: #d0d5dd;
    color: #344054;
  }
  .MuiButton-contained {
    border: 1px solid #d0d5dd;
    background-color: #f2f4f7;
    color: #344054;
  }
  @media (max-width: 420px) {
    .MuiButtonGroup-grouped {
      padding: 8px;
      font-size: 12px;
    }
  }
`;

function Filters({ setLoading, setStartDate, setEndDate, endDate, startDate }) {
  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    if (!clickedButton && endDate && startDate) {
      setClickedButton(moment(endDate).diff(moment(startDate), "months"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  return (
    <>
      <Wrapper>
        <ButtonGroup>
          <Button
            variant={clickedButton > 12 ? "contained" : "outlined"}
            onClick={() => {
              setStartDate(moment("01/01/2019", "DD/MM/YYYY"));
              setEndDate(moment(new Date()));
              setClickedButton(13);
              if (setLoading) {
                setLoading(true);
              }
            }}
          >
            Alle
          </Button>
          <Button
            variant={clickedButton === 12 ? "contained" : "outlined"}
            onClick={() => {
              setStartDate(moment(new Date()).subtract(1, "year"));
              setEndDate(moment(new Date()));
              setClickedButton(12);
              if (setLoading) {
                setLoading(true);
              }
            }}
          >
            12 Monate
          </Button>
          <Button
            variant={clickedButton === 6 ? "contained" : "outlined"}
            onClick={() => {
              setStartDate(dayjs(new Date()).subtract(6, "months"));
              setEndDate(dayjs(new Date()));
              setClickedButton(6);
              if (setLoading) {
                setLoading(true);
              }
            }}
          >
            6 Monate
          </Button>
          <Button
            variant={clickedButton === 3 ? "contained" : "outlined"}
            onClick={() => {
              setStartDate(dayjs(new Date()).subtract(3, "months"));
              setEndDate(dayjs(new Date()));
              setClickedButton(3);
              if (setLoading) {
                setLoading(true);
              }
            }}
          >
            3 Monate
          </Button>
        </ButtonGroup>
      </Wrapper>
    </>
  );
}

export default Filters;
