import {
  GET_STATUSES,
  ADD_STATUS,
  DELETE_STATUS,
  STATUSES_LOADING,
  UPDATE_PAID,
  UPDATE_STATUS,
} from "../types";

const initialState = {
  statuses: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STATUSES:
      return {
        ...state,
        statuses: action.payload.data,
        loading: false,
      };
    case DELETE_STATUS:
      return {
        ...state,
        statuses: state.statuses.filter(
          (status) => status._id !== action.payload
        ),
        loading: false,
      };
    case UPDATE_PAID:
      state.statuses.forEach((element, index) => {
        if (element._id === action.payload.id) {
          state.statuses[index].paid = action.payload.paid;
        }
      });
      return {
        ...state,
        statuses: state.statuses,
      };
    case UPDATE_STATUS:
      state.statuses.forEach((element, index) => {
        if (element._id === action.payload._id) {
          state.statuses[index] = action.payload;
        }
      });
      return {
        ...state,
        statuses: state.statuses,
      };
    case ADD_STATUS:
      return {
        ...state,
        statuses: [action.payload, ...state.statuses],
      };
    case STATUSES_LOADING:
      return {
        ...state,
        statuses: [],
        loading: true,
      };
    default:
      return state;
  }
}
