import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../../components/GlobalLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "../../components/Table";
import { useEffect } from "react";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import Header from "../../components/Page/Header";
import { V3 } from "../../lib/api";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
function Companies() {
  const [open, setOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(false);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    V3.delete(`/categories/candidate/${currentCategory}`)
      .then((res) => {
        setData(data?.filter((el) => el._id !== currentCategory));
        setOpen(false);
        enqueueSnackbar("Category deleted successfully", {
          variant: "success",
        });
      })

      .catch((err) => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCurrentCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateCategory = (values, formikApi) => {
    setUpdateMessage(null);
    V3.put(`/categories/candidate/${currentCategory?._id}`, values)
      .then((res) => {
        setData(
          data.map((el) => {
            if (el._id === res.data.data._id) {
              return res.data.data;
            } else {
              return el;
            }
          })
        );
        formikApi.setSubmitting(false);
        formikApi.resetForm({});
        setOpenEdit(false);
        enqueueSnackbar("Category updated successfully", {
          variant: "success",
        });
      })

      .catch((err) => {
        formikApi.setSubmitting(false);
        enqueueSnackbar("Category deleted successfully", { variant: "error" });
        setUpdateMessage(err?.response?.date?.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    V3.get(`/categories/candidate?limit=999`).then((res) => {
      setData(res?.data?.data);
      setLoading(false);
    });
  }, []);

  const _handleSubmit = (values, formikApi) => {
    setMessage(null);

    V3.post(`/categories/candidate`, values)
      .then((res) => {
        setData([...data, res?.data?.data]);
        formikApi.setSubmitting(false);
        enqueueSnackbar("Category created successfully", {
          variant: "success",
        });
        formikApi.resetForm({});
      })

      .catch((err) => {
        formikApi.setSubmitting(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        setMessage(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      id: "name",
      Header: "Kategorie",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.name}</strong>,
    },
    {
      id: "description",
      Header: "Beschreibung",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.description}</strong>,
    },
    {
      id: "createdAt",
      Header: "Created At",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "actions",
      Header: "Aktion",
      accessor: (d) => d._id,
      Cell: (props) => (
        <>
          {" "}
          <IconButton
            onClick={() => {
              setOpenEdit(true);
              setCurrentCategory(props.original);
            }}
          >
            <EditIcon />
          </IconButton>{" "}
          <IconButton onClick={() => handleClickOpen(props.original._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Candidate categories" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {message && (
            <Alert severity="error" style={{ marginBottom: 15 }}>
              {message}
            </Alert>
          )}
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              description: Yup.string(),
            })}
            onSubmit={_handleSubmit}
            initialValues={{
              name: "",
              description: "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Kategorie"
                  name="name"
                  style={{ marginTop: 0 }}
                />

                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Beschreibung"
                  name="description"
                  multiline
                  rows={6}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 30 }}
                >
                  Create category
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={8}>
          <Table
            data={data}
            columns={columns}
            loading={loading}
            noDataText="No categories"
            manual={false}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kategorie löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wirklich diese Kategorie löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Kategorie löschen</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              description: Yup.string(),
            })}
            onSubmit={handleUpdateCategory}
            initialValues={{
              name: currentCategory?.name || "",
              description: currentCategory?.name || "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {updateMessage && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {updateMessage}
                  </Alert>
                )}
                {isSubmitting && <Loader></Loader>}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Kategorie"
                  name="name"
                  style={{ marginTop: 0 }}
                />

                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Beschreibung"
                  name="description"
                  multiline
                  rows={6}
                />

                <DialogActions>
                  <Button
                    onClick={() => setOpenEdit(false)}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
export default Companies;
