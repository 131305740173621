import React, { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { V3 } from "../../lib/api";
import SharedPointsCard from "./SharedPointsCard";
import InstagramIcon from "./Icons/instagram";
import TwitterIcon from "./Icons/twitter";
import CopyIcon from "./Icons/CopyIcon";
import FacebookIcon from "./Icons/facebook";
import WhatsappIcon from "./Icons/whatsapp";
import LinkedinIcon from "./Icons/linkedin";
import MailIcon from "./Icons/mail";
import XingIcon from "./Icons/xing";
import LinkIcon from "./Icons/link";
import SharedJobsChart from "./SharedJobsChart";
import Filters from "./Filters";
import moment from "moment";

// import Filters from "@ui/Filters";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 960px) {
    overflow-y: scroll;
    padding-bottom: 10px;
  }
`;
const SingleCard = styled.div`
  width: calc(25% - 11.3px);
  @media (max-width: 1200px) {
    width: calc(33.33% - 11.3px);
  }
  @media (max-width: 960px) {
    width: calc(50% - 10px);
  }
`;

const RecruiterCharts = () => {
  const [links, setLinks] = useState([]);
  const [externalClicks, setExternalClicks] = useState([]);
  const { recruiter } = useSelector((state) => state.recruiter);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(3, "months")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));

  useEffect(() => {
    V3.get(`/recruiters/analytics/my/${recruiter._id}`, {
      params: {
        startDate: startDate.format("DD/MM/YYYY"),
        endDate: endDate.format("DD/MM/YYYY"),
      },
    }).then((res) => {
      setLinks(res.data.links);
      setExternalClicks(res.data.externalClicks);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let facebook = 0;
  let mail = 0;
  let twitter = 0;
  let linkedin = 0;
  let xing = 0;
  let whatsapp = 0;
  let shared = 0;
  let instagram = 0;

  let externalClickPoints = 0;

  // eslint-disable-next-line array-callback-return
  links.map((el) => {
    if (
      !el.facebook &&
      !el.mail &&
      !el.twitter &&
      !el.linkedin &&
      !el.xing &&
      !el.instagram &&
      !el.whatsapp
    ) {
      shared += el.points;
    }
    if (el.facebook) {
      facebook += el.points;
    }
    if (el.mail) {
      mail += el.points;
    }
    if (el.twitter) {
      twitter += el.points;
    }
    if (el.linkedin) {
      linkedin += el.points;
    }
    if (el.xing) {
      xing += el.points;
    }
    if (el.whatsapp) {
      whatsapp += el.points;
    }
    if (el.instagram) {
      instagram += el.points;
    }
  });

  // eslint-disable-next-line array-callback-return
  externalClicks.map((el) => {
    externalClickPoints += el.points;
  });

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Filters setEndDate={setEndDate} setStartDate={setStartDate} />
      </div>

      <CardWrapper>
        <SingleCard>
          <SharedPointsCard title="Copy" value={shared} icon={<CopyIcon />} />
        </SingleCard>

        <SingleCard>
          <SharedPointsCard
            title="Facebook"
            value={facebook}
            icon={<FacebookIcon />}
            type="Facebook"
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard
            title="Twitter"
            value={twitter}
            icon={<TwitterIcon />}
            type="Twitter"
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard
            title="LinkedIn"
            value={linkedin}
            icon={<LinkedinIcon />}
            type="Linkedin"
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard title="Xing" value={xing} icon={<XingIcon />} />
        </SingleCard>

        <SingleCard>
          <SharedPointsCard
            title="WhatsApp"
            value={whatsapp}
            icon={<WhatsappIcon />}
            type="Whatsapp"
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard
            title="E-Mail"
            value={mail}
            icon={<MailIcon />}
            type="Mail"
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard
            title={"Weiterleitungen"}
            value={externalClickPoints}
            icon={<LinkIcon />}
          />
        </SingleCard>
        <SingleCard>
          <SharedPointsCard
            title={"Instagram"}
            value={instagram}
            icon={<InstagramIcon />}
          />
        </SingleCard>
      </CardWrapper>
      <div>
        <SharedJobsChart links={links} />
      </div>
    </>
  );
};

export default RecruiterCharts;
