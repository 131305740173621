import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import UploadIcon from "./UploadIcon";
import { Alert, AlertTitle } from "@material-ui/lab";
import { fade } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import UploadProgress from "./UploadProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { V3 } from "../../../lib/api";
import { CLEAR_UPLOAD, SET_UPLOAD_FILE } from "../../../redux/types";
const FileUploadWrapper = styled.div`
  height: calc(100% - 124px);
  outline: none !important;
  .dropzone {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    outline: none !important;

    #scrollableDiv {
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100vh - 180px);
      padding: 15px;
    }

    .on-drag {
      outline: none !important;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: ${(props) => fade(props.theme.palette.secondary.main, 0.6)};
      height: 100%;
      z-index: 999999;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .cloud {
        fill: #fff !important;
        transition: 0.3s ease;
      }

      p {
        color: #fff;
      }
    }
    p,
    button {
      transition: 0.3s ease;
    }
    .upload-icon {
      width: 100px;
      fill: #ccc;
      transition: 0.3s ease;
      .arrow {
        fill: ${(props) => props.theme.palette.primary.main};
        transition: 0.3s ease;
      }
    }
  }
  &.active {
    .arrow {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: bounce;
      animation-name: bounce;
    }
    @-webkit-keyframes bounce {
      0%,
      100% {
        -webkit-transform: translateY(0);
      }
      50% {
        -webkit-transform: translateY(-40px);
      }
    }
    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-40px);
      }
    }

    .upload-icon {
      transition: 0.3s ease;
      transform: scale(1.5);
    }
  }
`;

function FileUpload(props) {
  const [myFiles, setMyFiles] = useState([]);

  const [media, setMedia] = useState([]);
  const [info, setInfo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    V3.get(`/medias?limit=72`).then((res) => {
      setMedia([...media, ...res.data.data]);
      setInfo(res.data.pagination);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.clear === false) {
      setMyFiles([]);
      dispatch({
        type: CLEAR_UPLOAD,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clear]);
  const fetchMoreData = () => {
    V3.get(`/medias?limit=72&page=${info.current + 1}`).then((res) => {
      setMedia([...media, ...res.data.data]);
      setInfo(res.data.pagination);
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      dispatch({
        type: SET_UPLOAD_FILE,
        payload: [...acceptedFiles],
      });

      const scrollableDiv = document.getElementById("scrollableDiv");
      scrollableDiv.scrollTo({ top: 0, behavior: "smooth" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myFiles]
  );

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      onDrop,
      accept: "image/*",
      noClick: true,
      noKeyboard: true,
      maxSize: 100000000,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <Alert severity="error" key={file.path} style={{ marginTop: 15 }}>
        <AlertTitle>
          {file.path} - {bytesToSize(file.size)}
        </AlertTitle>
        {errors.map((err) => {
          if (err.code === "file-too-large") {
            return (
              <ul style={{ margin: 0, padding: 0 }}>
                <li>File is larger than 100mb</li>
              </ul>
            );
          } else {
            return (
              <ul style={{ margin: 0, padding: 0 }}>
                <li>{err.message}</li>
              </ul>
            );
          }
        })}
      </Alert>
    );
  });
  return (
    <>
      <FileUploadWrapper
        style={{ height: "calc(100% - 125px)" }}
        className={isDragActive ? "active" : "not-active"}
      >
        <div
          {...getRootProps({
            className: `dropzone ${isDragActive ? "active" : ""}`,
          })}
        >
          <input {...getInputProps()} />

          <Button
            color="primary"
            variant="contained"
            onClick={open}
            disableElevation
            disableRipple
            style={{ borderRadius: 0 }}
          >
            Upload new images
          </Button>

          {isDragActive && (
            <div className="on-drag">
              <UploadIcon />
              <p className="jpeg">JPG, JPEG, PNG, SVG </p>
              <p style={{ textAlign: "center" }} className="jpeg">
                Legen Sie Ihre Bilder hier ab
              </p>
            </div>
          )}

          <div id="scrollableDiv">
            <div>{fileRejectionItems}</div>
            <InfiniteScroll
              dataLength={media.length}
              next={fetchMoreData}
              hasMore={info && info.current < info.total}
              loader={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 150,
                  }}
                >
                  <CircularProgress />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <UploadProgress
                library={media}
                selectedMedia={props.selectedMedia}
                setSelectedMedia={props.setSelectedMedia}
                updatedSelected={props.updatedSelected}
              />
            </InfiniteScroll>
          </div>
        </div>
      </FileUploadWrapper>
    </>
  );
}

export default FileUpload;
