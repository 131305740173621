import {
  GET_COMPANIES,
  GET_COMPANY,
  ADD_COMPANY,
  DELETE_COMPANY,
  COMPANIES_LOADING,
  ADD_CONTACT_PERSON,
  REMOVE_FROM_LIST,
  GET_COMPANIES_CRM,
  REMOVE_FROM_LIST_CRM,
  DELETE_COMPANY_CRM,
  ADD_COMPANY_CRM,
  ADD_COMPANY_BONUS,
  REMOVE_COMPANY_BONUS,
  SET_COMPANY_BONUSES,
  UPDATE_CRM_COMPANY,
  UPDATE_COMPANY,
} from "../types";

const initialState = {
  companies: [],
  crmcompanies: [],
  data: [],
  company: null,
  loading: true,
  sales: 0,
  offers: 0,

  consultantCompany: [],
  presents: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload.data,
        sales: action.payload.sales,
        offers: action.payload.offers,
        consultantCompany: action.payload.consultantCompany || [],
        presents: action.payload.presents || [],
        loading: false,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter(
          (company) => company._id !== action.payload
        ),
        loading: false,
      };
    case REMOVE_FROM_LIST:
      return {
        ...state,
        companies: state.companies.filter(
          (company) => company._id !== action.payload._id
        ),
      };
    case ADD_COMPANY:
      return {
        ...state,
        companies: [action.payload, ...state.companies],
      };
    case ADD_CONTACT_PERSON:
      return {
        ...state,
        company: action.payload,
      };
    case COMPANIES_LOADING:
      return {
        ...state,
        company: null,
        loading: true,
      };

    case GET_COMPANIES_CRM:
      return {
        ...state,
        crmcompanies: action.payload.data,
        data: action.payload,
        loading: false,
      };

    case REMOVE_FROM_LIST_CRM:
      return {
        ...state,
        crmcompanies: state.crmcompanies.filter(
          (company) => company._id !== action.payload._id
        ),
      };

    case DELETE_COMPANY_CRM:
      return {
        ...state,
        crmcompanies: state.crmcompanies.filter(
          (company) => company._id !== action.payload
        ),
      };

    case ADD_COMPANY_CRM:
      return {
        ...state,
        crmcompanies: [action.payload, ...state.crmcompanies],
      };
    case ADD_COMPANY_BONUS:
      return {
        ...state,
        company: { ...state.company, bonuses: action.payload },
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case REMOVE_COMPANY_BONUS:
      return {
        ...state,
        company: { ...state.company, bonuses: action.payload },
      };
    case SET_COMPANY_BONUSES:
      return {
        ...state,
        company: { ...state.company, bonuses: action.payload },
        loading: false,
      };
    case UPDATE_CRM_COMPANY:
      state.crmcompanies.forEach((element, index) => {
        if (element._id === action.payload._id) {
          state.crmcompanies[index] = action.payload;
        }
      });
      return {
        ...state,
        crmcompanies: state.crmcompanies,
        loading: false,
      };
    default:
      return state;
  }
}
