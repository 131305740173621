import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/GlobalLoader";
import Header from "../../components/Page/Header";
import Table from "../../components/Table";
import Actions from "../../components/Table/Actions";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "../../components/Card";
import AddBonus from "../../components/Bonuses/AddBonus";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import { V3 } from "../../lib/api";
import { BONUSES_LOADING, GET_BONUS, REMOVE_BONUS } from "../../redux/types";
import { useSnackbar } from "notistack";

function SingleBonus() {
  const { id } = useParams();
  const { bonus, loading } = useSelector((state) => state.bonus);
  const confirm = useConfirm();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = (id, bonusId) => {
    confirm({
      title: `Delete Bonus`,
      description: "You wont be able to restore this anymore!",
    }).then(() => {
      dispatch({
        type: BONUSES_LOADING,
      });
      V3.delete(`/bonuses/${id}/remove/${bonusId}`)
        .then((res) => {
          dispatch({
            type: REMOVE_BONUS,
            payload: res.data.data,
          });

          enqueueSnackbar("Bonus removed successfully", { variant: "success" });
        })

        .catch((err) => {
          enqueueSnackbar("Something went wrong", { variant: "success" });
        });
    });
  };

  useEffect(() => {
    dispatch({
      type: BONUSES_LOADING,
    });
    V3.get(`/bonuses/${id}`).then((res) =>
      dispatch({
        type: GET_BONUS,
        payload: res.data,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading || !bonus) {
    return <Loader />;
  }
  const columns = [
    {
      id: "title",
      Header: "Titel",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original.description}</strong>,
    },
    {
      id: "amount",
      Header: "Menge",
      accessor: (d) => d.title,
      Cell: (props) => <strong>{props.original.amount}</strong>,
    },

    {
      id: "createdAt",
      Header: "Publiziert am",
      accessor: (d) => d.createdAt,
      filterable: false,
      width: 200,
      Cell: (props) => (
        <div>
          <Moment>{props.original.createdAt}</Moment>
        </div>
      ),
    },

    {
      Header: "Aktion",
      accessor: "id",
      filterable: false,
      width: 160,
      Cell: (props) => (
        <Actions>
          <Tooltip title="Delete this job">
            <DeleteIcon onClick={() => handleDelete(id, props.original._id)} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <>
      <Header title={bonus.title}>
        <AddBonus data={bonus} />
      </Header>
      <Card title="Bonuses">
        <Table
          data={bonus.bonuses}
          columns={columns}
          loading={loading}
          pages={0}
          noDataText="No Bonuses"
          filterable={false}
          minRows={5}
        />
      </Card>
    </>
  );
}

export default SingleBonus;
