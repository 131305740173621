import React, { useEffect } from "react";
import { List, arrayMove } from "react-movable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "../../Views/products/Delete";
import { useDispatch, useSelector } from "react-redux";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useConfirm } from "material-ui-confirm";
import { V3 } from "../../lib/api";
import {
  DUPLICATE_PRODUCT,
  GET_ONLY_PRODUCTS,
  PRODUCTS_LOADING,
  UPDATE_PRODUCT,
} from "../../redux/types";
import { Backdrop, Switch, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

const DraggableTable = (props) => {
  const [items, setItems] = React.useState(props.data);
  const [widths, setWidths] = React.useState([]);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => state.product);

  const { search, setSearch } = props;

  useEffect(() => {
    setItems(props.data);
  }, [props.data]);

  const handleReorder = (oldIndex, newIndex) => {
    const orderedItems = arrayMove(props.data, oldIndex, newIndex);
    setItems(orderedItems);
    dispatch({
      type: PRODUCTS_LOADING,
    });
    V3.post(`/products/order`, { products: orderedItems }).then((res) => {
      dispatch({
        type: GET_ONLY_PRODUCTS,
        payload: res.data.data,
      });
    });
  };

  const handleDuplicate = (duplicateData) => {
    confirm({
      title: `Duplicate`,
      description: `Are you sure you wont to duplicate product ${duplicateData?.title}`,
    }).then(() => {
      V3.put(`/products/${duplicateData._id}/duplicate`).then((res) => {
        dispatch({
          type: DUPLICATE_PRODUCT,
          payload: res.data.data,
          id: duplicateData._id,
        });
        enqueueSnackbar("Product created successfully", {
          variant: "success",
        });
      });
    });
  };

  const updatePublished = (data, published) => {
    V3.put(`products/${data._id}/publish`, { published: published }).then(
      (res) => {
        dispatch({ type: UPDATE_PRODUCT, payload: res.data.data });
      }
    );
  };

  return (
    <>
      <Table component={Paper}>
        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span> Titel</span>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: 0, marginLeft: 15 }}
                size="small"
                label="Search"
              />
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Klicks
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Bestand
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Typ
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Produkt - Favorit
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              Publiziert
            </TableCell>
            <TableCell align="right">Aktion</TableCell>
          </TableRow>
        </TableBody>

        <List
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            handleReorder(oldIndex, newIndex);
          }}
          beforeDrag={({ elements, index }) => {
            const cells = Array.from(elements[index].children);
            const widths = cells.map(
              (cell) => window.getComputedStyle(cell).width
            );
            setWidths(widths);
          }}
          renderList={({ children, props, isDragged }) => (
            <TableBody
              style={{
                cursor: isDragged ? "grabbing" : undefined,
                position: "relative",
              }}
              {...props}
            >
              {loading && (
                <Backdrop
                  open={true}
                  style={{ zIndex: 99999999, position: "absolute" }}
                >
                  <CircularProgress color="#000" />
                </Backdrop>
              )}
              {children}
            </TableBody>
          )}
          renderItem={({ value, props, isDragged }) => {
            const _widths = isDragged ? widths : [];
            const row = (
              <TableRow {...props}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ width: _widths[0], padding: 0, paddingLeft: 15 }}
                >
                  <strong>{value.title}</strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[1] }}
                >
                  <strong>{value && value.points}</strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[2] }}
                >
                  <strong>{value && value.stock}</strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[3] }}
                >
                  <strong>
                    {value.type === "1" ? "Gutschein" : "Aktivität"}
                  </strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[4] }}
                >
                  <strong>
                    {value.favoriteProduct === null && "Ja"}
                    {value.favoriteProduct === undefined && "Ja"}
                    {value.favoriteProduct === true && "Ja"}
                    {value.favoriteProduct === false && "Nein"}{" "}
                  </strong>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ width: _widths[4] }}
                >
                  <Switch
                    checked={value.published === false ? false : true}
                    onChange={(e) => updatePublished(value, e.target.checked)}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: _widths[5], padding: 0 }}
                >
                  <div>
                    <Tooltip title="Duplicate this product">
                      <IconButton onClick={() => handleDuplicate(value)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit this product">
                      <IconButton
                        component={Link}
                        to={`/products/edit/${value._id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete this product">
                      <IconButton>
                        <Delete productId={value._id} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
            return isDragged ? <TableBody> {row}</TableBody> : row;
          }}
        />
      </Table>
    </>
  );
};

export default DraggableTable;
