import { GET_ACTIVITY, ADD_ACTIVITY, ACTIVITY_LOADING } from "../types";

const initialState = {
  activity: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload.data,
        loading: false,
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        activity: [action.payload, ...state.activity],
      };
    case ACTIVITY_LOADING:
      return {
        ...state,
        activity: [],
        loading: true,
      };
    default:
      return state;
  }
}
