import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Loader from "../GlobalLoader";
import Image from "./Image";
import { V3 } from "../../lib/api";
import { Alert } from "@material-ui/lab";
function New({ handleAdd }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, formikApi) => {
    setMessage(null);
    const data = {
      name: values.name,
      image: values.image ? values.image._id : null,
    };
    V3.post(`/partners`, data)
      .then((res) => {
        formikApi.setSubmitting(false);
        setOpen(false);
        handleAdd(res.data.data);
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);

        formikApi.setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Neue hinzufüge
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth="true" maxWidth="sm">
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            image: Yup.object().required("Required"),
          })}
          onSubmit={handleSubmit}
          initialValues={{}}
        >
          {({ isSubmitting, dirty, setFieldValue, values }) => (
            <Form>
              {isSubmitting && <Loader />}

              <DialogTitle>Neu hinzufügen</DialogTitle>
              <DialogContent>
                {message && (
                  <Alert severity="error" style={{ marginBottom: 15 }}>
                    {message}
                  </Alert>
                )}
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  name="name"
                  style={{ margin: 0 }}
                />
                <Image setFieldValue={setFieldValue} values={values} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Schließen
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  disabled={!dirty}
                  type="submit"
                >
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default New;
