import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../../GlobalLoader";
import { useDebounce } from "use-debounce";
import { V3 } from "../../../../lib/api";
import {
  BONUSES_LOADING,
  GET_ALL_BONUSES,
  SET_COMPANY_BONUSES,
} from "../../../../redux/types";
import { useSnackbar } from "notistack";

function Row({ data, defaultOpen = false, onSelect }) {
  const [open, setOpen] = React.useState(defaultOpen);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.title}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onSelect(data)}
          >
            Select
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.bonuses.map((bonus) => (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{bonus.description}</TableCell>
                      <TableCell align="right">{bonus.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SelectBonusTemplate({ id, totalEmployees }) {
  const [open, setOpen] = useState(false);
  const [init, setInit] = useState(true);

  const { bonuses, loading } = useSelector((state) => state.bonus);

  const [empValue] = useDebounce(totalEmployees, 600);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useLayoutEffect(() => {
    setInit(false);
    dispatch({
      type: BONUSES_LOADING,
    });
    V3.get(`/bonuses/all`).then((res) => {
      dispatch({
        type: GET_ALL_BONUSES,
        payload: res.data.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateCompanyBonuses = (id, data) => {
    V3.put(`/companies/${id}/bonus`, data)
      .then((res) => {
        dispatch({
          type: SET_COMPANY_BONUSES,
          payload: res.data.data,
        });
        setOpen(false);
        enqueueSnackbar("Bonuses updated successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        setOpen(false);
        enqueueSnackbar("Could not updated Company", {
          variant: "success",
        });
      });
  };

  useEffect(() => {
    if (!init) {
      if (empValue && Number.isInteger(empValue)) {
        for (let index = 0; index < bonuses.length; index++) {
          const bonus = bonuses[index];
          if (bonus?.title.includes("+")) {
            const amount = parseInt(bonus?.title.replace("+", ""));
            if (parseInt(empValue) >= amount) {
              updateCompanyBonuses(id, bonus);
            }
          } else if (bonus?.title.includes("-")) {
            const amounts = bonus?.title.split("-").map((el) => parseInt(el));
            if (amounts.length > 1) {
              if (
                parseInt(empValue) >= amounts[0] &&
                parseInt(empValue) <= amounts[1]
              ) {
                updateCompanyBonuses(id, bonus);
              }
            }
          }
        }
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empValue]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSelect = (data) => {
    updateCompanyBonuses(id, data, setOpen);
  };
  return (
    <>
      {loading && <Loader />}
      <Button
        variant="outlined"
        color="primary"
        style={{ marginRight: 15 }}
        onClick={() => setOpen(true)}
      >
        Templates
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Select Template</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
                {bonuses.map((bonus) => (
                  <Row data={bonus} onSelect={onSelect} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectBonusTemplate;
