import React from "react";
import Paper from "@material-ui/core/Paper";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { V3 } from "../../../lib/api";
import { UPDATE_JOB } from "../../../redux/types";
import { useSnackbar } from "notistack";
const Link = styled.a`
  margin: 0px;
  margin-right: 15px;
  min-width: 200px;
  color: ${(props) => props.theme.palette.primary.main};
`;

function Slug({ jobId, slug }) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const handleSubmit = (values, formApi) => {
    const data = {
      slug: values.slug,
    };
    V3.put(`/jobs/update-slug/${jobId}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_JOB,
          payload: jobId,
          data: res.data.data,
        });
        enqueueSnackbar("Job updated successfully", {
          variant: "success",
        });
        formApi.setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
        });
        formApi.setSubmitting(false);
      });
  };
  return (
    <Formik
      validationSchema={Yup.object().shape({
        slug: Yup.string().required("Required"),
      })}
      onSubmit={handleSubmit}
      initialValues={{
        slug: slug,
      }}
    >
      {({ dirty, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Paper
                style={{ padding: 15, display: "flex", alignItems: "center" }}
              >
                <Link
                  href={`https://two.jobs/job/${values.slug}`}
                  target="_blank"
                >
                  https://two.jobs/job/{values.slug}
                </Link>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="slug"
                  label="Slug"
                  name="slug"
                  style={{ margin: 0, marginLeft: 30 }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!dirty}
                  style={{ padding: "16px 32px" }}
                >
                  Aktualisieren
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default Slug;
