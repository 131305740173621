import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Logo from "../components/Logo";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Alert from "@material-ui/lab/Alert";
import GlobalLoader from "../components/GlobalLoader";
import { V3 } from "../lib/api";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Auth = styled.div`
  display: flex;
  height: 100vh;
`;

const Left = styled.div`
  width: 50%;
  height: 100vh;
  background-image: url("/images/auth.jpg");
  background-size: cover;
  background-position: center center;
  @media (max-width: 960px) {
    width: 0%;
    display: none;
  }
`;
const Right = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth--form {
    width: 100%;
    max-width: 400px;

    .auth--submit {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 30px;

    .mob-logo {
      text-align: center;
      width: 100%;
    }
  }
`;
const SignIn = () => {
  const [msg, setmsg] = useState(null);

  let { push } = useHistory();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated === true) {
      push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const _handleSubmit = (values, formikApi) => {
    setmsg(null);
    const data = {
      email: values.email,
    };

    V3.post(`auth/crm/login`, data)
      .then((res) => {
        push(`/login/${res.data.token}`);
      })
      .catch((err) => {
        setmsg(err.response?.data?.message || "Email doesn't exists!");

        formikApi.setSubmitting(false);
      });
  };

  return (
    <Auth>
      <Left></Left>
      <Right>
        <div className="auth--form">
          <div>
            <Logo
              style={{
                width: 140,
              }}
            />
            {msg && (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            )}
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please enter a valid email")
                .required("Required"),
            })}
            onSubmit={_handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form style={{ width: "100%" }}>
                {isSubmitting && <GlobalLoader></GlobalLoader>}
                <Field
                  component={TextField}
                  type="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />

                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="auth--submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Right>
    </Auth>
  );
};

export default SignIn;
