import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "../../Dialog";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Cantons2 } from "../../../data";
import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import KeyboardDatePicker from "../../../Views/crm/candidates/KeyboardDatePicker";
import { useHistory } from "react-router-dom";
import Loader from "../../GlobalLoader";
import { V3 } from "../../../lib/api";
import { ADD_CANDIDATE } from "../../../redux/types";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
// import

const FilesUploaded = styled.div`
  border-radius: 5px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      padding: 0 20px;
      transition: 0.5s ease;
      cursor: pointer;
      background: rgba(76, 84, 90, 0.1);
      margin-bottom: 10px;
      border-radius: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        width: 60%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .remove--btn {
        text-align: right;
        width: 10%;

        svg {
          transform: scale(1.2);
        }

        &:hover {
          svg {
            transform: scale(1.4);
          }
        }
      }
      span.size {
        width: 30%;
      }
      &:hover {
        background: rgba(76, 84, 90, 0.2);
      }
    }
  }
`;
function DropCandidates({ children }) {
  const [myFiles, setMyFiles] = useState([]);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const handleAddCandidate = async (values, formApi) => {
    try {
      setMessage(null);
      const { city, ...rest } = values;

      const data = { ...rest, city: city?.key };

      const res = await V3.post(`/crm/candidates`, data);
      dispatch({
        type: ADD_CANDIDATE,
        payload: res.data.data,
      });
      enqueueSnackbar("Candidate created successfully", {
        variant: "success",
      });

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      formApi.setSubmitting(false);

      await V3.post(
        `/crm/candidates/${res.data.data._id}/upload-documents`,
        formData
      );
      push(`/crm/candidate/${res.data.data._id}`);
      setMyFiles([]);
    } catch (error) {
      console.log("error", error);
      setMessage(error?.response?.date?.message);
      formApi.setSubmitting(false);
      enqueueSnackbar("Could not created Candidate", {
        variant: "error",
      });
    }
  };

  const files = myFiles.map((file) => (
    <li key={file.path}>
      <strong>{file.name}</strong>
      <span className="size">{file.size} bytes</span>
      <span className="remove--btn" onClick={removeFile(file)}>
        <AiOutlineDelete></AiOutlineDelete>
      </span>
    </li>
  ));

  return (
    <React.Fragment>
      <Dialog
        open={files.length > 0}
        maxWidth="sm"
        fullWidth
        onClose={(e) => setMyFiles([])}
      >
        <DialogTitle onClose={(e) => setMyFiles([])}>
          Create candidate
        </DialogTitle>
        <DialogContent>
          {message && (
            <Alert severity="error" style={{ marginBottom: 15 }}>
              {message}
            </Alert>
          )}
          {files.length > 0 && (
            <FilesUploaded>
              <ul>{files}</ul>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            </FilesUploaded>
          )}

          <Formik
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              birthday: Yup.string().required("Required").nullable(),
              email: Yup.string().email().required("Required"),
              city: Yup.string().required("Required"),
              zip: Yup.string(),
              street: Yup.string(),
              phone: Yup.number().nullable(),
              notes: Yup.string(),
            })}
            onSubmit={handleAddCandidate}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              gender: "",
              birthday: null,
              quality: "",
              status: "",
              state: "",
              city: "",
              street: "",
              zip: "",
              category: "",
              phone: null,
              notes: "",
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {isSubmitting && <Loader />}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstName"
                      label="Vorname"
                      name="firstName"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastName"
                      label="Nachname"
                      name="lastName"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      type="phone"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Tel."
                      name="phone"
                      style={{ margin: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      name="birthday"
                      component={KeyboardDatePicker}
                      label="Geburtsdatum"
                      format="dd.MM.yyyy"
                      inputVariant="outlined"
                      placeholder="15/04/2020"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Autocomplete}
                      fullWidth
                      name="city"
                      options={Cantons2}
                      textFieldProps={{
                        label: "Kanton",
                        variant: "outlined",
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
                <DialogActions style={{ padding: "15px 24px" }}>
                  <Button color="primary" variant="outlined" type="submit">
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <div
        {...getRootProps({
          className: `dropzone ${isDragActive ? "active" : ""}`,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p style={{ textAlign: "center", height: "100vh" }}>
            Legen Sie die Dateien hier ab ...
          </p>
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
}

export default DropCandidates;
