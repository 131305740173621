import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "../components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    maxWidth: "1920px",
    margin: "0 auto",
    padding: "15px",
    marginTop: 64,
    minHeight: "calc(100vh - 64px)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: theme.palette.background.paper,
  },
}));

function LayoutDefault({ children, setTheme, theme }) {
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.up("lg"));
  const classes = useStyles();
  const [open, setOpen] = React.useState(matches ? true : false);

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { fetching, loading } = useSelector((state) => state.global);
  return (
    <div className={classes.root}>
      {fetching && (
        <LinearProgress
          style={{
            zIndex: 999999,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
          }}
        />
      )}
      {isAuthenticated && (
        <React.Fragment>
          <Header
            setTheme={setTheme}
            theme={theme}
            open={open}
            setOpen={setOpen}
          />
          <Sidebar open={open} setOpen={setOpen} />
        </React.Fragment>
      )}

      <Loader loading={loading}>
        <main className={classes.content}>{children}</main>
      </Loader>
    </div>
  );
}

export default LayoutDefault;
