import React from "react";
import Card from "../Card";
import { TextField } from "material-ui-formik-components/TextField";
import { Field } from "formik";
import YouTube from "react-youtube";

function youtube_parser(url) {
  if (url.includes("shorts")) {
    const data = url.split("shorts/");

    if (data.length > 1) {
      return data[1].slice(0, 11);
    } else {
      return null;
    }
  } else {
    const regExp =
      // eslint-disable-next-line no-useless-escape
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }
}
const opts = {
  width: "100%",
};
export default function Video({ values }) {
  return (
    <Card title="Youtube Video" style={{ marginTop: 15 }}>
      {values.video && (
        <YouTube videoId={youtube_parser(values.video)} opts={opts} />
      )}
      <div style={{ padding: 15 }}>
        <Field
          component={TextField}
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
          label="YouTube link"
          name="video"
          style={{ margin: 0 }}
        />
      </div>
    </Card>
  );
}
