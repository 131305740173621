import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import { Tooltip, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import Sales from "../components/Team/Sales";

import GroupIcon from "@material-ui/icons/Group";
import Delete from "../components/User/Delete";
import New from "../components/User/New";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import usePrice from "../hooks/usePrice";
import { V3 } from "../lib/api";
import { GET_USERS, USERS_LOADING } from "../redux/types";
const Team = () => {
  const { users, data, loading } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState({ filtered: [], page: 0 });
  const [consultant, setConsultant] = useState(false);

  const [start, setStart] = useState(moment().startOf("year"));
  const [end, setEnd] = useState(moment());

  const dispatch = useDispatch();

  useEffect(() => {
    let queryString = state.filtered
      .map((key) => key.id + "=" + key.value)
      .join("&");
    let query = `?page=${state.page + 1}${
      consultant ? `&consultant=true` : ""
    }&roles=editor,employee,admin&${queryString}`;
    dispatch({
      type: USERS_LOADING,
    });
    V3.get(`/users${query}`).then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, consultant]);

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      filterable: true,
      Cell: (props) => (
        <Link to={`/team/${props.original._id}`} target="_blank">
          <strong>
            {props.original.profile ? props.original.profile.name : ""}
          </strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "Email",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "phone",
      Header: "Tel.",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`tel:+${props.original.profile.phone} `}
          >
            {props.original.profile.phone}
          </a>
        </strong>
      ),
    },
  ];

  if (
    user?.email === "kristian.oroshi@two.jobs" ||
    user?.email === "admin@test.com"
  ) {
    columns.push({
      id: "birthday",
      Header: "Birthday",
      accessor: (d) => d._id,
      Cell: (props) =>
        props.original?.profile?.birthday ? (
          <Moment>{props.original?.profile?.birthday}</Moment>
        ) : (
          <p>-</p>
        ),
    });
  }

  if (consultant) {
    columns.push({
      id: "totalAmount",
      Header: "Betrag of sales",
      accessor: (d) => d._id,
      Cell: (props) => <p> {usePrice(props.original.totalAmount)} CHF</p>,
    });
    columns.push({
      id: "clients",
      Header: "Kundenbesuche",
      accessor: (d) => d._id,
      Cell: (props) => <p>{props.original.clients}</p>,
    });
    columns.push({
      id: "completedPercentage",
      Header: "Abschlussquote",
      accessor: (d) => d._id,
      Cell: (props) => <p>{props.original.completedPercentage}%</p>,
    });
  }
  columns.push({
    id: "actions",
    Header: "Aktion",
    accessor: (d) => d._id,
    Cell: (props) => (
      <Actions>
        <Tooltip arrow title="Edit this user">
          <Link to={`/users/edit/${props.original._id}?fromTeam=true`}>
            <FiEdit></FiEdit>
          </Link>
        </Tooltip>
        <Tooltip arrow title="Delete this user">
          <Delete userId={props.original._id} />
        </Tooltip>
      </Actions>
    ),
  });

  return (
    <React.Fragment>
      <Header title="Team" size={data.total} icon={<GroupIcon />}>
        <Grid container>
          <Grid
            item
            md={9}
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={consultant}
                  onChange={() => setConsultant(!consultant)}
                />
              }
              label="Only Berater"
            />
            <div>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From"
                format="dd/MM/yyyy"
                value={start}
                onChange={(date) => setStart(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ margin: 0 }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To"
                format="dd/MM/yyyy"
                value={end}
                onChange={(date) => setEnd(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ margin: 0, marginLeft: 15 }}
              />
            </div>
          </Grid>
          <Grid item md={3} xs={12} align="right">
            <New />
          </Grid>
        </Grid>
      </Header>
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(filterState) => {
          setState(filterState);
        }}
        noDataText="No team users"
        defaultFiltered={[
          {
            id: "startDateSales",
            value: moment(start).format("DD/MM/YYYY"),
          },
          {
            id: "endDateSales",
            value: moment(end).format("DD/MM/YYYY"),
          },
        ]}
        collapseOnDataChange={false}
        SubComponent={(row) => (
          <div style={{ padding: 15 }}>
            <Sales
              user={row?.original?._id}
              withCard={false}
              start={start}
              end={end}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default Team;
