import React from "react";
import { Switch } from "react-router-dom";

// Layouts
import Default from "./Routes/default";
import Canvas from "./Routes/canvas";

// Import Views
import Error from "../../Views/Error";
import Dashboard from "../../Views/Dashboard";
import AdminAnalytics from "../../Views/Analytics";
import SearchCandidates from "../../Views/SearchCandidates";
import SearchCompanies from "../../Views/SearchCompanies";

//Authentications
import Login from "../../Views/Login";

// Companies
import Companies from "../../Views/Companies";
import AddCompany from "../../Views/companies/new";

//Recruiter
import Recruiters from "../../Views/Recruiters";
import Recruiter from "../../Views/recruiter/index";
import Analytics from "../../Views/recruiter/analytics";

import Calendar from "../../Views/Calendar";

//Jobs
import Jobs from "../../Views/Jobs";
import EditJob from "../../Views/jobs/edit/index";
import NewJob from "../../Views/jobs/new/index";

//Crawled Jobs
import CrawledJobs from "../../Views/CrawledJobs";
import SingleCrawledJob from "../../Views/crawledJobs/index";

//Applications
import Applications from "../../Views/Applications";
import EditApplication from "../../Views/applications/edit/index";

import Users from "../../Views/Users";
import Team from "../../Views/Team";
import TeamMember from "../../Views/team/index";
import EditUser from "../../Views/user/edit/index";

//Alert
import Alerts from "../../Views/Alert";

//Crm Companies
import CrmCompanies from "../../Views/crm/Companies";
import AddCrmCompany from "../../Views/crm/companies/new";
import ViewCrmCompany from "../../Views/crm/companies/index";
import EditCrmCompany from "../../Views/crm/companies/edit";
import ArchivedCompanies from "../../Views/crm/Archived";
//Crm Companies
import CrmCandidates from "../../Views/crm/Candidates";
import AddCrmCandidate from "../../Views/crm/candidates/new";
import ViewCrmCandidate from "../../Views/crm/candidates/index";
import EditCrmCandidate from "../../Views/crm/candidates/edit";

//Shop
import Products from "../../Views/Products";
import NewProduct from "../../Views/products/new";
import EditProduct from "../../Views/products/edit";
import Orders from "../../Views/Orders";
import Order from "../../Views/order/index";

import EditCompany from "../../Views/companies/edit";
import CompaniesAnalytics from "../../Views/companies/analytics";
import CompanyAnalytics from "../../Views/companies/analytics/company";
import MyAnalytics from "../../Views/my-analytics/index";

//  Categories
import JobCategories from "../../Views/categories/jobs";
import CompanyCategories from "../../Views/categories/companies";
import CandidateCategories from "../../Views/categories/candidates";
import reasons from "../../Views/categories/reasons";
import ProductsCategories from "../../Views/categories/products";
import Hashtags from "../../Views/categories/Hashtags";
import Rejects from "../../Views/categories/Rejects";
import Regions from "../../Views/categories/Regions";

// Settings
import SettingsTeam from "../../Views/settings/Team";
import NewTeamMember from "../../Views/settings/team/new";
import EditTeamMember from "../../Views/settings/team/edit";

// Media
import Media from "../../Views/Media";
import Testimonials from "../../Views/settings/Testimonials";
import NewTestimonial from "../../Views/settings/testimonials/new";
import EditTestimonial from "../../Views/settings/testimonials/edit";

//Interested
import Interested from "../../Views/Interested";
import NewInterested from "../../Views/Interested/New";
import EditInterested from "../../Views/Interested/Edit";

//linkedIn Accounts
import LinkedInAccounts from "../../Views/linkedInAccounts";
import AddLinkedInAccounts from "../../Views/linkedInAccounts/New";
import EditLinkedInAccounts from "../../Views/linkedInAccounts/Edit";

//Platform
import Platform from "../../Views/settings/Platform";
import Invoices from "../../Views/Invoices";
import Partners from "../../Views/Partners";
import JobCandidates from "../../Views/jobCandidates";

//Bonuses
import Bonuses from "../../Views/Bonuses";
import SingleBonus from "../../Views/Bonuses/SingleBonus";

//contacting

import Contacting from "../../Views/contacting";

import Pipeline from "../../Views/Pipeline";
import PipelineStatistic from "../../Views/PipelineStatistic";
import Emails from "../../Views/Emails";
import LoginCode from "../../Views/LoginCode";
import AnalyticSharedLink from "../../Views/recruiter/sharedLinks";
import InactiveRecruiters from "../../Views/recruiter/inactive";
import Blog from "../../Views/blogs";
import AddBlog from "../../Views/blogs/Add";
import EditBlog from "../../Views/blogs/Edit";
import QuizTemplate from "../../Views/quizTemplate";
import EditQuizTemplate from "../../Views/quizTemplate/Edit";
import Favorites from "../../Views/Favorites";
import UpdateCallTemplate from "../../Views/updateCallTemplate";
import AnalyticSharedJobs from "../../Views/recruiter/sharedJobs";
import NotesTemplate from "../../Views/notesTemplate";
import RouteTemplate from "../../Views/routesTemplate";

export default function SwitchWrapper({ location, setTheme, theme }) {
  return (
    <Switch location={location}>
      {/* Dashboard */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/"
        component={Dashboard}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/media"
        component={Media}
        checkPath={true}
      />
      {/*AdminAnalytics */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/analytics"
        component={AdminAnalytics}
        checkPath={true}
      />

      {/* Jobs */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/jobs"
        component={Jobs}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/jobs/new"
        component={NewJob}
        checkPath={true}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/jobs/edit/:id"
        component={EditJob}
        checkPath={true}
      />

      {/* Crawled Jobs */}

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crawled/jobs"
        component={CrawledJobs}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crawled/jobs/:id"
        component={SingleCrawledJob}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/pipeline"
        component={Pipeline}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/statistic/pipeline"
        component={PipelineStatistic}
        checkPath={true}
      />

      {/* Applications */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/applications"
        component={Applications}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/applications/edit/:id"
        component={EditApplication}
      />

      {/* Recruiters */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiters"
        component={Recruiters}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiters/analytics"
        component={Analytics}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiters/analytics/shared-links"
        component={AnalyticSharedLink}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiters/analytics/shared-jobs"
        component={AnalyticSharedJobs}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiters/analytics/inactive"
        component={InactiveRecruiters}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/recruiter/:id"
        component={Recruiter}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/alerts"
        component={Alerts}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/favorites"
        component={Favorites}
        checkPath={true}
      />

      {/* Users */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/users"
        component={Users}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/team"
        component={Team}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/team/:id"
        component={TeamMember}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/users/edit/:id"
        component={EditUser}
      />

      {/* Candidates */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/candidates"
        component={CrmCandidates}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/candidates/new"
        component={AddCrmCandidate}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/candidates/edit/:id"
        component={EditCrmCandidate}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/candidate/:id"
        component={ViewCrmCandidate}
      />

      {/* Calendar */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/calendar"
        component={Calendar}
        checkPath={true}
      />

      {/* Companies */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/companies"
        component={Companies}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/companies/new"
        component={AddCompany}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/companies"
        component={CrmCompanies}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/companies/new"
        component={AddCrmCompany}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/companies/archived"
        component={ArchivedCompanies}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/companies/edit/:id"
        component={EditCrmCompany}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/crm/company/:id"
        component={ViewCrmCompany}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/search/candidates"
        component={SearchCandidates}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/search/companies"
        component={SearchCompanies}
      />

      {/* Shop */}

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/products"
        component={Products}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/products/edit/:id"
        component={EditProduct}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/products/new"
        component={NewProduct}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/orders"
        component={Orders}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/order/:id"
        component={Order}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/companies/edit/:id"
        component={EditCompany}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/companies/analytics"
        component={CompaniesAnalytics}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/companies/analytics/:id"
        component={CompanyAnalytics}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/my-analytics/:id"
        component={MyAnalytics}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/jobs"
        component={JobCategories}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/products"
        component={ProductsCategories}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/reasons"
        component={reasons}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/candidates"
        component={CandidateCategories}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/companies"
        component={CompanyCategories}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/hashtags"
        component={Hashtags}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/rejects"
        component={Rejects}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/categories/regions"
        component={Regions}
        checkPath={true}
      />

      {/* Settings Routes */}
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/team"
        component={SettingsTeam}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/team/new"
        component={NewTeamMember}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/team/edit/:id"
        component={EditTeamMember}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/testimonials"
        component={Testimonials}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/testimonials/new"
        component={NewTestimonial}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/testimonials/edit/:id"
        component={EditTestimonial}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/platform"
        component={Platform}
        checkPath={true}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/invoices"
        component={Invoices}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/settings/partners"
        component={Partners}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/bonuses"
        component={Bonuses}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/bonuses/:id"
        component={SingleBonus}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/interested"
        component={Interested}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/interested/new"
        component={NewInterested}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/interested/edit/:id"
        component={EditInterested}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/job-candidates"
        component={JobCandidates}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/contacting"
        component={Contacting}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/linkedin"
        component={LinkedInAccounts}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/linkedin/edit/:id"
        component={EditLinkedInAccounts}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/linkedin/add"
        component={AddLinkedInAccounts}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/emails"
        component={Emails}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/blog"
        component={Blog}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/blog/add"
        component={AddBlog}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/blog/:id"
        component={EditBlog}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/quiz"
        component={QuizTemplate}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/quiz/:id"
        component={EditQuizTemplate}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/update-call-template"
        component={UpdateCallTemplate}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/notes-template"
        component={NotesTemplate}
        checkPath={true}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/routes-template"
        component={RouteTemplate}
        checkPath={true}
      />

      <Canvas exact={true} path="/login" component={Login} />
      <Canvas exact={true} path="/login/:id" component={LoginCode} />
      <Default setTheme={setTheme} theme={theme} path="*" component={Error} />
    </Switch>
  );
}
