import React, { useEffect, useState } from "react";
import Loader from "../../components/GlobalLoader";
import { Grid, Paper, Button } from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "material-ui-formik-components/TextField";
import { V3 } from "../../lib/api";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import QuizTemplateQuestions from "../../components/QuizTemplates";

function EditQuizTemplate(props) {
  const [message, setMessage] = useState(null);
  const params = useParams();
  const [quiz, setQuiz] = useState(null);

  useEffect(() => {
    V3.get(`quiz-template/${params.id}`).then((res) => {
      setQuiz(res.data.data);
    });
  }, [params]);

  const _handleSubmit = async (values, formikAPi) => {
    setMessage(null);
    const data = { ...values };

    try {
      await V3.put(`quiz-template/${params.id}`, data);
      formikAPi.setSubmitting(false);
    } catch (error) {
      formikAPi.setSubmitting(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
    }
  };

  if (!quiz) {
    return <Loader />;
  }

  return (
    <Formik
      validationSchema={Yup.object().shape({
        title: Yup.string().nullable().required("Required"),
      })}
      onSubmit={_handleSubmit}
      initialValues={{
        title: quiz?.title,
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {isSubmitting && <Loader />}

          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              {message && (
                <Alert severity="error" style={{ marginBottom: 30 }}>
                  {message}
                </Alert>
              )}
              <Paper style={{ padding: 15, marginBottom: 15 }}>
                <Field
                  component={TextField}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  style={{ margin: 0 }}
                />{" "}
              </Paper>
              <QuizTemplateQuestions template={quiz} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper style={{ padding: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  Update
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditQuizTemplate;
